import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Dropdown } from 'primereact';
import {  InputTextarea } from 'primereact';
import Button from 'react-bootstrap/Button';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import Swal from 'sweetalert2';
import { FileUpload } from "primereact/fileupload";

const Faq = (props) => {

    const { user,logout } = useAuth();
    const [show, setShow] = useState(props.showFaqModal);
    const navigate = useNavigate();
    const faqClose = () => setShow(false);
    const [loading, setLoading] = useState(false); 
    const [showReferralsField, setShowReferralsField] = useState(false); 
    const [errorMessage, setErrorMessage] = useState('');
    const [dbFaqData, setDbFaqData] = useState([]); 
    const [dbReferralData, setDbReferralData] = useState([]);
    const [media, setMedia] = useState('');
    const mediaRef = useRef('');
    const formik = useFormik({
        initialValues: {
            users_id: '',
            faq_master: '',
            referral_id:'',
            notes: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.faq_master) {
                errors.faq_master = 'Please select an option.';
            }
            
            if(data.faq_master.slug === 'Ihaveanissuewithareferral') {
                if (!data.referral_id) {
                    errors.referral_id = 'Please select Reference #';
                }
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    useEffect(() => {
        if (user !== null) {
            formik.setFieldValue("users_id", user.id ? user.id : "");
            fetchFaqData();
            fetchReferralReferenceData();
        }
    }, [user]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    
    const fetchReferralReferenceData = async () => {
        try {
            const response = await axios.post("/get-all-referral-reference-data");
            const { Status, StatusMessage, Error, Result } =
                response.data;

            if (Status === true) {
                //alert(Result)
                setDbReferralData(Result);
            }
        } catch (err) {

        }
    };
    const fetchFaqData = async () => {
        try {
            const response = await axios.post("/get-all-faq-master-data");
            const { Status, StatusMessage, Error, Result } =
                response.data;

            if (Status === true) {
                //alert(Result)
                setDbFaqData(Result);

            }
        } catch (err) {

        }
    };
    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name.replace(/_/g, ' ')}</div>
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };
    
    const optionTemplate = (option) => {
        return (
            <div className="country-item">
                <div className="agent-field">
                    <label htmlFor="" >{option.name.replace(/_/g, ' ')}</label>
                </div>
            </div>
        );
    };
    const selectedTemplateReferral = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.reference_number.replace(/_/g, ' ')}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };
    
    
    const optionTemplateReferral = (option) => {
        return (
            <div className="country-item">
                <div className="agent-field">
                    <label htmlFor="" >{option.reference_number.replace(/_/g, ' ')}</label>
                </div>
            </div>
        );
    };
    const onFileChangedProfilePic = async (event) => {
        
        let file = event.files[0];
        let fileSize = file.size;
        const imageSizeInMb = Math.round((fileSize / 1024));
        let fileName = event.files[0].name;
        let splitName = fileName.split('.');

        if (imageSizeInMb >= 2048) {
            mediaRef.current.value = '';
            
            //Swal.fire('Please upload the file with a size of not more than 2 MB.');
            Swal.fire({
                title: '',
                text: "Please upload the file with a size of not more than 2 MB.",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

            })
            return false;
        }
        else if (splitName[1] !== 'bmp' && splitName[1] !== 'jpe' && splitName[1] !== 'jfif' && splitName[1] !== 'webp' && splitName[1] !== 'jpg' && splitName[1] !== 'jpeg' && splitName[1] !== 'png' && splitName[1] !== 'gif' && splitName[1] !== 'mp4') {
            mediaRef.current.value = '';
            
            //Swal.fire(file.name+' has an invalid extension. Valid extension(s): bmp, gif, jpeg, jpg, jpe, jfif, png, webp.');
            Swal.fire({
                title: '',
                html: file.name + " has an invalid extension.<br> Valid extension(s): bmp, gif, jpeg, jpg, jpe, jfif, png, webp.",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
            }).then((result) => {

            })
            return false;
        }
        else {
            
            // const text = document.querySelectorAll('.p-fileupload-row')
            // let j = 0;
            // for (const el of text) {
                
            //     if(j === 0) {
            //         el.remove();
            //     }
            //     j++;
              
            // }
            setMedia(file);
        }
    };
    const handleSubmit = async (data) => {
        let formData = new FormData();
        
        formData.append('media_file', media);
        formData.append('users_id', data.users_id);
        formData.append('faq_master', JSON.stringify(data.faq_master));
        if(data.referral_id)
            formData.append('referral_id', JSON.stringify(data.referral_id));
        else
            formData.append('referral_id', '');
        formData.append('notes', data.notes);
        setLoading(true);
        try {
            const response = await axios.post('/insert-faq-logs', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    html: 'Your ticket submitted successfully.<br> We will contact you soon.',
                }).then((result) => {
                    if (result.isConfirmed) {
                        setLoading(false);
                        faqClose();
                        props.changeStatusFaq();
                    }
                });
            }
        } catch (err) {
            
            setLoading(false);
            window.scrollTo(0, 0);
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }else{
               
                setErrorMessage("Something went wrong");
            }
        }
    };
    const handleChangeFaq = (e) => {
        //alert(e.target.value.slug)
        formik.setFieldValue("referral_id", "");
        if(e.target.value.slug === 'Ihaveanissuewithareferral') {
            setShowReferralsField(true);
        } else {
            setShowReferralsField(false);
        }
    }
    
    return (
        <>
            <Modal enforceFocus={false} className='invite-user-popup' show={show} onHide={faqClose} data-keyboard="false" backdrop="static">
           
                <Modal.Header className='invite-user-modal'>
                    <Modal.Title>Support</Modal.Title>
                </Modal.Header>
                {errorMessage && (
                    <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                        {errorMessage}
                    </Alert>
                )}
                <form onSubmit={formik.handleSubmit} >
                    <Modal.Body className='invite-user-section'>
                    
                        <div className="invite-user-body">
                            <div className="invite-user-form">
                                <div className="row">
                                    <div className="field form-field signup-label col-lg-12 signup-form-field que-input-field"> 
                                        <label htmlFor="fname">How can we help? <span className="reuired-star">*</span></label>
                                        <Dropdown name="faq_master" value={formik.values.faq_master} options={dbFaqData} placeholder="Select One" 
                                            onChange={(e)=> {formik.handleChange(e);handleChangeFaq(e)}}
                                            valueTemplate={selectedTemplate}
                                            itemTemplate={optionTemplate}
                                            optionLabel="name"
                                            filter
                                            filterBy="name"
                                        />
                                        {getFormErrorMessage('faq_master')}
                                    </div>
                                    {
                                        (showReferralsField) && (<div className="field form-field signup-label col-lg-12 signup-form-field que-input-field">
                                        <label htmlFor="fname">Please select Reference # <span className="reuired-star">*</span></label>
                                        <Dropdown name="referral_id" value={formik.values.referral_id} options={dbReferralData} placeholder="Select One" 
                                            onChange={formik.handleChange}
                                            valueTemplate={selectedTemplateReferral}
                                            itemTemplate={optionTemplateReferral}
                                            optionLabel="reference_number"
                                            filter
                                            filterBy="reference_number"
                                        />
                                        {getFormErrorMessage('referral_id')}
                                    </div>)
                                    }
                                    
                                    
                                    <div className="field form-field signup-label col-lg-12 signup-form-field">
                                        <label htmlFor="lname">Message </label>
                                        <InputTextarea
                                            rows={5} cols={30} name="notes"
                                            autoComplete="off"
                                            value={formik.values.notes} onChange={formik.handleChange}
                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('description') })} placeholder="Enter here" />
                                        {getFormErrorMessage('notes')}
                                    </div>
                                </div>

                                <div className="field mb-3">
                                    <label htmlFor="recipient-name">Media </label>
                                    <div className="upload-item que-upload-field">
                                            <FileUpload
                                            ref={mediaRef}
                                            maxFileSize={2000000}
                                                type="file" 
                                                className="upload-img-btn"
                                                auto
                                                accept=".bmp, .mp4,.gif, .jpeg,.jpg, .jpe, .jfif, .png, .webp"
                                                customUpload uploadHandler={(e) => {onFileChangedProfilePic(e)}}
                                                chooseLabel="Upload Media"
                                            />
                                           
                                        </div>
                                    {getFormErrorMessage('email')}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='invite-user-footer'>
                        <Button className='cancel-btn' variant="secondary" onClick={() => { faqClose(); props.changeStatusFaq() }}>
                            Cancel
                        </Button>
                        <Button type="submit" className='btn btn-submit btn-500 btn-submit-loader' variant="primary" disabled={loading}>
                            Send Message

                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                        </Button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default Faq;