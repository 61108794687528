import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Alert, CircularProgress, Typography } from '@mui/material'
import axios from "../../../axiosCall.js";
import { IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ReferralStatusUpdate = () => {
    
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const search = useLocation().search;
    const verificationCode = new URLSearchParams(search).get('r');
    const [isLinkExpired, setIsLinkExpired] = useState(false);

    
    useEffect(() => {
        const verifyUser = async () => {
            setErrorMessage('');
           // alert(verificationCode)
            try {
                const response = await axios.post(`/verify-referral-status-link/${verificationCode}`);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = "";

                if (Status === false) {
                    if (Error.length > 0) {
                        Error.map(
                            (errorMessage) =>
                                //finalApiError += '<li>'+errorMessage+'</li>'
                                (finalApiError = errorMessage)
                        );
                    } else {
                        finalApiError = StatusMessage;
                    }
                    setErrorMessage(finalApiError);
                   // alert(Result.is_expired)
                    if(Result.is_expired)
                    {
                        setIsLinkExpired(true);
                    }
                    else
                    {
                        setIsLinkExpired(false);
                    }
                    window.scrollTo(0,0);
                }
                else {
                    setIsLinkExpired(false);
                }
            } catch (err) {
                // console.log(err);
                setErrorMessage('There is something wrong. Please try again');
                window.scrollTo(0,0);
            }
        };

        verifyUser();
    }, []);
    const redirectToLogin = () => {
        navigate('/login');
    }
    return (
        <>
            <section className="form-section auth-bg">
                <div className="container-fluid">
                    <div className="form-container reset-container">
                        <div className="form-wrapper">
                            {errorMessage && (
                                <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                    {errorMessage}
                                </Alert>
                            )}
                            {
                                (isLinkExpired) && (
                                    <>
                                
                                <p className="signup-desc">Your link is expired. Please login and changed status for the referral.</p>
                                <br/>
                                <div className="form-group">
                                <NavLink to="/login" className="btn form-btn">Login</NavLink>
                                </div></>
                            )}
                            {
                                    (isLinkExpired === false) && (
                            <><h1 className="signup-heading my-2">Success</h1>

                            <p className="signup-desc changedPassword-desc mb-4">
                                <span>
                                    <IconButton>
                                        <CheckCircleIcon />
                                    </IconButton>
                                </span>
                                You have successfully changed the status.
                            </p>

                            <div className="form-group">
                                <button className="btn form-btn" type="button" onClick={redirectToLogin}>Login</button>
                            </div></>)}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ReferralStatusUpdate;