import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { InputNumber, InputText } from 'primereact';
import Button from 'react-bootstrap/Button';
import { Dropdown } from 'primereact/dropdown';
const DeletePopup = () => {
    const [show, setShow] = useState(false);
    const deleteRecordClose = () => setShow(false);
    const deleteRecord = () => setShow(true);
    return (
        <>
            <Button variant="primary" onClick={deleteRecord}>
                Launch demo modal
            </Button>
            <Modal className='delete-popup' show={show} onHide={deleteRecordClose} centered>
                   <Modal.Body>
                    <div className='delete-modal text-center'>
                        <img src="../assets/images/delete-record.png" alt="" />
                        <p className='title'>Delete Record?</p>
                        <p className="sub-desc">Are  you sure you want to delete this record?</p>
                        <div className="delete-buttons">
                            <button className='btn btn-cancel mr-3' type='button'>Delete</button>
                            <button className='btn btn-submit' type='button'>Cancel</button>
                        </div>
                    </div>
                   </Modal.Body>
            </Modal>
        </>
    )
}

export default DeletePopup;