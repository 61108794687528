import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import LogoUI from "../LogoUI";
import { Password } from 'primereact/password';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Divider } from 'primereact/divider';
import { Alert, CircularProgress, Typography } from '@mui/material'
import axios from '../../../axiosCall.js';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

const ResetPassword = () => {
    const secretKey = 'REFR2022@GALAXY';
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const search = useLocation().search;
    const verificationCode = new URLSearchParams(search).get('c');
    const [isLinkExpired, setIsLinkExpired] = useState(false);

    const formik = useFormik({
        initialValues: {
            user_id: '',
            password: '',
            password_confirmation: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.password.trim()) {
                errors.password = 'Password is required.';
            }
            else {
                if (!data.password_confirmation.trim()) {
                    errors.password_confirmation = 'Confirm Password is required.';
                }
                else if (data.password !== data.password_confirmation) {
                    errors.password_confirmation = 'Password and confirm password must be same.';
                }
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    useEffect(() => {
        const verifyUser = async () => {
            setErrorMessage('');
           // alert(verificationCode)
            try {
                const response = await axios.post(`/verify-reset-password-link/${verificationCode}`);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = "";

                if (Status === false) {
                    if (Error.length > 0) {
                        Error.map(
                            (errorMessage) =>
                                //finalApiError += '<li>'+errorMessage+'</li>'
                                (finalApiError = errorMessage)
                        );
                    } else {
                        finalApiError = StatusMessage;
                    }
                    setErrorMessage(finalApiError);
                   // alert(Result.is_expired)
                    if(Result.is_expired)
                    {
                        setIsLinkExpired(true);
                    }
                    else
                    {
                        setIsLinkExpired(false);
                    }
                    window.scrollTo(0,0);
                }
                else {
                    formik.setFieldValue("user_id", Result.user_id);
                }
            } catch (err) {
                // console.log(err);
                setErrorMessage('There is something wrong. Please try again');
                window.scrollTo(0,0);
            }
        };

        verifyUser();
    }, []);
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);

        try {
            const response = await axios.post('/reset-password', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0,0);

            } else {
                setLoading(false);

                navigate('/password-changed');

                formik.resetForm();
            }
        } catch (err) {
            // console.log(err);
            setErrorMessage('There is something wrong. Please try again');
            setLoading(false);
            window.scrollTo(0,0);
        }
    };
    return (
        <>
            <section className="form-section auth-bg">
                <div className="container-fluid">
                    <div className="form-container reset-container">
                        <div className="form-wrapper">
                            {errorMessage && (
                                <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                    {errorMessage}
                                </Alert>
                            )}
                            {
                                (isLinkExpired) && (
                                    <>
                                
                                <p className="signup-desc">Your link is expired. Please again make a request for reset password.</p>
                                <br/>
                                <div className="form-group">
                                <NavLink to="/forgot-password" className="btn form-btn">Forgot Password</NavLink>
                                </div></>
                            )}
                            {
                                    (isLinkExpired === false) && (
                            <><form onSubmit={formik.handleSubmit} >
                                <div className="back-sign">
                                    <NavLink to="/forgot-password"><img src="./assets/images/back-icon.svg" alt="" /></NavLink>
                                </div>
                                <h1>Create New Password</h1>

                                <p className="signup-desc">Your new password must be different from previous password</p>
                                <div className="form-inner forgot-inner">
                                    <div className="form-field">
                                        <label htmlFor="">Password</label>
                                        <Password
                                            autoComplete="off"
                                            inputClassName={classNames("form-control", { 'p-invalid': isFormFieldValid('password') })} placeholder="Enter here"
                                            name="password"
                                            value={formik.values.password} onChange={formik.handleChange} toggleMask
                                        />
                                        {getFormErrorMessage('password')}
                                    </div>
                                    <div className="form-field show-icon">
                                        <div className="form-field">
                                            <label htmlFor="">Confirm Password</label>
                                            <Password
                                                autoComplete="off"
                                                inputClassName={classNames("form-control", { 'p-invalid': isFormFieldValid('password_confirmation') })} placeholder="Enter here"
                                                name="password_confirmation"
                                                value={formik.values.password_confirmation} onChange={formik.handleChange}
                                                toggleMask
                                            />
                                            {getFormErrorMessage('password_confirmation')}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn form-btn" disabled={loading}>Reset Password
                                    {loading && (<Typography align="center">
                                        <CircularProgress value={66} />
                                    </Typography>)}
                                    </button>
                                    
                                    {/* <NavLink to="/password-changed" className="btn form-btn">Reset Password</NavLink> */}
                                </div>
                                
                            </form></>)}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ResetPassword;