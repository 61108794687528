import React from "react";
import { ReferralFormStepper } from "./ReferralFormStepper";
import { SubmitAReferralContextProvider } from "./context/submit-a-referral-context";

export default function SubmitAReferral() {
    return (
      <SubmitAReferralContextProvider>
          <ReferralFormStepper />
      </SubmitAReferralContextProvider>
    );
}
