import React from 'react'
import { Dropdown, Checkbox, InputText, Editor, Button, SplitButton } from 'primereact';

const EmailCheck = () => {
    const items = [
        {
            label: 'select-1'
        },
        {
            label: 'select-2'
        },
        {
            label: 'select-3'
        }
    ]
    return (
        <>
            <section className='emailTemplate-component sec-pt sec-pb'>
                <div className="container">
                    <div className="email-container">
                        <h2 className='main-heading'>Email</h2>
                        <div className="form-container">
                            <form>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="field">
                                            <label htmlFor="" className="form-label">From</label>
                                            <Dropdown className='form-select' options="" optionLabel="from" placeholder="Select" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 state-flex">
                                        <div className="field">
                                            <label htmlFor="" className="form-label">By State</label>
                                            <Dropdown className='form-select' optionLabel="bystate" filter showClear filterBy="name" placeholder="Select" />
                                        </div>
                                        <div className="field-checkbox">
                                            <Checkbox inputId="allstates" />
                                            <label htmlFor="allstates">All States</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='create-checkboxes'>
                                    <div className="create-email-check">
                                        <Checkbox className="form-check-input" inputId="ambassadors" />
                                        <label htmlFor="ambassadors">Ambassadors</label>
                                        <Checkbox className="form-check-input referral" inputId="referral" />
                                        <label htmlFor="referral">Referral</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="field">
                                            <label htmlFor="to" className="form-label">To</label>
                                            <InputText className="form-control" placeholder="Darrell Steward " />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="field">
                                            <label htmlFor="" className="form-label">Select Template</label>
                                            <Dropdown className='form-select' optionLabel="template" filter showClear filterBy="name" placeholder="Select" />
                                        </div>
                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="form-label">Email Subject</label>
                                    <div className="p-inputgroup">
                                        <InputText className="form-control" placeholder="Steven" />
                                        <SplitButton label="Merge Fields" onClick="" model={items}></SplitButton>
                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="form-label">Message</label>
                                    <Editor placeholder="Enter Here" style={{ height: '199px' }} />

                                </div>

                                <div className="field text-center email-btns">
                                    <button className="btn btn-cancel me-3" type="button">Cancel</button>
                                    <button className="btn btn-submit" type="button">Preview Email</button>
                                </div>
                            </form>
                        </div>
                        <div className="email-template-letter">
                            <img src="assets/images/Open-Email.png" alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EmailCheck;
