import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Alert,CircularProgress,Typography} from '@mui/material';
import Swal from 'sweetalert2';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";

const SendContractToAgent = (props) => {
    const [show, setShow] = useState(props.showSendContract);
    const handleClose = () => setShow(false);
    const [isFile, setIsFile] = useState(false);
    const [fileName, setFileName] = useState('');
    const [contractAgentPdf, setContractAgentPdf] = useState('');
    const [validMessage, setValidMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { logout } = useAuth();
    const fileTypes = ["pdf"];
    const navigate = useNavigate();

    const handleImport = (file) => {
        const fileSize = file.size / 1024 / 1024; // in MiB
        setValidMessage('');
        if (fileSize > 1) {
            //alert('File size exceeds 1 MiB');
            setIsFile(false);
            setFileName('')
            setValidMessage('Please upload pdf file not more than 1 MB')
        } 
        else if (file.type !== 'application/pdf') {
            setIsFile(false);
            setFileName('')

            Swal.fire({
                title: '',
                text: "Please selects the file with valid extension i.e. *.pdf.",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Okay'
            }).then((result) => {

            })
        }
        else {
            setIsFile(true);
            setFileName(file.name)
            setContractAgentPdf(file);
        }
    };
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if(isFile)
        {
            setLoading(true);
            let formData = new FormData();

            formData.append('users_id', props.loginUserId); 
            formData.append('contract_pdf', contractAgentPdf); 
            formData.append('referral_id', props.referralRowId);

            try {
                const response = await axios.post('/send-agent-contract', formData);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = '';
                setLoading(false);
                if (Status === false) {
                  if (Error.length > 0) {
                    Error.map(
                      (errorMessage) =>
                        //finalApiError += '<li>'+errorMessage+'</li>'
                        (finalApiError = errorMessage)
                    );
                  } else {
                    finalApiError = StatusMessage;
                  }
                  setErrorMessage(finalApiError);
                } else {
                    setLoading(false);
                    Swal.fire({
                        title: 'Success',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        text: StatusMessage,
                      }).then((result) => {
                        if (result.isConfirmed) {
                            handleClose();
                            props.changeStatuSendContractToAgent();
                        }
                      });
                }
              } catch (err) {
                
                setLoading(false);
                if(err.response.status && err.response.status==401){
                    logout();
                    navigate('/login');
                }else{
                    setErrorMessage("Something went wrong");
                }
            }
        }
        else
        {
            setValidMessage('Please upload pdf file.')
        }
    }
    return (
        <>
            <Modal className='upload-popup' show={show} onHide={handleClose}backdrop="static">
                <form onSubmit={handleSubmit}> 
                    <Modal.Header className='upload-header'>
                        <Modal.Title>Upload Referral Fee Agreement</Modal.Title>
                    </Modal.Header>
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                            {errorMessage}
                        </Alert>
                    )}
                    <Modal.Body className='upload-section'>
                        <FileUploader
                            handleChange={handleImport}
                            name="file"
                            types={fileTypes}
                            // label= "Drag & drop or, browse your file"
                            children= <div className='upload-files-csv'>
                                <div className='upload-file-box'>
                                    <p className='title'><img src="../assets/images/csv-file-icon.png" alt="" /> Drag & drop or, <span>browse</span>  your file</p>
                                    {/* <p className='sub-title'>(PDF file only)</p> */}
                                </div>
                                {
                                    (fileName) && (<p>File Name {fileName}</p>)
                                }
                            </div>
                        />
                        {
                            (validMessage) && (<small className="p-error">{validMessage}</small>)
                        }
                    </Modal.Body>
                    <Modal.Footer className='file-upload-footer'>
                        <Button className='btn-cancel btn-500' variant="" onClick={() => {handleClose(); props.changeStatuSendContractToAgent();}} disabled={loading}>
                          Cancel
                        </Button>
                        <Button type="submit" className='btn btn-submit btn-500 btn-submit-loader' variant="" disabled={loading}>
                        Upload
                        {loading && (<Typography align="center">
                            <CircularProgress value={66} />
                        </Typography>)}
                        </Button>
                        
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default SendContractToAgent;