import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import { createRoot } from 'react-dom/client'
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';
import { InputText } from 'primereact';
import { Avatar } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Button } from "primereact/button";
import axios, { baseUrl } from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import AES from "crypto-js/aes";
import { Column } from 'primereact/column';
import Swal from 'sweetalert2';
import { Alert} from '@mui/material';
import XLSX from "xlsx-color";
import EditUser from "./../MyNetwork/EditUser";

const PayingChecks = () => {
    const { user, logout } = useAuth();
    const secretKey = "REFR2022@GALAXY";
    const navigate = useNavigate();
    
    const width = {
        width: "100%",
    }
    let filter = '';
    let maxDate = new Date();
    
    const [isShowSearch, setIsShowSearch] = useState('none');

    const [searchDateValue, setSearchDateValue] = useState(maxDate);
    const [reportData, setReportData] = useState([]);
    const [reportReferencewiseData, setReportReferencewiseData] = useState([]);
    const [excelReportData, setExcelReportData] = useState([]);
    const [excelUplineReportData, setExcelUplineReportData] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [showEditUser, setShowEditUser] = useState(false);
    const [showClearSearch, setShowClearSearch] = useState(false);
    const [editRowId, setEditRowId] = useState(0);
    const [editRowType, setEditRowType] = useState(false);
    const [editRowTypeTemp, setEditRowTypeTemp] = useState(false);
    const [dbStatesName, setDbStatesName] = useState([]);
    const [dbBrokerageRoleData, setBrokerageRoleData] = useState([]);
    
    const [currentFilter, setCurrentFilter] = useState('ambassador');

    const [callFrom, setCallFrom] = useState('');
    const changeStatusEditUser = () => {
        setEditRowId(0)
        setEditRowType('')
        setShowEditUser(false)
    }
    const reloadListing = () => {
        setEditRowId(0)
        setEditRowType('')
        
        if(editRowTypeTemp === 'ambassador')
            $(".ambassadorTabClass").first().trigger('click');
        else if(editRowTypeTemp === 'document_missing_docs')
            $(".documentMissingTabClass").first().trigger('click');

        setEditRowTypeTemp('')
    }
    const handleEdit = (rowId, rowType,mode) => {
        setShowEditUser(true)
        setEditRowId(rowId);
        setEditRowType(rowType);
        setEditRowTypeTemp(mode);
        setCallFrom('listing');
    }
    const handleEmail = (rowId) => {
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();

        let finalUrl = 'u=' + encodeURIComponent(userIdEncryptedString);
        
        navigate('/app/email-template?' + finalUrl);
    }

    
    const handleDelete = (rowId,referralCount,assignedAgentCount,mode) => {
        
        let message = '';
        let messageForDelete = '';
        if((referralCount > 0) && (assignedAgentCount > 0))
        {
            let referralText = "referral";
            let referralTextTemp = "referral";
            if(referralCount > 1)
                referralTextTemp = "referrals";

            if(assignedAgentCount > 1)
                referralText = "referrals";  
            message = "This can't be deleted, sorry!<br>The user assigned as agent for "+assignedAgentCount+" "+referralText+" and created "+referralCount+" "+referralTextTemp+".";
        }
        else if(referralCount > 0)
        {
            let referralTextTemp = "referral";
            if(referralCount > 1)
                referralTextTemp = "referrals";
            message = "This can't be deleted, sorry!<br>The user created "+referralCount+" "+referralTextTemp+".";
        }
        else if(assignedAgentCount > 0)
        {
            let referralText = "referral";
            if(assignedAgentCount > 1)
                referralText = "referrals";

            message = "This can't be deleted, sorry!<br>The user assigned as agent for "+assignedAgentCount+" "+referralText+".";
        }
        else
        {
            messageForDelete = "You won't be able to reverse this!";
        }
        if(message)
        {
            Swal.fire({
                title: '',
                html: message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

            })
        }
        else
        {
            Swal.fire({
                title: 'Are you sure?',
                html: messageForDelete,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                   
                    callDelete(rowId,mode)
                }
            })
        }
    }
    const callDelete = async (rowId,mode) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('user_id', rowId);

        try {
            const response = await axios.post('/delete-user', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
               
                if(mode === 'ambassador')
                    $(".ambassadorTabClass").first().trigger('click');
                else if(mode === 'document_missing_docs')
                    $(".documentMissingTabClass").first().trigger('click');
                

            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }
        }
    }
    function getFormattedDate(dbDate) {
        var date = new Date(dbDate);
    
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
    
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        sec = (sec < 10 ? "0" : "") + sec;
    
        var str = date.getFullYear() + "-" + month + "-" + day;
    
        /*alert(str);*/
    
        return str;
    }
    const fetchPayingChecksData = async (searchDate) => {
        let formData = new FormData();
        formData.append('searchDate', getFormattedDate(searchDate));

        try {
            const response = await axios.post("/paying-checks-list", formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                //console.log(Result)
                setReportData(Result.data);
                //
                setReportReferencewiseData(Result.reference_wise_data);

                const jsonData = Result.data.map(({
                    reference_number: ReferenceNumber,
                    final_closing_date: ClosingDate,
                    payment_received_at: PaymentReceivedAt,
                    brokerage_name: BrokerageName,
                    name: AgentName,
                    user_type: UserType,
                    record_type: Role,
                    final_sales_price: SalesPrice,
                    final_commission: Commission,
                    final_commission_earned: CommissionEarned,
                    final_referral_fee_due: ReferralFeeReceived,
                    plan: AgentPlan,
                    final_refr_gross_commission_income:Company,
                    agent_formula:AgentFormula,
                    price: Agent$,
                    paid_status: Paid,
                  }) => ({
                    ReferenceNumber,
                    ClosingDate,
                    PaymentReceivedAt,
                    BrokerageName,
                    AgentName,
                    UserType,
                    Role,
                    SalesPrice,
                    Commission,
                    CommissionEarned,
                    ReferralFeeReceived,
                    AgentPlan,
                    Company,
                    AgentFormula,
                    Agent$,
                    Paid
                  }));

                  
                  //console.log('jsonData '+JSON.stringify(jsonData));
                setExcelReportData(jsonData);
                setExcelUplineReportData(Result.upline_data);
            }
            else {
                navigate('/dashboard');
            }
        } catch (err) {

            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } 
        }
    };
    const handleClearSearch = () => {
        $('.searchbarTable').val('');
        if (currentFilter === 'ambassador') {
            $(".ambassadorTabClass").first().trigger('click');
        }
        else if(currentFilter === 'document_missing_docs'){
            $(".documentMissingTabClass").first().trigger('click');
        }
        else  if (currentFilter === 'report') {
            
            $(".reportTabClass").first().trigger('click');
        }
        setShowClearSearch(false);
    }
    const handleTab = (mode) => {
        if(mode === 'report')
        {
            $('.searchbarTable').val('');
            setIsShowSearch('none');
        }
        else
        {
            setIsShowSearch('block');
        }
        //
    }
    function fetchedNonMatchingBrokerageData() {
        //$.fn.dataTable.ext.errMode = 'throw';

        const accessToken = window.localStorage.getItem('accessToken');
        const table = $('.agentTable').
                on('preXhr.dt', function ( e, settings, data ) {
                if (settings.jqXHR) settings.jqXHR.abort();
            }).DataTable({
            "language": {
                "filter_tab": "",
                "search": "",
                'searchPlaceholder': "Search by name, email or phone",
                "sEmptyTable": "No records found"
            },
            dom: '<"top"f>rt<"bottom ambBottom"lip><"clear">',
            order: [[0, 'asc']],
            responsive: true,
            destroy: true,
            processing: true,
            "pageLength": 50,
            //"ordering": false,
            "lengthMenu": [[10, 20, 25, 30, 50, 100, 200, 500], [10, 20, 25, 30, 50, 100, 200, 500]],
            "serverSide": true,
            "serverMethod": 'POST',
            'ajax': {
                'url': baseUrl + 'get-amb-missing-brokerage-of-record',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                'data': function (data) {
                    data.user_id = user.id;
                    data.search_value = $('.searchbarTable').val();
                    data.filter = filter;
                    data.selected_documents_filter = $('#documents_search_type').val();
                },
                error: function (jqXHR) {
                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{
                        
                    }
                }
            },
            "drawCallback": function (settings) {
                // Here the response
                var response = settings.json;
                
                $("#spanAmbassadorCount").html('(' + response.ambassadorCount + ')');

                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.ambBottom').hide();
                else
                    $('.ambBottom').show();
                
            },
            // "rowCallback": function( row, data, index ) {
            //     if( data.is_all_docs_present ===  0)
            //     {
            //         $('td', row).css('background-color', '#ffe7e6');
            //     }
            // },
            "columnDefs":
                [
                    {
                        "targets": 0,
                        "width": "20%",
                        "title": "Name",
                        "data": "name",
                        "name": "name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "cellType": "td",
                        "className": "my_amb",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let matches = rowData.name.match(/\b(\w)/g);
                            let shortName = matches.join('');

                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'><Avatar>{shortName}</Avatar> <a href="javascript:;" onClick={(e) => handleNetworkDetail(`${rowData.id}`, 'ambassador')} className='name-link underline-ani-blue'>{rowData.name}</a></span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 1,
                        "width": "20%",
                        "title": "Up Line",
                        "data": "refer_user_name",
                        "name": "refer_user_name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [1],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop bold-td",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let tempType = '';
                            if(rowData.refer_user_type === 'agent')
                                tempType = '(RA)';
                            else if(rowData.refer_user_type === 'ambassador')
                                tempType = '(A)';
                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'>{(rowData.refer_user_name) ? rowData.refer_user_name+' '+tempType : 'N/A' }</span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 2,
                        "width": "15%",
                        "title": "Phone",
                        "data": "contact_number",
                        "name": "contact_number",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [2],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop bold-td",
                        "createdCell": "",
                    },
                    {
                        "targets": 3,
                        "width": "20%",
                        "title": "Broker License Info",
                        "data": "broker_license_number",
                        "name": "broker_license_number",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [3],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop",
                        render: function (data, type,row) {
                            
                            let html = '';
                            html += '<ul class="title-check">'
                            html += '<li><span class="name-title mb-1">Role: '+row.ambassador_broker_role_name+'</span><span class="name-title mb-1">License #: '+row.broker_license_number+'</span><span class="name-title">State: '+row.broker_license_state+'</span></li>';
                            html += '</ul>';
                            return html;
                        },
                    },
                    {
                        "targets": 4,
                        "width": "15%",
                        "title": "Created On",
                        "data": "created",
                        "name": "created",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [4],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 5,
                        "width": "15%",
                        "title": "Action",
                        "data": "id",
                        "visible": user.type === 'admin',
                        "searchable": false,
                        "orderable": false,
                        "className": "my_amb desktop tablet-l tablet-p",
                        "createdCell": (td, cellData, rowData, row, col) => {

                            createRoot(td).render(<ul className='action-icon'>
                                <li className='action-list-icon'>
                                <a href='javascript:;' onClick={() => {handleEmail(rowData.id)}}>
                                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.667 13.4582H2.33366C1.72655 13.456 1.14493 13.2138 0.715632 12.7845C0.286336 12.3552 0.0441879 11.7736 0.0419922 11.1665V2.83317C0.0441879 2.22606 0.286336 1.64444 0.715632 1.21514C1.14493 0.785848 1.72655 0.5437 2.33366 0.541504H15.667C16.2741 0.5437 16.8557 0.785848 17.285 1.21514C17.7143 1.64444 17.9565 2.22606 17.9587 2.83317V11.1665C17.9565 11.7736 17.7143 12.3552 17.285 12.7845C16.8557 13.2138 16.2741 13.456 15.667 13.4582ZM2.33366 1.7915C2.05739 1.7915 1.79244 1.90125 1.59709 2.0966C1.40174 2.29195 1.29199 2.5569 1.29199 2.83317V11.1665C1.29199 11.4428 1.40174 11.7077 1.59709 11.9031C1.79244 12.0984 2.05739 12.2082 2.33366 12.2082H15.667C15.9433 12.2082 16.2082 12.0984 16.4036 11.9031C16.5989 11.7077 16.7087 11.4428 16.7087 11.1665V2.83317C16.7087 2.5569 16.5989 2.29195 16.4036 2.0966C16.2082 1.90125 15.9433 1.7915 15.667 1.7915H2.33366Z" fill="#1E2938" />
                                            <path d="M9.0001 8.12498C8.51651 8.12498 8.04174 7.99549 7.6251 7.74998L0.350104 3.42498C0.214182 3.34026 0.115402 3.2072 0.0736538 3.05258C0.0319056 2.89795 0.0502894 2.73326 0.125104 2.59164C0.208308 2.45398 0.342012 2.35427 0.497695 2.31379C0.653378 2.27331 0.818715 2.29527 0.958437 2.37498L8.24177 6.69164C8.46265 6.82637 8.71638 6.89764 8.9751 6.89764C9.23383 6.89764 9.48756 6.82637 9.70844 6.69164L16.9918 2.37498C17.1315 2.29527 17.2968 2.27331 17.4525 2.31379C17.6082 2.35427 17.7419 2.45398 17.8251 2.59164C17.8999 2.73326 17.9183 2.89795 17.8766 3.05258C17.8348 3.2072 17.736 3.34026 17.6001 3.42498L10.3751 7.74998C9.95847 7.99549 9.4837 8.12498 9.0001 8.12498Z" fill="#1E2938" />
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                    <a href='javascript:;' onClick={() => {handleEdit(rowData.id,rowData.type,'ambassador')}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_21796)">
                                                <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_21796">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                <a href='javascript:;' onClick={() => {handleDelete(rowData.id,rowData.created_referral_count,rowData.assigned_as_agent_referral_count,'ambassador')}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_13698)">
                                                <path d="M3.33398 5.83398H16.6673" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.33398 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.666 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.16602 5.83398L4.99935 15.834C4.99935 16.276 5.17494 16.6999 5.4875 17.0125C5.80006 17.3251 6.22399 17.5007 6.66602 17.5007H13.3327C13.7747 17.5007 14.1986 17.3251 14.5112 17.0125C14.8238 16.6999 14.9993 16.276 14.9993 15.834L15.8327 5.83398" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_13698">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </a>
                                </li>
                                
                            </ul>);
                        },

                    }
                ]
        });
        
        $('.searchbarTable').keyup(function () {
           // console.log($(this).val().length)
           if ((filter === 'ambassador') && (($(this).val().length >= 3) || ($(this).val().length === 0)))
            {
                setCurrentFilter('ambassador');
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else if (filter === 'ambassador')
            {
                setShowClearSearch(false);
            }  
        })
        $('#documents_search_type').change(function () {
               table.draw();
        })
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

        $(".agentTable").on('click', 'th', function () {
            $(".agentTable thead th").each(function (i, th) {
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });

        $(".agentTable th").first().click().click();

        $('.ambassadorTabClass').click(function () {
            
            filter = 'ambassador';
            table.clear();
            table.draw();
        })
        setTimeout(function () {
            $('input[name^="switchId_"]').click(function () {
                
                let rowId = $(this).attr('data-id');
                let mlsStatus = $(this).attr('data-is-mls');
               // console.log('rowId ' + rowId + ' mlsStatus ' + mlsStatus)

            });
        }, 2000);
    }
    function fetchedDocumentMissingUsersData() {
        //$.fn.dataTable.ext.errMode = 'throw';

        const accessToken = window.localStorage.getItem('accessToken');
        const table = $('.documentMissingTable').
                on('preXhr.dt', function ( e, settings, data ) {
                if (settings.jqXHR) settings.jqXHR.abort();
            }).DataTable({
            "language": {
                "filter_tab": "",
                "search": "",
                'searchPlaceholder': "Search by name, email or phone",
                "sEmptyTable": "No records found"
            },
            dom: '<"top"f>rt<"bottom ambBottom"lip><"clear">',
            order: [[0, 'asc']],
            responsive: true,
            destroy: true,
            processing: true,
            "pageLength": 50,
            //"ordering": false,
            "lengthMenu": [[10, 20, 25, 30, 50, 100, 200, 500], [10, 20, 25, 30, 50, 100, 200, 500]],
            "serverSide": true,
            "serverMethod": 'POST',
            'ajax': {
                'url': baseUrl + 'get-amb-missing-brokerage-of-record',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                'data': function (data) {
                    data.user_id = user.id;
                    data.search_value = $('.searchbarTable').val();
                    data.filter = filter;
                    data.selected_documents_filter = $('#documents_search_type').val();
                },
                error: function (jqXHR) {
                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{
                        
                    }
                }
            },
            "drawCallback": function (settings) {
                // Here the response
                var response = settings.json;
                
                $("#spanDocumentMissingCount").html('(' + response.missingDocsCount + ')');

                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.ambBottom').hide();
                else
                    $('.ambBottom').show();
                
            },
            // "rowCallback": function( row, data, index ) {
            //     if( data.is_all_docs_present ===  0)
            //     {
            //         $('td', row).css('background-color', '#ffe7e6');
            //     }
            // },
            "columnDefs":
                [
                    {
                        "targets": 0,
                        "width": "20%",
                        "title": "Name",
                        "data": "name",
                        "name": "name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "cellType": "td",
                        "className": "my_amb",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let matches = rowData.name.match(/\b(\w)/g);
                            let shortName = matches.join('');

                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'><Avatar>{shortName}</Avatar> <a href="javascript:;" onClick={(e) => handleNetworkDetail(`${rowData.id}`, `${rowData.type}`)} className='name-link underline-ani-blue'>{rowData.name}</a></span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 1,
                        "width": "20%",
                        "title": "Up Line",
                        "data": "refer_user_name",
                        "name": "refer_user_name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [1],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop bold-td",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let tempType = '';
                            if(rowData.refer_user_type === 'agent')
                                tempType = '(RA)';
                            else if(rowData.refer_user_type === 'ambassador')
                                tempType = '(A)';
                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'>{(rowData.refer_user_name) ? rowData.refer_user_name+' '+tempType : 'N/A' }</span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 2,
                        "width": "15%",
                        "title": "Type",
                        "data": "user_type",
                        "name": "user_type",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [2],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop bold-td",
                        "createdCell": "",
                    },
                    {
                        "targets": 3,
                        "width": "15%",
                        "title": "Phone",
                        "data": "contact_number",
                        "name": "contact_number",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [3],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop bold-td",
                        "createdCell": "",
                    },
                    {
                        "targets": 4,
                        "width": "15%",
                        "title": "Created On",
                        "data": "created",
                        "name": "created",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [4],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 5,
                        "width": "15%",
                        "title": "Action",
                        "data": "id",
                        "visible": user.type === 'admin',
                        "searchable": false,
                        "orderable": false,
                        "className": "my_amb desktop tablet-l tablet-p",
                        "createdCell": (td, cellData, rowData, row, col) => {

                            createRoot(td).render(<ul className='action-icon'>
                                <li className='action-list-icon'>
                                <a href='javascript:;' onClick={() => {handleEmail(rowData.id)}}>
                                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.667 13.4582H2.33366C1.72655 13.456 1.14493 13.2138 0.715632 12.7845C0.286336 12.3552 0.0441879 11.7736 0.0419922 11.1665V2.83317C0.0441879 2.22606 0.286336 1.64444 0.715632 1.21514C1.14493 0.785848 1.72655 0.5437 2.33366 0.541504H15.667C16.2741 0.5437 16.8557 0.785848 17.285 1.21514C17.7143 1.64444 17.9565 2.22606 17.9587 2.83317V11.1665C17.9565 11.7736 17.7143 12.3552 17.285 12.7845C16.8557 13.2138 16.2741 13.456 15.667 13.4582ZM2.33366 1.7915C2.05739 1.7915 1.79244 1.90125 1.59709 2.0966C1.40174 2.29195 1.29199 2.5569 1.29199 2.83317V11.1665C1.29199 11.4428 1.40174 11.7077 1.59709 11.9031C1.79244 12.0984 2.05739 12.2082 2.33366 12.2082H15.667C15.9433 12.2082 16.2082 12.0984 16.4036 11.9031C16.5989 11.7077 16.7087 11.4428 16.7087 11.1665V2.83317C16.7087 2.5569 16.5989 2.29195 16.4036 2.0966C16.2082 1.90125 15.9433 1.7915 15.667 1.7915H2.33366Z" fill="#1E2938" />
                                            <path d="M9.0001 8.12498C8.51651 8.12498 8.04174 7.99549 7.6251 7.74998L0.350104 3.42498C0.214182 3.34026 0.115402 3.2072 0.0736538 3.05258C0.0319056 2.89795 0.0502894 2.73326 0.125104 2.59164C0.208308 2.45398 0.342012 2.35427 0.497695 2.31379C0.653378 2.27331 0.818715 2.29527 0.958437 2.37498L8.24177 6.69164C8.46265 6.82637 8.71638 6.89764 8.9751 6.89764C9.23383 6.89764 9.48756 6.82637 9.70844 6.69164L16.9918 2.37498C17.1315 2.29527 17.2968 2.27331 17.4525 2.31379C17.6082 2.35427 17.7419 2.45398 17.8251 2.59164C17.8999 2.73326 17.9183 2.89795 17.8766 3.05258C17.8348 3.2072 17.736 3.34026 17.6001 3.42498L10.3751 7.74998C9.95847 7.99549 9.4837 8.12498 9.0001 8.12498Z" fill="#1E2938" />
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                    <a href='javascript:;' onClick={() => {handleEdit(rowData.id,rowData.type,'document_missing_docs')}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_21796)">
                                                <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_21796">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                <a href='javascript:;' onClick={() => {handleDelete(rowData.id,rowData.created_referral_count,rowData.assigned_as_agent_referral_count,'document_missing_docs')}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_13698)">
                                                <path d="M3.33398 5.83398H16.6673" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.33398 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.666 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.16602 5.83398L4.99935 15.834C4.99935 16.276 5.17494 16.6999 5.4875 17.0125C5.80006 17.3251 6.22399 17.5007 6.66602 17.5007H13.3327C13.7747 17.5007 14.1986 17.3251 14.5112 17.0125C14.8238 16.6999 14.9993 16.276 14.9993 15.834L15.8327 5.83398" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_13698">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </a>
                                </li>
                                
                            </ul>);
                        },

                    }
                ]
        });
        
        $('.searchbarTable').keyup(function () {
           // console.log($(this).val().length)
           if ((filter === 'document_missing_docs') && (($(this).val().length >= 3) || ($(this).val().length === 0)))
            {
                setCurrentFilter('document_missing_docs');
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else if (filter === 'document_missing_docs')
            {
                setShowClearSearch(false);
            }  
        })
        $('#documents_search_type').change(function () {
               table.draw();
        })
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

        $(".documentMissingTable").on('click', 'th', function () {
            $(".documentMissingTable thead th").each(function (i, th) {
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });

        $(".documentMissingTable th").first().click().click();

        $('.documentMissingTabClass').click(function () {
            
            filter = 'document_missing_docs';
            table.clear();
            table.draw();
        })
        setTimeout(function () {
            $('input[name^="switchId_"]').click(function () {
                
                let rowId = $(this).attr('data-id');
                let mlsStatus = $(this).attr('data-is-mls');
               // console.log('rowId ' + rowId + ' mlsStatus ' + mlsStatus)

            });
        }, 2000);
    }
    useEffect(() => {
        const fetchBrokerageRoleData = async () => {
            try {
                const response = await axios.post('/get-all-Brokerage-role-data');
                const { Status, StatusMessage, Error, Result } = response.data;

                if (Status === true) {
                    
                    setBrokerageRoleData(Result);
                }
            } catch (err) {
               // console.log(err);
            }
        };
        fetchBrokerageRoleData();
        const fetchStatesData = async () => {
            try {
                const response = await axios.post("/get-all-states");
                const { Status, Result } = response.data;

                if (Status === true) {
                   
                    setDbStatesName(Result);
                }
            } catch (err) {
               // console.log(err);
            }
        };
        fetchStatesData();
    }, []);
    useEffect(() => {
        if (user !== null) {
            $.fn.dataTable.ext.errMode = 'none';
            fetchedNonMatchingBrokerageData();
            fetchedDocumentMissingUsersData();
            fetchPayingChecksData(maxDate);
        }
    }, [user]);
    
    const handleView = (rowId) => {
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();

        let finalUrl = 'u=' + encodeURIComponent(userIdEncryptedString);
        navigate('/app/referral-detail?' + finalUrl);
    }
    
    const handleNetworkDetail = (rowId, mode) => {
        //alert(rowId+' '+mode)
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();

        const modeEncryptedString = AES.encrypt(JSON.stringify(mode), secretKey).toString();

        let finalUrl = 'u=' + encodeURIComponent(userIdEncryptedString) + '&m=' + encodeURIComponent(modeEncryptedString);
        //alert(finalUrl);
        navigate('/app/my-network-detail?' + finalUrl);
    }
    
    const exportExcel = (mode) => {

        var wscols = '';

        let finalData = '';
        let Heading = '';
        if(mode === 'monthly_all_users')
        {
            wscols = [
                { width: 20 },  // first column
                { width: 18 }, // second column
                
                { width: 18 }, // second column
                { width: 12 }, //...
                { width: 12 }, //...
                { width: 12 }, 
                { width: 15 },
                { width: 12 }, 
                { width: 12 }, 
                { width: 16 }, 
                { width: 17 },
                { width: 25 },
                { width: 15 },
                { width: 30 },
                { width: 12 },
                { width: 12 }
                ];
            finalData  = excelReportData;
            Heading = [['Reference Number', 'Closing Date','Payment Received On','Brokerage Name','Agent Name','User Type','Role','Sale Price','Comm %','Commission earned','Referral Fee Received','Plan When Referral was submitted','Company $','Agent/Brokerage $ Formula','Agent/Brokerage $','Paid']];
        }
        else
        {
            wscols = [
                { width: 20 },  // first column
                { width: 18 }, // second column
                
                { width: 18 }, // second column
                { width: 18 }, // second column
                { width: 12 }, //...
                { width: 12 }, //...
                { width: 12 }, 
                { width: 15 },
                { width: 12 }, 
                { width: 12 }, 
                { width: 16 }, 
                { width: 17 },
                { width: 25 },
                { width: 15 },
                { width: 30 },
                { width: 12 },
                { width: 12 }
                ];
            finalData  = excelUplineReportData;
            Heading = [['Reference Number', 'Closing Date','Payment Received On','Referring Agent','Upline Brokerage Name','Upline Agent Name','Upline User Type','Upline Role','Sale Price','Comm %','Commission earned','Referral Fee Received','Plan When Referral was submitted','Company $','Agent/Brokerage $ Formula','Agent/Brokerage $','Paid']];
        }
        
        

        let worksheet = XLSX.utils.json_to_sheet(finalData,{ origin: 'A2', skipHeader: true });
        worksheet = XLSX.utils.sheet_add_aoa(worksheet, Heading, { origin: 'A1' });
        
        worksheet["!cols"] = wscols // first column;

        worksheet["A1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },
            
                font: {
                    name: "Times New Roman",
                    sz: 14,
                    color: { rgb: "000000" },
                    bold: true,
                    italic: false,
                    underline: false
                },
                alignment:{ wrapText: true }
            };
            worksheet["B1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },    font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }
            };
            worksheet["C1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }

            };
            worksheet["D1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }
            };
            worksheet["E1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }
            };
            worksheet["F1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }
            };
            worksheet["G1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }
            };
            worksheet["H1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }
            };
            worksheet["I1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }
            };
            worksheet["J1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }
            };
            worksheet["K1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }
            };
            worksheet["L1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }
            };
            worksheet["M1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }
            };
            worksheet["N1"].s = {
            fill: {
                patternType: "solid",
                fgColor: { rgb: "C0C0C0" }
            },font: {
                name: "Times New Roman",
                sz: 14,
                color: { rgb: "000000" },
                bold: true,
                italic: false,
                underline: false
            },
            alignment:{ wrapText: true }
            };
            worksheet["O1"].s = {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "C0C0C0" }
                },font: {
                    name: "Times New Roman",
                    sz: 14,
                    color: { rgb: "000000" },
                    bold: true,
                    italic: false,
                    underline: false
                },
                alignment:{ wrapText: true }
                };
                worksheet["P1"].s = {
                    fill: {
                        patternType: "solid",
                        fgColor: { rgb: "C0C0C0" }
                    },font: {
                        name: "Times New Roman",
                        sz: 14,
                        color: { rgb: "000000" },
                        bold: true,
                        italic: false,
                        underline: false
                    },
                    alignment:{ wrapText: true }
                    };
                    
                        if(mode  === 'monthly_all_upline_users') {
                            worksheet["Q1"].s = {
                                fill: {
                                    patternType: "solid",
                                    fgColor: { rgb: "C0C0C0" }
                                },font: {
                                    name: "Times New Roman",
                                    sz: 14,
                                    color: { rgb: "000000" },
                                    bold: true,
                                    italic: false,
                                    underline: false
                                },
                                alignment:{ wrapText: true }
                                };
                        }
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        if(mode === 'monthly_all_users')
        {
            saveAsExcelFile(excelBuffer, 'total_monthly_breakdown ');
        }
        else
        {
            saveAsExcelFile(excelBuffer, 'upline_totals_by_agent');
        }
    }
    
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }
    const headerExport = (
        
            (excelReportData.length > 0) && (<div className="flex align-items-center export-buttons">
                
                <Button type="button" label="Total Monthly Breakdown" icon="pi pi-file-excel" onClick={() => {exportExcel('monthly_all_users')}} className='btn btn-submit email-temp-plus-btn mr-2' data-pr-tooltip="XLS" />
                {(excelUplineReportData.length > 0) && (<Button type="button" label="Upline Totals by Agent"  icon="pi pi-file-excel" onClick={() => {exportExcel('monthly_all_upline_users')}} className="btn btn-submit  email-temp-plus-btn mr-2" data-pr-tooltip="XLS" />)}
                
            </div>)
    );
    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <span className="image-text">{data.reference_number}</span>
            </React.Fragment>
        );
    }
    const nameTemplate = (data) => {
        return (
            <React.Fragment>
               <span className='name-title'> <a href="javascript:;" onClick={(e) => handleNetworkDetail(`${data.user_id}`,`${data.user_type}`)} className='name-link underline-ani-blue'>{data.name}</a></span>
            </React.Fragment>
        );
    }
    const handlePaidStatus = (referralId,paidStatus,recordType) => {
        // alert('referralId '+referralId+'paidStatus '+paidStatus+'recordType '+recordType)
        let message = '';
        if(paidStatus === 'Paid')
        {
            message = 'Would you like to change user status to unpaid?';
        }
        else
        {
            message = 'Would you like to change user status to paid?';
        }
        Swal.fire({
            title: paidStatus+' Status',
            html: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!'
        }).then((result) => {
            if (result.isConfirmed) {

                handleUpdatePaidStatus(referralId,paidStatus,recordType);
            }
        })
    }

    const handleUpdatePaidStatus = async (referralId,paidStatus,recordType) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('referral_id', referralId);
        formData.append('status', paidStatus);
        formData.append('type', recordType);
        
        try {
            const response = await axios.post('/update-paid-unpaid-status', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {

                fetchPayingChecksData(searchDateValue);
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    }

    const paidStatusTemplate = (data) => {
        return (
            <React.Fragment>
                {
                    (data.record_type === 'Referral Agent' || data.record_type === 'Referral Ambassador') && (<span className='name-title'>{data.paid_status}</span>)
                }
                {
                    (data.record_type !== 'Referral Agent' && data.record_type !== 'Referral Ambassador') && (<span className='name-title'> <a href="javascript:;" onClick={(e) => handlePaidStatus(`${data.referral_id}`,`${data.paid_status}`,`${data.record_type}`)} className='name-link underline-ani-blue'>{data.paid_status}</a></span>)
                }
            </React.Fragment>
        );
    }
    
    const referenceNumberTemplate = (data) => {
        return (
            <React.Fragment>
               <span className='name-title'> <a href="javascript:;" onClick={(e) => handleView(`${data.referral_id}`)} className='name-link underline-ani-blue'>{data.reference_number}</a></span>
            </React.Fragment>
        );
    }
    const handleSearchDate= (e) => {
        setSearchDateValue(e.target.value);
        fetchPayingChecksData(e.target.value);
    }
    const allowExpansion = (rowData) => {
        return rowData.tier_counts >= 1;
    };
    const Capitalize = (data) =>{
        return data.user_type.charAt(0).toUpperCase() + data.user_type.slice(1);
        }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <DataTable value={data.reference_data} responsiveLayout="scroll">
                    <Column field="brokerage_name" header="Upline Agent Brokerage" ></Column>
                    <Column field={nameTemplate} header="Upine Agent Name" ></Column>
                    <Column field={Capitalize} header="User Type" ></Column>
                    <Column field="record_type" header="Role"></Column>
                    
                    <Column field="price" header="Agent/Brokerage $"></Column>
                    <Column field="referral_paid_date" header="Commission Received Date" ></Column>
                    <Column field={paidStatusTemplate} header="Paid Status"></Column>
                </DataTable>
            </div>
        );
    }
    return (
        <>
            <section className="table-component no-tabs sec-pt sec-pb">
                <div className="container-fluid">
                    <div className="table-search mb-4">
                        <h2 className="main-heading mb-0">
                            Reports
                        </h2>

                        <div className="tableSearch referral-list-search">
                            {/* <span className="p-input-icon-right document_right">
                                <select id="documents_search_type" className="document_dropdown" style={{display:`${isShowSearch}` }}>
                                    <option value="all">All</option>
                                    <option value="document_exits">Document exists</option>
                                    <option value="document_not_exits">Document not exists</option>
                                </select>
                                <i class="pi pi-angle-down"></i>
                            </span> */}
                            <span className="p-input-icon-right" >
                                <InputText  autoComplete="off" id='searchbarTable' className="searchbarTable mynetwork-search mynet-table" placeholder='Search by name, upline, email or phone' 
                                style={{display:`${isShowSearch}` }}
                                />
                                {
                                    (!showClearSearch) && (<i className="pi pi-search" style={{display:`${isShowSearch}` }}/>)
                                }
                                {
                                    (showClearSearch) && (<a className="close-icon" href="javascript:;" onClick={handleClearSearch} ><i className="pi pi-times" style={{display:`${isShowSearch}` }}/></a>)
                                }
 {
                                (isShowSearch === 'none') && (
                                <Calendar
                                     maxDate={maxDate}
                                    view="month" dateFormat="mm/yy"
                                    placeholder="MM/DD/YY"
                                    name="closing_date"
                                    value={searchDateValue} onChange={(e) => {  handleSearchDate(e)}}
                                    InputClassName="form-control"
                                    showIcon />)
                                }

                            </span>
                            
                            
                            
                            {/* <span className="p-input-icon-right">
                                <InputText  autoComplete="off" id='searchbarTable' className="searchbarTable"  placeholder="Search by reference number"
                                title="Search by reference number" />
                                {
                                    (!showClearSearch) && (<i className="pi pi-search" />)
                                }
                                
                                {
                                    (showClearSearch) && (<a className="close-icon" href="javascript:;"  ><i className="pi pi-times" /></a>)

                                }
                            </span> */}
                        </div>
                        
                    </div>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active reportTabClass" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" onClick={() => {handleTab('report')}}>Checks </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link ambassadorTabClass" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={() => {handleTab('ambassador')}}>Ambassadors No Matching Broker <span id="spanAmbassadorCount">(0)</span></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link documentMissingTabClass" id="document-missing-tab" data-bs-toggle="tab" data-bs-target="#document-missing-tab-pane" type="button" role="tab" aria-controls="document-missing-tab-pane" aria-selected="false" onClick={() => {handleTab('document_missing_docs')}}>Document Missing Users <span id="spanDocumentMissingCount">(0)</span></button>
                        </li>
                    </ul>
                    {errorMessage && (
                            <Alert
                                sx={{ mb: 3 }}
                                severity="error"
                                variant="filled"
                            >
                                {errorMessage}
                            </Alert>
                        )}

                        <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                        
                        <DataTable value={reportReferencewiseData} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} responsiveLayout="scroll"
                                    rowExpansionTemplate={rowExpansionTemplate}  dataKey="referral_id" header={headerExport}>

                            <Column expander={allowExpansion} style={{ width: '3em' }} />
                            <Column field={referenceNumberTemplate} header="Reference Number"></Column>
                                
                            <Column field="final_sales_price" header="Sale Price" ></Column>
                            <Column field="final_closing_date" header="Closing Date"></Column>
                            <Column field="payment_received_at" header="Payment Received On"></Column>
                            <Column field="brokerage_name" header="Referring Brokerage Name"></Column>
                            <Column field={nameTemplate} header="Referring Agent Name" ></Column>
                            <Column field="price" header="Agent/Brokerage $"></Column>
                            <Column field={paidStatusTemplate} header="Paid Status"></Column>
                        </DataTable>
                    </div>
                        <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                            <table className="short-table display nowrap networkTable agentTable" cellSpacing="0" style={width} id="networkTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Up Line </th>
                                        <th>Phone </th>
                                        <th>Broker License Info</th>
                                        <th>Created On</th>
                                        <th className='hide_sorting'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="document-missing-tab-pane" role="tabpanel" aria-labelledby="document-missing-tab" tabIndex="0">
                            <table className="short-table display nowrap networkTable documentMissingTable" cellSpacing="0" style={width} id="networkTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Up Line </th>
                                        <th>Type </th>
                                        <th>Phone</th>
                                        <th>Created On</th>
                                        <th className='hide_sorting'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                    <div>
                    
                       
                    </div>
                </div>
                
            </section>
            {
                (showEditUser) && (<EditUser showEditUser={showEditUser} changeStatusEditUser={changeStatusEditUser} reloadListing={reloadListing} editRowId={editRowId} editRowType={editRowType} dbStatesName={dbStatesName} dbBrokerageRoleData={dbBrokerageRoleData} callFrom={callFrom}/>)
            }
        </>
    );
};

export default PayingChecks;
