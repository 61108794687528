import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Alert, CircularProgress, Typography } from "@mui/material";
import axios from "../../axiosCall.js";
import { IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Modal from 'react-bootstrap/Modal';
import useAuth from "./../../hooks/useAuth";
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate
} from './UpdatePlan/cardUtils';
import { Form, Field } from 'react-final-form'

const EditCreditCard = (props) => {
    const [show, setShow] = useState(props.showEditCreditCard);
    const [shows, setShows] = useState(false);
    const { user, setUserDataCall, logout } = useAuth();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
  
    const [successMessage, setSuccessMessage] = useState('');
    const [movelicense, setMovelicense] = useState(false);
    const [numberCall, setNumberCall] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const modalClose = () => setShow(false);

    const fetchedCreditCardInfo = async () => {
        let formData = new FormData();
        formData.append("users_id", props.loginUserId);
        setErrorMessage('');
        try {
            const response = await axios.post("/get-user-credit-card-info", formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {
                let cardId = Result.data[0]['id'];
                let expMonth = Result.data[0]['exp_month'];
                let expYear = Result.data[0]['exp_year'];
                let last4 = Result.data[0]['last4'];

            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate("/login");
            }
        }
    };
    useEffect(() => {
        if (user !== null) {
            fetchedCreditCardInfo();
        }

    }, [user]);
    const handleSubmitCreditCard = async (data) => {
        setLoading(true);

        let dataSend = {
            name: data.name,
            cvc: data.cvc,
            product_id: props.favorite,
            number: data.number,
            expiry: data.expiry,
            user_id: props.userId
        }
        try {
            const response = await axios.post('/update-subscription', dataSend).then(function (response) {
                const { Status, StatusMessage, Error, Result } = response.data ? response.data : [];
                let finalApiError = '';
                if (Status === false) {
                    // if (Error.length > 0) {
                    //     // Error.map(
                    //     //     (errorMessage) =>
                    //     //         finalApiError += '<li>' + errorMessage + '</li>'
                    //     //             (finalApiError = errorMessage)
                    //     // );
                    // } else {
                    finalApiError = StatusMessage;
                    // }
                    setErrorMessage(finalApiError);
                    setLoading(false);
                } else if (Status === true) {
                    setLoading(false);
                    
                    setUserDataCall(Result.user);
                    // navigate('/edit-profile');
                    //props.modalClose()
                    //props.changeStatuClosedhandleModal()
                }
            }).catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    setErrorMessage(error.response.data.message);
                    setLoading(false);

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    setErrorMessage(error.message);
                    setLoading(false);
                }
                console.log(error.config);
            });

        } catch (err) {
            //console.log(err.toJSON());
            // setErrorMessage(err.message);
            // setLoading(false);
            if (err.response) {
                // The client was given an error response (5xx, 4xx)
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else if (err.request) {
                // The client never received a response, and the request was never left
            } else {
                // Anything else
                console.log('Error', err);
            }
        }
    };



    return (
        <>
           <Modal enforceFocus={false} className="add-people-popup make_payment successOpen" show={show} onHide={modalClose} backdrop="static" >

            <Modal.Header className='add-people-header'>
                        <Modal.Title>{(shows == true)?"Enter your credit card details":"Pick your plan"}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className='subscription-plan-section'>
                    <div className="container">
                <div className="referral-container">
                    {/* <h2 className='main-heading'>Subscription</h2> */}
                    <div className="cards-container row row-cols-1 row-cols-lg-2 g-1">
                        <div className="col col-left">
                            <Card className="subs-card-left">

                                <Form
                                    onSubmit={handleSubmitCreditCard}

                                    render={({
                                        handleSubmit,
                                        form,
                                        submitting,
                                        pristine,
                                        values,
                                        active
                                    }) => {
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <Card.Body>
                                                    <div className="card-container">

                                                        <div className='referral-fields credit_card_page'>
                                                            {/* <p className="title sub-titles">Enter your credit card details</p> */}

                                                            {errorMessage && (
                                                                <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                                                    {errorMessage}
                                                                </Alert>
                                                            )}
                                                            <div className="field credit-field">
                                                                {/* <p className="sub-titles-amt" >Total Amount : ${finalPrice}.00 </p> */}
                                                                <label htmlFor="FirstInput" className="form-label">Cardholder Name </label>
                                                                <Field
                                                                    name="name"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder="Enter Here e.g. 'RIVER DEE'"
                                                                    pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                                                                    patternMismatch="Capitalize your name!"
                                                                    required
                                                                    className="form-control p-inputtext"
                                                                    parse={value => {
                                                                        // Do what you want with `value`
                                                                        return value.toUpperCase().replace(/[^a-zA-Z ]/g, "");
                                                                    }}

                                                                />
                                                            </div>
                                                            <div className="field credit-field">
                                                                <label htmlFor="LastInput" className="form-label">Card Number</label>
                                                                <Field
                                                                autocomplete="cc-csc"
                                                                    name="number"
                                                                    component="input"
                                                                    type="text"
                                                                    pattern="[\d| ]{16,22}"
                                                                    placeholder="Enter Here "
                                                                   required
                                                                   format={formatCreditCardNumber}
                                                                    className="form-control p-inputtext"
                                                                />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="field credit-field">
                                                                        <label htmlFor="FirstInput" className="form-label">Expiry Date</label>
                                                                        <Field
                                                                            name="expiry"
                                                                            component="input"
                                                                            type="text"
                                                                            pattern="\d\d/\d\d\d\d"
                                                                            placeholder="MM/YYYY e.g. '04/2045'"
                                                                            required
                                                                            format={formatExpirationDate}
                                                                            className="form-control p-inputtext"
                                                                            parse={value => {
                                                                                // Do what you want with `value`
                                                                                const changeValue = value.replace(/\//g, "");
                                                                                return changeValue.slice(0, 2) + (changeValue.length > 2 ? "/" : "") + changeValue.slice(2, 7);
                                                                            }}

                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="field credit-field">
                                                                        <label htmlFor="LastInput" className="form-label">CVC/CVV</label>
                                                                        <Field
                                                                            name="cvc"
                                                                            component="input"
                                                                            type="password"
                                                                            pattern="\d{3,4}"
                                                                            placeholder="Enter Here"
                                                                            format={formatCVC}
                                                                            required
                                                                            className="form-control p-inputtext"

                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </Card.Body>

                                                <Card.Footer className="d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        class="btn btn-cancel btn-700 me-3"
                                                        onClick={() => { modalClose(); props.changeStatusEditCreditCard(); }}
                                                        disabled={loading}
                                                    >
                                                        Cancel

                                                    </button>


                                                    <button type="submit" class="btn  btn-submit subs-pay-btn btn-submit-loader" disabled={loading}>
                                                        {loading ? "Updating" : "Update"}
                                                        {loading && (<Typography align="center">
                                                            <CircularProgress value={30} />
                                                        </Typography>)}
                                                    </button>
                                                </Card.Footer>
                                            </form>

                                        )
                                    }} />

                            </Card>
                        </div>
                        
                    </div>
                </div>
            </div>

                    </Modal.Body>
                    
            </Modal>
        </>
    );
};

export default EditCreditCard;
