import React, { useState, useEffect } from "react";
import { NavLink,useLocation,useNavigate } from "react-router-dom";
import axios from "../../../axiosCall.js";
import { Alert, CircularProgress, Typography } from "@mui/material";
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

const ResetPasswordRequest = () => {
  const secretKey = 'REFR2022@GALAXY';
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [resetPasswordLink, setResetPasswordLink] = useState(false);
  const search = useLocation().search;
  const verificationCode = new URLSearchParams(search).get('c');
  const [userEncryptLink, setUserEncryptLink] =  useState('');

  
  useEffect(() => {

      const verifyUser = async () => {
        setErrorMessage('');
        setSuccessMessage('');
        setLoader(true);
        setResetPasswordLink(false);
        try {
          const response = await axios.post(`/verify-reset-password-link/${verificationCode}`);
          const { Status, StatusMessage, Error, Result } = response.data;
          let finalApiError = "";
          setLoader(false);
          if (Status === false) {
            if (Error.length > 0) {
              Error.map(
                (errorMessage) =>
                  //finalApiError += '<li>'+errorMessage+'</li>'
                  (finalApiError = errorMessage)
              );
            } else {
              finalApiError = StatusMessage;
            }
            setErrorMessage(finalApiError);
          } else {

            const userIdEncryptedString = AES.encrypt(JSON.stringify(Result.user_id), secretKey).toString();

            setUserEncryptLink(encodeURIComponent(userIdEncryptedString));
            
            setResetPasswordLink(true);
          }
        } catch (err) {
          // console.log(err);
          setErrorMessage(err);
          setLoader(false);
        }
      };
    
    if ( verificationCode === null ) 
    {
        navigate('/login');
    } else {
      verifyUser();
    }
  }, []);

  return (
    <>
      <div className="reset-section">
        <div className="reset-section-in">
          <div className="main-body">
            <div className="popup-logo">
              <img src="./assets/images/Mask-group.png" alt="img" />
            </div>
            <div className="reset-items">
              <h2 className="reset-title">Reset Password</h2>
              <p className="reset-content">
                We got a request to your reset password
              </p>
              {errorMessage && (
                <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                {errorMessage}
                </Alert>
              )}
              {loader && (<Typography align="center">
                      <CircularProgress value={66} />
              </Typography>)}
              {errorMessage && (<NavLink
                to="/forgot-password"
                className="btn btn-primary reset-btn"
              >
                Forgot Password
              </NavLink>)}

              {/* <button type="button" class="btn btn-primary reset-btn">Reset your Password</button> */}
              {(resetPasswordLink) && (userEncryptLink) && (<NavLink
                to={`/reset-password?u=${userEncryptLink}`}
                className="btn btn-primary reset-btn"
              >
                Reset your Password
              </NavLink>)
              }
              
              <p className="reset-message">
                If you did not request a password reset, you can safely ignore
                this email, Only a person with access to your email can reset
                your accoutnt password.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordRequest;
