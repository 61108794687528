import React, { useState, useEffect,useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useLocation } from "react-router-dom";
import { Editor, InputText } from 'primereact';
import { Checkbox } from 'primereact';
import Button from 'react-bootstrap/Button';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { Dropdown } from 'primereact/dropdown';
import Swal from 'sweetalert2';

const EmailTemplate = () => {
    const secretKey = "REFR2022@GALAXY";
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get('u');
    const editDataId = new URLSearchParams(search).get('e');
    let requestUserId = '';
    let isEditMode = false;
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [dbEmailTemplate, setDbEmailTemplate] = useState([]);
    const mediaRef = useRef('');
    if (userId === null && editDataId === null) {
        navigate('/dashboard');
    } else {
        //alert('else');
        if(userId)
        {
            let userIdDecrypt = AES.decrypt(userId, secretKey);
            if (userIdDecrypt == "") {
                
                navigate('/dashboard');
            }
            else {
                isEditMode = false;
                requestUserId = JSON.parse(userIdDecrypt.toString(Utf8));
            }
        }
        else if(editDataId)
        {
            let idDecrypt = AES.decrypt(editDataId, secretKey);
            if (idDecrypt == "") {
                navigate('/dashboard');
            }
            else {
                isEditMode = true;
                requestUserId = JSON.parse(idDecrypt.toString(Utf8));
            }
        }
        else
        {
            navigate('/dashboard');
        }
    }
    const { user, logout } = useAuth();

    const fetchUserDetailData = async () => {
        setErrorMessage("");
        let formData = new FormData();
        formData.append('user_id', requestUserId);
        try {
            const response = await axios.post("/get-user-data", formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                formik.setFieldValue("user_first_name", Result.user.first_name);
                formik.setFieldValue("user_full_name", Result.user.name);
                formik.setFieldValue("email", Result.user.email);
                formik.setFieldValue("users_id", Result.user.id);
                
                if(Result.user.upline_users_id)
                {
                    let uplineUserFirstName = Result.user.upline_users_id.name.split(' ');
                    formik.setFieldValue("upline_first_name", uplineUserFirstName[0]);
                }
            }
            else {
                navigate('/dashboard');
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } 
        }
    };

    
    useEffect(() => {
        const elements = document.getElementsByClassName("ql-image");
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
        if (user !== null) {

            const fetchEmailTemplateData = async () => {
                
                try {
                    const response = await axios.post("/get-admin-email-template-data");
                    const { Status, StatusMessage, Error, Result } = response.data;

                    if (Status === true) {
                        setDbEmailTemplate(Result);
                    }
                } catch (err) {
                    if (err.response.status && err.response.status == 401) {
                        logout();
                        navigate('/login');
                    } 
                }
            };

            fetchEmailTemplateData();
            
            if(isEditMode)
                fetchEditData();
            else
                fetchUserDetailData();
        }
    }, [user, requestUserId]);


    const formik = useFormik({
        initialValues: {
            edit_id: '',
            users_id: '',
            email_template_id: '',
            email: '',
            attachment_email_template_db:'',
            attachment_edit_db:'',
            attachment:'',
            user_first_name: '',
            upline_first_name:'',
            user_full_name: '',
            subject: '',
            message: '',
            host_name: window.location.origin,
        },
        validate: (data) => {
            let errors = {};

            if (!data.subject) {
                errors.subject = 'Subject is required.';
            }
            
            if (data.message === null) {
                errors.message = 'Message is required.';
            }
            else if (!data.message) {
                errors.message = 'Message is required.';
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    const fetchEditData = async () => {
        let formData = new FormData();
        formData.append('admin_send_email_to_user_log_id', requestUserId);
        try {
            const response = await axios.post("/get-admin-send-email-to-user-log", formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                formik.setFieldValue("edit_id", Result[0].id);
                formik.setFieldValue("email", Result[0].email);
                formik.setFieldValue("subject", Result[0].subject);
                formik.setFieldValue("message", Result[0].message);
                formik.setFieldValue("users_id", Result[0].users_id);
                formik.setFieldValue("attachment_edit_db", Result[0].attachment);
                formik.setFieldValue("email_template_id", Result[0].email_template[0]);

                requestUserId = Result[0].users_id;
                fetchUserDetailData();
            }
            else {
                navigate('/dashboard');
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } 
        }
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const onFileChanged = (event) => {
        // alert(event.files[0])
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];
            let fileSize = file.size;
            const imageSizeInMb = Math.round((fileSize / 1024));
            let fileName = event.target.files[0].name;
            let splitName = fileName.split('.');

            if (imageSizeInMb >= 2048) {
                mediaRef.current.value = '';
                Swal.fire({
                    title: '',
                    text: "Please upload the file with a size of not more than 2 MB.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })

                //Swal.fire('Please upload the file with a size of not more than 2 MB.');
            }
            else if (file.type !== 'application/pdf' && file.type !== 'application/msword'  && file.type !== 'application/wps-office.docx' && file.type !== 'application/wps-office.doc' && file.type !== 'application/ms-doc' && file.type !== 'application/doc' && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && splitName[1] !== 'bmp' && splitName[1] !== 'jpe' && splitName[1] !== 'jfif' && splitName[1] !== 'webp' && splitName[1] !== 'jpg' && splitName[1] !== 'jpeg' && splitName[1] !== 'png' && splitName[1] !== 'gif' && splitName[1] !== 'BMP' && splitName[1] !== 'JPE' && splitName[1] !== 'JFIF' && splitName[1] !== 'WEBP' && splitName[1] !== 'JPG' && splitName[1] !== 'JPEG' && splitName[1] !== 'PNG' && splitName[1] !== 'GIF')  {
                mediaRef.current.value = '';
                Swal.fire({
                    title: '',
                    html: file.name + " has an invalid extension.<br> Valid extension(s): bmp, gif, jpeg, jpg, jpe, jfif, png, webp, pdf, doc, docx.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })
                //Swal.fire('Please selects the file with valid extension i.e. *.pdf.');
            }
            else {
                //alert(event.target.files[0])
                formik.setFieldValue(
                    "attachment",
                    event.target.files[0]
                );
            }
        }

    };
    const handleDeleteDocuments = (rowId, mode, media) => {
        Swal.fire({
            title: 'Are you sure?',
            html: "You won't be able to reverse this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                handleDeleteDocumentsCall(rowId, mode, media);
            }
        })
    }
    const handleDeleteDocumentsCall = async (rowId, mode, media) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('email_template_id', rowId);
        formData.append('mode', 'single_email_send');
        formData.append('media', media);

        try {
            const response = await axios.post('/delete-email-attachment-docs', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                formik.setFieldValue("attachment", "");
                formik.setFieldValue("attachment_edit_db", "");
                formik.setFieldValue("attachment_email_template_db", "");
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    }
    const handleAddNewDocument = () => {
        formik.setFieldValue("attachment", "");
        formik.setFieldValue("attachment_edit_db", "");
        formik.setFieldValue("attachment_email_template_db", "");
    }
    const handleTemplateMessage = (event) => {
        if (event.htmlValue !== null) {
            formik.setFieldValue("message", event.htmlValue);
        }
        else {
            formik.setFieldValue("message", "");
        }
    }
    const handleEmailChange = (e) => {
        if(e.target.value.id)
        {
            let finalSubject = e.target.value.subject;
            if(finalSubject.indexOf('%agent_first_name%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%agent_first_name%", formik.values.user_first_name);
            }
            if(finalSubject.indexOf('%ambassador_first_name%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%ambassador_first_name%", formik.values.user_first_name);
            }
            if(finalSubject.indexOf('%upline_first_name%') !== -1)
            {
                if(formik.values.upline_first_name)
                    finalSubject = finalSubject.replaceAll("%upline_first_name%", formik.values.upline_first_name);
            }
            if(finalSubject.indexOf('%assigned_agent%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%assigned_agent%", formik.values.user_full_name);
            }
            if(finalSubject.indexOf("%assigned_agent's_Broker%") !== -1)
            {
                finalSubject = finalSubject.replaceAll("%assigned_agent's_Broker%", formik.values.user_full_name);
            }
            if(finalSubject.indexOf('%agent_name%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%agent_name%", formik.values.user_full_name);
            }
            if(finalSubject.indexOf('%ambassador_name%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%ambassador_name%", formik.values.user_full_name);
            }
            if(finalSubject.indexOf('%downline_full_name%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%downline_full_name%", formik.values.user_full_name);
            }
            
            if(finalSubject.indexOf('%refr_signup_page%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%refr_signup_page%", window.location.origin+'/signup');
            }

            if(finalSubject.indexOf('%refr_login_page%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%refr_login_page%", window.location.origin+'/login');
            }

            formik.setFieldValue("subject", finalSubject);

            let finalMessage = e.target.value.message;
            if(finalMessage.indexOf('%agent_first_name%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%agent_first_name%", formik.values.user_first_name);
            }
            if(finalMessage.indexOf('%ambassador_first_name%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%ambassador_first_name%", formik.values.user_first_name);
            }
            if(finalMessage.indexOf('%upline_first_name%') !== -1)
            {
                if(formik.values.upline_first_name)
                    finalMessage = finalMessage.replaceAll("%upline_first_name%", formik.values.upline_first_name);
            }
            
            if(finalMessage.indexOf('%assigned_agent%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%assigned_agent%", formik.values.user_full_name);
            }
            if(finalMessage.indexOf("%assigned_agent's_Broker%") !== -1)
            {
                finalMessage = finalMessage.replaceAll("%assigned_agent's_Broker%", formik.values.user_full_name);
            }
            if(finalMessage.indexOf('%agent_name%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%agent_name%", formik.values.user_full_name);
            }
            if(finalMessage.indexOf('%ambassador_name%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%ambassador_name%", formik.values.user_full_name);
            }
            if(finalMessage.indexOf('%downline_full_name%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%downline_full_name%", formik.values.user_full_name);
            }
            if(finalMessage.indexOf('%refr_signup_page%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%refr_signup_page%", window.location.origin+'/signup');
            }

            if(finalMessage.indexOf('%refr_login_page%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%refr_login_page%", window.location.origin+'/login');
            }
            formik.setFieldValue("message", finalMessage);
            if(e.target.value.attachment)
            {
                formik.setFieldValue("attachment_email_template_db", e.target.value.attachment);
                formik.setFieldValue("attachment_edit_db", "");
            }
            else
            {
                formik.setFieldValue("attachment_email_template_db", "");
                formik.setFieldValue("attachment_edit_db", "");
            }
        }
    }
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        let formData = new FormData();
        let file = data.attachment;
        formData.append('file', file)
        formData.append('data', JSON.stringify(data))
        try {
            const response = await axios.post('/add-admin-send-email-to-user', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                setLoading(false);
                const idEncryptedString = AES.encrypt(JSON.stringify(Result.admin_send_email_to_user_log_id), secretKey).toString();

                let finalUrl = 'u=' + encodeURIComponent(idEncryptedString);

                navigate('/app/preview-email-template?' + finalUrl);
            }
        } catch (err) {

            setLoading(false);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                setErrorMessage("Something went wrong");
                
            }
        }
    };

    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }
    const optionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    }
    
    return (
        <>
            <section className='emailTemplate-component sec-pt sec-pb'>

                <div className="container">

                    <div className="email-container">
                        <h2 className='main-heading'>Email </h2>
                        {errorMessage && (
                            <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                {errorMessage}
                            </Alert>
                        )}
                        <div className="form-container">

                            <form onSubmit={formik.handleSubmit}>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="field">
                                            <label htmlFor="to" className="form-label">To <span className="reuired-star">*</span></label>
                                            <InputText type="text"
                                                autoComplete="off"
                                                placeholder="Enter here"
                                                aria-label="Text input with dropdown button"
                                                name="email"
                                                disabled={true}
                                                value={formik.values.email}
                                                className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="field ">
                                            <label htmlFor="to" className="form-label">Email Template </label>
                                            <Dropdown
                                                name="email_template_id"
                                                value={
                                                    formik.values.email_template_id
                                                }
                                                options={
                                                    dbEmailTemplate
                                                }
                                                optionLabel="name"
                                                filter
                                                filterBy="name"
                                                className="form-select"
                                                placeholder="Select a Name"
                                                valueTemplate={
                                                    selectedTemplate
                                                }
                                                itemTemplate={
                                                    optionTemplate
                                                }
                                                onChange={(e) => {formik.handleChange(e);handleEmailChange(e)}}
                                            />

                                        </div>

                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="form-label">Email Subject <span className="reuired-star">*</span></label>
                                    <div className="p-inputgroup">
                                        <InputText type="text"
                                            autoComplete="off"
                                            placeholder="Enter here"
                                            aria-label="Text input with dropdown button"
                                            name="subject"
                                            value={formik.values.subject} onChange={formik.handleChange}
                                            className={classNames("form-control email-input", { 'p-invalid': isFormFieldValid('subject') })} />
                                       
                                    </div>
                                    {getFormErrorMessage('subject')}
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="form-label">Message <span className="reuired-star">*</span></label>
                                    {/* <ReactQuill
                                        style={{ height: '150px' }}
                                        theme="snow"
                                        name="message"
                                        value={formik.values.message}
                                        placeholder={"Write something awesome..."}
                                        modules={modules}
                                        formats={formats}
                                        onChange={handleTemplateMessage}
                                    /> */}
                                    <Editor placeholder="Enter Here" style={{ height: '199px' }} name="message"
                                        value={formik.values.message} onTextChange={(e) => { handleTemplateMessage(e) }} />
                                        {getFormErrorMessage('message')}
                                </div>
                                <div className="row">
                                {
                                    (!formik.values.attachment_email_template_db && !formik.values.attachment_edit_db) && (<div className="col-lg-6">
                                        <div className="field upload-file">
                                            <label htmlFor="UploadInput" className="form-label">Upload Attachment</label>
                                            <div className='custom-file d-flex'>
                                                <input type="file" ref={mediaRef} className="custom-file-input"
                                                    onChange={onFileChanged}
                                                    id="customFileLang" lang="in" />
                                                <label className="custom-file-label btn" for="customFileLang">Browse </label>
                                            </div>
                                            <div className='pdf-condi'>
                                                <span>Max Size 2MB</span>
                                            </div>
                                        </div>
                                    </div> )
                                        }
                                    {
                                    (formik.values.attachment_email_template_db || formik.values.attachment_edit_db) && (<div className="col-lg-6">
                                    <div className="field upload-file email_upload_file">
                                    <label htmlFor="UploadInput" className="form-label">Upload Attachment</label>
                                        <a href={(formik.values.attachment_email_template_db) ? formik.values.attachment_email_template_db : formik.values.attachment_edit_db } target="_blank" className="btn outlined-btn preview-btn">Preview</a>
                                        
                                        {
                                            (formik.values.attachment_email_template_db) && (<a href='javascript:;' className="btn outlined-btn" onClick={()=>{handleAddNewDocument()}}>Delete</a>)
                                        }
                                        {
                                            (formik.values.attachment_edit_db) && (<a href='javascript:;' className="btn outlined-btn" onClick={() => { handleDeleteDocuments(formik.values.edit_id,'attachment', formik.values.attachment_edit_db) }}>Delete</a>)
                                        }
                                            
                                        </div>
                                    </div>)
                                    }
                                </div>
                                <div className="field text-center email-btns email-preview-btn">
                                    <button className="btn btn-cancel me-3" type="button" disabled={loading} onClick={(e) => navigate('/app/my-network')}>Cancel</button>
                                    <button className="btn btn-submit btn-submit-loader" type="submit" disabled={loading}>Preview Email
                                        {loading && (<Typography align="center">
                                            <CircularProgress value={66} />
                                        </Typography>)}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="email-template-letter">
                            <img src="assets/images/Open-Email.png" alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EmailTemplate