import React, { useState, useEffect } from "react";
import { Outlet  } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { useNavigate,NavLink } from "react-router-dom";

const BaseLayout = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  //alert('user ::: '+user)
 
  let userData = localStorage.getItem('user_data')
  //alert('in base layout userData ::: '+userData)
  useEffect(() => {
    if(userData !== null && userData !== undefined)
    {
        //alert('in base layout userData ::: '+userData)
        navigate('/dashboards');
    }
  }, []);
  return (
    <div>
    <header className='dashboard-header'>
        <nav className="navbar navbar-expand-lg bg-light p-0">
            <div className="container-fluid navbar-container">
                <NavLink className="navbar-brand" to="/login"><img src="/assets/images/dashLogo.png" alt="" /></NavLink>
            </div>
        </nav>
    </header>
    <Outlet />
    </div>
  )
}

export default BaseLayout