import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LogoUI from "../LogoUI";
// import { Button } from 'primereact/button';
import { IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessfullyRegistered = () => {
    const navigate = useNavigate();
    const redirectToLogin = () => {
        //alert(1)
        navigate('/login');
    }
    return (
        <>
            <section className="form-section auth-bg">
                <div className="container-fluid">
                    <div className="form-container">
                        <div className="form-wrapper">
                            <form>
                                <div className="back-sign">
                                    {/* <NavLink to="/set-password"><img src="./assets/images/back-icon.svg" alt="" /></NavLink> */}
                                </div>
                                <h1 className="signup-heading my-2">Success</h1>

                                <p className="signup-desc changedPassword-desc mb-4">
                                    <span>
                                        <IconButton>
                                            <CheckCircleIcon />
                                        </IconButton>
                                    </span>
                                    You have successfully registered with <span>REFR</span>.
                                </p>

                                <div className="form-group">
                                    <button className="btn form-btn" type="button" onClick={redirectToLogin}>Continue to Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SuccessfullyRegistered;