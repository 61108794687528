// import React,{ Suspense, lazy } from 'react';
import React,{useEffect,useState} from 'react';
import { Navigate } from "react-router-dom";
import Layout from './layouts/Layout';
import BaseLayout from './layouts/BaseLayout';
import useAuth from './hooks/useAuth';
import { useNavigate } from "react-router-dom";
// import SuspenseLoader from './components/SuspenseLoader';
import Login from './components/Authentication/Login/Login';
import ForgotPassword from './components/Authentication/Login/ForgotPassword';
import ResetPasswordRequest from './components/Authentication/Login/ResetPasswordRequest';
import ResetPassword from './components/Authentication/Login/ResetPassword';
import SubscriptionPlan from './components/Authentication/Signup/SubscriptionPlan';
import PlanSelection from './components/Authentication/Signup/PlanSelection';
import MakePayment from './components/Authentication/Signup/MakePayment';
import PasswordChanged from './components/Authentication/Login/PasswordChanged';
import Signup from './components/Authentication/Signup';
import VerifyEmail from './components/Authentication/Signup/VerifyEmail';
import SuccessfullyRegistered from './components/Authentication/Signup/SuccessfullyRegistered';
import SuccessfullyPlanPurches from './components/Authentication/Signup/SuccessfullyPlanPurches';
import SetPassword from './components/Authentication/Signup/SetPassword';
import Dashboard from './components/Dashboard';
import EditProfile from './components/Profile/EditProfile';
import EmailTemplate from './components/EmailTemplate/EmailTemplate';

import MassEmailTemplate from './components/EmailTemplate/MassEmailTemplate';
import EmailCheck from './components/EmailTemplate/EmailCheck';
import PreviewEmail from './components/EmailTemplate/PreviewEmail';
import PreviewEmailForMassEmail from './components/EmailTemplate/PreviewEmailForMassEmail';
import EmailModal from './components/EmailTemplate/EmailModal';
import EmailTemplateList from './components/EmailTemplate/List';

import Status404 from './components/Status/Status404';
import Status500 from './components/Status/Status500';
import StatusComingSoon from './components/Status/ComingSoon';
import StatusMaintenance from './components/Status/Maintenance';
import MyNetwork from './components/MyNetwork/MyNetwork';

import EmailTemp from './components/EmailTemp';

import LicenceDetails from './components/MyNetwork/LicenceDetails';
import PeopleList from './components/People/PeopleList';
import PeopleDetail from './components/People/PeopleDetail';
import UnsubscribePage from './components/People/UnsubscribePage';

import SubmitAReferral from './components/Referral/SubmitAReferral';
import SubmitAReferralUpdate from './components/Referral/SubmitAReferralUpdate';
import ReferralListing from './components/Referral/ReferralListing';
import ReferralListingAdmin from './components/Referral/ReferralListingAdmin';

import ReferralEmailTemplate from './components/Referral/Email/EmailTemplate';
import ReferralPreviewEmail from './components/Referral/Email/PreviewEmail';


import ReferralStatusUpdate from './components/Referral/Email/ReferralStatusUpdate';

import PayingChecks from './components/Report/PayingChecks';
import PolicyContentSetting from './components/Setting/PolicyContentPage';

import PeopleListPopup from './components/PeopleListPopup';
import Subscription from './components/Subscription';
import CreditCard from './components/CreditCard';
import { ReferralDetailsFinal } from './components/Referral/ReferralDetailsFinal';
import ReferralEmailCommunication from './components/Referral/Email/ReferralEmailCommunication';
import DeletePopup from './components/DeletePopup';
import RegisterConfirm from './components/RegisterConfirm';
// import { ReferralDetailsPageRedirect } from './components/Referral/ReferralDetailsPageRedirect';

function PrivateRoute ({Component}) {
  const { user } = useAuth();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (user !== null) {

        if((window.location.pathname === '/app/submit-a-referral' || window.location.pathname === '/app/submit-a-referral-update') && user.type !== 'admin')
        {
          setIsValid(true);
          if(user.type === 'agent' && user.is_license_flag === 0)
          {
            setIsValid(false);
          }
        }
        else if(window.location.pathname === '/app/referral-detail' || window.location.pathname === '/app/referral-detail-page' || window.location.pathname === '/app/my-network' || window.location.pathname === '/app/my-network-detail' || window.location.pathname === '/app/licence-details')
        {
          setIsValid(true);
          if(user.type === 'agent' && user.is_license_flag === 0)
          {
            setIsValid(false);
          }
        }
        else if(window.location.pathname === '/app/referral-list' ||  window.location.pathname === '/app/people-list' || window.location.pathname === '/app/people-detail')
        {
          setIsValid(true);
          if(user.type === 'agent' && user.is_license_flag === 0)
          {
            setIsValid(false);
          }
          if(user.type === 'admin')
          {
            setIsValid(false);
          }
        }
        else if(window.location.pathname === '/app/email-template-list' || window.location.pathname === '/app/email-template' || window.location.pathname === '/app/mass-email' || window.location.pathname === '/app/preview-email-template' || window.location.pathname === '/app/preview-email-template-mass-email' || window.location.pathname === '/app/paying-checks-report' || window.location.pathname === '/app/terms-policy-setting' || window.location.pathname === '/app/referral-user-email-template' || window.location.pathname === '/app/preview-referral-user-email-template' || window.location.pathname === '/app/referral-user-email-communication' || window.location.pathname === '/app/referral-list-for-admin' )
        {
          if(user.type === 'admin')
          {
            setIsValid(true);
          }
        }
        else
        {
          setIsValid(false);
        }
        setHasLoaded(true)
    }
  }, [user]);

  if(hasLoaded)
  {
    if(isValid)
    {
      return (
        <Component />
      )
    }
    else
    {
      return (
        <Navigate to="/dashboard" />
      )
    }
  }
}


function RedirectToLogin () {
  return (
    <Navigate to="/login" />
  )
}
function RedirectToDashboard () {

  const navigate = useNavigate();
  const { handleUserDataCall } = useAuth();
  useEffect(() => {
    handleUserDataCall();
    navigate('/dashboard');
  }, []);
}

const routes = (isLoggedIn) => [
  {
    path: '/dashboard', // protected routes
    element: isLoggedIn ? <Layout /> : <Navigate to="/login" />,
    children: [
      { path: '', element: <Dashboard /> },
    ],
  },
  {
    path: '/app', // protected routes
    element: isLoggedIn ? <Layout /> : <Navigate to="/login" />,
    children: [
      { path: 'edit-profile', element: <EditProfile /> },
      //{ path: 'agent-subscription-plan', element: <SubscriptionPlan /> },
      { path: 'email-template', element: <PrivateRoute Component={EmailTemplate}/>},
      { path: 'mass-email', element:<PrivateRoute Component={MassEmailTemplate}/>  },
      { path: 'email-check', element: <EmailCheck /> },
      { path: 'preview-email-template-mass-email', element: <PrivateRoute Component={PreviewEmailForMassEmail}/>},
      { path: 'preview-email-template', element: <PrivateRoute Component={PreviewEmail}/>},
      { path: 'email-template-modal', element: <EmailModal /> },
      { path: 'email-template-list', element: <PrivateRoute Component={EmailTemplateList}/>},
      { path: 'my-network', element: <PrivateRoute Component={MyNetwork}/>},
      { path: 'my-network-detail', element: <PrivateRoute Component={LicenceDetails}/>},

      { path: 'licence-details', element: <PrivateRoute Component={LicenceDetails}/>},
      { path: 'email-temp', element: <EmailTemp /> },
      { path: 'people-list', element: <PrivateRoute Component={PeopleList}/>},
      { path: 'people-detail', element: <PrivateRoute Component={PeopleDetail}/>},

      { path: 'submit-a-referral', element: <PrivateRoute Component={SubmitAReferral}/>},
      { path: 'submit-a-referral-update', element: <PrivateRoute Component={SubmitAReferralUpdate}/>},
      { path: 'referral-list', element: <PrivateRoute Component={ReferralListing}/>},
      { path: 'referral-list-for-admin', element: <PrivateRoute Component={ReferralListingAdmin}/>},
      { path: 'referral-user-email-template', element: <PrivateRoute Component={ReferralEmailTemplate}/>},
      { path: 'preview-referral-user-email-template', element: <PrivateRoute Component={ReferralPreviewEmail}/>},
      { path: 'referral-user-email-communication', element: <PrivateRoute Component={ReferralEmailCommunication}/>},
      

      { path: 'peoplelist-popup', element: <PeopleListPopup /> },
      { path: 'sub', element: <Subscription /> },
      { path: 'credit', element: <CreditCard /> },
      { path: 'referral-detail', element: <PrivateRoute Component={ReferralDetailsFinal}/>},
      { path: 'paying-checks-report', element: <PrivateRoute Component={PayingChecks}/>},
      { path: 'terms-policy-setting', element: <PrivateRoute Component={PolicyContentSetting}/>},
      
      { path: 'delete', element: <DeletePopup /> },
      { path: 'register-c', element: <RegisterConfirm /> }
    ],
  },
  { // public routes
    path: '',
    element: !isLoggedIn ? <BaseLayout /> : <RedirectToDashboard />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '', element: <RedirectToLogin /> },
      { path: '/', element: <RedirectToLogin /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset-password-request', element: <ResetPasswordRequest /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: 'password-changed', element: <PasswordChanged /> },
      { path: 'signup', element: <Signup /> },
      { path: 'signup/agent', element: <Signup /> },
      { path: 'signup/ambassador', element: <Signup /> },
      { path: 'verify-email', element: <VerifyEmail /> },
      { path: 'agent-subscription-plan', element: <SubscriptionPlan /> },
      { path: 'select-plan', element: <PlanSelection /> },
      { path: 'payment', element: <MakePayment /> },
      { path: 'successfully-registered', element: <SuccessfullyRegistered /> },
      { path: 'successfully-plan-subscribe', element: <SuccessfullyPlanPurches /> },
      { path: 'set-password', element: <SetPassword /> },
      { path: 'referral-status-update', element: <ReferralStatusUpdate /> },
      { path: 'unsubscribe', element: <UnsubscribePage /> },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ],
  },
];

export default routes;
