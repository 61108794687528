import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode';
import axios from '../axiosCall.js';
import { NavLink, useNavigate } from 'react-router-dom';

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }
    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const setUserSession = (userData) => {
    if (userData) {
        localStorage.setItem('user_data', userData)
    } else {
        localStorage.removeItem('user_data')
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }

        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    setUserDataCall: () => Promise.resolve(),
    logout: () => { },
})

export const AuthProvider = ({ children }) => {

    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState)


    const login = async (email, password) => {
        const response = await axios.post('/login', {
            email,
            password,
        })
        const { Status, StatusMessage,Error,Result } = response.data;

        if(Status === false)
        {
           //console.log('error in login')
            dispatch({
                type: 'INIT',
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            })
        }
        else
        {
            const accessToken = Result.original.access_token;
            const user = Result.original.user;
            setSession(accessToken);
            setUserSession(user);
            //alert('after login'+user)
            dispatch({
                type: 'LOGIN',
                payload: {
                    user
                },
            })
        }
        return response.data;
    }

    const logout = () => {
        setSession(null)
        setUserSession(null)
        dispatch({ type: 'LOGOUT' })
    }
    const setUserDataCall= (user) => {
        //alert('user'+user)
        dispatch({
            type: 'INIT',
            payload: {
                isAuthenticated: true,
                user,
            },
        })
        setUserSession(user)
    }
    const handleUserDataCall= async() => {
        try {
            const accessToken = window.localStorage.getItem('accessToken')
           
            if (accessToken && isValidToken(accessToken)) {
                setSession(accessToken)
                const response = await axios.post('/user-profile')
                const { Status, StatusMessage,Error,Result } = response.data;
                if(Status === false)
                {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                    localStorage.removeItem('user_data')
                    localStorage.removeItem('user_data_id')
                    if(window.location.pathname !== '/login' && window.location.pathname !== '/signup/agent' && window.location.pathname !== '/signup/ambassador' && window.location.pathname !== '/forgot-password' && window.location.pathname !== '/reset-password-request' && window.location.pathname !== '/reset-password' && window.location.pathname !== '/password-changed' && window.location.pathname !== '/signup' && window.location.pathname !== '/verify-email' && window.location.pathname !== '/successfully-registered' && window.location.pathname !== '/set-password' && window.location.pathname !== '/referral-status-update' && window.location.pathname !== '/unsubscribe' && window.location.pathname !== '/agent-subscription-plan' && window.location.pathname !== '/select-plan' && window.location.pathname !== '/payment')
                        navigate('/');
                }
                else
                {
                    const user = Result.user;
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                    setUserSession(user)
                }
            } else {

                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
                localStorage.removeItem('user_data')
                localStorage.removeItem('user_data_id')
                if(window.location.pathname !== '/login' && window.location.pathname !== '/signup/agent' && window.location.pathname !== '/signup/ambassador' && window.location.pathname !== '/forgot-password' && window.location.pathname !== '/reset-password-request' && window.location.pathname !== '/reset-password' && window.location.pathname !== '/password-changed' && window.location.pathname !== '/signup' && window.location.pathname !== '/verify-email' && window.location.pathname !== '/successfully-registered' && window.location.pathname !== '/set-password' && window.location.pathname !== '/referral-status-update' && window.location.pathname !== '/unsubscribe' && window.location.pathname !== '/agent-subscription-plan' && window.location.pathname !== '/select-plan' && window.location.pathname !== '/payment')
                        navigate('/');
            }
        } catch (err) {

            console.error(err)
            dispatch({
                type: 'INIT',
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            })
            localStorage.removeItem('user_data')
            localStorage.removeItem('user_data_id')
            if(window.location.pathname !== '/login' && window.location.pathname !== '/signup/agent' && window.location.pathname !== '/signup/ambassador' && window.location.pathname !== '/forgot-password' && window.location.pathname !== '/reset-password-request' && window.location.pathname !== '/reset-password' && window.location.pathname !== '/password-changed' && window.location.pathname !== '/signup' && window.location.pathname !== '/verify-email' && window.location.pathname !== '/successfully-registered' && window.location.pathname !== '/set-password' && window.location.pathname !== '/referral-status-update' && window.location.pathname !== '/unsubscribe' && window.location.pathname !== '/agent-subscription-plan' && window.location.pathname !== '/select-plan' && window.location.pathname !== '/payment')
                        navigate('/');
        }
    }
    
    
    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
               
                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken)
                    const response = await axios.post('/user-profile')
                    const { Status, StatusMessage,Error,Result } = response.data;
                    if(Status === false)
                    {
                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: false,
                                user: null,
                            },
                        })
                        localStorage.removeItem('user_data')
                        localStorage.removeItem('user_data_id')
                        if(window.location.pathname !== '/login' && window.location.pathname !== '/signup/agent' && window.location.pathname !== '/signup/ambassador' && window.location.pathname !== '/forgot-password' && window.location.pathname !== '/reset-password-request' && window.location.pathname !== '/reset-password' && window.location.pathname !== '/password-changed' && window.location.pathname !== '/signup' && window.location.pathname !== '/verify-email' && window.location.pathname !== '/successfully-registered' && window.location.pathname !== '/set-password' && window.location.pathname !== '/referral-status-update' && window.location.pathname !== '/unsubscribe' && window.location.pathname !== '/agent-subscription-plan' && window.location.pathname !== '/select-plan' && window.location.pathname !== '/payment')
                            navigate('/');
                    }
                    else
                    {
                        const user = Result.user;
                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: true,
                                user,
                            },
                        })
                        setUserSession(user)
                    }
                } else {

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                    localStorage.removeItem('user_data')
                    localStorage.removeItem('user_data_id')
                    if(window.location.pathname !== '/login' && window.location.pathname !== '/signup/agent' && window.location.pathname !== '/signup/ambassador' && window.location.pathname !== '/forgot-password' && window.location.pathname !== '/reset-password-request' && window.location.pathname !== '/reset-password' && window.location.pathname !== '/password-changed' && window.location.pathname !== '/signup' && window.location.pathname !== '/verify-email' && window.location.pathname !== '/successfully-registered' && window.location.pathname !== '/set-password' && window.location.pathname !== '/referral-status-update' && window.location.pathname !== '/unsubscribe' && window.location.pathname !== '/agent-subscription-plan' && window.location.pathname !== '/select-plan' && window.location.pathname !== '/payment')
                            navigate('/');
                }
            } catch (err) {

                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
                localStorage.removeItem('user_data')
                localStorage.removeItem('user_data_id')
                if(window.location.pathname !== '/login' && window.location.pathname !== '/signup/agent' && window.location.pathname !== '/signup/ambassador' && window.location.pathname !== '/forgot-password' && window.location.pathname !== '/reset-password-request' && window.location.pathname !== '/reset-password' && window.location.pathname !== '/password-changed' && window.location.pathname !== '/signup' && window.location.pathname !== '/verify-email' && window.location.pathname !== '/successfully-registered' && window.location.pathname !== '/set-password' && window.location.pathname !== '/referral-status-update' && window.location.pathname !== '/unsubscribe' && window.location.pathname !== '/agent-subscription-plan' && window.location.pathname !== '/select-plan' && window.location.pathname !== '/payment')
                            navigate('/');
            }
        })()
    }, [])

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                handleUserDataCall,
                setUserDataCall,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
