import React,{ useContext,useEffect,useState } from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useStep } from "react-hooks-helper";
import ReferralType from "./steps/ReferralType";
import AgentSelection from "./steps/AgentSelection";
import ClientInformation from "./steps/ClientInformation";
import PropertyInformation from "./steps/PropertyInformation";
import ReviewYourReferral from "./steps/ReviewYourReferral";
import { Card } from "react-bootstrap";
import { SubmitAReferralContext } from "./context/submit-a-referral-context";
import useAuth from "./../../hooks/useAuth";
import axios from "../../axiosCall.js";

const steps = [
    { id: "ReferralType" },
    { id: "AgentSelection" },
    { id: "ClientInformation" },
    { id: "PropertyInformation" },
    { id: "ReviewYourReferral" },
];
const getSteps = () => {
    return [
        "Referral type",
        "Agent Selection",
        "Client Information",
        "Property Information",
        "Review your Referral",
    ];
};

export const ReferralFormStepper = (props) => {
    let activeStep = 0;
    let activeStep1 = 1;
    let activeStep2 = 2;
    let activeStep3 = 3;
    let activeStep4 = 4;
    const navigate = useNavigate();
    const { step, navigation } = useStep({ initialStep: 0, steps });
    const propsStep = { navigation };
    console.log(propsStep);
    const { id } = step;
    const stepsLabel = getSteps();
    const [state,dispatch] = useContext(SubmitAReferralContext);
    const { user, logout } = useAuth();

    const [dataReload, setDataReload] = useState(false);
    const fetchReferralDetailData = async () => {

        let formData = new FormData();
        formData.append('referral_id', props.editReferralId);

        try {
            const response = await axios.post("/get-referral-data", formData);
            const { Status, Result } = response.data;

            if (Status === true) {
                dispatch({
                    type: "SAVE_RERERRAL_TYPE",
                    edit_referral_id: props.editReferralId,
                    referral_type: Result.referral.referral_type,
                    buyer_or_seller:Result.referral.buyer_or_seller,
                });
                let is_buyer_referral_upline_agent = 'yes';
                let is_agent_assign_to_referral = 'no';
                let agent_upline_user_id = '';
                let agentDetail = false;
                let assignAgentDetail = false;

                if(Result.referral.agent_type == 'upline_user')
                {
                    is_buyer_referral_upline_agent = 'yes';
                    agent_upline_user_id = Result.referral.agent_id;
                }
                else
                {
                    is_buyer_referral_upline_agent = 'no';
                    if(Result.referral.agent_id)
                    {
                        is_agent_assign_to_referral = 'yes'
                        agentDetail = true;
                    }
                    else
                    {
                        is_agent_assign_to_referral = 'no'
                        assignAgentDetail = true;
                    }
                }
                //alert(assignAgentDetail)
                dispatch({
                    type: "SAVE_AGENT_INFO",
                        users_id: Result.referral.users_id,
                        is_buyer_referral_upline_agent: is_buyer_referral_upline_agent,
                        is_agent_assign_to_referral: is_agent_assign_to_referral,
                        agent_upline_user_id: agent_upline_user_id,
                        agent_first_name: ((agentDetail) && (Result.referral.agent_first_name)) ? Result.referral.agent_first_name : '',
                        agent_last_name: ((agentDetail) && (Result.referral.agent_last_name)) ? Result.referral.agent_last_name : '',
                        agent_email: ((agentDetail) && (Result.referral.agent_email)) ? Result.referral.agent_email : '',
                        agent_phone: ((agentDetail) && (Result.referral.agent_phone)) ? Result.referral.agent_phone : '',
                        agent_brokerage_name: ((agentDetail) && (Result.referral.agent_brokerage_name)) ? Result.referral.agent_brokerage_name : '',
                        agent_broker_name: ((agentDetail) && (Result.referral.agent_broker_name)) ? Result.referral.agent_broker_name : '',
                        agent_broker_phone: ((agentDetail) && (Result.referral.agent_broker_phone)) ? Result.referral.agent_broker_phone : '',
                        agent_broker_email: ((agentDetail) && (Result.referral.agent_broker_email)) ? Result.referral.agent_broker_email : '',
                        gender: ((assignAgentDetail) && (Result.referral.agent_gender)) ? Result.referral.agent_gender : '',
                        language_id: ((assignAgentDetail) && (Result.referral.agent_language_master_id)) ? Result.referral.agent_language_master_id : '',
                        language_other: ((assignAgentDetail) && (Result.referral.language_other)) ? Result.referral.language_other : '',
                        agent_assign_other_requirement:((assignAgentDetail) && (Result.referral.agent_assign_other_requirement)) ? Result.referral.agent_assign_other_requirement : '',
                  });


                  dispatch({
                    type: "SAVE_CLIENT_INFO",
                        client_type: Result.referral.client_type,
                        client_first_name: Result.referral.client_first_name,
                        client_last_name: Result.referral.client_last_name,
                        client_email: Result.referral.client_email,
                        client_phone: Result.referral.client_phone,
                        client_address: Result.referral.client_address,
                        other_interested_client: (Result.referral.other_interested_client) ? Result.referral.other_interested_client : '',
                        client_reference_other: (Result.referral.other_client_reference) ? Result.referral.other_client_reference : '',
                        client_reference_id: (Result.referral.client_reference_master_id) ? Result.referral.client_reference_master_id : '',
                        existing_client_id: ((Result.referral.client_type == 'existing_client') && (Result.referral.client_id)) ? Result.referral.client_id : '',
                    });

                    var arr = [];
                    let buy_property_area_json = [
                        {
                            buy_city: "",
                            buy_zipcode: "",
                        }
                      ];

                    if(Result.referral.referral_type == 'buyer_referral')
                    {
                        const countAll = JSON.parse(Result.referral.buy_property_area_json).length;
                        //alert(countAll);

                        for (var i = 0; i < countAll; i++) {
                            arr.push({
                                buy_city_error: "",
                                buy_zipcode_error: "",
                            });
                        }
                        buy_property_area_json = JSON.parse(Result.referral.buy_property_area_json);
                    }
                    else if(Result.referral.referral_type == 'seller_referral')
                    {
                        const countAll = 1;
                        //alert(countAll);
                        for (var i = 0; i < countAll; i++) {
                            arr.push({
                                buy_city_error: "",
                                buy_zipcode_error: "",
                            });
                        }
                    }
                    else
                    {
                        if(Result.referral.buy_state_id)
                        {
                            const countAll = JSON.parse(Result.referral.buy_property_area_json).length;
                            //alert(countAll);

                            for (var i = 0; i < countAll; i++) {
                                arr.push({
                                    buy_city_error: "",
                                    buy_zipcode_error: "",
                                });
                            }
                            buy_property_area_json = JSON.parse(Result.referral.buy_property_area_json);
                        }
                        else
                        {
                            const countAll = 1;
                            //alert(countAll);
                            for (var i = 0; i < countAll; i++) {
                                arr.push({
                                    buy_city_error: "",
                                    buy_zipcode_error: "",
                                });
                            }
                        }
                    }
                    dispatch({
                        type: "SAVE_PROPERTY_INFO",
                        property_area_multi_info: buy_property_area_json,
                        property_error_multi_info: arr,
                        property_master_id:(Result.referral.property_master_id) ? Result.referral.property_master_id : '',
                        buy_state_id:(Result.referral.buy_state_id) ? Result.referral.buy_state_id : '',
                        buyer_buy_timeframe_id: (Result.referral.buyer_buy_timeframe_id) ? Result.referral.buyer_buy_timeframe_id : '',
                        seller_buy_timeframe_id: (Result.referral.seller_buy_timeframe_id) ? Result.referral.seller_buy_timeframe_id : '',
                        buyer_min_price_range_id: (Result.referral.buyer_min_price_range_id) ? Result.referral.buyer_min_price_range_id : '',
                        buyer_max_price_range_id: (Result.referral.buyer_max_price_range_id) ? Result.referral.buyer_max_price_range_id : '',
                        seller_estimated_price: (Result.referral.seller_estimated_price) ? Result.referral.seller_estimated_price : '',
                        buyer_comments: (Result.referral.buyer_comments) ? Result.referral.buyer_comments : '',
                        seller_comments: (Result.referral.seller_comments) ? Result.referral.seller_comments : '',
                        seller_address: (Result.referral.seller_address) ? Result.referral.seller_address : '',
                        seller_state_id: (Result.referral.seller_state_id) ? Result.referral.seller_state_id : '',
                        seller_city: (Result.referral.seller_city) ? Result.referral.seller_city : '',
                        seller_zipcode: (Result.referral.seller_zipcode) ? Result.referral.seller_zipcode : '',
                    });
                setDataReload(true);
            }
            else {
                navigate('/dashboard');
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };

    useEffect(() => {
        if ((user !== null) && (props.editReferralId)) {
            fetchReferralDetailData();
        }
        else
        {
            setDataReload(true);
        }

    }, [user]);

    if(dataReload)
    {
        switch (id) {
            case "ReferralType":
                return (
                    <section className="referral-template sec-pt sec-pb">
                        <div className="container">
                            <div className="referral-container">
                                <h2 className="main-heading">Submit a Referral</h2>
                                <Card>
                                    <Card.Header>
                                        <Stepper
                                            alternativeLabel
                                            activeStep={activeStep}
                                        >
                                            {stepsLabel.map((step, index) => {
                                                const labelProps = {};
                                                const stepProps = {};

                                                    return (
                                                        <Step
                                                            {...stepProps}
                                                            key={index}
                                                        >
                                                            <StepLabel {...labelProps}>
                                                                {step}
                                                            </StepLabel>
                                                        </Step>
                                                    );

                                            })}
                                        </Stepper>
                                    </Card.Header>
                                    <ReferralType {...propsStep} />
                                </Card>
                            </div>
                        </div>
                    </section>
                );
            case "AgentSelection":
                return (
                    <section className="referral-template sec-pt sec-pb">
                        <div className="container">
                            <div className="referral-container">
                                <h2 className="main-heading">Submit a Referral</h2>
                                <Card>
                                    <Card.Header>
                                        <Stepper
                                            alternativeLabel
                                            activeStep={activeStep1}
                                        >
                                            {stepsLabel.map((step, index) => {
                                                const labelProps = {};
                                                const stepProps = {};

                                                return (
                                                    <Step
                                                        {...stepProps}
                                                        key={index}
                                                    >
                                                        <StepLabel {...labelProps}>
                                                            {step}
                                                        </StepLabel>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </Card.Header>

                                    <AgentSelection {...propsStep} />
                                </Card>
                            </div>
                        </div>
                    </section>
                );
            case "ClientInformation":
                return (
                    <section className="referral-template sec-pt sec-pb">
                        <div className="container">
                            <div className="referral-container">
                                <h2 className="main-heading">Submit a Referral</h2>
                                <Card>
                                    <Card.Header>
                                        <Stepper
                                            alternativeLabel
                                            activeStep={activeStep2}
                                        >
                                            {stepsLabel.map((step, index) => {
                                                const labelProps = {};
                                                const stepProps = {};

                                                return (
                                                    <Step
                                                        {...stepProps}
                                                        key={index}
                                                    >
                                                        <StepLabel {...labelProps}>
                                                            {step}
                                                        </StepLabel>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </Card.Header>
                                    <ClientInformation {...propsStep} />
                                </Card>
                            </div>
                        </div>
                    </section>
                );
            case "PropertyInformation":
                return (
                    <section className="referral-template sec-pt sec-pb">
                        <div className="container">
                            <div className="referral-container">
                                <h2 className="main-heading">Submit a Referral</h2>
                                <Card>
                                    <Card.Header>
                                        <Stepper
                                            alternativeLabel
                                            activeStep={activeStep3}
                                        >
                                            {stepsLabel.map((step, index) => {
                                                const labelProps = {};
                                                const stepProps = {};

                                                return (
                                                    <Step
                                                        {...stepProps}
                                                        key={index}
                                                    >
                                                        <StepLabel {...labelProps}>
                                                            {step}
                                                        </StepLabel>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </Card.Header>

                                    <PropertyInformation {...propsStep} />
                                </Card>
                            </div>
                        </div>
                    </section>
                );
            case "ReviewYourReferral":
                return (
                    <section className="referral-template sec-pt sec-pb">
                        <div className="container">
                            <div className="referral-container">
                                <h2 className="main-heading">Submit a Referral</h2>
                                <Card>
                                    <Card.Header>
                                        <Stepper
                                            alternativeLabel
                                            activeStep={activeStep4}
                                        >
                                            {stepsLabel.map((step, index) => {
                                                const labelProps = {};
                                                const stepProps = {};

                                                return (
                                                    <Step
                                                        {...stepProps}
                                                        key={index}
                                                    >
                                                        <StepLabel {...labelProps}>
                                                            {step}
                                                        </StepLabel>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </Card.Header>

                                    <ReviewYourReferral {...propsStep} />
                                </Card>
                            </div>
                        </div>
                    </section>
                );
            default:
                return <> Error! </>;
        }
    }
};
