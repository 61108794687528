import React, { useState, useEffect,useRef } from 'react'
import { Dropdown, InputText, Editor, Button, SplitButton, MultiSelect } from 'primereact';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import Swal from 'sweetalert2';

const fromData = [
    {
        id: 'admin',
        name: 'REFR Team'
    },
    {
        id: 'agent',
        name: 'REFR Agent'
    }
];

const MassEmailTemplate = () => {
    const { user, logout } = useAuth();
    const secretKey = "REFR2022@GALAXY";
    const [dbStatesName, setDbStatesName] = useState([]);
    const [dbEmailTemplateName, setDbEmailTemplateName] = useState([]);
    const [dbAllAmbassador, setDbAllAmbassador] = useState([]);
    const [dbAllAgent, setDbAllAgent] = useState([]);
    const navigate = useNavigate();
    const [dbPeopleCount, setDbPeopleCount] = useState(0);
    const search = useLocation().search;
    const editDataId = new URLSearchParams(search).get('e');
    const [dbToAmbassador, setDbToAmbassador] = useState([]);
    const [dbToAgent, setDbToAgent] = useState([]);
    const mediaRef = useRef('');
    const [errorNoPeople, setErrorNoPeople] = useState(false);

    let requestUserId = '';
    let isEditMode = false;

    let editTemplateIds = '';

    if (editDataId !== null) {
        if (editDataId) {
            let idDecrypt = AES.decrypt(editDataId, secretKey);
            if (idDecrypt == "") {
                navigate('/dashboard');
            }
            else {
                isEditMode = true;
                requestUserId = JSON.parse(idDecrypt.toString(Utf8));
            }
        }
        else {
            navigate('/dashboard');
        }
    }


    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const formik = useFormik({
        initialValues: {
            edit_id: "",
            from_agent_id: "",
            from_ambassador_id: "",
            from_type: "",
            to_ambassadors_id: "",
            to_agents_id: "",
            to_peoples_id: "",
            template_id: "",
            subject: "",
            message: "",
            attachment_email_template_db:'',
            attachment_edit_db:'',
            attachment:'',
            states_id: "",
            all_state: "",
            all_ambassador: "",
            all_agent: "",
            all_people: "",
        },
        validate: (data) => {
            

            let errors = {};

            if (!data.from_type) {
                errors.from_type = "From is required.";
            }
            else {
                if (data.from_type.id === 'agent') {
                    if (data.from_agent_id.length === 0) {
                        errors.from_agent_id = "Please select an Agent.";
                    }
                }
                if (data.from_type.id === 'admin') {
                    if (data.to_agents_id.length === 0) {
                        if (data.to_ambassadors_id.length === 0) {
                            errors.to_agents_id = "To agent is required.";
                        }
                    }
                    if (data.to_ambassadors_id.length === 0) {
                        if (data.to_agents_id.length === 0) {
                            errors.to_ambassadors_id = "To ambassador is required.";
                        }
                    }
                }
            }
            
            if (!data.subject) {
                errors.subject = "Subject is required.";
            }

            if (data.message === null) {
                errors.message = 'Message is required.';
            }
            else if (!data.message) {
                errors.message = 'Message is required.';
            }
            if (errorNoPeople) {
                errors.multi_fields = "error";
                return errors;
            }
            else {
                return errors;
            }

        },
        onSubmit: (data) => {
            handleSubmit(data)
        },
    });


    const fetchEmailTemplateData = async () => {
        try {
            const response = await axios.post("/get-admin-email-template-data");
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                
                setDbEmailTemplateName(Result);
                if (editTemplateIds) {
                    const result1 = Result.filter((template) => template.id === editTemplateIds.id);
                    
                    formik.setFieldValue("template_id", result1);
                }
            }
        } catch (err) {
            setLoading(false);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };
    const onFileChanged = (event) => {
        // alert(event.files[0])
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];
            let fileSize = file.size;
            const imageSizeInMb = Math.round((fileSize / 1024));
            let fileName = event.target.files[0].name;
            let splitName = fileName.split('.');

            if (imageSizeInMb >= 2048) {
                mediaRef.current.value = '';
                Swal.fire({
                    title: '',
                    text: "Please upload the file with a size of not more than 2 MB.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })

                //Swal.fire('Please upload the file with a size of not more than 2 MB.');
            }
            else if (file.type !== 'application/pdf' && file.type !== 'application/msword'  && file.type !== 'application/wps-office.docx' && file.type !== 'application/wps-office.doc' && file.type !== 'application/ms-doc' && file.type !== 'application/doc' && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && splitName[1] !== 'bmp' && splitName[1] !== 'jpe' && splitName[1] !== 'jfif' && splitName[1] !== 'webp' && splitName[1] !== 'jpg' && splitName[1] !== 'jpeg' && splitName[1] !== 'png' && splitName[1] !== 'gif' && splitName[1] !== 'BMP' && splitName[1] !== 'JPE' && splitName[1] !== 'JFIF' && splitName[1] !== 'WEBP' && splitName[1] !== 'JPG' && splitName[1] !== 'JPEG' && splitName[1] !== 'PNG' && splitName[1] !== 'GIF')  {
                mediaRef.current.value = '';
                Swal.fire({
                    title: '',
                    html: file.name + " has an invalid extension.<br> Valid extension(s): bmp, gif, jpeg, jpg, jpe, jfif, png, webp, pdf, doc, docx.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })
                //Swal.fire('Please selects the file with valid extension i.e. *.pdf.');
            }
            else {
                //alert(event.target.files[0])
                formik.setFieldValue(
                    "attachment",
                    event.target.files[0]
                );
            }
        }
    };
    const handleAddNewDocument = () => {
        formik.setFieldValue("attachment", "");
        formik.setFieldValue("attachment_edit_db", "");
        formik.setFieldValue("attachment_email_template_db", "");
    }
    const handleDeleteDocuments = (rowId, mode, media) => {
        Swal.fire({
            title: 'Are you sure?',
            html: "You won't be able to reverse this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                handleDeleteDocumentsCall(rowId, mode, media);
            }
        })
    }
    const handleDeleteDocumentsCall = async (rowId, mode, media) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('email_template_id', rowId);
        formData.append('mode', 'mass_email_send');
        formData.append('media', media);

        try {
            const response = await axios.post('/delete-email-attachment-docs', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                formik.setFieldValue("attachment", "");
                formik.setFieldValue("attachment_edit_db", "");
                formik.setFieldValue("attachment_email_template_db", "");
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    }
    const fetchAllAmbassadorData = async () => {
        try {
            const response = await axios.post("/mass-email-get-all-ambassador");
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                
                setDbAllAmbassador(Result);
               setDbToAmbassador(Result);
            }
        } catch (err) {
            //console.log(err);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };

    const fetchAllAgentData = async () => {
        try {
            const response = await axios.post("/mass-email-get-all-agent");
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                
                setDbAllAgent(Result);
                setDbToAgent(Result);
            }
        } catch (err) {
            console.log(err);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };

    useEffect(() => {
        const elements = document.getElementsByClassName("ql-image");
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
        if (user !== null) {
            formik.setFieldValue("user_id", user.id ? user.id : "");
            fetchEmailTemplateData();
            fetchAllAmbassadorData();
            fetchAllAgentData();

            if (isEditMode)
                fetchEditData();
        }

    }, [user]);

    const fetchEditData = async () => {
        let formData = new FormData();
        formData.append('mass_email_log_id', requestUserId);
        try {
            const response = await axios.post("/get-mass-email-log-data", formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                formik.setFieldValue("edit_id", Result[0].id);
                formik.setFieldValue("subject", Result[0].subject);
                formik.setFieldValue("message", Result[0].message);
                formik.setFieldValue("attachment_edit_db", Result[0].attachment);
                
                if (Result[0].from_type === 'admin') {
                    formik.setFieldValue("from_type", { id: 'admin', name: 'REFR Team' });
                }
                else if (Result[0].from_type === 'agent') {
                    formik.setFieldValue("from_type", { id: 'agent', name: 'REFR Agent' });

                    formik.setFieldValue("from_agent_id", Result[0].from_email);
                    

                    setDbToAmbassador(Result[0].user_ambassador_downline_data);
                    setDbToAgent(Result[0].user_agent_downline_data);

                    fetchedPeopleData(Result[0].from_email);
                }
                else if (Result[0].from_type === 'ambassador') {
                    formik.setFieldValue("from_type", { id: 'ambassador', name: 'Ambassador' });
                    formik.setFieldValue("from_ambassador_id", Result[0].user_type_data[0]);
                }
                if (Result[0].states_id)
                    formik.setFieldValue("states_id", Result[0].states_id);

                if (Result[0].to_agents_id) {
                    
                    formik.setFieldValue("to_agents_id", Result[0].to_agents_id);
                }

                if (Result[0].to_peoples_id) {
                    formik.setFieldValue("to_peoples_id", Result[0].to_peoples_id);
                }

                if (Result[0].to_ambassadors_id) {
                    formik.setFieldValue("to_ambassadors_id", Result[0].to_ambassadors_id);
                }

                if (Result[0].template_id) {
                    editTemplateIds = Result[0].template_id;
                    
                    formik.setFieldValue("template_id", Result[0].template_id[0]);
                }

            }
            else {
                navigate('/dashboard');
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                //logout();
                navigate('/login');
            }
        }
    };


    useEffect(() => {
        const fetchStatesData = async () => {
            try {
                const response = await axios.post("/get-all-states");
                const { Status, StatusMessage, Error, Result } = response.data;

                if (Status === true) {
                   
                    setDbStatesName(Result);
                }
            } catch (err) {
                // console.log(err);
            }
        };
        fetchStatesData();
    }, []);

    const selectedEmailTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };
    const emailOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleEmailChange = (e) => {
        
        if (e.target.value.id) {
            let finalSubject = e.target.value.subject;

            if(finalSubject.indexOf('%refr_signup_page%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%refr_signup_page%", window.location.origin+'/signup');
            }

            if(finalSubject.indexOf('%refr_login_page%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%refr_login_page%", window.location.origin+'/login');
            }

            formik.setFieldValue("subject", finalSubject);

            let finalMessage = e.target.value.message;
            if(finalMessage.indexOf('%refr_signup_page%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%refr_signup_page%", window.location.origin+'/signup');
            }

            if(finalMessage.indexOf('%refr_login_page%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%refr_login_page%", window.location.origin+'/login');
            }
            formik.setFieldValue("message", finalMessage);
            if(e.target.value.attachment)
            {
                formik.setFieldValue("attachment_email_template_db", e.target.value.attachment);
                formik.setFieldValue("attachment_edit_db", "");
            }
            else
            {
                formik.setFieldValue("attachment_email_template_db", "");
                formik.setFieldValue("attachment_edit_db", "");
            }
        }
        
    }
    const handleTemplateMessage = (event) => {
        //console.log('event.htmlValue :: '+event.htmlValue);
        //console.log('event.textValue :: '+event.textValue);

        if (event.textValue !== null) {
            formik.setFieldValue("message", event.htmlValue);
        }
        else {
            formik.setFieldValue("message", "");
        }
    }

    const handleSubmit = async (data) => {
        let formData = new FormData();
        let file = data.attachment;
        formData.append('file', file)
        formData.append('data', JSON.stringify(data))
        setLoading(true);
        try {
            const response = await axios.post('/mass-email-log-store', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                setLoading(false);
                const idEncryptedString = AES.encrypt(JSON.stringify(Result.mass_email_log_id), secretKey).toString();

                let finalUrl = 'u=' + encodeURIComponent(idEncryptedString);

                navigate('/app/preview-email-template-mass-email?' + finalUrl);

            }
        } catch (err) {
            // console.log(err);

            setLoading(false);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
            else {
                setErrorMessage(err);
            }
        }
    };

    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.first_name + ' ' + option.last_name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const optionTemplate = (option) => {
        return (
            <div className="country-item user-name-list">
                <p className='full-name-user'>{option.first_name + ' ' + option.last_name}</p>
                <p className='email-option'>{option.email}</p>
            </div>
        );
    };
    const handleFromName = (e) => {
        formik.setFieldValue('from_ambassador_id', '')
        formik.setFieldValue('from_agent_id', '')
        formik.setFieldValue('to_ambassadors_id', '')
        formik.setFieldValue('to_agents_id', '')
        formik.setFieldValue('to_peoples_id', '')
        formik.setFieldValue("states_id", '')

        setErrorNoPeople(false)
        setDbToAmbassador([]);
        setDbToAgent([]);
        setDbPeopleCount(0);
        if (e.target.value.id === 'admin') {
            setDbToAmbassador(dbAllAmbassador);
            setDbToAgent(dbAllAgent);
        }
    }
    const handleToData = (e) => {
        setDbToAmbassador([]);
        setDbToAgent([]);
        formik.setFieldValue('to_ambassadors_id', '')
        formik.setFieldValue('to_agents_id', '')
        formik.setFieldValue('to_peoples_id', '')
        formik.setFieldValue("states_id", '')
        setDbPeopleCount(0);
        if (e.target.value.length > 0) {
            //console.log('users_id' +e.target.value.users_id);
            //console.log('id' +e.target.value.id);

            const allAmbassador = dbAllAmbassador;
            const result1 = allAmbassador.filter((user) => user.users_id === e.target.value.id);
            //console.log('result1 '+JSON.stringify(result1));


            const finalAmbassador = result1.filter((user) => user.id !== e.target.value.id);
            //console.log('finalAmbassador '+JSON.stringify(finalAmbassador));

            const allAgent = dbAllAgent;
            const result = allAgent.filter((user) => user.users_id === e.target.value.id);
            //console.log('result '+JSON.stringify(result));
            const finalAgent = result.filter((user) => user.id !== e.target.value.id);
            //console.log('finalAgent '+JSON.stringify(finalAgent));
            // console.log(finalAmbassador);
            // console.log(finalAgent);

            setDbToAmbassador(finalAmbassador);
            setDbToAgent(finalAgent);

            fetchedPeopleData(e.target.value);

        }
    }
    const fetchedPeopleData = async (userId) => {
        let formData = new FormData();
        formData.append('users_id', JSON.stringify(userId));
        setErrorNoPeople(false);
        formik.setFieldValue("to_peoples_id", '');
        try {
            const response = await axios.post("/get-all-people-agent-wise", formData);
            const { Status, StatusMessage, Error, Result } =
                response.data;

            if (Status === true) {
                
                formik.setFieldValue("to_peoples_id", Result);
                setDbPeopleCount(Result.length);
                if(Result.length > 0)
                {
                    setErrorNoPeople(false);
                }
                else
                {
                    setErrorNoPeople(true);
                }
            }
            else {
                setDbPeopleCount(0);
                formik.setFieldValue("to_peoples_id", '');
                setErrorNoPeople(true);
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };
    const handleToAgent = (e, mode) => {
        formik.setFieldValue("states_id", '')
    }
    const handleToAmbassador = (e, mode) => {
        formik.setFieldValue("states_id", '')
    }
    const handleCheckboxChange = (mode) => {
        if (mode === 'all_state') {
            if (formik.values.all_state === 'all_state')
                formik.setFieldValue("all_state", '')
            else
                formik.setFieldValue("all_state", mode)
        }

        if (mode === 'all_ambassador') {
            if (formik.values.all_ambassador === 'all_ambassador')
                formik.setFieldValue("all_ambassador", '')
            else
                formik.setFieldValue("all_ambassador", mode)
        }
        if (mode === 'all_agent') {
            if (formik.values.all_agent === 'all_agent')
                formik.setFieldValue("all_agent", '')
            else
                formik.setFieldValue("all_agent", mode)
        }

        if (mode === 'all_people') {
            if (formik.values.all_people === 'all_people')
                formik.setFieldValue("all_people", '')
            else
                formik.setFieldValue("all_people", mode)
        }

    }
    const handleUserAsPerState = async (e) => {
        formik.setFieldValue('to_ambassadors_id', '')
        formik.setFieldValue('to_agents_id', '')
        formik.setFieldValue('from_agent_id', '')
        setDbPeopleCount(0);
        formik.setFieldValue("to_peoples_id", '');
        setErrorNoPeople(false);

        if (e.target.value[0].id) {
            let formData = new FormData();
            formData.append('state_id', JSON.stringify(e.target.value));

            try {
                const response = await axios.post("/get-user-as-per-state", formData);
                const { Status, StatusMessage, Error, Result } =
                    response.data;

                if (Status === true) {

                    let statewiseAmb = Result[0];
                    let statewiseAgent = Result[1];
                   
                    formik.setFieldValue('to_ambassadors_id', '')
                    formik.setFieldValue('to_agents_id', '')
                    
                    if (statewiseAgent.length > 0) {
                        
                        if(formik.values.from_type.id === 'agent')
                        {
                            formik.setFieldValue('from_agent_id', statewiseAgent)
                            fetchedPeopleData(statewiseAgent)
                        }
                        else if(formik.values.from_type.id === 'admin')
                        {
                            formik.setFieldValue('to_agents_id', statewiseAgent)
                        }
                    }

                    if (statewiseAmb.length > 0) {
                        if(formik.values.from_type.id === 'admin')
                        {
                            formik.setFieldValue('to_ambassadors_id', statewiseAmb)
                        }
                    }

                }
                else {

                }
            } catch (err) {
                if (err.response.status && err.response.status == 401) {
                    logout();
                    navigate('/login');
                }
            }
        }
    }
    return (
        <>
            <section className='emailTemplate-component sec-pt sec-pb'>
                <div className="container">
                    <div className="email-container">
                        <h2 className='main-heading'>Mass Email</h2>
                        {errorMessage && (
                            <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                {errorMessage}
                            </Alert>
                        )}
                        <div className="form-container">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="field">
                                            <label htmlFor="" className="form-label">From</label>

                                            <Dropdown className={classNames("form-select", { 'p-invalid': isFormFieldValid('from_type') })} options={fromData} defaultValue={formik.values.from_type} optionLabel="name" placeholder="Select" name="from_type" value={formik.values.from_type}
                                                onChange={(e) => { handleFromName(e); formik.handleChange(e); }}
                                            />
                                            {getFormErrorMessage('from_type')}
                                        </div>
                                    </div>

                                    
                                </div>
                                <div className="row">

                                    <div className="col-lg-6 ">{/* state-flex */}
                                        <div className="field">
                                            <label htmlFor="" className="form-label">Select States</label>
                                            <MultiSelect
                                                name="states_id"
                                                value={formik.values.states_id}
                                                optionLabel="state"
                                                options={dbStatesName}
                                                maxSelectedLabels={2}
                                                className={classNames("form-select", { 'p-invalid': isFormFieldValid('states_id') })}
                                                //onChange={(e)=>{formik.handleChange(e)}}
                                                onChange={(e) => { formik.handleChange(e); handleUserAsPerState(e) }}
                                                filter
                                            />
                                            {getFormErrorMessage('states_id')}
                                        </div>
                                        {/* <div className="field-checkbox">
                                        <Checkbox inputId="allstates" name="all_state" 
                                                value="all_state" checked={formik.values.all_state === 'all_state'}
                                                onChange={(e) => { handleCheckboxChange('all_state') }} />
                                            <label htmlFor="allstates">All States</label>
                                        </div>
                                        <br /><br /> */}

                                    </div>
                                </div>
                                {
                                    
                                        (formik.values.from_type.id === 'agent') && (<div className="row"><div className="col-lg-6">
                                            <div className="field">
                                                <label htmlFor="" className="form-label">Agents</label>
                                                <MultiSelect
                                                    name="from_agent_id"
                                                    value={formik.values.from_agent_id}
                                                    optionLabel="name"
                                                    options={dbAllAgent}
                                                    maxSelectedLabels={2}
                                                    className={classNames("form-select", { 'p-invalid': isFormFieldValid('from_agent_id') })}
                                                    //onChange={(e)=>{formik.handleChange(e)}}
                                                    onChange={(e) => { handleToData(e); formik.handleChange(e); }}
                                                    filter
                                                />
                                                {/* <Dropdown className={classNames("form-select", { 'p-invalid': isFormFieldValid('from_agent_id') })}
                                                    options={dbAllAgent} defaultValue={formik.values.from_agent_id}
                                                    optionLabel="name" placeholder="Select"
                                                    name="from_agent_id" value={formik.values.from_agent_id}
                                                    onChange={(e) => { handleToData(e); formik.handleChange(e); }}
                                                    filter
                                                    filterBy="name,email"
                                                    valueTemplate={selectedTemplate}
                                                    itemTemplate={optionTemplate}
                                                /> */}
                                                {
                                                    (!errorNoPeople) && (getFormErrorMessage('from_agent_id'))
                                                }

                                                {
                                                    (errorNoPeople) && (<small className="p-error">No sphere is available for the chosen agent.
                                                    </small>)
                                                }
                                            </div>
                                        </div> 
                                        </div>)
                                    }
                                   
                                {/* <div className='create-checkboxes'>
                                    <div className="create-email-check">
                                        <Checkbox className="form-check-input" inputId="ambassadors"
                                        name="ambassador_or_refferal_type"
                                        value="ambassador" checked={formik.values.ambassador_or_refferal_type === 'ambassador'}
                                        onChange={() => {handleCheckboxChange('ambassador')}}
                                        />
                                        <label htmlFor="ambassadors">Ambassadors</label>
                                        <Checkbox className="form-check-input referral" inputId="referral"
                                        name="ambassador_or_refferal_type"
                                        value="referral" checked={formik.values.ambassador_or_refferal_type === 'referral'}
                                        onChange={() => {handleCheckboxChange('referral')}}
                                        />
                                        <label htmlFor="referral">Referral</label>

                                    </div>
                                    {getFormErrorMessage('ambassador_or_refferal_type')}
                                </div> */}
                                {/* <div className="row">
                                    {
                                        (formik.values.from_type.id === 'ambassador') && (<div className="col-lg-6">
                                        <div className="field">
                                            <label htmlFor="" className="form-label">Ambassador</label>
                                            
                                            <Dropdown className={classNames("form-select", { 'p-invalid': isFormFieldValid('from_ambassador_id') })} 
                                            options={dbAllAmbassador} defaultValue={formik.values.from_ambassador_id} 
                                            optionLabel="name" placeholder="Select" 
                                            name="from_ambassador_id" value={formik.values.from_ambassador_id}
                                            onChange={(e) => {handleToData(e);formik.handleChange(e);}}
                                            filter
                                            filterBy="name,email"
                                            valueTemplate={selectedTemplate}
                                            itemTemplate={optionTemplate}
                                        />
                                        
                                        {getFormErrorMessage('from_ambassador_id')}
                                        </div>
                                    </div>)
                                    }
                                </div> */}
                                
                                {
                                    (formik.values.from_type.id === 'admin') && (
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label htmlFor="to" className="form-label">To Ambassador</label>
                                                    <MultiSelect

                                                        name="to_ambassadors_id"
                                                        value={formik.values.to_ambassadors_id}
                                                        optionLabel="name"
                                                        options={dbToAmbassador}
                                                        maxSelectedLabels={2}
                                                        className={classNames("form-select", { 'p-invalid': isFormFieldValid('to_ambassadors_id') })}
                                                        onChange={(e) => { formik.handleChange(e); handleToAmbassador(e, 'ambassador') }}
                                                        filter
                                                    />

                                                    {getFormErrorMessage('to_ambassadors_id')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label htmlFor="" className="form-label">To Agent</label>
                                                    <MultiSelect
                                                        name="to_agents_id"
                                                        value={formik.values.to_agents_id}
                                                        optionLabel="name"
                                                        options={dbToAgent}
                                                        maxSelectedLabels={2}
                                                        className={classNames("form-select", { 'p-invalid': isFormFieldValid('to_ambassadors_id') })}
                                                        onChange={(e) => { formik.handleChange(e); handleToAgent(e, 'agent') }}
                                                        filter
                                                    />

                                                    {getFormErrorMessage('to_agents_id')}
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                {
                                    (formik.values.from_type.id === 'ambassador' || formik.values.from_type.id === 'agent') && (
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label htmlFor="to" className="form-label">To </label>
                                                    <InputText
                                                        disabled={true}
                                                        placeholder="Enter here"
                                                        value={
                                                            (dbPeopleCount > 1) ? 
                                                            "REFR Agent’s Sphere " + "("+dbPeopleCount+" spheres)" : 
                                                            "REFR Agent’s Sphere " + "("+dbPeopleCount+" sphere)"
                                                        }
                                                        className="form-control"
                                                    />
                                                    {/* <MultiSelect
                                            name="to_peoples_id"
                                            value={formik.values.to_peoples_id}
                                            optionLabel="name"
                                            options={dbPeople}
                                            maxSelectedLabels={2}
                                            className={classNames("form-select", { 'p-invalid': isFormFieldValid('to_peoples_id') })}
                                            onChange={formik.handleChange}
                                             /> */}
                                                    {/* <div className="field-checkbox">
                                         <Checkbox inputId="allstates" name="all_people" 
                                                value="all_people" checked={formik.values.all_people === 'all_people'}
                                                onChange={(e) => { handleCheckboxChange('all_people') }} />
                                            <label htmlFor="allstates">All People</label>
                                        </div>
                                        <br /><br /> */}

                                                    {getFormErrorMessage('to_peoples_id')}
                                                </div>
                                            </div>

                                        </div>)
                                }
                                
                                <div className="row">

                                    {/* <div className="col-lg-6">
                                        <div className="field">
                                            <label htmlFor="to" className="form-label">To</label>
                                            <InputText
                                            placeholder="Enter here"
                                            name="to_name"
                                            value={formik.values.to_name} onChange={formik.handleChange}
                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('to_name') })}
                                            />
                                            {getFormErrorMessage('to_name')}
                                        </div>
                                    </div> */}
                                    <div className="col-lg-12">
                                        <div className="field">
                                            <label htmlFor="" className="form-label">Select Template</label>
                                            <Dropdown
                                                name="template_id"
                                                value={
                                                    formik.values.template_id
                                                }
                                                options={
                                                    dbEmailTemplateName
                                                }
                                                className={classNames("form-select", { 'p-invalid': isFormFieldValid('first_name') })}
                                                optionLabel="template" filter
                                                valueTemplate={
                                                    selectedEmailTemplate
                                                }
                                                itemTemplate={
                                                    emailOptionTemplate
                                                }
                                                onChange={(e) => { formik.handleChange(e); handleEmailChange(e) }}
                                                filterBy="name" placeholder="Select" />
                                            {getFormErrorMessage('template_id')}
                                        </div>

                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="form-label">Email Subject</label>
                                    <div className="p-inputgroup">
                                        <InputText
                                            placeholder="Enter here"
                                            name="subject"
                                            value={formik.values.subject} onChange={formik.handleChange}
                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('subject') })}
                                        />
                                        {/* <SplitButton label="Merge Fields"  model={items}></SplitButton> */}
                                    </div>
                                    {getFormErrorMessage('subject')}
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="form-label">Message</label>
                                    <Editor placeholder="Enter Here" style={{ height: '199px' }}
                                        name="message"
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('message') })}
                                        onTextChange={handleTemplateMessage}
                                    />
                                    {getFormErrorMessage('message')}
                                </div>
                                <div className="row">
                                {
                                    (!formik.values.attachment_email_template_db && !formik.values.attachment_edit_db) && (<div className="col-lg-6">
                                    <div className="field upload-file">
                                        <label htmlFor="UploadInput" className="form-label">Upload Attachment</label>
                                        <div className='custom-file d-flex'>
                                            <input type="file" ref={mediaRef} className="custom-file-input"
                                                onChange={onFileChanged}
                                                id="customFileLang" lang="in" />
                                            <label className="custom-file-label btn" for="customFileLang">Browse </label>
                                        </div>
                                        <div className='pdf-condi'>
                                            <span>Max Size 2MB</span>
                                        </div>
                                    </div>
                                </div> )
                                        }
                                    {
                                    (formik.values.attachment_email_template_db || formik.values.attachment_edit_db) && (<div className="col-lg-6">
                                        <div className="field upload-file email_upload_file">
                                        <label htmlFor="UploadInput" className="form-label">Upload Attachment</label>
                                        <a href={(formik.values.attachment_email_template_db) ? formik.values.attachment_email_template_db : formik.values.attachment_edit_db } target="_blank" className="btn outlined-btn preview-btn">Preview</a>
                                        
                                        {
                                            (formik.values.attachment_email_template_db) && (<a href='javascript:;' className="btn outlined-btn" onClick={()=>{handleAddNewDocument()}}>Delete</a>)
                                        }
                                        {
                                            (formik.values.attachment_edit_db) && (<a href='javascript:;' className="btn outlined-btn" onClick={() => { handleDeleteDocuments(formik.values.edit_id,'attachment', formik.values.attachment_edit_db) }}>Delete</a>)
                                        }
                                            
                                        </div>
                                    </div>)
                                    }
                                </div>
                                <div className="field text-center email-btns">
                                    <button className="btn btn-cancel me-3" type="button" onClick={(e) => navigate('/app/email-template-list')} disabled={loading}>Cancel</button>
                                    <button className="btn btn-submit" type="submit" disabled={loading}>Preview Email
                                        {loading && (<Typography align="center">
                                            <CircularProgress value={66} />
                                        </Typography>)}
                                    </button>

                                </div>
                            </form>
                        </div>
                        <div className="email-template-letter">
                            <img src="assets/images/Open-Email.png" alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MassEmailTemplate;
