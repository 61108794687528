import React, { useContext, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitAReferralContext } from "../context/submit-a-referral-context";
import { Card } from "react-bootstrap";
import Button from "@mui/material/Button";
import { Avatar,  Typography,CircularProgress,Alert } from "@mui/material";
import axios from "../../../axiosCall.js";
import useAuth from "./../../../hooks/useAuth";
import { Number, Currency } from "react-intl-number-format";

const ReviewYourReferral = ({ navigation }) => {
    const navigate = useNavigate();
    const { previous } = navigation;
    const [state, dispatch] = useContext(SubmitAReferralContext);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { user, logout } = useAuth();
    const [clientShortName, setClientShortName] = useState('N/A');
    const [agentShortName, setAgentShortName] = useState('N/A');

    const [buyerMinPrice, setBuyerMinPrice] = useState('');
    const [buyerMaxPrice, setBuyerMaxPrice] = useState('');
    const [sellerPrice, setSellerPrice] = useState('');
    
    const [potentialSalesPrice, setPotentialSalesPrice] = useState('');
    const [potentialCommissionEarned, setPotentialCommissionEarned] = useState('');
    const [potentialCommission, setPotentialCommission] = useState('');
    const [potentialReferralFee, setPotentialReferralFee] = useState('');
    const [potentialReferralFeePercentage, setPotentialReferralFeePercentage] = useState('');

    const [isPercentageAdded, setIsPercentageAdded] = useState(true);

    const [potentialSellerSalesPrice, setPotentialSellerSalesPrice] = useState('');
    const [potentialSellerCommissionEarned, setPotentialSellerCommissionEarned] = useState('');
    const [potentialSellerCommission, setPotentialSellerCommission] = useState('');
    const [potentialSellerReferralFee, setPotentialSellerReferralFee] = useState('');
    const [potentialSellerReferralFeePercentage, setPotentialSellerReferralFeePercentage] = useState('');

    useEffect(() => {
       //console.log('state '+JSON.stringify(state))
        if((state.client_first_name) && (state.client_last_name))
        {
            let str = state.client_first_name.trim()+' '+state.client_last_name.trim();
            let matches = str.match(/\b(\w)/g);
            let acronym = matches.join('');
            setClientShortName(acronym);
        }

        if((state.agent_first_name) && (state.agent_last_name))
        {
            let str = state.agent_first_name.trim()+' '+state.agent_last_name.trim();
            let matches = str.match(/\b(\w)/g);
            let acronym = matches.join('');
            setAgentShortName(acronym);
        }

        if(state.referral_type === 'buyer_referral') {
            let minPrice = state.buyer_min_price_range_id.name;
            let temp = minPrice.split('$');
            setBuyerMinPrice(temp[1]);

            let maxPrice = state.buyer_max_price_range_id.name;
            let temp1 = maxPrice.split('$');
            setBuyerMaxPrice(temp1[1]);

            let avgprice = (parseFloat(temp1[1]) * parseFloat(0.95));
            let finalAvgprice = (avgprice).toFixed(2);
            setPotentialSalesPrice(finalAvgprice);

            let potCom = (parseFloat(finalAvgprice) * parseFloat(2.5));
            let finalpotCom = ((potCom) / 100).toFixed(2);
            setPotentialCommissionEarned(finalpotCom);

            setPotentialCommission('2.5%');
            if(state.referral_fee_user_receive) {
                setPotentialReferralFeePercentage(parseFloat(state.referral_fee_user_receive).toFixed(2)+'%');

                let potReferralFee = (parseFloat(finalpotCom) * parseFloat(state.referral_fee_user_receive));
                let finalpotReferralFee = ((potReferralFee) / 100).toFixed(2);
                if(state.referring_agent_referral_fee_action === 'yes')
                {
                    setIsPercentageAdded(false)
                    setPotentialReferralFeePercentage(parseFloat(state.referral_fee_user_receive).toFixed(2)+'% + $199');
                    setPotentialReferralFee(parseFloat(finalpotReferralFee) + parseFloat(199));
                }
                else
                {
                    setPotentialReferralFee(finalpotReferralFee);
                }
            } else {
                setPotentialReferralFeePercentage('35.00%');

                let potReferralFee = (parseFloat(finalpotCom) * parseFloat(35));
                let finalpotReferralFee = ((potReferralFee) / 100).toFixed(2);
                setPotentialReferralFee(finalpotReferralFee);
            }
        }
        else if(state.referral_type === 'seller_referral') {
            if(state.seller_estimated_price)
                setSellerPrice(state.seller_estimated_price);

            if(state.referral_fee_user_receive)
                setPotentialSellerReferralFeePercentage(parseFloat(state.referral_fee_user_receive).toFixed(2)+'%');
            else
                setPotentialSellerReferralFeePercentage('35.00%');
            if(state.seller_estimated_price) {
                let finalAvgprice = state.seller_estimated_price;
                setPotentialSellerSalesPrice(finalAvgprice);

                let potCom = (parseFloat(finalAvgprice) * parseFloat(2.5));
                let finalpotCom = ((potCom) / 100).toFixed(2);
                setPotentialSellerCommissionEarned(finalpotCom);

                if(state.referral_fee_user_receive) {
                    let potReferralFee = (parseFloat(finalpotCom) * parseFloat(state.referral_fee_user_receive));
                    let finalpotReferralFee = ((potReferralFee) / 100).toFixed(2);
                    if(state.referring_agent_referral_fee_action === 'yes')
                    {
                        setIsPercentageAdded(false)
                        setPotentialSellerReferralFeePercentage(parseFloat(state.referral_fee_user_receive).toFixed(2)+'% + $199');
                        setPotentialSellerReferralFee(parseFloat(finalpotReferralFee) + parseFloat(199));
                    }
                    else
                    {
                        setPotentialSellerReferralFee(finalpotReferralFee);
                    }
                } else {
    
                    let potReferralFee = (parseFloat(finalpotCom) * parseFloat(35));
                    let finalpotReferralFee = ((potReferralFee) / 100).toFixed(2);
                    setPotentialSellerReferralFee(finalpotReferralFee);
                }
            }
            setPotentialSellerCommission('2.5%');
            
            
        } else if(state.referral_type === 'buyer_and_seller_referral')
        {
            let minPrice = state.buyer_min_price_range_id.name;
            let temp = minPrice.split('$');
            setBuyerMinPrice(temp[1]);

            let maxPrice = state.buyer_max_price_range_id.name;
            let temp1 = maxPrice.split('$');
            setBuyerMaxPrice(temp1[1]);

            let avgprice = (parseFloat(temp1[1]) * parseFloat(0.95));
            let finalAvgprice = (avgprice).toFixed(2);
            setPotentialSalesPrice(finalAvgprice);

            let potCom = (parseFloat(finalAvgprice) * parseFloat(2.5));
            let finalpotCom = ((potCom) / 100).toFixed(2);
            setPotentialCommissionEarned(finalpotCom);

            setPotentialCommission('2.5%');

            if(state.referral_fee_user_receive) {
                setPotentialReferralFeePercentage(parseFloat(state.referral_fee_user_receive).toFixed(2)+'%');

                let potReferralFee = (parseFloat(finalpotCom) * parseFloat(state.referral_fee_user_receive));
                let finalpotReferralFee = ((potReferralFee) / 100).toFixed(2);
                if(state.referring_agent_referral_fee_action === 'yes')
                {
                    setIsPercentageAdded(false)
                    setPotentialReferralFeePercentage(parseFloat(state.referral_fee_user_receive).toFixed(2)+'% + $199');
                    setPotentialReferralFee(parseFloat(finalpotReferralFee) + parseFloat(199));
                }
                else
                {
                    setPotentialReferralFee(finalpotReferralFee);
                }
            } else {
                setPotentialReferralFeePercentage('35.00%');

                let potReferralFee = (parseFloat(finalpotCom) * parseFloat(35));
                let finalpotReferralFee = ((potReferralFee) / 100).toFixed(2);
                setPotentialReferralFee(finalpotReferralFee);
            }

            if(state.seller_estimated_price)
                setSellerPrice(state.seller_estimated_price);

            if(state.referral_fee_user_receive)
                setPotentialSellerReferralFeePercentage(parseFloat(state.referral_fee_user_receive).toFixed(2)+'%');
            else
                setPotentialSellerReferralFeePercentage('35.00%');
            if(state.seller_estimated_price) {
                let finalAvgprice = state.seller_estimated_price;
                setPotentialSellerSalesPrice(finalAvgprice);

                let potCom = (parseFloat(finalAvgprice) * parseFloat(2.5));
                let finalpotCom = ((potCom) / 100).toFixed(2);
                setPotentialSellerCommissionEarned(finalpotCom);

                if(state.referral_fee_user_receive) {
                    let potReferralFee = (parseFloat(finalpotCom) * parseFloat(state.referral_fee_user_receive));
                    let finalpotReferralFee = ((potReferralFee) / 100).toFixed(2);
                    if(state.referring_agent_referral_fee_action === 'yes')
                    {
                        setIsPercentageAdded(false)
                        setPotentialSellerReferralFeePercentage(parseFloat(state.referral_fee_user_receive).toFixed(2)+'% + $199');
                        setPotentialSellerReferralFee(parseFloat(finalpotReferralFee) + parseFloat(199));
                    }
                    else
                    {
                        setPotentialSellerReferralFee(finalpotReferralFee);
                    }
                } else {
    
                    let potReferralFee = (parseFloat(finalpotCom) * parseFloat(35));
                    let finalpotReferralFee = ((potReferralFee) / 100).toFixed(2);
                    setPotentialSellerReferralFee(finalpotReferralFee);
                }
            }
            setPotentialSellerCommission('2.5%');
            
        }

    }, []);
    const [referralType, setReferralType] = useState(state.referral_type);
    const [isShowBuyerRefferal, setIsShowBuyerRefferal] = useState(false);
    const [isShowSellerRefferal, setIsShowSellerRefferal] = useState(false);

    useEffect(() => {
        //alert(state.edit_referral_id)
        if(state.edit_referral_id)
        {
            //alert(state.seller_state_id)
            if(state.buyer_or_seller == 'seller_referral')
            {
                setIsShowBuyerRefferal(false)
                setIsShowSellerRefferal(true);
            }
            else
            {
                setIsShowBuyerRefferal(true)
                setIsShowSellerRefferal(false);
            }
        }
        else
        {
            if (referralType === "buyer_referral") {
                setIsShowBuyerRefferal(true)
                setIsShowSellerRefferal(false);
            }
            else if (referralType === "seller_referral") {
                setIsShowBuyerRefferal(false)
                setIsShowSellerRefferal(true);
            }
            else
            {
                setIsShowBuyerRefferal(true)
                setIsShowSellerRefferal(true);
            }
            
        }
    }, [referralType,isShowBuyerRefferal]);

    const [inputList, setInputList] = useState(state.property_area_multi_info);
    const handleSubmit = async() => {
        setLoading(true);
        setErrorMessage('');
        try {
            const response = await axios.post('/add-referral', state);
            const { Status, StatusMessage, Error } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
              if (Error.length > 0) {
                Error.map(
                  (errorMessage) =>
                    //finalApiError += '<li>'+errorMessage+'</li>'
                    (finalApiError = errorMessage)
                );
              } else {
                finalApiError = StatusMessage;
              }
              setErrorMessage(finalApiError);
              window.scrollTo(0, 0);
            } else {
                const list = [...inputList];
                list[0]['buy_city'] = "";
                list[0]['buy_zipcode'] = "";

                setInputList(list);

                dispatch({
                    type: "REVIEW_YOUR_REFERRAL",
                    property_area_multi_info: inputList,
                });
                setLoading(false);
                navigate('/app/referral-list');
                
            }
          } catch (err) {
            
            setLoading(false);
            window.scrollTo(0, 0);
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }
            else
            {
                setErrorMessage('Something went wrong');
            }
        }
    }
    return (
        <div>
            <Card.Body>
                <div className="referral-fields referral-review">
                    <h2 className="referall-title mobile-title">
                        Review Your Referral
                    </h2>
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                        {errorMessage}
                        </Alert>
                    )}
                    {/* mobile title k */}
                    <div className="submit_referral_header">
                    <h2 className="final_title">
                    Please hit the <span>BLUE SUBMIT</span> button to finalize your referral.
                    </h2>
                    <div className="text-end">
                    <Button
                        className="btn btn-cancel rf-cancel-btn me-3 pd_btn"
                        variant="contained"
                        color="primary"
                        onClick={previous}
                        disabled={loading}
                    >
                        Back
                    </Button>
                    <Button
                        className="btn btn-submit pd_btn"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        Submit

                        {loading && (<Typography align="center">
                        <CircularProgress value={66} />
                    </Typography>)}
                    </Button>
                    
                </div>
                    </div>
                  
                    <Card>
                        <Card.Body>
                            <div className="card-container">
                                <p className="title">Client Referred</p>
                                <ul className="client-detail-list">
                                    <li className="user-pro-name user_pro">
                                        <Avatar>{clientShortName}</Avatar>
                                        <p className="name">{ (state.client_first_name) ? state.client_first_name+' '+state.client_last_name : 'Darrell Steward' }</p>
                                    </li>
                                    <li className="user_proname_li">
                                        <img
                                            src="../assets/images/email_i.png"
                                            alt=""
                                        />
                                        <a href={ (state.client_email) ? 'mailto:'+state.client_email : 'mailto:darrell@yahoo.com' }>
                                        { (state.client_email) ? state.client_email : 'darrell@yahoo.com' }
                                        </a>
                                    </li>
                                    <li  className="user_proname_li">
                                        <img
                                            src="../assets/images/phone.png"
                                            alt=""
                                        />
                                        <a href="tel:2095550104">
                                        { (state.client_phone) ? state.client_phone : '(209) 555-0104' } 
                                        </a>
                                    </li>
                                </ul>
                                <ul className="client-detail-list">
                                    <li>
                                        <p className="title">
                                        How do you know this client?
                                        </p>
                                        {
                                            (state.client_reference_id) && (state.client_reference_id.name !== 'Other') && 
                                            (<p className="sub-title">{state.client_reference_id.name}</p>)
                                        }
                                        
                                        {
                                            (state.client_reference_id) && (state.client_reference_id.name === 'Other') && (state.client_reference_other) && 
                                            (<p className="sub-title">{state.client_reference_other}</p>)
                                        }
                                        {
                                            (!state.client_reference_id) && 
                                            (<p className="sub-title">N/A</p>)
                                        }
                                    </li>
                                    <li>
                                        <p className="title">
                                        Any other interested parties
                                        </p>
                                        {
                                            (state.other_interested_client) &&
                                            (<p className="sub-title">{state.other_interested_client}</p>)
                                        }
                                        
                                        {
                                            (!state.other_interested_client) && 
                                            (<p className="sub-title">N/A</p>)
                                        }
                                    </li>
                                    
                                </ul>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className="assigned-agent my-4">
                        <Card.Body>
                            <div className="card-container">
                                <p className="title">Potential Agent</p>
                                
                                <ul className="client-detail-list">
                                    <li className="user-pro-name">
                                        <Avatar>{agentShortName}</Avatar>
                                        <p className="name">{ (state.agent_first_name) ? state.agent_first_name+' '+state.agent_last_name : 'N/A' } </p>
                                    </li>
                                    <li className="user_proname_li">
                                        <img
                                            src="../assets/images/email_i.png"
                                            alt=""
                                        />
                                        <a href={ (state.agent_email) ? 'mailto:'+state.agent_email : 'javascript:;' }>
                                        { (state.agent_email) ? state.agent_email : 'N/A' }
                                        </a>
                                    </li>
                                    <li className="user_proname_li">
                                        <img
                                            src="../assets/images/phone.png"
                                            alt=""
                                        />
                                        <a href="tel:2095550104">
                                            { (state.agent_phone) ? state.agent_phone : 'N/A' }
                                        </a>
                                    </li>
                                </ul>
                                {
                                    (!state.agent_first_name) && (<p className="title title_clint">Best Fit Agent Preferences</p>)
                                }
                                {
                                    (!state.agent_first_name) && (
                                <ul className="agent detail-list">
                                    <li>
                                        <p className="title">
                                        Brand
                                        </p>
                                        {
                                            (state.brokerage_list_id) && (state.brokerage_list_id.name) && 
                                            (<p className="sub-title">{state.brokerage_list_id.name}</p>)
                                        }
                                        {
                                            (!state.brokerage_list_id) && 
                                            (<p className="sub-title">N/A</p>)
                                        }
                                    </li>
                                    
                                    <li>
                                        <p className="title">
                                        Affinity Group
                                        </p>
                                        {
                                            (state.affinity_group_preference_id) && (state.affinity_group_preference_id.name) && 
                                            (<p className="sub-title">{state.affinity_group_preference_id.name}</p>)
                                        }
                                        {
                                            (!state.affinity_group_preference_id) && 
                                            (<p className="sub-title">N/A</p>)
                                        }
                                    </li>
                                    <li>
                                        <p className="title">
                                        Language
                                        </p>
                                        {
                                            (state.language_id) && (state.language_id.name !== 'Other') && 
                                            (<p className="sub-title">{state.language_id.name}</p>)
                                        }
                                        
                                        {
                                            (state.language_id) && (state.language_id.name === 'Other') && (state.language_other) && 
                                            (<p className="sub-title">{state.language_other}</p>)
                                        }
                                        {
                                            (!state.language_id) && 
                                            (<p className="sub-title">N/A</p>)
                                        }
                                    </li>
                                </ul>)}
                                {
                                    (!state.agent_first_name) && (
                                <ul className="client-detail-list below-detail-list mb-0">
                                    <li>
                                        <p className="title">
                                        Other Special Requirements
                                        </p>
                                        <p className="sub-title">{(state.agent_assign_other_requirement) ? state.agent_assign_other_requirement : 'N/A'}</p>                                       
                                    </li>
                                </ul>)}
                                {((state.referral_type !==
                                    "buyer_and_seller_referral") ||  (state.edit_referral_id)) && (
                                    <ul className="agent detail-list">
                                        <li>
                                            <p className="title">
                                                Referral Type
                                            </p>
                                            {
                                              ((!state.edit_referral_id) && (state.referral_type === 'buyer_referral')) && (<p className="sub-title">Buyer</p>)
                                            }
                                            {
                                              ((!state.edit_referral_id) && (state.referral_type === 'seller_referral'))  && (<p className="sub-title">Seller</p>)
                                            }

                                            {
                                              ((state.edit_referral_id) && (state.buyer_or_seller === 'seller_referral')) && (<p className="sub-title">Seller</p>)
                                            }
                                            {
                                              ((state.edit_referral_id) && (state.buyer_or_seller === 'buyer_referral')) && (<p className="sub-title">Buyer</p>)
                                            }
                                        </li>

                                        {
                                              (state.referral_type === 'buyer_referral' || state.buyer_or_seller === 'buyer_referral') && (<li>
                                            <p className="title">Time Frame </p>
                                            <p className="sub-title">
                                              {state.buyer_buy_timeframe_id.name}
                                              </p>
                                            
                                        </li>)
                                            }
                                            {
                                              (state.referral_type === 'buyer_referral' || state.buyer_or_seller === 'buyer_referral') && (<li>
                                            <p className="title">Price Range </p>
                                            <p className="sub-title">
                                            <Currency locale="en-US" currency="USD" minimumFractionDigits="0" maximumFractionDigits="0">{buyerMinPrice}</Currency>To<Currency locale="en-US" currency="USD" minimumFractionDigits="0" maximumFractionDigits="0">{buyerMaxPrice}</Currency>
                                              </p>
                                            
                                        </li>)
                                            }
                                        
            {
                                        (state.referral_type === 'seller_referral' || state.buyer_or_seller === 'seller_referral') && (
                                        <li>
                                            <p className="title">Time Frame </p>
                                            <p className="sub-title">
                                              {(state.seller_buy_timeframe_id.name) ? state.seller_buy_timeframe_id.name : 'N/A'}
                                              </p>
                                        </li>)
                                            }
                                            {
                                        (state.referral_type === 'seller_referral' || state.buyer_or_seller === 'seller_referral') && (
                                        <li>
                                            <p className="title">Estimated Home Value </p>
                                            <p className="sub-title">
                                                {
                                                    (sellerPrice) && (<Currency locale="en-US" currency="USD">{sellerPrice}</Currency>)
                                                }          
                                                {
                                                (!sellerPrice) && ('N/A')
                                                }
                                              </p>
                                        </li>)
                                            }
                                            {       
                                              (state.referral_type === 'buyer_referral' || state.buyer_or_seller === 'buyer_referral') && ( <li>
                                            <p className="title">
                                                Property Type
                                            </p>
                                            <p className="sub-title">
                                              {(state.property_master_id.name) ? state.property_master_id.name : 'N/A'}
                                            </p>
                                            </li>)}
                                            
                                            {
                                              (state.referral_type === 'seller_referral' || state.buyer_or_seller === 'seller_referral') && (<li>
                                            <p className="title">
                                                Property Type
                                            </p>
                                            <p className="sub-title">
                                            {(state.seller_property_master_id.name) ? state.seller_property_master_id.name : 'N/A'}
                                              </p>
                                        </li>)}
                                        
                                    </ul>
                                )}

                                {((state.referral_type !==
                                    "buyer_and_seller_referral") ||  (state.edit_referral_id)) && (
                                    <ul className="agent detail-list below-detail-list mb-0">
                                        {/* {
                                              (state.referral_type === 'seller_referral' || state.buyer_or_seller === 'seller_referral') && (<li>
                                            <p className="title">
                                                Reason for Purchase
                                            </p>
                                            <p className="sub-title">
                                              N/A
                                              </p>
                                        </li>)} */}
                                        {       
                                              (state.referral_type === 'buyer_referral' || state.buyer_or_seller === 'buyer_referral') && ( <li>
                                            <p className="title">
                                            Reason For Purchase
                                            </p>
                                            <p className="sub-title">
                                              {(state.clients_looking_to_purcahse_master_id.name) ? state.clients_looking_to_purcahse_master_id.name : 'N/A'}
                                            </p>
                                            </li>)}
                                            <li className="me-3">
                                                <p className="title">
                                                {(state.referral_type === 'buyer_referral') ? 'Location Searching' : 'Property Address'}
                                                </p>
                                                {
                                                (state.referral_type === 'buyer_referral' || state.buyer_or_seller === 'buyer_referral') && (<p className="sub-title">
                                                {state.buy_state_id.abbrv+' '+state.property_area_multi_info[0].buy_city+' '+state.property_area_multi_info[0].buy_zipcode}
                                                </p>)
                                                }
                                                {
                                                (state.referral_type === 'seller_referral' || state.buyer_or_seller === 'seller_referral') && (<p className="sub-title">
                                                {state.seller_state_id.abbrv+' '+state.seller_city+' '+state.seller_zipcode}
                                                </p>)
                                                }
                                            
                                        </li>
                                        <li>
                                            <p className="title">Comments </p>
                                            {
                                              (state.referral_type === 'buyer_referral' || state.buyer_or_seller === 'buyer_referral') && (<p className="sub-title">
                                              {(state.buyer_comments) ? state.buyer_comments : 'N/A'}
                                              </p>)
                                            }
                                            {
                                              (state.referral_type === 'seller_referral' || state.buyer_or_seller === 'seller_referral') && (<p className="sub-title">
                                              {(state.seller_comments) ? state.seller_comments : 'N/A'}
                                              </p>)
                                            }
                                            {/* <p className="sub-title">
                                            Joe is a buyer.
                                            </p> */}
                                        </li>
                                    </ul>
                                )}

                                {state.referral_type ===
                                    "buyer_and_seller_referral"  && (!state.edit_referral_id) && (
                                    <ul className="agent detail-list agent-list">
                                        <li>
                                            <p className="title">
                                                Referral Type 
                                            </p>
                                        </li>
                                        <li>
                                            <p className="desc">Buyer</p>
                                        </li>
                                        <li>
                                            <p className="desc">Seller</p>
                                        </li>
                                    </ul>
                                )}

                                {state.referral_type ===
                                    "buyer_and_seller_referral"  && (!state.edit_referral_id) && (
                                    <ul className="agent detail-list agent-list">
                                        <li>
                                            <p className="title">
                                            Property Type
                                            </p>
                                        </li>
                                        <li>
                                            <p className="desc">
                                            {(state.property_master_id.name) ? state.property_master_id.name : 'N/A'}
                                            </p>
                                        </li>
                                        <li>
                                            <p className="desc">
                                            {(state.seller_property_master_id.name) ? state.seller_property_master_id.name : 'N/A'}
                                            </p>
                                        </li>
                                    </ul>
                                )}
                                
                                {state.referral_type ===
                                    "buyer_and_seller_referral"  && (!state.edit_referral_id) && (
                                    <ul className="agent detail-list agent-list">
                                        <li>
                                            <p className="title">
                                                Time Frame 
                                            </p>
                                        </li>
                                        <li>
                                            <p className="desc">
                                            {state.buyer_buy_timeframe_id.name}
                                            </p>
                                        </li>
                                        <li>
                                            <p className="desc">
                                            {(state.seller_buy_timeframe_id.name) ? state.seller_buy_timeframe_id.name : 'N/A'}
                                            </p>
                                        </li>
                                    </ul>
                                )}
                                {state.referral_type ===
                                    "buyer_and_seller_referral"  && (!state.edit_referral_id) && (
                                    <ul className="agent detail-list agent-list">
                                        <li>
                                            <p className="title">
                                                Price Range
                                            </p>
                                        </li>
                                        <li>
                                            <p className="desc">
                                            <Currency locale="en-US" currency="USD" minimumFractionDigits="0" maximumFractionDigits="0">{buyerMinPrice}</Currency>To<Currency locale="en-US" currency="USD" minimumFractionDigits="0" maximumFractionDigits="0">{buyerMaxPrice}</Currency>
                                            </p>
                                        </li>
                                        <li>
                                            <p className="desc">
                                            {(sellerPrice) ? <Currency locale="en-US" currency="USD">{sellerPrice}</Currency> : 'N/A'}
                                            </p>
                                        </li>
                                    </ul>
                                )}
                                {state.referral_type ===
                                    "buyer_and_seller_referral"  && (!state.edit_referral_id) && (
                                    <ul className="agent detail-list agent-list">
                                        <li>
                                            <p className="title">
                                            Reason for Purchase
                                            </p>
                                        </li>
                                        <li>
                                            <p className="desc">
                                            {(state.clients_looking_to_purcahse_master_id.name) ? state.clients_looking_to_purcahse_master_id.name : 'N/A'}
                                            </p>
                                        </li>
                                        <li>
                                            <p className="desc">
                                                N/A
                                            </p>
                                        </li>
                                    </ul>
                                )}
                                {state.referral_type ===
                                    "buyer_and_seller_referral" && (!state.edit_referral_id) && (
                                    <ul className="agent detail-list agent-list">
                                        <li>
                                            <p className="title">Location </p>
                                        </li>
                                        <li>
                                            <p className="desc">{state.buy_state_id.abbrv+' '+state.property_area_multi_info[0].buy_city+' '+state.property_area_multi_info[0].buy_zipcode}</p>
                                        </li>
                                        <li>
                                            <p className="desc">{state.seller_state_id.abbrv+' '+state.seller_city+' '+state.seller_zipcode}</p>
                                        </li>
                                    </ul>
                                )}

                                {state.referral_type ===
                                    "buyer_and_seller_referral"  && (!state.edit_referral_id) && (
                                    <ul className="agent detail-list agent-list comment_block">
                                        <li>
                                            <p className="title">Comments </p>
                                        </li>
                                        <li>
                                            <p className="desc">
                                            {(state.buyer_comments) ? state.buyer_comments : 'N/A'}
                                            </p>
                                        </li>
                                        <li>
                                            <p className="desc">
                                            {(state.seller_comments) ? state.seller_comments : 'N/A'}
                                            </p>
                                        </li>
                                    </ul>
                                )}
                                
                            </div>
                        </Card.Body>
                    </Card>
                    {
                        (state.is_buyer_referral_upline_agent === 'no') && (state.is_agent_assign_to_referral === 'yes') && (state.referral_fee_user_receive) && (user) && (user.type === 'ambassador') && (<Card>
                            <Card.Body>
                                <div className="card-container">
                                    <p className="title">Referral Fee Calculation</p>
                                    
                                        <ul className="agent detail-list">
                                            <li>
                                                <p className="title">
                                                Referral Fee You Would Like to Receive
                                                </p>
                                                
                                                <p className="sub-title">{state.referral_fee_user_receive}%</p>
                                            </li>
    
                                            <li>
                                                <p className="title">
                                                REFR Platform Fee
                                                </p>
                                                
                                                <p className="sub-title">${state.referral_platform_fee}</p>
                                            </li>     
                                        </ul>
                                    <ul className="client-detail-list">
                                        <li>
                                            <p className="title">
                                            Total Referral Fee charged to Receiving Agent
                                            </p>
                                            
                                            <p className="sub-title">{state.referral_fee_charged_to_receiving_agent}</p>
                                        </li>
                                        
                                    </ul>
                                    <ul className="client-detail-list">
                                        <li>
                                            <p className="title">
                                            Total Referral Fee due to Referring Agent
                                            </p>
                                            
                                            <p className="sub-title">{state.referral_fee_due_to_referring_agent}</p>
                                        </li>
                                        
                                    </ul>
                                    <ul className="client-detail-list">
                                        <li>
                                            <p className="title">
                                            Ambassador Action
                                            </p>
                                            {
                                                (state.referring_agent_referral_fee_action === 'yes') && (<p className="sub-title">Yes, I would like to pass the $199.00 platform fee onto the receiving agent</p>)
                                            }
                                            {
                                                (state.referring_agent_referral_fee_action === 'no') && (<p className="sub-title">No, I want REFR to take the $199.00 fee from my portion of the referral fee</p>)
                                            }
                                        </li>
                                        
                                    </ul>
                                </div>
                            </Card.Body>
                        </Card>)
                    }
                    {
                        (potentialReferralFeePercentage) && (<Card>
                            <Card.Body>
                                <div className="card-container">
                                    <p className="title">{(potentialSellerReferralFeePercentage) ? 'Buyer' : ''} Potential Commission</p>
                                    
                                        <ul className="agent detail-list">
                                            <li className="tooltip-refr">
                                                <p className="title">
                                                    Estimated Purchase Price
                                                    {/* <img className="question-mark"
                                                                                src="../assets/images/question-mark.svg"
                                                                                alt=""
                                                                            /> */}
                                                </p>
                                                
                                                <p className="sub-title">{(potentialSalesPrice) ? (<Currency locale="en-US" currency="USD">{potentialSalesPrice}</Currency>) : 'N/A'}</p>
                                                <span className="tooltiptext">Calculated by taking 95% of the max purchase price</span>
                                            </li>
    
                                            <li>
                                                <p className="title">
                                                    Average Commission Percentage
                                                </p>
                                                
                                                <p className="sub-title">{potentialCommission}</p>
                                            </li>
                                            <li>
                                                <p className="title">
                                                    Total Commission
                                                </p>
                                                
                                                <p className="sub-title">{(potentialCommissionEarned) ? (<Currency locale="en-US" currency="USD">{potentialCommissionEarned}</Currency>) : 'N/A'}</p>
                                            </li>
                                            
                                        </ul>
                                   
                                    <ul className="client-detail-list">
                                        <li>
                                            <p className="title">
                                            REFR Referral Fee 
                                            </p>
                                            
                                            <p className="sub-title">{potentialReferralFeePercentage}</p>
                                        </li>
                                        <li>
                                            <p className="title">
                                            Referral Fee
                                            </p>
                                            
                                            <p className="sub-title">{(potentialReferralFee) ? (<Currency locale="en-US" currency="USD">{potentialReferralFee}</Currency>) : 'N/A'}</p>
                                        </li>
                                        <li>
                                            
                                        </li>
                                    </ul>
                                </div>
                            </Card.Body>
                        </Card>)
                    }
                    
                    {
                        (potentialSellerReferralFeePercentage) && (<Card>
                            <Card.Body>
                                <div className="card-container">
                                    <p className="title">{(potentialReferralFeePercentage) ? 'Seller' : ''} Potential Commission</p>
                                    
                                        <ul className="agent detail-list">
                                            <li>
                                                <p className="title">
                                                    Estimated Sale Price
                                                </p>
                                                
                                                <p className="sub-title">{(potentialSellerSalesPrice) ? (<Currency locale="en-US" currency="USD">{potentialSellerSalesPrice}</Currency>) : 'N/A'}</p>
                                            </li>
    
                                            <li>
                                                <p className="title">
                                                Average Commission Percentage
                                                </p>
                                                
                                                <p className="sub-title">{potentialSellerCommission}</p>
                                            </li>
                                            <li>
                                                <p className="title">
                                                Total Commission
                                                </p>
                                                
                                                <p className="sub-title">{(potentialSellerCommissionEarned) ? (<Currency locale="en-US" currency="USD">{potentialSellerCommissionEarned}</Currency>) : 'N/A'}</p>
                                            </li>
                                            
                                        </ul>
                                    
                                    <ul className="client-detail-list">
                                        <li>
                                            <p className="title">
                                            REFR Referral Fee 
                                            </p>
                                            
                                            <p className="sub-title">{potentialSellerReferralFeePercentage}</p>
                                        </li>
                                        <li>
                                            <p className="title">
                                            Referral Fee
                                            </p>
                                            
                                            <p className="sub-title">{(potentialSellerReferralFee) ? (<Currency locale="en-US" currency="USD">{potentialSellerReferralFee}</Currency>) : 'N/A'}</p>
                                        </li>
                                        <li>
                                            
                                        </li>
                                    </ul>
                                </div>
                            </Card.Body>
                        </Card>)
                    }
                    
                </div>
            </Card.Body>
            {/* <Card.Footer>
                <div className="text-end">
                    <Button
                        className="btn btn-cancel rf-cancel-btn me-3"
                        variant="contained"
                        color="primary"
                        onClick={previous}
                        disabled={loading}
                    >
                        Back
                    </Button>
                    <Button
                        className="btn btn-submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        Submit

                        {loading && (<Typography align="center">
                        <CircularProgress value={66} />
                    </Typography>)}
                    </Button>
                    
                </div>
            </Card.Footer> */}
        </div>
    );
};

export default ReviewYourReferral;
