import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { createRoot } from 'react-dom/client'
import { Alert, CircularProgress, Typography } from '@mui/material';
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
// import 'datatables.net-select';
// import 'datatables.net-select-dt';
import $ from 'jquery';
// import { CheckBox } from '@mui/icons-material';
import { Avatar, IconButton, Switch } from '@mui/material';

import { InputText } from 'primereact';
import axios, { baseUrl } from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import { Button } from 'primereact/button';
import AddPeople from "./AddPeople";
import UploadFileCSV from "./UploadFileCSV";
import Swal from 'sweetalert2';
import AES from 'crypto-js/aes';
import sampleFile from '../../people_sample.csv';
import UploadFileConfirmationMessage from "./UploadFileConfirmationMessage";


const PeopleList = () => {
    const { user,logout } = useAuth();
    const [showAddPeople, setShowAddPeople] = useState(false);
    const [showUploadCsv, setShowUploadCsv] = useState(false);
    const [showUploadConfirmationMessage, setShowUploadConfirmationMessage] = useState(false);

    const [editRowId, setEditRowId] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const secretKey = 'REFR2022@GALAXY';
    const navigate = useNavigate();
    const [showClearSearch, setShowClearSearch] = useState(false);
    useEffect(() => {
        if (user !== null) {
            //alert('line 28');
            $.fn.dataTable.ext.errMode = 'none';
            fetchPeopleData();
        }
    }, [user]);

    const handlePeopleDetail = (event, rowId) => {
        //alert(rowId+' '+mode)
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();

        let finalUrl = 'u=' + encodeURIComponent(userIdEncryptedString)
        //alert(finalUrl);
        navigate('/app/people-detail?' + finalUrl);
    }

    function fetchPeopleData() {
        const accessToken = window.localStorage.getItem('accessToken');

        const table = $('.peopleTable').on('preXhr.dt', function ( e, settings, data ) {
            if (settings.jqXHR) settings.jqXHR.abort();
        }).DataTable({
            "language": {
                "filter_tab": "",
                "search": "",
                'searchPlaceholder': "Search by name, email or phone",
                "sEmptyTable": "No records found"
            },
            dom: '<"top"f>rt<"bottom pBottom"lip><"clear">',
            order: [[1, 'desc']],
            "ordering": true,
            responsive: true,
            destroy: true,
            processing: true,

            "lengthMenu": [[10, 20, 25, 30, 50, 100, 200, 500], [10, 20, 25, 30, 50, 100, 200, 500]],
            "serverSide": true,
            "serverMethod": 'POST',
            'ajax': {
                'url': baseUrl + 'list-people',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                'data': function (data) {
                    data.user_id = user.id;
                    data.search_value = $('#searchbarTable').val();
                },
                error: function (jqXHR,error, errorThrown) {
                    //alert(jqXHR.status);
                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{

                    }
                }
            },
            "drawCallback": function (settings) {
                // Here the response
                var response = settings.json;
                //alert(JSON.stringify(response))

                $("#spanPeopleCount").html('(' + response.peopleCount + ')');
                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.pBottom').hide();
                else
                    $('.pBottom').show();
            },
            "columnDefs":
                [
                    {
                        "targets": 0,
                        "width": "5%",
                        "data": "id",
                        "title": "<input type='checkbox' class='table_checkbox' name='select_all' value='1' id='selectAllCheckboxes'>",
                        "visible": false,
                        "searchable": false,
                        "orderable": false,
                        "orderData": "",
                        "className": "my_agent",
                        "render": function (data, type, full, meta) {
                            return '<input type="checkbox" class="table_checkbox" id="id_' + data + '" name="id[]" value="' + data + '">';

                            //return '<Checkbox />';
                        }
                    },
                    {
                        "targets": 1,
                        "width": "15.8%",
                        "title": "Name",
                        "data": "name",
                        "name": "name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "cellType": "td",
                        "className": "my_agent",
                        
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let matches = rowData.name.match(/\b(\w)/g);
                            let shortName = matches.join('');

                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'><Avatar>{shortName}</Avatar> <a href="javascript:;" onClick={(e) => handlePeopleDetail(e, `${rowData.id}`)} className='name-link underline-ani-blue'>{rowData.name}</a></span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 2,
                        "width": "15.8%",
                        "title": "Email",
                        "data": "email",
                        "name": "email",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [2],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_agent desktop",
                        "createdCell": "",
                        render: function (data, type,row) {
                            if(row.is_mass_email === 0) {
                                let html = '<span style="color: red">'+row.email+'</span>'
                                return html;
                            } else {
                                let html = '<span>'+row.email+'</span>'
                                return html;
                            }
                        },
                    },
                    {
                        "targets": 3,
                        "width": "15.8%",
                        "title": "Home Address",
                        "data": "full_address",
                        "name": "full_address",
                        "visible": true,
                        "className": "my_agent desktop home-add",
                        "searchable": true,
                        "orderable": true,
                        "orderData": [3],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 4,
                        "width": "15.8%",
                        "title": "Mass Email",
                        "data": "is_mass_email",
                        "name": "is_mass_email",
                        "visible": true,
                        "searchable": true,
                        "orderable": false,
                        "orderData": [4],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_agent desktop",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let isChecked = "";
                            if (rowData.is_mass_email === 1) {
                                isChecked = "checked";
                            }
                            createRoot(td).render(<div class="form-check form-switch"><input class="form-check-input switch-toggle switchClass" type="checkbox" role="switch" value={rowData.id} onChange={(e) => handleCall(e, `${rowData.id}`)} defaultChecked={isChecked} /></div>
                            );
                        }
                    },
                    {
                        "targets": 5,
                        "width": "15.8%",
                        "title": "Created On",
                        "data": "created",
                        "name": "created",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [5],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_agent desktop",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 6,
                        "width": "15.8%",
                        "title": "Phone",
                        "data": "contact_number",
                        "name": "contact_number",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [6],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_agent desktop",
                        "createdCell": (td, cellData, rowData, row, col) => {

                            function formatPhoneNumber(value) {
                                // if input value is falsy eg if the user deletes the input, then just return
                                if (!value) return value;

                                // clean the input for any non-digit values.
                                const phoneNumber = value.replace(/[^\d]/g, "");

                                // phoneNumberLength is used to know when to apply our formatting for the phone number
                                const phoneNumberLength = phoneNumber.length;

                                // we need to return the value with no formatting if its less then four digits
                                // this is to avoid weird behavior that occurs if you  format the area code to early
                                if (phoneNumberLength < 4) return phoneNumber;

                                // if phoneNumberLength is greater than 4 and less the 7 we start to return
                                // the formatted number
                                if (phoneNumberLength < 7) {
                                  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
                                }

                                // finally, if the phoneNumberLength is greater then seven, we add the last
                                // bit of formatting and return it.
                                return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
                                  3,
                                  6
                                )}-${phoneNumber.slice(6, 10)}`;
                              }
                              let pNumber = formatPhoneNumber(rowData.contact_number);
                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'>{pNumber}</span></li>
                            </ul>
                            );
                         },
                        "render": ""
                    },
                    {
                        "targets": 7,
                        "width": "15.8%",
                        "title": "Action",
                        "data": "id",
                        "visible": true,
                        "searchable": false,
                        "orderable": false,
                        "className": "my_agent desktop tablet-l tablet-p",
                        "createdCell": (td, cellData, rowData, row, col) => {

                            createRoot(td).render(<ul className='action-icon'>
                                <li className='action-list-icon'>
                                    <a href='javascript:void(0)' onClick={(e) => handleEdit(e, `${rowData.id}`)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_21796)">
                                                <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_21796">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                    <a href='javascript:void(0)' onClick={(e) => handleDelete(e, `${rowData.id}`)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_13698)">
                                                <path d="M3.33398 5.83398H16.6673" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.33398 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.666 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.16602 5.83398L4.99935 15.834C4.99935 16.276 5.17494 16.6999 5.4875 17.0125C5.80006 17.3251 6.22399 17.5007 6.66602 17.5007H13.3327C13.7747 17.5007 14.1986 17.3251 14.5112 17.0125C14.8238 16.6999 14.9993 16.276 14.9993 15.834L15.8327 5.83398" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_13698">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </a>
                                </li>
                            </ul>);
                        },
                    }
                ]
        });
        $('#searchbarTable').keyup(function () {
            if(($(this).val().length >= 3) || ($(this).val().length === 0))
            {
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else
            {
                setShowClearSearch(false);
            } 
        })
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

        $(".peopleTable").on('click', 'th', function () {
            $(".peopleTable thead th").each(function (i, th) {
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });

        $(".peopleTable th").first().click().click();
        // $('#clearSearchTable').click(function () {
        //     $('#searchbarTable').val('');
        //     table.search('').draw();
        // })
    }
    const handleClearSearch = () => {
        $(".searchbarTable").val("");
        setShowClearSearch(false);
        $('.peopleTable').DataTable().ajax.reload();
    }
    const handleEdit = (event, rowId) => {
        setShowAddPeople(true)
        setEditRowId(rowId);
    }

    const handleDelete = async (event, rowId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to reverse this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                callDelete(rowId)
            }
        })
    }
    const width = {
        width: "100%",
    }

    const callDelete = async (rowId) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('people_id', rowId);

        try {
            const response = await axios.post('/delete-people', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                $('.peopleTable').DataTable().ajax.reload();
                //fetchPeopleData();
            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }
        }
    }
    const handleShowHideAddPeople = () => {
        setEditRowId(0)
        if (showAddPeople) {
            //alert('if');
            setShowAddPeople(false)
        }
        else {
            //alert('else');
            setShowAddPeople(true)
        }
    }
    const downloadSample = async () => {
        const originalImage = sampleFile;
        const image = await fetch(originalImage);

        const duplicateName = "people_sample";

        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)
        const link = document.createElement('a')
        link.href = imageURL;
        link.download = "" + duplicateName + "";
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    };


    const handleShowHideUploadCsv = () => {
        if (showUploadCsv) {
            //alert('if');
            setShowUploadConfirmationMessage(false);
            //setShowUploadCsv(false)
        }
        else {
            //alert('else');
            setShowUploadConfirmationMessage(true);
            //setShowUploadCsv(true)
        }
    }
    const openUploadCsvForm = () => {
        setShowUploadCsv(true);
        setShowUploadConfirmationMessage(false);
    }
    const changeStatusPeople = () => {
        setEditRowId(0)
        setShowAddPeople(false)
    }
    const changeStatusCsvUpload = () => {
        setShowUploadCsv(false)
    }
    const changeStatusUploadConfirmationMessage = () => {
        setShowUploadConfirmationMessage(false)
    }
    const reloadListing = () => {
        setEditRowId(0)
        $('.peopleTable').DataTable().ajax.reload();
        // fetchPeopleData();
    }

    const handleCall = async (event, id) => {
        try {
            let formData = new FormData();
            formData.append('people_id', id);

            const response = await axios.post('/update-mass-email', formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                reloadListing();
            }
            else {

            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }
        }
    };

    return (
        <>
            <section className='table-component no-tabs sec-pt sec-pb'>
                <div className="container-fluid">
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                            {errorMessage}
                        </Alert>
                    )}
                    <div className="table-search people-s">
                        <h2 className='main-heading'>People <span id="spanPeopleCount">(0)</span></h2>
                        <div className='tableSearch people-search-mob'>
                            <span className="p-input-icon-right">
                                <InputText  autoComplete="off" id='searchbarTable' className="searchbarTable" placeholder='Search by name, email or phone' />
                                {
                                    (!showClearSearch) && (<i className="pi pi-search" />)
                                }
                                {
                                    (showClearSearch) && (<a className="close-icon" href="javascript:;" onClick={handleClearSearch} ><i className="pi pi-times" /></a>)
                                }
                            </span>
                            {/* <span className='filter-table'>
                                <a href="javascript:;" className="clearSearchTable" id="clearSearchTable">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20" cy="20" r="19.5" fill="white" stroke="#828C99" />
                                        <g clip-path="url(#clip0_711_20484)">
                                            <path d="M27.5 13.3335V15.0002H26.6667L21.6667 22.5002V28.3335H18.3333V22.5002L13.3333 15.0002H12.5V13.3335H27.5Z" fill="#828C99" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_711_20484">
                                                <rect width="20" height="20" fill="white" transform="translate(10 10)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </span> */}
                            <span className='breadcrumbs-btns'>
                                <Button variant="primary" className='btn btn-submit' onClick={handleShowHideUploadCsv}>
                                    Upload CSV
                                </Button>
                                <Button variant="primary" className='btn btn-submit' onClick={downloadSample}>
                                    Download Sample
                                </Button>
                                <Button label="Add People" className="btn btn-submit" icon="pi pi-plus-circle" onClick={handleShowHideAddPeople} />
                            </span>
                        </div>
                    </div>

                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                            <table className="short-table nowrap display networkTable peopleTable" cellSpacing="0" style={width} id="networkTable">
                                <thead>
                                    <tr>
                                        <th className='hide_sorting'>Index </th>
                                        <th>Name </th>
                                        <th>Email </th>
                                        <th>Home Address </th>
                                        <th className='hide_sorting'>Mass Email </th>
                                        <th>Phone </th>
                                        <th>Created On</th>
                                        <th className='hide_sorting'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            {
                (showAddPeople) && (<AddPeople showAddPeople={showAddPeople} changeStatusPeople={changeStatusPeople} reloadListing={reloadListing} editRowId={editRowId} />)
            }
            {
                (showUploadCsv) && (user !== null) && (<UploadFileCSV showUploadCsv={showUploadCsv} changeStatusCsvUpload={changeStatusCsvUpload} reloadListing={reloadListing} userId={user.id} />)
            }
            {
                (showUploadConfirmationMessage) && (user !== null) && (<UploadFileConfirmationMessage showUploadConfirmationMessage={showUploadConfirmationMessage} changeStatusUploadConfirmationMessage={changeStatusUploadConfirmationMessage}  openUploadCsvForm={openUploadCsvForm}/>)
            }
        </>
    )
}

export default PeopleList;
