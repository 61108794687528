import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import useAuth from './../../hooks/useAuth';
import { Alert, CircularProgress, Typography } from "@mui/material";
import axios from "../../axiosCall.js";
import { Card } from 'react-bootstrap';
import { Number, Currency } from "react-intl-number-format"
import { handleCal } from './handleCal';


const Dashboard = () => {
    const { logout, user } = useAuth();

    const [userNetworkCount, setUserNetworkCount] = useState(0);
    const [userReferralCount, setUserReferralCount] = useState(0);
    const [userReferralDownlineUserCount, setUserReferralDownlineUserCount] = useState(0);

    const [referralCommission, setReferralCommission] = useState(
        {
            finalMyPotentialEarning: '',
            finalMyPendingEarning: '',
            finalMyYTDEarning: '',
            finalMyLifetimeEarning: '',
            finalMyYTDReferralCount: '',
            finalMyLifetimeReferralCount: '',
            finalDownlinePotentialEarning: '',
            finalDownlinePendingEarning: '',
            finalDownlineYTDEarning: '',
            finalDownlineLifetimeEarning: '',
            finalDownlineYTDReferralCount: '',
            finalDownlineLifetimeReferralCount: '',
            decimalMyPotentialEarning: '',
            decimalMyPendingEarning: '',
            decimalMyYTDEarning: '',
            decimalMyLifetimeEarning: '',
            decimalDownlinePotentialEarning: '',
            decimalDownlinePendingEarning: '',
            decimalDownlineYTDEarning: '',
            decimalDownlineLifetimeEarning: '',
        }
    );

    const [userRoleInHeader, setUserRoleInHeader] = useState('');
    const [userRoleState, setUserRoleState] = useState(false);
    const navigate = useNavigate();
    //alert('inside dashboard '+user);
    let loginUserName = '';
    useEffect(() => {
        if (user !== null) {
            //console.log(user);
            loginUserName = user.name;
            setUserRoleInHeader(user.type);
            if (user.type === 'agent') {
                if (user.is_license_flag === 1) {
                    setUserRoleState(true);
                } else {
                    setUserRoleState(false);
                }

            }
            if (user.length > 0) {
                const finalUserData = user;
                loginUserName = finalUserData[0].name;
            }
            let formData = new FormData();
            formData.append('user_id', user.id);

            const fetchCounts = async () => {
                try {
                    const response = await axios.post("/get-dashboard-data", formData);
                    const { Status, Result } = response.data;

                    if (Status === true) {
                        setUserNetworkCount(Result.network_count);
                        setUserReferralCount(Result.referral_count)
                        setUserReferralDownlineUserCount(Result.referral_downline_user_count)
                        let data_my_potential_commission = '';
                        let decimal_my_potential_commission = '';

                        if (Result.my_potential_commission > 0) {
                            let data = Result.my_potential_commission.toFixed(2);
                            data = (data + "").split('.');
                            if (data[0] > 0)
                                data_my_potential_commission = data[0];
                            decimal_my_potential_commission = data[1];
                        }

                        let data_my_pending_commission = '';
                        let decimal_my_pending_commission = '';

                        if (Result.my_pending_commission > 0) {
                            let data = Result.my_pending_commission.toFixed(2);
                            data = (data + "").split('.');
                            if (data[0] > 0)
                                data_my_pending_commission = data[0];
                            decimal_my_pending_commission = data[1];
                        }

                        let data_my_ytd_commission = '';
                        let decimal_my_ytd_commission = '';

                        if (Result.my_ytd_commission > 0) {
                            let data = Result.my_ytd_commission.toFixed(2);
                            data = (data + "").split('.');
                            if (data[0] > 0)
                                data_my_ytd_commission = data[0];
                            decimal_my_ytd_commission = data[1];
                        }

                        let data_my_lifetime_commission = '';
                        let decimal_my_lifetime_commission = '';

                        //alert(Result.my_lifetime_commission)

                        if (Result.my_lifetime_commission > 0) {
                            let data = Result.my_lifetime_commission.toFixed(2);
                            data = (data + "").split('.');
                            if (data[0] > 0)
                                data_my_lifetime_commission = data[0];
                            decimal_my_lifetime_commission = data[1];
                        }

                        let data_downline_user_potential_commission = '';
                        let decimal_downline_user_potential_commission = '';

                        //alert(Result.my_lifetime_commission)

                        if (Result.downline_user_potential_commission > 0) {
                            let data = Result.downline_user_potential_commission.toFixed(2);
                            data = (data + "").split('.');
                            if (data[0] > 0)
                                data_downline_user_potential_commission = data[0];
                            decimal_downline_user_potential_commission = data[1];
                        }

                        let data_downline_user_pending_commission = '';
                        let decimal_downline_user_pending_commission = '';

                        //alert(Result.my_lifetime_commission)

                        if (Result.downline_user_pending_commission > 0) {
                            let data = Result.downline_user_pending_commission.toFixed(2);
                            data = (data + "").split('.');
                            if (data[0] > 0)
                                data_downline_user_pending_commission = data[0];
                            decimal_downline_user_pending_commission = data[1];
                        }

                        let data_downline_user_ytd_commission = '';
                        let decimal_downline_user_ytd_commission = '';

                        //alert(Result.my_lifetime_commission)

                        if (Result.downline_user_ytd_commission > 0) {
                            let data = Result.downline_user_ytd_commission.toFixed(2);
                            data = (data + "").split('.');
                            if (data[0] > 0)
                                data_downline_user_ytd_commission = data[0];
                            decimal_downline_user_ytd_commission = data[1];
                        }

                        let data_downline_user_lifetime_commission = '';
                        let decimal_downline_user_lifetime_commission = '';

                        //alert(Result.my_lifetime_commission)

                        if (Result.downline_user_lifetime_commission > 0) {
                            let data = Result.downline_user_lifetime_commission.toFixed(2);
                            data = (data + "").split('.');
                            if (data[0] > 0)
                                data_downline_user_lifetime_commission = data[0];
                            decimal_downline_user_lifetime_commission = data[1];
                        }
                        //alert(decimal_my_potential_commission)
                        setReferralCommission((prevState) => {
                            return {
                                ...prevState,
                                'finalMyPotentialEarning': data_my_potential_commission,
                                'finalMyPendingEarning': data_my_pending_commission,
                                'finalMyYTDEarning': data_my_ytd_commission,
                                'finalMyLifetimeEarning': data_my_lifetime_commission,
                                'finalMyYTDReferralCount': Result.my_ytd_referral_count,
                                'finalMyLifetimeReferralCount': Result.my_lifetime_referral_count,
                                'finalDownlineYTDReferralCount': Result.downline_user_ytd_referral_count,
                                'finalDownlineLifetimeReferralCount': Result.downline_user_lifetime_referral_count,
                                'finalDownlinePotentialEarning': data_downline_user_potential_commission,
                                'finalDownlinePendingEarning': data_downline_user_pending_commission,
                                'finalDownlineYTDEarning': data_downline_user_ytd_commission,
                                'finalDownlineLifetimeEarning': data_downline_user_lifetime_commission,

                                'decimalMyPotentialEarning': decimal_my_potential_commission,
                                'decimalMyPendingEarning': decimal_my_pending_commission,
                                'decimalMyYTDEarning': decimal_my_ytd_commission,
                                'decimalMyLifetimeEarning': decimal_my_lifetime_commission,
                                'decimalDownlinePotentialEarning': decimal_downline_user_potential_commission,
                                'decimalDownlinePendingEarning': decimal_downline_user_pending_commission,
                                'decimalDownlineYTDEarning': decimal_downline_user_ytd_commission,
                                'decimalDownlineLifetimeEarning': decimal_downline_user_lifetime_commission,
                            };
                        });
                    }
                } catch (err) {
                    if (err.response.status && err.response.status == 401) {
                        logout();
                        navigate('/login');
                    } else {

                    }
                }
            };
            fetchCounts();
        }
    }, [user]);
    const callForLogout = () => {
        logout();
        navigate('/login');
    }

    return (
        <>
            {((userRoleInHeader === 'agent' && userRoleState == false)) &&
                (<section className="form-section auth-bg">
                    <div className="container-fluid">
                        <div className="form-container register-box">
                            <div className="form-wrapper">
                                <div className="register-confirm">
                                    <img src="../../assets/images/waiting-r.png" alt="" />
                                    <h3 className="title">Thanks for Registering to <br />Become a REFR Agent.</h3>
                                    <p className="desc desc_red">Unfortunately we are not licensed in your state yet. We will notify you when we are licensed in you state</p>
                                    <button className="btn btn-submit" onClick={callForLogout}>Logout</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>)
            }

            {((userRoleInHeader === 'agent' && userRoleState == true) || (userRoleInHeader === 'ambassador') || (userRoleInHeader === 'admin')) &&
                (
                    <section className='dashboard-component sec-pt sec-pb'>
                        <div className="container-fluid">
                            <h2 className='main-heading'>Dashboard</h2>
                            <div className="dashboard-container">

                                {/* New Administrator Dashboard */}

                                <div className="dashboard-admin-container">
                                    
                                    <div className="my-network-dash dashboard_grid">
                                        <NavLink className="navlink-c my-net-card" to="/app/my-network">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='pink dash_icon'> <img src="assets/images/users.png" alt="" /></p>

                                                            <p className='title'>My <br />Network </p>
                                                        </div>
                                                        <p className='number mb-0'>{userNetworkCount}</p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>
                                        <NavLink className="navlink-c my-net-card" to={(userRoleInHeader === 'admin') ? '/app/referral-list-for-admin' : '/app/referral-list'}>
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='green dash_icon'> <img src="assets/images/Pending Referrals.png" alt="" /></p>

                                                            <p className='title'>Pending <br />Referrals</p>
                                                        </div>
                                                        <p className='number mb-0'>{userReferralCount}</p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>
                                        <NavLink className="navlink-c my-net-card">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='blue dash_icon'> <img src="assets/images/Vector.png" alt="" /></p>
                                                            {(userRoleInHeader === 'admin') ?
                                                                (<p className='title'>REFR Lifetime <br />Commissions</p>) :
                                                                (<p className='title'>Lifetime <br />Commissions</p>)}
                                                        </div>
                                                        {/* <p className='number mb-0'>$9,000<sup>.05</sup></p> */}
                                                        <p className='number mb-0 currency_data'>
                                                            {
                                                                (referralCommission.finalMyLifetimeEarning > 0) && (<><Currency minimumFractionDigits="0" maximumFractionDigits="0" locale="en-US" currency="USD" >
                                                                    {referralCommission.finalMyLifetimeEarning}</Currency></>)
                                                            }
                                                            {
                                                                (!referralCommission.finalMyLifetimeEarning) && (0)
                                                            }
                                                            
                                                            {
                                                                (referralCommission.decimalMyLifetimeEarning) && (<sup>
                                                                    {"." + referralCommission.decimalMyLifetimeEarning}
                                                                </sup>)
                                                            }
                                                        </p>
                                                        
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>
                                        <NavLink className="navlink-c my-net-card">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='blue dash_icon'> <img src="assets/images/Vector.png" alt="" /></p>

                                                            {(userRoleInHeader === 'admin') ?
                                                                (<p className='title'>REFR Lifetime <br /> Completed Referrals</p>) :
                                                                (<p className='title'>Lifetime <br /> Completed Referrals</p>)}
                                                        </div>
                                                        {/* <p className='number mb-0'>$1,000<sup>.05</sup></p> */}
                                                        <p className='number mb-0 currency_data'>
                                                            {
                                                                (referralCommission.finalMyLifetimeReferralCount > 0) ? referralCommission.finalMyLifetimeReferralCount : 0
                                                            }


                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>
                                    </div>
                                    <div className="my-network-dash dashboard_grid">


                                        <NavLink className="navlink-c my-net-card">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='pink dash_icon'><img src="assets/images/potential.png" alt="" /></p>


                                                            {(userRoleInHeader === 'admin') ?
                                                                (<p className='title'>REFR Potential <br />Commissions</p>) :
                                                                (<p className='title'>Potential <br />Commissions</p>)}

                                                        </div>
                                                        {/* <p className='number mb-0'>$2,000<sup>.05</sup></p> .<sup>{userReferralPotentialDecimal}</sup>*/}
                                                        <p className='number mb-0 currency_data'>
                                                            {
                                                                (referralCommission.finalMyPotentialEarning > 0) && (<><Currency minimumFractionDigits="0" maximumFractionDigits="0" locale="en-US" currency="USD" >{referralCommission.finalMyPotentialEarning}
                                                                </Currency></>)
                                                            }
                                                            {
                                                                (!referralCommission.finalMyPotentialEarning) && (0)
                                                            }
                                                            {
                                                                (referralCommission.decimalMyPotentialEarning) && (<sup>
                                                                    {"." + referralCommission.decimalMyPotentialEarning}
                                                                </sup>)
                                                            }
                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>
                                        <NavLink className="navlink-c my-net-card">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='green dash_icon'> <img src="assets/images/Pending-Comissions.png" alt="" /></p>

                                                            {(userRoleInHeader === 'admin') ?
                                                                (<p className='title'>REFR Pending <br />Commissions</p>) :
                                                                (<p className='title'>Pending <br />Commissions</p>)}
                                                        </div>
                                                        {/* <p className='number mb-0'>$5,00<sup>.00</sup></p> <sup>{'.'+userReferralPendingDecimal}</sup>*/}
                                                        <p className='number mb-0 currency_data'>
                                                            {
                                                                (referralCommission.finalMyPendingEarning > 0) && (<><Currency minimumFractionDigits="0" maximumFractionDigits="0" locale="en-US" currency="USD" >
                                                                    {referralCommission.finalMyPendingEarning}</Currency></>)
                                                            }
                                                            {
                                                                (!referralCommission.finalMyPendingEarning) && (0)
                                                            }
                                                            {
                                                                (referralCommission.decimalMyPendingEarning) && (<sup>
                                                                    {"." + referralCommission.decimalMyPendingEarning}
                                                                </sup>)
                                                            }
                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>
                                        <NavLink className="navlink-c my-net-card">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='yellow dash_icon'> <img src="assets/images/YTD-Closed-Commissions-1.png" alt="" /></p>
                                                            {(userRoleInHeader === 'admin') ?
                                                                (<p className='title'>REFR YTD <br />Commissions</p>) :
                                                                (<p className='title'>YTD <br />Commissions</p>)}
                                                        </div>
                                                        {/* <p className='number mb-0'>$1,000<sup>.05</sup></p> */}
                                                        <p className='number mb-0 currency_data'>
                                                            {
                                                                (referralCommission.finalMyYTDEarning > 0) && (<><Currency minimumFractionDigits="0" maximumFractionDigits="0" locale="en-US" currency="USD" >
                                                                    {referralCommission.finalMyYTDEarning}</Currency></>)
                                                            }
                                                            {
                                                                (!referralCommission.finalMyYTDEarning) && (0)
                                                            }
                                                            {
                                                                (referralCommission.decimalMyYTDEarning) && (<sup>
                                                                    {"." + referralCommission.decimalMyYTDEarning}
                                                                </sup>)
                                                            }
                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>

                                        <NavLink className="navlink-c my-net-card">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='yellow dash_icon'> <img src="assets/images/YTD-Closed-Commissions-1.png" alt="" /></p>

                                                            {(userRoleInHeader === 'admin') ?
                                                                (<p className='title'>REFR YTD <br /> Completed Referrals</p>) :
                                                                (<p className='title'>YTD <br /> Completed Referrals</p>)}
                                                        </div>
                                                        {/* <p className='number mb-0'>$1,000<sup>.05</sup></p> */}
                                                        <p className='number mb-0 currency_data'>
                                                            {
                                                                (referralCommission.finalMyYTDReferralCount > 0) ? referralCommission.finalMyYTDReferralCount : 0
                                                            }

                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>




                                    </div>
                                    {((userRoleInHeader === 'agent' && userRoleState == true) || (userRoleInHeader === 'ambassador')) &&
                                    (
                                    <div className="my-network-dash dashboard_grid">
                                        
                                        <NavLink className="navlink-c my-net-card" to={(userRoleInHeader === 'admin') ? '/app/referral-list-for-admin' : '/app/referral-list'}>
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='green dash_icon'> <img src="assets/images/Pending Referrals.png" alt="" /></p>

                                                            <p className='title'>Downline Pending <br />Referrals</p>
                                                        </div>
                                                        <p className='number mb-0'>{userReferralDownlineUserCount}</p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>
                                        <NavLink className="navlink-c my-net-card">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='blue dash_icon'> <img src="assets/images/Vector.png" alt="" /></p>
                                                            
                                                                <p className='title'>Downline Lifetime <br />Commissions</p>
                                                        </div>
                                                        {/* <p className='number mb-0'>$9,000<sup>.05</sup></p> */}
                                                        <p className='number mb-0 currency_data'>
                                                            {
                                                                (referralCommission.finalDownlineLifetimeEarning > 0) && (<><Currency minimumFractionDigits="0" maximumFractionDigits="0" locale="en-US" currency="USD" >
                                                                    {referralCommission.finalDownlineLifetimeEarning}</Currency></>)
                                                            }
                                                            {
                                                                (!referralCommission.finalDownlineLifetimeEarning) && (0)
                                                            }
                                                            
                                                            {
                                                                (referralCommission.decimalDownlineLifetimeEarning) && (<sup>
                                                                    {"." + referralCommission.decimalDownlineLifetimeEarning}
                                                                </sup>)
                                                            }
                                                        </p>
                                                        
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>
                                        <NavLink className="navlink-c my-net-card">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='blue dash_icon'> <img src="assets/images/Vector.png" alt="" /></p>
                                                            
                                                                <p className='title'>Downline Lifetime <br /> Completed Referrals</p>
                                                        </div>
                                                        {/* <p className='number mb-0'>$1,000<sup>.05</sup></p> */}
                                                        <p className='number mb-0 currency_data'>
                                                            {
                                                                (referralCommission.finalDownlineLifetimeReferralCount > 0) ? referralCommission.finalDownlineLifetimeReferralCount : 0
                                                            }


                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>
                                    </div>)}
                                    {((userRoleInHeader === 'agent' && userRoleState == true) || (userRoleInHeader === 'ambassador')) &&
                                    (
                                    <div className="my-network-dash dashboard_grid">


                                        <NavLink className="navlink-c my-net-card">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='pink dash_icon'><img src="assets/images/potential.png" alt="" /></p>


                                                            <p className='title'>Downline Potential <br />Commissions</p>

                                                        </div>
                                                        {/* <p className='number mb-0'>$2,000<sup>.05</sup></p> .<sup>{userReferralPotentialDecimal}</sup>*/}
                                                        <p className='number mb-0 currency_data'>
                                                            {
                                                                (referralCommission.finalDownlinePotentialEarning > 0) && (<><Currency minimumFractionDigits="0" maximumFractionDigits="0" locale="en-US" currency="USD" >{referralCommission.finalDownlinePotentialEarning}
                                                                </Currency></>)
                                                            }
                                                            {
                                                                (!referralCommission.finalDownlinePotentialEarning) && (0)
                                                            }
                                                            {
                                                                (referralCommission.decimalDownlinePotentialEarning) && (<sup>
                                                                    {"." + referralCommission.decimalDownlinePotentialEarning}
                                                                </sup>)
                                                            }
                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>
                                        <NavLink className="navlink-c my-net-card">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='green dash_icon'> <img src="assets/images/Pending-Comissions.png" alt="" /></p>

                                                            <p className='title'>Downline Pending <br />Commissions</p>
                                                        </div>
                                                        {/* <p className='number mb-0'>$5,00<sup>.00</sup></p> <sup>{'.'+userReferralPendingDecimal}</sup>*/}
                                                        <p className='number mb-0 currency_data'>
                                                            {
                                                                (referralCommission.finalDownlinePendingEarning > 0) && (<><Currency minimumFractionDigits="0" maximumFractionDigits="0" locale="en-US" currency="USD" >
                                                                    {referralCommission.finalDownlinePendingEarning}</Currency></>)
                                                            }
                                                            {
                                                                (!referralCommission.finalDownlinePendingEarning) && (0)
                                                            }
                                                            {
                                                                (referralCommission.decimalDownlinePendingEarning) && (<sup>
                                                                    {"." + referralCommission.decimalDownlinePendingEarning}
                                                                </sup>)
                                                            }
                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>
                                        <NavLink className="navlink-c my-net-card">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='yellow dash_icon'> <img src="assets/images/YTD-Closed-Commissions-1.png" alt="" /></p>
                                                            <p className='title'>Downline YTD <br />Commissions</p>
                                                        </div>
                                                        {/* <p className='number mb-0'>$1,000<sup>.05</sup></p> */}
                                                        <p className='number mb-0 currency_data'>
                                                            {
                                                                (referralCommission.finalDownlineYTDEarning > 0) && (<><Currency minimumFractionDigits="0" maximumFractionDigits="0" locale="en-US" currency="USD" >
                                                                    {referralCommission.finalDownlineYTDEarning}</Currency></>)
                                                            }
                                                            {
                                                                (!referralCommission.finalDownlineYTDEarning) && (0)
                                                            }
                                                            {
                                                                (referralCommission.decimalDownlineYTDEarning) && (<sup>
                                                                    {"." + referralCommission.decimalDownlineYTDEarning}
                                                                </sup>)
                                                            }
                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>

                                        <NavLink className="navlink-c my-net-card">
                                            <Card>
                                                <Card.Body>
                                                    <div className="box">
                                                        <div className="box-img">
                                                            <p className='yellow dash_icon'> <img src="assets/images/YTD-Closed-Commissions-1.png" alt="" /></p>

                                                            <p className='title'>Downline YTD <br /> Completed Referrals</p>
                                                        </div>
                                                        {/* <p className='number mb-0'>$1,000<sup>.05</sup></p> */}
                                                        <p className='number mb-0 currency_data'>
                                                            {
                                                                (referralCommission.finalDownlineYTDReferralCount > 0) ? referralCommission.finalDownlineYTDReferralCount : 0
                                                            }

                                                        </p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </NavLink>

                                    </div>)}
                                </div>

                            </div>
                        </div>
                    </section>
                )
            }

        </>
    )
}

export default Dashboard
