import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { createRoot } from 'react-dom/client'
import { Alert, CircularProgress, Typography } from '@mui/material';
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
// import 'datatables.net-select';
// import 'datatables.net-select-dt';
import $ from 'jquery';
// import { CheckBox } from '@mui/icons-material';
import { Avatar, IconButton, Switch } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputText } from 'primereact';
import { Checkbox } from 'primereact/checkbox';
import axios, { baseUrl } from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import { Button } from 'primereact/button';
import EmailModal from "./EmailModal";
import Swal from 'sweetalert2';

const MyNetworkTest = () => {
    const { user,logout } = useAuth();
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [editRowId, setEditRowId] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [showClearSearch, setShowClearSearch] = useState(false);
    useEffect(() => {
        if (user !== null) {
            //alert('line 28');
            $.fn.dataTable.ext.errMode = 'none';
            fetchEmailTemplateData();
        }
    }, [user]);
    const parentToChild = () => {
        // if (show) {
        //   setShow(false);
        // } else {
        //   setShow(true);
        // }
        // const timeout = setTimeout(() => {
        //     setShow(false)
        //   }, 4000)

        //   return () => clearTimeout(timeout)
      }

    function fetchEmailTemplateData() {
        const accessToken = window.localStorage.getItem('accessToken');

        const table = $('.emailTemplateTable').on('preXhr.dt', function ( e, settings, data ) {
            if (settings.jqXHR) settings.jqXHR.abort();
        }).DataTable({
            "language": {
                "filter_tab": "",
                "search": "",
                'searchPlaceholder': "Search by name, subject",
                "sEmptyTable": "No records found"
            },
            dom: '<"top"f>rt<"bottom pBottom"lip><"clear">',
            order: [[1, 'asc']],
            //"ordering": false,
            responsive: true,
            destroy: true,
            processing: true,

            "lengthMenu": [[10, 20, 25, 30, 50, 100, 200, 500], [10, 20, 25, 30, 50, 100, 200, 500]],
            "serverSide": true,
            "serverMethod": 'POST',
            'ajax': {
                'url': baseUrl + 'list-email-template',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                'data': function (data) {
                    data.user_id = user.id;
                    data.search_value = $('#searchbarTable').val();
                },
                error: function (jqXHR,error, errorThrown) {
                    //alert(jqXHR.status);
                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{

                    }
                }
            },
            "drawCallback": function (settings) {
                // Here the response
                var response = settings.json;


                $("#spanEmailTemplateCount").html('(' + response.emailTemplateCount + ')');
                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.pBottom').hide();
                else
                    $('.pBottom').show();
            },
            "columnDefs":
                [
                    {
                        "targets": 0,
                        "width": "5%",
                        "title": "#",
                        "data": "index",
                        "name": "index",
                        "searchable": true,
                        "visible": true,
                        "orderable": false,
                        "cellType": "td",
                        "className": "bold-td",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 1,
                        "width": "25%",
                        "title": "Name",
                        "data": "name",
                        "name": "name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [1],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "bold-td",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 2,
                        "width": "40%",
                        "title": "Subject",
                        "data": "subject",
                        "name": "subject",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [2],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "email-subject desktop",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 3,
                        "width": "15%",
                        "title": "Created On",
                        "data": "created",
                        "name": "created",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [3],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "desktop",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 4,
                        "width": "10%",
                        "title": "Action",
                        "data": "id",
                        "visible": true,
                        "searchable": false,
                        "orderable": false,
                        "className": "desktop tablet-l tablet-p",
                        "createdCell": (td, cellData, rowData, row, col) => {

                            let isDeleteShow = false;
                            if (rowData.is_user_can_delete === 1) {
                                isDeleteShow = true;
                            }
                            createRoot(td).render(<ul className='action-icon'><li className='action-list-icon'>
                                <a href='javascript:void(0)' onClick={(e) => handleEdit(e, `${rowData.id}`)}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_3722_21796)">
                                            <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3722_21796">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>
                            </li>
                                {(isDeleteShow) && (<li className='action-list-icon'>
                                    <a href='javascript:void(0)' onClick={(e) => handleDelete(e, `${rowData.id}`)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_13698)">
                                                <path d="M3.33398 5.83398H16.6673" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.33398 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.666 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.16602 5.83398L4.99935 15.834C4.99935 16.276 5.17494 16.6999 5.4875 17.0125C5.80006 17.3251 6.22399 17.5007 6.66602 17.5007H13.3327C13.7747 17.5007 14.1986 17.3251 14.5112 17.0125C14.8238 16.6999 14.9993 16.276 14.9993 15.834L15.8327 5.83398" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_13698">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </a>
                                </li>

                                )}
                            </ul>);
                        },
                    }
                ]
        });
        $('#searchbarTable').keyup(function () {
            if(($(this).val().length >= 3) || ($(this).val().length === 0)){
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else
            {
                setShowClearSearch(false);
            }   
        })
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

        $(".emailTemplateTable").on('click', 'th', function () {
            $(".emailTemplateTable thead th").each(function (i, th) {
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });

        $(".emailTemplateTable th").first().click().click();
        // $('#clearSearchTable').click(function () {
        //     $('#searchbarTable').val('');
        //     table.search('').draw();
        // })
    }
    const handleClearSearch = () => {
        $(".searchbarTable").val("");
        setShowClearSearch(false);
        $('.emailTemplateTable').DataTable().ajax.reload();
    }
    const handleEdit = (event, rowId) => {
        setShowEmailModal(true)
        setEditRowId(rowId);
    }

    const handleDelete = async (event, rowId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                callDelete(rowId)
            }
        })
    }
    const width = {
        width: "100%",
    }

    const callDelete = async (rowId) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('email_template_id', rowId);

        try {
            const response = await axios.post('/delete-email-template', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                $('.emailTemplateTable').DataTable().ajax.reload();
                //fetchEmailTemplateData();
            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }else{
                Swal.fire({
                    title: '',
                    text: "Something went wrong",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })
            }
        }
    }
    const handleShowHideEmailModal = () => {
        setEditRowId(0)
        if (showEmailModal) {
            //alert('if');
            setShowEmailModal(false)
        }
        else {
            //alert('else');
            setShowEmailModal(true)
        }
    }
    const changeStatusEmailTemplate = () => {
        setEditRowId(0)
        setShowEmailModal(false)
    }
    const reloadListing = () => {
        setEditRowId(0)
        $('.emailTemplateTable').DataTable().ajax.reload();
        // fetchEmailTemplateData();
    }
    return (
        <>
            <section className='table-component no-tabs sec-pt sec-pb'>
                <div className="container-fluid">
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                            {errorMessage}
                        </Alert>
                    )}
                        { (show === true) && (<div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                            <Alert sx={{ mb: 1 }} severity="success" color="info" dismissible>

                            <p>
                                Information update successfully !!
                            </p>

                            </Alert>
                        </div>)}
                    <div className="table-search">
                        <h2 className='main-heading mb-0 email-heading'>Email Template <span id="spanEmailTemplateCount">(0)</span></h2>
                        <div className='tableSearch emailtable-search'>
                            <span className="p-input-icon-right">
                                <InputText id='searchbarTable'  autoComplete="off" className="searchbarTable email-temp-search" placeholder='Search by name, subject' />
                                {
                                    (!showClearSearch) && (<i className="pi pi-search" />)
                                }
                                {
                                    (showClearSearch) && (<a  className="close-icon" href="javascript:;" onClick={handleClearSearch} ><i className="pi pi-times" /></a>)
                                }
                            </span>
                            {/* <span className='filter-table'>
                                <a href="javascript:;" className="clearSearchTable" id="clearSearchTable">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20" cy="20" r="19.5" fill="white" stroke="#828C99" />
                                        <g clip-path="url(#clip0_711_20484)">
                                            <path d="M27.5 13.3335V15.0002H26.6667L21.6667 22.5002V28.3335H18.3333V22.5002L13.3333 15.0002H12.5V13.3335H27.5Z" fill="#828C99" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_711_20484">
                                                <rect width="20" height="20" fill="white" transform="translate(10 10)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>

                            </span> */}
                            <span className='add-email-template ms-2'>
                            <Button label="Email Template" className='btn btn-submit email-temp-plus-btn' icon="pi pi-plus-circle" onClick={handleShowHideEmailModal} />
                            </span>
                            <span className='add-email-template ms-2'>
                            <Button label="Mass Email" className='btn btn-submit email-temp-plus-btn' icon="pi pi-plus-circle" onClick={(e) => navigate('/app/mass-email')} />
                            </span>
                        </div>
                    </div>

                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                            <table className="short-table display networkTable emailTemplateTable" cellSpacing="0" style={width} id="networkTable">
                                <thead>
                                    <tr>
                                        <th className='hide_sorting'>Index </th>
                                        <th>Name </th>
                                        <th>Subject </th>
                                        <th>Created On</th>
                                        <th className='hide_sorting'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>


                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </section>
            {
                (showEmailModal) && (<EmailModal showEmailModal={showEmailModal} changeStatusEmailTemplate={changeStatusEmailTemplate} parentToChild={parentToChild} reloadListing={reloadListing} editRowId={editRowId} />)
            }
        </>

    )
}

export default MyNetworkTest;
