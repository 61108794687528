import React, { useState, useEffect } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import { Alert, CircularProgress, Typography } from '@mui/material';
import axios from "../../../axiosCall.js";
import useAuth from "./../../../hooks/useAuth";
import Swal from 'sweetalert2';
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

const PreviewEmail = () => {
    const secretKey = "REFR2022@GALAXY";
    const search = useLocation().search;
    const adminSendLogId = new URLSearchParams(search).get('u');
    let requestId = '';
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [email, setEmail] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [ccEmail, setCcEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    if (adminSendLogId === null) {
        navigate('/dashboard');
    } else {
        //alert('else');
        let idDecrypt = AES.decrypt(adminSendLogId, secretKey);
        if (idDecrypt == "") {
            //console.log('if')
            navigate('/dashboard');
        }
        else {
            //console.log('else')
            requestId = JSON.parse(idDecrypt.toString(Utf8));
        }
    }
    const { user, logout } = useAuth();
    useEffect(() => {
        
        if (user !== null) {
            let formData = new FormData();
            formData.append('admin_send_email_to_user_log_id', requestId);

            const fetchAdminSendEmailLogData = async () => {
                try {
                    const response = await axios.post("/get-admin-send-email-to-user-log", formData);
                    const { Status, StatusMessage, Error, Result } = response.data;

                    if (Status === true) {
                        setUserId(Result[0].users_id);
                        setEmail(Result[0].email);
                        if(Result[0].referral_user_type === 'client_and_referring_assigned_agent')
                        {
                            setClientEmail(Result[0].client_email);

                            setCcEmail(Result[0].referring_user_email);
                        }
                        
                        setSubject(Result[0].subject);
                        if(Result[0].message)
                        {
                            let message = Result[0].message.replace(/<br>/g,'');
                            message = message.split('<p></p>').join('');
                            setMessage(message);
                        }
                    }
                    else {
                        navigate('/dashboard');
                    }
                } catch (err) {
                    if (err.response.status && err.response.status == 401) {
                        logout();
                        navigate('/login');
                    } else {
                        setMessage("Something went wrong");
                    }
                }
            };
            fetchAdminSendEmailLogData();
        }
    }, [user, requestId]);

    const handleGoBackEdit = () => {
        
        const idEncryptedString = AES.encrypt(JSON.stringify(requestId), secretKey).toString();
        let finalUrl = 'e=' + encodeURIComponent(idEncryptedString);

        navigate('/app/referral-user-email-template?' + finalUrl);
    }
    const sendEmailToUser = async() => 
    {
        let formData = new FormData();
        formData.append('admin_send_email_to_user_log_id', requestId);
        setLoading(true);
        try {
            const response = await axios.post("/admin-send-email-to-user", formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                navigate('/app/referral-list-for-admin');
                
            }
            
        } catch (err) {
            setLoading(false);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                setErrorMessage("Something went wrong");
                
            }
        }
    }
    return (
        <>
            <section className='emailTemplate-component sec-pt sec-pb'>
                <div className="container">
                    <div className="email-container">
                        <h2 className='main-heading'>Email Template</h2>
                        {errorMessage && (
                            <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                {errorMessage}
                            </Alert>
                        )}
                        <div className="form-container">
                            <form>
                                <div className="email-preview">
                                    {
                                        (!clientEmail) && (<p className='title'>To :  {email}</p>)
                                    }
                                    {
                                        (clientEmail) && (<p className='title'>To :  {email+', '+clientEmail}</p>)
                                    }
                                    
                                    {
                                        (ccEmail) && (<p className='title'>CC :  {ccEmail}</p>)
                                    }
                                    
                                    <p className='title'><span>Subject</span> :  {subject}</p>
                                    <div className='hr-line'>
                                        <hr />
                                    </div>
                                    <div className='body-text'>
                                    <div dangerouslySetInnerHTML={{__html: message }}></div>
                                    </div>
                                    {/* <div className="footer-content">
                                        <p></p>

                                        <p className='regards'>Best Regards</p>
                                        <p className='name'>REFR</p>
                                    </div> */}
                                </div>
                                <div className="field text-center email-btns preview-btns email-preview-btn">
                                    <button className="btn btn-cancel me-3" type="button" onClick={(e) => navigate('/app/referral-list-for-admin')} disabled={loading}>Cancel</button>
                                    <button className="btn btn-cancel me-3" type="button" onClick={()=>handleGoBackEdit()} disabled={loading}>Go Back and Edit</button>
                                    <button className="btn btn-submit btn-submit-loader" type="button" onClick={sendEmailToUser}disabled={loading}>Send email {loading && (<Typography align="center">
                                            <CircularProgress value={66} />
                                        </Typography>)}</button>
                                </div>
                            </form>
                        </div>
                        <div className="email-template-letter">
                            <img src="assets/images/Open-Email.png" alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PreviewEmail