import React, { useState, useEffect,useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Badge } from 'primereact';
import { InputText } from "primereact";
import { Password } from 'primereact/password';
import axios from "../../axiosCall.js";
import useAuth from "./../../hooks/useAuth";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import Swal from 'sweetalert2';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { Avatar } from "primereact";
// import { Card } from "material-ui";
import { Card } from "react-bootstrap";
import EditUser from "./EditUser";
import UploadDocuments from "./UploadDocuments";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';

const LicenceDetails = () => {
    const secretKey = "REFR2022@GALAXY";
    let requestUserId = '';
    let requestListingType = '';
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get('u');
    const mode = new URLSearchParams(search).get('m');

    const [showEditUser, setShowEditUser] = useState(false);

    const [showUploadDocs, setShowUploadDocs] = useState(false);
    const [copied, setCopyStatus] = React.useState(false)
    const [editRowId, setEditRowId] = useState(0);
    const [editRowType, setEditRowType] = useState(false);
    const [editRowTypeTemp, setEditRowTypeTemp] = useState(false);
    const [dbStatesName, setDbStatesName] = useState([]);
    const [dbBrokerageRoleData, setBrokerageRoleData] = useState([]);
    const [dbUserFullName, setDbUserFullName] = useState('');
    const [callFrom, setCallFrom] = useState('');
    const [showPowerUser, setShowPowerUser] = useState(false);
    //alert(mode);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [profilePic, setProfilePic] = useState("");
    if (userId === null || mode === null) {
        navigate('/dashboard');
    } else {
        //alert('else');
        let userIdDecrypt = AES.decrypt(userId, secretKey);
        let modeDecrypt = AES.decrypt(mode, secretKey);

        //console.log('userIdDecrypt ' + userIdDecrypt)
        if (userIdDecrypt == "") {
            //console.log('if')
            navigate('/dashboard');
        }
        else {
            //console.log('else')
            requestUserId = JSON.parse(userIdDecrypt.toString(Utf8));
        }


        if (modeDecrypt != '')
            requestListingType = JSON.parse(modeDecrypt.toString(Utf8));
        else
            navigate('/dashboard');
    }

    const { user, logout } = useAuth();
    const [isMLSShow, setIsMLSShow] = useState(false);
    const [isMLSTabShow, setIsMLSTabShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        const fetchBrokerageRoleData = async () => {
            try {
                const response = await axios.post('/get-all-Brokerage-role-data');
                const { Status, StatusMessage, Error, Result } = response.data;

                if (Status === true) {
                    //alert(Result)
                    setBrokerageRoleData(Result);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchBrokerageRoleData();
        const fetchStatesData = async () => {
            try {
                const response = await axios.post("/get-all-states");
                const { Status, Result } = response.data;

                if (Status === true) {
                    //alert(Result)
                    setDbStatesName(Result);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchStatesData();
    }, []);

    const changeStatusEditUser = () => {
        setEditRowId(0)
        setEditRowType('')
        setShowEditUser(false)
        setCallFrom('');
    }

    const handleEdit = (rowId, rowType, mode, callFrom) => {
        setShowEditUser(true)
        setEditRowId(rowId);
        setEditRowType(rowType);
        setEditRowTypeTemp(mode);
        setCallFrom(callFrom);
    }
    const handleEmail = (rowId) => {
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();

        let finalUrl = 'u=' + encodeURIComponent(userIdEncryptedString);

        navigate('/app/email-template?' + finalUrl);
    }

    const handleDocumentsUpload = (rowId, rowType, userFullName, mode) => {

        setShowUploadDocs(true)
        setCallFrom(mode);
        setDbUserFullName(userFullName)
        setEditRowId(rowId);
        setEditRowType(rowType);
    }
    const handleDeleteDocuments = (rowId, mode, media) => {
        Swal.fire({
            title: 'Are you sure?',
            html: "You won't be able to reverse this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                handleDeleteDocumentsCall(rowId, mode, media);
            }
        })
    }
    const handleDeleteDocumentsCall = async (rowId, mode, media) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('user_id', rowId);
        formData.append('mode', mode);
        formData.append('media', media);
        formData.append('is_profile_page_call', 0);
        
        try {
            const response = await axios.post('/delete-docs_by_admin', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {

                fetchNetworkDetailData();
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    }
    const changeStatuUpload = () => {
        setDbUserFullName('');
        setCallFrom('');
        setEditRowId(0)
        setEditRowType('')
        setShowUploadDocs(false)
    }
    const handleDelete = (rowId, referralCount, assignedAgentCount) => {

        let message = '';
        let messageForDelete = '';
        if ((referralCount > 0) && (assignedAgentCount > 0)) {
            let referralText = "referral";
            let referralTextTemp = "referral";
            if (referralCount > 1)
                referralTextTemp = "referrals";

            if (assignedAgentCount > 1)
                referralText = "referrals";
            message = "This can't be deleted, sorry!<br>The user assigned as agent for " + assignedAgentCount + " " + referralText + " and created " + referralCount + " " + referralTextTemp + ".";
        }
        else if (referralCount > 0) {
            let referralTextTemp = "referral";
            if (referralCount > 1)
                referralTextTemp = "referrals";
            message = "This can't be deleted, sorry!<br>The user created " + referralCount + " " + referralTextTemp + ".";
        }
        else if (assignedAgentCount > 0) {
            let referralText = "referral";
            if (assignedAgentCount > 1)
                referralText = "referrals";

            message = "This can't be deleted, sorry!<br>The user assigned as agent for " + assignedAgentCount + " " + referralText + ".";
        }
        else {
            messageForDelete = "You won't be able to reverse this!";
        }
        if (message) {
            Swal.fire({
                title: '',
                html: message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

            })
        }
        else {
            Swal.fire({
                title: 'Are you sure?',
                html: messageForDelete,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {

                    callDelete(rowId)
                }
            })
        }
    }
    const callDelete = async (rowId) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('user_id', rowId);

        try {
            const response = await axios.post('/delete-user', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                navigate('/app/my-network');
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    }
    const reloadListing = () => {
        setEditRowId(0)
        setEditRowType('')
        setEditRowTypeTemp('')
        fetchNetworkDetailData();
    }
    const [datas, setDatas] = useState(
        {
            type: 'ambassador',
            name: 'Alicia Edward',
            short_name: 'AE',
            profile_pic: '',
            license_number: '8896632',
            license_expiry_date: '10/25/2024',
            license_state: 'michigan',
            business_address: '2972 Westheimer Rd. Santa Ana,Illinois 85486',
            email: 'darellsteward.com',
            contact_number: '(480) 555-1234',
            created: '9 / 29 / 22',
            power_user_checked:'',
            mls_checked:'',
            mls_name: 'John Doe',
            mls_website: 'https://www.xyz.com',
            mls_username: 'michigan',
            mls_password: '1234',
            brokerage_name: 'Guarantee Brokers',
            broker_name: 'Dravid Doe',
            broker_email: 'dravid@yopmail.com',
            broker_phone: '(480) 555-1234',
            brokerage_role: '',
            brokerage_role_slug: '',
            broker_of_record_detail: '',
            office_manager_detail: '',
            team_lead_detail: '',
            brokerage_role_other_detail: '',
            broker_license_number: '',
            broker_license_state_name:'',
            upline_user_name: '',
            upline_user_email: '',
            upline_user_type: '',
            master_referral_services_fee_media: '',
            brokerage_participation_agreement_media: '',
            independent_contractor_agreement_media: '',
            upload_personal_media: '',
            created_referral_count: '',
            assigned_as_agent_referral_count: '',
            is_email_verified: '',
            broker_license_json: '',
            refrUserName:'',
            plan_name: '',
            plan_start_date: '',
            plan_end_date:'',
            messageForDocuments:'',
            matching_brokerage_of_record_id:'',
            is_license_flag:'',
        }
    );
    const fetchNetworkDetailData = async () => {
        let formData = new FormData();
        formData.append('user_id', requestUserId);
        formData.append('mode', requestListingType);
        try {
            const response = await axios.post("/get-user-data", formData);
            const { Status, StatusMessage, Error, Result } = response.data;


            if (Status === true) {
                let isChecked = '';
                let isCheckedPowerUser = '';
                if (Result.user.type === 'ambassador' && requestListingType !== 'pending' && user.type === 'admin' && Result.user.is_verified === 1) {
                    setIsMLSTabShow(true);
                }
                else {
                    setIsMLSTabShow(false);
                }

                if (Result.user.type === 'ambassador' && requestListingType !== 'pending' && Result.user.is_verified === 1) {
                    if (Result.user.is_mls === 1) {
                        setIsMLSShow(true);
                        isChecked = "checked";
                    }
                    else {
                        setIsMLSShow(false);
                        isChecked = "";
                    }
                }

                if (Result.user.type === 'ambassador' && requestListingType !== 'pending' && Result.user.is_verified === 1) {
                    setShowPowerUser(true);
                    if (Result.user.is_power_user === 1) {
                        isCheckedPowerUser = "checked";
                    }
                    else {
                        isCheckedPowerUser = "";
                    }
                } else if (Result.user.type === 'agent' && Result.user.	is_license_flag === 1 && requestListingType !== 'pending' && Result.user.is_verified === 1) {
                    setShowPowerUser(true);
                    if (Result.user.is_power_user === 1) {
                        isCheckedPowerUser = "checked";
                    } else {
                        isCheckedPowerUser = "";
                    }
                }
                

                let matches = Result.user.name.match(/\b(\w)/g);
                let shortName = matches.join('');

                let objectDate = new Date(Result.user.created_at);

                let day = objectDate.getDate();
                let month = objectDate.getMonth() + 1;
                let year = objectDate.getFullYear();
                if (day < 10) {
                    day = '0' + day;
                }
                if (month < 10) {
                    month = `0${month}`;
                }
                // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
                //     "July", "Aug", "Sept", "Oct", "Nov", "Dec"
                // ];
                // month = monthNames[objectDate.getMonth()];

                if (Result.user.profile_pic !== null) {
                    setProfilePic(Result.user.profile_pic);
                }
                //alert(profilePic)
                //alert(MLSChecked)
                let messageForDocuments = '';
                if(Result.user.type === 'ambassador')
                {
                    if ((Result.user.is_bpa_document_missing === 1))
                    {
                        messageForDocuments = 'Missing Broker of Record Docs: Brokerage Participation Agreement';
                    }
                }

                setDatas((prevState) => {
                    return {
                        ...prevState,
                        'type': Result.user.type,
                        'name': Result.user.name,
                        'short_name': shortName,
                        'profile_pic': Result.user.profile_pic,
                        'license_number': (Result.user.license_number) ? Result.user.license_number : '-',
                        'is_license_flag':(Result.user.is_license_flag) ? Result.user.is_license_flag : '',
                        'license_expiry_date': (Result.user.license_expiry_date) ? Result.user.license_expiry_date : '-',
                        'license_state': (Result.user.license_state.abbrv) ? Result.user.license_state.abbrv : '-',
                        'business_address': (Result.user.business_address) ? Result.user.business_address : '-',
                        'email': Result.user.email,
                        'contact_number': Result.user.contact_number,
                        'created': month + '/' + day + '/' + year,
                        'mls_checked': isChecked,
                        'power_user_checked': isCheckedPowerUser,
                        'mls_name': ((Result.user.is_mls) && (Result.user_mls_info)) ? Result.user_mls_info.name : '-',
                        'mls_website': ((Result.user.is_mls) && (Result.user_mls_info)) ? Result.user_mls_info.website_url : '-',
                        'mls_username': ((Result.user.is_mls) && (Result.user_mls_info)) ? Result.user_mls_info.username : '-',
                        'mls_password': ((Result.user.is_mls) && (Result.user_mls_info)) ? Result.user_mls_info.password : '-',
                        'brokerage_name': (Result.user.brokerage_name) ? Result.user.brokerage_name : '-',
                        'broker_name': (Result.user.broker_name) ? Result.user.broker_name : '-',
                        'broker_email': (Result.user.broker_email) ? Result.user.broker_email : '-',
                        'broker_phone': (Result.user.broker_phone) ? Result.user.broker_phone : '-',
                        'broker_license_number': (Result.user.broker_license_number) ? Result.user.broker_license_number : '-',
                        'broker_license_json': (Result.user.broker_license_json) ? JSON.parse(Result.user.broker_license_json) : [],
                        'broker_license_state_name': (Result.user.broker_license_state) ? Result.user.broker_license_state.abbrv : '-',
                        'brokerage_role': (Result.user.brokerage_role) ? Result.user.brokerage_role.name : '-',
                        'brokerage_role_slug': (Result.user.brokerage_role) ? Result.user.brokerage_role.slug : '-',
                        'broker_of_record_detail': (Result.user.broker_of_record_detail) ? Result.user.broker_of_record_detail : '-',
                        'office_manager_detail': (Result.user.office_manager_detail) ? Result.user.office_manager_detail : '-',
                        'team_lead_detail': (Result.user.team_lead_detail) ? Result.user.team_lead_detail : '-',
                        'brokerage_role_other_detail': (Result.user.brokerage_role_other_detail) ? Result.user.brokerage_role_other_detail : '-',
                        'upline_user_name': ((Result.user.upline_users_id) && (Result.user.upline_users_id.id)) ? Result.user.upline_users_id.name : '-',
                        'upline_user_email': ((Result.user.upline_users_id) && (Result.user.upline_users_id.id)) ? Result.user.upline_users_id.email : '-',
                        'upline_user_type': ((Result.user.upline_users_id) && (Result.user.upline_users_id.id)) ? Result.user.upline_users_id.type : '-',
                        
                        'refrUserName': ((Result.user.username)) ? Result.user.username : '',
                        'created_referral_count': (Result.user.created_referral_count) ? Result.user.created_referral_count : 0,
                        'assigned_as_agent_referral_count': (Result.user.assigned_as_agent_referral_count) ? Result.user.assigned_as_agent_referral_count : 0,

                        'upload_personal_media': (Result.user.upload_personal_media) ? Result.user.upload_personal_media : '',
                        'master_referral_services_fee_media': (Result.user.master_referral_services_fee_media) ? Result.user.master_referral_services_fee_media : '',
                        'brokerage_participation_agreement_media': (Result.user.brokerage_participation_agreement_media) ? Result.user.brokerage_participation_agreement_media : '',
                        'independent_contractor_agreement_media': (Result.user.independent_contractor_agreement_media) ? Result.user.independent_contractor_agreement_media : '',
                        'is_email_verified': Result.user.is_verified,
                        'plan_start_date': (Result.user.user_plan_start_date) ? Result.user.user_plan_start_date : '',
                        'plan_end_date': (Result.user.user_plan_end_date) ? Result.user.user_plan_end_date : '',
                        'plan_name': (Result.user.user_plan_name) ? Result.user.user_plan_name : '',
                        'messageForDocuments':messageForDocuments,
                        'matching_brokerage_of_record_id': (Result.user.matching_brokerage_of_record_id) ? Result.user.matching_brokerage_of_record_id : '',
                        
                    };
                });
            }
            else {
                navigate('/dashboard');
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };
    useEffect(() => {

        if (user !== null) {

            fetchNetworkDetailData();
        }
    }, [user, requestUserId]);

    const handleCall = async () => {
        //alert('call')
        setLoading(true);
        try {
            let formData = new FormData();
            formData.append('user_id', requestUserId);

            const response = await axios.post('/mls-status-change', formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                let isChecked = '';
                if (Result.is_mls === 1) {
                    setIsMLSShow(true);
                    isChecked = "checked";
                }
                else {
                    setIsMLSShow(false);
                    isChecked = "";
                }
                setDatas((prevState) => {
                    return {
                        ...prevState,
                        'mls_checked': isChecked,
                    };
                });
                setLoading(false);
                Swal.fire({
                    title: '',
                    text: "MLS status changed successfully",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })
            }
            else {
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };
    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const toast = useRef(null);
    const showSuccess = () => {
        //alert('if')
        toast.current.show({severity: 'success', summary: 'Success Message', detail: 'Copied'});
    }
    const handleCallPowerUser = async () => {
        try {
            let formData = new FormData();
            formData.append('user_id', requestUserId);

            const response = await axios.post('/power-user-status-change', formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                Swal.fire({
                    title: '',
                    text: "Power user status changed successfully",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    
                })
            }
            else {

            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }else{
               
            }
        }
    };
    return (
        <>
            {/* --Notification Alert-- */}
            {
                (requestListingType === 'pending') && (<div className="alert-notification-box">
                    <Card className="noti-alert">
                        <Card.Body>
                            <div className="name-icon">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_4838_44600)">
                                        <path d="M14.92 12.9429L11.8526 10.6423V5.95607C11.8526 5.48488 11.4717 5.104 11.0005 5.104C10.5293 5.104 10.1484 5.48488 10.1484 5.95607V11.0684C10.1484 11.3368 10.2746 11.5899 10.4893 11.7501L13.8975 14.3062C14.0508 14.4213 14.2298 14.4766 14.4078 14.4766C14.6677 14.4766 14.9233 14.3599 15.0904 14.135C15.3733 13.7592 15.2966 13.2249 14.92 12.9429Z" fill="#1E2938" />
                                        <path d="M11 0C4.93423 0 0 4.93423 0 11C0 17.0658 4.93423 22 11 22C17.0658 22 22 17.0658 22 11C22 4.93423 17.0658 0 11 0ZM11 20.2959C5.8749 20.2959 1.7041 16.1251 1.7041 11C1.7041 5.8749 5.8749 1.7041 11 1.7041C16.126 1.7041 20.2959 5.8749 20.2959 11C20.2959 16.1251 16.1251 20.2959 11 20.2959Z" fill="#1E2938" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4838_44600">
                                            <rect width="22" height="22" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <p className="title">Pending approval</p>
                            </div>
                        </Card.Body>
                    </Card>
                </div>)
            }

            {/* End */}
            <section className="template-profile-view sec-pt sec-pb ">
            <Toast ref={toast} position="top-right" />
                <div className="container">
                    <div className="grid">
                        {
                            (user) && (user.type === 'admin') && (datas.type === 'ambassador') && (datas.messageForDocuments) && (<div className="col-12 md:col-12">
                                <Message severity="error" text={datas.messageForDocuments} />
                            </div>)
                        } 
                    </div>
                    <div className="mynetwork-details-title">
                        <h2 className='main-heading'>My Network</h2>
                        {errorMessage && (
                            <Alert
                                sx={{ mb: 3 }}
                                severity="error"
                                variant="filled"
                            >
                                {errorMessage}
                            </Alert>
                        )}
                        {
                                        (user) &&  (requestListingType !== 'pending') && (datas.is_email_verified === 1) && (user.type === 'admin') && (
                        <div className="title-btn">
                            <button className="btn btn-cancel btn-500 me-3" onClick={() => { handleDelete(requestUserId, datas.created_referral_count, datas.assigned_as_agent_referral_count) }}>Delete</button>
                            <button className="btn btn-submit btn-500" onClick={() => { handleEmail(requestUserId) }}>Email</button>
                        </div>)
}
                    </div>
                    <div className="cards-container row row-cols-1 row-cols-lg-2 g-1">
                        <div className="col col-basic-detail ps-lg-0 pe-lg-3">
                            <div className="card profile-card h-100 full-card">
                                <div className="card-body px-0">
                                    
                                    <div className="profile-image-components text-center pse-30">
                                        {
                                            (profilePic) && (<div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root edit-dp">
                                                <Avatar image={profilePic} className="" size="large" shape="circle" />
                                            </div>)
                                        }
                                        {
                                            (datas.short_name) && (!profilePic) && (<div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root edit-dp">{datas.short_name}</div>)
                                        }
                                        
                                        <p className='title' >{datas.name} 
                                            {
                                                (datas.type === 'ambassador') && ( <Badge style={{marginLeft: "2px"}} value="A"></Badge>)
                                            }
                                            {
                                                (datas.type === 'agent') && ( <Badge style={{marginLeft: "2px"}} value="RA"></Badge>)
                                            }
                                        </p>
                                        {
                                        (user) &&  (requestListingType !== 'pending') && (datas.is_email_verified === 1) && (user.type === 'admin') && (<button className="btn outlined-btn btn-padding" onClick={() => { handleEdit(requestUserId, datas.type, datas.type, 'section1') }}><i className="pi pi-pencil" style={{ 'fontSize': '1em' }}></i> Edit</button>)
                                        }

                                        
                                    </div>

                                    <div className='contact-list pse-30'>
                                        <ul className='list'>
                                            <li className='item'>
                                                <span><img src="../assets/images/phone-p.png" alt="" /></span>
                                                {
                                                    (datas.contact_number) && (<a className='phone' href={'tel:' + datas.contact_number}>{datas.contact_number}</a>)
                                                }
                                                {
                                                    (!datas.contact_number) && (<a className='phone' href="javascript:;">-</a>)
                                                }
                                            </li>
                                            <li className='item'>
                                                <span><img src="../assets/images/email-p.png" alt="" /></span>
                                                <a className='mail' href={`mailto:${datas.email}`}>{datas.email}</a>
                                            </li>
                                            {
                                                (user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (
                                            <li className='item'>
                                                <span><img src="../assets/images/home.png" alt="" /></span>
                                                <p className='address'>{(datas.business_address) ? datas.business_address : '-'}</p>
                                            </li>)
                                            }
                                        </ul>
                                    </div>
                                    {
                                                (user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (
                                    <div className='contact-list border-t pse-30'>
                                        <ul className='list'>
                                            <li className='item'>
                                                <span className="title-c">Upline Information</span>
                                                <p className='address'></p>
                                            </li>
                                            <li className='item'>
                                                <span className="name-title">Name</span>
                                                <p className='address'>{(datas.upline_user_name) ? datas.upline_user_name : '-'}</p>
                                            </li>
                                            <li className='item'>
                                                <span className="name-title">Email</span>
                                                <p className='address'>{(datas.upline_user_email) ? datas.upline_user_email : '-'}</p>
                                            </li>
                                            <li className='item'>
                                                <span className="name-title">Type</span>
                                                <p className='address'>{(datas.upline_user_type) ? Capitalize(datas.upline_user_type) : '-'}</p>
                                            </li>

                                        </ul>
                                    </div>)
                                    }
                                    
                                    {
                                        (user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (requestListingType !== 'pending') && (datas.is_email_verified === 1) && (<div className="documents-box pse-30">
                                        <p className="title-doc">Documents</p>
                                        {
                                        (user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (datas.type === 'ambassador') && (<>
                                        <p className="name-title">Master Referral Services Fee</p>
                                            <div className="services-btn mb-4">
                                                {
                                                    (user) && (!datas.master_referral_services_fee_media && user.type === 'admin') && (<a href='javascript:;' onClick={() => { handleDocumentsUpload(requestUserId, datas.type, datas.name, 'master_referral_services_fee_media') }} className="btn outlined-btn">Upload</a>)
                                                }
                                                {
                                                    (datas.master_referral_services_fee_media) && (<a href={datas.master_referral_services_fee_media} target="_blank" className="btn outlined-btn">Preview</a>)
                                                }
                                                {
                                                    (user) && (datas.master_referral_services_fee_media  && user.type === 'admin') && (<a href='javascript:;' className="btn outlined-btn" onClick={() => { handleDeleteDocuments(requestUserId, 'master_referral_services_fee_media', datas.master_referral_services_fee_media) }}>Delete</a>)
                                                }
                                                {
                                                    (user) && (!datas.master_referral_services_fee_media && user.type != 'admin') && (<p className="no-doc">No document uploaded by admin</p>)
                                                }
                                            </div></>)
                                        }
                                        {
                                                (user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (datas.type === 'ambassador' && datas.brokerage_role_slug === 'broker_of_record') && (<>
                                        <p className="name-title">Brokerage Participation Agreement</p>
                                        <div className="services-btn mb-4">
                                            {
                                                (user) && (!datas.brokerage_participation_agreement_media  && user.type === 'admin') && (<a href='javascript:;' onClick={() => { handleDocumentsUpload(requestUserId, datas.type, datas.name, 'brokerage_participation_agreement_media') }} className="btn outlined-btn">Upload</a>)
                                            }
                                            {
                                                (datas.brokerage_participation_agreement_media) && (<a href={datas.brokerage_participation_agreement_media} target="_blank" className="btn outlined-btn">Preview</a>)
                                            }
                                            {
                                                (user) && (datas.brokerage_participation_agreement_media  && user.type === 'admin') && (<a href='javascript:;' className="btn outlined-btn" onClick={() => { handleDeleteDocuments(requestUserId, 'brokerage_participation_agreement_media', datas.brokerage_participation_agreement_media) }}>Delete</a>)
                                            }
                                            {
                                                (user) && (!datas.brokerage_participation_agreement_media && user.type != 'admin') && (<p className="no-doc">No document uploaded by admin</p>)
                                            }
                                        </div></>)}


                                        {
                                                (user) && (user.type === 'admin') && (datas.type === 'agent') && (<>
                                        <p className="name-title">Independent Contractor Agreement</p>
                                        <div className="services-btn mb-4">
                                            
                                            {
                                                (user) && (!datas.independent_contractor_agreement_media && user.type === 'admin') && (<a href='javascript:;' onClick={() => { handleDocumentsUpload(requestUserId, datas.type, datas.name, 'independent_contractor_agreement_media') }} className="btn outlined-btn">Upload</a>)
                                            }
                                            {
                                                (datas.independent_contractor_agreement_media) && (<a href={datas.independent_contractor_agreement_media} target="_blank" className="btn outlined-btn">Preview</a>)
                                            }
                                            {
                                                (user) && (datas.independent_contractor_agreement_media && user.type === 'admin') && (<a href='javascript:;' className="btn outlined-btn" onClick={() => { handleDeleteDocuments(requestUserId, 'independent_contractor_agreement_media', datas.independent_contractor_agreement_media) }}>Delete</a>)
                                            }
                                            {
                                                (user) && (!datas.independent_contractor_agreement_media && user.type != 'admin') && (<p className="no-doc">No document uploaded by admin</p>)
                                            }
                                        </div></>)}

                                        {
                                                (user) && (user.type === 'admin') && (datas.type === 'agent') && (<>
                                        <p className="name-title">Personal W-9</p>
                                        <div className="services-btn mb-4">
                                            {
                                                (user) && (!datas.upload_personal_media && user.type === 'admin') && (<a href='javascript:;' onClick={() => { handleDocumentsUpload(requestUserId, datas.type, datas.name, 'upload_personal_media') }} className="btn outlined-btn">Upload</a>)
                                            }
                                            {
                                                (datas.upload_personal_media) && (<a href={datas.upload_personal_media} target="_blank" className="btn outlined-btn">Preview</a>)
                                            }
                                            {
                                                (user) && (datas.upload_personal_media && user.type === 'admin') && (<a href='javascript:;' className="btn outlined-btn" onClick={() => { handleDeleteDocuments(requestUserId, 'upload_personal_media', datas.upload_personal_media) }}>Delete</a>)
                                            }
                                            {
                                                (user) && (!datas.upload_personal_media && user.type != 'admin') && (<p className="no-doc">No document uploaded by user or admin</p>)
                                            }
                                        </div></>)}

                                        {
                                            (user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (datas.type === 'ambassador' && datas.brokerage_role_slug === 'broker_of_record') && (<>
                                        <p className="name-title">Brokerage W-9</p>
                                        <div className="services-btn mb-4">
                                        {
                                                (user) && (!datas.upload_personal_media && user.type === 'admin') && (<a href='javascript:;' onClick={() => { handleDocumentsUpload(requestUserId, datas.type, datas.name, 'upload_personal_media') }} className="btn outlined-btn">Upload</a>)
                                            }
                                            {
                                                (datas.upload_personal_media) && (<a href={datas.upload_personal_media} target="_blank" className="btn outlined-btn">Preview</a>)
                                            }
                                            {
                                                (user) && (datas.upload_personal_media && user.type === 'admin') && (<a href='javascript:;' className="btn outlined-btn" onClick={() => { handleDeleteDocuments(requestUserId, 'upload_personal_media', datas.upload_personal_media) }}>Delete</a>)
                                            }
                                            {
                                                (user) && (!datas.upload_personal_media && user.type != 'admin') && (<p className="no-doc">No document uploaded by user or admin</p>)
                                            }
                                        </div></>)}

                                        
                                    </div>)
                                    }
                                    
                                    
                                    
                                </div>
                                <div className="card-footer text-center">
                                    <p className="text-muted mb-0">Member Since  <span>{datas.created}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col pe-lg-0 ps-lg-3">
                            <div className="card h-100 full-card">
                                <div className="card-body">
                                    <h2 className='page-heading'>
                                        {(datas.type === 'agent') ? 'License Details' : 'Brokerage Details'}</h2>
                                    
                                    <div className='licence-fields'>
                                        {(datas.type === 'ambassador') && (<ul className='licence-list'>
                                            <li className="licence-field">
                                                <li className='license-item'><p>Brokerage Name</p></li>
                                                <li className='license-info'><p>{(datas.brokerage_name) ? datas.brokerage_name : '-'}</p></li>
                                            </li>
                                        </ul>)}

                                        <ul className='licence-list'>
                                            <li className="licence-field">
                                                <li className='license-item'><p>{(datas.type === 'agent') ? 'License Number' : 'Broker of Record Name'} </p></li>
                                                <li className='license-info'><p>{(datas.type === 'agent') ? datas.license_number : datas.broker_name}</p></li>
                                            </li>
                                        </ul>
                                        {(datas.type === 'ambassador') && (
                                            <ul className='licence-list'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>Broker of Record Email  </p></li>
                                                    <li className='license-info'><p>{ datas.broker_email} </p></li>
                                                </li>
                                            </ul>)}
                                            {(user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (datas.type === 'agent') && (
                                            <ul className='licence-list'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>License Expiration Date</p></li>
                                                    <li className='license-info'><p>{datas.license_expiry_date}</p></li>
                                                </li>
                                            </ul>)}
                                        <ul className='licence-list'>
                                            <li className="licence-field">
                                                <li className='license-item'><p>{(datas.type === 'agent') ? 'License State' : 'Broker of Record Phone'}</p></li>
                                                <li className='license-info'><p>{(datas.type === 'agent') ? datas.license_state : datas.broker_phone}</p></li>
                                            </li>
                                        </ul>

                                        {(user) && (user.type === 'admin') && (datas.type === 'agent') && (
                                            <ul className='licence-list'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>Plan Name</p></li>
                                                    <li className='license-info'><p>{(datas.plan_name) ? datas.plan_name : 'N/A'}</p></li>
                                                </li>
                                            </ul>)}
                                            {(user) && (user.type === 'admin') && (datas.type === 'agent') && (
                                            <ul className='licence-list'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>Start Date</p></li>
                                                    <li className='license-info'><p>{(datas.plan_start_date) ? datas.plan_start_date : 'N/A'}</p></li>
                                                </li>
                                            </ul>)}
                                            {(user) && (user.type === 'admin') && (datas.type === 'agent') && (
                                            <ul className='licence-list'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>Renews On</p></li>
                                                    <li className='license-info'><p>{(datas.plan_end_date) ? datas.plan_end_date : 'N/A'}</p></li>
                                                </li>
                                            </ul>)}
                                        {(user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (datas.type === 'ambassador') && (
                                            <ul className='licence-list'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>Role with current brokerage</p></li>
                                                    <li className='license-info'><p>{datas.brokerage_role}</p></li>
                                                </li>
                                            </ul>)}
                                        {(user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (datas.type === 'ambassador') && (datas.brokerage_role_slug === 'broker_of_record') && (
                                            <ul className='licence-list'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>How many agents in your brokerage</p></li>
                                                    <li className='license-info'><p>{datas.broker_of_record_detail}</p></li>
                                                </li>
                                            </ul>)}
                                        {(user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (datas.type === 'ambassador') && (datas.brokerage_role_slug === 'office_manager') && (
                                            <ul className='licence-list'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>How many agents in your office</p></li>
                                                    <li className='license-info'><p>{datas.office_manager_detail}</p></li>
                                                </li>
                                            </ul>)}
                                        {(user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (datas.type === 'ambassador') && (datas.brokerage_role_slug === 'team_lead') && (
                                            <ul className='licence-list'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>How many agents on your team</p></li>
                                                    <li className='license-info'><p>{datas.team_lead_detail}</p></li>
                                                </li>
                                            </ul>)}
                                        {(user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (datas.type === 'ambassador') && (datas.brokerage_role_slug === 'other') && (
                                            <ul className='licence-list'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>Other Role</p></li>
                                                    <li className='license-info'><p>{datas.brokerage_role_other_detail}</p></li>
                                                </li>
                                        </ul>)}
                                            {(datas.type === 'ambassador') && ( <ul className='licence-list licence_list_info'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>Broker of Record License #</p></li>
                                                {
                                                    (datas.broker_license_json.length > 0) && (datas.broker_license_json.map((x, i) => {
                                                        return (
                                                            <li key={i}  className='license-info'><p>{x.license_number}</p></li>
                                                        )
                                                    }))
                                                    
                                                }
                                                {
                                                    (datas.broker_license_json.length === 0) && (<li className='license-info'><p>-</p></li>)
                                                }
                                                </li>
                                            </ul>)}
                                            {(datas.type === 'ambassador') && ( <ul className='licence-list licence_list_info'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>Broker of Record License State</p></li>
                                                {
                                                    (datas.broker_license_json.length > 0) && (datas.broker_license_json.map((x, i) => {
                                                        return (
                                                            <li key={i}  className='license-info'><p>{x.license_state.abbrv}</p></li>
                                                        )
                                                    }))
                                                    
                                                }
                                                {
                                                    (datas.broker_license_json.length === 0) && (<li className='license-info'><p>-</p></li>)
                                                }
                                                </li>
                                            </ul>)}
                                            
                                        {
                                            (user) && ((user.type === 'admin')) && (showPowerUser) && (<ul className='licence-list'>
                                                <li className="licence-field highligh-item">
                                                    <li className='licence-info-check'> <p className='page-heading' htmlFor="">Power User</p></li>
                                                    <li className='licence-info-check'>  <div className='form-check form-switch'><input className='form-check-input switch-toggle' type='checkbox' role='switch' onClick={handleCallPowerUser} defaultChecked={datas.power_user_checked} disabled={loading} /></div></li>
                                                    {loading && (<Typography align="center">
                                                        <CircularProgress value={66} />
                                                    </Typography>)}

                                                </li></ul>)
                                        }   
                                        {
                                            (user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (isMLSTabShow) && (<ul className='licence-list'>
                                                <li className="licence-field highligh-item">
                                                    <li className='licence-info-check'> <p className='page-heading' htmlFor="">MLS Info</p></li>
                                                    <li className='licence-info-check'>  <div className='form-check form-switch'><input className='form-check-input switch-toggle' type='checkbox' role='switch' onClick={handleCall} defaultChecked={datas.mls_checked} disabled={loading} /></div></li>
                                                    {loading && (<Typography align="center">
                                                        <CircularProgress value={66} />
                                                    </Typography>)}

                                                </li></ul>)
                                        }

                                        {
                                            (user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (isMLSShow === true) && (<div><ul className='licence-list'>
                                                <li className="licence-field">
                                                    <li className='license-item'><p>Name of MLS</p></li>
                                                    <li className='license-info'><p>{datas.mls_name}</p></li>
                                                </li>
                                            </ul>
                                                <ul className='licence-list'>
                                                    <li className="licence-field">
                                                        <li className='license-item'> <p>Website URL</p></li>
                                                        <li className='license-info'><p>{datas.mls_website}</p></li>
                                                    </li>
                                                </ul>
                                                <ul className='licence-list'>
                                                    <li className="licence-field">
                                                        <li className='license-item'><p>Username</p></li>
                                                        <li className='license-info'><p>{datas.mls_username}</p></li>
                                                    </li>
                                                </ul>
                                                <ul className='licence-list'>
                                                    <li className="licence-field">
                                                        <li className='license-item'> <p>Password</p></li>
                                                        <li className='licence-info-check'><Password
                                                            disabled
                                                            className="form-control license-field"
                                                            value={datas.mls_password}
                                                            placeholder="Enter here" feedback={false} toggleMask /></li>
                                                    </li>
                                                </ul>
                                            </div>)
                                        }

                                        {
                                            (user) && ((user.type === 'admin') || (user.id === datas.matching_brokerage_of_record_id)) && (datas.refrUserName) && (<div className="row" style = {{ paddingTop : 10 }}>
                                            <div className="col-lg-12"><div className="field">
                                            <label
                                                htmlFor="LicenseInput"
                                                className="form-label"
                                            >
                                                Invite Link
                                            </label>
                                            <span className="p-input-icon-right">
                                                <CopyToClipboard text={window.location.origin + '/signup?refrid=' + datas.refrUserName}
                                                onCopy={() => showSuccess()}>
                                                    
                                                    <i className="pi pi-copy copyicon" /></CopyToClipboard>
                                                   
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder="Enter here"
                                                    disabled={true}
                                                    value={window.location.origin + '/signup?refrid=' + datas.refrUserName}
                                                    className="form-control"
                                                />
                                            </span>
                                        </div></div></div>)
                                        }
                                    </div>
                                    
                                    
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <button className="btn btn-cancel me-4" onClick={(e) => navigate('/app/my-network')}>Cancel</button>
                                    {
                                        (user) && (user.type === 'admin') &&  (requestListingType !== 'pending') && (datas.is_email_verified === 1) && (<button className="btn btn-submit btn-500" onClick={() => { handleEdit(requestUserId, datas.type, datas.type, 'section2') }}>Edit</button>)
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                (showEditUser) && (<EditUser showEditUser={showEditUser} changeStatusEditUser={changeStatusEditUser} reloadListing={reloadListing} editRowId={editRowId} editRowType={editRowType} dbStatesName={dbStatesName} dbBrokerageRoleData={dbBrokerageRoleData} callFrom={callFrom} />)
            }
            {
                (showUploadDocs) && (<UploadDocuments showUploadDocs={showUploadDocs} changeStatuUpload={changeStatuUpload} reloadListing={reloadListing} editRowId={editRowId} editRowType={editRowType} dbUserFullName={dbUserFullName} callFrom={callFrom} is_profile_page_call={0}/>)
            }

        </>
    )
}

export default LicenceDetails;