import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "../../../axiosCall.js";
import { Alert, CircularProgress, Typography } from "@mui/material";
import LogoUI from "../LogoUI";
import { InputText } from "primereact";
import useAuth from './../../../hooks/useAuth';
import { Password } from 'primereact/password';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Divider } from 'primereact/divider';

const SetPassword = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [resetPasswordLink, setResetPasswordLink] = useState(false);
  const search = useLocation().search;
  const verificationCode = new URLSearchParams(search).get('c');
  const [linkPost, setLinkPost] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [userLicenseState, setUserLicenseState] = useState('');
  const [userLicenseStateName, setUserLicenseStateName] = useState('');
  const { login } = useAuth();
  const [isShowLinkResendVerifyEmail, setIsShowLinkResendVerifyEmail] = useState(false);
  let counter = 0;
  useEffect(() => {
    const verifyUser = async () => {
      setErrorMessage('');
      setSuccessMessage('');
      setLoader(true);
      setResetPasswordLink(false);
      try {
        const response = await axios.post(`/user_verify/${verificationCode}`);
        const { Status, StatusMessage, Error, Result } = response.data;
        let finalApiError = "";
        setLoader(false);
        if (Status === false) {
          if (Error.length > 0) {
            Error.map(
              (errorMessage) =>
                //finalApiError += '<li>'+errorMessage+'</li>'
                (finalApiError = errorMessage)
            );
          } else {
            finalApiError = StatusMessage;
          }
          setErrorMessage(finalApiError);
          if(Result.is_expired)
          {
              setIsLinkExpired(true);
          }
          else
          {
              setIsLinkExpired(false);
          }
          window.scrollTo(0,0);
        } else {
          formik.setFieldValue("user_id", Result.user_id);
          formik.setFieldValue("name", Result.name);
          formik.setFieldValue("email", Result.email);
          setResetPasswordLink(true);
          setUserLicenseState(Result.license_state_id);
          setUserLicenseStateName(Result.stateName);
          setUserType(Result.type)
          setUserEmail(Result.email)
        }
      } catch (err) {
        // console.log(err);
        setErrorMessage('There is something wrong. Please try again');
        window.scrollTo(0,0);
        setLoader(false);
      }
    };

    if (counter === 0) {
      //alert(counter)
      counter++;
      setLinkPost(false);
      verifyUser();

    }

  }, [linkPost]);

  const formik = useFormik({
    initialValues: {
      name: '',
      user_id: '',
      password: '',
      email:'',
      password_confirmation: '',
      verification_code: verificationCode,
      host_name: window.location.origin,
    },
    validate: (data) => {
      let errors = {};

      if (!data.password.trim()) {
        errors.password = 'Password is required.';
      }
      else {
        if (!data.password_confirmation.trim()) {
          errors.password_confirmation = 'Confirm Password is required.';
        }
        else if (data.password !== data.password_confirmation) {
          errors.password_confirmation = 'Password and confirm password must be same.';
        }
      }
      return errors;
    },
    onSubmit: (data) => {
      setErrorMessage('');
      handleSubmit(data);
    }
  });
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const handleSubmit = async (data) => {
    //alert('inisde submit')
    setLoading(true);

    try {
      const response = await axios.post('/set-password', data);
      const { Status, StatusMessage, Error, Result } = response.data;
      let finalApiError = '';
      setLoading(false);
      if (Status === false) {
        if (Error.length > 0) {
          Error.map(
            (errorMessage) =>
              //finalApiError += '<li>'+errorMessage+'</li>'
              (finalApiError = errorMessage)
          );
        } else {
          finalApiError = StatusMessage;
        }
        setErrorMessage(finalApiError);
      } else {
        setLoading(false);
        if(userType == "agent"){
            if(userLicenseStateName === "Connecticut" || userLicenseStateName === "Massachusetts" || userLicenseStateName === "Florida"){
                navigate({
                    pathname: '/select-plan',
                    search: `?c=${Result.token}`,
                  })
            }else{
                try {

                    const getOutput = await login(data.email, data.password);
                    //console.log('getOutput ::: ' + getOutput);
                    const { Status, StatusMessage, Error, Result } = getOutput;
                    if (Status === false) {
                        setLoading(false);
                        let finalApiError = '';
                        if (Error.length > 0) {
                            Error.map((message) => finalApiError = message);
                            setErrorMessage(finalApiError);
                        }
                        else {
                            finalApiError = StatusMessage;
                            setErrorMessage(finalApiError);
                        }
                        if(Result.is_resend_verify_email === 1)
                        {
                            setIsShowLinkResendVerifyEmail(true);
                        }
                        else if(Result.subscription != null){
                            navigate({
                                pathname: '/select-plan',
                                search: `?c=${Result.subscription}`,
                              })
                        }
                        else
                        {
                            setIsShowLinkResendVerifyEmail(false);
                        }


                    }
                    else {
                       navigate('/dashboard');
                    }
                } catch (e) {
                    setLoading(false);
                }
            }


        }else{
            navigate('/successfully-registered');
        }


        formik.resetForm();
      }
    } catch (err) {
      // console.log(err);
      setErrorMessage(err);
      setLoading(false);
    }
  };

  return (
    <>
      <section className="form-section auth-bg">
        <div className="container-fluid">
          <div className="form-container">
            <div className="form-wrapper">
              <form onSubmit={formik.handleSubmit} >
                <div className="back-sign">
                  {/* <NavLink to="/verify-email"><img src="./assets/images/back-icon.svg" alt="" /></NavLink> */}
                </div>
                {errorMessage && (
                  <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                    {errorMessage}
                  </Alert>
                )}
                {loader && (<Typography align="center">
                  <CircularProgress value={66} />
                </Typography>)}
                {
                  (isLinkExpired) && (
                      <>

                  <p className="signup-desc">Your link is expired. Please make a request for set password.</p>
                  <br/>
                  <div className="form-group">
                  <NavLink to="/forgot-password" className="btn form-btn">Forgot Password</NavLink>
                  </div></>
                )}
                {
                  (isLinkExpired === false) && (<><h1 className="signup-heading p-0">Welcome, {formik.values.name}</h1><p className="signup-desc">Please set your password</p>
                  <div className="form-inner mb-3">
                    <div className="form-field">
                      <label htmlFor="">Password</label>
                      <Password
                        autoComplete="off"
                        inputClassName={classNames("form-control", { 'p-invalid': isFormFieldValid('password') })} placeholder="Enter here"
                        name="password"
                        value={formik.values.password} onChange={formik.handleChange} toggleMask
                      />
                      {getFormErrorMessage('password')}
                    </div>
                    <div className="form-field show-icon">
                      <div className="form-field">
                        <label htmlFor="">Confirm Password</label>
                        <Password
                          autoComplete="off"
                          inputClassName={classNames("form-control", { 'p-invalid': isFormFieldValid('password_confirmation') })} placeholder="Enter here"
                          name="password_confirmation"
                          value={formik.values.password_confirmation} onChange={formik.handleChange}
                          toggleMask
                        />
                        {getFormErrorMessage('password_confirmation')}
                        <InputText
                        type ="hidden"
                        name="email"
                        value={formik.values.email}

                        inputId="" />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn form-btn" disabled={loading}>Continue
                    {loading && (<Typography align="center">
                      <CircularProgress value={66} />
                    </Typography>)}
                    </button>

                    {/* <NavLink to="/successfully-registered" className="btn form-btn">Continue</NavLink> */}
                  </div></>)
                  }
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default SetPassword;
