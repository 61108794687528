import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState } from "react";

const UploadFileConfirmationMessage = (props) => {
    const [show, setShow] = useState(props.showUploadConfirmationMessage);
    
    const handleCloseCsv = () => setShow(false);
    
    

    return (
        <>
            <Modal className='upload-popup' show={show} onHide={handleCloseCsv} backdrop="static">
                
                    <Modal.Header className='upload-header'>
                        <Modal.Title>Confirm Opt-In Consent</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='upload-section'>
                        <p className='upload_file_content'>I agree that I've obtained clear opt-in consent to message all contacts in this import file.  These contacts are expecting to hear from me and this list wasn't purchased or provided by a third-party.</p>
                    </Modal.Body>
                    <Modal.Footer className='file-upload-footer'>
                        <Button className='btn-cancel btn-500' variant="" onClick={() => {handleCloseCsv(); props.changeStatusUploadConfirmationMessage();}} >
                          Cancel
                        </Button>
                        <Button type="button" className='btn-submit btn-submit-loader btn-500' variant="" onClick={() => {props.openUploadCsvForm();}}>
                        Yes, I confirm
                        
                        </Button>

                    </Modal.Footer>
                
            </Modal>
        </>
    )
}

export default UploadFileConfirmationMessage;
