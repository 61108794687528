import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const RegisterCheckEmail = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(props.showModal);
  useEffect(() => {
    if (props.showModal) {
      showModal();
    }
   // alert(props.userEmailId)
  }, []);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  const handleRedirect = () => {
    navigate('/login');
  };
  const handleRedirectSetPassword = () => {
    navigate('/set-password?c='+props.verificationCode);
  };
  
  return (
    <>
      <Modal className="check-email-m" show={isOpen} onHide={hideModal} backdrop="static">
        <Modal.Body className="p-0">
          <div className="email-popup-modal">
            <div className="modal-content">
              
              {
                (!props.verificationCode) && (<><div className="modal-header email-popup-logo"><img src="/assets/images/emailicon.png" alt="img" /></div><div className="modal-body email-popup-body">
                <h2 className="email-popup-title" >Check Your Email</h2>
                <div className="email-content">
                  <p>We have sent an email to <br/><a href={`mailto:${props.userEmailId}`}> {props.userEmailId} </a> <br/>to verify your account.  </p>
                </div>
                <button onClick={() => {hideModal();handleRedirect()}} type="button" className="btn btn-primary email-btn" data-bs-dismiss="modal">OK</button>

              </div></>)
              }
              {
                (props.verificationCode) && (<div className="modal-body email-popup-body">
                <h2 className="email-popup-title" >Signup Completed</h2>
                <div className="email-content">
                  <p>Click continue to set password</p>
                </div>
                <button onClick={() => {hideModal();handleRedirectSetPassword()}} type="button" className="btn btn-primary email-btn" data-bs-dismiss="modal">Continue</button>

              </div>)
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>



    </>
  )
}

export default RegisterCheckEmail;