import React, { useState, useEffect } from "react";
import LogoUI from "../LogoUI";
import { NavLink, useNavigate } from "react-router-dom";
// import ResetPasswordRequest from "./ResetPasswordRequest";
import { InputText } from "primereact";
import { IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ForgotCheckEmail from "./ForgotCheckEmail";
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../../axiosCall.js';
import RegisterCheckEmail from "../Signup/RegisterCheckEmail";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);

    const [showModalSendEmail, setShowModalSendEmail] = useState(false);
    const [userEmailId, setUserEmailId] = useState('');
    const [emailSendLoading, setEmailSendLoading] = useState(false);
    const [isShowLinkResendVerifyEmail, setIsShowLinkResendVerifyEmail] = useState(false);

    useEffect(() => {
    }, [showModal]);
    const formik = useFormik({
        initialValues: {
            email: '',
            host_name: window.location.origin
        },
        validate: (data) => {
            let errors = {};

            if (!data.email) {
                errors.email = 'Email address is required.';
                setIsEmailValid(false);
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address. e.g. example@email.com';
                setIsEmailValid(false);
            }
            else {
                setIsEmailValid(true);
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        setShowModal(false);
        try {
            const response = await axios.post('/forgot-password', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                if(Result.is_resend_verify_email === 1)
                {
                    setIsShowLinkResendVerifyEmail(true);
                }
                else
                {
                    setIsShowLinkResendVerifyEmail(false);
                }
            } else {
                setLoading(false);
                setShowModal(true);
                setIsEmailValid(false);
                formik.resetForm();
            }
        } catch (err) {
            // console.log(err);
            setErrorMessage(err);
            setLoading(false);
        }
    };
    const handleResendEmailVerify = async() => {
        let formData = new FormData();
        formData.append('email',formik.values.email);
        formData.append('host_name',window.location.origin);
        setEmailSendLoading(true);
        try {
            const response = await axios.post('/send_verify_email', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setEmailSendLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                setIsEmailValid(false);
                setIsShowLinkResendVerifyEmail(false);
                setErrorMessage('');
                formik.resetForm();
                setShowModal(true);
                setUserEmailId(Result.email);
            }
        } catch (err) {
            setEmailSendLoading(false);
        }
    }
    return (
        <>
            <section className="form-section auth-bg">
                <div className="container-fluid">
                    <div className="form-container forgot-container">
                        <div className="form-wrapper">
                            {errorMessage && (
                                <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                    {errorMessage}
                                </Alert>
                            )}
                            {
                                (isShowLinkResendVerifyEmail) && (<NavLink onClick={handleResendEmailVerify} className="signup resend-verify-link"><span>Resend Email Verify Link</span>{emailSendLoading && (<span><Typography align="center">
                                <CircularProgress value={66} />
                            </Typography></span>)}</NavLink>)
                            }
                            <form onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); formik.handleSubmit(e) }} method="post">

                                <div className="back-sign">
                                    <NavLink to="/"><img src="./assets/images/back-icon.svg" alt="" /></NavLink>
                                </div>
                                <h1>Forgot Password</h1>

                                <p className="signup-desc">Enter the email associated with your account and we’ll send an email with instructions to reset your password.</p>

                                <div className="form-inner forgot-inner">
                                    <div className="form-field show-icon">
                                        <label htmlFor="email">Email Address</label>
                                        <InputText autoComplete="off" placeholder="Enter here"
                                            name="email"
                                            value={formik.values.email} onChange={formik.handleChange} autoFocus
                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('email') })}
                                        />
                                        {(isEmailValid) && (<span className="icon">
                                            <IconButton>
                                                <CheckCircleIcon />
                                            </IconButton>
                                        </span>)}
                                        {getFormErrorMessage('email')}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn form-btn" disabled={loading} >Send Instructions
                                    {loading && (<Typography align="center">
                                        <CircularProgress value={66} />
                                    </Typography>)}
                                    </button>
                                    
                                    {/* data-bs-toggle="modal" data-bs-target="#forgotemailcheck"<NavLink className="btn form-btn" to="/resetpasswordrequest">Send Instructions</NavLink> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {(showModal) && (<ForgotCheckEmail showModal={showModal} />)}
            {showModalSendEmail && (
                <RegisterCheckEmail
                    showModal={showModalSendEmail}
                    userEmailId={userEmailId}
                 />
            )}
        </>
    )
}
export default ForgotPassword;