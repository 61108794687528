import React, { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Avatar, InputText } from "primereact";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import { Password } from "primereact/password";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import useAuth from "./../../hooks/useAuth";
import { InputMask } from 'primereact/inputmask';
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import axios, { googleAPIKey } from "../../axiosCall.js";
import Swal from 'sweetalert2';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Alert, CircularProgress, Typography } from "@mui/material";
import UploadDocuments from "../MyNetwork/UploadDocuments";
import Button from "@mui/material/Button";
import SelectPlan from "./UpdatePlan/SelectPlan";
import Autocomplete from "react-google-autocomplete";
import { Toast } from 'primereact/toast';
import EditCreditCard from "./EditCreditCard";

const EditEProfile = () => {
    const { user, setUserDataCall, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState('admin');
    const [userIsMLS, setUserIsMLS] = useState(0);
    const [userLicenseState, setUserLicenseState] = useState('');
    const [profilePicLoader, setProfilePicLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dbStatesName, setDbStatesName] = useState([]);
    const [planInfo, setPlanInfo] = useState([]);

    const [refrUserName, setRefrUserName] = useState("");
    const [userSignupDate, setUserSignupDate] = useState("");
    const [userFullName, setUserFullName] = useState("");
    const [userShortName, setUserShortName] = useState("JD");
    const [userId, setUserId] = useState("");
    const [dbBrokerageRoleData, setBrokerageRoleData] = useState([]);
    const [loadingChangePassword, setLoadingChangePassword] = useState(false);
    const [errorMessageCP, setErrorMessageCP] = useState("");

    const [loadingMLS, setLoadingMLS] = useState(false);
    const [errorMessageMLS, setErrorMessageMLS] = useState("");

    const [tabChange, setTabChange] = useState(false);
    const [isSubmitCall, setIsSubmitCall] = useState(false);
    const [profilePic, setProfilePic] = useState("");
    const mediaRef = useRef('');
    const navigate = useNavigate();

    const [showUploadDocs, setShowUploadDocs] = useState(false);

    const [editRowId, setEditRowId] = useState(0);
    const [editRowType, setEditRowType] = useState(false);
    const [planInfoCheck, setPlanInfoCheck] = useState(true);
    const [editRowTypeTemp, setEditRowTypeTemp] = useState(false);
    const [dbUserFullName, setDbUserFullName] = useState('');
    const [callFrom, setCallFrom] = useState('');
    const [isDisableBrokerOfRecord, setIsDisableBrokerOfRecord] = useState(false);
    const [isDisableBrokerOfRecordDetail, setIsDisableBrokerOfRecordDetail] = useState(false);
    const [isDisableBrokerOfRecordBrokerageName, setIsDisableBrokerOfRecordBrokerageName] = useState(false);
    const [isDisableBrokerOfRecordName, setIsDisableBrokerOfRecordName] = useState(false);
    const [isDisableBrokerOfRecordEmail, setIsDisableBrokerOfRecordEmail] = useState(false);
    const [isDisableBrokerOfRecordPhone, setIsDisableBrokerOfRecordPhone] = useState(false);
    const [isDisableBrokerOfRecordLicenseNo, setIsDisableBrokerOfRecordLicenseNo] = useState(false);

    const [showSelectPlan, setShowSelectPlan] = useState(false);
    const [showEditCreditCard, setShowEditCreditCard] = useState(false);
    const [inputList, setInputList] = useState([
        {
            license_number: "",
            license_number_error: "",
            license_state: "",
            license_state_error: "",
        }
    ]);
    let minDate = new Date();
    const handleClick = async (event) => {
        // const { data } = await axios.get("/api/portal");
        // router.push(data.url);

        let formData = new FormData();
        formData.append("users_id", user.id);
        setErrorMessage('');
        try {
            const response = await axios.post("/get-user-credit-card-info", formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {

                //alert(Result.url);
                window.open(Result.url, '_blank', 'noreferrer');
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate("/login");
            }
        }

    };
    const handleDocumentsUpload = (rowId, rowType, userFullName, mode) => {

        setShowUploadDocs(true)
        setCallFrom(mode);
        setDbUserFullName(userFullName)
        setEditRowId(rowId);
        setEditRowType(rowType);
    }
    const handleDeleteDocuments = (rowId, mode, media) => {
        Swal.fire({
            title: 'Are you sure?',
            html: "You won't be able to reverse this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                handleDeleteDocumentsCall(rowId, mode, media);
            }
        })
    }
    const handleDeleteDocumentsCall = async (rowId, mode, media) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('user_id', rowId);
        formData.append('mode', mode);
        formData.append('media', media);
        formData.append('is_profile_page_call', 1);
        try {
            const response = await axios.post('/delete-docs_by_admin', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {


                setUserDataCall(Result);
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    }
    const reloadListing = () => {
        setEditRowId(0)
        setEditRowType('')
        setEditRowTypeTemp('')
    }
    const changeStatuUpload = () => {
        setDbUserFullName('');
        setCallFrom('');
        setEditRowId(0)
        setEditRowType('')
        setShowUploadDocs(false)
    }

    const formik = useFormik({
        initialValues: {
            user_id: "",
            first_name: "",
            last_name: "",
            email: "",
            type: "agent",
            license_number: "",
            license_expiry_date: "",
            license_state_id: "",
            business_address: "",
            contact_number: "",
            upload_personal_pdf_file: '',
            upline_user_name: '',
            brokerage_name: "",
            broker_name: "",
            broker_email: "",
            broker_phone: "",
            brokerage_role_id: '',
            broker_of_record_detail: '',
            office_manager_detail: '',
            team_lead_detail: '',
            broker_license_json: '',
            brokerage_role_other_detail: '',
            master_referral_services_fee_media: '',
            brokerage_participation_agreement_media: '',
            independent_contractor_agreement_media: '',
            upload_personal_media: '',
        },
        validate: (data) => {
            //alert(data.license_expiry_date)

            let errors = {};
            let isValid = true;
            if (!data.first_name.trim()) {
                errors.first_name = "First name is required.";
            }
            if (!data.last_name.trim()) {
                errors.last_name = "Last name is required.";
            }
            if (data.type === 'agent') {
                if (!data.license_number.trim()) {
                    errors.license_number = "License number is required.";
                }
                if (!data.license_expiry_date) {
                    errors.license_expiry_date = "License expiration date is required.";
                }
                if (!data.license_state_id) {
                    errors.license_state_id = "License state is required.";
                }
            }
            if (data.type === 'ambassador') {
                if (!data.brokerage_name.trim()) {
                    errors.brokerage_name = 'Brokerage name is required.';
                }
                if (!data.broker_name.trim()) {
                    errors.broker_name = 'Broker of record name is required.';
                }
                if (data.broker_email) {
                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.broker_email)) {
                        errors.broker_email = 'Invalid email address. e.g. example@email.com';
                    }
                }


                if (!data.brokerage_role_id) {
                    errors.brokerage_role_id = 'Role with current brokerage is required.';
                }
                else {
                    if (data.brokerage_role_id.slug === 'broker_of_record') {
                        if (data.broker_of_record_detail == null || data.broker_of_record_detail.trim() === '') {
                            errors.broker_of_record_detail = 'How many agents in your brokerage is required.';
                        }
                    }

                    if (data.brokerage_role_id.slug === 'office_manager') {
                        if (data.office_manager_detail == null || data.office_manager_detail.trim() === '') {
                            errors.office_manager_detail = 'How many agents in your office is required.';
                        }
                    }

                    if (data.brokerage_role_id.slug === 'team_lead') {
                        if (data.team_lead_detail == null || data.team_lead_detail.trim() === '') {
                            errors.team_lead_detail = 'How many agents on your team is required.';
                        }
                    }

                    if (data.brokerage_role_id.slug === 'other') {
                        if (data.brokerage_role_other_detail == null || data.brokerage_role_other_detail.trim() === '') {
                            errors.brokerage_role_other_detail = 'Other role is required.';
                        }
                    }
                    if (isSubmitCall) {
                        if (inputList.length > 1) {
                            inputList.map((x, i) => {

                                if (x.license_number == null || x.license_number.trim() === "") {
                                    x.license_number_error = "Broker of Record License # is required.";
                                    isValid = false;
                                }

                                if (!x.license_state) {
                                    x.license_state_error = "Broker of Record License State is required.";
                                    isValid = false;
                                }
                            });
                        }
                        else {
                            inputList.map((x, i) => {

                                if (x.license_state) {
                                    if (x.license_number == null || x.license_number.trim() === "") {
                                        x.license_number_error = "Broker of Record License # is required.";
                                        isValid = false;
                                    }
                                }

                                if (x.license_number) {
                                    if (!x.license_state) {
                                        x.license_state_error = "Broker of Record License State is required.";
                                        isValid = false;
                                    }
                                }
                                if (!x.license_state && !x.license_number) {
                                    x.license_state_error = "";
                                    x.license_number_error = "";
                                }
                            });
                        }
                    }
                }
            }
            if (data.type === 'ambassador' || data.type === 'agent') {
                if (!data.business_address.trim()) {
                    errors.business_address = "Home address is required.";
                }
            }
            if (!data.contact_number.trim()) {
                errors.contact_number = "Phone is required.";
            }

            if (isValid) {
                return errors;
            } else {
                errors.multi_fields = "error";
                return errors;
            }
        },
        onSubmit: (data) => {
            handleSubmit(data);
        },
    });
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        setErrorMessage('');
        let formData = new FormData();
        let file = data.upload_personal_pdf_file;
        formData.append('file', file)
        formData.append('data', JSON.stringify(data))
        try {
            const response = await axios.post('/update-profile', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {
                //alert(Result.user)
                setUserDataCall(Result.user);
                setLoading(false);
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {

                    }
                });
            }
        } catch (err) {

            setLoading(false);
            window.scrollTo(0, 0);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                setErrorMessage("Something went wrong");

            }
        }
    };
    const handleGoogleAddress = (place) => {
        formik.setFieldValue("business_address", place.formatted_address);
    }
    useEffect(() => {
        const fetchBrokerageRoleData = async () => {
            try {
                const response = await axios.post('/get-all-Brokerage-role-data');
                const { Status, StatusMessage, Error, Result } = response.data;

                if (Status === true) {
                    //alert(Result)
                    setBrokerageRoleData(Result);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchBrokerageRoleData();
        const fetchStatesData = async () => {
            try {
                const response = await axios.post("/get-all-states");
                const { Status, StatusMessage, Error, Result } = response.data;

                if (Status === true) {
                    //alert(Result)
                    setDbStatesName(Result);
                }
            } catch (err) {
                // console.log(err);
            }
        };
        fetchStatesData();

    }, []);
    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }
    const optionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    }
    const toast = useRef(null);
    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };
    const handleBrokerageRole = (e) => {
        //alert(JSON.stringify(e.target.value))
        setIsDisableBrokerOfRecordBrokerageName(false);
        setIsDisableBrokerOfRecordName(false);
        setIsDisableBrokerOfRecordEmail(false);
        setIsDisableBrokerOfRecordPhone(false);
        setIsDisableBrokerOfRecordLicenseNo(false);

        let list = [...inputList];

        if (list.length > 1) {
            list = list.slice(0, 1);
            setInputList(list);
        }

        if (formik.values.brokerage_name) {
            if (e.target.value.slug === 'broker_of_record') {
                setIsDisableBrokerOfRecordBrokerageName(true);
            }
        }
        if (formik.values.broker_name) {
            if (e.target.value.slug === 'broker_of_record') {
                setIsDisableBrokerOfRecordName(true);
            }
        }
        if (formik.values.broker_email) {
            if (e.target.value.slug === 'broker_of_record') {
                setIsDisableBrokerOfRecordEmail(true);
            }
        }
        if (formik.values.broker_phone) {
            if (e.target.value.slug === 'broker_of_record') {
                setIsDisableBrokerOfRecordPhone(true);
            }
        }

        formik.setFieldValue("broker_of_record_detail", '')
        formik.setFieldValue("office_manager_detail", '')
        formik.setFieldValue("team_lead_detail", '')
        formik.setFieldValue("brokerage_role_other_detail", '')
    }
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;

        if (name === "license_number") {
            list[index]["license_number_error"] = "";
        } else if (name === "license_state") {
            list[index]["license_state_error"] = "";
        }
        setInputList(list);
        formik.setFieldValue("broker_license_json", list);
    };
    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        formik.setFieldValue("broker_license_json", list);
    };

    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                license_number: "",
                license_number_error: "",
                license_state: "",
                license_state_error: "",
            },
        ]);
    };
    const isFormFieldValidCP = (name) =>
        !!(formikCP.touched[name] && formikCP.errors[name]);
    const getFormErrorMessageCP = (name) => {
        return (
            isFormFieldValidCP(name) && (
                <small className="p-error">{formikCP.errors[name]}</small>
            )
        );
    };

    const isFormFieldValidMLS = (name) =>
        !!(formikMLS.touched[name] && formikMLS.errors[name]);
    const getFormErrorMessageMLS = (name) => {
        return (
            isFormFieldValidMLS(name) && (
                <small className="p-error">{formikMLS.errors[name]}</small>
            )
        );
    };

    const selectedStateTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.abbrv}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };
    const stateOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.abbrv}</div>
            </div>
        );
    };
    const onBasicUploadAuto = () => {
        toast.current.show({
            severity: "info",
            summary: "Success",
            detail: "File Uploaded with Auto Mode",
        });
    };
    const onFileChanged = (event) => {
        // alert(event.files[0])
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];
            let fileSize = file.size;
            const imageSizeInMb = Math.round((fileSize / 1024));

            if (imageSizeInMb >= 2048) {
                mediaRef.current.value = '';
                Swal.fire({
                    title: '',
                    text: "Please upload the file with a size of not more than 2 MB.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })

                //Swal.fire('Please upload the file with a size of not more than 2 MB.');
            }
            else if (file.type !== 'application/pdf') {
                mediaRef.current.value = '';
                Swal.fire({
                    title: '',
                    text: "Please selects the file with valid extension i.e. *.pdf.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })
                //Swal.fire('Please selects the file with valid extension i.e. *.pdf.');
            }
            else {
                formik.setFieldValue(
                    "upload_personal_pdf_file",
                    event.target.files[0]
                );
            }
        }

    };

    const onFileChangedProfilePic = (event) => {
        // alert(event.files)
        let file = event.target.files[0];
        let fileSize = file.size;
        const imageSizeInMb = Math.round((fileSize / 1024));
        let fileName = event.target.files[0].name;
        let splitName = fileName.split('.');

        if (imageSizeInMb >= 2048) {
            //Swal.fire('Please upload the file with a size of not more than 2 MB.');
            Swal.fire({
                title: '',
                text: "Please upload the file with a size of not more than 2 MB.",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

            })
            return false;
        }
        else if (splitName[1] !== 'bmp' && splitName[1] !== 'jpe' && splitName[1] !== 'jfif' && splitName[1] !== 'webp' && splitName[1] !== 'jpg' && splitName[1] !== 'jpeg' && splitName[1] !== 'png' && splitName[1] !== 'gif') {
            //Swal.fire(file.name+' has an invalid extension. Valid extension(s): bmp, gif, jpeg, jpg, jpe, jfif, png, webp.');
            Swal.fire({
                title: '',
                html: file.name + " has an invalid extension.<br> Valid extension(s): bmp, gif, jpeg, jpg, jpe, jfif, png, webp.",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
            }).then((result) => {

            })
            return false;
        }
        else {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {

                uploadProfilePic(e.target.result)
            };
            fileReader.readAsDataURL(file);
        }
    };
    const uploadProfilePic = async (profilePic) => {

        let formData = new FormData();
        formData.append('data', profilePic);
        formData.append('user_id', userId);
        setProfilePicLoader(true);
        try {
            const response = await axios.post('/update-profile-pic', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setProfilePicLoader(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                Swal.fire({
                    title: 'Error',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    text: finalApiError,
                }).then((result) => {
                    if (result.isConfirmed) {

                    }
                });

            } else {
                setProfilePic(profilePic);
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        setUserDataCall(Result.user);
                    }
                });
            }
        } catch (err) {
            setProfilePicLoader(false);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                Swal.fire({
                    title: '',
                    text: "Something went wrong",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })
            }
        }
    };
    //Change Password
    const formikCP = useFormik({
        initialValues: {
            user_id: "",
            old_password: "",
            new_password: "",
            password_confirmation: "",
        },
        validate: (data) => {
            //alert(1);
            //alert(data.license_expiry_date)
            setErrorMessageCP('');
            let errors = {};

            if (!data.old_password) {
                //alert(1);
                errors.old_password = "Current password is required.";
            }
            //console.log('data.new_password ' + data.new_password)
            //console.log('data.password_confirmation ' + data.password_confirmation)
            if (!data.new_password) {
                errors.new_password = "New password is required.";
            }
            else {
                if (data.new_password !== data.password_confirmation) {
                    errors.password_confirmation = 'Password and confirm password must be same.';
                }
            }
            return errors;
        },
        onSubmit: (data) => {
            handleSubmitCP(data);
        },
    });
    const handleSubmitCP = async (data) => {
        //alert('inisde submit')
        setLoadingChangePassword(true);
        setErrorMessageCP('');
        try {
            const response = await axios.post('/change-password', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoadingChangePassword(false);
            if (Status === false) {
                if (Error.length > 0) {
                    finalApiError = errorMessage;
                    Error.map((errorMessage) => {
                        finalApiError = errorMessage
                    });
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessageCP(finalApiError);
                window.scrollTo(0, 0);
            } else {
                //alert(Result.user)

                setLoadingChangePassword(false);
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {

                        formikCP.resetForm();
                        formikCP.setFieldValue("user_id", user.id);
                    }
                });
            }
        } catch (err) {

            setLoadingChangePassword(false);
            window.scrollTo(0, 0);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                setErrorMessageCP("Something went wrong");

            }
        }
    };
    //MLS INFO
    const formikMLS = useFormik({
        initialValues: {
            user_mls_info_id: "",
            user_id: "",
            name: "",
            website_url: "",
            username: "",
            password: "",
        },
        validate: (data) => {

            setErrorMessageMLS('');
            let errors = {};
            var urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

            if (!data.name) {
                errors.name = "Name is required.";
            }

            if (!data.website_url) {
                errors.website_url = "Website URL is required.";
            }
            else if (!urlPattern.test(data.website_url)) {
                errors.website_url = "Please enter a valid Website URL.";
            }
            if (!data.username) {
                errors.username = "Username is required.";
            }

            if (!data.password) {
                errors.password = "Password is required.";
            }

            return errors;
        },
        onSubmit: (data) => {
            handleSubmitMLS(data);
        },
    });
    const handleSubmitMLS = async (data) => {
        //alert('inisde submit')
        setLoadingMLS(true);
        setErrorMessageMLS('');
        try {
            const response = await axios.post('/update-mls', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoadingMLS(false);
            if (Status === false) {
                if (Error.length > 0) {
                    finalApiError = errorMessage;
                    Error.map((errorMessage) => {
                        finalApiError = errorMessage
                    });
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessageMLS(finalApiError);
                window.scrollTo(0, 0);
            } else {
                //alert(Result.user)

                setLoadingChangePassword(false);
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        // formikMLS.setFieldValue("name", "");
                        // formikMLS.setFieldValue("website_url", "");
                        // formikMLS.setFieldValue("username", "");
                        // formikMLS.setFieldValue("password", "");
                        window.scrollTo(0, 0);
                    }
                });
            }
        } catch (err) {

            setLoadingMLS(false);
            window.scrollTo(0, 0);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                setErrorMessageMLS("Something went wrong");

            }
        }
    };
    const handleUserData = (user) => {
        let objectDate = new Date(user.created_at);

        let day = objectDate.getDate();
        let month = objectDate.getMonth() + 1;
        let year = objectDate.getFullYear();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = `0${month}`;
        }
        // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
        // "July", "Aug", "Sept", "Oct", "Nov", "Dec"
        // ];
        // month = monthNames[objectDate.getMonth()];

        setUserSignupDate(month + '/' + day + '/' + year);
        setUserFullName(user.name);
        setUserRole(user.type);
        setUserLicenseState(user.license_state_id)

        //alert(userRole)
        if (user.type === 'agent')
            setUserIsMLS(0)
        else
            setUserIsMLS(user.is_mls)

        let str = user.name
        let matches = str.match(/\b(\w)/g);
        let acronym = matches.join('');
        setUserShortName(acronym);

        if (user.profile_pic !== null) {
            setProfilePic(user.profile_pic);
        }
        if (user.username !== null) {
            setRefrUserName(user.username);
        }
        //alert(user.license_expiry_date);

        formikMLS.setFieldValue("user_id", user.id ? user.id : "");
        formikCP.setFieldValue("user_id", user.id ? user.id : "");
        setUserId(user.id)
        formik.setFieldValue("user_id", user.id ? user.id : "");
        formik.setFieldValue(
            "first_name",
            user.first_name ? user.first_name : ""
        );
        formik.setFieldValue(
            "last_name",
            user.last_name ? user.last_name : ""
        );

        formik.setFieldValue(
            "type",
            user.type ? user.type : ""
        );
        formik.setFieldValue("email", user.email ? user.email : "");
        formik.setFieldValue(
            "license_number",
            user.license_number ? user.license_number : ""
        );
        formik.setFieldValue(
            "license_expiry_date",
            user.license_expiry_date
                ? new Date(user.license_expiry_date)
                : ""
        );
        formik.setFieldValue(
            "license_state_id",
            user.license_state ? user.license_state : ""
        );
        formik.setFieldValue(
            "business_address",
            user.business_address ? user.business_address : ""
        );
        formik.setFieldValue(
            "contact_number",
            user.contact_number ? user.contact_number : ""
        );
        formik.setFieldValue(
            "upline_user_name",
            user.upline_user_name ? user.upline_user_name : "None"
        );


        if (user.brokerage_name) {
            if (user.brokerage_role_id.slug === 'broker_of_record') {
                setIsDisableBrokerOfRecordBrokerageName(true);
            }
            formik.setFieldValue("brokerage_name", user.brokerage_name);
        }
        //alert(user.brokerage_role_id)
        if (user.broker_name) {
            if (user.brokerage_role_id.slug === 'broker_of_record') {
                setIsDisableBrokerOfRecordName(true);
            }
            formik.setFieldValue("broker_name", user.broker_name);
        }
        if (user.broker_email) {
            if (user.brokerage_role_id.slug === 'broker_of_record') {
                setIsDisableBrokerOfRecordEmail(true);
            }
            formik.setFieldValue("broker_email", user.broker_email);
        }
        if (user.broker_phone) {
            if (user.brokerage_role_id.slug === 'broker_of_record') {
                setIsDisableBrokerOfRecordPhone(true);
            }
            formik.setFieldValue("broker_phone", user.broker_phone);
        }


        if (user.brokerage_role_id) {
            if (user.brokerage_role_id.slug === 'broker_of_record') {
                setIsDisableBrokerOfRecord(true);
            }
            formik.setFieldValue("brokerage_role_id", user.brokerage_role_id);
        }


        if (user.broker_of_record_detail) {
            if (user.brokerage_role_id.slug === 'broker_of_record') {
                setIsDisableBrokerOfRecordDetail(true);
            }
            formik.setFieldValue("broker_of_record_detail", user.broker_of_record_detail);
        }


        if (user.office_manager_detail)
            formik.setFieldValue("office_manager_detail", user.office_manager_detail);

        if (user.team_lead_detail)
            formik.setFieldValue("team_lead_detail", user.team_lead_detail);

        if (user.brokerage_role_other_detail)
            formik.setFieldValue("brokerage_role_other_detail", user.brokerage_role_other_detail);

        if (user.broker_license_json) {
            if (user.brokerage_role_id.slug === 'broker_of_record') {
                setIsDisableBrokerOfRecordLicenseNo(true);
            }
            setInputList(JSON.parse(user.broker_license_json));
            formik.setFieldValue("broker_license_json", JSON.parse(user.broker_license_json));
        }

        if (user.master_referral_services_fee_media)
            formik.setFieldValue("master_referral_services_fee_media", user.master_referral_services_fee_media);

        if (user.brokerage_participation_agreement_media)
            formik.setFieldValue("brokerage_participation_agreement_media", user.brokerage_participation_agreement_media);

        if (user.independent_contractor_agreement_media)
            formik.setFieldValue("independent_contractor_agreement_media", user.independent_contractor_agreement_media);

        if (user.upload_personal_media)
            formik.setFieldValue("upload_personal_media", user.upload_personal_media);
        else
            formik.setFieldValue("upload_personal_media", '');


    }
    useEffect(() => {
        if (user !== null) {
            handleUserData(user);
            let formData = new FormData();
            formData.append('user_id', user.id);

            const fetchMlsData = async () => {
                try {
                    const response = await axios.post("/get-mls-data", formData);
                    const { Status, StatusMessage, Error, Result } = response.data;

                    if (Status === true) {
                        formikMLS.setFieldValue("user_mls_info_id", Result[0].id ? Result[0].id : "");

                        formikMLS.setFieldValue("name", Result[0].name ? Result[0].name : "");

                        formikMLS.setFieldValue("website_url", Result[0].website_url ? Result[0].website_url : "");

                        formikMLS.setFieldValue("username", Result[0].username ? Result[0].username : "");

                        formikMLS.setFieldValue("password", Result[0].password ? Result[0].password : "");
                    }
                } catch (err) {
                    if (err.response.status && err.response.status == 401) {
                        logout();
                        navigate('/login');
                    } else {
                        Swal.fire({
                            title: '',
                            text: "Something went wrong",
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Ok'
                        }).then((result) => {

                        })
                    }
                }
            };
            fetchMlsData();
        }
    }, [user]);
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    const cancelSubscription = (userId) => {
        console.log(userId)
        Swal.fire({
            title: '',
            html: "You are trying to cancel your scheduled plan change.  This request will override your previous change request.  <br/>Are you sure you want to continue? ",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Back',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                handleCancelPlan(userId);


            }
        })

    }

    const cancelUpcomingPlan = (userId) => {
        console.log(userId)
        Swal.fire({
            title: '',
            html: "You are trying to cancel your scheduled plan change.  This request will override your previous change request.  <br/>Are you sure you want to continue? ",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Back',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                handleCancelUpcomingPlan(userId);


            }
        })

    }
    const handleCancelPlan = async (userId) => {

        let formData = new FormData();
        formData.append('user_id', userId);
        formData.append('product_id', 3);
        //alert(userId);
        try {
            const response = await axios.post('/update-sub', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            //alert(Status);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }

            } else {
                //alert('Result.user '+Result.user);
                // alert('Result.user '+JSON.stringify(Result.user));
                Swal.fire(
                    '',
                    'You have successfully cancelled your plan change request.',
                    'success'
                )
                setUserDataCall(Result.user);

            }
        } catch (err) {

        }
    }

    const handleCancelUpcomingPlan = async (userId) => {

        let formData = new FormData();
        formData.append('user_id', userId);

        //alert(userId);
        try {
            const response = await axios.post('/upcoming-cancel', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            //alert(Status);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }

            } else {
                //alert('Result.user '+Result.user);
                // alert('Result.user '+JSON.stringify(Result.user));
                Swal.fire(
                    '',
                    'You have successfully cancelled your plan change request.',
                    'success'
                )
                setUserDataCall(Result.user);

            }
        } catch (err) {

        }
    }
    const changeStatuClosedhandleModal = () => {
        setShowSelectPlan(false)

    }
    const planInformation = () => {
        if (planInfoCheck) {
            setPlanInfoCheck(false)
        } else {
            setPlanInfoCheck(true)
        }
    }
    const changeStatusEditCreditCard = () => {
        setShowEditCreditCard(false)
    }
    const editCreditCard = () => {
        if (showEditCreditCard) {
            setShowEditCreditCard(false)
        } else {
            setShowEditCreditCard(true)
        }
    }
    const handleModal = () => {
        setShowSelectPlan(true)
    }
    const toast1 = useRef(null);
    const showSuccess = () => {
        //alert('if')
        toast1.current.show({ severity: 'success', summary: 'Success Message', detail: 'Copied' });
    }
    return (
        <>
            <section className="template-profile-view sec-pt sec-pb edit-template-view">
                <div className="container">
                    <h2 className="main-heading">Edit Profile</h2>

                    <div className="cards-container row row-cols-1 row-cols-lg-2 g-1">
                        <div className="col col-basic-detail ps-lg-0 pe-lg-3">
                            <div className="card profile-card h-100 full-card">
                                <div className="card-body">
                                    <div className="profile-image-components text-center">
                                        {
                                            (profilePic) && (<div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root edit-dp">

                                                <img src={profilePic} alt={userShortName} className="rounded-circle z-depth-2 img-fluid" data-holder-rendered="true" />

                                            </div>)
                                        }
                                        {
                                            (userShortName) && (profilePic === '') && (<div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root edit-dp">
                                                {userShortName}
                                            </div>)
                                        }
                                        <div className="upload_pro_img">
                                            <a href="javascript:void(0)" className="btnimg"><img className="edit-icon-profile" src="../assets/images/profile-edit-icon.png" alt="" /></a>
                                            <input type="file" onChange={(e) => { onFileChangedProfilePic(e) }} accept=".bmp, .gif, .jpeg,.jpg, .jpe, .jfif, .png, .webp" />
                                            {profilePicLoader && (
                                                <Typography align="center">
                                                    <CircularProgress
                                                        value={66}
                                                    />
                                                </Typography>
                                            )}
                                        </div>
                                        {/* <a href="javascript:void(0)"><img className="edit-icon-profile" src="../assets/images/profile-edit-icon.png" alt="" /></a> */}
                                        <p className="title">{userFullName}</p>
                                        {/* <div className="upload-item">
                                            <FileUpload
                                                className="upload-img-btn"
                                                onUpload={onBasicUploadAuto}
                                                auto
                                                accept=".bmp, .gif, .jpeg,.jpg, .jpe, .jfif, .png, .webp"
                                                customUpload uploadHandler={onFileChangedProfilePic}
                                                chooseLabel="Upload Your Photo"
                                            />
                                            {profilePicLoader && (
                                                <Typography align="center">
                                                    <CircularProgress
                                                        value={66}
                                                    />
                                                </Typography>
                                            )}
                                        </div> */}
                                    </div>
                                    {
                                        (formik.values.type === 'agent' || formik.values.type === 'ambassador') && (<div className="documents-box pse-30">
                                            <p className="title-doc">Documents</p>
                                            {
                                                (formik.values.type === 'ambassador') && (<>
                                                    <p className="name-title">Master Referral Services Fee</p>
                                                    <div className="services-btn mb-4">

                                                        {
                                                            (formik.values.master_referral_services_fee_media) && (<a href={formik.values.master_referral_services_fee_media} target="_blank" className="btn outlined-btn">Preview</a>)
                                                        }
                                                        {
                                                            (!formik.values.master_referral_services_fee_media) && (
                                                                <p className="no-doc">No document uploaded by admin</p>
                                                            )}
                                                    </div></>)
                                            }
                                            {
                                                ((user) && (user.brokerage_role_id) && (formik.values.type === 'ambassador' && user.brokerage_role_id.slug === 'broker_of_record')) && (<>
                                                    <p className="name-title line-height-c">Brokerage Participation Agreement</p>
                                                    <div className="services-btn mb-4">

                                                        {
                                                            (formik.values.brokerage_participation_agreement_media) && (<a href={formik.values.brokerage_participation_agreement_media} target="_blank" className="btn outlined-btn">Preview</a>)
                                                        }
                                                        {
                                                            (!formik.values.brokerage_participation_agreement_media) && (
                                                                <p className="no-doc">No document uploaded by admin</p>
                                                            )}
                                                    </div></>)}
                                            {
                                                (formik.values.type === 'agent') && (<>
                                                    <p className="name-title line-height-c">Independent Contractor Agreement</p>
                                                    <div className="services-btn mb-4">
                                                        {
                                                            (formik.values.independent_contractor_agreement_media) && (<a href={formik.values.independent_contractor_agreement_media} target="_blank" className="btn outlined-btn">Preview</a>)
                                                        }
                                                        {
                                                            (!formik.values.independent_contractor_agreement_media) && (
                                                                <p className="no-doc">No document uploaded by admin</p>
                                                            )}
                                                    </div></>)}
                                            {
                                                (formik.values.type === 'agent') && (<><p className="name-title">{(formik.values.type === 'agent') ? 'Personal' : 'Brokerage'}  W-9</p>
                                                    <div className="services-btn mb-4">

                                                        {
                                                            (!formik.values.upload_personal_media) && (user) && (<a href='javascript:;' onClick={() => { handleDocumentsUpload(userId, formik.values.type, formik.values.name, 'upload_personal_media') }} className="btn outlined-btn">Upload</a>)
                                                        }
                                                        {
                                                            (formik.values.upload_personal_media) && (<a href={formik.values.upload_personal_media} target="_blank" className="btn outlined-btn">Preview</a>)
                                                        }
                                                        {
                                                            (formik.values.upload_personal_media) && (<a href='javascript:;' className="btn outlined-btn" onClick={() => { handleDeleteDocuments(userId, 'upload_personal_media', formik.values.upload_personal_media) }}>Delete</a>)
                                                        }

                                                    </div></>)
                                            }


                                            {
                                                ((user) && (user.brokerage_role_id) && (formik.values.type === 'ambassador' && user.brokerage_role_id.slug === 'broker_of_record')) && (<><p className="name-title">{(formik.values.type === 'agent') ? 'Personal' : 'Brokerage'}  W-9</p>
                                                    <div className="services-btn mb-4">

                                                        {
                                                            (!formik.values.upload_personal_media) && (user) && (user.brokerage_role_id) && (user.brokerage_role_id.slug === 'broker_of_record') && (<a href='javascript:;' onClick={() => { handleDocumentsUpload(userId, formik.values.type, formik.values.name, 'upload_personal_media') }} className="btn outlined-btn">Upload</a>)
                                                        }
                                                        {
                                                            (formik.values.upload_personal_media) && (<a href={formik.values.upload_personal_media} target="_blank" className="btn outlined-btn">Preview</a>)
                                                        }
                                                        {
                                                            (formik.values.upload_personal_media) && (user.brokerage_role_id) && (user.brokerage_role_id.slug === 'broker_of_record') && (<a href='javascript:;' className="btn outlined-btn" onClick={() => { handleDeleteDocuments(userId, 'upload_personal_media', formik.values.upload_personal_media) }}>Delete</a>)
                                                        }

                                                        {(!formik.values.upload_personal_media) && (user) && (user.brokerage_role_id) && (user.brokerage_role_id.slug != 'broker_of_record') && (
                                                            <p className="no-doc">No document uploaded by user or admin</p>
                                                        )}
                                                    </div></>)
                                            }

                                        </div>)}
                                </div>
                                <div className="card-footer text-center">
                                    <p className="text-muted mb-0">
                                        Member Since <span>{userSignupDate}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col pe-lg-0 ps-lg-3">
                            <TabView className="tabview-header-icon edit-profile-tabs">
                                <TabPanel
                                    header="Edit Profile"
                                    className="tabPanelClass"
                                >
                                    <form
                                        onSubmit={formik.handleSubmit}
                                        className="edit-form-section"
                                    >
                                        <div className="card h-100">
                                            <div className="card-body">
                                                <div className="personal-info">
                                                    {errorMessage && (
                                                        <Alert
                                                            sx={{ mb: 3 }}
                                                            severity="error"
                                                            variant="filled"
                                                        >
                                                            {errorMessage}
                                                        </Alert>
                                                    )}
                                                    <Toast ref={toast1} position="top-right" />
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="field">
                                                                <label
                                                                    htmlFor="FirstInput"
                                                                    className="form-label"
                                                                >
                                                                    First Name <span className="reuired-star">*</span>
                                                                </label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    name="first_name"
                                                                    placeholder="Enter here"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .first_name
                                                                    }
                                                                    onChange={
                                                                        formik.handleChange
                                                                    }
                                                                    className={classNames(
                                                                        "form-control",
                                                                        {
                                                                            "p-invalid":
                                                                                isFormFieldValid(
                                                                                    "first_name"
                                                                                ),
                                                                        }
                                                                    )}
                                                                />
                                                                {getFormErrorMessage(
                                                                    "first_name"
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="field">
                                                                <label
                                                                    htmlFor="LastInput"
                                                                    className="form-label"
                                                                >
                                                                    Last Name <span className="reuired-star">*</span>
                                                                </label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    name="last_name"
                                                                    placeholder="Enter here"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .last_name
                                                                    }
                                                                    onChange={
                                                                        formik.handleChange
                                                                    }
                                                                    className={classNames(
                                                                        "form-control",
                                                                        {
                                                                            "p-invalid":
                                                                                isFormFieldValid(
                                                                                    "last_name"
                                                                                ),
                                                                        }
                                                                    )}
                                                                />
                                                                {getFormErrorMessage(
                                                                    "last_name"
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="field">
                                                                <label
                                                                    htmlFor="EmailInput"
                                                                    className="form-label "
                                                                >
                                                                    Email
                                                                    Address <span className="reuired-star">*</span>
                                                                </label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    disabled
                                                                    className="disabled"
                                                                    placeholder="Enter here"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .email
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="field">
                                                                <label
                                                                    htmlFor="PhoneInput"
                                                                    className="form-label"
                                                                >
                                                                    Phone <span className="reuired-star">*</span>
                                                                </label>
                                                                <InputMask
                                                                    autoComplete="off"
                                                                    mask="(999) 999-9999" placeholder="(999) 999-9999"
                                                                    name="contact_number"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .contact_number
                                                                    }
                                                                    onChange={
                                                                        formik.handleChange
                                                                    }
                                                                    className={classNames(
                                                                        "form-control",
                                                                        {
                                                                            "p-invalid":
                                                                                isFormFieldValid(
                                                                                    "contact_number"
                                                                                ),
                                                                        }
                                                                    )}
                                                                />
                                                                {getFormErrorMessage(
                                                                    "contact_number"
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (userRole !== 'admin') && (<div className="bussiness-info">

                                                        {
                                                            (formik.values.type === 'agent') && (
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="field">
                                                                            <label
                                                                                htmlFor="LicenseInput"
                                                                                className="form-label"
                                                                            >
                                                                                License
                                                                                Number <span className="reuired-star">*</span>
                                                                            </label>
                                                                            <InputText
                                                                                autoComplete="off"
                                                                                placeholder="Enter here"
                                                                                name="license_number"
                                                                                value={
                                                                                    formik
                                                                                        .values
                                                                                        .license_number
                                                                                }
                                                                                onChange={
                                                                                    formik.handleChange
                                                                                }
                                                                                className={classNames(
                                                                                    "form-control",
                                                                                    {
                                                                                        "p-invalid":
                                                                                            isFormFieldValid(
                                                                                                "license_number"
                                                                                            ),
                                                                                    }
                                                                                )}
                                                                            />
                                                                            {getFormErrorMessage(
                                                                                "license_number"
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="field licence-date">
                                                                            <label
                                                                                htmlFor="LicenseInput"
                                                                                className="form-label"
                                                                            >
                                                                                License Expiration Date <span className="reuired-star">*</span>
                                                                            </label>
                                                                            <Calendar
                                                                                minDate={minDate}
                                                                                dateFormat="mm/dd/yy"
                                                                                placeholder="MM/DD/YY"
                                                                                showIcon
                                                                                name="license_expiry_date"
                                                                                value={
                                                                                    formik
                                                                                        .values
                                                                                        .license_expiry_date
                                                                                }
                                                                                viewDate={
                                                                                    formik
                                                                                        .values
                                                                                        .license_expiry_date
                                                                                }
                                                                                onChange={
                                                                                    formik.handleChange
                                                                                }
                                                                                inputClassName={classNames(
                                                                                    "form-control license-date",
                                                                                    {
                                                                                        "p-invalid":
                                                                                            isFormFieldValid(
                                                                                                "license_expiry_date"
                                                                                            ),
                                                                                    }
                                                                                )}
                                                                            />
                                                                            {getFormErrorMessage(
                                                                                "license_expiry_date"
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                        }
                                                        {
                                                            (formik.values.type === 'ambassador') && (
                                                                <div className="row">
                                                                    <div className="field signup-label col-lg-12 signup-form-field">
                                                                        <label htmlFor="baddress">Role with current brokerage <span className="reuired-star">*</span> </label>

                                                                        <Dropdown
                                                                            className={classNames("form-select", { 'p-invalid': isFormFieldValid('brokerage_role_id') })}
                                                                            value={formik.values.brokerage_role_id} options={dbBrokerageRoleData}
                                                                            onChange={(e) => { formik.handleChange(e); handleBrokerageRole(e) }} optionLabel="name" name="brokerage_role_id" filter filterBy="name" placeholder="Select a Role"
                                                                            valueTemplate={selectedTemplate}
                                                                            itemTemplate={optionTemplate}
                                                                            disabled={isDisableBrokerOfRecord} />
                                                                        {
                                                                            (!formik.values.brokerage_role_id) && (getFormErrorMessage('brokerage_role_id'))

                                                                        }
                                                                    </div>

                                                                    {
                                                                        (formik.values.brokerage_role_id.slug === 'broker_of_record') && (<div className="field signup-label col-lg-12 signup-form-field">
                                                                            <label htmlFor="">How many agents in your brokerage <span className="reuired-star">*</span></label>
                                                                            <InputText
                                                                                disabled={isDisableBrokerOfRecordDetail}
                                                                                autoComplete="off"
                                                                                name="broker_of_record_detail"
                                                                                value={formik.values.broker_of_record_detail} onChange={formik.handleChange}
                                                                                className={classNames("form-control", { 'p-invalid': isFormFieldValid('broker_of_record_detail') })} placeholder="Enter here" />
                                                                            {getFormErrorMessage('broker_of_record_detail')}
                                                                        </div>)
                                                                    }
                                                                    {
                                                                        (formik.values.brokerage_role_id.slug === 'office_manager') && (
                                                                            <div className="field signup-label col-lg-12 signup-form-field">
                                                                                <label htmlFor="">How many agents in your office <span className="reuired-star">*</span></label>
                                                                                <InputText
                                                                                    autoComplete="off"
                                                                                    name="office_manager_detail"
                                                                                    value={formik.values.office_manager_detail} onChange={formik.handleChange}
                                                                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('office_manager_detail') })} placeholder="Enter here" />
                                                                                {getFormErrorMessage('office_manager_detail')}
                                                                            </div>)
                                                                    }
                                                                    {
                                                                        (formik.values.brokerage_role_id.slug === 'team_lead') && (
                                                                            <div className="field signup-label col-lg-12 signup-form-field">
                                                                                <label htmlFor="">How many agents on your team <span className="reuired-star">*</span></label>
                                                                                <InputText
                                                                                    autoComplete="off"
                                                                                    name="team_lead_detail"
                                                                                    value={formik.values.team_lead_detail} onChange={formik.handleChange}
                                                                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('team_lead_detail') })} placeholder="Enter here" />
                                                                                {getFormErrorMessage('team_lead_detail')}
                                                                            </div>
                                                                        )}
                                                                    {
                                                                        (formik.values.brokerage_role_id.slug === 'other') && (
                                                                            <div className="field signup-label col-lg-12 signup-form-field">
                                                                                <label htmlFor="">Other Role <span className="reuired-star">*</span></label>
                                                                                <InputText
                                                                                    autoComplete="off"
                                                                                    name="brokerage_role_other_detail"
                                                                                    value={formik.values.brokerage_role_other_detail} onChange={formik.handleChange}
                                                                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('brokerage_role_other_detail') })} placeholder="Enter here" />
                                                                                {getFormErrorMessage('brokerage_role_other_detail')}
                                                                            </div>
                                                                        )}
                                                                </div>)}
                                                        {
                                                            (formik.values.type === 'ambassador') && (
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="field">
                                                                            <label
                                                                                htmlFor="LicenseInput"
                                                                                className="form-label"
                                                                            >
                                                                                Brokerage Name <span className="reuired-star">*</span>
                                                                            </label>
                                                                            <InputText
                                                                                disabled={isDisableBrokerOfRecordBrokerageName}
                                                                                autoComplete="off"
                                                                                placeholder="Enter here"
                                                                                name="brokerage_name"
                                                                                value={
                                                                                    formik
                                                                                        .values
                                                                                        .brokerage_name
                                                                                }
                                                                                onChange={
                                                                                    formik.handleChange
                                                                                }
                                                                                className={classNames(
                                                                                    "form-control",
                                                                                    {
                                                                                        "p-invalid":
                                                                                            isFormFieldValid(
                                                                                                "brokerage_name"
                                                                                            ),
                                                                                    }
                                                                                )}
                                                                            />
                                                                            {getFormErrorMessage(
                                                                                "brokerage_name"
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="field licence-date">
                                                                            <label
                                                                                htmlFor="LicenseInput"
                                                                                className="form-label"
                                                                            >
                                                                                Broker of Record Name <span className="reuired-star">*</span>
                                                                            </label>
                                                                            <InputText
                                                                                disabled={isDisableBrokerOfRecordName}
                                                                                autoComplete="off"
                                                                                placeholder="Enter here"
                                                                                name="broker_name"
                                                                                value={
                                                                                    formik
                                                                                        .values
                                                                                        .broker_name
                                                                                }
                                                                                onChange={
                                                                                    formik.handleChange
                                                                                }
                                                                                className={classNames(
                                                                                    "form-control",
                                                                                    {
                                                                                        "p-invalid":
                                                                                            isFormFieldValid(
                                                                                                "broker_name"
                                                                                            ),
                                                                                    }
                                                                                )}
                                                                            />
                                                                            {getFormErrorMessage(
                                                                                "broker_name"
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                        }
                                                        {
                                                            (formik.values.type === 'ambassador') && (
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="field">
                                                                            <label
                                                                                htmlFor="LicenseInput"
                                                                                className="form-label"
                                                                            >
                                                                                Broker of Record Email
                                                                            </label>
                                                                            <InputText
                                                                                disabled={isDisableBrokerOfRecordEmail}
                                                                                autoComplete="off"
                                                                                placeholder="Enter here"
                                                                                name="broker_email"
                                                                                value={
                                                                                    formik
                                                                                        .values
                                                                                        .broker_email
                                                                                }
                                                                                onChange={
                                                                                    formik.handleChange
                                                                                }
                                                                                className={classNames(
                                                                                    "form-control",
                                                                                    {
                                                                                        "p-invalid":
                                                                                            isFormFieldValid(
                                                                                                "broker_email"
                                                                                            ),
                                                                                    }
                                                                                )}
                                                                            />
                                                                            {getFormErrorMessage(
                                                                                "broker_email"
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="field licence-date">
                                                                            <label
                                                                                htmlFor="LicenseInput"
                                                                                className="form-label"
                                                                            >
                                                                                Broker of Record Phone
                                                                            </label>
                                                                            <InputMask
                                                                                disabled={isDisableBrokerOfRecordPhone}
                                                                                autoComplete="off"
                                                                                mask="(999) 999-9999" placeholder="(999) 999-9999"
                                                                                name="broker_phone"
                                                                                value={
                                                                                    formik.values.broker_phone
                                                                                }
                                                                                onChange={
                                                                                    formik.handleChange
                                                                                }
                                                                                className={classNames(
                                                                                    "form-control",
                                                                                    {
                                                                                        "p-invalid":
                                                                                            isFormFieldValid(
                                                                                                "broker_phone"
                                                                                            ),
                                                                                    }
                                                                                )}
                                                                            />
                                                                            {getFormErrorMessage(
                                                                                "broker_phone"
                                                                            )}
                                                                        </div>
                                                                    </div>



                                                                </div>)
                                                        }
                                                        {(formik.values.type === 'ambassador') && (
                                                            <div className="row">
                                                                {inputList.map((x, i) => {
                                                                    return (
                                                                        <div key={i} className="repeaterClass">
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="field">
                                                                                        <label
                                                                                            htmlFor="FirstInput"
                                                                                            className="form-label"
                                                                                        >
                                                                                            Broker of Record License #
                                                                                        </label>
                                                                                        <InputText
                                                                                            disabled={isDisableBrokerOfRecordLicenseNo}
                                                                                            autoComplete="off"
                                                                                            className={classNames("form-control",
                                                                                                {
                                                                                                    "p-invalid": isFormFieldValid("license_number"),
                                                                                                }
                                                                                            )}
                                                                                            placeholder="Enter here"
                                                                                            name="license_number"
                                                                                            value={x.license_number}
                                                                                            onChange={(e) => handleInputChange(e, i)
                                                                                            }
                                                                                        />
                                                                                        {(!x.license_number) && (x.license_number_error) && (
                                                                                            <small className="p-error">
                                                                                                {
                                                                                                    x.license_number_error
                                                                                                }
                                                                                            </small>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="field">
                                                                                        <label
                                                                                            htmlFor="LastInput"
                                                                                            className="form-label"
                                                                                        >
                                                                                            Broker of Record License State
                                                                                        </label>
                                                                                        <Dropdown

                                                                                            className={classNames("form-select", { 'p-invalid': isFormFieldValid('license_state') })}
                                                                                            value={x.license_state}
                                                                                            options={dbStatesName}
                                                                                            onChange={(e) =>
                                                                                                handleInputChange(e, i)
                                                                                            } optionLabel="state"
                                                                                            name="license_state" filter filterBy="abbrv"
                                                                                            placeholder="Select a State"
                                                                                            valueTemplate={selectedStateTemplate}
                                                                                            itemTemplate={stateOptionTemplate}
                                                                                            disabled={isDisableBrokerOfRecordLicenseNo}
                                                                                        />


                                                                                        {(!x.license_state) && (x.license_state_error) && (
                                                                                            <small className="p-error">
                                                                                                {
                                                                                                    x.license_state_error
                                                                                                }
                                                                                            </small>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                (!isDisableBrokerOfRecordLicenseNo) && (formik.values.brokerage_role_id.slug === 'broker_of_record') && (<div className="btn-box mb-3">
                                                                                    {inputList.length !== 1 && (
                                                                                        <Button

                                                                                            className="mr10 add-more remove-link me-2"
                                                                                            onClick={() =>
                                                                                                handleRemoveClick(i)
                                                                                            }
                                                                                        >
                                                                                            Remove
                                                                                        </Button>
                                                                                    )}
                                                                                    {inputList.length - 1 === i && (
                                                                                        <Button
                                                                                            onClick={handleAddClick}
                                                                                            className="add-more"
                                                                                        >
                                                                                            Add More +
                                                                                        </Button>
                                                                                    )}
                                                                                </div>)
                                                                            }
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        )}
                                                        <div className="row">
                                                            {
                                                                (formik.values.type === 'agent') && (<div className="col-lg-6">
                                                                    <div className="field">
                                                                        <label
                                                                            htmlFor="LicenseInput"
                                                                            className="form-label"
                                                                        >
                                                                            License
                                                                            State <span className="reuired-star">*</span>
                                                                        </label>
                                                                        <Dropdown
                                                                            name="license_state_id"
                                                                            value={
                                                                                formik.values.license_state_id
                                                                            }
                                                                            options={
                                                                                dbStatesName
                                                                            }
                                                                            className={classNames(
                                                                                {
                                                                                    "p-invalid":
                                                                                        isFormFieldValid(
                                                                                            "license_state_id"
                                                                                        ),
                                                                                }
                                                                            )}
                                                                            optionLabel="state"
                                                                            filter
                                                                            filterBy="abbrv"
                                                                            placeholder="Select a State"
                                                                            valueTemplate={
                                                                                selectedStateTemplate
                                                                            }
                                                                            itemTemplate={
                                                                                stateOptionTemplate
                                                                            }
                                                                            onChange={
                                                                                formik.handleChange
                                                                            }
                                                                            disabled={true}
                                                                        />
                                                                        {getFormErrorMessage(
                                                                            "license_state_id"
                                                                        )}
                                                                    </div>
                                                                </div>)
                                                            }

                                                            <div className={`${(formik.values.type === 'agent') ? 'col-lg-6' : 'col-lg-12'}`}>
                                                                <div className="field">
                                                                    <label
                                                                        htmlFor="AdressInput"
                                                                        className="form-label"
                                                                    >
                                                                        Home Address <span className="reuired-star">*</span>
                                                                    </label>
                                                                    <Autocomplete
                                                                        //value={formik.values.business_address}
                                                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('business_address') })}
                                                                        apiKey={googleAPIKey}
                                                                        onPlaceSelected={(place) => {
                                                                            handleGoogleAddress(place);
                                                                        }}
                                                                        options={{
                                                                            types: ["geocode", "establishment"],
                                                                            componentRestrictions: { country: "us" },
                                                                        }}
                                                                        defaultValue={formik.values.business_address}
                                                                    />
                                                                    {getFormErrorMessage(
                                                                        "business_address"
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="field">
                                                                    <label
                                                                        htmlFor="desableInput"
                                                                        className="form-label"
                                                                    >
                                                                        Upline
                                                                    </label>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        disabled
                                                                        className="disabled"
                                                                        placeholder="None"
                                                                        name="upline_user_name"
                                                                        value={
                                                                            formik
                                                                                .values
                                                                                .upline_user_name
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>)
                                                }
                                                <div className="invite-link-box">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="field">
                                                                <label
                                                                    htmlFor="LicenseInput"
                                                                    className="form-label"
                                                                >
                                                                    Invite Link
                                                                </label>
                                                                <span className="p-input-icon-right">
                                                                    <CopyToClipboard text={window.location.origin + '/signup?refrid=' + refrUserName}
                                                                        onCopy={() => showSuccess()}>
                                                                        <i className="pi pi-copy copyicon" /></CopyToClipboard>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        placeholder="Enter here"
                                                                        disabled={true}
                                                                        value={window.location.origin + '/signup?refrid=' + refrUserName}
                                                                        className="form-control"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex justify-content-end">
                                                <button
                                                    className="btn btn-cancel me-md-2"
                                                    type="button"
                                                    disabled={loading}
                                                    onClick={(e) => navigate('/dashboard')}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="btn btn-submit btn-submit-loader"
                                                    type="submit"
                                                    disabled={loading}
                                                    onClick={() => { setIsSubmitCall(true) }}
                                                >
                                                    Update
                                                    {loading && (
                                                        <Typography align="right">
                                                            <CircularProgress
                                                                value={66}
                                                            />
                                                        </Typography>
                                                    )}
                                                </button>

                                            </div>
                                        </div>
                                    </form>
                                </TabPanel>
                                <TabPanel
                                    className="right-tab tabPanelClass"
                                    header="Change Password"
                                // rightIcon="pi pi-user"

                                >
                                    <div className="card h-100">
                                        <form onSubmit={formikCP.handleSubmit} className="edit-form-section">

                                            <div className="card-body change-pass-sec">
                                                <div className="edit-form-section-1">
                                                    {errorMessageCP && (
                                                        <Alert
                                                            sx={{ mb: 3 }}
                                                            severity="error"
                                                            variant="filled"
                                                        >
                                                            {errorMessageCP}
                                                        </Alert>)}
                                                    <div className="field">
                                                        <label
                                                            htmlFor="PasswordInput"
                                                            className="form-label"
                                                        >
                                                            Current Password <span className="reuired-star">*</span>
                                                        </label>
                                                        <Password
                                                            feedback={false}
                                                            name="old_password"
                                                            placeholder="Enter here"
                                                            value={formikCP.values.old_password}
                                                            onChange={formikCP.handleChange}
                                                            className={classNames(
                                                                "form-control",
                                                                {
                                                                    "p-invalid":
                                                                        isFormFieldValidCP(
                                                                            "old_password"
                                                                        ),
                                                                }
                                                            )}
                                                            toggleMask
                                                        />
                                                        {getFormErrorMessageCP(
                                                            "old_password"
                                                        )}
                                                    </div>

                                                    <div className="field">
                                                        <label
                                                            htmlFor="PasswordInput"
                                                            className="form-label"
                                                        >
                                                            New Password <span className="reuired-star">*</span>
                                                        </label>
                                                        <Password

                                                            name="new_password"
                                                            placeholder="Enter here"
                                                            value={formikCP.values.new_password}
                                                            onChange={formikCP.handleChange}
                                                            className={classNames(
                                                                "form-control",
                                                                {
                                                                    "p-invalid":
                                                                        isFormFieldValidCP(
                                                                            "new_password"
                                                                        ),
                                                                }
                                                            )}
                                                            toggleMask
                                                        />
                                                        {getFormErrorMessageCP(
                                                            "new_password"
                                                        )}
                                                    </div>

                                                    <div className="field">
                                                        <label
                                                            htmlFor="PasswordInput"
                                                            className="form-label"
                                                        >
                                                            Confirm Password <span className="reuired-star">*</span>
                                                        </label>
                                                        <Password
                                                            name="password_confirmation"
                                                            placeholder="Enter here"
                                                            value={formikCP.values.password_confirmation}
                                                            onChange={formikCP.handleChange}
                                                            className={classNames(
                                                                "form-control",
                                                                {
                                                                    "p-invalid":
                                                                        isFormFieldValidCP(
                                                                            "password_confirmation"
                                                                        ),
                                                                }
                                                            )}
                                                            toggleMask
                                                        />
                                                        {getFormErrorMessageCP(
                                                            "password_confirmation"
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex justify-content-end">
                                                <button
                                                    className="btn btn-cancel me-md-2"
                                                    type="button"
                                                    onClick={(e) => navigate('/dashboard')}
                                                    disabled={loadingChangePassword}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="btn btn-submit btn-submit-loader"
                                                    type="submit" disabled={loadingChangePassword}
                                                >
                                                    Update
                                                </button>
                                                {loadingChangePassword && (
                                                    <Typography align="center">
                                                        <CircularProgress
                                                            value={66}
                                                        />
                                                    </Typography>
                                                )}
                                            </div>
                                        </form>
                                    </div>

                                </TabPanel>
                                {
                                    (userRole == 'ambassador') && (userIsMLS) && (
                                        <TabPanel className='right-tab' header="MLS Information" rightIcon="pi pi-user">
                                            <div className="card h-100">
                                                <form className="edit-form-section" onSubmit={formikMLS.handleSubmit}>

                                                    <div className="card-body change-pass-sec mls-h">
                                                        {errorMessageMLS && (
                                                            <Alert
                                                                sx={{ mb: 3 }}
                                                                severity="error"
                                                                variant="filled"
                                                            >
                                                                {errorMessageMLS}
                                                            </Alert>)}
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="field">
                                                                    <label htmlFor="FirstInput" className="form-label">Name of MLS <span className="reuired-star">*</span></label>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        name="name"
                                                                        placeholder="Enter here"
                                                                        value={formikMLS.values.name}
                                                                        onChange={formikMLS.handleChange}
                                                                        className={classNames(
                                                                            "form-control",
                                                                            {
                                                                                "p-invalid":
                                                                                    isFormFieldValidMLS(
                                                                                        "name"
                                                                                    ),
                                                                            }
                                                                        )}
                                                                    />
                                                                    {getFormErrorMessageMLS(
                                                                        "name"
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="field">
                                                                    <label htmlFor="LastInput" className="form-label">Website URL <span className="reuired-star">*</span></label>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        name="website_url"
                                                                        placeholder="Enter here"
                                                                        value={formikMLS.values.website_url}
                                                                        onChange={formikMLS.handleChange}
                                                                        className={classNames(
                                                                            "form-control",
                                                                            {
                                                                                "p-invalid":
                                                                                    isFormFieldValidMLS(
                                                                                        "website_url"
                                                                                    ),
                                                                            }
                                                                        )}
                                                                    />
                                                                    {getFormErrorMessageMLS(
                                                                        "website_url"
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="field">
                                                                    <label htmlFor="EmailInput" className="form-label ">Username <span className="reuired-star">*</span></label>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        name="username"
                                                                        placeholder="Enter here"
                                                                        value={formikMLS.values.username}
                                                                        onChange={formikMLS.handleChange}
                                                                        className={classNames(
                                                                            "form-control",
                                                                            {
                                                                                "p-invalid":
                                                                                    isFormFieldValidMLS(
                                                                                        "username"
                                                                                    ),
                                                                            }
                                                                        )}
                                                                    />
                                                                    {getFormErrorMessageMLS(
                                                                        "username"
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="field">
                                                                    <label htmlFor="PhoneInput" className="form-label">Password <span className="reuired-star">*</span></label>
                                                                    <Password
                                                                        name="password"
                                                                        placeholder="Enter Here"
                                                                        feedback={false}
                                                                        value={formikMLS.values.password}
                                                                        onChange={formikMLS.handleChange}
                                                                        className={classNames(
                                                                            "form-control",
                                                                            {
                                                                                "p-invalid":
                                                                                    isFormFieldValidMLS(
                                                                                        "password"
                                                                                    ),
                                                                            }
                                                                        )}
                                                                        toggleMask
                                                                    />
                                                                    {getFormErrorMessageMLS(
                                                                        "password"
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer d-flex justify-content-end">
                                                        <button className="btn btn-cancel me-md-2" disabled={loadingMLS} type="button" onClick={(e) => navigate('/dashboard')}>Cancel</button>
                                                        <button type="submit" className="btn btn-submit btn-submit-loader" disabled={loadingMLS}>Update
                                                            {loadingMLS && (
                                                                <Typography align="center">
                                                                    <CircularProgress
                                                                        value={66}
                                                                    />
                                                                </Typography>
                                                            )}
                                                        </button>

                                                    </div>
                                                </form>
                                            </div>
                                        </TabPanel>
                                    )
                                }

                                {((userRole == 'agent') && (user.is_license_flag === 1) &&
                                    <TabPanel className='right-tab' header="Subscription Info" rightIcon="pi pi-user">
                                        <div className="card h-100 subs-tabs">
                                            <div className="card-body change-pass-sec subscription-h table-responsive">
                                                <div className="header_plan">
                                                    {(planInfoCheck == false) ?
                                                        (<h3 className="subs-main-title">Payment History </h3>) :
                                                        (<h3 className="subs-main-title current_plan">Current Plan : {
                                                            (user.plan.length > 0) &&
                                                            (user.plan.map((item, i) => {
                                                                return (<span>{(item.flag === "active") ? item.name : ""}</span>)
                                                            }))

                                                        }</h3>)}
                                                    {(user.plan.length > 0) &&
                                                        (user.plan.map((item, i) => {
                                                            if (i === 0) {
                                                                return (<span>{(item.flag === "upcoming") && (i === 0) ? <Button onClick={() => cancelUpcomingPlan((user.id))} className="change_plan_btn">Cancel Plan Change</Button> : <Button onClick={(e) => handleModal()} className="change_plan_btn">Change Plan</Button>}</span>)
                                                            }

                                                        }))}

                                                </div>
                                                {
                                                    (planInfoCheck == false) ? (<table className="table table-borderless table-responsive">
                                                        <thead>
                                                            <tr>
                                                                <th className="first-th">Date</th>
                                                                <th>Charge <br />Amount</th>
                                                                <th>Status</th>

                                                                <th className="last-th text-center">Invoice <br />Details</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (user.planinfo.length > 0) &&
                                                                (user.planinfo.map((item, i) => {

                                                                    //console.log(`Index: ${i}`);

                                                                    return (
                                                                        <tr>
                                                                            <td className="first-td">{item.date}</td>
                                                                            <td>${item.amt_charge / 100}.00</td>
                                                                            <td>
                                                                                <span className="paid">{(item.status === "charge.succeeded") ? "Paid" : "Paid"}</span>
                                                                            </td>

                                                                            <td className="last-td text-center">
                                                                                <NavLink onClick={() => openInNewTab((item.invoice) ? item.invoice : '#')}><img src="../../assets/images/subs-eye.svg" alt="" /></NavLink>
                                                                            </td>
                                                                        </tr>)

                                                                }))

                                                            }


                                                        </tbody>
                                                    </table>) : (<table className="table table-borderless table-responsive">
                                                        <thead>
                                                            <tr>
                                                                <th className="first-th plan-info">Plan History</th>
                                                                <th>Start On</th>
                                                                <th>Renews On</th>
                                                                <th>Status</th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (user.plan.length > 0) &&
                                                                (user.plan.map((item, i) => {



                                                                    return (
                                                                        <tr>
                                                                            <td className="first-td">{item.name}</td>
                                                                            <td>{item.start}</td>
                                                                            <td>{item.end}</td>
                                                                            <td>
                                                                                <span className="first-td">{item.flag}</span>
                                                                            </td>


                                                                        </tr>)

                                                                }))

                                                            }


                                                        </tbody>
                                                    </table>)
                                                }


                                            </div>
                                            <div className="card-footer">
                                                <div className="invoice-download text-end">
                                                    <button className="btn btn-submit-trans" onClick={() => planInformation()}> {(planInfoCheck == false) ?
                                                        (<span >Plan Information </span>) :
                                                        (<span> Payment History</span>)}</button>
                                                    {(user.lastplan === "Basic") ? (<div></div>) : (<button className="btn btn-submit-trans" onClick={() => cancelSubscription((user.id))}>Cancel Plan Change</button>)}
                                                    <button role="link" className="btn btn-submit-trans" onClick={handleClick}>
                                                        Edit Credit Card
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>)
                                }
                            </TabView>
                        </div>
                    </div>
                </div>
            </section>
            {
                (showUploadDocs) && (<UploadDocuments showUploadDocs={showUploadDocs} changeStatuUpload={changeStatuUpload} reloadListing={reloadListing} editRowId={editRowId} editRowType={editRowType} dbUserFullName={dbUserFullName} callFrom={callFrom} is_profile_page_call={1} />)
            }
            {
                (showSelectPlan) && (<SelectPlan showSelectPlan={showSelectPlan} changeStatuClosedhandleModal={changeStatuClosedhandleModal} loginUserId={user.id} loginUserName={user.name} />)
            }
            {
                (showEditCreditCard) && (<EditCreditCard showEditCreditCard={showEditCreditCard} changeStatusEditCreditCard={changeStatusEditCreditCard} loginUserId={user.id} loginUserName={user.name} />)
            }
        </>
    );
};

export default EditEProfile;