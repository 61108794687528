import { InputText, RadioButton } from "primereact";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "react-bootstrap";
import Swal from 'sweetalert2';
// import axios from "axios";
import { Alert, CircularProgress, Typography } from "@mui/material";
import Cards from 'react-credit-cards';
import Cardsm from './Card';
import Styles from './Styles';
import axios from "../../../axiosCall.js";
import TextField from 'material-ui/TextField'
import { IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useAuth from "./../../../hooks/useAuth";

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate
} from './cardUtils';
import { Form, Field } from 'react-final-form'
const MakePayment = (props) => {
    const { user, setUserDataCall, logout } = useAuth();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [showBack, setShowback] = useState(false);
    const [msg, setMsg] = useState(false);
    const search = useLocation().search;
    const [successMessage, setSuccessMessage] = useState('');
    const [movelicense, setMovelicense] = useState(false);
    const [numberCall, setNumberCall] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [takePlanPrice, setTakePlanPrice] = useState(0);
    const [totalPlanPrice, setTotalPlanPrice] = useState(0);
    const [isLinkExpired, setIsLinkExpired] = useState(false);

    const handleSubmitCPThrough = async (data) => {
        let dataSend = {
            product_id: props.favorite,
            user_id: props.userId
        }
        try {
            const response = await axios.post('/update-sub', dataSend).then(function (response) {
                const { Status, StatusMessage, Error, Result } = response.data ? response.data : [];
                let finalApiError = '';
                if (Status === false) {
                    // if (Error.length > 0) {
                    //     // Error.map(
                    //     //     (errorMessage) =>
                    //     //         finalApiError += '<li>' + errorMessage + '</li>'
                    //     //             (finalApiError = errorMessage)
                    //     // );
                    // } else {
                    finalApiError = StatusMessage;
                    // }
                    setErrorMessage(finalApiError);
                    setLoading(false);
                } else if (Status === true) {
                    setLoading(false);
                    setMsg(true)
                    setUserDataCall(Result.user);
                    // navigate('/edit-profile');
                    //props.modalClose()
                    //props.changeStatuClosedhandleModal()
                }
            }).catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    setErrorMessage(error.response.data.message);
                    setLoading(false);

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    setErrorMessage(error.message);
                    setLoading(false);
                }
                console.log(error.config);
            });

        } catch (err) {
            //console.log(err.toJSON());
            // setErrorMessage(err.message);
            // setLoading(false);
            if (err.response) {
                // The client was given an error response (5xx, 4xx)
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else if (err.request) {
                // The client never received a response, and the request was never left
            } else {
                // Anything else
                console.log('Error', err);
            }
        }
    };

    const handleSubmitCP = async (data) => {
        setLoading(true);


        let dataSend = {
            name: data.name,
            cvc: data.cvc,
            product_id: props.favorite,
            number: data.number,
            expiry: data.expiry,
            user_id: props.userId
        }
        try {
            const response = await axios.post('/update-subscription', dataSend).then(function (response) {
                const { Status, StatusMessage, Error, Result } = response.data ? response.data : [];
                let finalApiError = '';
                if (Status === false) {
                    // if (Error.length > 0) {
                    //     // Error.map(
                    //     //     (errorMessage) =>
                    //     //         finalApiError += '<li>' + errorMessage + '</li>'
                    //     //             (finalApiError = errorMessage)
                    //     // );
                    // } else {
                    finalApiError = StatusMessage;
                    // }
                    setErrorMessage(finalApiError);
                    setLoading(false);
                } else if (Status === true) {
                    setLoading(false);
                    setMsg(true)
                    setUserDataCall(Result.user);
                    // navigate('/edit-profile');
                    //props.modalClose()
                    //props.changeStatuClosedhandleModal()
                }
            }).catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    setErrorMessage(error.response.data.message);
                    setLoading(false);

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    setErrorMessage(error.message);
                    setLoading(false);
                }
                console.log(error.config);
            });

        } catch (err) {
            //console.log(err.toJSON());
            // setErrorMessage(err.message);
            // setLoading(false);
            if (err.response) {
                // The client was given an error response (5xx, 4xx)
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else if (err.request) {
                // The client never received a response, and the request was never left
            } else {
                // Anything else
                console.log('Error', err);
            }
        }
    };
    const onClick = () => {
        props.parentToChild()
    }
    useEffect(() => {
        console.log(props.nextOpen)
        if(props.nextOpen === true){
            handleSubmitCPThrough(props.nextOpen)
            setMsg(true)
        }
        var planPrice = 0, takePlanPrice = 0, finalPrice = 0;

        if (props.favorite === "1") {
            planPrice = 9;
            setTakePlanPrice(9)
        } else if (props.favorite === "2") {
            planPrice = 19;
            setTakePlanPrice(19)
        }
        else if (props.favorite === "3") {
            planPrice = 0;

            setTakePlanPrice(0)

        }

        if (props.userLicenseState === 9 || props.userLicenseState === 23) {
            finalPrice = (props.favorite === "3") ? planPrice : planPrice;
            setTotalPlanPrice(finalPrice)
            setMovelicense(false);
        } else if (props.userLicenseState === 6) {
            finalPrice = (props.favorite === "3") ? planPrice : planPrice;
            setTotalPlanPrice(finalPrice )
            setMovelicense(false);

        } else {
            finalPrice = (props.favorite === "3") ? planPrice - 25 : planPrice;
            setTotalPlanPrice(finalPrice + 25)
        }


    }, [numberCall]);




    return (

        <section className= {(msg === true) ? "referral-template subs-template credit_card_details form-section auth-bg" : "referral-template subs-template credit_card_details"}>
            { (msg === true) ? <div className="container-fluid">
                    <div className="form-container">
                        <div className="form-wrapper">
                            <form>
                                <div className="back-sign">
                                    {/* <NavLink to="/set-password"><img src="./assets/images/back-icon.svg" alt="" /></NavLink> */}
                                </div>
                                <h1 className="signup-heading my-2">Success</h1>

                                <p className="signup-desc changedPassword-desc mb-4">
                                    <span>
                                        <IconButton>
                                            <CheckCircleIcon />
                                        </IconButton>
                                    </span>
                                    {/* Your <span>REFR</span> Subscription has been upto date!!. */}
                                    Your REFR Subscription has been changed, please check your email for details.
                                </p>

                                <div className="form-group">
                                    <button className="btn form-btn" type="button" onClick={() => { props.modalClose(); props.changeStatuClosedhandleModal() }} >Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
             </div> :
            <div className="container">
                <div className="referral-container">
                    {/* <h2 className='main-heading'>Subscription</h2> */}
                    <div className="cards-container row row-cols-1 row-cols-lg-2 g-1">
                        <div className="col col-left">
                            <Card className="subs-card-left">

                                <Form
                                    onSubmit={handleSubmitCP}

                                    render={({
                                        handleSubmit,
                                        form,
                                        submitting,
                                        pristine,
                                        values,
                                        active
                                    }) => {
                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <Card.Body>


                                                    <div className="card-container">

                                                        <div className='referral-fields credit_card_page'>
                                                            {/* <p className="title sub-titles">Enter your credit card details</p> */}

                                                            {errorMessage && (
                                                                <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                                                    {errorMessage}
                                                                </Alert>
                                                            )}
                                                            <div className="field credit-field">
                                                                {/* <p className="sub-titles-amt" >Total Amount : ${finalPrice}.00 </p> */}
                                                                <label htmlFor="FirstInput" className="form-label">Cardholder Name </label>
                                                                <Field
                                                                    name="name"
                                                                    component="input"
                                                                    type="text"
                                                                    placeholder="Enter Here e.g. 'RIVER DEE'"
                                                                    pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                                                                    patternMismatch="Capitalize your name!"
                                                                    required
                                                                    className="form-control p-inputtext"
                                                                    parse={value => {
                                                                        // Do what you want with `value`
                                                                        return value.toUpperCase().replace(/[^a-zA-Z ]/g, "");
                                                                    }}

                                                                />
                                                            </div>
                                                            <div className="field credit-field">
                                                                <label htmlFor="LastInput" className="form-label">Card Number</label>
                                                                <Field
                                                                    name="number"
                                                                    component="input"
                                                                    type="text"
                                                                    pattern="[\d| ]{16,22}"
                                                                    placeholder="Enter Here "
                                                                    required
                                                                    format={formatCreditCardNumber}
                                                                    className="form-control p-inputtext"
                                                                />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="field credit-field">
                                                                        <label htmlFor="FirstInput" className="form-label">Expiration Date</label>
                                                                        <Field
                                                                            name="expiry"
                                                                            component="input"
                                                                            type="text"
                                                                            pattern="\d\d/\d\d\d\d"
                                                                            placeholder="MM/YYYY e.g. '04/2045'"
                                                                            required
                                                                            format={formatExpirationDate}
                                                                            className="form-control p-inputtext"
                                                                            parse={value => {
                                                                                // Do what you want with `value`
                                                                                const changeValue = value.replace(/\//g, "");
                                                                                return changeValue.slice(0, 2) + (changeValue.length > 2 ? "/" : "") + changeValue.slice(2, 7);
                                                                            }}

                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="field credit-field">
                                                                        <label htmlFor="LastInput" className="form-label">CVC/CVV</label>
                                                                        <Field
                                                                            name="cvc"
                                                                            component="input"
                                                                            type="password"
                                                                            pattern="\d{3,4}"
                                                                            placeholder="Enter Here"
                                                                            format={formatCVC}
                                                                            required
                                                                            className="form-control p-inputtext"

                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </Card.Body>

                                                <Card.Footer className="d-flex justify-content-end">
                                                    <button
                                                        type="button"
                                                        class="btn btn-cancel btn-700 me-3"
                                                        onClick={onClick}
                                                        disabled={loading}
                                                    >
                                                        Back

                                                    </button>


                                                    <button type="submit" class="btn  btn-submit subs-pay-btn btn-submit-loader" disabled={loading}>
                                                        {loading ? "Processing" : "Pay"}
                                                        {loading && (<Typography align="center">
                                                            <CircularProgress value={30} />
                                                        </Typography>)}
                                                    </button>
                                                </Card.Footer>
                                            </form>

                                        )
                                    }} />

                            </Card>
                        </div>
                        <div className="col col col-right">
                            <Card className="subs-card-right">
                                <Card.Body>
                                    <ul className="payment-details-list">


                                        <li>
                                            <p className="title-name">Plan fees</p>
                                            <p className="amount">${takePlanPrice}</p>
                                        </li>
                                    </ul>
                                </Card.Body>
                                <Card.Footer>
                                    <ul className="list-ta">
                                        <li>
                                            <p className="title-name">Total Amount</p>
                                            <p className="amount">${totalPlanPrice}</p>
                                        </li>
                                    </ul>
                                </Card.Footer>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>}
        </section>

    )


}

export default MakePayment;
