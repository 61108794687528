import React from 'react'

const EmailTemp = () => {
    return (
        <>
            <div class="wrapper">
                <div class="wrapper-inner" >
                    <table>
                        <tr>
                            <td>
                                <table class="inner-table" width="100%">
                                    <tr>
                                        <td class="inner-td" align="center">
                                            <p class="logo-img"><a href="/#"><img src="./assets/images/logo.png" alt="" class="email-logo" width="40%" /></a></p>
                                        </td>
                                    </tr>
                                </table> 
                                <table class="inner-table" width="100%">
                                    <tr>
                                        <td align="center">
                                            <p class="btn-p"><a href="/#" class="btn quote-btn">Click Me!</a></p>
                                        </td>
                                    </tr>
                                </table>
                                <table class="inner-table" width="100%">
                                    <tr>
                                        <td>
                                            <p class="msg-text">Thank you for your patience and for choosing REFR. Your finalized custom shipping quote from REFR is ready for your approval. <a href="/#">Click on this link</a></p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </>
    )
}

export default EmailTemp;