import React, { useState, useEffect,useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useLocation } from "react-router-dom";
import { Editor, InputText } from 'primereact';
import { RadioButton } from 'primereact';
import Button from 'react-bootstrap/Button';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from "../../../axiosCall.js";
import useAuth from "./../../../hooks/useAuth";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { Dropdown } from 'primereact/dropdown';
import Swal from 'sweetalert2';

const EmailTemplate = () => {
    const secretKey = "REFR2022@GALAXY";
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get('u');
    const editDataId = new URLSearchParams(search).get('e');
    let requestUserId = '';
    let isEditMode = false;
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isUserAssigned, setIsUserAssigned] = useState(false);

    const [dbEmailTemplate, setDbEmailTemplate] = useState([]);
    const mediaRef = useRef('');
    if (userId === null && editDataId === null) {
        navigate('/dashboard');
    } else {
        //alert('else');
        if (userId) {
            let userIdDecrypt = AES.decrypt(userId, secretKey);
            if (userIdDecrypt == "") {

                navigate('/dashboard');
            }
            else {
                isEditMode = false;
                requestUserId = JSON.parse(userIdDecrypt.toString(Utf8));
            }
        }
        else if (editDataId) {
            let idDecrypt = AES.decrypt(editDataId, secretKey);
            if (idDecrypt == "") {
                navigate('/dashboard');
            }
            else {
                isEditMode = true;
                requestUserId = JSON.parse(idDecrypt.toString(Utf8));
            }
        }
        else {
            navigate('/dashboard');
        }
    }
    const { user, logout } = useAuth();

    

    const handleTypeMode = (mode) => {
        formik.setFieldValue("type", mode);
        formik.setFieldValue("subject", "");
        formik.setFieldValue("message", "");
        formik.setFieldValue("email_template_id", "");

        if(mode === 'referring_agent')
        {
            formik.setFieldValue("email", formik.values.referring_email);
        }
        else if(mode === 'assigned_agent')
        {
            formik.setFieldValue("email", formik.values.assigned_email);
        }
        else
        {
            formik.setFieldValue("email", formik.values.assigned_email);
            formik.setFieldValue("client_email", formik.values.client_email);
            formik.setFieldValue("ref_email", formik.values.referring_email);
        }
    };
    const formik = useFormik({
        initialValues: {
            edit_id: '',
            referral_id: '',
            referring_users_id: '',
            assigned_users_id: '',
            type: 'referring_agent',
            type_db: '',
            referring_upline_user_first_name: '',
            referring_user_upline_name:'',
            referring_user_upline_agent_brokerage_name:'',
            assigned_upline_user_first_name: '',
            email_template_id: '',
            email: '',
            client_email: '',
            ref_email: '',
            assigned_email: '',
            referring_email: '',
            referring_user_first_name: '',
            assigned_user_first_name: '',
            referring_user_full_name: '',
            assigned_user_full_name: '',
            assigned_agent_phone: '',
            upline_first_name:'',
            client_first_name:'',
            client_full_name:'',
            client_address:'',
            reference_number:'',
            subject: '',
            message: '',
            attachment_email_template_db:'',
            attachment_edit_db:'',
            attachment:'',
            call_from:'referral_page',
            host_name: window.location.origin,
            assigned_brokerage_name:'',
            assigned_broker_name:'',
            assigned_broker_license_json:'',
            comments:'',
            price_range:'',
            referring_phone:'',
            client_phone:'',
            other_interested_client:'',
            referral_status:'',
            sale_price:'',
            commission_earned:'',
            refr_referral_fee_percent:'',
            referral_fee_35percent:'',
            agent_commission_plan:'',
            agent_commission_plan_current:'',
            agent_commission_plan_at_referral_submission:'',
            refr_agent_commission:'',
            refr_5percent:'',
            brokerage_commission_30percent:'',
            referral_fee_received:'',
            closing_date:'',
            property_address: '',
            pending_brokerage_commission_due:'',
            closed_brokerage_commission_due:'',
            brand_preference_data:'',
            other_requiremment_data:'',
            language_data:''
        },
        validate: (data) => {
            let errors = {};

            if (!data.subject) {
                errors.subject = 'Subject is required.';
            }

            if (data.message === null) {
                errors.message = 'Message is required.';
            }
            else if (!data.message) {
                errors.message = 'Message is required.';
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    const fetchUserDetailData = async () => {
        setErrorMessage("");
        let formData = new FormData();
        formData.append('referral_id', requestUserId);
        try {
            const response = await axios.post("/get-referral-users-data", formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                //alert(Result.referral.referring_email)
                formik.setFieldValue("referral_id",requestUserId);
                //console.log(JSON.stringify(Result.referral));
                formik.setFieldValue("referring_user_first_name", Result.referral.referring_user_first_name);
                //console.log('assigned_users_id '+Result.referral.assigned_users_id)
                formik.setFieldValue("referring_user_full_name", Result.referral.referring_user_first_name+' '+Result.referral.referring_user_last_name);
                
                
                if (isEditMode)
                {
                    if(formik.values.type_db === 'referring_agent')
                    {
                        formik.setFieldValue("email", Result.referral.referring_email);
                    }
                }
                else
                {
                    formik.setFieldValue("email", Result.referral.referring_email);
                }
                    
                formik.setFieldValue("referring_email", Result.referral.referring_email);
                formik.setFieldValue("referring_users_id", Result.referral.referring_users_id);

                formik.setFieldValue("client_first_name", Result.referral.client_first_name);
                
                formik.setFieldValue("client_full_name", Result.referral.client_first_name+' '+Result.referral.client_last_name);
                
                formik.setFieldValue("reference_number", Result.referral.reference_number);
                formik.setFieldValue("referral_status", Result.referral.referral_status);
                
                if(Result.referral.referral_fee_received)
                {
                    formik.setFieldValue("referral_fee_received", Result.referral.referral_fee_received);
                }

                if(Result.referral.refr_referral_fee_percent_temp)
                {
                    formik.setFieldValue("refr_referral_fee_percent", Result.referral.refr_referral_fee_percent_temp);
                }

                if(Result.referral.closed_brokerage_commission_due_temp)
                {
                    formik.setFieldValue("closed_brokerage_commission_due", Result.referral.closed_brokerage_commission_due_temp);
                }
                if(Result.referral.pending_brokerage_commission_due_temp)
                {
                    formik.setFieldValue("pending_brokerage_commission_due", Result.referral.pending_brokerage_commission_due_temp);
                }
                if(Result.referral.sales_price_temp)
                {
                    formik.setFieldValue("sale_price", Result.referral.sales_price_temp);
                }
                if(Result.referral.commission_earned_temp)
                {
                    formik.setFieldValue("commission_earned", Result.referral.commission_earned_temp);
                }
                if(Result.referral.referral_fee_35percent_temp)
                {
                    formik.setFieldValue("referral_fee_35percent", Result.referral.referral_fee_35percent_temp);
                }
                
                if(Result.referral.agent_plan_commission_temp)
                {
                    formik.setFieldValue("agent_commission_plan", Result.referral.agent_plan_commission_temp);
                }
                if(Result.referral.agent_plan_commission_temp)
                {
                    formik.setFieldValue("agent_commission_plan_current", Result.referral.agent_plan_commission_temp);
                }
                if(Result.referral.agent_commission_plan_current_temp)
                {
                    formik.setFieldValue("agent_commission_plan_at_referral_submission", Result.referral.agent_commission_plan_current_temp);
                }
                if(Result.referral.refr_plan_commission_temp)
                {
                    formik.setFieldValue("refr_agent_commission", Result.referral.refr_plan_commission_temp);
                }
                if(Result.referral.refr_5percent_temp)
                {
                    formik.setFieldValue("refr_5percent", Result.referral.refr_5percent_temp);
                }
                if(Result.referral.brokerage_commission_30percent_temp)
                {
                    formik.setFieldValue("brokerage_commission_30percent", Result.referral.brokerage_commission_30percent_temp);
                }
                if(Result.referral.closing_date_temp)
                {
                    formik.setFieldValue("closing_date", Result.referral.closing_date_temp);
                }
                if(Result.referral.property_address_temp)
                {
                    formik.setFieldValue("property_address", Result.referral.property_address_temp);
                }
                if(Result.referral.client_address)
                {
                    formik.setFieldValue("client_address", Result.referral.client_address);
                }
                if(Result.referral.client_email)
                {
                    formik.setFieldValue("client_email", Result.referral.client_email);
                }
                if(Result.referral.client_phone)
                {
                    formik.setFieldValue("client_phone", Result.referral.client_phone);
                }
                if(Result.referral.other_interested_client)
                {
                    formik.setFieldValue("other_interested_client", Result.referral.other_interested_client);
                }
                if(Result.referral.referring_phone)
                {
                    formik.setFieldValue("referring_phone", Result.referral.referring_phone);
                }

                if(Result.referral.price_range)
                {
                    formik.setFieldValue("price_range", Result.referral.price_range);
                }

                if(Result.referral.comments)
                {
                    formik.setFieldValue("comments", Result.referral.comments);
                }
                if(Result.referral.referring_upline_users_id)
                {
                    let uplineUserFirstName = Result.referral.referring_upline_users_id.name.split(' ');
                    //alert(uplineUserFirstName)
                    formik.setFieldValue("referring_upline_user_first_name", uplineUserFirstName[0]);
                }
                if(Result.referral.referring_upline_users_id)
                {
                    let uplineUserFirstName = Result.referral.referring_upline_users_id.name;
                    //alert(uplineUserFirstName)
                    formik.setFieldValue("referring_user_upline_name", uplineUserFirstName);
                }
                if(Result.referral.referring_upline_users_id)
                {
                    let uplineUserBrokerName = Result.referral.referring_upline_users_id.broker_name;
                    //alert(uplineUserFirstName)
                    formik.setFieldValue("referring_user_upline_agent_brokerage_name", uplineUserBrokerName);
                }
                if(Result.referral.referring_broker_name)
                {
                    formik.setFieldValue("referring_broker_name", Result.referral.referring_broker_name);
                }
                
                if(Result.referral.language_data)
                {
                    formik.setFieldValue("language_data", Result.referral.language_data);
                }
                if(Result.referral.other_requiremment_data)
                {
                    formik.setFieldValue("other_requiremment_data", Result.referral.other_requiremment_data);
                }
                if(Result.referral.brand_preference_data)
                {
                    formik.setFieldValue("brand_preference_data", Result.referral.brand_preference_data);
                }
                if(Result.referral.assigned_users_id)
                {
                    if(Result.referral.assigned_upline_users_id)
                    {
                        let uplineUserFirstName = Result.referral.assigned_upline_users_id.name.split(' ');
                        formik.setFieldValue("assigned_upline_user_first_name", uplineUserFirstName[0]);
                    }
                    formik.setFieldValue("assigned_user_first_name", Result.referral.assigned_user_first_name);
                    formik.setFieldValue("assigned_user_full_name", Result.referral.assigned_user_first_name+' '+Result.referral.assigned_user_last_name);
                    formik.setFieldValue("assigned_users_id", Result.referral.assigned_users_id);
                    formik.setFieldValue("assigned_email", Result.referral.assigned_email);
                    
                    formik.setFieldValue("assigned_agent_phone", Result.referral.assigned_phone);

                    formik.setFieldValue("assigned_brokerage_name", Result.referral.assigned_brokerage_name);
                    formik.setFieldValue("assigned_broker_name", Result.referral.assigned_broker_name);
                    formik.setFieldValue("assigned_broker_license_json", Result.referral.assigned_broker_license_json);

                    setIsUserAssigned(true);
                }
                else
                {
                    setIsUserAssigned(false);
                }
            }
            else {
                navigate('/dashboard');
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };


    useEffect(() => {
        const elements = document.getElementsByClassName("ql-image");
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
        if (user !== null) {

            const fetchEmailTemplateData = async () => {

                try {
                    const response = await axios.post("/get-admin-email-template-data");
                    const { Status, StatusMessage, Error, Result } = response.data;

                    if (Status === true) {
                        setDbEmailTemplate(Result);
                    }
                } catch (err) {
                    if (err.response.status && err.response.status == 401) {
                        logout();
                        navigate('/login');
                    }
                }
            };

            fetchEmailTemplateData();
            
            if (isEditMode)
                fetchEditData();
            else
                fetchUserDetailData();
        }
    }, [user, requestUserId]);
    const fetchEditData = async () => {
        let formData = new FormData();
        formData.append('admin_send_email_to_user_log_id', requestUserId);
        try {
            const response = await axios.post("/get-admin-send-email-to-user-log", formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                formik.setFieldValue("edit_id", Result[0].id);
                formik.setFieldValue("email", Result[0].email);
                formik.setFieldValue("ref_email", Result[0].referring_user_email);
                formik.setFieldValue("client_email", Result[0].client_email);
                formik.setFieldValue("subject", Result[0].subject);
                formik.setFieldValue("message", Result[0].message);
                formik.setFieldValue("attachment_edit_db", Result[0].attachment);
                formik.setFieldValue("email_template_id", Result[0].email_template[0]);

                if(Result[0].referral_user_type)
                {
                    if(Result[0].referral_user_type === 'referring_user')
                    {
                        formik.setFieldValue("type",'referring_agent');
                        formik.setFieldValue("type_db",'referring_agent');
                    }
                        
                    else if(Result[0].referral_user_type === 'assigned_user')
                    {
                        formik.setFieldValue("type",'assigned_agent');
                        formik.setFieldValue("type_db",'assigned_agent');
                    } 
                    else
                    {
                        formik.setFieldValue("type",'client_and_referring_assigned_agent');
                        formik.setFieldValue("type_db",'client_and_referring_assigned_agent');
                    }
                        
                }

                formik.setFieldValue("referral_id",Result[0].referral_id);
                
                formik.setFieldValue("referring_user_first_name", Result[0].referring_user_first_name);
                
                formik.setFieldValue("referring_user_full_name", Result[0].referring_user_first_name+' '+Result[0].referring_user_last_name);
                
                formik.setFieldValue("referring_email", Result[0].referring_email);
                formik.setFieldValue("referring_users_id", Result[0].referring_users_id);
                
                if(Result[0].assigned_users_id)
                {
                    formik.setFieldValue("assigned_user_first_name", Result[0].assigned_user_first_name);
                    formik.setFieldValue("assigned_user_full_name", Result[0].assigned_user_first_name+' '+Result[0].assigned_user_last_name);
                    formik.setFieldValue("assigned_users_id", Result[0].assigned_users_id);
                    formik.setFieldValue("assigned_email", Result[0].assigned_email);
                    setIsUserAssigned(true);
                }
                else
                {
                    setIsUserAssigned(false);
                }
                requestUserId = Result[0].referral_id;
                fetchUserDetailData();
            }
            else {
                navigate('/dashboard');
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };

    const onFileChanged = (event) => {
        // alert(event.files[0])
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];
            let fileSize = file.size;
            const imageSizeInMb = Math.round((fileSize / 1024));
            let fileName = event.target.files[0].name;
            let splitName = fileName.split('.');

            if (imageSizeInMb >= 2048) {
                mediaRef.current.value = '';
                Swal.fire({
                    title: '',
                    text: "Please upload the file with a size of not more than 2 MB.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })

                //Swal.fire('Please upload the file with a size of not more than 2 MB.');
            }
            else if (file.type !== 'application/pdf' && file.type !== 'application/msword'  && file.type !== 'application/wps-office.docx' && file.type !== 'application/wps-office.doc' && file.type !== 'application/ms-doc' && file.type !== 'application/doc' && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && splitName[1] !== 'bmp' && splitName[1] !== 'jpe' && splitName[1] !== 'jfif' && splitName[1] !== 'webp' && splitName[1] !== 'jpg' && splitName[1] !== 'jpeg' && splitName[1] !== 'png' && splitName[1] !== 'gif' && splitName[1] !== 'BMP' && splitName[1] !== 'JPE' && splitName[1] !== 'JFIF' && splitName[1] !== 'WEBP' && splitName[1] !== 'JPG' && splitName[1] !== 'JPEG' && splitName[1] !== 'PNG' && splitName[1] !== 'GIF')  {
                mediaRef.current.value = '';
                Swal.fire({
                    title: '',
                    html: file.name + " has an invalid extension.<br> Valid extension(s): bmp, gif, jpeg, jpg, jpe, jfif, png, webp, pdf, doc, docx.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })
                //Swal.fire('Please selects the file with valid extension i.e. *.pdf.');
            }
            else {
               // alert(event.target.files[0])
                formik.setFieldValue(
                    "attachment",
                    event.target.files[0]
                );
            }
        }

    };
    const handleAddNewDocument = () => {
        formik.setFieldValue("attachment", "");
        formik.setFieldValue("attachment_edit_db", "");
        formik.setFieldValue("attachment_email_template_db", "");
    }
    const handleDeleteDocuments = (rowId, mode, media) => {
        Swal.fire({
            title: 'Are you sure?',
            html: "You won't be able to reverse this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                handleDeleteDocumentsCall(rowId, mode, media);
            }
        })
    }
    const handleDeleteDocumentsCall = async (rowId, mode, media) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('email_template_id', rowId);
        formData.append('mode', 'single_email_send');
        formData.append('media', media);

        try {
            const response = await axios.post('/delete-email-attachment-docs', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                formik.setFieldValue("attachment", "");
                formik.setFieldValue("attachment_edit_db", "");
                formik.setFieldValue("attachment_email_template_db", "");
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    }
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleTemplateMessage = (event) => {
        if (event.htmlValue !== null) {
            formik.setFieldValue("message", event.htmlValue);
        }
        else {
            formik.setFieldValue("message", "");
        }
    }
    const handleEmailChange = (e) => {
        
        if (e.target.value.id) {
            let finalSubject = e.target.value.subject;
            let userFirstName = '';
            let userFullName = '';
            let referringUserFirstName = '';
            let referringUserFullName = '';
            let assignedUserFullName = '';
            let assignedUserFirstName = '';

            let userUplineFirstName = '';
            referringUserFirstName = formik.values.referring_user_first_name;
            referringUserFullName = formik.values.referring_user_full_name;
            if(formik.values.assigned_user_full_name)
                assignedUserFullName = formik.values.assigned_user_full_name;

            if(formik.values.assigned_user_first_name)
                assignedUserFirstName = formik.values.assigned_user_first_name;
           

            if(formik.values.type === 'referring_agent')
            {
                userFirstName = formik.values.referring_user_first_name;
                userFullName = formik.values.referring_user_full_name;
                if(formik.values.referring_upline_user_first_name)
                    userUplineFirstName = formik.values.referring_upline_user_first_name;
            }
            else if(formik.values.type === 'assigned_agent')
            {
                userFirstName = formik.values.assigned_user_first_name;
                userFullName = formik.values.assigned_user_full_name;
                if(formik.values.assigned_upline_user_first_name)
                    userUplineFirstName = formik.values.assigned_upline_user_first_name;
            }
            else
            {
                userFirstName = formik.values.assigned_user_first_name;
                userFullName = formik.values.assigned_user_full_name;

                if(formik.values.assigned_upline_user_first_name)
                    userUplineFirstName = formik.values.assigned_upline_user_first_name;
            }

            if (finalSubject.indexOf('%agent_first_name%') !== -1) {
                finalSubject = finalSubject.replaceAll("%agent_first_name%", userFirstName);
            }
            if (finalSubject.indexOf('%ambassador_first_name%') !== -1) {
                finalSubject = finalSubject.replaceAll("%ambassador_first_name%", userFirstName);
            }
            if (finalSubject.indexOf('%ambassador_first_name%') !== -1) {
                finalSubject = finalSubject.replaceAll("%ambassador_first_name%", userFirstName);
            }
            if (finalSubject.indexOf('%referring_agent_name%') !== -1) {
                finalSubject = finalSubject.replaceAll("%referring_agent_name%", referringUserFullName);
            }
            if (finalSubject.indexOf('%referring_agent_first_name%') !== -1) {
                finalSubject = finalSubject.replaceAll("%referring_agent_first_name%", referringUserFirstName);
            }
            if (finalSubject.indexOf('%assigned_agent_first_name%') !== -1) {
                if(assignedUserFirstName)
                    finalSubject = finalSubject.replaceAll("%assigned_agent_first_name%", assignedUserFirstName);
            }
            if(finalSubject.indexOf('%upline_first_name%') !== -1)
            {
                if(userUplineFirstName)
                    finalSubject = finalSubject.replaceAll("%upline_first_name%", userUplineFirstName);
            }
            if (finalSubject.indexOf('%assigned_agent%') !== -1) {
                if(assignedUserFullName)
                    finalSubject = finalSubject.replaceAll("%assigned_agent%", assignedUserFullName);
            }
            if (finalSubject.indexOf('%most_recent_previously_selected_agent_first_name%') !== -1) {
                if(assignedUserFirstName)
                finalSubject = finalSubject.replaceAll("%most_recent_previously_selected_agent_first_name%", assignedUserFirstName);
            }
            if (finalSubject.indexOf('%most_recent_previously_assigned_agent_first_name%') !== -1) {
                if(assignedUserFirstName)
                finalSubject = finalSubject.replaceAll("%most_recent_previously_assigned_agent_first_name%", assignedUserFirstName);
            }

            if (finalSubject.indexOf('%most_recent_previously_selected_agent%') !== -1) {
                if(assignedUserFullName)
                finalSubject = finalSubject.replaceAll("%most_recent_previously_selected_agent%", assignedUserFullName);
            }
            if (finalSubject.indexOf('%most_recent_previously_assigned_agent%') !== -1) {
                if(assignedUserFullName)
                finalSubject = finalSubject.replaceAll("%most_recent_previously_assigned_agent%", assignedUserFullName);
            }
            if (finalSubject.indexOf("%assigned_agent's_Broker%") !== -1) {
                finalSubject = finalSubject.replaceAll("%assigned_agent's_Broker%", userFullName);
            }
            if (finalSubject.indexOf('%agent%') !== -1) {
                finalSubject = finalSubject.replaceAll("%agent%", userFullName);
            }
            if (finalSubject.indexOf('%ambassador%') !== -1) {
                finalSubject = finalSubject.replaceAll("%ambassador%", userFullName);
            }
            if (finalSubject.indexOf('%user_name%') !== -1) {
                finalSubject = finalSubject.replaceAll("%user_name%", userFirstName);
            }
            
            if (finalSubject.indexOf('%agent_name%') !== -1) {
                finalSubject = finalSubject.replaceAll("%agent_name%", userFullName);
            }
            if (finalSubject.indexOf('%ambassador_name%') !== -1) {
                finalSubject = finalSubject.replaceAll("%ambassador_name%", userFullName);
            }
            if(finalSubject.indexOf('%downline_full_name%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%downline_full_name%", userFullName);
            }
            if (finalSubject.indexOf('%client_first_name%') !== -1) {
                finalSubject = finalSubject.replaceAll("%client_first_name%", formik.values.client_first_name);
            }
            if (finalSubject.indexOf('%client_name%') !== -1) {
                finalSubject = finalSubject.replaceAll("%client_name%", formik.values.client_full_name);
            }
            if (finalSubject.indexOf('%reference_number%') !== -1) {
                finalSubject = finalSubject.replaceAll("%reference_number%", formik.values.reference_number);
            }
            if (finalSubject.indexOf('%referral_status%') !== -1) {
                finalSubject = finalSubject.replaceAll("%referral_status%", formik.values.referral_status);
            }

            if (finalSubject.indexOf('%full_name_of_referral%') !== -1) {
                finalSubject = finalSubject.replaceAll("%full_name_of_referral%", formik.values.client_full_name);
            }
            if (finalSubject.indexOf('%transaction_address%') !== -1) {
                if(formik.values.client_address)
                    finalSubject = finalSubject.replaceAll("%transaction_address%", formik.values.client_address);
            }
            if (finalSubject.indexOf('%client_address%') !== -1) {
                if(formik.values.client_address)
                    finalSubject = finalSubject.replaceAll("%client_address%", formik.values.client_address);
            }
            if (finalSubject.indexOf('%assigned_agent_phone%') !== -1) {
                if(formik.values.assigned_agent_phone)
                    finalSubject = finalSubject.replaceAll("%assigned_agent_phone%", formik.values.assigned_agent_phone);
            }
            if (finalSubject.indexOf('%assigned_agent_email%') !== -1) {
                if(formik.values.assigned_email)
                    finalSubject = finalSubject.replaceAll("%assigned_agent_email%", formik.values.assigned_email);
            }
            if(finalSubject.indexOf('%refr_signup_page%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%refr_signup_page%", window.location.origin+'/signup');
            }

            if(finalSubject.indexOf('%refr_login_page%') !== -1)
            {
                finalSubject = finalSubject.replaceAll("%refr_login_page%", window.location.origin+'/login');
            }

            
            if (finalSubject.indexOf('%referring_agent_phone%') !== -1) {
                if(formik.values.referring_phone)
                    finalSubject = finalSubject.replaceAll("%referring_agent_phone%", formik.values.referring_phone);
            }
            if (finalSubject.indexOf('%referring_agent_email%') !== -1) {
                if(formik.values.referring_email)
                    finalSubject = finalSubject.replaceAll("%referring_agent_email%", formik.values.referring_email);
            }
            if (finalSubject.indexOf('%assigned_agent_email%') !== -1) {
                if(formik.values.assigned_email)
                    finalSubject = finalSubject.replaceAll("%assigned_agent_email%", formik.values.assigned_email);
            }
            if (finalSubject.indexOf('%other_interested_parties%') !== -1) {
                if(formik.values.other_interested_client)
                    finalSubject = finalSubject.replaceAll("%other_interested_parties%", formik.values.other_interested_client);
            }
            if (finalSubject.indexOf('%client_phone%') !== -1) {
                if(formik.values.client_phone)
                    finalSubject = finalSubject.replaceAll("%client_phone%", formik.values.client_phone);
            }
            if (finalSubject.indexOf('%client_email%') !== -1) {
                if(formik.values.client_email)
                    finalSubject = finalSubject.replaceAll("%client_email%", formik.values.client_email);
            }
            if (finalSubject.indexOf('%price_range%') !== -1) {
                if(formik.values.price_range)
                    finalSubject = finalSubject.replaceAll("%price_range%", formik.values.price_range);
            }
            if (finalSubject.indexOf('%comments%') !== -1) {
                if(formik.values.comments)
                    finalSubject = finalSubject.replaceAll("%comments%", formik.values.comments);
            }

            if (finalSubject.indexOf('%assigned_agent_brokerage_name%') !== -1) {
                if(formik.values.assigned_brokerage_name)
                    finalSubject = finalSubject.replaceAll("%assigned_agent_brokerage_name%", formik.values.assigned_brokerage_name);
            }
            if (finalSubject.indexOf('%assigned_agent_broker_of_record_name%') !== -1) {
                if(formik.values.assigned_broker_name)
                    finalSubject = finalSubject.replaceAll("%assigned_agent_broker_of_record_name%", formik.values.assigned_broker_name);
            }
            if (finalSubject.indexOf('%selected_agent_broker_of_record_first_name%') !== -1) {
                if(formik.values.assigned_broker_name)
                    finalSubject = finalSubject.replaceAll("%selected_agent_broker_of_record_first_name%", formik.values.assigned_broker_name);
            }
            if (finalSubject.indexOf('%assigned_agent_broker_of_record_license_#_state%') !== -1) {
                if(formik.values.assigned_broker_license_json)
                    finalSubject = finalSubject.replaceAll("%assigned_agent_broker_of_record_license_#_state%", formik.values.assigned_broker_license_json);
            }

            if (finalSubject.indexOf('%sale_price%') !== -1) {
                if(formik.values.sale_price)
                    finalSubject = finalSubject.replaceAll("%sale_price%", formik.values.sale_price);
            }
            if (finalSubject.indexOf('%commission_earned%') !== -1) {
                if(formik.values.commission_earned)
                    finalSubject = finalSubject.replaceAll("%commission_earned%", formik.values.commission_earned);
            }
            if (finalSubject.indexOf('%referral_fee_35percent%') !== -1) {
                if(formik.values.referral_fee_35percent)
                    finalSubject = finalSubject.replaceAll("%referral_fee_35percent%", formik.values.referral_fee_35percent);
            }

            if (finalSubject.indexOf('%REFR_referral_fee_percent%') !== -1) {
                if(formik.values.refr_referral_fee_percent)
                    finalSubject = finalSubject.replaceAll("%REFR_referral_fee_percent%", formik.values.refr_referral_fee_percent);
            }

            if (finalSubject.indexOf('%pending_brokerage_commission_due%') !== -1) {
                if(formik.values.pending_brokerage_commission_due)
                    finalSubject = finalSubject.replaceAll("%pending_brokerage_commission_due%", formik.values.pending_brokerage_commission_due);
            }
            if (finalSubject.indexOf('%closed_brokerage_commission_due%') !== -1) {
                if(formik.values.closed_brokerage_commission_due)
                    finalSubject = finalSubject.replaceAll("%closed_brokerage_commission_due%", formik.values.closed_brokerage_commission_due);
            }

            if (finalSubject.indexOf('%referral_fee_received%') !== -1) {
                if(formik.values.referral_fee_received)
                    finalSubject = finalSubject.replaceAll("%referral_fee_received%", formik.values.referral_fee_received);
            }
            
            if (finalSubject.indexOf('%referral_fee_due%') !== -1) {
                if(formik.values.referral_fee_35percent)
                    finalSubject = finalSubject.replaceAll("%referral_fee_due%", formik.values.referral_fee_35percent);
            }
            if (finalSubject.indexOf('%agent_commission_plan%') !== -1) {
                if(formik.values.agent_commission_plan)
                    finalSubject = finalSubject.replaceAll("%agent_commission_plan%", formik.values.agent_commission_plan);
            }
            if (finalSubject.indexOf('%agent_commission_plan_at_referral_submission%') !== -1) {
                if(formik.values.agent_commission_plan)
                    finalSubject = finalSubject.replaceAll("%agent_commission_plan_at_referral_submission%", formik.values.agent_commission_plan);
            }
            if (finalSubject.indexOf('%agent_commission_plan_current%') !== -1) {
                if(formik.values.agent_commission_plan_current)
                    finalSubject = finalSubject.replaceAll("%agent_commission_plan_current%", formik.values.agent_commission_plan_current);
            }
            if (finalSubject.indexOf('%refr_agent_commission%') !== -1) {
                if(formik.values.refr_agent_commission)
                    finalSubject = finalSubject.replaceAll("%refr_agent_commission%", formik.values.refr_agent_commission);
            }
            if (finalSubject.indexOf('%refr_5percent%') !== -1) {
                if(formik.values.refr_5percent)
                    finalSubject = finalSubject.replaceAll("%refr_5percent%", formik.values.refr_5percent);
            }
            if (finalSubject.indexOf('%brokerage_commission_30percent%') !== -1) {
                if(formik.values.brokerage_commission_30percent)
                    finalSubject = finalSubject.replaceAll("%brokerage_commission_30percent%", formik.values.brokerage_commission_30percent);
            }
            if (finalSubject.indexOf('%closing_date%') !== -1) {
                if(formik.values.closing_date)
                    finalSubject = finalSubject.replaceAll("%closing_date%", formik.values.closing_date);
            }
            if (finalSubject.indexOf('%property_address%') !== -1) {
                if(formik.values.property_address)
                    finalSubject = finalSubject.replaceAll("%property_address%", formik.values.property_address);
            }
            

            if (finalSubject.indexOf('%referring_user_upline_name%') !== -1) {
                if(formik.values.referring_user_upline_name)
                    finalSubject = finalSubject.replaceAll("%referring_user_upline_name%", formik.values.referring_user_upline_name);
            }
            if (finalSubject.indexOf('%referring_agent_brokerage_name%') !== -1) {
                if(formik.values.referring_broker_name)
                    finalSubject = finalSubject.replaceAll("%referring_agent_brokerage_name%", formik.values.referring_broker_name);
            }
            if (finalSubject.indexOf('%referring_user_upline_agent_brokerage_name%') !== -1) {
                if(formik.values.referring_user_upline_agent_brokerage_name)
                    finalSubject = finalSubject.replaceAll("%referring_user_upline_agent_brokerage_name%", formik.values.referring_user_upline_agent_brokerage_name);
            }
            if (finalSubject.indexOf('%brand_preference%') !== -1) {
                if(formik.values.brand_preference_data)
                    finalSubject = finalSubject.replaceAll("%brand_preference%", formik.values.brand_preference_data);
            }
            if (finalSubject.indexOf('%language%') !== -1) {
                if(formik.values.language_data)
                    finalSubject = finalSubject.replaceAll("%language%", formik.values.language_data);
            }
            if (finalSubject.indexOf('%other_requirement%') !== -1) {
                if(formik.values.other_requiremment_data)
                    finalSubject = finalSubject.replaceAll("%other_requirement%", formik.values.other_requiremment_data);
            }
            formik.setFieldValue("subject", finalSubject);

            let finalMessage = e.target.value.message;
            if (finalMessage.indexOf('%agent_first_name%') !== -1) {
                finalMessage = finalMessage.replaceAll("%agent_first_name%", userFirstName);
            }
            if (finalMessage.indexOf('%ambassador_first_name%') !== -1) {
                finalMessage = finalMessage.replaceAll("%ambassador_first_name%", userFirstName);
            }
            if(finalMessage.indexOf('%upline_first_name%') !== -1)
            {
                if(userUplineFirstName)
                    finalMessage = finalMessage.replaceAll("%upline_first_name%", userUplineFirstName);
            }
            if (finalMessage.indexOf('%assigned_agent%') !== -1) {
                if(assignedUserFullName)
                    finalMessage = finalMessage.replaceAll("%assigned_agent%", assignedUserFullName);
            }
            if (finalMessage.indexOf("%assigned_agent's_Broker%") !== -1) {
                finalMessage = finalMessage.replaceAll("%assigned_agent's_Broker%", userFullName);
            }
            if (finalMessage.indexOf('%agent_name%') !== -1) {
                finalMessage = finalMessage.replaceAll("%agent_name%", userFullName);
            }
            if (finalMessage.indexOf('%ambassador_name%') !== -1) {
                finalMessage = finalMessage.replaceAll("%ambassador_name%", userFullName);
            }
            if (finalMessage.indexOf('%agent%') !== -1) {
                finalMessage = finalMessage.replaceAll("%agent%", userFullName);
            }
            if (finalMessage.indexOf('%ambassador%') !== -1) {
                finalMessage = finalMessage.replaceAll("%ambassador%", userFullName);
            }
            if (finalMessage.indexOf('%user_name%') !== -1) {
                finalMessage = finalMessage.replaceAll("%user_name%", userFirstName);
            }
            if (finalMessage.indexOf('%referring_agent_name%') !== -1) {
                finalMessage = finalMessage.replaceAll("%referring_agent_name%", referringUserFullName);
            }
            if (finalMessage.indexOf('%referring_agent_first_name%') !== -1) {
                finalMessage = finalMessage.replaceAll("%referring_agent_first_name%", referringUserFirstName);
            }
            if (finalMessage.indexOf('%referral_status%') !== -1) {
                finalMessage = finalMessage.replaceAll("%referral_status%", formik.values.referral_status);
            }
            if (finalMessage.indexOf('%assigned_agent_first_name%') !== -1) {
                if(assignedUserFirstName)
                    finalMessage = finalMessage.replaceAll("%assigned_agent_first_name%", assignedUserFirstName);
            }
            if (finalMessage.indexOf('%most_recent_previously_selected_agent_first_name%') !== -1) {
                if(assignedUserFirstName)
                    finalMessage = finalMessage.replaceAll("%most_recent_previously_selected_agent_first_name%", assignedUserFirstName);
            }
            if (finalMessage.indexOf('%most_recent_previously_assigned_agent_first_name%') !== -1) {
                if(assignedUserFirstName)
                    finalMessage = finalMessage.replaceAll("%most_recent_previously_assigned_agent_first_name%", assignedUserFirstName);
            }

            if (finalMessage.indexOf('%most_recent_previously_selected_agent%') !== -1) {
                if(assignedUserFullName)
                    finalMessage = finalMessage.replaceAll("%most_recent_previously_selected_agent%", assignedUserFullName);
            }
            if (finalMessage.indexOf('%most_recent_previously_assigned_agent%') !== -1) {
                if(assignedUserFullName)
                    finalMessage = finalMessage.replaceAll("%most_recent_previously_assigned_agent%", assignedUserFullName);
            }
            
            if(finalMessage.indexOf('%downline_full_name%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%downline_full_name%", userFullName);
            }
            if (finalMessage.indexOf('%client_first_name%') !== -1) {
                finalMessage = finalMessage.replaceAll("%client_first_name%", formik.values.client_first_name);
            }
            if (finalMessage.indexOf('%client_name%') !== -1) {
                finalMessage = finalMessage.replaceAll("%client_name%", formik.values.client_full_name);
            }
            if (finalMessage.indexOf('%full_name_of_referral%') !== -1) {
                finalMessage = finalMessage.replaceAll("%full_name_of_referral%", formik.values.client_full_name);
            }
            if (finalMessage.indexOf('%transaction_address%') !== -1) {
                if(formik.values.client_address)
                    finalMessage = finalMessage.replaceAll("%transaction_address%", formik.values.client_address);
            }
            if (finalMessage.indexOf('%client_address%') !== -1) {
                if(formik.values.client_address)
                    finalMessage = finalMessage.replaceAll("%client_address%", formik.values.client_address);
            }
            if (finalMessage.indexOf('%reference_number%') !== -1) {
                finalMessage = finalMessage.replaceAll("%reference_number%", formik.values.reference_number);
            }
            if (finalMessage.indexOf('%assigned_agent_phone%') !== -1) {
                if(formik.values.assigned_agent_phone)
                    finalMessage = finalMessage.replaceAll("%assigned_agent_phone%", formik.values.assigned_agent_phone);
            }
            if (finalMessage.indexOf('%assigned_agent_email%') !== -1) {
                if(formik.values.assigned_email)
                    finalMessage = finalMessage.replaceAll("%assigned_agent_email%", formik.values.assigned_email);
            }
            if(finalMessage.indexOf('%refr_signup_page%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%refr_signup_page%", window.location.origin+'/signup');
            }

            if(finalMessage.indexOf('%refr_login_page%') !== -1)
            {
                finalMessage = finalMessage.replaceAll("%refr_login_page%", window.location.origin+'/login');
            }

            if (finalMessage.indexOf('%referring_agent_phone%') !== -1) {
                if(formik.values.referring_phone)
                finalMessage = finalMessage.replaceAll("%referring_agent_phone%", formik.values.referring_phone);
            }
            if (finalMessage.indexOf('%referring_agent_email%') !== -1) {
                if(formik.values.referring_email)
                finalMessage = finalMessage.replaceAll("%referring_agent_email%", formik.values.referring_email);
            }
            if (finalMessage.indexOf('%assigned_agent_email%') !== -1) {
                if(formik.values.assigned_email)
                finalMessage = finalMessage.replaceAll("%assigned_agent_email%", formik.values.assigned_email);
            }
            if (finalMessage.indexOf('%other_interested_parties%') !== -1) {
                if(formik.values.other_interested_client)
                finalMessage = finalMessage.replaceAll("%other_interested_parties%", formik.values.other_interested_client);
            }
            if (finalMessage.indexOf('%client_phone%') !== -1) {
                if(formik.values.client_phone)
                finalMessage = finalMessage.replaceAll("%client_phone%", formik.values.client_phone);
            }
            if (finalMessage.indexOf('%client_email%') !== -1) {
                if(formik.values.client_email)
                finalMessage = finalMessage.replaceAll("%client_email%", formik.values.client_email);
            }
            if (finalMessage.indexOf('%price_range%') !== -1) {
                if(formik.values.price_range)
                finalMessage = finalMessage.replaceAll("%price_range%", formik.values.price_range);
            }
            if (finalMessage.indexOf('%comments%') !== -1) {
                if(formik.values.comments)
                finalMessage = finalMessage.replaceAll("%comments%", formik.values.comments);
            }

            if (finalMessage.indexOf('%assigned_agent_brokerage_name%') !== -1) {
                if(formik.values.assigned_brokerage_name)
                finalMessage = finalMessage.replaceAll("%assigned_agent_brokerage_name%", formik.values.assigned_brokerage_name);
            }
            
            if (finalMessage.indexOf('%assigned_agent_broker_of_record_name%') !== -1) {
                if(formik.values.assigned_broker_name)
                finalMessage = finalMessage.replaceAll("%assigned_agent_broker_of_record_name%", formik.values.assigned_broker_name);
            }
            if (finalMessage.indexOf('%selected_agent_broker_of_record_first_name%') !== -1) {
                if(formik.values.assigned_broker_name)
                    finalMessage = finalMessage.replaceAll("%selected_agent_broker_of_record_first_name%", formik.values.assigned_broker_name);
            }
            if (finalMessage.indexOf('%assigned_agent_broker_of_record_license_#_state%') !== -1) {
                if(formik.values.assigned_broker_license_json)
                finalMessage = finalMessage.replaceAll("%assigned_agent_broker_of_record_license_#_state%", formik.values.assigned_broker_license_json);
            }

            if (finalMessage.indexOf('%sale_price%') !== -1) {
                if(formik.values.sale_price)
                finalMessage = finalMessage.replaceAll("%sale_price%", formik.values.sale_price);
            }
            if (finalMessage.indexOf('%commission_earned%') !== -1) {
                if(formik.values.commission_earned)
                finalMessage = finalMessage.replaceAll("%commission_earned%", formik.values.commission_earned);
            }
            if (finalMessage.indexOf('%referral_fee_35percent%') !== -1) {
                if(formik.values.referral_fee_35percent)
                finalMessage = finalMessage.replaceAll("%referral_fee_35percent%", formik.values.referral_fee_35percent);
            }
            if (finalMessage.indexOf('%referral_fee_due%') !== -1) {
                if(formik.values.referral_fee_35percent)
                finalMessage = finalMessage.replaceAll("%referral_fee_due%", formik.values.referral_fee_35percent);
            }
            if (finalMessage.indexOf('%pending_brokerage_commission_due%') !== -1) {
                if(formik.values.pending_brokerage_commission_due)
                finalMessage = finalMessage.replaceAll("%pending_brokerage_commission_due%", formik.values.pending_brokerage_commission_due);
            }
            if (finalMessage.indexOf('%closed_brokerage_commission_due%') !== -1) {
                if(formik.values.closed_brokerage_commission_due)
                finalMessage = finalMessage.replaceAll("%closed_brokerage_commission_due%", formik.values.closed_brokerage_commission_due);
            }
            if (finalMessage.indexOf('%REFR_referral_fee_percent%') !== -1) {
                if(formik.values.refr_referral_fee_percent)
                    finalMessage = finalMessage.replaceAll("%REFR_referral_fee_percent%", formik.values.refr_referral_fee_percent);
            }
            if (finalMessage.indexOf('%referral_fee_received%') !== -1) {
                if(formik.values.referral_fee_received)
                    finalMessage = finalMessage.replaceAll("%referral_fee_received%", formik.values.referral_fee_received);
            }
            if (finalMessage.indexOf('%agent_commission_plan%') !== -1) {
                if(formik.values.agent_commission_plan)
                finalMessage = finalMessage.replaceAll("%agent_commission_plan%", formik.values.agent_commission_plan);
            }
            if (finalMessage.indexOf('%agent_commission_plan_at_referral_submission%') !== -1) {
                if(formik.values.agent_commission_plan)
                finalMessage = finalMessage.replaceAll("%agent_commission_plan_at_referral_submission%", formik.values.agent_commission_plan);
            }
            if (finalMessage.indexOf('%agent_commission_plan_current%') !== -1) {
                if(formik.values.agent_commission_plan_current)
                finalMessage = finalMessage.replaceAll("%agent_commission_plan_current%", formik.values.agent_commission_plan_current);
            }
            if (finalMessage.indexOf('%refr_agent_commission%') !== -1) {
                if(formik.values.refr_agent_commission)
                finalMessage = finalMessage.replaceAll("%refr_agent_commission%", formik.values.refr_agent_commission);
            }
            if (finalMessage.indexOf('%refr_5percent%') !== -1) {
                if(formik.values.refr_5percent)
                finalMessage = finalMessage.replaceAll("%refr_5percent%", formik.values.refr_5percent);
            }
            if (finalMessage.indexOf('%brokerage_commission_30percent%') !== -1) {
                if(formik.values.brokerage_commission_30percent)
                finalMessage = finalMessage.replaceAll("%brokerage_commission_30percent%", formik.values.brokerage_commission_30percent);
            }
            if (finalMessage.indexOf('%closing_date%') !== -1) {
                if(formik.values.closing_date)
                finalMessage = finalMessage.replaceAll("%closing_date%", formik.values.closing_date);
            }
            if (finalMessage.indexOf('%property_address%') !== -1) {
                if(formik.values.property_address)
                    finalMessage = finalMessage.replaceAll("%property_address%", formik.values.property_address);
            }

            if (finalMessage.indexOf('%referring_user_upline_name%') !== -1) {
                if(formik.values.referring_user_upline_name)
                    finalMessage = finalMessage.replaceAll("%referring_user_upline_name%", formik.values.referring_user_upline_name);
            }
            if (finalMessage.indexOf('%referring_agent_brokerage_name%') !== -1) {
                if(formik.values.referring_broker_name)
                    finalMessage = finalMessage.replaceAll("%referring_agent_brokerage_name%", formik.values.referring_broker_name);
            }
            if (finalMessage.indexOf('%referring_user_upline_agent_brokerage_name%') !== -1) {
                if(formik.values.referring_user_upline_agent_brokerage_name)
                    finalMessage = finalMessage.replaceAll("%referring_user_upline_agent_brokerage_name%", formik.values.referring_user_upline_agent_brokerage_name);
            }
            if (finalMessage.indexOf('%brand_preference%') !== -1) {
                if(formik.values.brand_preference_data)
                    finalMessage = finalMessage.replaceAll("%brand_preference%", formik.values.brand_preference_data);
            }
            if (finalMessage.indexOf('%language%') !== -1) {
                if(formik.values.language_data)
                    finalMessage = finalMessage.replaceAll("%language%", formik.values.language_data);
            }
            if (finalMessage.indexOf('%other_requirement%') !== -1) {
                if(formik.values.other_requiremment_data)
                    finalMessage = finalMessage.replaceAll("%other_requirement%", formik.values.other_requiremment_data);
            }

            formik.setFieldValue("message", finalMessage);
            if(e.target.value.attachment)
            {
                formik.setFieldValue("attachment_email_template_db", e.target.value.attachment);
                formik.setFieldValue("attachment_edit_db", "");
            }
            else
            {
                formik.setFieldValue("attachment_email_template_db", "");
                formik.setFieldValue("attachment_edit_db", "");
            }
        }
    }
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        let formData = new FormData();
        let file = data.attachment;
        formData.append('file', file)
        formData.append('data', JSON.stringify(data))
        setLoading(true);
        try {
            const response = await axios.post('/add-admin-send-email-to-user', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                setLoading(false);
                const idEncryptedString = AES.encrypt(JSON.stringify(Result.admin_send_email_to_user_log_id), secretKey).toString();

                let finalUrl = 'u=' + encodeURIComponent(idEncryptedString);

                navigate('/app/preview-referral-user-email-template?' + finalUrl);
            }
        } catch (err) {

            setLoading(false);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                setErrorMessage("Something went wrong");

            }
        }
    };

    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }
    const optionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    }

    return (
        <>
            <section className='emailTemplate-component sec-pt sec-pb'>

                <div className="container">

                    <div className="email-container email_referral">
                        <h2 className='main-heading'>Email </h2>
                        {errorMessage && (
                            <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                {errorMessage}
                            </Alert>
                        )}
                        <div className="form-container">

                            <form onSubmit={formik.handleSubmit}>
                            {
                                                    (isUserAssigned) && (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="field radio_email_filed">
                                            <label htmlFor="to" className="form-label">User Type <span className="reuired-star">*</span></label>
                                            <div className="radio-btn-flex radio_btns">
                                                <div className="agent-field me-2 field-radiobutton">
                                                    <RadioButton
                                                        inputId="referring_agent"
                                                        onChange={() => {
                                                            handleTypeMode("referring_agent");
                                                        }}
                                                        name="type"
                                                        value="referring_agent"
                                                        checked={
                                                            formik.values.type ===
                                                            "referring_agent"
                                                        }
                                                    />
                                                    <label htmlFor="referring_agent">Referring Agent</label>
                                                </div>
                                                <div className="agent-field me-2 field-radiobutton">
                                                    <RadioButton
                                                        inputId="assigned_agent"
                                                        onChange={() => {
                                                            handleTypeMode("assigned_agent");
                                                        }}
                                                        name="type"
                                                        value="assigned_agent"
                                                        checked={
                                                            formik.values.type ===
                                                            "assigned_agent"
                                                        }
                                                    />
                                                    <label htmlFor="assigned_agent">
                                                        Assigned Agent
                                                    </label>
                                                </div>
                                                <div className="agent-field field-radiobutton">
                                                    <RadioButton
                                                        inputId="client_and_referring_assigned_agent"
                                                        onChange={() => {
                                                            handleTypeMode("client_and_referring_assigned_agent");
                                                        }}
                                                        name="type"
                                                        value="client_and_referring_assigned_agent"
                                                        checked={
                                                            formik.values.type ===
                                                            "client_and_referring_assigned_agent"
                                                        }
                                                    />
                                                    <label htmlFor="assigned_agent">
                                                    To: Client, Assigned Agent cc: Referring Agent
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="field">
                                            <label htmlFor="to" className="form-label">To <span className="reuired-star">*</span></label>
                                            <InputText type="text"
                                                autoComplete="off"
                                                placeholder="Enter here"
                                                aria-label="Text input with dropdown button"
                                                name="email"
                                                disabled={true}
                                                value={formik.values.email}
                                                className="form-control" />
                                                <br/><br/>
                                                {
                                                    (formik.values.type === "client_and_referring_assigned_agent") && (<InputText type="text"
                                                        autoComplete="off"
                                                        placeholder="Enter here"
                                                        aria-label="Text input with dropdown button"
                                                        name="email"
                                                        disabled={true}
                                                        value={formik.values.client_email}
                                                        className="form-control" />)
                                                }
                                            
                                        </div>
                                    </div>
                                </div>
                                {
                                    (formik.values.type === "client_and_referring_assigned_agent") && (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="field">
                                            <label htmlFor="to" className="form-label">CC <span className="reuired-star">*</span></label>
                                            <InputText type="text"
                                                autoComplete="off"
                                                placeholder="Enter here"
                                                aria-label="Text input with dropdown button"
                                                name="email"
                                                disabled={true}
                                                value={formik.values.ref_email}
                                                className="form-control" />
                                            
                                        </div>
                                    </div>
                                </div>)}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="field ">
                                            <label htmlFor="to" className="form-label">Email Template </label>
                                            <Dropdown
                                                name="email_template_id"
                                                value={
                                                    formik.values.email_template_id
                                                }
                                                options={
                                                    dbEmailTemplate
                                                }

                                                optionLabel="name"
                                                filter
                                                filterBy="name"
                                                className="form-select"
                                                placeholder="Select a Name"
                                                valueTemplate={
                                                    selectedTemplate
                                                }
                                                itemTemplate={
                                                    optionTemplate
                                                }
                                                onChange={(e) => { formik.handleChange(e); handleEmailChange(e) }}
                                            />

                                        </div>

                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="form-label">Email Subject <span className="reuired-star">*</span></label>
                                    <div className="p-inputgroup">
                                        <InputText type="text"
                                            autoComplete="off"
                                            placeholder="Enter here"
                                            aria-label="Text input with dropdown button"
                                            name="subject"
                                            value={formik.values.subject} onChange={formik.handleChange}
                                            className={classNames("form-control email-input", { 'p-invalid': isFormFieldValid('subject') })} />

                                    </div>
                                    {getFormErrorMessage('subject')}
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="form-label">Message <span className="reuired-star">*</span></label>
                                    
                                    <Editor placeholder="Enter Here" style={{ height: '199px' }} name="message"
                                        value={formik.values.message} onTextChange={(e) => { handleTemplateMessage(e) }} />
                                    {getFormErrorMessage('message')}
                                </div>
                                <div className="row">
                                {
                                    (!formik.values.attachment_email_template_db && !formik.values.attachment_edit_db) && (<div className="col-lg-6">
                                    <div className="field upload-file">
                                        <label htmlFor="UploadInput" className="form-label">Upload Attachment</label>
                                        <div className='custom-file d-flex'>
                                            <input type="file" ref={mediaRef} className="custom-file-input"
                                                onChange={onFileChanged}
                                                id="customFileLang" lang="in" />
                                            <label className="custom-file-label btn" for="customFileLang">Browse </label>
                                        </div>
                                        <div className='pdf-condi'>
                                            <span>Max Size 2MB</span>
                                        </div>
                                    </div>
                                </div> )
                                        }
                                    {
                                    (formik.values.attachment_email_template_db || formik.values.attachment_edit_db) && (<div className="col-lg-6">
                                    <div className="field upload-file email_upload_file">
                                    <label htmlFor="UploadInput" className="form-label">Upload Attachment</label>
                                        <a href={(formik.values.attachment_email_template_db) ? formik.values.attachment_email_template_db : formik.values.attachment_edit_db } target="_blank" className="btn outlined-btn preview-btn">Preview</a>
                                        
                                        {
                                            (formik.values.attachment_email_template_db) && (<a href='javascript:;' className="btn outlined-btn" onClick={()=>{handleAddNewDocument()}}>Delete</a>)
                                        }
                                        {
                                            (formik.values.attachment_edit_db) && (<a href='javascript:;' className="btn outlined-btn" onClick={() => { handleDeleteDocuments(formik.values.edit_id,'attachment', formik.values.attachment_edit_db) }}>Delete</a>)
                                        }
                                            
                                        </div>
                                    </div>)
                                    }
                                </div>
                                <div className="field text-center email-btns email-preview-btn">
                                    <button className="btn btn-cancel me-3" type="button" disabled={loading} onClick={(e) => navigate('/app/referral-list-for-admin')}>Cancel</button>
                                    <button className="btn btn-submit btn-submit-loader" type="submit" disabled={loading}>Preview Email
                                        {loading && (<Typography align="center">
                                            <CircularProgress value={66} />
                                        </Typography>)}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="email-template-letter">
                            <img src="assets/images/Open-Email.png" alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EmailTemplate