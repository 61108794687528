import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { InputTextarea } from 'primereact';
import Button from 'react-bootstrap/Button';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import Swal from 'sweetalert2';

const ClientCancelledStatusForm = (props) => {

    const { user, logout } = useAuth();
    const [show, setShow] = useState(props.showClientCancelledStatusForm);
    const navigate = useNavigate();
    const closeModal = () => setShow(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitCall, setIsSubmitCall] = useState(false);
    
    
    const formik = useFormik({
        initialValues: {
            referral_id: props.referralRowId,
            client_cancelled_reason: (props.callFrom === 'client_cancelled') ? props.clientCancelledReasonDB : props.noLongerWorkingWithClientReasonDB,
            call_from: props.callFrom,
            login_user_id: props.loginUserId,
            login_user_name: props.loginUserName,
            host_name: window.location.origin,
        },
        validate: (data) => {
            let errors = {};
            if (!data.client_cancelled_reason.trim()) {
                if(props.callFrom === 'client_cancelled')
                    errors.client_cancelled_reason = 'Please provide a reason for client cancellation.';
                else
                    errors.client_cancelled_reason = 'Please provide why are you no longer working with this client';
            }
            else if (data.client_cancelled_reason.length > 500)
            {
                errors.client_cancelled_reason = 'Max characters: 500';
            }
            else if (data.client_cancelled_reason.length < 10)
            {
                errors.client_cancelled_reason = 'Min characters: 10';
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    
    useEffect(() => {
        if (user !== null) {
            
            formik.setFieldValue("users_id", user.id ? user.id : "");
        }
    }, [user]);
    
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
   
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        try {
            const response = await axios.post('/cancelled-status-reason-update', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {
                setLoading(false);
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        closeModal();
                    props.changeStatuClientCancelledForm();
                    }
                });
            }
        } catch (err) {

            setLoading(false);
            window.scrollTo(0, 0);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {

                setErrorMessage("Something went wrong");
            }
        }
    };
    
    
    return (
        <>
            {/* <Button variant="primary" onClick={inviteShow}>
                Invite Modal
            </Button> */}
            <Modal enforceFocus={false} className='invite-user-popup' show={show} onHide={closeModal} data-keyboard="false" backdrop="static">

                <Modal.Header className='invite-user-modal'>
                   
                    <Modal.Title>{
                        (props.callFrom === 'client_cancelled') ? 'Client Cancelled Information' : 'No Longer Working with Client Information'
                    }</Modal.Title>
                </Modal.Header>
                {errorMessage && (
                    <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                        {errorMessage}
                    </Alert>
                )}
                <form onSubmit={formik.handleSubmit} >
                    <Modal.Body className='invite-user-section'>
                    <div className="referral-fields step-3">
                            
                    <div className="invite-user-body">
                    <div className="row ">
                            <div className="field col-md-12">
                                <label htmlFor="inputEmail4" className="form-label">{
                        (props.callFrom === 'client_cancelled') ? 'Please provide a reason for client cancellation ' : 'Why are you no longer working with this client? '
                    } <span className="reuired-star">*</span></label>
                                <InputTextarea
                                rows={5} cols={30}
                                    
                                        autoComplete="off"
                                        placeholder="Enter here"
                                        name="client_cancelled_reason"
                                        value={
                                            formik
                                                .values
                                                .client_cancelled_reason
                                        }
                                        onChange={
                                            formik.handleChange
                                        }
                                        className={classNames(
                                            "form-control",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "client_cancelled_reason"
                                                    ),
                                            }
                                        )}
                                    />
                        
                                {getFormErrorMessage('client_cancelled_reason')}
                            </div>
                            </div>
                            </div>
                            
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='invite-user-footer'>
                        <Button className='cancel-btn' variant="secondary" onClick={() => { closeModal(); props.changeStatuClientCancelledForm() }}>
                            Cancel
                        </Button>
                        <Button type="submit" className='btn btn-submit btn-500 btn-submit-loader' onClick={() => {setIsSubmitCall(true)}} variant="primary" disabled={loading}>
                        Save

                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                        </Button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ClientCancelledStatusForm;