import axios from "axios";

export const googleAPIKey = 'AIzaSyAHih3137r_dACl0M_Zh6xBy-0D597ZSnk';
export const stripePublicKey = 'pk_live_51LozqUALrBkhhtEcKOHhnFLAinHtfyhacTVlPLHwEoFlpVO18X7YvArd8YhZkkZpRtyGMypI0Az1eLXg9aBtQbee00LiLXE8XJ';
//export const baseUrl = 'http://127.0.0.1:8000/api/';
//export const baseUrl = 'https://refr.galaxyweblinks.com/api/';
export const baseUrl = 'https://api.refrnow.com/api/';

//const axiosInstance = axios.create({baseURL: 'http://127.0.0.1:8000/api/'});
//const axiosInstance = axios.create({baseURL: 'https://refr.galaxyweblinks.com/api/'});
const axiosInstance = axios.create({baseURL: 'https://api.refrnow.com/api/'});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

