import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { InputText } from 'primereact';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "../../hooks/useAuth";
import Swal from 'sweetalert2';
import { InputMask, RadioButton, Avatar } from 'primereact';
import { Dropdown } from 'primereact/dropdown';

const AssignedAgent = (props) => {
    const [show, setShow] = useState(props.showAssignedAgent);
    const navigate = useNavigate();
    const assignedAgentModalClose = () => setShow(true);
    const { user, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [isAmbassadorDetailShow, setIsAmbassadorDetailShow] = useState(false);
    const [ambassadorShortName, setAmbassadorShortName] = useState("DD");
    const [ambassadorFullName, setAmbassadorFullName] = useState("Dravid Doe");
    const [dbAmbassadorData, setDbAmbassadorData] = useState([]);
    const [headingText, setHeadingText] = useState('Select an Agent');
    const [submitButtonText, setSubmitButtonText] = useState('Select');

    const formik = useFormik({
        initialValues: {
            type: 'existing_ambassador',
            call_from: props.selectAgentCallFrom,
            users_id: '',
            referral_id: props.referralRowId,
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            brokerage_name: '',
            broker_name: '',
            broker_email: '',
            broker_phone: '',
            existing_ambassador_id: '',
            address: "",
            host_name: window.location.origin,
        },
        validate: (data) => {
            let errors = {};
            ///alert(data.type)
            if (data.type === 'new_ambassador') {
                if (data.first_name == null || data.first_name.trim() === '') {
                    errors.first_name = 'First name is required.';
                }

                if (data.first_name == null || data.first_name.trim() === '') {
                    errors.last_name = 'Last name is required.';
                }

                if (!data.email) {
                    errors.email = 'Email is required.';
                }
                else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                    
                    errors.email = 'Invalid email address. e.g. example@email.com';
                } 

                if (data.broker_email) {
                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.broker_email)) {
                        errors.broker_email = 'Invalid email address. e.g. example@email.com';
                    }
                }

                if (!data.phone) {
                    errors.phone = 'Phone is required.';
                }
            }
            else {
                if (!data.existing_ambassador_id) {
                    errors.existing_ambassador_id = "Ambassador is required.";
                }
            }

            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    useEffect(() => {
        if(props.selectAgentCallFrom === 'select_agent')
        {
            setHeadingText('Select an Agent');
            setSubmitButtonText('Select');
        }
        else if(props.selectAgentCallFrom === 'change_agent')
        {
            setHeadingText('Change an Agent');
            setSubmitButtonText('Change');
        }
        else
        {
            setHeadingText('Assign an Agent');
            setSubmitButtonText('Assign');
        }
        if (user !== null) {
            formik.setFieldValue("users_id", user.id ? user.id : "");
        }
        const fetchAmbassadorData = async () => {
            let formData = new FormData();
            formData.append('users_id', user.id);
            formData.append('referral_id', props.referralRowId);
            //formData.append('call_from', 'assigned_agent');
            try {
                const response = await axios.post("/get-all-ambassador", formData);
                const { Status, Result } =
                    response.data;

                if (Status === true) {
                    //alert(Result)
                    setDbAmbassadorData(Result);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchAmbassadorData();
    }, [user]);


    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.first_name + ' ' + option.last_name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };
    
    const optionTemplate = (option) => {
        return (
            <div className="country-item user-name-list">
                <p className='full-name-user'>{option.first_name + ' ' + option.last_name}</p>
                <p className='email-option'>{option.email}</p>
            </div>
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        try {
            const response = await axios.post('/add-assigned-agent', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                setLoading(false);
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        assignedAgentModalClose();
                        props.changeStatuAssignedAgent();
                    }
                });
            }
        } catch (err) {
            setLoading(false);
            
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } 

        }
    };

    const handleTypeMode = (mode) => {
        formik.setFieldValue("type", mode);

        setIsAmbassadorDetailShow(false);
        formik.setFieldValue("first_name", "");
        formik.setFieldValue("last_name", "");
        formik.setFieldValue("email", "");
        formik.setFieldValue("phone", "");
        formik.setFieldValue("address", "");
        formik.setFieldValue("brokerage_name", "");        
        formik.setFieldValue("existing_ambassador_id", "");

        setAmbassadorShortName("DD");
        setAmbassadorFullName("Dravid Doe");
    };
    const handleAmbassador = (e) => {
        if (e.target.value) {
            let data = e.target.value;
            let str = data.first_name.trim() + " " + data.last_name.trim();
            let matches = str.match(/\b(\w)/g);
            let acronym = matches.join("");
            setAmbassadorShortName(acronym);
            setAmbassadorFullName(str);

            formik.setFieldValue("first_name", data.first_name);
            formik.setFieldValue("last_name", data.last_name);
            formik.setFieldValue("email", data.email);
            formik.setFieldValue("phone", data.contact_number);
            formik.setFieldValue("address", data.business_address);
            setIsAmbassadorDetailShow(true);
        } else {
            setIsAmbassadorDetailShow(false);
            formik.setFieldValue("first_name", "");
            formik.setFieldValue("last_name", "");
            formik.setFieldValue("email", "");
            formik.setFieldValue("phone", "");
            formik.setFieldValue("address", "");
            setAmbassadorShortName("DD");
            setAmbassadorFullName("Dravid Doe");
        }
        formik.setFieldValue('existing_ambassador_id', e.target.value)
        formik.setFieldError('existing_ambassador_id', "")
    };
    
    return (
        <>
            <Modal enforceFocus={false} className='add-people-popup' show={show} onHide={assignedAgentModalClose} data-keyboard="false" backdrop="static" >
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header className='add-people-header'>
                        <Modal.Title>{headingText}</Modal.Title>
                    </Modal.Header>
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                            {errorMessage}
                        </Alert>
                    )}
                    <Modal.Body className='addpeople-section assign-agent-popup'>
                        {
                            (dbAmbassadorData.length > 0) && (props.selectAgentCallFrom === 'select_agent' || props.selectAgentCallFrom === 'change_agent') && (<div className="gx-3 form-section">
                                <div className="radio-btn-flex">
                                    <div className="agent-field me-3 field-radiobutton">
                                        <RadioButton
                                            inputId="new_ambassador"
                                            onChange={() => {
                                                handleTypeMode("new_ambassador");
                                            }}
                                            name="type"
                                            value="new_ambassador"
                                            checked={
                                                formik.values.type ===
                                                "new_ambassador"
                                            }
                                        />
                                        <label htmlFor="new_ambassador">Add New</label>
                                    </div>
                                    <div className="agent-field field-radiobutton">
                                        <RadioButton
                                            inputId="existing_ambassador"
                                            onChange={() => {
                                                handleTypeMode("existing_ambassador");
                                            }}
                                            name="type"
                                            value="existing_ambassador"
                                            checked={
                                                formik.values.type ===
                                                "existing_ambassador"
                                            }
                                        />
                                        <label htmlFor="existing_ambassador">
                                            Select from Existing Ambassador
                                        </label>
                                    </div>
                                </div>
                            </div>)
                        } 
                        {
                            (formik.values.type === "new_ambassador") && (<div className="row gx-3 form-section">
                                <div className="field col-md-6 profile-input" >
                                    <label htmlFor="inputEmail4" className="form-label">First Name <span className="reuired-star">*</span></label>
                                    <InputText placeholder="Enter Here"
                                        autoComplete="off"
                                        name="first_name"
                                        value={formik.values.first_name} onChange={formik.handleChange}
                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('first_name') })}
                                    />
                                    {getFormErrorMessage('first_name')}
                                </div>
                                <div className="field col-md-6 profile-input right">
                                    <label htmlFor="inputPassword4" className="form-label">Last Name <span className="reuired-star">*</span></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder="Enter Here" name="last_name"
                                        value={formik.values.last_name} onChange={formik.handleChange}
                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('last_name') })}
                                    />
                                    {getFormErrorMessage('last_name')}
                                </div>
                                <div className="field col-md-6 profile-input">
                                    <label htmlFor="inputEmail4" className="form-label">Email Address <span className="reuired-star">*</span></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder="Enter Here" name="email"
                                        value={formik.values.email} onChange={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('email') })}
                                    />
                                    {getFormErrorMessage('email')}
                                </div>
                                <div className="field col-md-6 profile-input right">
                                    <label htmlFor="inputPassword4" className="form-label">Phone <span className="reuired-star">*</span></label>
                                    <InputMask
                                        autoComplete="off"
                                        mask="(999) 999-9999" placeholder="(999) 999-9999" name="phone"
                                        value={formik.values.phone} onChange={formik.handleChange}
                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('phone') })}
                                    />
                                    {getFormErrorMessage('phone')}
                                </div>
                                <div className="field col-md-6 street-input">
                                    <label htmlFor="inputAddress" className="form-label">Brokerage Name </label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder="Enter Here" name="brokerage_name"
                                        value={formik.values.brokerage_name} onChange={formik.handleChange}
                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('brokerage_name') })}
                                    />
                                    {getFormErrorMessage('brokerage_name')}
                                </div>
                                <div className="field col-md-6 street-input">
                                    <label htmlFor="inputAddress" className="form-label">Broker of Record Name </label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder="Enter Here" name="broker_name"
                                        value={formik.values.broker_name} onChange={formik.handleChange}
                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('broker_name') })}
                                    />
                                    {getFormErrorMessage('broker_name')}
                                </div>
                                <div className="field col-md-6 street-input">
                                    <label htmlFor="inputAddress" className="form-label">Broker of Record Email </label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder="Enter Here" name="broker_email"
                                        value={formik.values.broker_email} onChange={formik.handleChange}
                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('broker_email') })}
                                    />
                                    {getFormErrorMessage('broker_email')}
                                </div>
                                <div className="field col-md-6 street-input">
                                    <label htmlFor="inputPassword4" className="form-label">Broker of Record Phone </label>
                                    <InputMask
                                        autoComplete="off"
                                        mask="(999) 999-9999" placeholder="(999) 999-9999" name="broker_phone"
                                        value={formik.values.broker_phone} onChange={formik.handleChange}
                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('broker_phone') })}
                                    />
                                    {getFormErrorMessage('broker_phone')}
                                </div>
                            </div>)
                        }
                        {formik.values.type === "existing_ambassador" && (
                            <div className="row gx-3 form-section">
                                <div className="col-md-12">
                                    <div className="field referral-bar">
                                        <span className="p-input-icon-right">

                                            <Dropdown
                                                className={classNames(
                                                    "form-select",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "existing_ambassador_id"
                                                            ),
                                                    }
                                                )}
                                                value={
                                                    formik.values
                                                        .existing_ambassador_id
                                                }
                                                options={dbAmbassadorData}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    handleAmbassador(e);
                                                }}
                                                // onChange={formik.handleChange}
                                                optionLabel="name"
                                                name="existing_client_id"
                                                filter
                                                filterBy="name,email"
                                                placeholder="Select one"
                                                valueTemplate={selectedTemplate}
                                                itemTemplate={optionTemplate}
                                            />
                                            {getFormErrorMessage(
                                                "existing_ambassador_id"
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isAmbassadorDetailShow && (
                            <div className="contact-list gx-3 form-section">
                                <div className="contact-details">
                                    <div className="name-dp ref-name-dp">
                                        <Avatar>{ambassadorShortName}</Avatar>
                                    </div>
                                    <div className="contact-info">
                                        <p className="title">
                                            {ambassadorFullName}
                                        </p>
                                        <ul>
                                            <li className="user_proname_li">
                                                <img
                                                    src="../assets/images/email_i.png"
                                                    alt=""
                                                />
                                                <a
                                                    href={`mailto:${formik.values.email}`}
                                                >
                                                    {formik.values.email
                                                        ? formik.values
                                                            .email
                                                        : "dravid@yopmail.com"}
                                                </a>
                                            </li>
                                            <li className="user_proname_li">
                                                <img
                                                    src="../assets/images/phone.png"
                                                    alt=""
                                                />
                                                <a
                                                    href={`tel:${formik.values.phone}`}
                                                >
                                                    {formik.values.phone
                                                        ? formik.values
                                                            .phone
                                                        : "(222) 222-2222"}
                                                </a>
                                            </li>
                                        </ul>
                                        <p className="contact-address">
                                            <img
                                                src="../assets/images/location.png"
                                                alt=""
                                            />
                                            {formik.values.address
                                                ? formik.values.address
                                                : " 2972 Westheimer Rd. Santa Ana,  Illinois 85486"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer className='add-people-footer'>
                        <button type="button" className='btn btn-cancel btn-500' variant="secondary" onClick={() => { assignedAgentModalClose(); props.changeStatuAssignedAgent(); }} disabled={loading}>
                            Close
                        </button>
                        <button type="submit" className='btn btn-submit btn-500 btn-submit-loader' variant="primary" disabled={loading}>
                        {submitButtonText}
                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                        </button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default AssignedAgent;