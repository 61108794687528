import React, { useState, useEffect } from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import { MultiSelect,InputText  } from 'primereact';

import { Alert, CircularProgress, Typography } from '@mui/material';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import Swal from 'sweetalert2';
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

const PreviewEmailForMassEmail = () => {
    const secretKey = "REFR2022@GALAXY";
    const search = useLocation().search;
    const massEmailLogId = new URLSearchParams(search).get('u');
    let requestId = '';
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [email, setEmail] = useState([]);
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [fromType, setFromType] = useState('');
    const [adminFromEmail, setAdminFromEmail] = useState('');
    const [toAmbassadorsId, setToAmbassadorsId] = useState([]);
    const [toPeoplesCount, setToPeoplesCount] = useState('');
    const [toAgentsId, setToAgentsId] = useState([]);

    if (massEmailLogId === null) {
        navigate('/dashboard');
    } else {
        
        let idDecrypt = AES.decrypt(massEmailLogId, secretKey);
        if (idDecrypt == "") {
            //console.log('if')
            navigate('/dashboard');
        }
        else {
            //console.log('else')
            requestId = JSON.parse(idDecrypt.toString(Utf8));
        }
    }
    const { user, logout } = useAuth();
    useEffect(() => {
        
        if (user !== null) {
            let formData = new FormData();
            formData.append('mass_email_log_id', requestId);

            const fetchAdminSendEmailLogData = async () => {
                try {
                    const response = await axios.post("/get-mass-email-log-data", formData);
                    const { Status, StatusMessage, Error, Result } = response.data;

                    if (Status === true) {
                        setUserId(Result[0].users_id);
                        if(Result[0].to_ambassadors_id)
                            setToAmbassadorsId(Result[0].to_ambassadors_id);

                        if(Result[0].to_agents_id)
                            setToAgentsId(Result[0].to_agents_id);
                        if(Result[0].people_count)
                            setToPeoplesCount(Result[0].people_count);

                        if(Result[0].from_type === 'admin')
                            setAdminFromEmail(Result[0].from_email);
                        else
                            setEmail(Result[0].from_email);
                        
                        setFromType(Result[0].from_type);

                        setSubject(Result[0].subject);
                        if(Result[0].message)
                        {
                            let message = Result[0].message.replace(/<br>/g,'');
                            setMessage(message);
                        }
                    }
                    else {
                        navigate('/dashboard');
                    }
                } catch (err) {
                    if (err.response.status && err.response.status == 401) {
                        logout();
                        navigate('/login');
                    } else {
                        setErrorMessage('Something went wrong');
                    }
                }
            };
            fetchAdminSendEmailLogData();
        }
    }, [user, requestId]);

    const handleGoBackEdit = () => {
        
        const idEncryptedString = AES.encrypt(JSON.stringify(requestId), secretKey).toString();
        let finalUrl = 'e=' + encodeURIComponent(idEncryptedString);

        navigate('/app/mass-email?' + finalUrl);
    }
    const sendEmailToUser = async() => 
    {
        let formData = new FormData();
        formData.append('mass_email_log_id', requestId);
        setLoading(true);
        try {
            const response = await axios.post("/mass-email-send-to-user", formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                window.scrollTo(0, 0);
                setErrorMessage(finalApiError);
            } else {
                navigate('/app/email-template-list');
            }
            
        } catch (err) {
            setLoading(false);
            window.scrollTo(0, 0);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                setErrorMessage('Something went wrong');
            }
        }
    }
    return (
        <>
            <section className='emailTemplate-component sec-pt sec-pb'>
                <div className="container">
                    <div className="email-container">
                        <h2 className='main-heading'>Email Template</h2>
                        {errorMessage && (
                            <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                {errorMessage}
                            </Alert>
                        )}
                        <div className="form-container">
                            <form>
                                <div className="email-preview">
                                    <p className='title'>From :  {adminFromEmail}</p>
                                    {
                                        (fromType === 'agent') && (email.length > 0) && (
                                        <div className="row">
                                            {
                                                (email.length > 0) && (<div className="col-lg-6">
                                                <div className="field">
                                                    <label htmlFor="to" className="form-label">Agents</label>
                                                    <MultiSelect
                                                    value={email}
                                                    optionLabel="name"
                                                    options={email}
                                                    maxSelectedLabels={2}
                                                    className="form-select"
                                                />
                                                    
                                                </div>
                                            </div>)
                                            }
                                        </div>
                                    )}
                                    <p className='title'>To : </p>
                                    <div className="row">
                                        {
                                            (toAmbassadorsId.length > 0) && (<div className="col-lg-6">
                                            <div className="field">
                                                <label htmlFor="to" className="form-label">Ambassador</label>
                                                <MultiSelect
                                                value={toAmbassadorsId}
                                                optionLabel="name"
                                                options={toAmbassadorsId}
                                                maxSelectedLabels={2}
                                                className="form-select"
                                                
                                                 />
                                                
                                            </div>
                                        </div>)
                                        }
                                    
                                        
                                        {
                                            (toAgentsId.length > 0) && (<div className="col-lg-6">
                                            <div className="field">
                                                <label htmlFor="to" className="form-label">Agent</label>
                                                <MultiSelect
                                                value={toAgentsId}
                                                optionLabel="name"
                                                options={toAgentsId}
                                                maxSelectedLabels={2}
                                                className="form-select"
                                                
                                                 />
                                                
                                            </div>
                                        </div>)
                                        }
                                        {
                                            (toPeoplesCount > 0) && (<div className="col-lg-6">
                                            <div className="field">
                                                <label htmlFor="to" className="form-label">REFR Agent’s Sphere</label>
                                                <InputText
                                                    disabled={true}
                                                    placeholder="Enter here"
                                                    value={
                                                        (toPeoplesCount > 1) ? 
                                                        "REFR Agent’s Sphere " + "("+toPeoplesCount+" spheres)" : 
                                                        "REFR Agent’s Sphere " + "("+toPeoplesCount+" sphere)"
                                                    }
                                                    className="form-control"
                                                />
                                                
                                            </div>
                                        </div>)
                                        }
                                    
                                </div>
                                    <p className='title'><span>Subject</span> :  {subject}</p>
                                    <div className='hr-line'>
                                        <hr />
                                    </div>
                                    <div className='body-text'>
                                    <div dangerouslySetInnerHTML={{__html: message }}></div>
                                    </div>
                                    {/* <div className="footer-content">
                                        <p></p>

                                        <p className='regards'>Best Regards</p>
                                        <p className='name'>REFR</p>
                                    </div> */}
                                </div>
                                <div className="field text-center email-btns preview-btns email-preview-btn">
                                    <button className="btn btn-cancel me-3" type="button" onClick={(e) => navigate('/app/email-template-list')} disabled={loading}>Cancel</button>
                                    <button className="btn btn-cancel me-3" type="button" onClick={()=>handleGoBackEdit()} disabled={loading}>Go Back and Edit</button>
                                    <button className="btn btn-submit btn-submit-loader" type="button" onClick={sendEmailToUser}disabled={loading}>Send email {loading && (<Typography align="center">
                                            <CircularProgress value={66} />
                                        </Typography>)}</button>
                                </div>
                            </form>
                        </div>
                        <div className="email-template-letter">
                            <img src="assets/images/Open-Email.png" alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PreviewEmailForMassEmail