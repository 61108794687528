import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { InputText } from 'primereact';
import { Checkbox } from 'primereact';
import Button from 'react-bootstrap/Button';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "../../hooks/useAuth";
import Swal from 'sweetalert2';
import AES from "crypto-js/aes";

const NotificationModal = (props) => {
    const secretKey = "REFR2022@GALAXY";
    const { user, logout } = useAuth();
    const [show, setShow] = useState(props.showNotificationModal);
    const navigate = useNavigate();
    const modalClose = () => setShow(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dbNotification, setDbNotification] = useState([]);
    const [loadingInHeader, setLoadingInHeader] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);

    let formData = new FormData();
    formData.append('user_id', user.id);
    
    const fetchNotificationData = async () => {
        setLoadingInHeader(true);
        try {
            const response = await axios.post("/get-notification", formData);
            const { Status, Result } = response.data;
            setLoadingInHeader(false);
            if (Status === true) {

                setDbNotification(Result);
                setNotificationCount(Result.length)
            }
            else {
                //navigate('/dashboard');
            }
        } catch (err) {
            setLoadingInHeader(false);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } 
        }
    };
    
    useEffect(() => {
        
    }, [notificationCount]);
    useEffect(() => {
        if (user !== null) {
            
            fetchNotificationData();
        }
    }, [user]);

    const handleNotificationDelete = async(event,rowId,mode) => {
        //alert(rowId)
        if(mode === '')
        {
            event.stopPropagation();
        }
        let formData = new FormData();
        formData.append('notification_id', rowId);
        formData.append('mode', mode);
        formData.append('users_id', user.id);
        
        try {
            const response = await axios.post("/delete-notification", formData);
            const { Status, StatusMessage, Error } = response.data;
            
            let finalApiError = '';
            if (Status === false) {
                if (Error.length > 0) {
                  Error.map(
                    (errorMessage) =>
                      //finalApiError += '<li>'+errorMessage+'</li>'
                      (finalApiError = errorMessage)
                  );
                } else {
                  finalApiError = StatusMessage;
                }
                Swal.fire({
                    title: '',
                    text: finalApiError,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    event.stopPropagation();
                    fetchNotificationData();
                    
                })
              }
              else
              {
                if(mode === 'clear_all')
                    {
                        Swal.fire({
                            title: 'Success',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            text: "All notifications clear successfully.",
                          }).then((result) => {
                            if (result.isConfirmed) {
                                setNotificationCount(0)
                                modalClose(); 
                                props.changeNotificationModalStatus()
                            }
                          });
                    }
                    else
                    {
                        event.stopPropagation();
                        fetchNotificationData();
                    }
              }
           
        } catch (err) {
            
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                
            }
        }
    };
    const handleRedirectOfNotification = (page, pageId) => {
        //alert('page '+page+' pageId '+pageId)

        if (page === 'referral') {
            if(pageId)
            {
                const userIdEncryptedString = AES.encrypt(JSON.stringify(pageId), secretKey).toString();

                let finalUrl = 'u=' + encodeURIComponent(userIdEncryptedString);
                navigate('/app/referral-detail?' + finalUrl);
                props.closeNav();
                modalClose(); 
                props.changeNotificationModalStatus()
            }
            
        }
        else if (page === 'profile_page') {
            navigate('/app/edit-profile');
            props.closeNav();
            modalClose(); 
            props.changeNotificationModalStatus()
        }
        else if (page === 'network_detail') {
            //alert(1);
            const userIdEncryptedString = AES.encrypt(JSON.stringify(pageId), secretKey).toString();

            const modeEncryptedString = AES.encrypt(JSON.stringify('ambassador'), secretKey).toString();

            let finalUrl = 'u=' + encodeURIComponent(userIdEncryptedString) + '&m=' + encodeURIComponent(modeEncryptedString);

            navigate('/app/my-network-detail?' + finalUrl);
            props.closeNav();
            modalClose(); 
            props.changeNotificationModalStatus()
        }
    }
    return (
        <>
            <Modal className='people-popup notification-popup' show={show} onHide={modalClose} centered backdrop="static">

                <Modal.Header className='people-header'>
                    <p className='people-title'>Notification ({notificationCount})</p>
                    {
                        (notificationCount > 0) && (<NavLink onClick={(e) => handleNotificationDelete(e,'','clear_all')}className="clear-noti">Clear All</NavLink>)
                    }
                    
                </Modal.Header>
                {errorMessage && (
                    <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                        {errorMessage}
                    </Alert>
                )}

                <Modal.Body className='addpeople-section'>
                    {/* <div className="container">
                        <div className="list-group list-group-light">
                            {
                                (dbNotification.length > 0) && dbNotification.map(notification => <button className="list-group-item list-group-item-action px-3 border-0 " key={notification.id}
                                >{notification.message}</button>)
                            }
                        </div>
                    </div> */}
                    <div className="people-data">
                    {loadingInHeader && (<Typography align="center">
                            <CircularProgress value={66} />
                        </Typography>)}
                    {
                        (dbNotification.length > 0) && dbNotification.map((notification, index) =>
                        <div className='people-column' key={notification.id}>
                            <div className='noti-left'>
                                <div className={`MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root email-dp-name ${(index%2 ==0) ? "even" : "odd"}`}>EP</div>
                                <div className="people-info">
                                    <div className='noti-title'>
                                        <h2 className='member-name'><a href="javascript:;" onClick={() => { handleRedirectOfNotification(notification.redirect_page, notification.redirect_page_id) }}>{notification.notification_sent_user_name}</a> <span>{notification.created}</span></h2>
                                        <h3 className='member-email'>{notification.message}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='view-icon'>
                                <a href="javascript:;" onClick={(e) => handleNotificationDelete(e,notification.id,'')} ><i className="pi pi-times"></i></a>
                            </div>
                        </div>
                        )
                    } 
                    {
                        (dbNotification.length === 0) && (!loadingInHeader) && (
                            <div className="people-column justify-content-center" >
                                <p className='mb-0'>No records found</p>
                            </div>
                            )
                    }
                    </div>
                </Modal.Body>
                <Modal.Footer className='invite-user-footer'>
                        <Button className='btn-cancel' variant="secondary" onClick={() => { modalClose(); props.changeNotificationModalStatus() }}>
                            Close
                        </Button>
                    </Modal.Footer>
            </Modal>
        </>
    )
}

export default NotificationModal;