import React, {  useState,useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';
import { createRoot } from 'react-dom/client'
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';
// import { CheckBox } from '@mui/icons-material';
import { InputText } from 'primereact';
import axios, { baseUrl } from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import AES from 'crypto-js/aes';
import Swal from 'sweetalert2';

import { Alert } from '@mui/material';

const ReferralListing = () => {
    const { user,logout } = useAuth();
    const secretKey = 'REFR2022@GALAXY';
    const navigate = useNavigate();
    let filter = '';
    let isFirstTimeList = 1;
    let isPageLoad = 1;
    const [showClearSearch, setShowClearSearch] = useState(false);
    
    const [currentFilter, setCurrentFilter] = useState('in_process_referral');
    

    const [errorMessage, setErrorMessage] = useState('');
    filter = 'in_process_referral';
    useEffect(() => {
        if (user !== null) {
            
            $.fn.dataTable.ext.errMode = 'none';

            fetchInProcessData();
            fetchClosedData();
            fetchUnCompletedData();
            fetchAwaitingPaymentData();
        }
        
    }, [user]);
    const handleNetworkDetail = (event,rowId,mode) => {
        //alert(rowId+' '+mode)
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();

        const modeEncryptedString = AES.encrypt(JSON.stringify(mode), secretKey).toString();

        let finalUrl = 'u='+encodeURIComponent(userIdEncryptedString)+'&m='+encodeURIComponent(modeEncryptedString);
        //alert(finalUrl);
        navigate('/app/my-network-detail?'+finalUrl);
    }
    const handleEmail = (rowId) => {
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();
        //alert(rowId)
        let finalUrl = 'u=' + encodeURIComponent(userIdEncryptedString);
        navigate('/app/referral-user-email-template?' + finalUrl);
    }
    const handleView = (e, rowId) => {
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();

        let finalUrl = 'u='+encodeURIComponent(userIdEncryptedString);
        navigate('/app/referral-detail?'+finalUrl);
    }
    const handleDelete = async (event, rowId,mode) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to reverse this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                callDelete(rowId,mode)
            }
        })
    }

    const callDelete = async (rowId,mode) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('id', rowId);

        try {
            const response = await axios.post('/delete-referral', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                if(mode === 'in_process_referral')
                    $(".inProcessTabClass").first().trigger('click');
                else if(mode === 'closed_referral')
                    $(".closedTabClass").first().trigger('click');
                else if(mode === 'uncompleted_referral')
                    $(".unCompletedTabClass").first().trigger('click');
                else if(mode === 'awaiting_payment_referral')
                    $(".awaitingPaymentTabClass").first().trigger('click');
            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }
        }
    }
    
    const handleClearSearch = () => {

        $('.searchbarTable').val('');
        if (currentFilter === 'in_process_referral') {
            $(".inProcessTabClass").first().trigger('click');
        }
        else  if (currentFilter === 'closed_referral') {
            $(".closedTabClass").first().trigger('click');
        }
        else  if (currentFilter === 'uncompleted_referral') {
            $(".unCompletedTabClass").first().trigger('click');
        }
        else  if (currentFilter === 'awaiting_payment_referral') {
            $(".awaitingPaymentTabClass").first().trigger('click');
        }
        setShowClearSearch(false);
    }
    
    function fetchInProcessData() {
        const accessToken = window.localStorage.getItem("accessToken");
        const table = $(".inProcessTable").on('preXhr.dt', function ( e, settings, data ) {
            if (settings.jqXHR) settings.jqXHR.abort();
        }).DataTable({
            language: {
                filter_tab: "",
                search: "",
                searchPlaceholder: "Search by name, email or phone",
                emptyTable: "No records found"
            },
            dom: '<"top"f>rt<"bottom pBottom"lip><"clear">',
           // order: [[6, 'asc']],
            responsive: true,
            // scrollX: true,
            destroy: true,
            processing: true,
            //"ordering": false,
            lengthMenu: [
                [10, 20, 25, 30, 50, 100, 200, 500],
                [10, 20, 25, 30, 50, 100, 200, 500],
            ],
            serverSide: true,
            serverMethod: "POST",
            ajax: {
                url: baseUrl + "list-referral",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: function (data) {
                    data.user_id = user.id;
                    data.search_value = $(".searchbarTable").val();
                    data.filter = filter;
                    data.call_from = 'admin_referral_listing';
                    data.first_time_call = isFirstTimeList;
                },
                error: function (jqXHR,error, errorThrown) {

                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{

                    }
                }
            },
            drawCallback: function (settings) {
                var response = settings.json;
                isPageLoad = 0;
                $("#spanInProcessCount").html('(' + response.inProcessReferralCount + ')');
                $("#spanClosedCount").html('(' + response.closedReferralCount + ')');
                $("#spanUncompletedCount").html('(' + response.uncompletedReferralCount + ')');
                $("#spanAwaitingPaymentCount").html('(' + response.awaitingPaymentReferralCount + ')');

                let totalCount =  parseInt(response.inProcessReferralCount) + parseInt(response.closedReferralCount) + parseInt(response.uncompletedReferralCount) + parseInt(response.awaitingPaymentReferralCount);
                
                $("#spanReferralCount").html('(' + totalCount + ')');

                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.pBottom').hide();
                else
                    $('.pBottom').show();
            },
            columnDefs: [
                {
                    targets: 0,
                    width: "10.00%",
                    title: "Client",
                    data: "client_name",
                    name: "client_name",
                    searchable: true,
                    visible: true,
                    orderable: true,
                    orderData: [0],
                    orderDataType: "asc",
                    className: "my_amb referral-t bold-td desktop tablet-p tablet-l mobile-p mobile-l",
                    createdCell:"",
                    render: "",
                },
                {
                    targets: 1,
                    width: "15.00%",
                    title: "<span class='sorting-arrow'>Referring </br>Agent</span>",
                    data: "referring_user_full_name",
                    name: "referring_user_full_name",
                    searchable: true,
                    visible: true,
                    orderable: true,
                    orderData: [1],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.referring_user_full_name !== 'N/A')
                        {
                            createRoot(td).render(<ul className='title-check'>
                            <li><span className='name-title'> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e,`${rowData.users_id}`,'ambassador')} className='name-link underline-ani-blue'>{rowData.referring_user_full_name}</a></span></li>
                                </ul>
                            );
                        }
                        else
                        {
                            createRoot(td).render(<ul className='title-check'><li><span className='name-title'>N/A</span></li></ul>
                            );
                        }
                  },
                    render: "",
                },
                {
                    targets: 2,
                    width: "12.00%",
                    title: "<span class='sorting-arrow'>Assigned </br>Agent</span>",
                    data: "assigned_agent",
                    name: "assigned_agent",
                    searchable: true,
                    visible: true,
                    orderable: true,
                    orderData: [2],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.assigned_agent !== 'N/A')
                        {
                            createRoot(td).render(<ul className='title-check'>
                            <li><span className='name-title'> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e,`${rowData.agent_id}`,'ambassador')} className='name-link underline-ani-blue'>{rowData.assigned_agent}</a></span></li>
                                </ul>
                            );
                        }
                        else
                        {
                            createRoot(td).render(<ul className='title-check'><li><span className='name-title'>N/A</span></li></ul>
                            );
                        }
                  },
                    render: "",
                },
                {
                    targets: 3,
                    width: "10.00%",
                    title: "<span class='sorting-arrow'>Referral </br>Type</span>",
                    data: "buyer_or_seller",
                    name: "buyer_or_seller",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [3],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t desktop referral-th",
                    createdCell: "",
                    render: function (data, type,row) {
                        if(data)
                        {
                            if(data === 'buyer_referral')
                                data = 'Buy';
                            if(data === 'seller_referral')
                                data = 'Sell';
                        }
                        return '<span>' + data + '</span>';
                    },
                },
                {
                    targets: 4,
                    width: "5.00%",
                    title: "<span class='sorting-arrow'>Reference</br>Number</span>",
                    data: "reference_number",
                    name: "reference_number",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [4],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t tablet-p desktop tablet-l referral-th",
                    createdCell: "",
                    render: "",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.reference_number)
                        {
                            if(rowData.is_downline_record) {
                                createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title name-link'> {rowData.reference_number}</span></li>
                                    </ul>
                                );
                            } else {
                                createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'> <a href="javascript:;" onClick={(e) => handleView(e,`${rowData.id}`)} className='name-link underline-ani-blue'>{rowData.reference_number}</a></span></li>
                                    </ul>
                                );
                            }
                        }
                        else
                        {
                            createRoot(td).render(<ul className='title-check'><li><span className='name-title'>N/A</span></li></ul>
                            );
                        }
                  },
                },
                
                {
                    targets: 5,
                    width: "11.00%",
                    title: "<span class='sorting-arrow'>Potential </br>/Pending </br>Referral Fee</span>",
                    data: "potential_referral_fee_db",
                    name: "potential_referral_fee_db",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [5],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    createdCell: "",
                    render: "",
                },
                
                {
                    targets: 6,
                    width: "10.00%",
                    title: "<span class='sorting-arrow'>Created Date</span>",
                    data: "created",
                    name: "created",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [6],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    createdCell: "",
                    render: "",
                },
                {
                    targets: 7,
                    width: "10.00%",
                    title: "Status",
                    data: "status",
                    name: "status",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [7],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    createdCell: "",
                    render: "",
                },
                {
                    targets: 8,
                    width: "15.00%",
                    title: "Action",
                    data: "",
                    visible: true,
                    searchable: false,
                    orderable: false,
                    className: "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                    "createdCell": (td, cellData, rowData, row, col) => {

                        if(rowData.is_downline_record === 0) {
                            createRoot(td).render(<div class="dropdown referral-option">
                            <a class="dropdown-toggle" href="javascript:void(0)" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="../assets/images/option-icon.png" alt="" />
                            </a>

                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a class="dropdown-item" href="javascript:;" onClick={(e) => handleView(e, `${rowData.id}`)}>View</a></li>

                                { (user.type === 'admin')  && (
                                <li><a class="dropdown-item" href="javascript:;" onClick={(e) => handleEmail(`${rowData.id}`)}>Email</a></li>)
                                }

                            { (((rowData.users_id === user.id) && (!rowData.agent_id)) || (user.type === 'admin'))  && (<>
                            <li><a class="dropdown-item"  onClick={(e) => handleDelete(e, `${rowData.id}`,'in_process_referral')} href="javascript:;">Delete</a></li></>)
                                }

                            </ul>
                        </div>
                            );
                        }
                    },
                    render: "",
                },
            ],
        });
        $(".searchbarTable").keyup(function () {
            if ((filter === 'in_process_referral') && (($(this).val().length >= 3) || ($(this).val().length === 0)))
            {
                setCurrentFilter('in_process_referral');
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else if (filter === 'in_process_referral')
            {
                setShowClearSearch(false);
            }
        });
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

            
       
       
        $(".inProcessTable").on('click', 'th', function () {
            $(".inProcessTable thead th").each(function (i, th) {
                
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');

                    
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });
        
        $(".inProcessTable th").first().click().click();
        $('.inProcessTabClass').click(function () {
            filter = 'in_process_referral';
            isFirstTimeList = 1;
            isPageLoad = 1;
            table.clear();
            table.draw();
        })
        var headers = $(".inProcessTable").DataTable().columns().header().toArray();

        $(headers).on('click', function () {
            if(isPageLoad == 0) {
                
                isFirstTimeList = 0;
            }
        });
        
    }
    function fetchAwaitingPaymentData() {
        const accessToken = window.localStorage.getItem("accessToken");
        const table = $(".awaitingPaymentTable").on('preXhr.dt', function ( e, settings, data ) {
            if (settings.jqXHR) settings.jqXHR.abort();
        }).DataTable({
            language: {
                filter_tab: "",
                search: "",
                searchPlaceholder: "Search by name, email or phone",
                emptyTable: "No records found"
            },
            dom: '<"top"f>rt<"bottom pBottom"lip><"clear">',
            order: [[6, 'asc']],
            responsive: true,
            // scrollX: true,
            destroy: true,
            processing: true,
            //"ordering": false,
            lengthMenu: [
                [10, 20, 25, 30, 50, 100, 200, 500],
                [10, 20, 25, 30, 50, 100, 200, 500],
            ],
            serverSide: true,
            serverMethod: "POST",
            ajax: {
                url: baseUrl + "list-referral",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: function (data) {
                    data.user_id = user.id;
                    data.search_value = $(".searchbarTable").val();
                    data.filter = filter;
                    data.call_from = 'admin_referral_listing';
                    data.first_time_call = isFirstTimeList;
                },
                error: function (jqXHR,error, errorThrown) {

                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{

                    }
                }
            },
            drawCallback: function (settings) {
                // Here the response
                var response = settings.json;
                isPageLoad = 0;
                $("#spanInProcessCount").html('(' + response.inProcessReferralCount + ')');
                $("#spanClosedCount").html('(' + response.closedReferralCount + ')');
                $("#spanUncompletedCount").html('(' + response.uncompletedReferralCount + ')');
                $("#spanAwaitingPaymentCount").html('(' + response.awaitingPaymentReferralCount + ')');

                let totalCount =  parseInt(response.inProcessReferralCount) + parseInt(response.closedReferralCount) + parseInt(response.uncompletedReferralCount) + parseInt(response.awaitingPaymentReferralCount);
                
                $("#spanReferralCount").html('(' + totalCount + ')');

                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.pBottom').hide();
                else
                    $('.pBottom').show();
            },
            columnDefs: [
                {
                    targets: 0,
                    width: "10.00%",
                    title: "Client",
                    data: "client_name",
                    name: "client_name",
                    searchable: true,
                    visible: true,
                    orderable: true,
                    orderData: [0],
                    orderDataType: "asc",
                    className: "my_amb referral-t bold-td desktop tablet-p tablet-l mobile-p mobile-l",
                    createdCell:"",
                    render: "",
                },
                {
                    targets: 1,
                    width: "15.00%",
                    title: "<span class='sorting-arrow'>Referring </br>Agent</span>",
                    data: "referring_user_full_name",
                    name: "referring_user_full_name",
                    searchable: true,
                    visible: true,
                    orderable: true,
                    orderData: [1],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.referring_user_full_name !== 'N/A')
                        {
                            createRoot(td).render(<ul className='title-check'>
                            <li><span className='name-title'> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e,`${rowData.users_id}`,'ambassador')} className='name-link underline-ani-blue'>{rowData.referring_user_full_name}</a></span></li>
                                </ul>
                            );
                        }
                        else
                        {
                            createRoot(td).render(<ul className='title-check'><li><span className='name-title'>N/A</span></li></ul>
                            );
                        }
                  },
                    render: "",
                },
                {
                    targets: 2,
                    width: "12.00%",
                    title: "<span class='sorting-arrow'>Assigned </br>Agent</span>",
                    data: "assigned_agent",
                    name: "assigned_agent",
                    searchable: true,
                    visible: true,
                    orderable: true,
                    orderData: [2],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.assigned_agent !== 'N/A')
                        {
                            createRoot(td).render(<ul className='title-check'>
                            <li><span className='name-title'> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e,`${rowData.agent_id}`,'ambassador')} className='name-link underline-ani-blue'>{rowData.assigned_agent}</a></span></li>
                                </ul>
                            );
                        }
                        else
                        {
                            createRoot(td).render(<ul className='title-check'><li><span className='name-title'>N/A</span></li></ul>
                            );
                        }
                  },
                    render: "",
                },
                {
                    targets: 3,
                    width: "10.00%",
                    title: "<span class='sorting-arrow'>Referral </br>Type</span>",
                    data: "buyer_or_seller",
                    name: "buyer_or_seller",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [3],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t desktop referral-th",
                    createdCell: "",
                    render: function (data, type,row) {
                        if(data)
                        {
                            if(data === 'buyer_referral')
                                data = 'Buy';
                            if(data === 'seller_referral')
                                data = 'Sell';
                        }
                        return '<span>' + data + '</span>';
                    },
                },
                {
                    targets: 4,
                    width: "5.00%",
                    title: "<span class='sorting-arrow'>Reference</br>Number</span>",
                    data: "reference_number",
                    name: "reference_number",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [4],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t tablet-p desktop tablet-l referral-th",
                    createdCell: "",
                    render: "",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.reference_number)
                        {
                            if(rowData.is_downline_record) {
                                createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title name-link'> {rowData.reference_number}</span></li>
                                    </ul>
                                );
                            } else {
                                createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'> <a href="javascript:;" onClick={(e) => handleView(e,`${rowData.id}`)} className='name-link underline-ani-blue'>{rowData.reference_number}</a></span></li>
                                    </ul>
                                );
                            }
                        }
                        else
                        {
                            createRoot(td).render(<ul className='title-check'><li><span className='name-title'>N/A</span></li></ul>
                            );
                        }
                  },
                },
                
                {
                    targets: 5,
                    width: "11.00%",
                    title: "<span class='sorting-arrow'>Potential </br>/Pending </br>Referral Fee</span>",
                    data: "potential_referral_fee_db",
                    name: "potential_referral_fee_db",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [5],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    createdCell: "",
                    render: "",
                },
                
                {
                    targets: 6,
                    width: "10.00%",
                    title: "<span class='sorting-arrow'>Created Date</span>",
                    data: "created",
                    name: "created",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [6],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    createdCell: "",
                    render: "",
                },
                {
                    targets: 7,
                    width: "10.00%",
                    title: "Status",
                    data: "status",
                    name: "status",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [7],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    createdCell: "",
                    render: "",
                },
                {
                    targets: 8,
                    width: "15.00%",
                    title: "Action",
                    data: "",
                    visible: true,
                    searchable: false,
                    orderable: false,
                    className: "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.is_downline_record === 0) {
                            createRoot(td).render(<div class="dropdown referral-option">
                            <a class="dropdown-toggle" href="javascript:void(0)" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="../assets/images/option-icon.png" alt="" />
                            </a>

                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a class="dropdown-item" href="javascript:;" onClick={(e) => handleView(e, `${rowData.id}`)}>View</a></li>

                                { (user.type === 'admin')  && (
                                <li><a class="dropdown-item" href="javascript:;" onClick={(e) => handleEmail(`${rowData.id}`)}>Email</a></li>)
                                }

                            { (((rowData.users_id === user.id) && (!rowData.agent_id)) || (user.type === 'admin'))  && (<>
                            <li><a class="dropdown-item"  onClick={(e) => handleDelete(e, `${rowData.id}`,'in_process_referral')} href="javascript:;">Delete</a></li></>)
                                }

                            </ul>
                        </div>
                            );
                        }
                    },
                    render: "",
                },
            ],
        });
        $(".searchbarTable").keyup(function () {
            if ((filter === 'awaiting_payment_referral') && (($(this).val().length >= 3) || ($(this).val().length === 0)))
            {
                setCurrentFilter('awaiting_payment_referral');
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else if (filter === 'awaiting_payment_referral')
            {
                setShowClearSearch(false);
            }
        });
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

            
            
        $(".awaitingPaymentTable").on('click', 'th', function () {
            $(".awaitingPaymentTable thead th").each(function (i, th) {
                
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');

                   
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });
        
        $(".awaitingPaymentTable th").first().click().click();
        $('.awaitingPaymentTabClass').click(function () {
            filter = 'awaiting_payment_referral';
            isFirstTimeList = 1;
            isPageLoad = 1;
            table.clear();
            table.draw();
        })

        var headers = $(".awaitingPaymentTable").DataTable().columns().header().toArray();

        $(headers).on('click', function () {
            if(isPageLoad == 0) {
                
                isFirstTimeList = 0;
            }
        });
    }
    function fetchUnCompletedData() {
        const accessToken = window.localStorage.getItem("accessToken");
        const table = $(".unCompletedTable").on('preXhr.dt', function ( e, settings, data ) {
            if (settings.jqXHR) settings.jqXHR.abort();
        }).DataTable({
            language: {
                filter_tab: "",
                search: "",
                searchPlaceholder: "Search by name, email or phone",
                emptyTable: "No records found"
            },
            dom: '<"top"f>rt<"bottom pBottom"lip><"clear">',
            order: [[6, 'asc']],
            responsive: true,
            // scrollX: true,
            destroy: true,
            processing: true,
            //"ordering": false,
            lengthMenu: [
                [10, 20, 25, 30, 50, 100, 200, 500],
                [10, 20, 25, 30, 50, 100, 200, 500],
            ],
            serverSide: true,
            serverMethod: "POST",
            ajax: {
                url: baseUrl + "list-referral",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: function (data) {
                    data.user_id = user.id;
                    data.search_value = $(".searchbarTable").val();
                    data.filter = filter;
                    data.call_from = 'admin_referral_listing';
                    data.first_time_call = isFirstTimeList;
                },
                error: function (jqXHR,error, errorThrown) {

                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{

                    }
                }
            },
            drawCallback: function (settings) {
                // Here the response
                var response = settings.json;
                isPageLoad = 0;
                $("#spanInProcessCount").html('(' + response.inProcessReferralCount + ')');
                $("#spanClosedCount").html('(' + response.closedReferralCount + ')');
                $("#spanUncompletedCount").html('(' + response.uncompletedReferralCount + ')');
                $("#spanAwaitingPaymentCount").html('(' + response.awaitingPaymentReferralCount + ')');

                let totalCount =  parseInt(response.inProcessReferralCount) + parseInt(response.closedReferralCount) + parseInt(response.uncompletedReferralCount) + parseInt(response.awaitingPaymentReferralCount);
                
                $("#spanReferralCount").html('(' + totalCount + ')');

                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.pBottom').hide();
                else
                    $('.pBottom').show();
            },
            columnDefs: [
                {
                    targets: 0,
                    width: "10.00%",
                    title: "Client",
                    data: "client_name",
                    name: "client_name",
                    searchable: true,
                    visible: true,
                    orderable: true,
                    orderData: [0],
                    orderDataType: "asc",
                    className: "my_amb referral-t bold-td desktop tablet-p tablet-l mobile-p mobile-l",
                    createdCell:"",
                    render: "",
                },
                {
                    targets: 1,
                    width: "15.00%",
                    title: "<span class='sorting-arrow'>Referring </br>Agent</span>",
                    data: "referring_user_full_name",
                    name: "referring_user_full_name",
                    searchable: true,
                    visible: true,
                    orderable: true,
                    orderData: [1],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.referring_user_full_name !== 'N/A')
                        {
                            createRoot(td).render(<ul className='title-check'>
                            <li><span className='name-title'> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e,`${rowData.users_id}`,'ambassador')} className='name-link underline-ani-blue'>{rowData.referring_user_full_name}</a></span></li>
                                </ul>
                            );
                        }
                        else
                        {
                            createRoot(td).render(<ul className='title-check'><li><span className='name-title'>N/A</span></li></ul>
                            );
                        }
                  },
                    render: "",
                },
                {
                    targets: 2,
                    width: "12.00%",
                    title: "<span class='sorting-arrow'>Assigned </br>Agent</span>",
                    data: "assigned_agent",
                    name: "assigned_agent",
                    searchable: true,
                    visible: true,
                    orderable: true,
                    orderData: [2],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.assigned_agent !== 'N/A')
                        {
                            createRoot(td).render(<ul className='title-check'>
                            <li><span className='name-title'> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e,`${rowData.agent_id}`,'ambassador')} className='name-link underline-ani-blue'>{rowData.assigned_agent}</a></span></li>
                                </ul>
                            );
                        }
                        else
                        {
                            createRoot(td).render(<ul className='title-check'><li><span className='name-title'>N/A</span></li></ul>
                            );
                        }
                  },
                    render: "",
                },
                {
                    targets: 3,
                    width: "10.00%",
                    title: "<span class='sorting-arrow'>Referral </br>Type</span>",
                    data: "buyer_or_seller",
                    name: "buyer_or_seller",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [3],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t desktop referral-th",
                    createdCell: "",
                    render: function (data, type,row) {
                        if(data)
                        {
                            if(data === 'buyer_referral')
                                data = 'Buy';
                            if(data === 'seller_referral')
                                data = 'Sell';
                        }
                        return '<span>' + data + '</span>';
                    },
                },
                {
                    targets: 4,
                    width: "5.00%",
                    title: "<span class='sorting-arrow'>Reference</br>Number</span>",
                    data: "reference_number",
                    name: "reference_number",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [4],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t tablet-p desktop tablet-l referral-th",
                    createdCell: "",
                    render: "",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.reference_number)
                        {
                            if(rowData.is_downline_record) {
                                createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title name-link'> {rowData.reference_number}</span></li>
                                    </ul>
                                );
                            } else {
                                createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'> <a href="javascript:;" onClick={(e) => handleView(e,`${rowData.id}`)} className='name-link underline-ani-blue'>{rowData.reference_number}</a></span></li>
                                    </ul>
                                );
                            }
                        }
                        else
                        {
                            createRoot(td).render(<ul className='title-check'><li><span className='name-title'>N/A</span></li></ul>
                            );
                        }
                  },
                },
                
                {
                    targets: 5,
                    width: "11.00%",
                    title: "<span class='sorting-arrow'>Potential </br>/Pending </br>Referral Fee</span>",
                    data: "potential_referral_fee_db",
                    name: "potential_referral_fee_db",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [5],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    createdCell: "",
                    render: "",
                },
                
                {
                    targets: 6,
                    width: "10.00%",
                    title: "<span class='sorting-arrow'>Created Date</span>",
                    data: "created",
                    name: "created",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [6],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    createdCell: "",
                    render: "",
                },
                {
                    targets: 7,
                    width: "10.00%",
                    title: "Status",
                    data: "status",
                    name: "status",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [7],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    createdCell: "",
                    render: "",
                },
                {
                    targets: 8,
                    width: "15.00%",
                    title: "Action",
                    data: "",
                    visible: true,
                    searchable: false,
                    orderable: false,
                    className: "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.is_downline_record === 0) {
                            createRoot(td).render(<div class="dropdown referral-option">
                            <a class="dropdown-toggle" href="javascript:void(0)" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="../assets/images/option-icon.png" alt="" />
                            </a>

                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a class="dropdown-item" href="javascript:;" onClick={(e) => handleView(e, `${rowData.id}`)}>View</a></li>

                                { (user.type === 'admin')  && (
                                <li><a class="dropdown-item" href="javascript:;" onClick={(e) => handleEmail(`${rowData.id}`)}>Email</a></li>)
                                }

                            { (((rowData.users_id === user.id) && (!rowData.agent_id)) || (user.type === 'admin'))  && (<>
                            <li><a class="dropdown-item"  onClick={(e) => handleDelete(e, `${rowData.id}`,'in_process_referral')} href="javascript:;">Delete</a></li></>)
                                }

                            </ul>
                        </div>
                            );
                        }
                    },
                    render: "",
                },
            ],
        });
        $(".searchbarTable").keyup(function () {
            if ((filter === 'uncompleted_referral') && (($(this).val().length >= 3) || ($(this).val().length === 0)))
            {
                setCurrentFilter('uncompleted_referral');
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else if (filter === 'uncompleted_referral')
            {
                setShowClearSearch(false);
            }
        });
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

            
            
        $(".unCompletedTable").on('click', 'th', function () {
            $(".unCompletedTable thead th").each(function (i, th) {
                
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');

                    
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });
        
        $(".unCompletedTable th").first().click().click();
        $('.unCompletedTabClass').click(function () {
            filter = 'uncompleted_referral';
            isFirstTimeList = 1;
            isPageLoad = 1;
            table.clear();
            table.draw();
        })

        var headers = $(".unCompletedTable").DataTable().columns().header().toArray();

        $(headers).on('click', function () {
            if(isPageLoad == 0) {
                
                isFirstTimeList = 0;
            }
        });
    }
    function fetchClosedData() {
        const accessToken = window.localStorage.getItem("accessToken");
        const table = $(".closedTable").on('preXhr.dt', function ( e, settings, data ) {
            if (settings.jqXHR) settings.jqXHR.abort();
        }).DataTable({
            language: {
                filter_tab: "",
                search: "",
                searchPlaceholder: "Search by name, email or phone",
                emptyTable: "No records found"
            },
            dom: '<"top"f>rt<"bottom pBottom"lip><"clear">',
            order: [[6, 'asc']],
            responsive: true,
            // scrollX: true,
            destroy: true,
            processing: true,
            //"ordering": false,
            lengthMenu: [
                [10, 20, 25, 30, 50, 100, 200, 500],
                [10, 20, 25, 30, 50, 100, 200, 500],
            ],
            serverSide: true,
            serverMethod: "POST",
            ajax: {
                url: baseUrl + "list-referral",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: function (data) {
                    data.user_id = user.id;
                    data.search_value = $(".searchbarTable").val();
                    data.filter = filter;
                    data.call_from = 'admin_referral_listing';
                    data.first_time_call = isFirstTimeList;
                },
                error: function (jqXHR,error, errorThrown) {

                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{

                    }
                }
            },
            drawCallback: function (settings) {
                // Here the response
                var response = settings.json;
                isPageLoad = 0;
                
                $("#spanInProcessCount").html('(' + response.inProcessReferralCount + ')');
                $("#spanClosedCount").html('(' + response.closedReferralCount + ')');
                $("#spanUncompletedCount").html('(' + response.uncompletedReferralCount + ')');
                $("#spanAwaitingPaymentCount").html('(' + response.awaitingPaymentReferralCount + ')');

                let totalCount =  parseInt(response.inProcessReferralCount) + parseInt(response.closedReferralCount) + parseInt(response.uncompletedReferralCount) + parseInt(response.awaitingPaymentReferralCount);
                
                $("#spanReferralCount").html('(' + totalCount + ')');

                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.pBottom').hide();
                else
                    $('.pBottom').show();
            },
            columnDefs: [
                {
                    targets: 0,
                    width: "10.00%",
                    title: "Client",
                    data: "client_name",
                    name: "client_name",
                    searchable: true,
                    visible: true,
                    orderable: true,
                    orderData: [0],
                    orderDataType: "asc",
                    className: "my_amb referral-t bold-td desktop tablet-p tablet-l mobile-p mobile-l",
                    createdCell:"",
                    render: "",
                },
                {
                    targets: 1,
                    width: "15.00%",
                    title: "<span class='sorting-arrow'>Referring </br>Agent</span>",
                    data: "referring_user_full_name",
                    name: "referring_user_full_name",
                    searchable: true,
                    visible: true,
                    orderable: true,
                    orderData: [1],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.referring_user_full_name !== 'N/A')
                        {
                            createRoot(td).render(<ul className='title-check'>
                            <li><span className='name-title'> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e,`${rowData.users_id}`,'ambassador')} className='name-link underline-ani-blue'>{rowData.referring_user_full_name}</a></span></li>
                                </ul>
                            );
                        }
                        else
                        {
                            createRoot(td).render(<ul className='title-check'><li><span className='name-title'>N/A</span></li></ul>
                            );
                        }
                  },
                    render: "",
                },
                {
                    targets: 2,
                    width: "12.00%",
                    title: "<span class='sorting-arrow'>Assigned </br>Agent</span>",
                    data: "assigned_agent",
                    name: "assigned_agent",
                    searchable: true,
                    visible: true,
                    orderable: true,
                    orderData: [2],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.assigned_agent !== 'N/A')
                        {
                            createRoot(td).render(<ul className='title-check'>
                            <li><span className='name-title'> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e,`${rowData.agent_id}`,'ambassador')} className='name-link underline-ani-blue'>{rowData.assigned_agent}</a></span></li>
                                </ul>
                            );
                        }
                        else
                        {
                            createRoot(td).render(<ul className='title-check'><li><span className='name-title'>N/A</span></li></ul>
                            );
                        }
                  },
                    render: "",
                },
                {
                    targets: 3,
                    width: "10.00%",
                    title: "<span class='sorting-arrow'>Referral </br>Type</span>",
                    data: "buyer_or_seller",
                    name: "buyer_or_seller",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [3],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t desktop referral-th",
                    createdCell: "",
                    render: function (data, type,row) {
                        if(data)
                        {
                            if(data === 'buyer_referral')
                                data = 'Buy';
                            if(data === 'seller_referral')
                                data = 'Sell';
                        }
                        return '<span>' + data + '</span>';
                    },
                },
                {
                    targets: 4,
                    width: "5.00%",
                    title: "<span class='sorting-arrow'>Reference</br>Number</span>",
                    data: "reference_number",
                    name: "reference_number",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [4],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t tablet-p desktop tablet-l referral-th",
                    createdCell: "",
                    render: "",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.reference_number)
                        {
                            if(rowData.is_downline_record) {
                                createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title name-link'> {rowData.reference_number}</span></li>
                                    </ul>
                                );
                            } else {
                                createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'> <a href="javascript:;" onClick={(e) => handleView(e,`${rowData.id}`)} className='name-link underline-ani-blue'>{rowData.reference_number}</a></span></li>
                                    </ul>
                                );
                            }
                        }
                        else
                        {
                            createRoot(td).render(<ul className='title-check'><li><span className='name-title'>N/A</span></li></ul>
                            );
                        }
                  },
                },
                
                {
                    targets: 5,
                    width: "11.00%",
                    title: "<span class='sorting-arrow'>Actual </br>Commission</span>",
                    data: "pending_or_closed_commission",
                    name: "pending_or_closed_commission",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [5],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    createdCell: "",
                    render: "",
                },
                
                {
                    targets: 6,
                    width: "10.00%",
                    title: "<span class='sorting-arrow'>Created Date</span>",
                    data: "created",
                    name: "created",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [6],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    createdCell: "",
                    render: "",
                },
                {
                    targets: 7,
                    width: "10.00%",
                    title: "Status",
                    data: "status",
                    name: "status",
                    visible: true,
                    searchable: true,
                    orderable: true,
                    orderData: [7],
                    orderDataType: "asc",
                    cellType: "td",
                    className: "my_amb referral-t referral-th desktop",
                    createdCell: "",
                    render: "",
                },
                {
                    targets: 8,
                    width: "15.00%",
                    title: "Action",
                    data: "",
                    visible: true,
                    searchable: false,
                    orderable: false,
                    className: "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                    "createdCell": (td, cellData, rowData, row, col) => {
                        if(rowData.is_downline_record === 0) {
                            createRoot(td).render(<div class="dropdown referral-option">
                            <a class="dropdown-toggle" href="javascript:void(0)" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="../assets/images/option-icon.png" alt="" />
                            </a>

                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a class="dropdown-item" href="javascript:;" onClick={(e) => handleView(e, `${rowData.id}`)}>View</a></li>

                                { (user.type === 'admin')  && (
                                <li><a class="dropdown-item" href="javascript:;" onClick={(e) => handleEmail(`${rowData.id}`)}>Email</a></li>)
                                }

                            { (((rowData.users_id === user.id) && (!rowData.agent_id)) || (user.type === 'admin'))  && (<>
                            <li><a class="dropdown-item"  onClick={(e) => handleDelete(e, `${rowData.id}`,'in_process_referral')} href="javascript:;">Delete</a></li></>)
                                }

                            </ul>
                        </div>
                            );
                        }
                    },
                    render: "",
                },
            ],
        });
        $(".searchbarTable").keyup(function () {
            if ((filter === 'closed_referral') && (($(this).val().length >= 3) || ($(this).val().length === 0)))
            {
                setCurrentFilter('closed_referral');
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else if (filter === 'closed_referral')
            {
                setShowClearSearch(false);
            }
        });
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

            
            
        $(".closedTable").on('click', 'th', function () {
            $(".closedTable thead th").each(function (i, th) {
               
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');

                    
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });
        
        $(".closedTable th").first().click().click();
        $('.closedTabClass').click(function () {
            filter = 'closed_referral';
            isFirstTimeList = 1;
            isPageLoad =1;
            table.clear();
            table.draw();
        })

        var headers = $(".closedTable").DataTable().columns().header().toArray();

        $(headers).on('click', function () {
            if(isPageLoad == 0) {
                isFirstTimeList = 0;
            }
        });
    }
    
    const width = {
        width: "100%",
    }
    
    
    return (
        <>
            <section className='table-component sec-pt sec-pb'>
                <div className="container-fluid">
                {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                            {errorMessage}
                        </Alert>
                    )}
                    <div className="table-search">
                        <h2 className='main-heading mb-0'>Referrals <span id="spanReferralCount">(0)</span></h2>
                        <div className="tableSearch referral-list-search">
                            <span className="p-input-icon-right">
                                <InputText
                                 autoComplete="off"
                                    id="searchbarTable"
                                    className="searchbarTable referral-search"
                                    placeholder="Search by client, assigned, reference number or referring agent"
                                    title= "Search by client, assigned, reference number or referring agent"
                                />
                                {
                                    (!showClearSearch) && (<i className="pi pi-search" />)
                                }
                                {
                                    (showClearSearch) && (<a className="close-icon" href="javascript:;" onClick={handleClearSearch} ><i className="pi pi-times" /></a>)
                                }

                            </span>
                            
                        </div>
                    </div>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active inProcessTabClass" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false" >In Process <span id="spanInProcessCount">(0)</span></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link awaitingPaymentTabClass" id="awaiting-payment-tab" data-bs-toggle="tab" data-bs-target="#awaiting-payment-tab-pane" type="button" role="tab" aria-controls="awaiting-payment-tab-pane" aria-selected="false" >Awaiting Payment <span id="spanAwaitingPaymentCount">(0)</span></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link closedTabClass" id="license-tab" data-bs-toggle="tab" data-bs-target="#license-tab-pane" type="button" role="tab" aria-controls="license-tab-pane" aria-selected="false" >Completed <span id="spanClosedCount">(0)</span></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link unCompletedTabClass" id="uncompleted-tab" data-bs-toggle="tab" data-bs-target="#uncompleted-tab-pane" type="button" role="tab" aria-controls="uncompleted-tab-pane" aria-selected="false" >Uncompleted <span id="spanUncompletedCount">(0)</span></button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                       
                        <div className="tab-pane fade show active" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                            <table className="short-table display nowrap networkTable inProcessTable" cellSpacing="0" style={width} id="networkTable">
                            <thead>
                                    <tr>
                                        <th>Client</th>
                                        <th>Referring<br/>Agent</th>
                                        <th>Assigned<br/>Agent</th>
                                        <th>Referral<br/>Type</th>
                                        <th>Reference<br/>Number</th>
                                        <th>Potential<br/>Referral Fee</th>
                                        <th>Created Date</th>
                                        <th>Status</th>
                                        <th className="hide_sorting">Action</th>
                                    </tr>
                                </thead>
                                <tbody>


                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="awaiting-payment-tab-pane" role="tabpanel" aria-labelledby="awaiting-payment-tab" tabIndex="0">
                            <table className="short-table display nowrap networkTable awaitingPaymentTable" cellSpacing="0" style={width} id="networkTable">
                            <thead>
                                    <tr>
                                        <th>Client</th>
                                        <th>Referring<br/>Agent</th>
                                        <th>Assigned<br/>Agent</th>
                                        <th>Referral<br/>Type</th>
                                        <th>Reference<br/>Number</th>
                                        <th>Potential<br/>Referral Fee</th>
                                        <th>Created Date</th>
                                        <th>Status</th>
                                        <th className="hide_sorting">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="license-tab-pane" role="tabpanel" aria-labelledby="license-tab" tabIndex="0">
                            <table className="short-table display nowrap networkTable closedTable" cellSpacing="0" style={width} id="networkTable">
                            <thead>
                                    <tr>
                                        <th>Client</th>
                                        <th>Referring<br/>Agent</th>
                                        <th>Assigned<br/>Agent</th>
                                        <th>Referral<br/>Type</th>
                                        <th>Reference<br/>Number</th>
                                        <th>Potential<br/>Referral Fee</th>
                                        <th>Created Date</th>
                                        <th>Status</th>
                                        <th className="hide_sorting">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="uncompleted-tab-pane" role="tabpanel" aria-labelledby="uncompleted-tab" tabIndex="0">
                            <table className="short-table display nowrap networkTable unCompletedTable" cellSpacing="0" style={width} id="networkTable">
                            <thead>
                                    <tr>
                                        <th>Client</th>
                                        <th>Referring<br/>Agent</th>
                                        <th>Assigned<br/>Agent</th>
                                        <th>Referral<br/>Type</th>
                                        <th>Reference<br/>Number</th>
                                        <th>Potential<br/>Referral Fee</th>
                                        <th>Created Date</th>
                                        <th>Status</th>
                                        <th className="hide_sorting">Action</th>
                                    </tr>
                                </thead>
                                <tbody>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default ReferralListing;