import React, { useState, useEffect } from "react";
import { NavLink, useNavigate,useLocation } from "react-router-dom";
import RegisterCheckEmail from "./RegisterCheckEmail";
import axios from "../../../axiosCall.js";
import { Alert, CircularProgress, Typography } from "@mui/material";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

const VerifyEmail = () => {
    const secretKey = "REFR2022@GALAXY";
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showModal, setShowModal] = useState(false);

    const search = useLocation().search;
    const userIdRequest = new URLSearchParams(search).get('u');
    const userEmailRequest = new URLSearchParams(search).get('ue');
    const userNameRequest = new URLSearchParams(search).get('un');

    let userId = "";
    let userEmailId = "";
    let userName = "";

    if ( userIdRequest === null ||  userEmailRequest === null || userNameRequest === null ) 
    {
        navigate('/signup');
    } else {
      //alert('else');
        let userIdDecrypt = AES.decrypt(userIdRequest, secretKey);
        
        if(userIdDecrypt == "")
        {
            //console.log('if')
            navigate('/signup');
        }
        else
        {
            //console.log('else')
            userId = JSON.parse(userIdDecrypt.toString(Utf8));
        }

        let userEmailIdDecrypt = AES.decrypt(userEmailRequest, secretKey);
        if(userEmailIdDecrypt == "")
        {
            //console.log('if')
            navigate('/signup');
        }
        else
        {
            //console.log('else')
            userEmailId = JSON.parse(userEmailIdDecrypt.toString(Utf8));
        }

        
        let userNameDecrypt = AES.decrypt(userNameRequest, secretKey);
        if(userNameDecrypt == "")
        {
            //console.log('if')
            navigate('/signup');
        }
        else
        {
            //console.log('else')
            userName = JSON.parse(userNameDecrypt.toString(Utf8));
        }
        
    }

    

    useEffect(() => {}, [showModal]);
    const handleSendVerifyEmail = async () => {
        //alert('inisde submit')
        setErrorMessage("");
        setLoading(true);
        setShowModal(false);

        let data = { email: userEmailId, host_name: window.location.origin };
        try {
            const response = await axios.post("/send_verify_email", data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = "";
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                setLoading(false);
                setShowModal(true);
            }
        } catch (err) {
            // console.log(err);
            setErrorMessage(err);
            setLoading(false);
        }
    };

    return (
        <>
            <div className="reset-section">
                <div className="reset-section-in">
                    <div className="main-body">
                        <div className="popup-logo">
                            <img
                                src="./assets/images/Mask-group.png"
                                alt="img"
                            />
                        </div>
                        {errorMessage && (
                            <Alert
                                sx={{ mb: 3 }}
                                severity="error"
                                variant="filled"
                            >
                                {errorMessage}
                            </Alert>
                        )}
                        <div className="reset-items">
                            <h2 className="reset-title">Hi, {userName}</h2>
                            <p className="reset-content">
                                Please verify your email address
                                <br />{" "}
                                <a href={`mailto:${userEmailId}`}>
                                    {userEmailId}
                                </a>
                                , that you entered when signing up for{" "}
                                <span>REFR</span>.
                            </p>
                            <button
                                type="button"
                                className="btn btn-primary reset-btn"
                                onClick={handleSendVerifyEmail}
                                disabled={loading}
                            >
                                Verify Email
                                {loading && (
                                <Typography align="center">
                                    <CircularProgress value={66} />
                                </Typography>
                            )}
                            </button>
                            
                            {/* <NavLink to="/resetpassword" className="btn btn-primary reset-btn">Verify Email</NavLink> */}
                            <p className="reset-message">
                                You have received this email as you have
                                registered with <span>REFR.com</span>{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <RegisterCheckEmail
                    showModal={showModal}
                    userEmailId={userEmailId}
                />
            )}
        </>
    );
};

export default VerifyEmail;
