import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { InputText } from 'primereact';
import { Checkbox } from 'primereact';
import Button from 'react-bootstrap/Button';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";


const Invite = (props) => {

    const { user,logout } = useAuth();
    const [show, setShow] = useState(props.showUserInvite);
    const navigate = useNavigate();
    const inviteClose = () => setShow(false);
    const inviteShow = () => setShow(true);
    const [loading, setLoading] = useState(false); 
    const [errorMessage, setErrorMessage] = useState('');


    const formik = useFormik({
        initialValues: {
            users_id: '',
            email: '',
            first_name: '',
            last_name: '',
            added_from: 'other_user',
            type: 'invite_as_ambassador',
            host_name: window.location.origin,
        },
        validate: (data) => {
            let errors = {};

            if (!data.email) {
                errors.email = 'Email address is required.';
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address. e.g. example@email.com';
            }
            if (data.first_name == null || data.first_name.trim() === '') {
                errors.first_name = 'First name is required.';
            }
            else if (data.first_name.length > 70)
            {
                errors.first_name = 'Max characters: 70';
            }
            else if (data.first_name.length < 2)
            {
                errors.first_name = 'Min characters: 2';
            }
            if (data.last_name == null || data.last_name.trim() === '') {
                errors.last_name = 'Last name is required.';
            }
            else if (data.last_name.length > 70)
            {
                errors.last_name = 'Max characters: 70';
            }
            else if (data.last_name.length < 2)
            {
                errors.last_name = 'Min characters: 2';
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    useEffect(() => {
        if (user !== null) {
            formik.setFieldValue("users_id", user.id ? user.id : "");
        }
    }, [user]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleCheckboxChange = (mode) => {
        formik.setFieldValue("type", mode)
    }
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        try {
            const response = await axios.post('/invite-email', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {
                setLoading(false);
                inviteClose();
                props.changeStatus();
                navigate('/app/my-network');
            }
        } catch (err) {
            
            setLoading(false);
            window.scrollTo(0, 0);
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }else{
               
                setErrorMessage("Something went wrong");
            }
        }
    };
    return (
        <>
            {/* <Button variant="primary" onClick={inviteShow}>
                Invite Modal
            </Button> */}
            <Modal className='invite-user-popup' show={show} onHide={inviteClose} data-keyboard="false" backdrop="static">

                <Modal.Header className='invite-user-modal'>
                    <Modal.Title>Invite User</Modal.Title>
                </Modal.Header>
                {errorMessage && (
                    <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                        {errorMessage}
                    </Alert>
                )}
                <form onSubmit={formik.handleSubmit} >
                    <Modal.Body className='invite-user-section'>
                        <div className="invite-user-select">
                            <label htmlFor="inlineRadio1">
                                <div className={classNames("form-check form-check-inline invite-box invite-space", { 'active': formik.values.type === 'invite_as_ambassador' })} >
                                    <div className="invite-items">
                                        <div className='userTitle'>
                                            {
                                                (formik.values.type === 'invite_as_agent') && (
                                                    <img src='/assets/images/user-unselect.png' alt='img' />)
                                            }
                                            {
                                                (formik.values.type === 'invite_as_ambassador') && (
                                                    <img src='/assets/images/user-blue.png' alt='img' />)
                                            }

                                            <label className="form-check-label">REFR <br />Ambassador</label>
                                        </div>
                                        <div className='check'>
                                            <Checkbox
                                                className="form-check-input user-check" name="type"
                                                value="invite_as_ambassador"
                                                checked={formik.values.type === 'invite_as_ambassador'}
                                                onChange={() => { handleCheckboxChange('invite_as_ambassador') }}
                                                inputId="inlineRadio1" ></Checkbox>
                                        </div>
                                    </div>
                                    <div className="user-description">
                                        <p>Choose this option if you are still actively selling real estate with another brokerage and you would just like to use our platform to submit/receive referrals or invite others who are looking to be Referral Only Agents - <span>This option is FREE</span></p>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor="inlineRadio2">
                                <div className={classNames("form-check mb-4 form-check-inline invite-box", { 'active': formik.values.type === 'invite_as_agent' })} >
                                    <div className="invite-items ">
                                        <div className='userTitle'>
                                            {
                                                (formik.values.type === 'invite_as_ambassador') && (
                                                    <img src='/assets/images/user-unselect.png' alt='img' />)
                                            }
                                            {
                                                (formik.values.type === 'invite_as_agent') && (
                                                    <img src='/assets/images/user-blue.png' alt='img' />)
                                            }
                                            <label className="form-check-label label2">REFR <br />Agent </label>
                                        </div>
                                        <div className='check'>
                                            <Checkbox className="form-check-input user-check" name="type"
                                                value="invite_as_agent" checked={formik.values.type === 'invite_as_agent'}
                                                onChange={() => { handleCheckboxChange('invite_as_agent') }} inputId="inlineRadio2" />
                                        </div>
                                    </div>
                                    <div className="user-description">
                                        <p>Choose this option if they are looking to move their license to our brokerage and work as a Referral Only Agent</p>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="invite-user-body">
                            <div className="invite-user-form">
                                <div className="row">
                                    <div className="field form-field signup-label col-lg-6 signup-form-field">
                                        <label htmlFor="fname">First Name <span className="reuired-star">*</span></label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder="Enter here"
                                            name="first_name"
                                            value={formik.values.first_name} onChange={formik.handleChange}
                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('first_name') })}
                                        />
                                        {getFormErrorMessage('first_name')}
                                    </div>
                                    <div className="field form-field signup-label col-lg-6 signup-form-field">
                                        <label htmlFor="lname">Last Name <span className="reuired-star">*</span></label>
                                        <InputText name="last_name"
                                            autoComplete="off"
                                            value={formik.values.last_name} onChange={formik.handleChange}
                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('last_name') })} placeholder="Enter here" />
                                        {getFormErrorMessage('last_name')}
                                    </div>
                                </div>

                                <div className="field mb-3">
                                    <label htmlFor="recipient-name">Email Address <span className="reuired-star">*</span></label>
                                    <InputText
                                        autoComplete="off"
                                        type="text" placeholder="Enter Here" id="recipient-name"
                                        name="email"
                                        value={formik.values.email} onChange={formik.handleChange}
                                        className={classNames("form-control invite-input", { 'p-invalid': isFormFieldValid('email') })} />
                                    {getFormErrorMessage('email')}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='invite-user-footer'>
                        <Button className='cancel-btn' variant="secondary" onClick={() => { inviteClose(); props.changeStatus() }}>
                            Cancel
                        </Button>
                        <Button type="submit" className='btn btn-submit btn-500 btn-submit-loader' variant="primary" disabled={loading}>
                            Send Invite

                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                        </Button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default Invite;