import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import React, {  useState,useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { FileUploader } from "react-drag-drop-files";
import { Alert,CircularProgress,Typography} from '@mui/material';
import Swal from 'sweetalert2';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";

const UploadDocuments = (props) => {
    const [show, setShow] = useState(props.showUploadDocs);
    const handleClose = () => setShow(false);

    const [isFile, setIsFile] = useState(false);
    const [fileName, setFileName] = useState('');
    const [media, setMedia] = useState('');
    const [validMessage, setValidMessage] = useState('');

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { logout,setUserDataCall } = useAuth();
    const fileTypes = ["pdf","docx","doc"];
    const navigate = useNavigate();

    
    const handleImport = (file) => {
        const fileSize = file.size / 1024 / 1024; // in MiB
        setValidMessage('');
        
        if (fileSize > 2) {            
            setIsFile(false);
            setFileName('')
            setValidMessage('Please upload pdf file not more than 2 MB')
        }
        else if (file.type !== 'application/pdf' && file.type !== 'application/msword'  && file.type !== 'application/wps-office.docx' && file.type !== 'application/wps-office.doc' && file.type !== 'application/ms-doc' && file.type !== 'application/doc' && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            setIsFile(false);
            setFileName('')
            
            setValidMessage(file.name + " has an invalid extension.<br/> Valid extension(s): pdf, doc, docx",)
        }
        else {
            setIsFile(true);
            setFileName(file.name)
            setMedia(file);
        }
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isFile) {
            setLoading(true);
            let formData = new FormData();

            formData.append('users_id', props.editRowId);
            formData.append('mode', props.callFrom);
            formData.append('media', media);
            formData.append('is_profile_page_call', props.is_profile_page_call);
            try {
                const response = await axios.post('/upload-docs_by_admin', formData);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = '';
                setLoading(false);
                if (Status === false) {
                    if (Error.length > 0) {
                        Error.map(
                            (errorMessage) =>
                                //finalApiError += '<li>'+errorMessage+'</li>'
                                (finalApiError = errorMessage)
                        );
                    } else {
                        finalApiError = StatusMessage;
                    }
                    setErrorMessage(finalApiError);
                } else {
                    if(props.is_profile_page_call)
                    {
                        setUserDataCall(Result);
                    }
                    setLoading(false);
                    handleClose();
                    props.changeStatuUpload();
                    props.reloadListing();
                }
            } catch (err) {

                setLoading(false);
                if (err.response.status && err.response.status == 401) {
                    logout();
                    navigate('/login');
                } else {
                    setErrorMessage("Something went wrong");
                   
                }
            }
        }
        else {
            setValidMessage('Please upload file.')
        }
    }
    return (
        <>
            <Modal className='upload-popup' show={show} onHide={handleClose} backdrop="static">
                <form onSubmit={handleSubmit}>
                    <Modal.Header className='upload-header'>
                        <Modal.Title>Upload Document for  {(props.dbUserFullName) ? props.dbUserFullName+"'s" : 'Referral'}</Modal.Title>
                    </Modal.Header>
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                            {errorMessage}
                        </Alert>
                    )}
                    <Modal.Body className='upload-section'>
                        <div className="row gx-3 form-section">

                            <div className="field col-md-12 profile-input" >
                                {
                                    (props.callFrom === 'master_referral_services_fee_media') && (<label htmlFor="inputEmail4" className="form-label">Master Referral Services Fee <span className="reuired-star">*</span></label>)
                                }
                                {
                                    (props.callFrom === 'brokerage_participation_agreement_media') && (<label htmlFor="inputEmail4" className="form-label">Brokerage Participation Agreement <span className="reuired-star">*</span></label>)
                                }
                                {
                                    (props.callFrom === 'independent_contractor_agreement_media') && (<label htmlFor="inputEmail4" className="form-label">Independent Contractor Agreement <span className="reuired-star">*</span></label>)
                                }
                                {
                                    (props.callFrom === 'upload_personal_media') && (<label htmlFor="inputEmail4" className="form-label">{(props.editRowType === 'agent') ? 'Personal' : 'Brokerage'}  W-9 <span className="reuired-star">*</span></label>)
                                }
                                {
                                    (props.callFrom === 'referral_documents') && (<label htmlFor="inputEmail4" className="form-label">Referral Document <span className="reuired-star">*</span></label>)
                                }
                                <FileUploader
                                    handleChange={handleImport}
                                    name="file"
                                    types={fileTypes}
                                    // label= "Drag & drop or, browse your file"
                                    children=<div className='upload-files-csv'>
                                        <div className='upload-file-box'>
                                            <p className='title'><img src="../assets/images/csv-file-icon.png" alt="" /> Drag & drop or, <span>browse</span>  your file</p>
                                        </div>
                                        {
                                            (fileName) && (<p>File Name {fileName}</p>)
                                        }
                                    </div>
                                />
                                {
                                    (validMessage) && (<small className="p-error">{validMessage}</small>)
                                }
 <br/>
                               
                            </div>

                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer className='file-upload-footer'>
                        <Button className='btn-cancel btn-500' variant="" onClick={() => { handleClose(); props.changeStatuUpload(); }} disabled={loading}>
                            Cancel
                        </Button>
                        <Button type="submit" className='btn btn-submit btn-500 btn-submit-loader' variant="" disabled={loading}>
                            Upload
                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                        </Button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default UploadDocuments;