import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { classNames } from "primereact/utils";
import { Dropdown, Avatar, InputText, RadioButton } from "primereact";
import { Card } from "react-bootstrap";
import { useFormik } from "formik";
import { InputMask } from "primereact";
import axios from "../../../axiosCall.js";
import useAuth from "./../../../hooks/useAuth";
import Modal from 'react-bootstrap/Modal';
import { Alert, CircularProgress, Typography } from '@mui/material';
import MakePayment from './MakePayment';

const SelectPlan = (props) => {
    const [show, setShow] = useState(props.showSelectPlan);
    const [shows, setShows] = useState(false);
    const [statusbtn, setStatusbtn] = useState(true);
    const [favorite, setFavorite] = React.useState('3');
    const [favoritePre, setFavoritePre] = React.useState('3');
    const navigate = useNavigate();
    const modalClose = () => setShow(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState('');
    const [loader, setLoader] = useState(false);
    const search = useLocation().search;
    const verificationCode = new URLSearchParams(search).get('c');
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    const [userEmail, setUserEmail] = useState('');
    const [userBadd, setUserBadd] = useState('');
    const [userId, setUserId] = useState('');
    const [cardData, setCardData] = useState([]);
    const [linkPost, setLinkPost] = useState(true);
    const [planId, setPlanId] = useState('');
    const [userType, setUserType] = useState('');
    const [userLicenseState, setUserLicenseState] = useState('');
    const [userMsg, setUserMsg] = useState('');
    const [isLinkExpired, setIsLinkExpired] = useState(false);
    const { user, logout } = useAuth();
    const [nextOpen, setNextOpen] = useState(false);
    const {cls, setCls} = useState('add-people-popup');

    const handleCatChange = () => {
        setFavorite('1');
        setStatusbtn(false)
    };

    const handleDogChange = () => {
        setFavorite('2');
        setStatusbtn(false)
    };
    const handleMChange = () => {
        setFavorite('3');
        setStatusbtn(false)
    };
    const routeChange = () => {
        navigate('/');
    }
    const parentToChild = () => {

        setPlanId(favorite);
        if (shows) {
            setShows(false);
        } else {
            setShows(true);
        }

        // let dataSend = {
        //     user_id: userId,
        //     product_id: favorite
        // }

        // try {
        //     const response =  axios.post('/update-subscription', dataSend).then(function (response) {
        //         const { Status, StatusMessage, Error, Result } = response.data ? response.data : [];
        //         let finalApiError = '';
        //         if (Status === false) {
        //             // if (Error.length > 0) {
        //             //     // Error.map(
        //             //     //     (errorMessage) =>
        //             //     //         finalApiError += '<li>' + errorMessage + '</li>'
        //             //     //             (finalApiError = errorMessage)
        //             //     // );
        //             // } else {
        //             finalApiError = StatusMessage;
        //             // }
        //             setErrorMessage(finalApiError);

        //         } else if (Status === true) {



        //             // navigate('/edit-profile');
        //             //props.modalClose()
        //             //props.changeStatuClosedhandleModal()
        //         }
        //     }).catch(function (error) {
        //         if (error.response) {
        //             // The request was made and the server responded with a status code
        //             // that falls out of the range of 2xx
        //             console.log(error.response.data);
        //             console.log(error.response.status);
        //             console.log(error.response.headers);
        //             setErrorMessage(error.response.data.message);


        //         } else if (error.request) {
        //             // The request was made but no response was received
        //             // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        //             // http.ClientRequest in node.js
        //             console.log(error.request);
        //         } else {
        //             // Something happened in setting up the request that triggered an Error
        //             console.log('Error', error.message);
        //             setErrorMessage(error.message);

        //         }
        //         console.log(error.config);
        //     });

        // } catch (err) {
        //     //console.log(err.toJSON());
        //     // setErrorMessage(err.message);
        //     // setLoading(false);
        //     if (err.response) {
        //         // The client was given an error response (5xx, 4xx)
        //         console.log(err.response.data);
        //         console.log(err.response.status);
        //         console.log(err.response.headers);
        //     } else if (err.request) {
        //         // The client never received a response, and the request was never left
        //     } else {
        //         // Anything else
        //         console.log('Error', err);
        //     }
        // }
    }

    let counter = 0;
    useEffect(() => {
        const verifyUser = async () => {
            setErrorMessage('');
            setSuccessMessage('');
            setLoader(true);

            try {
                const response = await axios.post(`/userSubscription/${user.id}`);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = "";
                setLoader(false);

                if (Status === false) {
                    if (Error.length > 0) {
                        // Error.map(
                        //   (errorMessage) =>
                        //     finalApiError += '<li>'+errorMessage+'</li>'
                        //     (finalApiError = errorMessage)
                        // );
                    } else {
                        finalApiError = StatusMessage;
                    }
                    setErrorMessage(finalApiError);

                    if (Result.is_expired === 1) {

                        setIsLinkExpired(false);
                    }
                    else {
                        setIsLinkExpired(false);
                    }
                    window.scrollTo(0, 0);
                } else {

                    setUserEmail(Result.email)
                    setUserBadd(Result.business_address)
                    setUserId(Result.user_id);
                    setUserType(Result.type);
                    setFavorite(Result.planId);
                    setFavoritePre(Result.planId);
                    setUserLicenseState(Result.license_state_id)
                    if(Result.lastStatus === 'active' && Result.planId === '3'){
                        setNextOpen(true)
                    }else{
                        if(Result.lastStatus === 'active' && Result.planId === '2'){
                            setNextOpen(true)
                        }else if(Result.lastStatus === 'active' && Result.planId === '1'){
                            setNextOpen(true)
                        }else{
                            setNextOpen(true)
                        }

                    }

                    if (Result.license_state_id === 9 || Result.license_state_id === 33) {
                        setUserMsg('');
                    } else if (Result.license_state_id === 6 || Result.license_state_id === 30) {
                        setUserMsg('');
                    } else {
                        setUserMsg('');
                    }

                }
            } catch (err) {
                // console.log(err);
                setErrorMessage(err);
                setLoader(false);
            }
        };

        if (counter === 0) {
            //alert(counter)
            counter++;

            verifyUser();

        }

    }, [linkPost]);





    const handleSubmit = async (data) => {
        //alert('inisde submit')


    };
    useEffect(() => {



    }, [user]);



    return (
        <>
           <Modal enforceFocus={false} className={(shows === true) ?  (nextOpen === true)? "add-people-popup make_payment successOpen" : "add-people-popup make_payment"  :  (nextOpen === true)? "add-people-popup successOpen" : "add-people-popup" } show={show} onHide={modalClose} backdrop="static" >

            { (nextOpen === false) ?(<Modal.Header className='add-people-header'>
                        <Modal.Title>{(shows == true)?"Enter your credit card details":"Pick your plan"}</Modal.Title>
                    </Modal.Header>):<Modal.Header className='add-people-header'><Modal.Title> </Modal.Title> </Modal.Header>}

                    <Modal.Body className='subscription-plan-section'>
                        <div className=''>
                            {(isLinkExpired === false) && (<div className="">
                            {(shows == true) ? <MakePayment  nextOpen={nextOpen} parentToChild={parentToChild} modalClose={modalClose} changeStatuClosedhandleModal={props.changeStatuClosedhandleModal} favorite={favorite} userId={userId} userLicenseState={userLicenseState} /> : <div></div>}
                                {(shows === false) ?
                                    <section className='referral-template'>
                                        <div className="container">
                                            <div className="referral-container">
                                                {/* <h2 className='main-heading'>Subscription</h2> */}
                                                <Card>
                                                    <Card.Body>
                                                        <div className="card-container">

                                                            <div className='referral-fields subscription_plan'>
                                                                {/* <p className="title sub-titles">Pick your plan</p> */}
                                                                <label htmlFor="subs_1">
                                                                    <div className={(favorite == 3) ? "referral-field active  sub_field sub_field_1 " : "referral-field sub_field sub_field_1"}>
                                                                        <RadioButton inputId="subs_1" name="referral_type" checked={(favorite == 3) ? true : false} disabled={(favoritePre == 3) ? true : false} value={favorite === '3'} onChange={handleMChange} />
                                                                        <label className="step-one-label">Basic</label>
                                                                        <p className='referral-desc'>FREE FOREVER - 50/50 Commission Split </p>
                                                                    </div>
                                                                </label>
                                                                <label htmlFor="subs_2">
                                                                    <div className={(favorite == 1) ? "referral-field active  sub_field sub_field_1 " : "referral-field sub_field sub_field_1"}>
                                                                        <RadioButton inputId="subs_2" name="referral_type" checked={(favorite == 1) ? true : false} disabled={(favoritePre == 1) ? true : false} value={favorite === '1'} onChange={handleCatChange} />
                                                                        <label className="step-one-label">Standard</label>
                                                                        <p className='referral-desc'><b>$9</b>/Mo-80/20 Commission Split (You Keep 80% of the Referral Commission)</p>
                                                                    </div>
                                                                </label>
                                                                <label htmlFor="subs_3">
                                                                    <div className={(favorite == 2) ? "referral-field active  sub_field sub_field_1" : "referral-field sub_field sub_field_1 last_field"}>
                                                                        <RadioButton inputId="subs_3" name="referral_type" checked={(favorite == 2) ? true : false} disabled={(favoritePre == 2) ? true : false} value={favorite === '2'} onChange={handleDogChange} />
                                                                        <label className="step-one-label">Pro</label>
                                                                        <p className='referral-desc'><b>$19</b>/Mo-90/10 Commission Split (You Keep 90% of the Referral Commission) </p>
                                                                    </div>
                                                                </label>
                                                                <p className="sub-description">{userMsg}</p>
                                                            </div>
                                                            <div className="card-footer text-end">
                                                                <button class="btn btn-cancel me-3" type="button" onClick={() => { modalClose(); props.changeStatuClosedhandleModal(); }}>Cancel</button>
                                                                <button class="btn btn-submit btn-500" type="button" onClick={() => parentToChild(shows)}  disabled={statusbtn}>Next</button>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div> </div></section> : <div></div>}
                            </div>)
                            }
                        </div>

                    </Modal.Body>
                    {/* <Modal.Footer className='add-people-footer'>
                        <button type="button" className='btn btn-cancel' variant="secondary" onClick={() => { modalClose(); props.changeStatuClosedhandleModal(); }} >
                            Close
                        </button>



                    </Modal.Footer> */}

            </Modal>
        </>
    );
};

export default SelectPlan;
