import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { InputText } from 'primereact';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';



const ReferralFeeReceivedForm = (props) => {
    const [show, setShow] = useState(props.showReferralFeeReceivedForm);
    const navigate = useNavigate();
    const modalClose = () => setShow(true);
    const { user, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
   
    const formik = useFormik({
        initialValues: {
            referral_id: props.referralRowId,
            referral_fee_received:props.referralFeeReceivedDB,
            host_name: window.location.origin,
        },
        validate: (data) => {
            let errors = {};
            ///alert(data.type)
            if (!data.referral_fee_received) {
                errors.referral_fee_received = 'Referral fee received is required.';
            }
            else if (data.referral_fee_received)
            {
                if (!isPositiveFloat(data.referral_fee_received)) {
                    errors.referral_fee_received = "Invalid referral fee received. e.g. 10000";
                }
                else if(parseFloat(data.referral_fee_received) > 100000000)
                {
                    errors.referral_fee_received = "Max: 100,000,000";
                }
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    const isPositiveFloat = (s) => {
        return !isNaN(s) && Number(s) > 0;
    }
    
    useEffect(() => {
        if (user !== null) {
            formik.setFieldValue("users_id", user.id ? user.id : "");
        }
        
    }, [user]);
    
    

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        try {
            const response = await axios.post('/post-referral-fee-received', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                setLoading(false);
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        modalClose();
                        props.changeStatusReferralFeeReceivedFormForm();
                    }
                });
            }
        } catch (err) {
            setLoading(false);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } 

        }
    };
    
    return (
        <>
            <Modal className='add-people-popup' show={show} onHide={modalClose} data-keyboard="false" backdrop="static" >
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header className='add-people-header'>
                        <Modal.Title>Referral Fee Received</Modal.Title>
                    </Modal.Header>
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                            {errorMessage}
                        </Alert>
                    )}
                    <Modal.Body className='addpeople-section assign-agent-popup'>
                    <div className="row gx-3 form-section">
                            <div className="field col-md-12 profile-input doller-value" >
                                <label htmlFor="inputEmail4" className="form-label">Referral Fee Received <span className="reuired-star">*</span></label>
                                <span className="p-input-icon-left custom-input-left">

                                    <i className="pi pi-dollar"></i>
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control ps-4",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "referral_fee_received"
                                                    ),
                                            }
                                        )}
                                        name="referral_fee_received"
                                        placeholder=""
                                        value={
                                            formik.values.referral_fee_received
                                        }
                                        onChange={(e) => {formik.handleChange(e)}}
                                        
                                    /></span>
                                {getFormErrorMessage('referral_fee_received')}

                                
                            </div>

                            

                            

                        </div>

                        
                        
                    </Modal.Body>
                    <Modal.Footer className='add-people-footer'>
                        <button type="button" className='btn btn-cancel btn-500' variant="secondary" onClick={() => { modalClose(); props.changeStatusReferralFeeReceivedFormForm(); }} disabled={loading}>
                            Close
                        </button>
                        <button type="submit" className='btn btn-submit btn-500 btn-submit-loader' variant="primary" disabled={loading}>
                        Save
                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                        </button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ReferralFeeReceivedForm;