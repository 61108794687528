import React, { useReducer, useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import axios from "../../../axiosCall.js";
import useAuth from "./../../../hooks/useAuth";
import { useStep } from "react-hooks-helper";
import { InputMask } from 'primereact';
import { Card } from "react-bootstrap";

import { Alert, CircularProgress, Typography } from "@mui/material";
import { classNames } from 'primereact/utils';
import { Dropdown, InputText, RadioButton, InputTextarea } from "primereact";
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';

const AffinityGroupPreferenceData = [
    {
      id: 1,
      name: 'BAM'
    }
  ];


const AssignedAgentModal = (props) => {
    const [show, setShow] = useState(props.showAssignedAgentModal);
    const navigate = useNavigate();
    

    const peopleModalClose = () => setShow(false);
    const { user, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [referral_type, setReferralType] = useState();
    const [refrReferralFeePercentageError, setRefrReferralFeePercentageError] = useState(false);
   
    const [uplineUserName, setUplineUserName] = useState('');
    const [dbLanguageData, setDbLanguageData] = useState([]);
    const [isEmailUnique, setIsEmailUnique] = useState(true);
    const [languageOtherShow, setLanguageOtherShow] =
        useState(false);
    const [uplineCheckboxIsShow, setUplineCheckboxIsShow] = useState(false);
    const [dbBrokerageListData, setDbBrokerageListData] = useState([]);
    const fetchReferralDetailData = async () => {
        let formData = new FormData();
        formData.append("referral_id", props.editReferralId);
        console.log(props.editReferralId);

        try {
            const response = await axios.post("/get-referral-data", formData);
            const { Status, Result } = response.data;

            if (Status === true) {
                
                formik.setFieldValue("agent_upline_user_id", Result.referral.agent_id)
                formik.setFieldValue("agent_first_name", Result.referral.agent_first_name)
                formik.setFieldValue("agent_last_name", Result.referral.agent_last_name)
                formik.setFieldValue("agent_email", Result.referral.agent_email)
                formik.setFieldValue("agent_phone", Result.referral.agent_phone)
                formik.setFieldValue("agent_brokerage_name", Result.referral.agent_brokerage_name)
                formik.setFieldValue("agent_broker_name", Result.referral.agent_broker_name)
                formik.setFieldValue("agent_broker_phone", Result.referral.agent_broker_phone)
                formik.setFieldValue("agent_broker_email", Result.referral.agent_broker_email)
                formik.setFieldValue("language_other", Result.referral.language_other)
                formik.setFieldValue("agent_assign_other_requirement", Result.referral.agent_assign_other_requirement)
                formik.setFieldValue("language_id", Result.referral.language_id)
                formik.setFieldValue("agent_language_master_id", Result.referral.agent_language_master_id)
                formik.setFieldValue("language_other", Result.referral.language_other)
                formik.setFieldValue("brokerage_list_id", Result.referral.brokerage_list_id)
                formik.setFieldValue("affinity_group_preference_id", Result.referral.affinity_group_preference_id)
                
               

                if (Result.referral.language_other !== "") {
                    setLanguageOtherShow(true);
                } else {
                    setLanguageOtherShow(false);
                }
                //alert(JSON.stringify(Result.referral.agent_language_master_id))
                if (Result.referral.agent_language_master_id)
                    formik.setFieldValue("language_id", Result.referral.agent_language_master_id)
               
                
                if (Result.referral.brokerage_list_id)
                    formik.setFieldValue("brokerage_list_id", Result.referral.brokerage_list_id)
                
                if (Result.referral.affinity_group_preference_id)
                    formik.setFieldValue("affinity_group_preference_id", Result.referral.affinity_group_preference_id)

                formik.setFieldValue("agent_broker_email", Result.referral.agent_broker_email)
                formik.setFieldValue("agent_email", Result.referral.agent_email)
                formik.setFieldValue("agent_assign_other_requirement", Result.referral.agent_assign_other_requirement)
                

                let is_buyer_referral_upline_agent = "yes";
                let is_agent_assign_to_referral = "no";
                let agent_upline_user_id = "";
                let agentDetail = false;
                let assignAgentDetail = false;

                if (Result.referral.agent_type == "upline_user") {
                    is_buyer_referral_upline_agent = "yes";
                    formik.setFieldValue("is_buyer_referral_upline_agent", "yes")
                    agent_upline_user_id = Result.referral.agent_id;
                } else {
                    is_buyer_referral_upline_agent = "no";
                    formik.setFieldValue("is_buyer_referral_upline_agent", "no")
                    if (Result.referral.agent_id) {
                        is_agent_assign_to_referral = "yes";
                        formik.setFieldValue("is_agent_assign_to_referral", "yes")
                        agentDetail = true;

                        formik.setFieldValue("referral_fee_user_receive", (Result.referral.referral_fee_user_receive) ? Result.referral.referral_fee_user_receive : '25.00')

                        formik.setFieldValue("referral_platform_fee", (Result.referral.referral_platform_fee) ? Result.referral.referral_platform_fee : '199.00')

                        formik.setFieldValue("referral_fee_charged_to_receiving_agent", (Result.referral.referral_fee_charged_to_receiving_agent) ? Result.referral.referral_fee_charged_to_receiving_agent : '25.00% + $199.00')

                        formik.setFieldValue("referral_fee_due_to_referring_agent",(Result.referral.referral_fee_due_to_referring_agent) ? Result.referral.referral_fee_due_to_referring_agent : '25.00%')

                        formik.setFieldValue("referring_agent_referral_fee_action", (Result.referral.referring_agent_referral_fee_action) ? Result.referral.referring_agent_referral_fee_action : 'yes')
                    } else {
                        is_agent_assign_to_referral = "no";
                        assignAgentDetail = true;
                        formik.setFieldValue("is_agent_assign_to_referral", "no")
                    }
                    //alert(is_agent_assign_to_referral)
                }
                
            } else {
                navigate("/dashboard");
            }
        } catch (err) {
            // if (err.response.status && err.response.status == 401) {
            //     logout();
            //     navigate("/login");
            // }
        }
    };

    useEffect(() => {
        if (user !== null && props.editReferralId) {
            fetchReferralDetailData();
            console.log(referral_type);
        } 

        if (user !== null && props.editReferralId) {

            formik.setFieldValue("users_id", user.id)
            setReferralType(referral_type);
            formik.setFieldValue("referral_type", referral_type)
            if (user.upline_user_name && user.type === 'agent') {
                setUplineCheckboxIsShow(true);
                setUplineUserName(user.upline_user_name);
                if (formik.values.is_buyer_referral_upline_agent === 'yes') {
                    formik.setFieldValue("agent_upline_user_id", user.users_id)
                    formik.setFieldValue("agent_first_name", user.upline_user_first_name)
                    formik.setFieldValue("agent_last_name", user.upline_user_last_name)
                    formik.setFieldValue("agent_email", user.upline_user_email)
                    formik.setFieldValue("agent_phone", user.upline_user_phone)
                }
            }
            else {
                if (formik.values.is_buyer_referral_upline_agent === 'yes') {
                    formik.setFieldValue("is_buyer_referral_upline_agent", 'no')
                    setUplineCheckboxIsShow(false);
                }
            }
            fetchLanguageData();
            fetchBrokerageRoleData();
        }

    }, [user]);

    

    const handleSubmit = async (data) => {
        //alert(data)
        setLoading(true);
        setErrorMessage('');
        try {
            const response = await axios.post('/referral-agent-data-update', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {
                setLoading(false);
                props.changeStatuClosedhandleModalAssignedAgent();
                peopleModalClose();
            }
        } catch (err) {

            setLoading(false);
            window.scrollTo(0, 0);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }

    };
    const handleReferralFeeMode = (mode) => {
        formik.setFieldValue("referring_agent_referral_fee_action", mode)
      }
    const formik = useFormik({
        initialValues: {
            users_id: props.loginUserId,
            referral_id: props.requestReferralId,
            is_buyer_referral_upline_agent: "yes",
            is_agent_assign_to_referral: "no",
            agent_upline_user_id: "",
            agent_first_name: "",
            agent_last_name: "",
            agent_email: "",
            agent_phone: "",
            agent_brokerage_name: "",
            agent_broker_name: "",
            agent_broker_phone: "",
            agent_broker_email: "",
            brokerage_list_id: "",
            affinity_group_preference_id:"",
            language_id: "",
            language_other: "",
            agent_assign_other_requirement: "",
            host_name: window.location.origin,

            referral_fee_user_receive: "",
            referral_platform_fee: "",
            referral_fee_charged_to_receiving_agent: "",
            referral_fee_due_to_referring_agent: "",
            referring_agent_referral_fee_action: "                                                                                    ",
        },
        validate: (data) => {
            let errors = {};
            if (data.is_buyer_referral_upline_agent === 'no') {
                if (data.is_agent_assign_to_referral === 'yes') {
                    if (data.agent_first_name == null || data.agent_first_name.trim() === '') {
                        errors.agent_first_name = 'First name is required.';
                    }
                    else if (data.agent_first_name.length > 50)
                    {
                        errors.agent_first_name = 'Max characters: 50';
                    }

                    if (data.agent_last_name == null || data.agent_last_name.trim() === '') {
                        errors.agent_last_name = 'Last name is required.';
                    }
                    else if (data.agent_last_name.length > 50)
                    {
                        errors.agent_last_name = 'Max characters: 50';
                    }

                    if (!data.agent_email) {
                        setIsEmailUnique(true);
                        errors.agent_email = 'Email is required.';
                    }
                    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.agent_email)) {
                        setIsEmailUnique(true);
                        errors.agent_email = 'Invalid email address. e.g. example@email.com';
                    } else if ((!isEmailUnique) && (data.agent_email)) {
                        errors.agent_email =
                            "Email address already exists.";
                    }

                    if (!data.agent_phone) {
                        errors.agent_phone = 'Phone is required.';
                    }

                    if (data.agent_brokerage_name == null || data.agent_brokerage_name.trim() === '') {
                        errors.agent_brokerage_name = 'Brokerage name is required.';
                    }

                    if((props.referraDetail.referral_created_users_type === 'ambassador'))
                    {
                      if (!data.referral_fee_user_receive) {
                        errors.referral_fee_user_receive = 'Referral fee user recceive is required.';
                      } 
                      else
                      {
                          if (data.referral_fee_user_receive)
                          {
                              if (!isPositiveFloat(data.referral_fee_user_receive)) {
                                  errors.other_commission = "Invalid referral fee user recceive. e.g. 5";
                              }
                              else if(parseFloat(data.referral_fee_user_receive) > 50)
                              {
                                  errors.referral_fee_user_receive = "Max: 50 percentage";
                              }
                          }
                      }
                    }
                    // if (data.agent_broker_name == null || data.agent_broker_name.trim() === '') {
                    //     errors.agent_broker_name = 'Broker name is required.';
                    // }

                    // if (!data.agent_broker_phone) {
                    //     errors.agent_broker_phone = 'Broker phone is required.';
                    // }

                    if (data.agent_broker_email) {
                        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.agent_broker_email)) {
                            errors.agent_broker_email = 'Invalid email address. e.g. example@email.com';
                        }
                    }
                }
                else {
                   if(data.language_id)
                   {
                        if (data.language_id.name === 'Other' || data.language_id.name === 'other') {
                            if (data.language_other == null || data.language_other.trim() === '') {
                                errors.language_other = "Other language is required.";
                            }
                            if (data.language_other) {
                                if (data.language_other.length > 50)
                                {
                                    errors.language_other = 'Max characters: 50';
                                }
                            }
                            
                        }
                   }
                    if(data.agent_assign_other_requirement)
                    {
                        if (data.agent_assign_other_requirement.length > 200)
                        {
                            errors.agent_assign_other_requirement = 'Max characters: 200';
                        }
                    }
                }
            }
            //console.log('errors '+errors)
            return errors;
        },
        onSubmit: (data) => {
          // alert(1)
            handleSubmit(data);

        }
    });
    const handleLanguage = (e) => {
       // alert(JSON.stringify(e.target.value))
        if(JSON.stringify(e.target.value) === undefined){
            formik.setFieldValue("language_other", "");
            setLanguageOtherShow(false);
        }
        else
        {
            formik.setFieldValue("language_other", "");
            if (e.target.value.name === "Other" || e.target.value.name === "other") {
                setLanguageOtherShow(true);
            } else {
                setLanguageOtherShow(false);
            }
        }
        
    };
    const fetchLanguageData = async () => {
        try {
            const response = await axios.post("/get-all-language-data");
            const { Status, Result } = response.data;

            if (Status === true) {
                setDbLanguageData(Result);
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };
    const fetchBrokerageRoleData = async () => {
        try {
            const response = await axios.post("/get-all-Brokerage-list-data");
            const { Status,  Result } = response.data;
  
            if (Status === true) {
              setDbBrokerageListData(Result);
            }
        } catch (err) {
          if(err.response.status && err.response.status==401){
            logout();
            navigate('/login');
          }
        }
    };
    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }
  
        return <span>{props.placeholder}</span>;
    };
    const optionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {

        // if (state.language_id.name === 'Other' || state.language_id.name === 'other') {
        //     setLanguageOtherShow(true);
        // }
        // else {
        //     setLanguageOtherShow(false);
        // }



    }, [user]);

    const handleReferralUplineAgentMode = (mode) => {
        formik.setFieldValue("is_buyer_referral_upline_agent", mode)
        formik.setFieldValue("is_agent_assign_to_referral", 'no')
        resetAgentFields();
        if (mode === 'yes') {
            if (user.upline_user_name) {
                setUplineUserName(user.upline_user_name);
                formik.setFieldValue("agent_upline_user_id", user.users_id)
                formik.setFieldValue("agent_first_name", user.upline_user_first_name)
                formik.setFieldValue("agent_last_name", user.upline_user_last_name)
                formik.setFieldValue("agent_email", user.upline_user_email)
                formik.setFieldValue("agent_phone", user.upline_user_phone)
            }
        }

    }
    const handleReferralAgentMode = (mode) => {
        formik.setFieldValue("is_agent_assign_to_referral", mode)
        resetAgentFields();

        if (mode === 'yes' && props.referraDetail.referral_created_users_type === 'ambassador') {
            formik.setFieldValue("referral_fee_user_receive", parseFloat(25.00))
            formik.setFieldValue("referral_platform_fee", parseFloat(199.00))
            formik.setFieldValue("referral_fee_charged_to_receiving_agent", '25% + $199.00')
            formik.setFieldValue("referral_fee_due_to_referring_agent", parseFloat(25.00)+'%')
            formik.setFieldValue("referring_agent_referral_fee_action", 'yes')
        }
    }
    const resetAgentFields = () => {
        formik.setFieldValue("agent_upline_user_id", '')
        formik.setFieldValue("agent_first_name", '')
        formik.setFieldValue("agent_last_name", '')
        formik.setFieldValue("agent_email", '')
        formik.setFieldValue("agent_phone", '')
        formik.setFieldValue("agent_brokerage_name", '')
        formik.setFieldValue("agent_broker_name", '')
        formik.setFieldValue("agent_broker_phone", '')
        formik.setFieldValue("agent_broker_email", '')
        formik.setFieldValue("brokerage_list_id", "")
        formik.setFieldValue("affinity_group_preference_id", "")
        
        formik.setFieldValue("language_id", "")
        formik.setFieldValue("language_other", '')
        formik.setFieldError("agent_broker_email", '')
        formik.setFieldError("agent_email", '')
        formik.setFieldError("agent_assign_other_requirement", '')
        formik.setFieldValue("referral_fee_user_receive", '')
        formik.setFieldValue("referral_platform_fee", '')
        formik.setFieldValue("referral_fee_charged_to_receiving_agent", '')
        formik.setFieldValue("referral_fee_due_to_referring_agent", '')
        formik.setFieldValue("referring_agent_referral_fee_action", '')

    }
    const handleRefrReferralFeeCalculation = (e,mode) => {
   
        setRefrReferralFeePercentageError('');
        let refrReferralFeePercentage = '';
        let referring_agent_referral_fee_action = '';
        if(mode === 'referring_agent_referral_fee_action')
        {
          refrReferralFeePercentage = formik.values.referral_fee_user_receive;
          referring_agent_referral_fee_action = e.target.value;
        }
        else
        {
          refrReferralFeePercentage = e.target.value;
          referring_agent_referral_fee_action = formik.values.referring_agent_referral_fee_action;
        }
        let referral_platform_fee = formik.values.referral_platform_fee;
        
        //alert(referring_agent_referral_fee_action)
        let isValidFeePercentage = true;
        if(refrReferralFeePercentage) {
            if (!isPositiveFloat(refrReferralFeePercentage)) {
                isValidFeePercentage = false;
                setRefrReferralFeePercentageError("Invalid refr referral fee percentage. e.g. 5")
            }
            else if(parseFloat(refrReferralFeePercentage) > 50)
            {
                isValidFeePercentage = false;
                setRefrReferralFeePercentageError("Max: 50 percentage")
            }
        }
        
    
        if((referral_platform_fee) && (refrReferralFeePercentage) && (isValidFeePercentage))
        {
            let calulation2 = '';
            if(referring_agent_referral_fee_action === 'no')
            {
              calulation2 = refrReferralFeePercentage+'% - $'+referral_platform_fee;
              formik.setFieldValue("referral_fee_charged_to_receiving_agent", refrReferralFeePercentage+'%')
              formik.setFieldValue("referral_fee_due_to_referring_agent", calulation2)
            }
            else
            {
              calulation2 = refrReferralFeePercentage+'% + $'+referral_platform_fee;
              formik.setFieldValue("referral_fee_charged_to_receiving_agent", calulation2)
              formik.setFieldValue("referral_fee_due_to_referring_agent", refrReferralFeePercentage+'%')
            }
            
        }
        else
        {
            formik.setFieldValue("referral_fee_charged_to_receiving_agent", "")
            formik.setFieldValue("referral_fee_due_to_referring_agent", "")
        }
      }
      const isPositiveFloat = (s) => {
        return !isNaN(s) && Number(s) > 0;
      }
    return (
        <>

            <Modal enforceFocus={false} className='add-people-popup' show={show} onHide={peopleModalClose} backdrop="static" >

                    <Modal.Header className='add-people-header'>
                        <Modal.Title>Update Assigned Agent </Modal.Title>
                    </Modal.Header>
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                            {errorMessage}
                        </Alert>
                    )}
                    <form onSubmit={formik.handleSubmit}>
                    <Modal.Body className='addpeople-section'>
                       
                       
                        <Card.Body>

                            <div className='referral-fields'>
                                <h2 className="referall-title">Agent Selection</h2>
                                {
                                    (uplineCheckboxIsShow) && (<p className='referral-main-description'>Would you like to assign this Buyer Referral to your upline agent? ({(uplineUserName) ? uplineUserName : 'N/A'}) </p>)
                                }
                                {
                                    (uplineCheckboxIsShow) && (<div className='referral-agent-fields'>
                                        <div className="agent-field">
                                            <RadioButton inputId="agent1" onChange={() => { handleReferralUplineAgentMode('yes') }} name="is_buyer_referral_upline_agent" value="yes" checked={formik.values.is_buyer_referral_upline_agent === 'yes'} />
                                            <label htmlFor="agent1">yes</label>
                                        </div>
                                        <div className="agent-field">
                                            <RadioButton inputId="agent2" onChange={() => { handleReferralUplineAgentMode('no') }} name="is_buyer_referral_upline_agent" value="no" checked={formik.values.is_buyer_referral_upline_agent === 'no'} />
                                            <label className="no-field" htmlFor="agent2">No</label>
                                        </div>
                                    </div>)
                                }



                                {
                                    (formik.values.is_buyer_referral_upline_agent === 'no') && (<><p className='referral-main-description' >Do you have an agent that you would like to assign this referral to? </p>
                                        <div className='referral-agent-fields' >
                                            <div className="agent-field d-flex align-items-baseline">
                                                <RadioButton inputId="" onChange={() => { handleReferralAgentMode('yes') }} name="is_agent_assign_to_referral" value="yes" checked={formik.values.is_agent_assign_to_referral === 'yes'} />
                                                <label  htmlFor="">yes</label>
                                            </div>
                                            <div className="agent-field label-desc">
                                                <RadioButton inputId="" onChange={() => { handleReferralAgentMode('no') }} name="is_agent_assign_to_referral" value="no" checked={formik.values.is_agent_assign_to_referral === 'no'} />
                                                {/* <label htmlFor="">No<span className="ms-2">-</span> </label> <p className="ms-2">I would like REFR to assign this referral to the best fit match in the market</p> */}
                                                <label className="no-field" htmlFor="">No<span className="ms-1 yes-field">-I would like REFR to assign this referral to the best fit match in the market</span> </label>
                                            </div>
                                        </div></>)
                                }

                                {
                                    (formik.values.is_buyer_referral_upline_agent === 'no') && (formik.values.is_agent_assign_to_referral === 'yes') && (<><div className="hr-line">
                                        <hr />
                                    </div>
                                        <p className='referral-main-description contact-title'>Contact Information for the Agent you would like to assign your referral to</p>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label htmlFor="">First Name <span className="reuired-star">*</span></label>
                                                    <InputText autoComplete="off" className={classNames("form-control", { 'p-invalid': isFormFieldValid('agent_first_name') })} placeholder="Enter here" name="agent_first_name" value={formik.values.agent_first_name} onChange={formik.handleChange} />

                                                    {getFormErrorMessage('agent_first_name')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label htmlFor="">Last Name <span className="reuired-star">*</span></label>
                                                    <InputText autoComplete="off" className={classNames("form-control", { 'p-invalid': isFormFieldValid('agent_last_name') })} name="agent_last_name" placeholder="Enter here" value={formik.values.agent_last_name} onChange={formik.handleChange} />
                                                    {getFormErrorMessage('agent_last_name')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label htmlFor="">Email <span className="reuired-star">*</span></label>
                                                    {/* onBlur={(e) => {handleUnqiueValidation(e)}} */}
                                                    <InputText autoComplete="off" className={classNames("form-control", { 'p-invalid': isFormFieldValid('agent_email') })} name="agent_email" placeholder="Enter here" value={formik.values.agent_email} onChange={formik.handleChange} />
                                                    {getFormErrorMessage('agent_email')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label htmlFor="">Phone <span className="reuired-star">*</span></label>
                                                    <InputMask autoComplete="off" className={classNames("form-control", { 'p-invalid': isFormFieldValid('agent_phone') })} name="agent_phone" mask="(999) 999-9999" placeholder="(999) 999-9999" value={formik.values.agent_phone} onChange={formik.handleChange} />
                                                    {getFormErrorMessage('agent_phone')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label htmlFor="">Brokerage Name <span className="reuired-star">*</span></label>
                                                    <InputText autoComplete="off" className={classNames("form-control", { 'p-invalid': isFormFieldValid('agent_brokerage_name') })} name="agent_brokerage_name" placeholder="Enter here" value={formik.values.agent_brokerage_name} onChange={formik.handleChange} />
                                                    {getFormErrorMessage('agent_brokerage_name')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label htmlFor="">Broker of Record Name </label>
                                                    <InputText autoComplete="off" className={classNames("form-control", { 'p-invalid': isFormFieldValid('agent_broker_name') })} name="agent_broker_name" placeholder="Enter here" value={formik.values.agent_broker_name} onChange={formik.handleChange} />
                                                    {getFormErrorMessage('agent_broker_name')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label htmlFor="">Broker of Record Email </label>
                                                    <InputText autoComplete="off" className={classNames("form-control", { 'p-invalid': isFormFieldValid('agent_broker_email') })} name="agent_broker_email" placeholder="Enter here" value={formik.values.agent_broker_email} onChange={formik.handleChange} />
                                                    {getFormErrorMessage('agent_broker_email')}

                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label htmlFor="">Broker of Record Phone </label>
                                                    <InputMask autoComplete="off" className={classNames("form-control", { 'p-invalid': isFormFieldValid('agent_broker_phone') })} name="agent_broker_phone" mask="(999) 999-9999" placeholder="(999) 999-9999" value={formik.values.agent_broker_phone} onChange={formik.handleChange} />
                                                    {getFormErrorMessage('agent_broker_phone')}
                                                </div>
                                            </div>
                                        </div></>)
                                }
                                {
              (props.referraDetail.referral_created_users_type === 'ambassador') && (formik.values.is_buyer_referral_upline_agent === 'no') && (formik.values.is_agent_assign_to_referral === 'yes') && (
              <><div className="hr-line">
              <hr />
            </div>
            <p className='referral-main-description contact-title'>Referral Fee Calculation</p>
            <div className="row popup_agent_fileds">
              <div className="col-lg-12 profile-input doller-value count_input">
                <div className="field ">
                  <label htmlFor="">Referral Fee You Would Like to Receive <span className="reuired-star">*</span></label>
                  <InputText autoComplete="off" className={classNames("form-control", { 'p-invalid': isFormFieldValid('referral_fee_user_receive') })}  placeholder="Enter here" name="referral_fee_user_receive" value={formik.values.referral_fee_user_receive} onChange={formik.handleChange} 
                  onKeyUp={(e) => {handleRefrReferralFeeCalculation(e,'referral_fee_user_receive')}}
                  />
                  
                  {getFormErrorMessage('referral_fee_user_receive')}
                      {(refrReferralFeePercentageError) && (!getFormErrorMessage('referral_fee_user_receive')) && (
                      <small className="p-error">{refrReferralFeePercentageError}</small>
                  )}
                   <span className="custom-input-right"><i className="pi pi-percentage"></i></span>
                </div>
              </div>
              <div className="col-lg-12 profile-input doller-value count_input">
                <div className="field">
                  <label htmlFor="">REFR Platform Fee <span className="reuired-star">*</span></label>
                  
                  <InputText autoComplete="off" className={classNames("form-control", { 'p-invalid': isFormFieldValid('referral_platform_fee') })} name="referral_platform_fee" placeholder="Enter here" value={formik.values.referral_platform_fee} onChange={formik.handleChange} disabled={true}/>
                  
                  {getFormErrorMessage('referral_platform_fee')}
                   <span className="custom-input-left">
                  <i className="pi pi-dollar"></i></span>
                </div>
              </div>
              <div className="col-lg-12 count_input">
                <div className="field">
                  <label htmlFor="">Total Referral Fee Charged to Receiving Agent <span className="reuired-star">*</span></label>
                 
                  <InputText autoComplete="off" className={classNames("form-control", { 'p-invalid': isFormFieldValid('referral_fee_charged_to_receiving_agent') })} name="referral_fee_charged_to_receiving_agent" placeholder="Enter here" value={formik.values.referral_fee_charged_to_receiving_agent} onChange={formik.handleChange} disabled={true}/>
                  {getFormErrorMessage('referral_fee_charged_to_receiving_agent')}
                  
                </div>
              </div>
              <div className="col-lg-12 count_input">
                <div className="field">
                  <label htmlFor="">Total Referral Fee Due to Referring Agent <span className="reuired-star">*</span></label>
                  <InputText autoComplete="off" className={classNames("form-control", { 'p-invalid': isFormFieldValid('referral_fee_due_to_referring_agent') })} name="referral_fee_due_to_referring_agent" placeholder="Enter here" value={formik.values.referral_fee_due_to_referring_agent} onChange={formik.handleChange} disabled={true}/>
                  {getFormErrorMessage('referral_fee_due_to_referring_agent')}
                  
                </div>
              </div>
              <div className="col-lg-12">
                <div className="field">
                <><p className='referral-main-description' >Please confirm </p>
                  <div className='referral-agent-fields confirm-field last_radio' >
                    <div className="agent-field d-flex align-items-baseline">
                      <RadioButton inputId=""  onChange={(e) => { handleReferralFeeMode('yes');handleRefrReferralFeeCalculation(e,'referring_agent_referral_fee_action') }} 
                      
                     
                      name="referring_agent_referral_fee_action" value="yes" checked={formik.values.referring_agent_referral_fee_action === 'yes'}/>
                      <label htmlFor="">Yes, <span className="ms-2">I would like to pass the $199.00 platform fee onto the receiving agent </span></label>
                    </div>
                    <div className="agent-field label-desc">
                      <RadioButton inputId=""  onChange={(e) => { handleReferralFeeMode('no');handleRefrReferralFeeCalculation(e,'referring_agent_referral_fee_action') }} name="referring_agent_referral_fee_action" value="no" checked={formik.values.referring_agent_referral_fee_action === 'no'} />
                      <label htmlFor="">No,<span className="ms-2">I want REFR to take the $199.00 fee from my portion of the referral fee</span> </label>
                    </div>
                  </div></>
                </div>
              </div>
              
            </div></>)
          }
                                {
                                    (formik.values.is_buyer_referral_upline_agent === 'no') && (formik.values.is_agent_assign_to_referral === 'no') && (<div className="row mt-4">
                                        
                                        <div className="col-lg-6">
                                            <div className="field">
                                            <label htmlFor="" className="form-label">Brand Preference </label>
                                            <Dropdown  className={classNames("form-select", { 'p-invalid': isFormFieldValid('brokerage_list_id') })} 
                                            options={dbBrokerageListData} 
                                            defaultValue={formik.values.brokerage_list_id} 
                                            optionLabel="name" placeholder="Select One" 
                                            name="brokerage_list_id" 
                                            value={formik.values.brokerage_list_id}
                                            onChange={formik.handleChange} 
                                            filter
                                            filterBy="name"
                                            showClear
                                            valueTemplate={selectedTemplate}
                                            itemTemplate={optionTemplate}
                                            />
                                            {getFormErrorMessage('brokerage_list_id')}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="field">
                                            <label htmlFor="" className="form-label">Affinity Group Preference </label>
                                            <Dropdown  className={classNames("form-select", { 'p-invalid': isFormFieldValid('affinity_group_preference_id') })} 
                                            options={AffinityGroupPreferenceData} 
                                            defaultValue={formik.values.affinity_group_preference_id} 
                                            optionLabel="name" placeholder="Select One" 
                                            name="affinity_group_preference_id" 
                                            value={formik.values.affinity_group_preference_id}
                                            onChange={formik.handleChange} 
                                            filter
                                            showClear
                                            filterBy="name"
                                            valueTemplate={selectedTemplate}
                                            itemTemplate={optionTemplate}
                                            />
                                            {getFormErrorMessage('affinity_group_preference_id')}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="field">
                                                <label htmlFor="" className="form-label">Language Fluency </label>
                                                <Dropdown className={classNames("form-select", { 'p-invalid': isFormFieldValid('language_id') })} defaultValue={formik.values.language_id} options={dbLanguageData} placeholder="Select One" name="language_id" value={formik.values.language_id}
                                                   filter
                                                   showClear
                                                   filterBy="name"
                                                   valueTemplate={selectedTemplate}
                                                   itemTemplate={optionTemplate}  onChange={(e) => { formik.handleChange(e); handleLanguage(e) }} optionLabel="name" />
                                                {getFormErrorMessage('language_id')}
                                            </div>
                                        </div>
                                        {languageOtherShow && (
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label
                                                        htmlFor="FirstInput"
                                                        className="form-label"
                                                    >
                                                        Other Language  <span className="reuired-star">*</span>
                                                    </label>
                                                    <InputText
                                                        autoComplete="off"
                                                        className={classNames(
                                                            "form-control",
                                                            {
                                                                "p-invalid":
                                                                    isFormFieldValid(
                                                                        "language_other"
                                                                    ),
                                                            }
                                                        )}
                                                        placeholder="Enter here"
                                                        name="language_other"
                                                        value={
                                                            formik.values
                                                                .language_other
                                                        }
                                                        onChange={formik.handleChange}
                                                    />
                                                    {getFormErrorMessage(
                                                        "language_other"
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>)
                                }
                                {
                                    (formik.values.is_buyer_referral_upline_agent === 'no') && (formik.values.is_agent_assign_to_referral === 'no') && (<div className="row">
                                        <div className="col-lg-12">
                                            <div className="field">
                                                <label htmlFor="" className="form-label">Any other special requirements of the assigned agent</label>
                                                <InputText
                                                    autoComplete="off"
                                                    className={classNames(
                                                        "form-control",
                                                        {
                                                            "p-invalid":
                                                                isFormFieldValid(
                                                                    "agent_assign_other_requirement"
                                                                ),
                                                        }
                                                    )}
                                                    placeholder="Enter here"
                                                    name="agent_assign_other_requirement"
                                                    value={
                                                        formik.values
                                                            .agent_assign_other_requirement
                                                    }
                                                    onChange={formik.handleChange}
                                                />
                                                {getFormErrorMessage(
                                                    "agent_assign_other_requirement"
                                                )}
                                            </div>
                                        </div>
                                    </div>)
                                }
                            </div>
                        </Card.Body>
                        
                      

                    </Modal.Body>
                    <Modal.Footer className='add-people-footer'>
                    <div className="text-end">
                            <Button
                            className="btn btn-cancel rf-cancel-btn me-3"
                            variant="contained"
                            color="primary"
                            onClick={() => { peopleModalClose(); props.changeStatuClosedhandleModalAssignedAgent(); }}
                            disabled={loading}
                            >
                            Close
                            </Button>
                            <Button
                            className="btn btn-submit"
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loading}
                            >
                            Update
                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                            </Button>
                        </div>

                    </Modal.Footer>
                    </form>
            </Modal>
        </>
    )
}

export default AssignedAgentModal;
