import React, { useReducer, createContext } from "react";

export const SubmitAReferralContext = createContext([]);

const initialState = {
    users_id: '',
    edit_referral_id: '',
    referral_type: 'buyer_referral',
    buyer_or_seller:'',
    is_buyer_referral_upline_agent: 'yes',
    is_agent_assign_to_referral: 'no',
    agent_upline_user_id: '',
    agent_first_name: '',
    agent_last_name: '',
    agent_email: '',
    agent_phone: '',
    agent_brokerage_name: '',
    agent_broker_name: '',
    agent_broker_phone: '',
    agent_broker_email: '',
    // gender: '',
    brokerage_list_id: '',
    affinity_group_preference_id:'',
    language_id: '',
    language_other: '',
    agent_assign_other_requirement: '',
    referral_fee_user_receive:'',
    referral_platform_fee:'',
    referral_fee_charged_to_receiving_agent:'',
    referral_fee_due_to_referring_agent:'',
    referring_agent_referral_fee_action:'',
    client_type: "new_client",
    client_first_name: "",
    client_last_name: "",
    client_email: "",
    client_phone: "",
    client_address:"",
    client_reference_other: "",
    other_interested_client: "",
    client_reference_id:"",
    existing_client_id:"",
    property_area_multi_info: [
      {
          buy_city: "",
          buy_zipcode: "",
      }
    ],
    property_error_multi_info:[
      {
        buy_city_error: '',
        buy_zipcode_error: '',
      }
    ],
    property_master_id:'',
    seller_property_master_id:'',
    buy_state_id:'',
    buyer_buy_timeframe_id: "",
    clients_looking_to_purcahse_master_id:"",
    seller_buy_timeframe_id: "",
    buyer_min_price_range_id: "",
    buyer_max_price_range_id: "",
    seller_estimated_price: "",
    buyer_comments: "",
    seller_comments: "",
    seller_address: "",
    seller_state_id: "",
    seller_city: "",
    seller_zipcode: "",
    host_name: window.location.origin,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SAVE_RERERRAL_TYPE":
      return {
        ...state,
        edit_referral_id: action.edit_referral_id,
        referral_type: action.referral_type,
        buyer_or_seller:action.buyer_or_seller
      };
    case "SAVE_AGENT_INFO":
      return {
        ...state,
        users_id: action.users_id,
        is_buyer_referral_upline_agent: action.is_buyer_referral_upline_agent,
        is_agent_assign_to_referral: action.is_agent_assign_to_referral,
        agent_upline_user_id: action.agent_upline_user_id,
        agent_first_name: action.agent_first_name,
        agent_last_name: action.agent_last_name,
        agent_email: action.agent_email,
        agent_phone: action.agent_phone,
        agent_brokerage_name: action.agent_brokerage_name,
        agent_broker_name: action.agent_broker_name,
        agent_broker_phone: action.agent_broker_phone,
        agent_broker_email: action.agent_broker_email,
        // gender: action.gender,
        brokerage_list_id: action.brokerage_list_id,
        affinity_group_preference_id: action.affinity_group_preference_id,
        language_id: action.language_id,
        language_other: action.language_other,
        agent_assign_other_requirement: action.agent_assign_other_requirement,
        referral_fee_user_receive:action.referral_fee_user_receive,
        referral_platform_fee:action.referral_platform_fee,
        referral_fee_charged_to_receiving_agent:action.referral_fee_charged_to_receiving_agent,
        referral_fee_due_to_referring_agent:action.referral_fee_due_to_referring_agent,
        referring_agent_referral_fee_action:action.referring_agent_referral_fee_action,
        
      };
      case "SAVE_CLIENT_INFO":
      return {
        ...state,
        client_type: action.client_type,
        client_first_name: action.client_first_name,
        client_last_name: action.client_last_name,
        client_email: action.client_email,
        client_phone: action.client_phone,
        client_address: action.client_address,
        existing_client_id:action.existing_client_id,
        client_reference_other: action.client_reference_other,
        other_interested_client: action.other_interested_client,
        client_reference_id: action.client_reference_id,
      };
      case "SAVE_PROPERTY_INFO":
      return {
        ...state,
        property_area_multi_info: action.property_area_multi_info,
        property_error_multi_info:action.property_error_multi_info,
        buyer_buy_timeframe_id: action.buyer_buy_timeframe_id,
        clients_looking_to_purcahse_master_id: action.clients_looking_to_purcahse_master_id,
        seller_buy_timeframe_id: action.seller_buy_timeframe_id,
        buyer_min_price_range_id: action.buyer_min_price_range_id,
        buyer_max_price_range_id: action.buyer_max_price_range_id,
        seller_estimated_price: action.seller_estimated_price,
        buyer_comments: action.buyer_comments,
        seller_comments: action.seller_comments,
        seller_address: action.seller_address,
        seller_state_id: action.seller_state_id,
        seller_city: action.seller_city,
        seller_zipcode: action.seller_zipcode,
        property_master_id:action.property_master_id,
        seller_property_master_id:action.seller_property_master_id,
        
        buy_state_id:action.buy_state_id,
       };
      case "REVIEW_YOUR_REFERRAL":
        return {
          ...state,
          property_area_multi_info: action.property_area_multi_info,
        };
    default:
      return {
        ...state
      };
  }
};

export const SubmitAReferralContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <SubmitAReferralContext.Provider value={[state, dispatch]}>
      {props.children}
    </SubmitAReferralContext.Provider>
  );
};
