import React, { useState, useEffect } from "react";
import { Checkbox } from 'primereact/checkbox';
import { NavLink, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import PrivacyPolicy from "./PrivacyPolicy";
import RegisterCheckEmail from "./RegisterCheckEmail";
import TermsUse from "./TermsUse";
import Autocomplete from "react-google-autocomplete";
import { InputText } from "primereact";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios, { googleAPIKey } from '../../../axiosCall.js';
import Swal from 'sweetalert2';
import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete'
import '@geoapify/geocoder-autocomplete/styles/minimal.css'


let LocationType = 'country' | 'state' | 'city' | 'postcode' | 'street';
let SupportedLanguage = "en";

let CountyCode =  "auto" | "us";
// let Position = "India"| "United States";

const Signup = () => {

    const verificationCode = new URLSearchParams(window.location.search).get('c');
    const refridCode = new URLSearchParams(window.location.search).get('refrid');

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dbStatesName, setDbStatesName] = useState([]);
    const [dbBrokerageRoleData, setBrokerageRoleData] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [userEmailId, setUserEmailId] = useState('');
    const [newVerificationCode, setNewVerificationCode] = useState('');
    const [isDisableInvited, setIsDisableInvited] = useState(false);
    const [isDisableBrokerOfRecord, setIsDisableBrokerOfRecord] = useState(false);
    
    const [isSubmitCall, setIsSubmitCall] = useState(false);
    const [inputList, setInputList] = useState([
        {
            license_number: "",
            license_number_error: "",
            license_state: "",
            license_state_error: "",
        }
    ]);
    

    let minDate = new Date();
    useEffect(() => {
        const fetchStatesData = async () => {
            try {
                const response = await axios.post('/get-all-states');
                const { Status, StatusMessage, Error, Result } = response.data;

                if (Status === true) {
                    //alert(Result)
                    setDbStatesName(Result);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchStatesData();

        const fetchBrokerageRoleData = async () => {
            try {
                const response = await axios.post('/get-all-Brokerage-role-data');
                const { Status, StatusMessage, Error, Result } = response.data;

                if (Status === true) {
                    //alert(Result)
                    setBrokerageRoleData(Result);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchBrokerageRoleData();

    }, []);
    useEffect(() => { }, [showModal]);

    useEffect(() => { 
        //alert(window.location.pathname)
        if(window.location.pathname === '/signup/ambassador')
        {
            formik.setFieldValue("type", 'ambassador');
        }
        else if(window.location.pathname === '/signup/agent')
        {
            formik.setFieldValue("type", 'agent');
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            update_user_id: '',
            invite_email_logs_id: '',
            refer_user_id: '',
            verification_code: verificationCode,
            first_name: '',
            last_name: '',
            email: '',
            type: '',
            license_number: '',
            license_expiry_date: '',
            license_state_id: '',
            business_address: '',
            contact_number: '',
            host_name: window.location.origin,
            accept: false,
            brokerage_name: '',
            broker_name: '',
            broker_email: '',
            broker_phone: '',
            brokerage_role_id: '',
            broker_of_record_detail: '',
            office_manager_detail: '',
            team_lead_detail: '',
            brokerage_role_other_detail: '',
            broker_license_json: '',
            is_sponser_need: 1,
            invited_to_the_platform: '',
            invited_to_the_platform_email: ''
        },
        validate: (data) => {
            let errors = {};
            let isValid = true;
            if (!data.type) {
                errors.type = 'Please select one.';
            }

            if (!data.email) {
                errors.email = 'Email address is required.';
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address. e.g. example@email.com';
            }

            if (data.first_name == null || data.first_name.trim() === '') {
                errors.first_name = 'First name is required.';
            }
            if (data.last_name == null || data.last_name.trim() === '') {
                errors.last_name = 'Last name is required.';
            }
            if (data.type === 'agent') {
                if (data.license_number == null || data.license_number.trim() === '') {
                    errors.license_number = 'License number is required.';
                }
                if (!data.license_expiry_date) {
                    errors.license_expiry_date = 'License expiration date is required.';
                }
                if (!data.license_state_id) {
                    errors.license_state_id = 'License state is required.';
                }
            }
            if (data.business_address == null || data.business_address.trim() === '') {
                errors.business_address = 'Home address is required.';
            }
            if (!data.contact_number) {
                errors.contact_number = 'Phone is required.';
            }
            if (data.type === 'ambassador') {

                if (data.brokerage_name == null || data.brokerage_name.trim() === '') {
                    errors.brokerage_name = 'Brokerage name is required.';
                }

                if (data.broker_name == null || data.broker_name.trim() === '') {
                    errors.broker_name = 'Broker of record name is required.';
                }
                if (data.broker_email) 
                {
                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.broker_email)) 
                    {
                        errors.broker_email = 'Invalid email address. e.g. example@email.com';
                    }
                }
                
                
                if(isSubmitCall)
                {
                    if(inputList.length > 1)
                    {
                        inputList.map((x, i) => {

                            if (x.license_number == null || x.license_number.trim() === "")
                            {
                                x.license_number_error = "Broker of Record License # is required.";
                                isValid = false;
                            }
        
                            if (!x.license_state)
                            {
                                x.license_state_error = "Broker of Record License State is required.";
                                isValid = false;
                            }
                        });
                    }
                    else
                    {
                        inputList.map((x, i) => {

                            if (x.license_state)
                            {
                                if (x.license_number == null || x.license_number.trim() === "")
                                {
                                    x.license_number_error = "Broker of Record License # is required.";
                                    isValid = false;
                                }
                            }
                                
                            if (x.license_number)
                            {
                                if (!x.license_state)
                                {
                                    x.license_state_error = "Broker of Record License State is required.";
                                    isValid = false;
                                }
                            }

                            if (!x.license_state && !x.license_number)
                            {
                                x.license_state_error = "";
                                x.license_number_error = "";
                            }
                        });
                    }
                }

                if(!data.brokerage_role_id)
                {
                    errors.brokerage_role_id = 'Role with current brokerage is required.';
                }
                else
                {
                    if(data.brokerage_role_id.slug === 'broker_of_record')
                    {
                        if (data.broker_of_record_detail == null || data.broker_of_record_detail.trim() === '') {
                            errors.broker_of_record_detail = 'How many agents in your brokerage is required.';
                        }
                    }

                    if(data.brokerage_role_id.slug === 'office_manager')
                    {
                        if (data.office_manager_detail == null || data.office_manager_detail.trim() === '') {
                            errors.office_manager_detail = 'How many agents in your office is required.';
                        }
                    }

                    if(data.brokerage_role_id.slug === 'team_lead')
                    {
                        if (data.team_lead_detail == null || data.team_lead_detail.trim() === '') {
                            errors.team_lead_detail = 'How many agents on your team is required.';
                        }
                    }

                    if(data.brokerage_role_id.slug === 'other')
                    {
                        if (data.brokerage_role_other_detail == null || data.brokerage_role_other_detail.trim() === '') {
                            errors.brokerage_role_other_detail = 'Other role is required.';
                        }
                    }
                }
            }

            if (!data.accept) {
                errors.accept = 'You need to agree to the terms and conditions.';
            }

            if (data.invited_to_the_platform && !data.invited_to_the_platform.trim()) {
                errors.invited_to_the_platform = 'Please enter first name and last name.';
            }
            
            if(data.invited_to_the_platform)
            {
                if (!data.invited_to_the_platform_email) {
                    errors.invited_to_the_platform_email = 'Sponsor email address is required.';
                }
                else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.invited_to_the_platform_email)) {
                    errors.invited_to_the_platform_email = 'Invalid email address. e.g. example@email.com';
                }
            }
            if (isValid) {
                return errors;
            } else {
                errors.multi_fields = "error";
                return errors;
            }

        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data,'sponser');
        }
    });

    useEffect(() => {
        const verifyReferralLink = async () => {
            try {
                let formData = new FormData();
                formData.append('verification_code', verificationCode);

                const response = await axios.post('/verify-referral-link', formData);
                const { Status, Result } = response.data;

                if (Status === true) {

                    formik.setFieldValue("refer_user_id", Result.refer_user_id);
                    formik.setFieldValue("type", Result.type);
                    formik.setFieldValue("email", Result.email);
                    formik.setFieldValue("first_name", Result.first_name);
                    formik.setFieldValue("last_name", Result.last_name);
                    formik.setFieldValue("invite_email_logs_id", Result.invite_email_logs_id);
                    formik.setFieldValue("invited_to_the_platform", Result.invited_to_the_platform);
                    formik.setFieldValue("invited_to_the_platform_email", Result.invited_to_the_platform_email);
                    

                    if (Result.invited_to_the_platform) {
                        setIsDisableInvited(true);
                    }
                    else {
                        setIsDisableInvited(false);
                    }
                    formik.setFieldValue("contact_number", Result.contact_number);
                    formik.setFieldValue("brokerage_name", Result.brokerage_name);
                    formik.setFieldValue("broker_name", Result.broker_name);
                    formik.setFieldValue("broker_email", Result.broker_email);
                    formik.setFieldValue("broker_phone", Result.broker_phone);
                    formik.setFieldValue("update_user_id", Result.update_user_id);

                    setIsDisabled(true);
                }
                else {
                    Swal.fire({
                        title: '',
                        text: "Verification link is expired.",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        navigate('/login')
                    })
                }
            } catch (err) {
                console.log(err);
            }
        }

        const verifyRefrCode = async () => {
            try {
                let formData = new FormData();
                formData.append('refr_code', refridCode);

                const response = await axios.post('/verify-refr-code-link', formData);
                const { Status, Result } = response.data;

                if (Status === true) {

                    formik.setFieldValue("refer_user_id", Result.refer_user_id);
                    
                    formik.setFieldValue("invited_to_the_platform", Result.invited_to_the_platform);
                    if(Result.invited_to_the_platform_email)
                      formik.setFieldValue("invited_to_the_platform_email", Result.invited_to_the_platform_email);
                    
                    if (Result.invited_to_the_platform) {
                        setIsDisableInvited(true);
                    }
                    else {
                        setIsDisableInvited(false);
                    }
                }
                else {
                    Swal.fire({
                        title: '',
                        text: "Verification link is expired.",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        navigate('/login')
                    })
                }
            } catch (err) {
                console.log(err);
            }
        }
        
        if (verificationCode !== null) {
            verifyReferralLink();
        }
        if (refridCode !== null) {
            verifyRefrCode();
        }
        
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const selectedStateTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.abbrv}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }
    const stateOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.abbrv}</div>
            </div>
        );
    }

    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }
    const optionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    }
    const handleCheckboxChange = (e,mode) => {
        
        formik.setFieldValue("type", mode)
        formik.setFieldError('type', "")
        formik.setFieldValue("brokerage_name", '')
        formik.setFieldValue("broker_name", '')
        formik.setFieldValue("broker_email", '')
        
        
        formik.setFieldValue("broker_phone", '')

        formik.setFieldValue("brokerage_role_id", '')
        formik.setFieldValue("broker_of_record_detail", '')
        formik.setFieldValue("office_manager_detail", '')
        formik.setFieldValue("team_lead_detail", '')
        formik.setFieldValue("brokerage_role_other_detail", '')

        formik.setFieldValue("license_number", '')
        formik.setFieldValue("license_expiry_date", '')
        formik.setFieldValue("license_state_id", '')

        setInputList([
            {
                license_number: "",
                license_number_error: "",
                license_state: "",
                license_state_error: "",
            }
        ]);
    }
    const handleSubmit = async (data,mode) => {
        //alert('inisde submit')
        setShowModal(false);
        setLoading(true);
        try {
            const response = await axios.post('/register', data,mode);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {

                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                if(Result.sponser_not_exists)
                {
                    Swal.fire({
                        title: 'No such email address exists for the sponsor!',
                        text: "Do you wish to make changes or continue the signup?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: "Continue",
                        confirmButtonText: 'Yes, Change it'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            formik.setFieldValue("is_sponser_need", 1)
                        }
                        else
                        {
                            formik.setFieldValue("is_sponser_need", 0)
                            formik.handleSubmit();
                        }
                    })
                }
                else if(Result.is_link_expired)
                {
                    Swal.fire({
                        title: '',
                        text: "Verification link is expired.",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        navigate('/login')
                    })
                }
                else
                {
                    setErrorMessage(finalApiError);
                    window.scrollTo(0, 0);
                }
            } else {
                setLoading(false);
                formik.resetForm();
                setShowModal(true);
                setUserEmailId(Result.email);
                if (verificationCode !== null) {
                    setNewVerificationCode(Result.new_verification_code);
                }
                
            }
        } catch (err) {
            // console.log(err);
            setErrorMessage(err);
            setLoading(false);
            window.scrollTo(0, 0);
        }
    };
    const handleChangeUserDataFirstName = (e) => {
        if(formik.values.brokerage_role_id.slug === 'broker_of_record')
        {
            if(formik.values.last_name)
            {
                formik.setFieldValue("broker_name", e.target.value+' '+formik.values.last_name)
            }
            else
            {
                formik.setFieldValue("broker_name", e.target.value)
            }
        }
    }
    const handleChangeUserDataLastName = (e) => {
        if(formik.values.brokerage_role_id.slug === 'broker_of_record')
        {
            if(formik.values.first_name)
            {
                formik.setFieldValue("broker_name", formik.values.first_name+' '+e.target.value)
            }
            else
            {
                formik.setFieldValue("broker_name", e.target.value)
            }
        }
    }
    const handleChangeUserDataEmail = (e) => {
        if(formik.values.brokerage_role_id.slug === 'broker_of_record')
        {
            formik.setFieldValue("broker_email", e.target.value)
        }
    }
    const handleChangeUserDataPhone = (e) => {
        if(formik.values.brokerage_role_id.slug === 'broker_of_record')
        {
            formik.setFieldValue("broker_phone", e.target.value)
        }
    }
    const handleBrokerageRole = (e) => {     
           
        setIsDisableBrokerOfRecord(false);
        formik.setFieldValue("broker_name", "")
        formik.setFieldValue("broker_email", "")
        formik.setFieldValue("broker_phone", "")
        setInputList([
            {
                license_number: "",
                license_number_error: "",
                license_state: "",
                license_state_error: "",
            }
        ]);

        if(e.target.value.slug === 'broker_of_record')
        {
            setIsDisableBrokerOfRecord(true);
            let first_name = formik.values.first_name;
            let last_name = formik.values.last_name;
            if((first_name) && (last_name))
            {
                formik.setFieldValue("broker_name", first_name+' '+last_name)
            }
            else if(first_name)
            {
                formik.setFieldValue("broker_name", first_name)
            }
            else if(last_name)
            {
                formik.setFieldValue("broker_name", last_name)
            }
            
            if(formik.values.email)
            {
                formik.setFieldValue("broker_email", formik.values.email)
            }
            
            if(formik.values.contact_number)
            {
                formik.setFieldValue("broker_phone", formik.values.contact_number)
            }
        }
        
        formik.setFieldValue("broker_of_record_detail", '')
        formik.setFieldValue("office_manager_detail", '')
        formik.setFieldValue("team_lead_detail", '')
        formik.setFieldValue("brokerage_role_other_detail", '')
    }

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        
        if (name === "license_number") {
            list[index]["license_number_error"] = "";
        } else if (name === "license_state") {
            list[index]["license_state_error"] = "";
        }
        setInputList(list);
        formik.setFieldValue("broker_license_json", list);
    };
    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
       // alert(list.length)
        if(list.length == 1)
        {
            list.map((x, i) => {
                x.license_number_error = "";
                x.license_state_error = "";
            });
        }
        //alert(JSON.stringify(list))

        setInputList(list);

        formik.setFieldValue("broker_license_json", list);
    };

    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                license_number: "",
                license_number_error: "",
                license_state: "",
                license_state_error: "",
            },
        ]);
    };
    
     
    
    const handleGoogleAddress = (place) => {
        //console.log('place.formatted_address '+place.formatted_address)
        formik.setFieldValue("business_address", place.formatted_address);
        
    }
    return (
        <GeoapifyContext apiKey="899b8d057417463bb2537bd52181f4df">

            <section className="form-section signup-section auth-bg">
                <div className="container-fluid">
                    <div className="form-container signup-container">
                        <div className="form-wrapper">
                            <div className="back-sign">
                                <NavLink to="/"><img src="/assets/images/back-icon.svg" alt="" /></NavLink>
                            </div>
                            <h1 className="signup-heading">Sign Up</h1>
                            {errorMessage && (
                                <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                    {errorMessage}
                                </Alert>
                            )}
                            <form onSubmit={formik.handleSubmit} >
                                <div className="choose-form signup-option">

                                    <div className={classNames("card refr-agent-card", { 'active': formik.values.type === 'ambassador' })} >
                                        <label htmlFor="ambassador_type">
                                            <div className="card-body">
                                                <div className="box">
                                                    <div className="userTitle">
                                                        {
                                                            (formik.values.type === 'ambassador') && (
                                                                <img src='/assets/images/user-blue.png' alt='img' />)
                                                        }
                                                        {
                                                            (formik.values.type === 'agent') && (
                                                                <img src='/assets/images/user-unselect.png' alt='img' />)
                                                        }
                                                        {
                                                            (!formik.values.type) && (
                                                                <img src='/assets/images/user-unselect.png' alt='img' />)
                                                        }
                                                        <p className="title">REFR <br />Ambassador</p>
                                                    </div>
                                                    <div className="check">
                                                        <Checkbox
                                                            inputId="ambassador_type"
                                                            disabled={isDisabled}
                                                            name="type" value="ambassador" checked={formik.values.type === 'ambassador'}
                                                            onChange={(e) => { handleCheckboxChange(e,'ambassador') }}></Checkbox>
                                                    </div>
                                                </div>

                                                <p className="signup-desc">Choose this option if you are still actively selling real estate with another brokerage and you would just like to use our platform to submit/receive referrals or invite others who are looking to be Referral Only Agents <span>- Register for FREE</span></p>
                                            </div>
                                        </label>
                                    </div>
                                    <div className={classNames("card", { 'active': formik.values.type === 'agent' })} >
                                        <label htmlFor="agent_type">
                                            <div className="card-body">
                                                <div className="box">
                                                    <div className="userTitle">
                                                        {
                                                            (formik.values.type === 'agent') && (
                                                                <img src='/assets/images/user-blue.png' alt='img' />)
                                                        }
                                                        {
                                                            (formik.values.type === 'ambassador') && (
                                                                <img src='/assets/images/user-unselect.png' alt='img' />)
                                                        }
                                                        {
                                                            (!formik.values.type) && (
                                                                <img src='/assets/images/user-unselect.png' alt='img' />)
                                                        }
                                                        <p className="title">REFR <br />Agent</p>
                                                    </div>
                                                    <div className="check">
                                                        <Checkbox
                                                            inputId="agent_type"
                                                            disabled={isDisabled}
                                                            name="type"
                                                            value="agent" checked={formik.values.type === 'agent'}
                                                            onChange={(e) => { handleCheckboxChange(e,'agent') }}></Checkbox>
                                                    </div>
                                                    
                                                </div>
                                                <p className="signup-desc">Choose this option if you are looking to move your license to our brokerage and work as a Referral Only Agent</p>
                                                
                                            </div>
                                           
                                        </label>
                                        
                                    </div>
                                    {
                                        (!formik.values.type) && (getFormErrorMessage('type'))
                                    }
                                </div>
                                {
                                        (formik.values.type) && (
                                <div className="form-inner">
                                    <div className="row">
                                        <div className="form-field signup-label col-lg-6 signup-form-field">
                                            <label htmlFor="fname">First Name <span className="reuired-star">*</span></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder="Enter here"
                                                name="first_name"
                                                value={formik.values.first_name} onChange={(e) =>{formik.handleChange(e);handleChangeUserDataFirstName(e)} }
                                                className={classNames("form-control", { 'p-invalid': isFormFieldValid('first_name') })}
                                            />
                                            {getFormErrorMessage('first_name')}
                                        </div>
                                        <div className="form-field signup-label col-lg-6 signup-form-field">
                                            <label htmlFor="lname">Last Name <span className="reuired-star">*</span></label>
                                            <InputText name="last_name"
                                                autoComplete="off"
                                                value={formik.values.last_name} onChange={(e) =>{formik.handleChange(e);handleChangeUserDataLastName(e)} }
                                                className={classNames("form-control", { 'p-invalid': isFormFieldValid('last_name') })} placeholder="Enter here" />
                                            {getFormErrorMessage('last_name')}
                                        </div>
                                    </div>
                                    <div className="form-field signup-label">
                                        <label htmlFor="email">Email Address <span className="reuired-star">*</span></label>
                                        <InputText
                                            autoComplete="off"
                                            disabled={isDisabled}
                                            name="email"
                                            value={formik.values.email} onChange={(e) =>{formik.handleChange(e);handleChangeUserDataEmail(e)} }
                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('email') })} placeholder="Enter here" />
                                        {getFormErrorMessage('email')}
                                    </div>
                                    <div className="form-field signup-label">
                                        <label htmlFor="phone">Phone <span className="reuired-star">*</span></label>
                                        <InputMask
                                            autoComplete="off"
                                            name="contact_number"
                                            value={formik.values.contact_number} onChange={(e) =>{formik.handleChange(e);handleChangeUserDataPhone(e)} }
                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('contact_number') })} id="phone" mask="(999) 999-9999" placeholder="(999) 999-9999" ></InputMask>
                                        {getFormErrorMessage('contact_number')}
                                    </div>
                                    
                                    <div className="form-field signup-label" >
                                        <label htmlFor="baddress">Home Address <span className="reuired-star">*</span></label>
                                        <Autocomplete
                                        className="p-inputtext p-component p-filled form-control"
                                        apiKey={googleAPIKey}
                                        
                                        onPlaceSelected={(place) => {
                                            handleGoogleAddress(place);
                                        }}
                                        options={{
                                            types: ["geocode", "establishment"],
                                            componentRestrictions: { country: "us" },
                                        }}
                                        // defaultValue="Amsterdam"
                                        />
                                        {getFormErrorMessage('business_address')}
                                    </div>
                                    

                                    
                                    <div className="divider">
                                        <hr />
                                    </div>
                                    {
                                        (formik.values.type === 'ambassador') && (
                                            <>
                                                <h5 className="form-sub-title">Brokerage Information</h5>
                                                <div className="row">
                                                    <div className="field signup-label col-lg-12 signup-form-field">
                                                        <label htmlFor="baddress">Role with current brokerage <span className="reuired-star">*</span> </label>
                                                        
                                                        <Dropdown
                                                            className={classNames("form-select", { 'p-invalid': isFormFieldValid('brokerage_role_id') })}
                                                            value={formik.values.brokerage_role_id} options={dbBrokerageRoleData} 
                                                            onChange={(e) => {formik.handleChange(e);handleBrokerageRole(e)}} optionLabel="name" name="brokerage_role_id" filter filterBy="name" placeholder="Select a Role"
                                                            valueTemplate={selectedTemplate} itemTemplate={optionTemplate} />
                                                        {
                                            (!formik.values.brokerage_role_id) && (getFormErrorMessage('brokerage_role_id')) 
                                            
                                            }
                                                    </div>
                                                    {
                                                        (formik.values.brokerage_role_id.slug === 'broker_of_record') && (<div className="field signup-label col-lg-12 signup-form-field">
                                                        <label htmlFor="">How many agents in your brokerage <span className="reuired-star">*</span></label>
                                                        <InputText
                                                            autoComplete="off"
                                                            name="broker_of_record_detail"
                                                            value={formik.values.broker_of_record_detail} onChange={formik.handleChange}
                                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('broker_of_record_detail') })} placeholder="Enter here" />
                                                        {getFormErrorMessage('broker_of_record_detail')}
                                                    </div>)
                                                    }
                                                    {
                                                        (formik.values.brokerage_role_id.slug === 'office_manager') && (
                                                    <div className="form-field signup-label col-lg-12 signup-form-field">
                                                        <label htmlFor="">How many agents in your office <span className="reuired-star">*</span></label>
                                                        <InputText
                                                            autoComplete="off"
                                                            name="office_manager_detail"
                                                            value={formik.values.office_manager_detail} onChange={formik.handleChange}
                                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('office_manager_detail') })} placeholder="Enter here" />
                                                        {getFormErrorMessage('office_manager_detail')}
                                                    </div>)
                                                    }
                                                    {
                                                        (formik.values.brokerage_role_id.slug === 'team_lead') && (
                                                    <div className="form-field signup-label col-lg-12 signup-form-field">
                                                        <label htmlFor="">How many agents on your team <span className="reuired-star">*</span></label>
                                                        <InputText
                                                            autoComplete="off"
                                                            name="team_lead_detail"
                                                            value={formik.values.team_lead_detail} onChange={formik.handleChange}
                                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('team_lead_detail') })} placeholder="Enter here" />
                                                        {getFormErrorMessage('team_lead_detail')}
                                                    </div>
                                                        )}
                                                        {
                                                        (formik.values.brokerage_role_id.slug === 'other') && (
                                                    <div className="form-field signup-label col-lg-12 signup-form-field">
                                                        <label htmlFor="">Other Role <span className="reuired-star">*</span></label>
                                                        <InputText
                                                            autoComplete="off"
                                                            name="brokerage_role_other_detail"
                                                            value={formik.values.brokerage_role_other_detail} onChange={formik.handleChange}
                                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('brokerage_role_other_detail') })} placeholder="Enter here" />
                                                        {getFormErrorMessage('brokerage_role_other_detail')}
                                                    </div>
                                                    )}
                                                </div>
                                                <div className="row">
                                                    <div className="field signup-label col-lg-12 signup-form-field">
                                                        <label htmlFor="">Brokerage Name <span className="reuired-star">*</span></label>
                                                        <InputText
                                                            autoComplete="off"
                                                            name="brokerage_name"
                                                            value={formik.values.brokerage_name} onChange={formik.handleChange}
                                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('brokerage_name') })} placeholder="Enter here" />
                                                        {getFormErrorMessage('brokerage_name')}
                                                    </div>
                                                    <div className="field signup-label col-lg-6 signup-form-field">
                                                        <label htmlFor="licenseNum">Broker of Record Name <span className="reuired-star">*</span></label>
                                                        <InputText
                                                            disabled={isDisableBrokerOfRecord}
                                                            autoComplete="off"
                                                            name="broker_name"
                                                            value={formik.values.broker_name} onChange={formik.handleChange}
                                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('broker_name') })} placeholder="Enter here" />
                                                        {getFormErrorMessage('broker_name')}
                                                    </div>
                                                    <div className="field signup-label col-lg-6 signup-form-field">
                                                        <label htmlFor="licenseNum">Broker of Record Phone </label>
                                                        <InputMask
                                                        disabled={isDisableBrokerOfRecord}
                                                            autoComplete="off"
                                                            name="broker_phone"
                                                            value={formik.values.broker_phone} onChange={formik.handleChange}
                                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('broker_phone') })} id="phone" mask="(999) 999-9999" placeholder="(999) 999-9999" ></InputMask>
                                                        {getFormErrorMessage('broker_phone')}
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </>
                                        )}
                                    {
                                        (formik.values.type === 'ambassador')  && (
                                            <div className="row">
                                                <div className="field signup-label col-lg-12 signup-form-field">
                                                    <label htmlFor="licenseNum">Broker of Record Email </label>
                                                    <InputText
                                                    disabled={isDisableBrokerOfRecord}
                                                        autoComplete="off"
                                                        name="broker_email"
                                                        value={formik.values.broker_email} onChange={formik.handleChange}
                                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('broker_email') })} placeholder="Enter here" />
                                                    {getFormErrorMessage('broker_email')}
                                                </div>
                                                
                                            </div>
                                        )}
                                        {(formik.values.type === 'ambassador')  &&(
                                            <div className="row">
                                                {inputList.map((x, i) => {
                                                return (
                                                    <div key={i} className="repeaterClass">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="field">
                                                                    <label
                                                                        htmlFor="FirstInput"
                                                                        className="form-label"
                                                                    >
                                                                        Broker of Record License # 
                                                                    </label>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        className={classNames("form-control",
                                                                            {"p-invalid":isFormFieldValid("license_number"),
                                                                            }
                                                                        )}
                                                                        placeholder="Enter here"
                                                                        name="license_number"
                                                                        value={x.license_number}
                                                                        onChange={(e) => handleInputChange(e,i)
                                                                        }
                                                                    />
                                                                    {(!x.license_number) && (x.license_number_error)  && (
                                                                        <small className="p-error">
                                                                            {
                                                                                x.license_number_error
                                                                            }
                                                                        </small>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="field">
                                                                    <label
                                                                        htmlFor="LastInput"
                                                                        className="form-label"
                                                                    >
                                                                        Broker of Record License State 
                                                                    </label>
                                                                    <Dropdown
                                                                        className={classNames("form-select", { 'p-invalid': isFormFieldValid('license_state') })}
                                                                        value={x.license_state}
                                                                        options={dbStatesName} 
                                                                        onChange={(e) =>
                                                                            handleInputChange(e,i)
                                                                        } optionLabel="state" 
                                                                        name="license_state" filter filterBy="abbrv" 
                                                                        placeholder="Select a State"
                                                                        valueTemplate={selectedStateTemplate} 
                                                                        itemTemplate={stateOptionTemplate} />
                                                                   
                                                                    
                                                                   {(!x.license_state) && (x.license_state_error) && (
                                                                        <small className="p-error">
                                                                            {
                                                                               x.license_state_error
                                                                            }
                                                                        </small>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            (formik.values.brokerage_role_id.slug === 'broker_of_record') && (<div className="btn-box mb-3">
                                                            {inputList.length !== 1 && (
                                                                <Button
                                                                    className="mr10 add-more remove-link me-2"
                                                                    onClick={() =>
                                                                        handleRemoveClick(i)
                                                                    }
                                                                >
                                                                    Remove
                                                                </Button>
                                                            )}
                                                            {inputList.length - 1 === i && (
                                                                <Button
                                                                    onClick={handleAddClick}
                                                                    className="add-more"
                                                                >
                                                                Add More +
                                                                </Button>
                                                            )}
                                                        </div>)
                                                        }
                                                    </div>
                                                );
                                            })}
                                            </div>
                                        )}
                                    {
                                        (formik.values.type === 'agent') && (
                                            <div className="row">
                                                <div className="form-field signup-label col-lg-6 signup-form-field">
                                                    <label htmlFor="licenseNum">License Number <span className="reuired-star">*</span></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        name="license_number"
                                                        value={formik.values.license_number} onChange={formik.handleChange}
                                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('license_number') })} placeholder="Enter here" />
                                                    {getFormErrorMessage('license_number')}
                                                </div>
                                                <div className="form-field signup-label col-lg-6 signup-form-field">
                                                    <label htmlFor="licenseNum">License Expiration Date <span className="reuired-star">*</span></label>
                                                    <Calendar
                                                        minDate={minDate}
                                                        name="license_expiry_date"
                                                        value={formik.values.license_expiry_date} onChange={formik.handleChange}
                                                        InputClassName={classNames("form-control", { 'p-invalid': isFormFieldValid('license_expiry_date') })}
                                                        dateFormat="mm/dd/yy"
                                                        placeholder="MM/DD/YY" showIcon />
                                                    {getFormErrorMessage('license_expiry_date')}
                                                </div>
                                            </div>)}
                                    {
                                        (formik.values.type === 'agent') && (
                                            <div className="row">
                                                <div className="form-field signup-label col-lg-12 signup-form-field">
                                                    <label htmlFor="baddress">License State <span className="reuired-star">*</span> </label>
                                                    {/* <Dropdown className="form-select" value={selectedCity1} options={dbStatesName} onChange={onCityChange} optionLabel="name" placeholder="Select" /> */}
                                                    <Dropdown
                                                        className={classNames("form-select", { 'p-invalid': isFormFieldValid('license_state_id') })}
                                                        value={formik.values.license_state_id} options={dbStatesName} onChange={formik.handleChange} optionLabel="state" name="license_state_id" filter filterBy="abbrv" placeholder="Select a State"
                                                        valueTemplate={selectedStateTemplate} itemTemplate={stateOptionTemplate} />
                                                    {getFormErrorMessage('license_state_id')}
                                                </div>
                                            </div>
                                        )}
                                    <div className="row">
                                        <div className="form-field signup-label col-lg-12 signup-form-field">
                                            <label htmlFor="">Sponsor (Who invited you to the platform)</label>
                                            <InputText
                                                disabled={isDisableInvited} className="form-control" autoComplete="off" name="invited_to_the_platform" value={formik.values.invited_to_the_platform}
                                                onChange={formik.handleChange} placeholder="Sponsor full name" />
                                            {getFormErrorMessage('invited_to_the_platform')}
                                            <p className="field-desc mb-0">Leave blank if you found us on your own.</p>
                                        </div>
                                        <div className="form-field signup-label col-lg-12 signup-form-field">
                                            <label htmlFor="">Sponsor Email</label>
                                            <InputText
                                                disabled={isDisableInvited} className="form-control" autoComplete="off" name="invited_to_the_platform_email" value={formik.values.invited_to_the_platform_email}
                                                onChange={formik.handleChange} placeholder="Sponsor email" />
                                            {getFormErrorMessage('invited_to_the_platform_email')}
                                            <p className="field-desc mb-0">Leave blank if you found us on your own.</p>
                                        </div>
                                    </div>
                                </div>)
                                }
                                {
                                        (formik.values.type) && (
                                <div className="remember-me signup-check d-block">
                                    <div className="field-checkbox mb-0">
                                        <Checkbox inputId="rememberMe" name="accept"
                                            value={formik.values.accept} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('accept') })}
                                            checked={formik.values.accept} />
                                            <label className="form-check-label" htmlFor="rememberMe">
                                            I agree to the <a href="https://www.refrnow.com/terms" target="_blank" className="policy-link underline-ani">Terms of Use</a> and <a href="https://www.refrnow.com/privacy-policy" target="_blank"className="policy-link underline-ani">Privacy Policy</a>
                                        </label>
                                        {/* <label className="form-check-label" htmlFor="rememberMe">
                                            I agree to the <a href="/#" data-bs-toggle="modal" data-bs-target="#termsUse" className="policy-link underline-ani">Terms of Use</a> and <a href="/#" data-bs-toggle="modal" data-bs-target="#privacypolicy" className="policy-link underline-ani">Privacy Policy</a>
                                        </label> */}
                                    </div>

                                    {getFormErrorMessage('accept')}
                                </div>)
                                }
                                {
                                        (!formik.values.type) && (
                                <div className="remember-me signup-check d-block">
                                    
                                </div>)
                                }
                                <div className="form-group">
                                    {
                                        (formik.values.type) && (
                                    <p className="require-field-desc">All fields are mandatory. (<span className="reuired-star">*</span>)</p>
                                    
                                    )
                                }
                                    <button type="submit" className="btn form-btn" onClick={() => {setIsSubmitCall(true)}} disabled={loading}>Sign Up
                                        {loading && (<Typography align="center">
                                            <CircularProgress value={66} />
                                        </Typography>)}
                                    </button>
                                    {/* <NavLink to="/verify-email" className="btn form-btn">Sign Up</NavLink> */}

                                </div>
                                <div className="or-btn">
                                    <span>OR</span>
                                </div>
                                <div className="already-reg-login text-center">
                                    <span>Already registered? <NavLink to="/" className="underline-ani-blue login-line">Log In</NavLink></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {showModal && (
                <RegisterCheckEmail
                    showModal={showModal}
                    userEmailId={userEmailId}
                    verificationCode={newVerificationCode}
                />
            )}
            <TermsUse />
            <PrivacyPolicy />
            </GeoapifyContext>
    )
}

export default Signup;
