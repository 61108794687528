import React, { useState, useEffect } from "react";
import Header from './Header'  
import useAuth from '../hooks/useAuth';
import { useNavigate,Outlet  } from "react-router-dom";

const Layout = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  let userData = localStorage.getItem('user_data')
  useEffect(() => {
    
    if(userData === null)
    {
      //alert('in layout userData ::: '+userData)
      navigate('/');
    }
    if(userData === undefined)
    {
      //alert('in layout userData 1::: '+userData)
      navigate('/');
    }
  }, []);
  
  useEffect(() => {
    //alert(1);
    if (user !== null) {
        let type = user.type;
        //alert(2);
    }
  }, [user]);

  return (
    <div>
        <Header>
        </Header>
    </div>
  )
}

export default Layout