import { RadioButton } from "primereact";
import { Card } from "react-bootstrap";

const Subscription = () => {

    return (
        <section className='referral-template sec-pt sec-pb'>
            <div className="container">
                <div className="referral-container">
                    <h2 className='main-heading'>Subscription</h2>
                    <Card>
                        <Card.Body>
                            <div className="card-container">

                                <div className='referral-fields subscription-page'>
                                    <p className="title sub-titles">Select the Subscription plan</p>
                                    <label htmlFor="subs_1">
                                    <div className="referral-field active sub_field sub_field_1">
                                        <RadioButton inputId="subs_1" name="referral_type" checked/>
                                        <label className="step-one-label">Basic</label>
                                        <p className='referral-desc'>FREE FOREVER - 50/50 Commission Split </p>
                                    </div>
                                    </label>
                                    <label htmlFor="subs_2">
                                    <div className="referral-field sub_field">
                                        <RadioButton inputId="subs_2" name="referral_type" />
                                        <label className="step-one-label">Standard</label>
                                        <p className='referral-desc'><b>$9</b>/Mo-80/20 Commission Split (You Keep 80% of the Referral Commission)</p>
                                    </div>
                                    </label>
                                    <label htmlFor="subs_3">
                                    <div className="referral-field sub_field last-sub">
                                        <RadioButton inputId="subs_3" name="referral_type" />
                                        <label className="step-one-label">Pro</label>
                                        <p className='referral-desc'><b>$19</b>/Mo-90/20 Commission Split (You Keep 80% of the Referral Commission) </p>
                                    </div>
                                    </label>
                                    <p className="sub-description">There is a $25 one-time activation fee on all plans and some states charge $25 to move your license </p>
                                </div>
                                <div className="card-footer text-end">
                                    <button class="btn btn-cancel me-3" type="button">Cancel</button>
                                    <button class="btn btn-submit btn-500" type="button">Next</button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </section>

    )
}

export default Subscription;