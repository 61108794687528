import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { InputTextarea } from 'primereact';
import axios from "../../axiosCall.js";
import useAuth from "./../../hooks/useAuth";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import Swal from 'sweetalert2';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { Panel } from 'primereact/panel';

const PeopleDetail = () => {
    const secretKey = "REFR2022@GALAXY";
    let requestPeopleId = '';
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get('u');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [notes, setNotes] = useState('');
    const [notesError, setNotesError] = useState('');

    const [peopleCommunicationEmail, setPeopleCommunicationEmail] = useState([]);

    const { user, logout } = useAuth();
    if (userId === null) {
        navigate('/dashboard');
    } else {
        //alert('else');
        let userIdDecrypt = AES.decrypt(userId, secretKey);
        //alert(userIdDecrypt)
        if (userIdDecrypt != '')
            requestPeopleId = JSON.parse(userIdDecrypt.toString(Utf8));
        else
            navigate('/dashboard');
    }
    const [datas, setDatas] = useState(
        {
            name: 'Alicia Edward',
            short_name: 'AE',
            email: '',
            contact_number: '8896632',
            full_address: '10/25/2024'
        }
    );

    useEffect(() => {

        if (user !== null) {
            let formData = new FormData();
            formData.append('people_id', requestPeopleId);

            const fetchPeopleDetailData = async () => {
                try {
                    const response = await axios.post("/get-people-data", formData);
                    const { Status, StatusMessage, Error, Result } = response.data;

                    if (Status === true) {
                        let isChecked = '';

                        let matches = Result.people.name.match(/\b(\w)/g);
                        let shortName = matches.join('');

                        let objectDate = new Date(user.created_at);

                        let day = objectDate.getDate();
                        let month = objectDate.getMonth() + 1;
                        let year = objectDate.getFullYear();
                        if (day < 10) {
                            day = '0' + day;
                        }

                        if (month < 10) {
                            month = `0${month}`;
                        }
                        if(Result.people.people_communication_log.length > 0)
                        {
                            setPeopleCommunicationEmail(Result.people.people_communication_log);
                        }

                        //alert(MLSChecked)
                        setDatas((prevState) => {
                            return {
                                ...prevState,
                                'name': Result.people.name,
                                'short_name': shortName,
                                'email': Result.people.email,
                                'contact_number': Result.people.contact_number,
                                'full_address': Result.people.full_address,
                                'created': month + '/' + day + '/' + year,
                            };
                        });
                        if (Result.people.background_notes) {
                            setNotes(Result.people.background_notes)
                        }
                    }
                    else {
                        navigate('/dashboard');
                    }
                } catch (err) {

                    if (err.response.status && err.response.status == 401) {
                        logout();
                        navigate('/login');
                    } 
                }
            };
            fetchPeopleDetailData();
        }
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (notes) {
            setLoading(true);
            let formData = new FormData();

            formData.append('notes', notes);
            formData.append('people_id', requestPeopleId);

            try {
                const response = await axios.post('/update-people-notes', formData);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = '';
                setLoading(false);
                if (Status === false) {
                    if (Error.length > 0) {
                        Error.map(
                            (errorMessage) =>
                                //finalApiError += '<li>'+errorMessage+'</li>'
                                (finalApiError = errorMessage)
                        );
                    } else {
                        finalApiError = StatusMessage;
                    }
                    setErrorMessage(finalApiError);
                } else {
                    setLoading(false);
                    Swal.fire({
                        title: 'Success',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        text: StatusMessage,
                    }).then((result) => {
                        if (result.isConfirmed) {

                        }
                    });
                }
            } catch (err) {

                setLoading(false);
                if (err.response.status && err.response.status == 401) {
                    logout();
                    navigate('/login');
                } 
            }
        }
        else {
            setNotesError('Please enter notes')
        }
    }
    function getFormattedDate(dbDate) {
        // alert('dbDate '+dbDate)
         var date = new Date(dbDate);
     
         var month = date.getMonth() + 1;
         var day = date.getDate();
         var hour = date.getHours();
         var min = date.getMinutes();
         var sec = date.getSeconds();
     
         month = (month < 10 ? "0" : "") + month;
         day = (day < 10 ? "0" : "") + day;
         hour = (hour < 10 ? "0" : "") + hour;
         min = (min < 10 ? "0" : "") + min;
         sec = (sec < 10 ? "0" : "") + sec;
     
         var str = month + "/" + day + "/" + date.getFullYear() + " " +  hour + ":" + min + ":" + sec;
     
         /*alert(str);*/
     
         return str;
     }
    return (
        <>
            <section className="template-profile-view sec-pt sec-pb email_temp_pr">
                <div className="container-fluid email-container">
                    <h2 className='main-heading'>People</h2>
                    <div className="cards-container row row-cols-1 row-cols-lg-2 g-1">
                        <div className="col col-basic-detail ps-lg-0 email-col">
                            <div className="card profile-card full-card h-100">
                                <div className="card-body">
                                    <div className="profile-image-components text-center">
                                        <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root edit-dp">{datas.short_name}</div>
                                        <p className='title'>{datas.name}</p>
                                    </div>
                                    <div className='contact-list'>
                                        <ul className='list'>
                                            <li className='item'>
                                                <span><img src="../assets/images/phone-p.png" alt="" /></span>
                                                <a className='phone' href="tel:4805551234">{datas.contact_number}</a>
                                            </li>
                                            <li className='item'>
                                                <span><img src="../assets/images/email-p.png" alt="" /></span>
                                                <a className='mail' href="mailto:stevenrovithis@gmail.com">{datas.email}</a>
                                            </li>
                                            <li className='item'>
                                                <span><img src="../assets/images/home.png" alt="" /></span>
                                                <p className='address'>{(datas.full_address) ? datas.full_address : 'N/A'}</p>
                                            </li>
                                        </ul>
                                        <button className='edit-btn d-none'> <i className="pi pi-pencil"></i>Edit </button>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="add-notes d-none" >
                                            <h2 className="notes-content">Background Notes</h2>
                                            <InputTextarea className="form-control" placeholder=""
                                                value={notes}
                                                onChange={(e) => { setNotes(e.target.value); setNotesError('') }}
                                            />
                                            {
                                                (notesError) && (<><small className="p-error">{notesError}</small> <br /></>)
                                            }

                                            <div className="d-flex justify-content-end">
                                            <button className='btn-submit add-note-btn btn-submit-loader' disabled={loading}>Add Note
                                                {loading && (<Typography align="center">
                                                    <CircularProgress value={66} />
                                                </Typography>)}
                                            </button>
                                            </div>

                                        </div>
                                        <div className="add-notes d-none">
                                            <h2 className="notes-content">Details</h2>
                                            <div className='personal-fields'>
                                                <ul className='email-list'>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Birthday :</p></li>
                                                        <li className='email-info'><p className="mb-0">4/21/2001</p></li>
                                                    </li>
                                                </ul>
                                                <ul className='email-list'>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Home Purchase Anniversary :</p></li>
                                                        <li className='email-info'><p className="mb-0">4/21/2022</p></li>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col pe-lg-0 email-col-tab">
                            <div className="card h-100 full-card">
                                <div className="card-body">
                                    <h2 className='main-heading email-heading '>Emails</h2>
                                    {
                                        (peopleCommunicationEmail.length > 0) &&
                                    (<div className='email-section-tab'>
                                    { (peopleCommunicationEmail.map((data, i) => {
                                        let mediaFileName = '';
                                        if(data.attachment)
                                        {
                                            let mediaSplit = data.attachment.split('/');
                                            let mediaSplitTemp = mediaSplit[mediaSplit.length - 1].split('.');
                                            mediaFileName = 'file.'+mediaSplitTemp[1];
                                        }
                                        return (
                                        <div key={i} className="email-section-fields">
                                            <div className="email-field">
                                                <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root email-dp"><i className="pi pi-envelope"></i></div>
                                                <div className="email-details">
                                                    <div className='email-duration'>
                                                        <h3 className='email-name'>{data.from_email}  </h3>
                                                        {
                                                            (data.cc) && (<h3 className='email-light-name cc_email'> <span>CC: </span>{data.cc}</h3>)
                                                        }
                                                        {
                                                            (data.bcc) && (<h3 className='email-light-name cc_email'> <span>BCC: </span>{data.bcc}</h3>)
                                                        }
                                                        {
                                                            (data.attachment) && (<h3 className='email-light-name cc_email'> <span>Attachment: </span><a href={data.attachment} target="_blank" className='file-btn detail-op'>{mediaFileName}</a></h3>)
                                                        }
                                                        <span className='date'>{getFormattedDate(data.created_at_db)}</span>
                                                    </div>
                                                    <i className="pi pi-chevron-right"></i>
                                                    <h3 className='email-light-name'>{data.to}</h3>
                                                </div>
                                            </div>
                                            <div className="email-thoughts">
                                                <h4 className='email-thought'>{data.subject} </h4>
                                                {/* <a href='' className='view-email'>View email</a> */}
                                                <Panel  header="View email" toggleable  collapsed="true">
                                                    <div className='m-0 body-text'>
                                                        <div dangerouslySetInnerHTML={{__html: data.message }}></div>
                                                    </div>
                                                </Panel>
                                            </div>
                                        </div>
                                        )
                                    }))
                                    
                                }
                                    </div>)}
                                    {
                                        (peopleCommunicationEmail.length === 0) &&
                                    (<div className="comming-note h-100">
                                        <div className="text-center">
                                            <img src="../../assets/images/waiting.png" alt=""  className="img-fluid"/>
                                            <p>No Email sent</p>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PeopleDetail;