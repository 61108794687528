import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import LogoUI from "../LogoUI";
// import { Button } from 'primereact/button';
import { IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const RegisterConfirm = () => {
    const navigate = useNavigate();
    const redirectToLogin = () => {
        //alert(1)
        navigate('/login');
    }
    return (
        <>
            <section className="form-section auth-bg">
                <div className="container-fluid">
                    <div className="form-container register-box">
                        <div className="form-wrapper">
                            <div className="register-confirm">
                                <img src="../../assets/images/waiting-r.png" alt="" />
                                <h3 className="title">Thanks for Registering to <br />Become a REFR Agent.</h3>
                                <p className="desc">Unfortunately we are not licensed in your state yet. We will notify you when we are licensed in you state</p>
                                <button className="btn btn-submit">Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default RegisterConfirm;