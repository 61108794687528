import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Editor } from 'primereact';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";

const PolicyContentPage = () => {
    
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    
    const { user, logout } = useAuth();

    const formik = useFormik({
        initialValues: {
            terms_of_service_message: '',
            privacy_policy_message: '',
        },
        validate: (data) => {
            let errors = {};

            if (data.terms_of_service_message === null) {
                errors.terms_of_service_message = 'Terms of Service is required.';
            }
            else if (!data.terms_of_service_message) {
                errors.terms_of_service_message = 'Terms of Service is required.';
            }
            if (data.privacy_policy_message === null) {
                errors.privacy_policy_message = 'Privacy Policy is required.';
            }
            else if (!data.privacy_policy_message) {
                errors.privacy_policy_message = 'Privacy Policy is required.';
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    
    const getEditData = async () => {
        
        try {
            const response = await axios.post("/get-content");
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                //alert(Result[0].terms_of_service_message)
                formik.setFieldValue("terms_of_service_message", Result[0].terms_of_service_message);
                formik.setFieldValue("privacy_policy_message", Result[0].privacy_policy_message);

            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } 
        }
    };
    useEffect(() => {
        const elements = document.getElementsByClassName("ql-image");
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
        getEditData();
        if (user !== null) {
            
        }
    }, [user]);
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        try {
            const response = await axios.post('/update-content', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {
                setLoading(false);
                window.scrollTo(0, 0);
                setSuccessMessage('Data updated successfully.')
            }
        } catch (err) {

            setLoading(false);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                setErrorMessage("Something went wrong");
                
            }
        }
    };
    const handleMessage = (event,mode) => {
        if(mode === 'terms_of_service')
        {
            if (event.htmlValue !== null) {
                formik.setFieldValue("terms_of_service_message", event.htmlValue);
            }
            else {
                formik.setFieldValue("terms_of_service_message", "");
            }
        }
        if(mode === 'privacy_policy')
        {
            if (event.htmlValue !== null) {
                formik.setFieldValue("privacy_policy_message", event.htmlValue);
            }
            else {
                formik.setFieldValue("privacy_policy_message", "");
            }
        }
    }
    return (
        <>
            <section className='emailTemplate-component sec-pt sec-pb'>

                <div className="container">

                    <div className="email-container">
                        <h2 className='main-heading'>Terms & Policy </h2>
                        {errorMessage && (
                            <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                {errorMessage}
                            </Alert>
                        )}
                        {successMessage && (
                            <Alert sx={{ mb: 3 }} severity="success" variant="filled">
                                {successMessage}
                            </Alert>
                        )}
                        <div className="form-container">

                            <form onSubmit={formik.handleSubmit}>
                                
                                <div className="field">
                                    <label htmlFor="" className="form-label">Terms of Service <span className="reuired-star">*</span></label>
                                    
                                    <Editor placeholder="Enter Here" style={{ height: '400px' }} name="terms_of_service_message"
                                        value={formik.values.terms_of_service_message} onTextChange={(e) => { handleMessage(e,'terms_of_service') }} />
                                        {getFormErrorMessage('terms_of_service_message')}
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="form-label">Privacy Policy <span className="reuired-star">*</span></label>
                                    
                                    <Editor placeholder="Enter Here" style={{ height: '400px' }} name="privacy_policy_message"
                                        value={formik.values.privacy_policy_message} onTextChange={(e) => { handleMessage(e,'privacy_policy') }} />
                                        {getFormErrorMessage('privacy_policy_message')}
                                </div>
                                <div className="field text-center email-btns email-preview-btn">
                                    <button className="btn btn-cancel me-3" type="button" disabled={loading} onClick={(e) => navigate('/dashboard')}>Cancel</button>
                                    <button className="btn btn-submit btn-submit-loader" type="submit" disabled={loading}>Submit
                                        {loading && (<Typography align="center">
                                            <CircularProgress value={66} />
                                        </Typography>)}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="email-template-letter">
                            <img src="assets/images/Open-Email.png" alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PolicyContentPage