import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { InputText } from "primereact";
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import useAuth from './../../../hooks/useAuth';
import { IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../../axiosCall.js';
import RegisterCheckEmail from "../Signup/RegisterCheckEmail";

const Login = () => {
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [userEmailId, setUserEmailId] = useState('');

    const [emailSendLoading, setEmailSendLoading] = useState(false);

    const [isShowLinkResendVerifyEmail, setIsShowLinkResendVerifyEmail] = useState(false);
    const { login } = useAuth();
    //alert('inisde login')
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.email) {
                errors.email = 'Email address is required.';
                setIsEmailValid(false);
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address. e.g. example@email.com';
                setIsEmailValid(false);
            }
            else {
                setIsEmailValid(true);
            }
            if (!data.password) {
                errors.password = 'Password is required.';
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleSubmit = async (data) => {
        setLoading(true);
        //alert(JSON.stringify(data));
        try {
            const getOutput = await login(data.email, data.password);
            //console.log('getOutput ::: ' + getOutput);
            const { Status, StatusMessage, Error, Result } = getOutput;
            if (Status === false) {
                setLoading(false);
                let finalApiError = '';
                if (Error.length > 0) {
                    Error.map((message) => finalApiError = message);
                    setErrorMessage(finalApiError);
                }
                else {
                    finalApiError = StatusMessage;
                    setErrorMessage(finalApiError);
                }
                if(Result.is_resend_verify_email === 1)
                {
                    setIsShowLinkResendVerifyEmail(true);
                }
                else if(Result.subscription != null){
                    navigate({
                        pathname: '/select-plan',
                        search: `?c=${Result.subscription}`,
                      })
                }
                else
                {
                    setIsShowLinkResendVerifyEmail(false);
                }


            }
            else {
               navigate('/dashboard');
            }
        } catch (e) {
            setLoading(false);
        }
    };
    const handleResendEmailVerify = async() => {
        let formData = new FormData();
        formData.append('email',formik.values.email);
        formData.append('host_name',window.location.origin);
        setEmailSendLoading(true);
        try {
            const response = await axios.post('/send_verify_email', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setEmailSendLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                setIsEmailValid(false);
                setIsShowLinkResendVerifyEmail(false);
                setErrorMessage('');
                formik.resetForm();
                setShowModal(true);
                setUserEmailId(formik.values.email);
            }
        } catch (err) {
            setEmailSendLoading(false);
        }
    }
    return (
        <>
            <section className="form-section auth-bg">
                <div className="container-fluid">
                    <div className="form-container login-container">
                        <div className="form-wrapper">
                            {errorMessage && (
                                <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                    {errorMessage}
                                </Alert>
                            )}
                            {
                                (isShowLinkResendVerifyEmail) && (<div className="text-center"><NavLink onClick={handleResendEmailVerify} className="signup resend-verify-link"><span>Resend Email Verify Link</span>{emailSendLoading && (<span><Typography align="center">
                                <CircularProgress value={66} />
                            </Typography></span>)}</NavLink></div>)
                            }

                            <form onSubmit={formik.handleSubmit} >
                                <h1>Welcome Back!</h1>
                                <p className="mb-lg-0">Please enter your details to log In</p>

                                <div className="form-inner">
                                    <div className="form-field show-icon">
                                        <label htmlFor="email">Email Address</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder="Enter here"
                                            name="email"
                                            value={formik.values.email} onChange={formik.handleChange} autoFocus
                                            className={classNames("form-control", { 'p-invalid': isFormFieldValid('email') })}
                                            inputId="" />
                                        {(isEmailValid) && (<span className="icon">
                                            <IconButton>
                                                <CheckCircleIcon />
                                            </IconButton>
                                        </span>)}

                                        {getFormErrorMessage('email')}
                                    </div>
                                    <div className="form-field">
                                        <label htmlFor="password">Password</label>
                                        <Password
                                            autoComplete="off"
                                            name="password"
                                            value={formik.values.password} onChange={formik.handleChange}
                                            placeholder="Enter here" toggleMask
                                            inputClassName={classNames("form-control", { 'p-invalid': isFormFieldValid('password') })}
                                            feedback={false} />
                                        {getFormErrorMessage('password')}
                                    </div>
                                </div>
                                <div className="remember-me">
                                    <div className="field-checkbox remember-check">
                                        <Checkbox inputId="rememberMe" onChange={e => setChecked(e.checked)} checked={checked} />
                                        <label htmlFor="rememberMe">Remember Me</label>
                                    </div>
                                    <div className="forgot-password">
                                        <p><NavLink to="/forgot-password" className="underline-ani">Forgot Password?</NavLink></p>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button className="btn form-btn" disabled={loading}>Log In
                                    {loading && (<Typography align="center">
                                        <CircularProgress value={66} />
                                    </Typography>)}
                                    </button>

                                </div>
                                <div className="or-btn">
                                    <span>OR</span>
                                </div>
                                <div className="sign-up-btn text-center">
                                    <NavLink to="/signup" className="underline-ani-blue signup">Sign Up</NavLink>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {showModal && (
                <RegisterCheckEmail
                    showModal={showModal}
                    userEmailId={userEmailId}
                 />
            )}
        </>
    )
}
export default Login;
