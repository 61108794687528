import React, { useState, useRef, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { Avatar, InputText } from "primereact";
import { TabView, TabPanel } from "primereact/tabview";
import { InputNumber } from "primereact/inputnumber";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import axios from "../../../axiosCall.js";
import Swal from 'sweetalert2';
// import axios from "axios";
import { Alert, CircularProgress, Typography } from "@mui/material";
import Cards from 'react-credit-cards';
import Cardsm from './Card';
import Styles from './Styles';

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate
  } from './cardUtils';
  import { Form ,Field } from 'react-final-form'
const SubscriptionPlan = () => {

    const secretKey = "REFR2022@GALAXY";
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [userShortName, setUserShortName] = useState("JD");
    const search = useLocation().search;
    const [successMessage, setSuccessMessage] = useState('');
    const [loader, setLoader] = useState(false);
    const [resetPasswordLink, setResetPasswordLink] = useState(false);

    const verificationCode = new URLSearchParams(search).get('c');
    const [linkPost, setLinkPost] = useState(true);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    const [userEmail, setUserEmail] = useState('');
    const [userBadd, setUserBadd] = useState('');
    const [userId, setUserId] = useState('');
    const [cardData, setCardData] = useState([]);



    const [profilePic, setProfilePic] = useState("");

    const formik = useFormik({
        initialValues: {
            user_id : "",
            name:"",
            product_id: "",
            name: "",
            number: "",
            expiry: "",
            cvc: ""

        },
        validate: (data) => {

        },
        onSubmit: (data) => {

            handleSubmitCP(data);

        },
    });
    let counter = 0;
    useEffect(() => {
        const verifyUser = async () => {
            setErrorMessage('');
            setSuccessMessage('');
            setLoader(true);
            setResetPasswordLink(false);
            try {
              const response = await axios.post(`/user_verify/${verificationCode}`);
              const { Status, StatusMessage, Error, Result } = response.data;
              let finalApiError = "";
              setLoader(false);
              if (Status === false) {
                if (Error.length > 0) {
                  Error.map(
                    (errorMessage) =>
                      //finalApiError += '<li>'+errorMessage+'</li>'
                      (finalApiError = errorMessage)
                  );
                } else {
                  finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
              } else {
                formik.setFieldValue("user_id", Result.user_id);
                formik.setFieldValue("name", Result.name);
                setUserEmail(Result.email)
                setUserBadd(Result.business_address)
                setUserId(Result.user_id);
                let str = Result.name;
                let matches = str.match(/\b(\w)/g);
                let acronym = matches.join('');
                setUserShortName(acronym);
                setResetPasswordLink(true);
              }
            } catch (err) {
              // console.log(err);
              setErrorMessage(err);
              setLoader(false);
            }
          };

          if (counter === 0) {
            //alert(counter)
            counter++;
            setLinkPost(false);
            verifyUser();

          }

    }, [linkPost]);









const Radio = ({ input, children }) =>
    // input should contain checked value to indicate
    // if the input is checked
     (
      <label>
        <input type="radio" {...input} />
        {children}
      </label>
    );

    const handleSubmitCP = async (data) => {

        //console.log(dataSend);
        //window.alert(JSON.stringify(data, 0, 2))
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, please subscribe it!'
        }).then((Res) => {
            let dataSend = {
                name : data.name,
                cvc : data.cvc,
                product_id : data.product_id,
                number: data.number,
                expiry: data.expiry,
                user_id : formik.values.user_id
            }
            try {
                const response =  axios.post('/add-subscription', dataSend);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = '';
                setLoading(false);
                if (Status === false) {
                  if (Error.length > 0) {
                    Error.map(
                      (errorMessage) =>
                        //finalApiError += '<li>'+errorMessage+'</li>'
                        (finalApiError = errorMessage)
                    );
                  } else {
                    finalApiError = StatusMessage;
                  }
                  setErrorMessage(finalApiError);
                } else {
                  setLoading(false);
                  navigate('/successfully-registered');

                  formik.resetForm();
                }
              } catch (err) {
                // console.log(err);
                setErrorMessage(err);
                setLoading(false);
              }
        })



    };

    useEffect(() => {
     },);

    return (
        <>
            <section className="template-profile-view sec-pt sec-pb edit-template-view">
                <div className="container">
                    <div className="cards-container row row-cols-1 row-cols-lg-2 g-1">
                        <div className="col col-basic-detail ps-lg-0">
                            <div className="card profile-card h-100">
                                <div className="card-body">
                                    <div className="profile-image-components text-center">
                                        {
                                            (profilePic) && (<div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root edit-dp">

                                                {/* <img src={profilePic} alt={userShortName} className="rounded-circle z-depth-2 img-fluid"  data-holder-rendered="true" /> */}
                                                <Avatar image={profilePic} className="" size="large" shape="circle" />

                                            </div>)
                                        }
                                        {
                                            (userShortName) && (profilePic === '') && (<div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root edit-dp">
                                                {userShortName}
                                            </div>)
                                        }

                                        <p className="title">{formik.values.name}</p>
                                        <div className='contact-list'>
                                        <ul className='list'>
                                            <li className='item'>
                                                <span><img src="../assets/images/email-p.png" alt="" /></span>
                                                <a className='mail' href={`mailto:${userEmail}`}>{userEmail}</a>
                                            </li>
                                            <li className='item'>
                                                <span><img src="../assets/images/home.png" alt="" /></span>
                                                <p className='address'>{ (userBadd) ? userBadd : '-'}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                <div className="card-footer text-center">
                                    <p className="text-muted mb-0">

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col pe-lg-0">
                            <TabView className="tabview-header-icon edit-profile-tabs">
                                <TabPanel
                                    header="Subscription Plan"
                                    // leftIcon="pi pi-calendar"
                                    className="tabPanelClass"
                                >
                                     <Styles>

                                    <Form
                                        onSubmit={handleSubmitCP}

                                        render={({
                                            handleSubmit,
                                            form,
                                            submitting,
                                            pristine,
                                            values,
                                            active
                                        }) => {
                                            return (
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                <div className="col-lg-6">
                                                <Field name="product_id" type="radio" value="1" component={Radio}>
                                                  REFR - Pro US$19.00 / month
                                                </Field>
                                                </div>
                                                <div className="col-lg-6">
                                                <label>
                                                    <Field name="product_id" type="radio" value="2" component="input" />
                                                 REFR - Standard US$9.00 / month
                                                </label>
                                                </div>
                                                </div>
                                                <Cardsm
                                                number={values.number || ''}
                                                name={values.name || ''}
                                                expiry={values.expiry || ''}
                                                cvc={values.cvc || ''}
                                                focused={active}
                                                />
                                                <div>

                                                <Field
                                                    name="number"
                                                    component="input"
                                                    type="text"
                                                    pattern="[\d| ]{16,22}"
                                                    placeholder="Card Number"
                                                    required

                                                    format={formatCreditCardNumber}

                                                />
                                                </div>
                                                <div>
                                                <Field
                                                    name="name"
                                                    component="input"
                                                    type="text"
                                                    placeholder="Name"
                                                    pattern="[A-Z].+"
                                                    patternMismatch="Capitalize your name!"
                                                    required
                                                    value={formik.values.name}
                                                />
                                                </div>
                                                <div>
                                                <Field
                                                    name="expiry"
                                                    component="input"
                                                    type="text"
                                                    pattern="\d\d/\d\d\d\d"
                                                    placeholder="MM/YYYY"
                                                    required
                                                    format={formatExpirationDate}
                                                    value={formik.values.expiry}
                                                />
                                                </div>
                                                <div>
                                                <Field
                                                    name="cvc"
                                                    component="input"
                                                    type="text"
                                                    pattern="\d{3,4}"
                                                    placeholder="CVC"
                                                    format={formatCVC}
                                                    required
                                                    value={formik.values.cvc}
                                                />
                                                <Field
                                                    name="user_id"
                                                    component="input"
                                                    type="hidden"
                                                    value={userId}
                                                />

                                                </div>
                                                <div className="buttons">
                                                <button type="submit" disabled={submitting}>
                                                    Submit
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={form.reset}
                                                    disabled={submitting || pristine}
                                                >
                                                    Reset
                                                </button>
                                                </div>

                                            </form>
                                            )
                                        }}
                                        />
                                        </Styles>

                                </TabPanel>


                            </TabView>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SubscriptionPlan;
