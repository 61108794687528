import { InputText, RadioButton } from "primereact";
import { Card } from "react-bootstrap";
const CreditCard = () => {

    return (
        <section className='referral-template sec-pt sec-pb'>
            <div className="container">
                <div className="referral-container">
                    <h2 className='main-heading'>Subscription</h2>
                    <Card>
                        <Card.Body>
                            <div className="card-container">
                                <div className='referral-fields credit_card_page'>
                                    <p className="title sub-titles">Enter Your Credit Card Details</p>

                                    <div className="field credit-field">
                                        <label htmlFor="FirstInput" className="form-label">Cardholder Name </label>
                                        <InputText className="form-control" placeholder="Enter Here" />
                                    </div>
                                    <div className="field credit-field">
                                        <label htmlFor="LastInput" className="form-label">Card Number</label>
                                        <InputText className="form-control" placeholder="Enter Here" />
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="field credit-field">
                                                <label htmlFor="FirstInput" className="form-label">Expiry Date</label>
                                                <InputText className="form-control" placeholder="MM/YY" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="field credit-field">
                                                <label htmlFor="LastInput" className="form-label">CVC/CVV</label>
                                                <InputText className="form-control" placeholder="Enter Here" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    <button class="btn btn-cancel me-3" type="button">Cancel</button>
                                    <button class="btn btn-submit btn-500" type="button">Pay</button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </section>

    )
}

export default CreditCard;