import React, { useState, useEffect } from 'react';
import axios from "../../../axiosCall.js";

const PrivacyPolicy = () => {
  const [privacyPolicyMessage, setPrivacyPolicyMessage] = useState('');
  const getEditData = async () => {
        
    try {
        const response = await axios.post("/get-content");
        const { Status, StatusMessage, Error, Result } = response.data;

        if (Status === true) {
            //alert(Result[0].privacy_policy_message)
            let message = Result[0].privacy_policy_message.replace(/<br>/g,'');
            
            setPrivacyPolicyMessage(message);

        }
    } catch (err) {
        
    }
};
useEffect(() => {
  
  getEditData();
}, []);
  return (
    <>
      <div className="modal fade popup-modal term-condition" id="privacypolicy" tabindex="-1" aria-labelledby="PrivacyModalLabel" aria-hidden="true">
        <div className="modal-dialog content-popup-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="main-title">Privacy Policy</h2>
              <button type="button" className="btn-close close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <div className="content" dangerouslySetInnerHTML={{__html: privacyPolicyMessage }}></div>
              {/* <div className="term-content">
                <h3 className="heading">OUR COMMITMENT TO PRIVACY</h3>
                <p className="content">Thank you for visiting our webpage, www.refrnow.com (the “Site”), a Site operated by REFR, LLC, a Massachusetts limited liability company (collectively “REFR,” “we,” “us,” or “our”). REFR provides a platform to enable clients to handle lead management and response for the real estate industry (the “Service” or “Services”). What personal information (collectively “personal information” or “information”) we do collect is to provide users (collectively “user” or “you”) with high quality referral real estate services. Safeguarding your privacy is important to us, so we have composed this Privacy Policy to inform you about our use and protection of your personal information. All of the features, services, and products on this Site are subject to the following Privacy Policy. BY REGISTERING FOR OR USING THE SITE, YOU SIGNIFY YOUR ACCEPTANCE OF THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, YOU CANNOT USE THE SITE.  </p>
                <p className="content">This Privacy Policy may be updated from time to time. REFR shall notify you of any material changes by posting the new Privacy Policy on our website. You are advised to consult this policy regularly for any changes.</p>
                <h3 className="heading">1. COLLECTION OR GATHERING OF PERSONAL INFORMATION:</h3>
                <p className="content">
                  <span className="inner-heading">1.1 Personal Information Definition. </span> Generally, personal information is information relating to an individual that can be used to identify, locate or contact that individual, alone or when combined with other personal or identifying information.
                  <p> 1.1.1 From time to time you may provide us with personal information that tells us who you are and other things about you. Personal information we may collect includes but is not limited to your name, age, postal address, telephone number, and e-mail address. We may also collect your username and password and any other demographic data necessary to maintain your account. All personal information provided to us is voluntary and will only be requested to the extent necessary to provide you our Services.</p>
                </p>
                <p className="content">
                  <span className="inner-heading">1.2 ​Method of Collection.</span><p> We collect information from you in following ways.</p>
                </p>
                <p className="content">
                  <span className="inner-heading">1.2.1 Information You Give Us.</span> In order to effectively provide our Services, we may collect personal information on the Site, including but not limited to: first and last name, phone number, personal or residential address, your email address, credit card and billing information, customer data, marketing information, password information and employer or company name. We may collect information when you contact REFR for support and customer service issues. This is to ensure that your inquiries are answered in the most efficient manner. For example, you may be required to sign-up or register for an account to fully utilize this Site’s services and features. When you do, we will ask for personal information, including but not limited to the information in the preceding paragraph 1.1.1.
                </p>
                <h3 className="heading">2. COLLECTION OR GATHERING OF NON-PERSONAL INFORMATION:</h3>
                <p className="content">
                  <span className="inner-heading">2.1 General Information Collected Through Automatic Data Collection Technologies. </span>As you navigate through and interact with our Site, we may use automatic data collection technologies to collect certain information about your browsing actions, and patterns, including but not limited to details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources you access and use on the Site. This information may also include, but not be limited to, your computer and internet connection, including your IP address, operating system, and browser type. The information we collect automatically is statistical data and may or may not include personal information, or we may maintain the information or link it with personal information we collect in other ways.
                </p>
                <p className="content">
                  <span className="inner-heading">2.2 Purpose of Collection.</span> We collect personal and non-personal information to help us improve our Site to provide you with the best and most personalized Services as possible. For example, this information helps us: estimate our audience size and usage patterns; store information about your preferences, enabling us to customize our Site according to your individual needs and interests; speed up your referral searches so you can act quickly; recognize you when you return to our Site; locate properties and referrals that meet your needs based on your previous referral history; send business communications, confirmations, invoices, notices, updates, product alerts, and administrative messages concerning your requests; detect and prevent fraudulent or illegal acts; meet legal, regulatory, insurance, security, and processing requirements; and any other purposes with your consent or as permitted or required by law.
                </p>
                <p className="content">
                  <span className="inner-heading">2.3 Children Under the Age of 18.</span> Our Site is not directed at children under the age of eighteen (18) and we do not collect or maintain information at our Site from those we actually know are under the age of eighteen (18). Further, we do not sell products or services for purchase by children. Because we do not collect such information, REFR has no such information to use or to disclose to third parties. If you are under the age of eighteen (18), you should use this Site with the supervision of an adult.
                </p>
                <p className="content">
                  <span className="inner-heading">2.4 Methods of Collection.</span>
                </p>
                <p className="content">
                  <span className="inner-heading">2.4.1 Cookies.</span> A “cookie” is a small file placed on the hard drive of your computer. Most websites use cookies as a common practice to enable the recognition of your computer or user when you revisit a website. We may or may not utilize “cookies” to personalize services to visitors. Generally, your internet browser will have a setting that will allow you to configure the limitation or rejection of all cookies. Please refer to your browser instructions (which are different for each browser) to learn more about customizing cookie settings. Please note, however, if you select a setting that limits cookies you may be unable to access certain parts of our Site. For example, without cookies, we will not be able to provide you with searches you have requested us to save. Unless you have adjusted your browser setting to refuse cookies, our system will automatically issue cookies when you direct your browser to our Site.
                </p>
                <p className="content">
                  <span className="inner-heading">2.4.2 Flash Cookies.</span> Certain features of our Site may use local stored objects, also known as “Flash Cookies,” to collect and store information about your preferences and navigation to, from, and on our Site. Flash cookies are not managed by the same browser settings as are used for cookies. You must create a separate configuration in your browser settings to limit or reject flash cookies. Please consult your browser instructions to learn more about the differences of flash cookies and their management.
                </p>
                <p className="content">
                  <span className="inner-heading">2.4.3 Web Beacons. </span> Pages or features of our the Site may contain small electronic files known as web beacons, also referred to as clear gifs, pixel tags, and single-pixel gifs, which permit us, for example, to count users who have visited those pages, to verify system and server integrity, among other things.
                </p>
                <h3 className="heading">3. DISCLOSURE AND USE OF YOUR PERSONAL INFORMATION:</h3>
                <p className="content">
                  <span className="inner-heading">3.1 How We Use Personal Information. </span> We may use your personal information you provided to customize our Services to your specific needs, including but not limited to when you: (A) submit a referral request for us to find you the real estate agent that is the best fit, under the circumstances, to consummate the real estate transaction under consideration (the “Best Fit Agent”); (B) register for email updates regarding our Services; (C) embed a link to a property on a third party website; (D) make referral inquiries; and (E) contact REFR regarding our leads or Services.
                </p>
                <p className="content">
                  <span className="inner-heading">3.2 Social Network Sharing.</span> Our Site includes social network sharing, such as the Facebook recommend button or LinkedIn referral appearing on referral listings. These third party links may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the Feature to function properly. Social network sharing are either hosted by a third party or hosted directly on our Site. This Privacy Policy does not govern third party social media networks. Please refer to their individual policies to learn more about how they manage personal information.
                </p>
                <p className="content">
                  <span className="inner-heading">3.3 Disclosure. </span> We may disclose information about users of this Site, and information that does not identify any individual without restriction. We do share personal information under the following circumstances: to REFR’s subsidiaries and affiliates; to contractors, service providers, and other third parties we use to support your referral submission who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them; to fulfill the purpose for which you provide it; to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of REFR’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by REFR about our Website users is among the assets transferred; and for any other purpose disclosed by us when you provide the information.
                </p>
                <p className="content">
                  <span className="inner-heading">3.4 Legal and Safety Disclosure.</span> Under certain circumstances we will release personal information to enforce or apply our Terms and Conditions. REFR reserves the right to use or disclose your personal information in response to subpoenas, court orders, warrants, or legal process, or to otherwise establish or exercise our legal rights or defend against legal claims or in the event you violate or breach an agreement with REFR. In addition, REFR will use and disclose your personal information if we reasonably believe you will harm the property or rights of REFR, its owners, or those of REFR’s other customers. Finally, we will use or disclose your personal information if we believe it is necessary to share such information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person or property, violations of REFR’s other policies, or as otherwise required by law when responding to subpoenas, court orders and other legal processes.
                </p>
                <h3 className="heading">  4. PROTECTING THE CONFIDENTIALITY OF YOUR PERSONAL INFORMATION:</h3>
                <p className="content">
                  <span className="inner-heading">4.1 Employee and Agent Access.</span> We limit access to personal information to those employees and agents of REFR and its affiliates who need it to fulfill their business duties and responsibilities. Employees and agents must adhere to REFR privacy policies. Any employee or agent found to be violating this Privacy Policy may be subject to disciplinary action, up to and including dismissal. Vendors and other outside contractors we engage are subject to our contractual requirements to ensure that sensitive personal information is safeguarded at all times.
                </p>
                <p className="content">
                  <span className="inner-heading">4.2 Security Safeguards.</span> At REFR, security is a priority. We take many steps to protect your personal information, which may include, among others things, encryption, physical access security, and other appropriate technologies. We continually review and enhance our security systems, as necessary. Unfortunately, however, the transmission of information via the internet is not 100% secure. Although we and our hosting service do our best to protect your personal information against unauthorized access, accidental or intentional manipulation and loss, by exercising reasonable industry standards, we cannot guarantee the security of your personal information transmitted to our Site. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Site.
                </p>
                <p className="content">
                  <span className="inner-heading">4.3 Data Retention.</span> REFR will retain your personal information for as long as is reasonably necessary to fulfill the purposes outlined in this privacy notice unless a longer retention period is required or permitted by law. We may retain personal information for longer where required by our legal and regulatory obligations, indemnity obligations, or where we believe it is necessary to establish, defend or protect our legal rights and interests or those of others.
                </p>
                <h3 className="heading">  5. LIMITATION OF GOVERNANCE:</h3>
                <p className="content">This Privacy Policy applies to your use of this Site only. The following subsections are, but not limited to, places, parties, or products and services that are not governed by this Privacy Policy. </p>
                <p className="content">
                  <span className="inner-heading">5.1 Public Areas.</span> This privacy policy does not apply to any public area of the site or services. In the event you submit personal information to contact another member, contact a property owner or agent about a potential referral, create a referral request, add a contact on a referral listing, such information becomes publicly available and REFR is not responsible for the personal information you choose to submit to these services, forums, listings, or persons.
                </p>
                <p className="content">
                  <span className="inner-heading">5.2 Our Website May Be Linked To Third-Party Websites.</span> Information you volunteer or information collected by any third party, including through any application or content (including advertising) that may link to or be accessible from, or on, the Site is not governed by this Privacy Policy. We have not reviewed all of the information on other webpages and are not responsible for the content of any third party websites, products, or services that may be offered through third party links. We highly recommend you read the privacy agreements of any of the third party sites you visit, as our Privacy Policy and practices do not apply to them. Please be advised that we take no responsibility for the privacy practices of other websites and expressly disclaim liability for their conduct, which includes the management of your personal information.
                </p>
                <h3 className="heading">6. PROHIBITED CONDUCT:</h3>
                <p className="content">
                  <span className="inner-heading">6.1 Spam Mailing Using Contact US Feature.</span> You may NOT use our “Contact US” feature, or other communication services we provide to send spam (i.e. unsolicited commercial email) or otherwise send content that would violate this Privacy Policy or REFR’s Terms and Conditions. We store this information for the sole purpose of sending this one-time email and for tracking the success of our referral program. We may monitor, track or save the use of this feature, but will be under no obligation to monitor, track, or save messages sent from this feature or others. We also do not rent or sell these email addresses.
                </p>
                <h3 className="heading"> 7. GOVERNING LAW: </h3>
                <p className="content">
                  <span className="inner-heading">7.1 Choice of Law.</span> Use of this Site and any actions arising out of, or relating to the use or subject matter of this Site, or this Privacy Policy, shall be governed by the laws of the Commonwealth of Massachusetts without regard to the Commonwealth's rules concerning choice of law. If any material or services on this Site are contrary to the laws of the place where you are when you access it, then the Site is not intended for you, and we politely ask you to refrain from using the Site. You are solely responsible for knowing of, and complying with, the laws of the jurisdiction in which you are accessing the Site.
                </p>
                <p className="content">
                  <span className="inner-heading">7.2 Forum for Disputes.</span> In the event that you desire to assert any claims arising out of the use of the Site or relating to the subject matter of this Privacy Policy, you shall assert those claims in the state or federal courts located in Hampden County, Massachusetts, in which event any counterclaims to be asserted in response may also be asserted in that forum. REFR reserves the right, at our sole discretion, to commence and pursue proceedings in alternative jurisdictions.
                </p>
                <p className="content">
                  <span className="inner-heading">7.3 Severance.</span> In the event any provision of this Privacy Policy is held to be invalid or unenforceable, the remainder the Privacy Policy shall continue in full force and effect, and the provision held to be invalid or unenforceable shall be automatically amended to most closely approximate the original provision on terms that are valid and enforceable and the court or other adjudicating authority holding such provision invalid or unenforceable shall confirm the terms of such amendment accordingly.
                </p>
                
                <h3 className="heading">  8. CONTACT US:</h3>
                <p className="content">
                  <span className="inner-heading">8.1 Questions or Concerns.</span> If you have any questions or concerns about this Privacy Policy, please contact us at:
                </p>
                <ul className="list-c">
                  <li> <a href="mailto:support@refrnow.com">support@refrnow.com</a></li>
                  <li>11 Herbert P. Almgren Drive, Agawam, Massachusetts 01001</li>
                  <li>(413) 217-1975</li>
                </ul>
              </div> */}
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default PrivacyPolicy;
