import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { InputText } from 'primereact';
import { Alert, CircularProgress,Typography} from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import { InputMask} from 'primereact';
import { Dropdown } from 'primereact/dropdown';

const AddPeople = (props) => {
    const [show, setShow] = useState(props.showAddPeople);
    const navigate = useNavigate();
    const peopleModalClose = () => setShow(false);
    const {  user,logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [dbStatesName, setDbStatesName] = useState([]);

    const formik = useFormik({
        initialValues: {
            people_id:'',
            users_id: '',
            first_name: '',
            last_name: '',
            email: '',
            contact_number:'',
            address: '',
            city: '',
            state_id: '',
            zipcode:'',
            host_name: window.location.origin,
        },
        validate: (data) => {
            let errors = {};

            if (data.first_name == null || data.first_name.trim() === '') {
                errors.first_name = 'First name is required.';
            }
            else if (data.first_name.length > 50)
            {
                errors.first_name = 'Max characters: 50';
            }

            if (data.last_name == null || data.last_name.trim() === '') {
                errors.last_name = 'Last name is required.';
            }
            else if (data.last_name.length > 50)
            {
                errors.last_name = 'Max characters: 50';
            }

            if(data.email)
            {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                    errors.email = 'Invalid email address. e.g. example@email.com';
                }
            }
            

            // if (!data.contact_number) {
            //     errors.contact_number = 'Phone is required.';
            // }

            // if (data.address == null || data.address.trim() === '') {
            //     errors.address = 'Street address is required.';
            // }

            // if (data.city == null || data.city.trim() === '') {
            //     errors.city = 'City is required.';
            // }
            // if (!data.state_id) {
            //     errors.state_id = 'State is required.';
            // }

            // if (data.zipcode == null || data.zipcode.trim() === '') {
            //     errors.zipcode = 'Zip code is required.';
            // }
            if (data.zipcode) {
                if (!/^\d{5}(-\d{4})?$/.test(data.zipcode)) {
                    errors.zipcode = 'Invalid zip code. e.g. 00501';
                }
            }

            return errors;
        },
        onSubmit:  (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    useEffect(() => {
        if (user !== null) {
            formik.setFieldValue("users_id", user.id ? user.id : "");
        }

    }, [user]);
    useEffect(() => {
        const fetchStatesData = async () => {
            try {
                const response = await axios.post("/get-all-states-people");
                const { Status, StatusMessage, Error, Result } = response.data;

                if (Status === true) {
                    //alert(Result)
                    setDbStatesName(Result);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchStatesData();

    }, []);
    useEffect(() => {
        const fetchEditData = async() => {
            let formData = new FormData();
            formData.append('people_id',props.editRowId);

            try {
                const response = await axios.post('/edit-people', formData);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = '';
                setLoading(false);
                if (Status === false) {
                  if (Error.length > 0) {
                    Error.map(
                      (errorMessage) =>
                        //finalApiError += '<li>'+errorMessage+'</li>'
                        (finalApiError = errorMessage)
                    );
                  } else {
                    finalApiError = StatusMessage;
                  }
                  setErrorMessage(finalApiError);
                  window.scrollTo(0, 0);
                } else {
                    setLoading(false);
                    formik.setFieldValue("people_id", props.editRowId);
                    formik.setFieldValue("first_name", Result.first_name);
                    formik.setFieldValue("last_name", Result.last_name);
                    formik.setFieldValue("email", Result.email);
                    formik.setFieldValue("contact_number", Result.contact_number);
                    formik.setFieldValue("address", Result.address);
                    formik.setFieldValue("city", Result.city);
                    formik.setFieldValue("state_id", Result.state_id);
                    formik.setFieldValue("zipcode", Result.zipcode);

                }
              } catch (err) {
                setLoading(false);
                window.scrollTo(0, 0);
                
                if(err.response.status && err.response.status==401){
                    logout();
                    navigate('/login');
                }

            }
        }
        if(props.editRowId > 0)
        {
            fetchEditData();
        }
    }, []);

    const selectedStateTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.state}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };
    const stateOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.state}</div>
            </div>
        );
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        try {
            const response = await axios.post('/add-people', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
              if (Error.length > 0) {
                Error.map(
                  (errorMessage) =>
                    //finalApiError += '<li>'+errorMessage+'</li>'
                    (finalApiError = errorMessage)
                );
              } else {
                finalApiError = StatusMessage;
              }
              setErrorMessage(finalApiError);
            } else {
                setLoading(false);
                peopleModalClose();
                props.changeStatusPeople();
                props.reloadListing();
            }
          } catch (err) {
            setLoading(false);
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }else{
                setErrorMessage("Something went wrong");
               
            }

        }
    };

    return (
        <>
            <Modal enforceFocus={false} className='add-people-popup' show={show} onHide={peopleModalClose} backdrop="static" >
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header className='add-people-header'>
                        <Modal.Title>{(props.editRowId) ? 'Edit' : 'Add'} People</Modal.Title>
                    </Modal.Header>
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                        {errorMessage}
                        </Alert>
                    )}
                    <Modal.Body className='addpeople-section'>
                        <div className="row gx-3 form-section">
                            <div className="field col-md-6 profile-input" >
                                <label htmlFor="inputEmail4" className="form-label">First Name <span className="reuired-star">*</span></label>
                                <InputText placeholder="Enter Here"
                                autoComplete="off"
                                name="first_name"
                                value={formik.values.first_name} onChange={formik.handleChange}
                                className={classNames("form-control", { 'p-invalid': isFormFieldValid('first_name') })}
                                />
                                 {getFormErrorMessage('first_name')}
                            </div>
                            <div className="field col-md-6 profile-input right">
                                <label htmlFor="inputPassword4" className="form-label">Last Name <span className="reuired-star">*</span></label>
                                <InputText
                                autoComplete="off"
                                placeholder="Enter Here" name="last_name"
                                value={formik.values.last_name} onChange={formik.handleChange}
                                className={classNames("form-control", { 'p-invalid': isFormFieldValid('last_name') })}
                                />
                                 {getFormErrorMessage('last_name')}
                            </div>
                            <div className="field col-md-6 profile-input">
                                <label htmlFor="inputEmail4" className="form-label">Email Address</label>
                                <InputText
                                autoComplete="off"
                                 placeholder="Enter Here" name="email"
                                value={formik.values.email} onChange={formik.handleChange}
                                className={classNames("form-control", { 'p-invalid': isFormFieldValid('email') })}
                                />
                                 {getFormErrorMessage('email')}
                            </div>
                            <div className="field col-md-6 profile-input right">
                                <label htmlFor="inputPassword4" className="form-label">Phone</label>
                                <InputMask
                                autoComplete="off"
                                mask="(999) 999-9999" placeholder="(999) 999-9999"    name="contact_number"
                                    value={formik.values.contact_number} onChange={formik.handleChange}
                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('contact_number') })}
                                />
                                 {getFormErrorMessage('contact_number')}
                            </div>
                            <div className="home-heading">
                                <h2>Home Address</h2>

                            </div>
                            <div className="field col-12 street-input">
                                <label htmlFor="inputAddress" className="form-label">Street Address</label>
                                <InputText
                                autoComplete="off"
                                placeholder="Enter Here" name="address"
                                value={formik.values.address} onChange={formik.handleChange}
                                className={classNames("form-control", { 'p-invalid': isFormFieldValid('address') })}
                                />
                                 {getFormErrorMessage('address')}
                            </div>
                            <div className="field col-md-4 bottom-input">
                                <label htmlFor="inputCity" className="form-label">City</label>
                                <InputText
                                autoComplete="off"
                                placeholder="Enter Here" name="city"
                                value={formik.values.city} onChange={formik.handleChange}
                                className={classNames("form-control", { 'p-invalid': isFormFieldValid('city') })}
                                />
                                 {getFormErrorMessage('city')}
                            </div>
                            <div className="field col-md-4 bottom-input">
                                <label htmlFor="inputZip" className="form-label">Zip Code</label>
                                <InputText
                                autoComplete="off"
                                placeholder="Enter Here" name="zipcode"
                                value={formik.values.zipcode} onChange={formik.handleChange}
                                className={classNames("form-control", { 'p-invalid': isFormFieldValid('zipcode') })}
                                />
                                 {getFormErrorMessage('zipcode')}
                            </div>
                            <div className="field col-md-4 bottom-input-state">
                                <label htmlFor="inputState" className="form-label">State</label>
                                <Dropdown
                                    name="state_id"
                                    value={
                                        formik.values.state_id
                                    }
                                    options={
                                        dbStatesName
                                    }
                                    className={classNames(
                                        {
                                            "p-invalid":
                                                isFormFieldValid(
                                                    "state_id"
                                                ),
                                        }
                                    )}
                                    optionLabel="state"
                                    filter
                                    filterBy="state"
                                    placeholder="Select a State"
                                    valueTemplate={
                                        selectedStateTemplate
                                    }
                                    itemTemplate={
                                        stateOptionTemplate
                                    }
                                    onChange={
                                        formik.handleChange
                                    }
                                />
                                {getFormErrorMessage(
                                    "state_id"
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='add-people-footer'>
                        <button type="button" className='btn btn-cancel' variant="secondary" onClick={() => {peopleModalClose(); props.changeStatusPeople();}} disabled={loading}>
                            Close
                        </button>
                        <button type="submit" className='btn btn-submit btn-submit-loader btn-500' variant="primary" disabled={loading}>
                        {(props.editRowId) ? 'Edit' : 'Add'}
                            {loading && (<Typography align="center">
                            <CircularProgress value={66} />
                        </Typography>)}
                        </button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default AddPeople;

