import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { InputText } from 'primereact';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

const commissionData = [
    {
      id: '2.0%',
      name: '2.0%'
    },
    {
      id: '2.25%',
      name: '2.25%'
    },
    {
      id: '2.5%',
      name: '2.5%'
    },
    {
        id: '2.75%',
        name: '2.75%'
    },
    {
        id: '3.0%',
        name: '3.0%'
    },
    {
        id: '3.25%',
        name: '3.25%'
    },
    {
        id: '3.5%',
        name: '3.5%'
    },
    {
        id: 'other',
        name: 'Other'
    }
  ];

const PotentialCommissionEditForm = (props) => {
    const [show, setShow] = useState(props.showPotentialDataEditForm);
    const navigate = useNavigate();
    const modalClose = () => setShow(true);
    const { user, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [referralFeePercentageError, setReferralFeePercentageError] = useState(false);
    const [refrReferralFeePercentageError, setRefrReferralFeePercentageError] = useState(false);

    const formik = useFormik({
        initialValues: {
            users_id: props.loginUserId,
            users_name: props.loginUserName,
            referral_id: props.referralRowId,
            referral_users_id:props.referralUsersId,
            referred_agent_users_id:props.referredAgentUsersId,
            edit_referral_commission_log_id:'',
            sales_price: '',
            closing_date: '',
            commission: '',
            other_commission:'',
            commission_earned: '',
            referral_fee_due:'',
            refr_referral_fee_percent: '',
            user_fee_percent:'',
            refr_potential_commission: '',
            user_potential_commission:'',
            potential_referral_fee_percentage:'',
            host_name: window.location.origin,
            referral_fee_user_receive:'',
            referral_platform_fee:'',
            referral_fee_charged_to_receiving_agent:'',
            referral_fee_due_to_referring_agent:'',
            referring_agent_referral_fee_action:'',
        },
        validate: (data) => {
            let errors = {};
            ///alert(data.type)
            
            if (!data.potential_referral_fee_percentage) {
                errors.potential_referral_fee_percentage = 'Referral fee percentage  is required.';
            } 
            else
            {
                if (data.potential_referral_fee_percentage)
                {
                    if (!isPositiveFloat(data.potential_referral_fee_percentage)) {
                        errors.potential_referral_fee_percentage = "Invalid referral fee percentage. e.g. 5";
                    }
                    else if(parseFloat(data.potential_referral_fee_percentage) > 50)
                    {
                        errors.potential_referral_fee_percentage = "Max: 50 percentage";
                    }
                }
            }
            if(props.referraDetail.referral_created_users_type === 'ambassador') {
                if(data.refr_referral_fee_percent) {
                    if(!data.referral_fee_user_receive){
                        if (!isPositiveFloatForRefrFee(data.refr_referral_fee_percent)) {
                        
                            errors.refr_referral_fee_percent = "Invalid refr referral fee percentage. e.g. 5";
                            
                        }
                        else if(parseFloat(data.refr_referral_fee_percent) > parseFloat(data.potential_referral_fee_percentage))
                        {
                            
                            errors.refr_referral_fee_percent = "Max: "+parseFloat(data.potential_referral_fee_percentage).toFixed(2)+" percentage";
                        }
                    }
                }
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    const isPositiveFloat = (s) => {
        return !isNaN(s) && Number(s) > 0;
    }
    const isPositiveFloatForRefrFee = (s) => {
        return !isNaN(s) && Number(s) >= 0;
    }
    function addZeroes(num) {
        
        if(num === 3 || num === 2)
            return num.toFixed(1);
        else
            return num;
      }
    useEffect(() => {
        if (user !== null) {
            formik.setFieldValue("users_id", user.id ? user.id : "");
        }
        //alert(props.referralCommissionLogDetail.length)
            
        if(props.referraDetail.average_sales_value)
        {
            if(props.referraDetail.referral_fee_user_receive) {
                formik.setFieldValue("referral_fee_user_receive", parseFloat(props.referraDetail.referral_fee_user_receive).toFixed(2))
            }
            if(props.referraDetail.referral_platform_fee) {
                formik.setFieldValue("referral_platform_fee", parseFloat(props.referraDetail.referral_platform_fee).toFixed(2))
            }
            if(props.referraDetail.referral_fee_charged_to_receiving_agent) {
                formik.setFieldValue("referral_fee_charged_to_receiving_agent", props.referraDetail.referral_fee_charged_to_receiving_agent)
            }
            if(props.referraDetail.referral_fee_due_to_referring_agent) {
                formik.setFieldValue("referral_fee_due_to_referring_agent", props.referraDetail.referral_fee_due_to_referring_agent)
            }
            if(props.referraDetail.referring_agent_referral_fee_action) {
                formik.setFieldValue("referring_agent_referral_fee_action", props.referraDetail.referring_agent_referral_fee_action)
            }

            if(commissionExists(props.referraSettingDetail[0].potential_commission))
            {
                let num = addZeroes( props.referraSettingDetail[0].potential_commission )
                
                formik.setFieldValue("commission", {
                    id: num+'%',
                    name: num+'%'
                })
                
            }
            else
            {
                formik.setFieldValue("commission", {
                    id: 'other',
                    name: 'Other'
                })
                formik.setFieldValue("other_commission", parseFloat(props.referraSettingDetail[0].potential_commission).toFixed(2))
                
            }

            formik.setFieldValue("commission_earned", parseFloat(props.referraDetail.total_potential_commission).toFixed(2))
            formik.setFieldValue("sales_price", parseFloat(props.referraDetail.average_sales_value).toFixed(2))
            formik.setFieldValue("referral_fee_due", parseFloat(props.referraDetail.potential_referral_fee).toFixed(2))
            formik.setFieldValue("potential_referral_fee_percentage", parseFloat(props.referraDetail.potential_referral_fee_percentage).toFixed(2))
            if(props.referraDetail.refr_plan_commission >= 0 && props.referraDetail.refr_plan_commission != null)
            {
                formik.setFieldValue("refr_referral_fee_percent", parseFloat(props.referraDetail.refr_plan_commission).toFixed(2))
            }
            else
                formik.setFieldValue("refr_referral_fee_percent", '')

            if(props.referraDetail.agent_plan_commission >= 0 && props.referraDetail.agent_plan_commission != null)
                formik.setFieldValue("user_fee_percent", parseFloat(props.referraDetail.agent_plan_commission).toFixed(2))
            else
                formik.setFieldValue("user_fee_percent", '')

                
            if(props.referraDetail.potential_refr_earning >= 0 && props.referraDetail.refr_plan_commission != null)
            {
                //alert(props.referraDetail.potential_refr_earning)
                formik.setFieldValue("refr_potential_commission", parseFloat(props.referraDetail.potential_refr_earning).toFixed(2))
            }  
              
            if(props.referraDetail.potential_user_earning >= 0 && props.referraDetail.potential_user_earning != null)
                formik.setFieldValue("user_potential_commission", parseFloat(props.referraDetail.potential_user_earning).toFixed(2))
        }
    }, [user]);
    
    function commissionExists(commission) {
        return commissionData.some(function(el) {
          return el.name.replace('%','') == commission;
        }); 
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        try {
            const response = await axios.post('/referral-update-potential-commission-calculation', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                setLoading(false);
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        modalClose();
                        props.changeStatuEditPotentialCommissionForm();
                    }
                });
            }
        } catch (err) {
            setLoading(false);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } 

        }
    };
    const handleCalculation = (e) => {
        setReferralFeePercentageError('');
        setRefrReferralFeePercentageError('');
        let commissionEarned = formik.values.commission_earned;
        let referralFeePercentage = e.target.value;
        let isValidFeePercentage = true;
        if(referralFeePercentage) {
            if (!isPositiveFloat(referralFeePercentage)) {
                isValidFeePercentage = false;
                setReferralFeePercentageError("Invalid referral fee percentage. e.g. 5")
            }
            else if(parseFloat(referralFeePercentage) > 50)
            {
                isValidFeePercentage = false;
                setReferralFeePercentageError("Max: 50 percentage")
            }
        }
        

        if((commissionEarned) && (referralFeePercentage) && (isValidFeePercentage))
        {
            let calulation1 = parseFloat(commissionEarned) * parseFloat(referralFeePercentage);
            let refrCommission = parseFloat(calulation1) / parseFloat(100);

            formik.setFieldValue("referral_fee_due", refrCommission.toFixed(2))

            if(props.referraDetail.referral_created_users_type === 'ambassador') {
                if(formik.values.referral_fee_user_receive) {
                    if(formik.values.referring_agent_referral_fee_action === 'yes') {

                        let finalData = parseFloat(refrCommission) + parseFloat(199);
                        formik.setFieldValue("referral_fee_due", finalData.toFixed(2));

                    }
                    formik.setFieldValue("user_fee_percent", parseFloat(referralFeePercentage).toFixed(2))

                    let calulation2 = parseFloat(refrCommission) * (parseFloat(referralFeePercentage) / parseFloat(referralFeePercentage));
                    formik.setFieldValue("user_potential_commission", calulation2.toFixed(2))
                }
                else {
                    if(parseFloat(referralFeePercentage) > 5)
                    {
                        let userFeePercent = parseFloat(referralFeePercentage) - 5;
                        formik.setFieldValue("refr_referral_fee_percent", (5.00).toFixed(2))
                        formik.setFieldValue("user_fee_percent", userFeePercent.toFixed(2))
    
                        let calulation1 = parseFloat(refrCommission) * (parseFloat(5) / parseFloat(referralFeePercentage));
                        formik.setFieldValue("refr_potential_commission", calulation1.toFixed(2))
    
                        let calulation2 = parseFloat(refrCommission) * (parseFloat(userFeePercent) / parseFloat(referralFeePercentage));
                        formik.setFieldValue("user_potential_commission", calulation2.toFixed(2))
                    }
                    else
                    {
                        //alert(parseFloat(referralFeePercentage).toFixed(2))
                        formik.setFieldValue("refr_referral_fee_percent", '')
                        formik.setFieldValue("user_fee_percent", parseFloat(referralFeePercentage).toFixed(2))
    
                        let calulation2 = parseFloat(refrCommission) * (parseFloat(referralFeePercentage) / parseFloat(referralFeePercentage));
                        formik.setFieldValue("user_potential_commission", calulation2.toFixed(2))
                        formik.setFieldValue("refr_potential_commission", "")
                    }
                }
            }
        }
        else
        {
            formik.setFieldValue("referral_fee_due", "")
            if(props.referraDetail.referral_created_users_type === 'ambassador') {
                formik.setFieldValue("user_fee_percent", '')
                formik.setFieldValue("refr_referral_fee_percent", '')                
                formik.setFieldValue("user_potential_commission", '')
                formik.setFieldValue("refr_potential_commission", "")
            }
        }
    }
    
    const handleRefrReferralFeeCalculation = (e) => {
        setReferralFeePercentageError('');
        setRefrReferralFeePercentageError('');
        let referral_fee_due = formik.values.referral_fee_due;
        let potential_referral_fee_percentage = formik.values.potential_referral_fee_percentage;

        let refrReferralFeePercentage = e.target.value;

        let isValidFeePercentage = true;
        if(refrReferralFeePercentage) {
            if (!isPositiveFloatForRefrFee(refrReferralFeePercentage)) {
                isValidFeePercentage = false;
                setRefrReferralFeePercentageError("Invalid refr referral fee percentage. e.g. 5")
            }
            else if(parseFloat(refrReferralFeePercentage) > parseFloat(potential_referral_fee_percentage))
            {
                isValidFeePercentage = false;
                setRefrReferralFeePercentageError("Max: "+parseFloat(potential_referral_fee_percentage).toFixed(2)+" percentage")
            }
        }

        if((referral_fee_due) && (refrReferralFeePercentage) && (isValidFeePercentage))
        {
            let calulation1 = parseFloat(referral_fee_due) * (parseFloat(refrReferralFeePercentage) / potential_referral_fee_percentage);
            formik.setFieldValue("refr_potential_commission", calulation1.toFixed(2))

            let userFeePercentage = potential_referral_fee_percentage - refrReferralFeePercentage;
            formik.setFieldValue("user_fee_percent", userFeePercentage.toFixed(2))

            let calulation2 = parseFloat(referral_fee_due) * (parseFloat(userFeePercentage) / potential_referral_fee_percentage);
            formik.setFieldValue("user_potential_commission", calulation2.toFixed(2))
        }
        else if((referral_fee_due)  && (isValidFeePercentage))
        {
            let userFeePercentage = potential_referral_fee_percentage;
            formik.setFieldValue("user_fee_percent", userFeePercentage.toFixed(2))

            let calulation2 = parseFloat(referral_fee_due) * (parseFloat(userFeePercentage) / potential_referral_fee_percentage);
            formik.setFieldValue("user_potential_commission", calulation2.toFixed(2))
            formik.setFieldValue("refr_potential_commission", "")
        }
        else
        {
            formik.setFieldValue("refr_potential_commission", "")
            formik.setFieldValue("user_potential_commission", "")
            formik.setFieldValue("user_fee_percent", "")
        }
    }
    return (
        <>
            <Modal className='add-people-popup' show={show} onHide={modalClose} data-keyboard="false" backdrop="static" >
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header className='add-people-header'>
                        <Modal.Title> Potential Commission</Modal.Title>
                    </Modal.Header>
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                            {errorMessage}
                        </Alert>
                    )}
                    <Modal.Body className='addpeople-section assign-agent-popup'>
                    <div className="row gx-3 form-section">
                            <div className="field col-md-6 profile-input doller-value" >
                                <label htmlFor="inputEmail4" className="form-label">{(props.referraDetail.buyer_or_seller === 'buyer_referral' ? 'Estimated Purchase Price' : 'Estimated Sale Price')} <span className="reuired-star">*</span></label>
                                <span className="p-input-icon-left custom-input-left">

                                    <i className="pi pi-dollar"></i>
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control ps-4",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "sales_price"
                                                    ),
                                            }
                                        )}
                                        name="sales_price"
                                        placeholder=""
                                        value={
                                            formik.values.sales_price
                                        }
                                        disabled={true}
                                        onChange={(e) => {formik.handleChange(e)}}
                                        onKeyUp={(e) => handleCalculation(e,'sales_price')}
                                    /></span>
                                {getFormErrorMessage('sales_price')}

                                
                            </div>

                            <div className="col-md-6">
                                <div className="field">
                                    <label htmlFor="" className="form-label">Average Commission Percentage <span className="reuired-star">*</span></label>
                                    <Dropdown disabled={true} className={classNames("form-select", { 'p-invalid': isFormFieldValid('commission') })} options={commissionData} defaultValue={formik.values.commission} optionLabel="name" placeholder="Select" name="commission" value={formik.values.commission}
                                        
                                        />
                                    {getFormErrorMessage('commission')}
                                </div>
                            </div>

                            

                        </div>

                        <div className="row gx-3 form-section">
                            <div className="field col-md-6 profile-input doller-value">
                                <label htmlFor="inputEmail4" className="form-label">Total Commission </label>
                                <span className="p-input-icon-left custom-input-left">

                                    <i className="pi pi-dollar"></i>
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control ps-4",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "commission_earned"
                                                    ),
                                            }
                                        )}
                                        name="commission_earned"
                                        placeholder=""
                                        value={
                                            formik.values.commission_earned
                                        }
                                        onChange={formik.handleChange}
                                        disabled={true}
                                    /></span>
                                {getFormErrorMessage('commission_earned')}
                                    
                            </div>
                            <div className={`field col-md-6 profile-input doller-value ${((formik.values.referral_fee_user_receive) &&(formik.values.referring_agent_referral_fee_action === 'yes')) ? 'refr_two_col' : ''}`} >
                                <label htmlFor="inputEmail4" className="form-label"> REFR Referral Fee </label>
                                    <div className='two_input_col'>
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control ps-4",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "potential_referral_fee_percentage"
                                                    ),
                                            }
                                        )}
                                        name="potential_referral_fee_percentage"
                                        placeholder=""
                                        value={
                                            formik.values.potential_referral_fee_percentage
                                        }
                                        onKeyUp={(e) => {handleCalculation(e)}}
                                        onChange={formik.handleChange}
                                        
                                    />
                                    <span className="p-input-icon-right custom-input-right percentage_ico"><i className="pi pi-percentage"></i></span>
                                    {
                                        ((formik.values.referral_fee_user_receive) &&(formik.values.referring_agent_referral_fee_action === 'yes')) && ( <>+ <InputText
                                            autoComplete="off"
                                            className={classNames(
                                                "form-control ps-4",
                                                {
                                                    "p-invalid":
                                                        isFormFieldValid(
                                                            "referral_platform_fee"
                                                        ),
                                                }
                                            )}
                                            name="referral_platform_fee"
                                            placeholder=""
                                            value={
                                                formik.values.referral_platform_fee
                                            }
                                            disabled={true}
                                            
                                        /></>)
                                    }
                                {getFormErrorMessage('potential_referral_fee_percentage')}
                                    {(referralFeePercentageError) && (!getFormErrorMessage('potential_referral_fee_percentage')) && (
                                    <small className="p-error">{referralFeePercentageError}</small>
                                )}
                                </div>
                            </div>
                            <div className="field col-md-6 profile-input doller-value">
                                <label htmlFor="inputEmail4" className="form-label"> Referral Fee </label>
                                <span className="p-input-icon-left custom-input-left">

                                    <i className="pi pi-dollar"></i>
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control ps-4",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "referral_fee_due"
                                                    ),
                                            }
                                        )}
                                        name="referral_fee_due"
                                        placeholder=""
                                        value={
                                            formik.values.referral_fee_due
                                        }
                                        onChange={formik.handleChange}
                                        disabled={true}
                                    /></span>
                                {getFormErrorMessage('referral_fee_due')}
                            </div>
                            
                            
                        </div>
                        <div className="row gx-3 form-section">
                        <div className="field col-md-6 profile-input doller-value">
                                <label htmlFor="inputEmail4" className="form-label"> REFR Portion </label>
                                    
                                    
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control ps-4",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "refr_referral_fee_percent"
                                                    ),
                                            }
                                        )}
                                        name="refr_referral_fee_percent"
                                        placeholder=""
                                        value={
                                            formik.values.refr_referral_fee_percent
                                        }
                                        onKeyUp={(e) => {handleRefrReferralFeeCalculation(e)}}
                                        onChange={formik.handleChange}
                                        disabled={props.referraDetail.referral_created_users_type === 'agent' ? true : (props.referraDetail.referral_fee_user_receive) ? true : false}
                                        
                                    />{
                                        (!formik.values.referral_fee_user_receive) && (<span className="p-input-icon-right custom-input-right percentage_ico"><i className="pi pi-percentage"></i></span>)}
                                
                                {getFormErrorMessage('refr_referral_fee_percent')}
                                    {(refrReferralFeePercentageError) && (!getFormErrorMessage('refr_referral_fee_percent')) && (
                                    <small className="p-error">{refrReferralFeePercentageError}</small>
                                )}
                            </div>
                            <div className="field col-md-6 profile-input doller-value">
                                <label htmlFor="inputEmail4" className="form-label"> REFR Commission </label>
                                <span className="p-input-icon-left custom-input-left">

                                    <i className="pi pi-dollar"></i>
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control ps-4",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "refr_potential_commission"
                                                    ),
                                            }
                                        )}
                                        name="refr_potential_commission"
                                        placeholder=""
                                        value={
                                            formik.values.refr_potential_commission
                                        }
                                        onChange={formik.handleChange}
                                        disabled={true}
                                    /></span>
                                {getFormErrorMessage('refr_potential_commission')}
                            </div>
                            <div className="field col-md-6 profile-input doller-value">
                                <label htmlFor="inputEmail4" className="form-label"> {(props.referraDetail.referral_created_users_type === 'ambassador') ? 'Ambassador' : 'Agent'} Portion </label>
                                    
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control ps-4",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "user_fee_percent"
                                                    ),
                                            }
                                        )}
                                        name="user_fee_percent"
                                        placeholder=""
                                        value={
                                            formik.values.user_fee_percent
                                        }
                                        onKeyUp={(e) => {handleCalculation(e)}}
                                        onChange={formik.handleChange}
                                        disabled={true}
                                    /><span className="p-input-icon-right custom-input-right percentage_ico"><i className="pi pi-percentage"></i></span>
                                
                                {getFormErrorMessage('user_fee_percent')}
                                   
                            </div>
                            
                            <div className="field col-md-6 profile-input doller-value">
                                <label htmlFor="inputEmail4" className="form-label"> {(props.referraDetail.referral_created_users_type === 'ambassador') ? 'Ambassador' : 'Agent'}  Commission </label>
                                <span className="p-input-icon-left custom-input-left">

                                    <i className="pi pi-dollar"></i>
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control ps-4",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "user_potential_commission"
                                                    ),
                                            }
                                        )}
                                        name="user_potential_commission"
                                        placeholder=""
                                        value={
                                            formik.values.user_potential_commission
                                        }
                                        onChange={formik.handleChange}
                                        disabled={true}
                                    /></span>
                                {getFormErrorMessage('user_potential_commission')}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='add-people-footer'>
                        <button type="button" className='btn btn-cancel btn-500' variant="secondary" onClick={() => { modalClose(); props.changeStatuEditPotentialCommissionForm(); }} disabled={loading}>
                            Close
                        </button>
                        <button type="submit" className='btn btn-submit btn-500 btn-submit-loader' variant="primary" disabled={loading}>
                        Save
                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                        </button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default PotentialCommissionEditForm;