import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LogoUI from "../LogoUI";
// import { Button } from 'primereact/button';
import { IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PasswordChanged = () => {
    const navigate = useNavigate();
    const redirectToLogin = () => {
        //alert(1)
        navigate('/login');
    }
    return (
        <>
            <section className="form-section auth-bg">
                <div className="container-fluid">
                    <div className="form-container">
                        <div className="form-wrapper">
                            <form>
                                {/* <div className="back-sign">
                                        <NavLink to="/resetpassword"><img src="./assets/images/back-icon.svg" alt="" /></NavLink>
                                    </div> */}
                                <h1 className="my-2">Password Changed</h1>

                                <p className="signup-desc changedPassword-desc mb-lg-4">
                                    <span>
                                        <IconButton>
                                            <CheckCircleIcon />
                                        </IconButton>
                                    </span>
                                    Your password has been successfully changed.
                                </p>

                                <div className="form-group">
                                    <button className="btn form-btn" onClick={redirectToLogin} type="button">Continue to Login</button>
                                    {/* <NavLink to="/" className="btn form-btn">Continue to Login</NavLink> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default PasswordChanged;