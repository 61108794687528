import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { InputText, RadioButton } from 'primereact';
import Button from 'react-bootstrap/Button';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import { Dropdown } from "primereact/dropdown";

const InviteAdminUi = (props) => {

    const { user, logout } = useAuth();
    const [show, setShow] = useState(props.showUserInvite);
    const navigate = useNavigate();
    const inviteClose = () => setShow(false);
    const inviteShow = () => setShow(true);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dbUplineUserData, setDbUplineUserData] = useState([]);
    const [isSubmitCall, setIsSubmitCall] = useState(false);

    const [inputList, setInputList] = useState([
        {
            first_name: "",
            first_name_error: "",
            last_name: "",
            last_name_error: "",
            email: "",
            email_name_error: "",
        }
    ]);
    const formik = useFormik({
        initialValues: {
            users_id: '',
            user_json: '',
            upline_users_id: '',
            added_from: 'admin',
            type: 'invite_as_ambassador',
            host_name: window.location.origin,
        },
        validate: (data) => {
            let errors = {};
            let isValid = true;
            if (!data.upline_users_id) {
                errors.upline_users_id = 'Upline user is required.';
            }
            if(isSubmitCall)
            {
                inputList.map((x, i) => {

                    if (!x.email) {
                        x.email_error = 'Email address is required.';
                        isValid = false;
                    }
                    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(x.email)) {
                        x.email_error = 'Invalid email address. e.g. example@email.com';
                        isValid = false;
                    }
                    if (x.first_name == null || x.first_name.trim() === '') {
                        x.first_name_error = 'First name is required.';
                        isValid = false;
                    }
                    else if (x.first_name.length > 70)
                    {
                        x.first_name_error = 'Max characters: 70';
                        isValid = false;
                    }
                    else if (x.first_name.length < 2)
                    {
                        x.first_name_error = 'Min characters: 2';
                        isValid = false;
                    }
                    if (x.last_name == null || x.last_name.trim() === '') {
                        x.last_name_error = 'Last name is required.';
                        isValid = false;
                    }
                    else if (x.last_name.length > 70)
                    {
                        x.last_name_error = 'Max characters: 70';
                        isValid = false;
                    }
                    else if (x.last_name.length < 2)
                    {
                        x.last_name_error = 'Min characters: 2';
                        isValid = false;
                    }
                    
                    
                });
            }
            if (isValid) {
                return errors;
            } else {
                errors.multi_fields = "error";
                return errors;
            }
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    useEffect(() => {
        if (user !== null) {
            const fetchUplineUserData = async () => {
                let formData = new FormData();
                formData.append('user_id', '');
                formData.append('admin_invite_form_call', true);
                try {
                    const response = await axios.post('/get-all-user', formData);
                    const { Status, StatusMessage, Error, Result } = response.data;
                    let finalApiError = '';
                    setLoading(false);
                    if (Status === false) {
                        if (Error.length > 0) {
                            Error.map(
                                (errorMessage) =>
                                    //finalApiError += '<li>'+errorMessage+'</li>'
                                    (finalApiError = errorMessage)
                            );
                        } else {
                            finalApiError = StatusMessage;
                        }
                        setErrorMessage(finalApiError);
                        window.scrollTo(0, 0);
                    } else {
                        setLoading(false);
                        setDbUplineUserData(Result);
                    }
                } catch (err) {
                    setLoading(false);
                    window.scrollTo(0, 0);

                    if (err.response.status && err.response.status == 401) {
                        logout();
                        navigate('/login');
                    } else {
                        setErrorMessage("Something went wrong");

                    }

                }
            }
            fetchUplineUserData();
            formik.setFieldValue("users_id", user.id ? user.id : "");
        }
    }, [user]);
    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;

        if (name === "first_name") {
            list[index]["first_name_error"] = "";
        } else if (name === "last_name") {
            list[index]["last_name_error"] = "";
        } else if (name === "email") {
            list[index]["email_error"] = "";
        }
        setInputList(list);
        formik.setFieldValue("user_json", list);
    };
    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        formik.setFieldValue("user_json", list);
    };

    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                first_name: "",
                first_name_error: "",
                last_name: "",
                last_name_error: "",
                email: "",
                email_name_error: "",
            },
        ]);
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
   
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        try {
            const response = await axios.post('/invite-email', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {
                setLoading(false);
                inviteClose();
                props.changeStatus();
                navigate('/app/my-network');
            }
        } catch (err) {

            setLoading(false);
            window.scrollTo(0, 0);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {

                setErrorMessage("Something went wrong");
            }
        }
    };
    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name + ' (' + Capitalize(option.type) + ')'}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const optionTemplate = (option) => {
        return (
            <div className="country-item user-name-list">
                <p className='full-name-user'>{option.name + ' (' + Capitalize(option.type) + ')'}</p>
                <p className='email-option'>{option.email}</p>
            </div>
        );
    };
    
    return (
        <>
            {/* <Button variant="primary" onClick={inviteShow}>
                Invite Modal
            </Button> */}
            <Modal enforceFocus={false} className='invite-user-popup' show={show} onHide={inviteClose} data-keyboard="false" backdrop="static">

                <Modal.Header className='invite-user-modal'>
                    <Modal.Title>Invite User</Modal.Title>
                </Modal.Header>
                {errorMessage && (
                    <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                        {errorMessage}
                    </Alert>
                )}
                <form onSubmit={formik.handleSubmit} >
                    <Modal.Body className='invite-user-section'>
                        <div className="referral-fields step-3">
                            <div className="invite-user-body">
                                <div className="invite-user-form">
                                <label for="">Types<span class="reuired-star ml-1">*</span></label>
                                    <div className="referral-agent-fields">
                                   
                                        <div className="agent-field">
                                            <RadioButton
                                            onChange={(e) => { formik.handleChange(e) }}
                                                inputId=""
                                                name="type"
                                                value="invite_as_ambassador"
                                                checked={
                                                    formik.values.type ===
                                                    "invite_as_ambassador"
                                                }
                                            />
                                            <label htmlFor="">Ambassador</label>
                                        </div>
                                        <div className="agent-field ml-2">
                                            <RadioButton
                                            onChange={(e) => { formik.handleChange(e) }}
                                                inputId=""
                                                name="type"
                                                value="invite_as_agent"
                                                checked={
                                                    formik.values.type ===
                                                    "invite_as_agent"
                                                }
                                            />
                                            <label htmlFor="">
                                                Agent
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="invite-user-body">
                                <div className="invite-user-form">
                                    <div className="row">
                                        <div className="field signup-label col-lg-12 signup-form-field">
                                            <label htmlFor="">Upline User </label>

                                            <Dropdown
                                                className={classNames("form-select mw-100", { 'p-invalid': isFormFieldValid('upline_users_id') })}
                                                value={
                                                    formik.values
                                                        .upline_users_id
                                                }
                                                options={dbUplineUserData}
                                                onChange={(e) => { formik.handleChange(e) }}
                                                optionLabel="name"
                                                name="upline_users_id"
                                                placeholder="Select one"
                                                valueTemplate={selectedTemplate}
                                                itemTemplate={optionTemplate}

                                                filter
                                                filterBy="name,email"
                                            />
                                            {getFormErrorMessage(
                                                "upline_users_id"
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="invite-user-body">
                                {inputList.map((x, i) => {
                                    return (
                                        <div key={i} className="invite-user-form">
                                            <div className="row">
                                                <div className="field form-field signup-label col-lg-6 signup-form-field">
                                                    <label htmlFor="fname">First Name <span className="reuired-star">*</span></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder="Enter here"
                                                        name="first_name"
                                                        value={x.first_name} onChange={(e) => handleInputChange(e, i)}
                                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('first_name') })}
                                                    />
                                                    {(x.first_name_error) && (
                                                        <small className="p-error">
                                                            {
                                                                x.first_name_error
                                                            }
                                                        </small>
                                                    )}
                                                </div>
                                                <div className="field form-field signup-label col-lg-6 signup-form-field">
                                                    <label htmlFor="lname">Last Name <span className="reuired-star">*</span></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder="Enter here"
                                                        name="last_name"
                                                        value={x.last_name} onChange={(e) => handleInputChange(e, i)}
                                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('last_name') })}
                                                    />
                                                    {(x.last_name_error) && (
                                                        <small className="p-error">
                                                            {
                                                                x.last_name_error
                                                            }
                                                        </small>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="field mb-3">
                                                <label htmlFor="recipient-name">Email Address <span className="reuired-star">*</span></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder="Enter here"
                                                    name="email"
                                                    value={x.email} onChange={(e) => handleInputChange(e, i)}
                                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('email') })}
                                                />
                                                { (x.email_error) && (
                                                    <small className="p-error">
                                                        {
                                                            x.email_error
                                                        }
                                                    </small>
                                                )}
                                            </div>
                                            {
                                                (<div className="btn-box mb-3">
                                                    {inputList.length !== 1 && (
                                                        <Button
                                                            className="add-more remove-link me-2"
                                                            onClick={() =>
                                                                handleRemoveClick(i)
                                                            }
                                                        >
                                                            Remove
                                                        </Button>
                                                    )}
                                                    {inputList.length - 1 === i && (i < 10) && (
                                                        <Button
                                                            onClick={handleAddClick}
                                                            className="add-more"
                                                        >
                                                            Add More +
                                                        </Button>
                                                    )}
                                                </div>)
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='invite-user-footer'>
                        <Button className='cancel-btn' variant="secondary" onClick={() => { inviteClose(); props.changeStatus() }}>
                            Cancel
                        </Button>
                        <Button type="submit" className='btn btn-submit btn-500 btn-submit-loader' onClick={() => {setIsSubmitCall(true)}} variant="primary" disabled={loading}>
                            Send Invite

                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                        </Button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default InviteAdminUi;