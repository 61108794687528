import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { InputText } from 'primereact';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

const commissionData = [
    {
      id: '2.0%',
      name: '2.0%'
    },
    {
      id: '2.25%',
      name: '2.25%'
    },
    {
      id: '2.5%',
      name: '2.5%'
    },
    {
        id: '2.75%',
        name: '2.75%'
    },
    {
        id: '3.0%',
        name: '3.0%'
    },
    {
        id: '3.25%',
        name: '3.25%'
    },
    {
        id: '3.5%',
        name: '3.5%'
    },
    {
        id: 'other',
        name: 'Other'
    }
  ];

const ClosedStatusForm = (props) => {
    const [show, setShow] = useState(props.showClosedStatusForm);
    const navigate = useNavigate();
    const modalClose = () => setShow(true);
    const { user, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [salesPriceError, setSalesPriceError] = useState(false);
    const [commissionEarnedError, setCommissionEarnedError] = useState(false);
    const [otherCommissionError, setOtherCommissionError] = useState(false);
    const [commissionValue, setCommissionValue] = useState(0);  

    const [isOtherCommission, setIsOtherCommission] = useState(false);
    const [isPotentialReferralFeePercentageChange, setIsPotentialReferralFeePercentageChange] = useState(0); 

    let minDate = new Date();
    const formik = useFormik({
        initialValues: {
            users_id: props.loginUserId,
            users_name: props.loginUserName,
            referral_id: props.referralRowId,
            referral_users_id:props.referralUsersId,
            referred_agent_users_id:props.referredAgentUsersId,
            edit_referral_commission_log_id:'',
            sales_price: '',
            closing_date: '',
            commission: '',
            other_commission:'',
            commission_earned: '',
            referral_fee_due:'',
            referral_fee_percentage:'',
            refr_referral_fee_percent:'',
            user_fee_percent:'',
            host_name: window.location.origin,
            referral_fee_user_receive:'',
            referral_platform_fee:'',
            referral_fee_charged_to_receiving_agent:'',
            referral_fee_due_to_referring_agent:'',
            referring_agent_referral_fee_action:'',
        },
        validate: (data) => {
            let errors = {};
            ///alert(data.type)
            if (!data.sales_price) {
                errors.sales_price = 'Sale price is required.';
            }
            else if (data.sales_price)
            {
                if (!isPositiveFloat(data.sales_price)) {
                    errors.sales_price = "Invalid sale price. e.g. 10000";
                }
                else if(parseFloat(data.sales_price) > 100000000)
                {
                    errors.sales_price = "Max: 100,000,000";
                }
            }
            if (!data.commission_earned) {
                errors.commission_earned = 'Total closed commission is required.';
            }
            else if (data.commission_earned)
            {
                if (!isPositiveFloat(data.commission_earned)) {
                    errors.commission_earned = "Invalid total closed commission. e.g. 10000";
                }
                else if(parseFloat(data.commission_earned) > 100000000)
                {
                    errors.commission_earned = "Max: 100,000,000";
                }
            }
            if (!data.closing_date) {
                errors.closing_date = 'Closing date is required.';
            }
            
            if (!data.commission) {
                errors.commission = 'Commission is required.';
            } 
            else
            {
                if(data.commission.name === 'Other' || data.commission.name === 'other')
                {
                    if (!data.other_commission) {
                        errors.other_commission = "Other Commission is required.";
                    }
                    
                    if (data.other_commission)
                    {
                        if (!isPositiveFloat(data.other_commission)) {
                            errors.other_commission = "Invalid other commission. e.g. 5";
                        }
                        else if(parseFloat(data.other_commission) > 50)
                        {
                            errors.other_commission = "Max: 50 percentage";
                        }
                    }
                }
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            if (data.closing_date) 
            {
                data.closing_date = getFormattedDate(data.closing_date);
            }
            handleSubmit(data);
        }
    });
    const isPositiveFloat = (s) => {
        return !isNaN(s) && Number(s) > 0;
    }
    function addZeroes(num) {
        
        if(num === 3 || num === 2)
            return num.toFixed(1);
        else
            return num;
      }
    useEffect(() => {
        if (user !== null) {
            formik.setFieldValue("users_id", user.id ? user.id : "");
        }
        setIsPotentialReferralFeePercentageChange(props.referraDetail.is_potential_referral_fee_percentage_change);
        //alert(props.referralCommissionLogDetail.length)
        if(props.referraDetail.referral_fee_user_receive) {
            formik.setFieldValue("referral_fee_user_receive", parseFloat(props.referraDetail.referral_fee_user_receive).toFixed(2))
        }
        if(props.referraDetail.referral_platform_fee) {
            formik.setFieldValue("referral_platform_fee", parseFloat(props.referraDetail.referral_platform_fee).toFixed(2))
        }
        if(props.referraDetail.referral_fee_charged_to_receiving_agent) {
            formik.setFieldValue("referral_fee_charged_to_receiving_agent", props.referraDetail.referral_fee_charged_to_receiving_agent)
        }
        if(props.referraDetail.referral_fee_due_to_referring_agent) {
            formik.setFieldValue("referral_fee_due_to_referring_agent", props.referraDetail.referral_fee_due_to_referring_agent)
        }
        if(props.referraDetail.referring_agent_referral_fee_action) {
            formik.setFieldValue("referring_agent_referral_fee_action", props.referraDetail.referring_agent_referral_fee_action)
        }
        if(props.referralCommissionLogDetail.length > 0)
        {
            formik.setFieldValue("edit_referral_commission_log_id", props.referralCommissionLogDetail[0].id)

            if(props.referralCommissionLogDetail[0].final_sales_price)
            {
                if(commissionExists(props.referralCommissionLogDetail[0].final_commission))
                {
                    let num = addZeroes( props.referralCommissionLogDetail[0].final_commission )
                    
                    formik.setFieldValue("commission", {
                        id: num+'%',
                        name: num+'%'
                    })
                    setCommissionValue(num+'%')
                    setIsOtherCommission(false);
                    formik.setFieldValue("other_commission", '')

                    
                }
                else
                {
                    formik.setFieldValue("commission", {
                        id: 'other',
                        name: 'Other'
                    })
                    formik.setFieldValue("other_commission", props.referralCommissionLogDetail[0].final_commission)
                    setIsOtherCommission(true);
                    setCommissionValue('Other')
                }
                
                formik.setFieldValue("closing_date",new Date(props.referralCommissionLogDetail[0].final_closing_date));

                formik.setFieldValue("commission_earned", props.referralCommissionLogDetail[0].final_commission_earned)
                formik.setFieldValue("sales_price", props.referralCommissionLogDetail[0].final_sales_price)
                formik.setFieldValue("referral_fee_due", props.referralCommissionLogDetail[0].final_referral_fee_due)

                if(props.referralCommissionLogDetail[0].final_refr_plan_commission >= 0 && props.referralCommissionLogDetail[0].final_refr_plan_commission != null)
                    formik.setFieldValue("refr_referral_fee_percent", parseFloat(props.referralCommissionLogDetail[0].final_refr_plan_commission).toFixed(2))

                if(props.referralCommissionLogDetail[0].final_agent_plan_commission >= 0 && props.referralCommissionLogDetail[0].final_agent_plan_commission != null)
                    formik.setFieldValue("user_fee_percent", parseFloat(props.referralCommissionLogDetail[0].final_agent_plan_commission).toFixed(2))
                
                if(props.referralCommissionLogDetail[0].closed_referral_fee_percentage)
                {
                    formik.setFieldValue("referral_fee_percentage", props.referralCommissionLogDetail[0].closed_referral_fee_percentage)
                } else 
                {
                    formik.setFieldValue("referral_fee_percentage", 35)
                }

                if(!props.referraDetail.referral_fee_user_receive) {
                    if (props.referraDetail.is_potential_referral_fee_percentage_change === 0)
                    {
                        if(props.referralCommissionLogDetail[0].final_sales_price > 200000) {
                            
                            formik.setFieldValue("referral_fee_percentage", 35)
                            formik.setFieldValue("refr_referral_fee_percent", 5)
                            formik.setFieldValue("user_fee_percent", 30)
                        } else {
                            
                            formik.setFieldValue("referral_fee_percentage", 30)
                            formik.setFieldValue("refr_referral_fee_percent", 5)
                            formik.setFieldValue("user_fee_percent", 25)
                        }
                    }
                }
                
            }
            else
            {
                if(commissionExists(props.referralCommissionLogDetail[0].commission))
                {
                    let num = addZeroes( props.referralCommissionLogDetail[0].commission )
                
                    formik.setFieldValue("commission", {
                        id: num+'%',
                        name: num+'%'
                    })
                    setCommissionValue(num+'%')
                    setIsOtherCommission(false);
                    formik.setFieldValue("other_commission", '')
                }
                else
                {
                    formik.setFieldValue("commission", {
                        id: 'other',
                        name: 'Other'
                    })
                    formik.setFieldValue("other_commission", props.referralCommissionLogDetail[0].commission)
                    setIsOtherCommission(true);
                    setCommissionValue('Other')
                }
                
                formik.setFieldValue("closing_date",new Date(props.referralCommissionLogDetail[0].closing_date));

                formik.setFieldValue("commission_earned", props.referralCommissionLogDetail[0].pending_commission)
                formik.setFieldValue("sales_price", props.referralCommissionLogDetail[0].sales_price)
                formik.setFieldValue("referral_fee_due", props.referralCommissionLogDetail[0].referral_fee)

                if(props.referralCommissionLogDetail[0].refr_plan_commission >= 0 && props.referralCommissionLogDetail[0].refr_plan_commission != null)
                    formik.setFieldValue("refr_referral_fee_percent", parseFloat(props.referralCommissionLogDetail[0].refr_plan_commission).toFixed(2))

                if(props.referralCommissionLogDetail[0].agent_plan_commission >= 0 && props.referralCommissionLogDetail[0].agent_plan_commission != null)
                    formik.setFieldValue("user_fee_percent", parseFloat(props.referralCommissionLogDetail[0].agent_plan_commission).toFixed(2))
                
                if(props.referralCommissionLogDetail[0].pending_referral_fee_percentage)
                {
                    formik.setFieldValue("referral_fee_percentage", props.referralCommissionLogDetail[0].pending_referral_fee_percentage)
                } else 
                {
                    formik.setFieldValue("referral_fee_percentage", 35)
                }

                if(!props.referraDetail.referral_fee_user_receive) {
                    if (props.referraDetail.is_potential_referral_fee_percentage_change === 0)
                    {
                        if(props.referralCommissionLogDetail[0].sales_price > 200000) {
                            
                            formik.setFieldValue("referral_fee_percentage", 35)
                            formik.setFieldValue("refr_referral_fee_percent", 5)
                            formik.setFieldValue("user_fee_percent", 30)
                        } else {
                            
                            formik.setFieldValue("referral_fee_percentage", 30)
                            formik.setFieldValue("refr_referral_fee_percent", 5)
                            formik.setFieldValue("user_fee_percent", 25)
                        }
                    }
                }
                
            }
        }
        else
        {
            
            if(props.referraDetail.average_sales_value)
            {
                if(commissionExists(props.referraSettingDetail[0].potential_commission))
                {
                    let num = addZeroes( props.referraSettingDetail[0].potential_commission )
                    
                    formik.setFieldValue("commission", {
                        id: num+'%',
                        name: num+'%'
                    })
                    setCommissionValue(num+'%')
                    setIsOtherCommission(false);
                    formik.setFieldValue("other_commission", '')
                }
                else
                {
                    formik.setFieldValue("commission", {
                        id: 'other',
                        name: 'Other'
                    })
                    formik.setFieldValue("other_commission", props.referraSettingDetail[0].potential_commission)
                    setIsOtherCommission(true);
                    setCommissionValue('Other')
                }

                formik.setFieldValue("commission_earned", props.referraDetail.total_potential_commission)
                formik.setFieldValue("sales_price", props.referraDetail.average_sales_value)
                formik.setFieldValue("referral_fee_due", props.referraDetail.potential_referral_fee)

                if(props.referraDetail.refr_plan_commission >= 0 && props.referraDetail.refr_plan_commission != null)
                    formik.setFieldValue("refr_referral_fee_percent", parseFloat(props.referraDetail.refr_plan_commission).toFixed(2))

                if(props.referraDetail.agent_plan_commission >= 0 && props.referraDetail.agent_plan_commission != null)
                    formik.setFieldValue("user_fee_percent", parseFloat(props.referraDetail.agent_plan_commission).toFixed(2))
                
                if(props.referraDetail.potential_referral_fee_percentage)
                {
                    formik.setFieldValue("referral_fee_percentage", props.referraDetail.potential_referral_fee_percentage)
                } else 
                {
                    formik.setFieldValue("referral_fee_percentage", 35)
                }

                if(!props.referraDetail.referral_fee_user_receive) {
                    if (props.referraDetail.is_potential_referral_fee_percentage_change === 0)
                    {
                        if(props.referraDetail.average_sales_value > 200000) {
                            
                            formik.setFieldValue("referral_fee_percentage", 35)
                            formik.setFieldValue("refr_referral_fee_percent", 5)
                            formik.setFieldValue("user_fee_percent", 30)
                        } else {
                            formik.setFieldValue("referral_fee_percentage", 30)
                            formik.setFieldValue("refr_referral_fee_percent", 5)
                            formik.setFieldValue("user_fee_percent", 25)
                        }
                    }
                    
                }
            }
            
        }
    }, [user]);
    function getFormattedDate(dbDate) {
        // alert('dbDate '+dbDate)
         var date = new Date(dbDate);
     
         var month = date.getMonth() + 1;
         var day = date.getDate();
         var hour = date.getHours();
         var min = date.getMinutes();
         var sec = date.getSeconds();
     
         month = (month < 10 ? "0" : "") + month;
         day = (day < 10 ? "0" : "") + day;
         hour = (hour < 10 ? "0" : "") + hour;
         min = (min < 10 ? "0" : "") + min;
         sec = (sec < 10 ? "0" : "") + sec;
     
         var str = month + "/" + day + "/" + date.getFullYear();
     
         /*alert(str);*/
     
         return str;
     }
    function commissionExists(commission) {
        return commissionData.some(function(el) {
          return el.name.replace('%','') == commission;
        }); 
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleCommission = (e) => {
        formik.setFieldValue("other_commission", "");
        if (e.target.value.name === "Other" || e.target.value.name === "other") {
            setIsOtherCommission(true);
        } else {
            setIsOtherCommission(false);
        }
    }
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        try {
            const response = await axios.post('/closed-referral-status-update', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                setLoading(false);
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        modalClose();
                        props.changeStatuClosedForm();
                    }
                });
            }
        } catch (err) {
            setLoading(false);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } 

        }
    };
    const handleCalculation = (e,mode) => {
        let salesPrice = formik.values.sales_price;
        //alert(salesPrice)
        let commission = 0;
        let otherCommission = 0;
        if(mode === 'other_commission')
        {
            var regexWhiteSpace = /^\s+|\s+$/g;
            if (e.target.value) {
                var regex = /^\d+(\.\d{0,2})?$/g;
                if (!regex.test(parseFloat(e.target.value))) {
                    
                    otherCommission = parseFloat(e.target.value).toFixed(2);
                    if(otherCommission !== 'NaN')
                        formik.setFieldValue("other_commission", otherCommission)
                }
                else 
                {
                    otherCommission = e.target.value;
                    if(otherCommission !== 'NaN')
                        formik.setFieldValue("other_commission", otherCommission)
                }
           }
            
        }
        else
        {
            otherCommission = formik.values.other_commission;
        }
        //console.log('mode '+mode)
        if(mode === 'commission')
        {
            commission = e.target.value.name;
        }
        else
        {
            commission = commissionValue;
        }

        
        
        let commissionTemp = 0;
        let isValidCommission = true;
        let isValidSalesPrice = true;
       // console.log('commission '+commission)
        if(commission)
        {
            setOtherCommissionError('')
            if(commission != 'Other')
                commissionTemp = commission.replace('%','');
            if (otherCommission && (commission === 'other' || commission === 'Other'))
            {
                
                if (!isPositiveFloat(otherCommission)) {
                    isValidCommission = false;
                    setOtherCommissionError("Invalid other commission. e.g. 5")
                }
                else if(parseFloat(otherCommission) > 50)
                {
                    isValidCommission = false;
                    setOtherCommissionError("Max: 50 percentage")
                }
                if(isValidCommission)
                    commissionTemp = otherCommission;
            }
        }

        
        if (salesPrice)
        {
            setSalesPriceError('')
            if (!isPositiveFloat(salesPrice)) {
                isValidSalesPrice = false;
                setSalesPriceError("Invalid sale price. e.g. 10000")
            }
            else if(parseFloat(salesPrice) > 100000000)
            {
                isValidSalesPrice = false;
                setSalesPriceError("Max: 100,000,000")
            }
        }
       
        if((salesPrice) && (isValidSalesPrice) && (isValidCommission) && (commissionTemp))
        {
            let calulation = parseFloat(salesPrice) * parseFloat(commissionTemp);
            let commissionEarned = parseFloat(calulation) / parseFloat(100);
            formik.setFieldValue("commission_earned", commissionEarned.toFixed(2))

            let calulation1 = 0;
            calulation1 = parseFloat(commissionEarned) * parseFloat(formik.values.referral_fee_percentage);
            
            if(!props.referraDetail.referral_fee_user_receive) {
                if(isPotentialReferralFeePercentageChange === 0)
                {
                    if(salesPrice > 200000) {
                        calulation1 = parseFloat(commissionEarned) * parseFloat(35);
                        formik.setFieldValue("referral_fee_percentage", 35)
                        formik.setFieldValue("refr_referral_fee_percent", 5)
                        formik.setFieldValue("user_fee_percent", 30)
                    } 
                    else
                    {
                        calulation1 = parseFloat(commissionEarned) * parseFloat(30); 
                        formik.setFieldValue("referral_fee_percentage", 30)
                        formik.setFieldValue("refr_referral_fee_percent", 5)
                        formik.setFieldValue("user_fee_percent", 25)
                    }
                }
            }
            let refrCommission = parseFloat(calulation1) / parseFloat(100);
            formik.setFieldValue("referral_fee_due", refrCommission.toFixed(2))

            if(formik.values.referral_fee_user_receive)
            {
                if(formik.values.referring_agent_referral_fee_action === 'yes')
                {
                    let finalReferralDue = parseFloat(refrCommission) + parseFloat(199);
                    formik.setFieldValue("referral_fee_due", finalReferralDue.toFixed(2))
                }
            }            
        }
        else
        {
            formik.setFieldValue("commission_earned", "")
            formik.setFieldValue("referral_fee_due", "")
        }
    }
    const handleCalculationCommissionEarned = (e) => {
        let salesPrice = formik.values.sales_price;
        let commissionEarned =  e.target.value;
        let isValidCommissionEarned = true;
        let isValidSalesPrice = true;

        if (salesPrice)
        {
            setSalesPriceError('')
            if (!isPositiveFloat(salesPrice)) {
                isValidSalesPrice = false;
                setSalesPriceError("Invalid sale price. e.g. 10000")
            }
            else if(parseFloat(salesPrice) > 100000000)
            {
                isValidSalesPrice = false;
                setSalesPriceError("Max: 100,000,000")
            }
        }

        if (commissionEarned)
        {
            setCommissionEarnedError('')
            if (!isPositiveFloat(commissionEarned)) {
                isValidCommissionEarned = false;
                setCommissionEarnedError("Invalid total closed commission. e.g. 10000")
            }
            else if(parseFloat(commissionEarned) > 100000000)
            {
                isValidCommissionEarned = false;
                setCommissionEarnedError("Max: 100,000,000")
            }
        }

        if((salesPrice) && (commissionEarned) && (isValidCommissionEarned) && (isValidSalesPrice))
        {
            let calulation = parseFloat(commissionEarned) / parseFloat(salesPrice);

            let commissionPercentage = parseFloat(calulation) * parseFloat(100);
            
            if(parseFloat(commissionPercentage) > 50)
            {
                setOtherCommissionError("Max: 50 percentage")
                formik.setFieldValue("referral_fee_due", "")
            }
            else
            {
                setOtherCommissionError("")
                if(commissionExists(commissionPercentage))
                {
                    let num = addZeroes( commissionPercentage )
                    
                    formik.setFieldValue("commission", {
                        id: num+'%',
                        name: num+'%'
                    })
                    setCommissionValue(num+'%')
                    setIsOtherCommission(false);
                    formik.setFieldValue("other_commission", '')
                }
                else
                {
                    formik.setFieldValue("commission", {
                        id: 'other',
                        name: 'Other'
                    })
                    
                    formik.setFieldValue("other_commission", parseFloat(commissionPercentage).toFixed(2))
                    setIsOtherCommission(true);
                    setCommissionValue('Other')
                }

                let calulation1 = 0;
                calulation1 = parseFloat(commissionEarned) * parseFloat(formik.values.referral_fee_percentage);
                if(!props.referraDetail.referral_fee_user_receive) {
                    if(isPotentialReferralFeePercentageChange === 0)
                    {
                        if(salesPrice > 200000) {
                            calulation1 = parseFloat(commissionEarned) * parseFloat(35);
                            
                            formik.setFieldValue("referral_fee_percentage", 35)
                        } 
                        else
                        {
                            calulation1 = parseFloat(commissionEarned) * parseFloat(30); 
                            
                            formik.setFieldValue("referral_fee_percentage", 30)
                        }
                    }
                }
                let refrCommission = parseFloat(calulation1) / parseFloat(100);
                formik.setFieldValue("referral_fee_due", refrCommission.toFixed(2))
                if(formik.values.referral_fee_user_receive)
                {
                    if(formik.values.referring_agent_referral_fee_action === 'yes')
                    {
                        let finalReferralDue = parseFloat(refrCommission) + parseFloat(199);
                        formik.setFieldValue("referral_fee_due", finalReferralDue.toFixed(2))
                    }
                }
            }
            
        }
        else
        {
            formik.setFieldValue("commission", '')
            formik.setFieldValue("other_commission", '')
            setIsOtherCommission(false);
            setCommissionValue('')
            formik.setFieldValue("referral_fee_due", "")
        }
    }
    return (
        <>
            <Modal className='add-people-popup' show={show} onHide={modalClose} data-keyboard="false" backdrop="static" >
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header className='add-people-header'>
                        <Modal.Title>Closed Commission</Modal.Title>
                    </Modal.Header>
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                            {errorMessage}
                        </Alert>
                    )}
                    <Modal.Body className='addpeople-section assign-agent-popup'>
                    <div className="row gx-3 form-section">
                            <div className="field col-md-6 profile-input doller-value" >
                                <label htmlFor="inputEmail4" className="form-label">Sale Price <span className="reuired-star">*</span></label>
                                <span className="p-input-icon-left custom-input-left">

                                    <i className="pi pi-dollar"></i>
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control ps-4",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "sales_price"
                                                    ),
                                            }
                                        )}
                                        name="sales_price"
                                        placeholder=""
                                        value={
                                            formik.values.sales_price
                                        }
                                        onChange={(e) => {formik.handleChange(e)}}
                                        onKeyUp={(e) => handleCalculation(e,'sales_price')}
                                    /></span>
                                {getFormErrorMessage('sales_price')}

                                {(salesPriceError) && (!getFormErrorMessage('sales_price')) && (
                                    <small className="p-error">{salesPriceError}</small>
                                )}
                            </div>

                            <div className="col-md-6">
                                <div className="field">
                                    <label htmlFor="" className="form-label">Commission Percentage <span className="reuired-star">*</span></label>
                                    <Dropdown className={classNames("form-select", { 'p-invalid': isFormFieldValid('commission') })} options={commissionData} defaultValue={formik.values.commission} optionLabel="name" placeholder="Select" name="commission" value={formik.values.commission}
                                        onChange={(e) => {handleCommission(e);formik.handleChange(e);setCommissionValue(e.target.value.name);handleCalculation(e,'commission')}}
                                        />
                                    {getFormErrorMessage('commission')}
                                </div>
                            </div>

                            {
                                (isOtherCommission) && (<div className="field col-md-12 profile-input" >
                                    <label htmlFor="inputEmail4" className="form-label">Other Commission Percentage<span className="reuired-star">*</span></label>
                                    <InputText placeholder="Enter Here"
                                        autoComplete="off"
                                        name="other_commission"
                                        value={formik.values.other_commission} 
                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('other_commission') })}
                                        onChange={(e) => {formik.handleChange(e);}}
                                        onKeyUp={(e) => {handleCalculation(e,'other_commission')}}
                                    />
                                    {getFormErrorMessage('other_commission')}
                                    
                                    {(otherCommissionError) && (!getFormErrorMessage('other_commission')) && (
                                    <small className="p-error">{otherCommissionError}</small>
                                )}
                                </div>)
                            }

                        </div>

                        <div className="row gx-3 form-section">
                            <div className="field col-md-6 profile-input doller-value">
                                <label htmlFor="inputEmail4" className="form-label">Total Closed Commission </label>
                                <span className="p-input-icon-left custom-input-left">

                                    <i className="pi pi-dollar"></i>
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control ps-4",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "commission_earned"
                                                    ),
                                            }
                                        )}
                                        name="commission_earned"
                                        placeholder=""
                                        value={
                                            formik.values.commission_earned
                                        }
                                        onKeyUp={(e) => {handleCalculationCommissionEarned(e)}}
                                        onChange={formik.handleChange}
                                        //disabled={true}
                                    /></span>
                                {getFormErrorMessage('commission_earned')}
                                    {(commissionEarnedError) && (!getFormErrorMessage('commission_earned')) && (
                                    <small className="p-error">{commissionEarnedError}</small>
                                )}
                            </div>
                            <div className="field col-md-6 profile-input doller-value">
                                <label htmlFor="inputEmail4" className="form-label">Referral Fee Due </label>
                                <span className="p-input-icon-left custom-input-left">

                                    <i className="pi pi-dollar"></i>
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control ps-4",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "referral_fee_due"
                                                    ),
                                            }
                                        )}
                                        name="referral_fee_due"
                                        placeholder=""
                                        value={
                                            formik.values.referral_fee_due
                                        }
                                        onChange={formik.handleChange}
                                        disabled={true}
                                    /></span>
                                {getFormErrorMessage('referral_fee_due')}
                            </div>
                            
                            <div className="field col-md-6 profile-input">
                                <label htmlFor="inputPassword4" className="form-label">Closing Date <span className="reuired-star">*</span></label>
                                <Calendar
                                    //minDate={minDate}
                                    dateFormat="mm/dd/yy"
                                    placeholder="MM/DD/YY"
                                    name="closing_date"
                                    value={formik.values.closing_date} onChange={formik.handleChange}
                                    InputClassName={classNames("form-control", { 'p-invalid': isFormFieldValid('closing_date') })}
                                     showIcon />

                                {getFormErrorMessage('closing_date')}
                            </div>
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer className='add-people-footer'>
                        <button type="button" className='btn btn-cancel btn-500' variant="secondary" onClick={() => { modalClose(); props.changeStatuClosedForm(); }} disabled={loading}>
                            Close
                        </button>
                        <button type="submit" className='btn btn-submit btn-500 btn-submit-loader' variant="primary" disabled={loading}>
                        Save
                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                        </button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ClosedStatusForm;
