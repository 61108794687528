import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { InputTextarea } from 'primereact';
import axios from "../../../axiosCall.js";
import useAuth from "./../../../hooks/useAuth";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { Panel } from 'primereact/panel';

const ReferralEmailCommunication = () => {
    const secretKey = "REFR2022@GALAXY";
    let requestReferralId = '';
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    
    const search = useLocation().search;
    const referralId = new URLSearchParams(search).get('u');
    if (referralId === null) {
        navigate('/dashboard');
    } 
    else if(referralId)
    {
        let referralIdDecrypt = AES.decrypt(referralId, secretKey);

        if (referralIdDecrypt == '')
        {
            navigate('/dashboard');
        }
        else
            requestReferralId = JSON.parse(referralIdDecrypt.toString(Utf8));
    }
    else {
        navigate('/dashboard');
    }

    const [referralCommunicationEmail, setReferralCommunicationEmail] = useState([]);

    const [state, setState] = useState(
        {
            referral_type: '',
            referral_status_name: '',
            reference_number: '',
            agent_id: '',
            agent_name: '',
            agent_email: '',
            agent_phone: '',
            agent_type:'',
            referring_agent_id: '',
            referring_agent_name: '',
            referring_agent_email: '',
            referring_agent_phone: '',
            referring_agent_type:'',
            client_name: "",
            client_email: "",
            client_phone: "",
        }
    );
    

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    
    const fetchReferralDetailData = async () => {
        let formData = new FormData();
        formData.append('referral_id', requestReferralId);

        try {
            const response = await axios.post("/get-referral-data-email-communication", formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                if(Result.referral_communication_log.length > 0)
                {
                    setReferralCommunicationEmail(Result.referral_communication_log);
                }
                

                setState((prevState) => {
                    return {
                        ...prevState,
                        reference_number: (Result.referral.reference_number) ? Result.referral.reference_number : '',
                        referral_status_name: (Result.referral.referral_status_name) ? Result.referral.referral_status_name : '',
                        referral_type: (Result.referral.referral_type) ? Result.referral.referral_type : '-',
                        agent_name: (Result.referral.agent_name) ? Result.referral.agent_name : '',
                        agent_email: (Result.referral.agent_email) ? Result.referral.agent_email : '',
                        agent_phone: (Result.referral.agent_phone) ? Result.referral.agent_phone : '',
                        agent_type: (Result.referral.agent_type) ? Result.referral.agent_type : '',
                        
                        client_name: (Result.referral.client_name) ? Result.referral.client_name : '',
                        client_email: (Result.referral.client_email) ? Result.referral.client_email : '',
                        client_phone: (Result.referral.client_phone) ? Result.referral.client_phone : '',
                        
                        agent_id: (Result.referral.agent_id) ? Result.referral.agent_id : '',
                        referring_agent_id: (Result.referral.users_id) ? Result.referral.users_id : '',

                        referring_agent_name: (Result.referral.referring_agent_name) ? Result.referral.referring_agent_name : '',
                        referring_agent_email: (Result.referral.referring_agent_email) ? Result.referral.referring_agent_email : '',
                        referring_agent_type: (Result.referral.referring_agent_type) ? Result.referral.referring_agent_type : '',
                        referring_agent_phone: (Result.referral.referring_agent_phone) ? Result.referral.referring_agent_phone : '',
                        

                        
                    };
                });
                
            }
            else {
                navigate('/dashboard');
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };

    useEffect(() => {
        if (user !== null) {
            fetchReferralDetailData();
        }
    }, [user, requestReferralId]);
    const handleView = (rowId) => {
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();

        let finalUrl = 'u='+encodeURIComponent(userIdEncryptedString);
        return '/app/referral-detail?'+finalUrl;
    }
    const handleNetworkDetail = (rowId,mode) => {
        //alert(rowId+' '+mode)
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();

        const modeEncryptedString = AES.encrypt(JSON.stringify(mode), secretKey).toString();

        let finalUrl = 'u='+encodeURIComponent(userIdEncryptedString)+'&m='+encodeURIComponent(modeEncryptedString);
        //alert(finalUrl);
        return '/app/my-network-detail?'+finalUrl;
        //navigate('/app/my-network-detail?'+finalUrl);
    }

    function getFormattedDate(dbDate) {
       // alert('dbDate '+dbDate)
        var date = new Date(dbDate);
    
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
    
        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        sec = (sec < 10 ? "0" : "") + sec;
    
        var str = month + "/" + day + "/" + date.getFullYear() + " " +  hour + ":" + min + ":" + sec;
    
        /*alert(str);*/
    
        return str;
    }
    return (
        <>
            <section className="template-profile-view sec-pt sec-pb email_temp_pr">
                <div className="container-fluid email-container">
                    <h2 className='main-heading'>Referral</h2>
                    <div className="cards-container row row-cols-1 row-cols-lg-2 g-1">
                        <div className="col col-basic-detail ps-lg-0 email-col">
                            <div className="card profile-card full-card h-100">
                                <div className="card-body">
                                        
                                        <div className="add-notes">
                                            <h2 className="notes-content">Referral Details</h2>
                                            <div className='personal-fields'>
                                                <ul className='email-list'>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Reference Number :</p></li>
                                                        <li className='email-info'><p className="mb-0"><NavLink to={handleView(requestReferralId)}>{(state.reference_number) ? state.reference_number : 'N/A'}</NavLink></p></li>
                                                    </li>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Status :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.referral_status_name) ? state.referral_status_name  : 'N/A'}</p></li>
                                                    </li>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Type :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.referral_type) ? state.referral_type : 'N/A'}</p></li>
                                                    </li>
                                                </ul>
                                                
                                            </div>
                                        </div>
                                        <div className="add-notes">
                                            <h2 className="notes-content">Client Details</h2>
                                            <div className='personal-fields'>
                                                <ul className='email-list'>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Name :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.client_name) ? state.client_name : 'N/A'}</p></li>
                                                    </li>
                                                    <li className="email-field">
                                                        <li className='email-item email_id'><p className="mb-3">Email :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.client_email) ? state.client_email : 'N/A'}</p></li>
                                                    </li>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Phone :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.client_phone) ? state.client_phone : 'N/A'}</p></li>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <div className="add-notes">
                                            <h2 className="notes-content">Referring Agent</h2>
                                            <div className='personal-fields'>
                                                <ul className='email-list'>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Name :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.referring_agent_name) ? <NavLink to={handleNetworkDetail(`${state.referring_agent_id}`,`${state.referring_agent_type}`)} >{state.referring_agent_name}</NavLink> : 'N/A'}</p></li>
                                                    </li>
                                                    <li className="email-field">
                                                        <li className='email-item email_id'><p className="mb-3">Email :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.referring_agent_email) ? state.referring_agent_email : 'N/A'}</p></li>
                                                    </li>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Phone :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.referring_agent_phone) ? state.referring_agent_phone : 'N/A'}</p></li>
                                                    </li>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Type :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.referring_agent_type) ? Capitalize(state.referring_agent_type) : 'N/A'}</p></li>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="add-notes">
                                            <h2 className="notes-content">Assigned Agent</h2>
                                            <div className='personal-fields'>
                                                <ul className='email-list'>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Name :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.agent_name) ? <NavLink to={handleNetworkDetail(`${state.agent_id}`,`${state.agent_type}`)} >{state.agent_name}</NavLink> : 'N/A'}</p></li>
                                                    </li>
                                                    <li className="email-field">
                                                        <li className='email-item email_id'><p className="mb-3">Email :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.agent_email) ? state.agent_email : 'N/A'}</p></li>
                                                    </li>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Phone :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.agent_phone) ? state.agent_phone : 'N/A'}</p></li>
                                                    </li>
                                                    <li className="email-field">
                                                        <li className='email-item'><p className="mb-3">Type :</p></li>
                                                        <li className='email-info'><p className="mb-0">{(state.agent_type) ? Capitalize(state.agent_type) : 'N/A'}</p></li>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col pe-lg-0 email-col-tab">
                            <div className="card h-100 full-card">
                                <div className="card-body">
                                    <h2 className='main-heading email-heading'>Emails</h2>
                                    {
                                        (referralCommunicationEmail.length > 0) &&
                                    (<div className='email-section-tab'>
                                    { (referralCommunicationEmail.map((data, i) => {
                                        let mediaFileName = '';
                                        if(data.attachment)
                                        {
                                            let mediaSplit = data.attachment.split('/');
                                            let mediaSplitTemp = mediaSplit[mediaSplit.length - 1].split('.');
                                            mediaFileName = 'file.'+mediaSplitTemp[1];
                                        }
                                        return (
                                        <div key={i} className="email-section-fields">
                                            <div className="email-field">
                                                <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root email-dp"><i className="pi pi-envelope"></i></div>
                                                <div className="email-details">
                                                    <div className='email-duration'>
                                                        <h3 className='email-name'>{data.from_email}  </h3>
                                                        {
                                                            (data.cc) && (<h3 className='email-light-name cc_email'> <span>CC: </span>{data.cc}</h3>)
                                                        }
                                                        {
                                                            (data.bcc) && (<h3 className='email-light-name cc_email'> <span>BCC: </span>{data.bcc}</h3>)
                                                        }
                                                        {
                                                            (data.attachment) && (<h3 className='email-light-name cc_email'> <span>Attachment: </span><a href={data.attachment} target="_blank" className='file-btn detail-op'>{mediaFileName}</a></h3>)
                                                        }
                                                        <span className='date'>{getFormattedDate(data.created_at_db)}</span>
                                                    </div>
                                                    <i className="pi pi-chevron-right"></i>
                                                    <h3 className='email-light-name'>{data.to}</h3>
                                                </div>
                                            </div>
                                            <div className="email-thoughts">
                                                <h4 className='email-thought'>{data.subject} </h4>
                                                {/* <a href='' className='view-email'>View email</a> */}
                                                <Panel  header="View email" toggleable  collapsed="true">
                                                    <div className='m-0 body-text'>
                                                        <div dangerouslySetInnerHTML={{__html: data.message }}></div>
                                                    </div>
                                                </Panel>
                                            </div>
                                        </div>
                                        )
                                    }))
                                    
                                }
                                    </div>)}
                                    {
                                        (referralCommunicationEmail.length === 0) &&
                                    (<div className="comming-note h-100">
                                        <div className="text-center">
                                            <img src="../../assets/images/waiting.png" alt=""  className="img-fluid"/>
                                            <p>No Email sent</p>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReferralEmailCommunication;