import React, { useState, useEffect,useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { InputText } from 'primereact';
import { Checkbox,MultiSelect } from 'primereact';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import Swal from 'sweetalert2';

const toCCBCCData = [
    {
        "id": "client",
        "name": "Client"
    },
    {
        "id": "refr_ambassador",
        "name": "REFR Ambassador"
    },
    {
        "id": "refr_agent",
        "name": "REFR Agent"
    },
    {
        "id": "referring_agent",
        "name": "Referring Agent"
    },
    {
        "id": "assigned_agent",
        "name": "Assigned Agent"
    },
    {
        "id": "selected_agent",
        "name": "Selected Agent"
    },
    {
        "id": "selected_agent_bor",
        "name": "Selected Agent - BOR"
    },
    {
        "id": "referring_agent_bor",
        "name": "Referring Agent - BOR"
    },
    {
        "id": "assigned_agent_bor",
        "name": "Assigned Agent - BOR"
    },
    {
        "id": "refr_admin",
        "name": "REFR Admin"
    },
    {
        "id": "referring_agent_upline",
        "name": "Referring Agent - Upline"
    },
    {
        "id": "assigned_agent_upline",
        "name": "Assigned Agent - Upline"
    },
    {
        "id": "ambassador_REFR_agent_upline",
        "name": "Ambassador/REFR Agent - Upline"
    }
];

const EmailModal = (props) => {
    const [show, setShow] = useState(props.showEmailModal);
    const [dbTagName, setDbTagName] = useState([]);
    const emailModalClose = () => setShow(false);
    const { user,logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [state, setState] = React.useState({ value: null });
    const navigate = useNavigate();
    const handleChange = value => {
        setState({ value });
    };
    const mediaRef = useRef('');
    const formik = useFormik({
        initialValues: {
            email_template_id: '',
            users_id: '',
            name: '',
            to_data:'',
            cc_data:'',
            bcc_data:'',
            subject: '',
            message: '',
            attachment: '',
            attachment_db: '',
            share_to_everyone: false,
            is_message_added: false,
            host_name: window.location.origin,
        },
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = 'Name is required.';
            }

            if (!data.subject) {
                errors.subject = 'Subject is required.';
            }

            if (data.message === null) {
                errors.message = 'Message is required.';
            }
            else if (!data.message) {
                errors.message = 'Message is required.';
            }
            return errors;
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    useEffect(() => {
        //alert(JSON.stringify(toCCBCCData))
        const elements = document.getElementsByClassName("ql-image");
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }

        if (user !== null) {
            formik.setFieldValue("users_id", user.id ? user.id : "");
        }

    }, [user]);
    
    useEffect(() => {
        const fetchEditData = async () => {
            let formData = new FormData();
            formData.append('email_template_id', props.editRowId);

            try {
                const response = await axios.post('/edit-email-template', formData);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = '';
                setLoading(false);
                if (Status === false) {
                    if (Error.length > 0) {
                        Error.map(
                            (errorMessage) =>
                                //finalApiError += '<li>'+errorMessage+'</li>'
                                (finalApiError = errorMessage)
                        );
                    } else {
                        finalApiError = StatusMessage;
                    }
                    setErrorMessage(finalApiError);
                } else {
                    setLoading(false);
                    formik.setFieldValue("email_template_id", props.editRowId);
                    formik.setFieldValue("name", Result.name);
                    formik.setFieldValue("subject", Result.subject);
                    formik.setFieldValue("message", Result.message);
                    formik.setFieldValue("attachment_db", Result.attachment);
                    if (Result.share_to_everyone === 0)
                        formik.setFieldValue("share_to_everyone", false);
                    else
                        formik.setFieldValue("share_to_everyone", true);

                    
                    if(Result.to_data)
                    {
                        formik.setFieldValue("to_data", JSON.parse(Result.to_data))
                    }

                    if(Result.cc_data)
                    {
                        formik.setFieldValue("cc_data", JSON.parse(Result.cc_data))
                    }  
                    if(Result.bcc_data)
                    {
                        formik.setFieldValue("bcc_data", JSON.parse(Result.bcc_data))
                    }                      
                }
            } catch (err) {

                setLoading(false);
                if(err.response.status && err.response.status==401){
                    logout();
                    navigate('/login');
                }else{
                    setErrorMessage('There is something wrong. Please try after sometime.');
                }
            }
        }
        if (props.editRowId > 0) {
            fetchEditData();
        }
    }, []);
    

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        let formData = new FormData();
        let file = data.attachment;
        formData.append('file', file)
        formData.append('data', JSON.stringify(data))

        try {
            const response = await axios.post('/add-email-template', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                setLoading(false);
                emailModalClose();
                props.changeStatusEmailTemplate();
                props.reloadListing();
            }
        } catch (err) {

            setLoading(false);
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }else{
                setErrorMessage('There is something wrong. Please try after sometime.');
            }
        }
    };
    const handleTemplateMessage = (html) => {
//        console.log('event.textValue :: ' + html);

        if (html !== null) {
            formik.setFieldValue("message", html);
        }
        else {
            formik.setFieldValue("message", "");
        }
    }
    const onFileChanged = (event) => {
        // alert(event.files[0])
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];
            let fileSize = file.size;
            const imageSizeInMb = Math.round((fileSize / 1024));
            let fileName = event.target.files[0].name;
            let splitName = fileName.split('.');
            
            if (imageSizeInMb >= 2048) {
                mediaRef.current.value = '';
                Swal.fire({
                    title: '',
                    text: "Please upload the file with a size of not more than 2 MB.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })

                //Swal.fire('Please upload the file with a size of not more than 2 MB.');
            }
            else if (file.type !== 'application/pdf' && file.type !== 'application/msword'  && file.type !== 'application/wps-office.docx' && file.type !== 'application/wps-office.doc' && file.type !== 'application/ms-doc' && file.type !== 'application/doc' && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && splitName[1] !== 'bmp' && splitName[1] !== 'jpe' && splitName[1] !== 'jfif' && splitName[1] !== 'webp' && splitName[1] !== 'jpg' && splitName[1] !== 'jpeg' && splitName[1] !== 'png' && splitName[1] !== 'gif' && splitName[1] !== 'BMP' && splitName[1] !== 'JPE' && splitName[1] !== 'JFIF' && splitName[1] !== 'WEBP' && splitName[1] !== 'JPG' && splitName[1] !== 'JPEG' && splitName[1] !== 'PNG' && splitName[1] !== 'GIF')  {
                mediaRef.current.value = '';
                Swal.fire({
                    title: '',
                    html: file.name + " has an invalid extension.<br> Valid extension(s): bmp, gif, jpeg, jpg, jpe, jfif, png, webp, pdf, doc, docx.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                })
                //Swal.fire('Please selects the file with valid extension i.e. *.pdf.');
            }
            else {
                //alert(event.target.files[0])
                formik.setFieldValue(
                    "attachment",
                    event.target.files[0]
                );
            }
        }

    };
    const handleDeleteDocuments = (rowId, mode, media) => {
        Swal.fire({
            title: 'Are you sure?',
            html: "You won't be able to reverse this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                handleDeleteDocumentsCall(rowId, mode, media);
            }
        })
    }
    const handleDeleteDocumentsCall = async (rowId, mode, media) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('email_template_id', rowId);
        formData.append('mode', 'email_template');
        formData.append('media', media);

        try {
            const response = await axios.post('/delete-email-attachment-docs', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                formik.setFieldValue("attachment", "");
                formik.setFieldValue("attachment_db", "");
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    }
    useEffect(() => {
        const fetchTagData = async () => {
            try {
                const response = await axios.post("/get-all-tag-data");
                const { Status, StatusMessage, Error, Result } = response.data;

                if (Status === true) {
                    setDbTagName(Result);
                    //alert('1'+dbTagName)
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchTagData();
    }, []);
    const handleSubjectMergeField = (mode) => {
        //alert(mode);
        let previousData = formik.values.subject;
        previousData += '%' + mode + '%';

        formik.setFieldValue("subject", previousData);
    }

    const handleKeyword = (str) => {
        let i, frags = str.split('_');
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }

    return (
        <>
            <Modal enforceFocus={false} className='email-temlate-popup' show={show} onHide={emailModalClose} data-keyboard="false" backdrop="static" >
                <Modal.Header className='template-header'>
                    <Modal.Title className="modal-title">{(props.editRowId) ? 'Update' : 'Create'} Email Template</Modal.Title>
                </Modal.Header>
                {errorMessage && (
                    <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                        {errorMessage}
                    </Alert>
                )}
                <Modal.Body className='template-body'>
                    <form className="form-section" onSubmit={formik.handleSubmit}>
                        <div className="field text-input">
                            <label className="input-label" id="exampleModalLabel">Template Name <span className="reuired-star">*</span></label>
                            <InputText
                            autoComplete="off"
                                type="text"
                                placeholder="Enter here"
                                aria-label="Text input with dropdown button"
                                name="name"
                                value={formik.values.name} onChange={formik.handleChange}
                                className={classNames("form-control", { 'p-invalid': isFormFieldValid('name') })}
                            />
                            {getFormErrorMessage('name')}
                        </div>
                        <div className="field text-input">
                            <div className="field">
                                <label htmlFor="" className="form-label">To</label>
                                <MultiSelect
                                menuPosition="fixed"
                                    name="to_data"
                                    value={formik.values.to_data}
                                    optionLabel="name"
                                    options={toCCBCCData}
                                    maxSelectedLabels={3}
                                    className={classNames("form-select", { 'p-invalid': isFormFieldValid('to_data') })}
                                    onChange={(e)=>{formik.handleChange(e)}}
                                    // onChange={(e) => { handleToData(e); formik.handleChange(e); }}
                                    filter
                                />
                                
                            </div>
                        </div>
                        <div className="field text-input">
                            <div className="field">
                                <label htmlFor="" className="form-label">CC</label>
                                <MultiSelect
                                    name="cc_data"
                                    value={formik.values.cc_data}
                                    optionLabel="name"
                                    options={toCCBCCData}
                                    maxSelectedLabels={3}
                                    className={classNames("form-select", { 'p-invalid': isFormFieldValid('cc_data') })}
                                    onChange={(e)=>{formik.handleChange(e)}}
                                    // onChange={(e) => { handleToData(e); formik.handleChange(e); }}
                                    filter
                                />
                                
                            </div>
                        </div>
                        <div className="field text-input">
                            <div className="field">
                                <label htmlFor="" className="form-label">BCC</label>
                                <MultiSelect
                                    name="bcc_data"
                                    value={formik.values.bcc_data}
                                    optionLabel="name"
                                    options={toCCBCCData}
                                    maxSelectedLabels={3}
                                    className={classNames("form-select", { 'p-invalid': isFormFieldValid('bcc_data') })}
                                    onChange={(e)=>{formik.handleChange(e)}}
                                    // onChange={(e) => { handleToData(e); formik.handleChange(e); }}
                                    filter
                                />
                                
                            </div>
                        </div>
                        <div className="field">
                            <label className="input-label" id="exampleModalLabel">Template Subject <span className="reuired-star">*</span></label>
                            <div className="input-group text-input">
                                <InputText
                                autoComplete="off"
                                    type="text"
                                    placeholder="Enter here"
                                    aria-label="Text input with dropdown button"
                                    name="subject"
                                    value={formik.values.subject} onChange={formik.handleChange}
                                    className={classNames("form-control email-input", { 'p-invalid': isFormFieldValid('subject') })}
                                />

                                <button className="btn btn-outline-secondary dropdown-toggle merge-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">Merge Fields</button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    {
                                        dbTagName.length > 0 &&
                                        dbTagName.map((tagData) => (
                                            <li><a className="dropdown-item" href="javascript:;" key={tagData.id} onClick={() => { handleSubjectMergeField(`${tagData.name}`) }}>{handleKeyword(tagData.name)}</a></li>
                                        ))}
                                </ul>
                            </div>
                            {getFormErrorMessage('subject')}
                        </div>
                        
                        <div className="text-field">
                            <label htmlFor="message-text" className="col-form-label text-label">Message <span className="reuired-star">*</span></label>
                            {
                                (dbTagName.length > 0) && (<><EditorToolbar dbTagName={dbTagName} />
                                    <ReactQuill
                                        style={{ height: '150px' }}
                                        theme="snow"
                                        name="message"
                                        value={formik.values.message}
                                        placeholder={"Write something awesome..."}
                                        modules={modules}
                                        formats={formats}
                                        onChange={handleTemplateMessage}
                                    /></>)
                            }

                            {/* <Editor
                                style={{ height: '100px' }}
                                placeholder="Enter Here"
                                name="message"
                                value={formik.values.message}
                                onChange={formik.handleChange}
                                className={classNames("form-control message", { 'p-invalid': isFormFieldValid('message') })}
                                onTextChange={handleTemplateMessage}
                                //headerTemplate={header}
                            /> */}
                            {getFormErrorMessage('message')}
                        </div>
                        <div className="row">
                        {
                            (!formik.values.attachment_db) && (<div className="col-lg-6">
                            <div className="field upload-file">
                                <label htmlFor="UploadInput" className="form-label">Upload Attachment</label>
                                <div className='custom-file d-flex'>
                                    <input type="file" ref={mediaRef} className="custom-file-input"
                                        onChange={onFileChanged}
                                        id="customFileLang" lang="in" />
                                    <label className="custom-file-label btn" for="customFileLang">Browse </label>
                                </div>
                                <div className='pdf-condi'>
                                    <span>Max Size 2MB</span>
                                </div>
                            </div>
                        </div> )
                                }
                            {
                            (formik.values.attachment_db) && (<div className="col-lg-6">
                                <div className="field upload-file email_upload_file">
                                <label htmlFor="UploadInput" className="form-label">Upload Attachment</label>
                                <a href={formik.values.attachment_db} target="_blank" className="btn outlined-btn preview-btn">Preview</a>
                                    <a href='javascript:;' className="btn outlined-btn" onClick={() => { handleDeleteDocuments(formik.values.email_template_id,'attachment', formik.values.attachment_db) }}>Delete</a>
                                    
                                </div>
                            </div>)
                            }
                        </div>
                        <p className="template-policy">The sender's signature will automatically be added</p>
                        <div className="form-check share-template">
                            <Checkbox
                                inputId="share_to_everyone"
                                className="form-check-input"
                                name="share_to_everyone"
                                value={formik.values.share_to_everyone} onChange={formik.handleChange}
                                checked={formik.values.share_to_everyone} />

                            <label className="form-check-label template-share" htmlFor="share_to_everyone">
                                Share This Template With Everyone
                            </label>
                        </div>
                        <div className="modal-footer template-footer">
                            <button type="button" className="btn btn-cancel" data-bs-dismiss="modal" disabled={loading} onClick={() => { emailModalClose(); props.changeStatusEmailTemplate(); }} >Cancel</button>
                            <button type="submit" className="btn btn-submit create-sub btn-submit-loader" disabled={loading}>Save
                                {loading && (<Typography align="center">
                                    <CircularProgress value={66} />
                                </Typography>)}
                            </button>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default EmailModal;
