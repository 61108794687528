import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Alert, CircularProgress, Typography } from "@mui/material";
import axios, { stripePublicKey } from "../../../axiosCall.js";
import StripeCheckout from "react-stripe-checkout";


const MakePayment = (props) => {
    // console.log(props.parentToChild);
    // console.log(props.userId);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [movelicense, setMovelicense] = useState(false);
    const [numberCall, setNumberCall] = useState(false);
    const [loading, setLoading] = useState(false);
    const [takePlanPrice, setTakePlanPrice] = useState(0);
    const [totalPlanPrice, setTotalPlanPrice] = useState(0);
    const [isShowActivationFees, setIsShowActivationFees] = useState(false);

    const handleSubmitCP = async (token) => {
        setLoading(true);
        let dataSend = {
            product_id: props.favorite,
            user_id: props.userId,
            token_id: token.id,
            card_id: token.card.id
        }
        try {
            const response = axios.post('/add-subscription', dataSend).then(function (response) {
                const { Status, StatusMessage, Error, Result } = response.data ? response.data : [];
                let finalApiError = '';
                if (Status === false) {
                    // if (Error.length > 0) {
                    //     // Error.map(
                    //     //     (errorMessage) =>
                    //     //         finalApiError += '<li>' + errorMessage + '</li>'
                    //     //             (finalApiError = errorMessage)
                    //     // );
                    // } else {
                    finalApiError = StatusMessage;
                    // }
                    setErrorMessage(finalApiError);
                    setLoading(false);
                } else if (Status === true) {
                    setLoading(false);
                    navigate('/successfully-plan-subscribe');
                }
            }).catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    setErrorMessage(error.response.data.message);
                    setLoading(false);

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    setErrorMessage(error.message);
                    setLoading(false);
                }
                console.log(error.config);
            });

        } catch (err) {
            //console.log(err.toJSON());
            // setErrorMessage(err.message);
            // setLoading(false);
            if (err.response) {
                // The client was given an error response (5xx, 4xx)
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else if (err.request) {
                // The client never received a response, and the request was never left
            } else {
                // Anything else
                console.log('Error', err);
            }
        }
    };
    const onClick = () => {
        props.parentToChild()
    }
    useEffect(() => {
        var planPrice = 0, takePlanPrice = 0, finalPrice = 0;

        if (props.favorite === "1") {
            planPrice = 9;
            setTakePlanPrice(9)
        } else if (props.favorite === "2") {
            planPrice = 19;
            setTakePlanPrice(19)
        }
        else if (props.favorite === "3") {
            planPrice = 0;

            setTakePlanPrice(0)

        }
        //alert(props.userLicenseState)
        if (props.userLicenseState === 9 || props.userLicenseState === 23) {
            finalPrice = (props.favorite === "3") ? planPrice : planPrice;
            setTotalPlanPrice(finalPrice + 25)
            setMovelicense(false);
            setIsShowActivationFees(true)
        } else if (props.userLicenseState === 6) {
            finalPrice = (props.favorite === "3") ? planPrice : planPrice;
            setTotalPlanPrice(finalPrice + 50)
            setMovelicense(true);
            setIsShowActivationFees(true)
        } else {
            finalPrice = (props.favorite === "3") ? planPrice - 25 : planPrice;
            setTotalPlanPrice(finalPrice + 25)
        }


    }, [numberCall]);

    
        

    return (
        
        <section className="referral-template subs-template sec-pt sec-pb make-payment">
            <div className="container">
                <div className="referral-container">
                    <h2 className='main-heading'>Subscription</h2>
                    <div className="cards-container row row-cols-1 row-cols-lg-2 g-1">
                        <div className="col col-left ps-lg-0 pe-lg-3">
                            <Card className="subs-card-left">
                                <Card.Body>
                                    {
                                        (props.favorite == 3) && (<label htmlFor="subs_1">
                                        <div className="sub_field sub_field_1 ">
                                            
                                            <label className="step-one-label">Basic</label>
                                            <p className='referral-desc'>FREE FOREVER - 50/50 Commission Split </p>
                                        </div>
                                        </label>)
                                    }
                                    {
                                        (props.favorite == 1) && (<label htmlFor="subs_2">
                                        <div className="sub_field sub_field_1 ">
                                        
                                        <label className="step-one-label">Standard</label>
                                        <p className='referral-desc'><b>$9</b>/Mo-80/20 Commission Split (You Keep 80% of the Referral Commission)</p>
                                        </div>
                                    </label>)
                                    }
                                    {
                                        (props.favorite == 2) && (<label htmlFor="subs_3">
                                        <div className="sub_field sub_field_1 ">
                                        
                                        <label className="step-one-label">Pro</label>
                                        <p className='referral-desc'><b>$19</b>/Mo-90/10 Commission Split (You Keep 90% of the Referral Commission) </p>
                                        </div>
                                    </label>)
                                    }
                                    <ul className="payment-details-list">
                                        {(movelicense == true) ? <li>
                                            <p className="title-name">License Moving Fee</p>
                                            <p className="amount">$25</p>
                                        </li> : <li>
                                        </li>}
                                        {
                                            (isShowActivationFees) && (<li>
                                                <p className="title-name">One-time activation fee</p>
                                                <p className="amount">$25</p>
                                            </li>)
                                        }
                                        <li>
                                            <p className="title-name">Plan fees</p>
                                            <p className="amount">${takePlanPrice}</p>
                                        </li>
                                    </ul>
                                </Card.Body>
                                <Card.Footer>
                                    <ul className="list-ta">
                                        <li>
                                            <p className="title-name">Total Amount</p>
                                            <p className="amount">${totalPlanPrice}</p>
                                        </li>
                                    </ul>
                                    <div className="bottom-buttons">
                                        <button
                                        type="button"
                                        class="btn btn-cancel btn-700 me-3"
                                        onClick={onClick}
                                        disabled={loading}
                                    >
                                        Back

                                    </button>
                                    {
                                        (loading) && (<button
                                            type="button"
                                            className="btn  btn-submit subs-pay-btn btn-submit-loader blue-button"
                                            onClick={onClick}
                                            disabled={loading}
                                        >
                                            Processing
                                        {loading && (<Typography align="center">
                                            <CircularProgress value={30} />
                                        </Typography>)}
                                        </button>)
                                    }
                                   {
                                        (!loading) && (
                                        <StripeCheckout
                                            className="blue-button"
                                            stripeKey={stripePublicKey}
                                            token={handleSubmitCP}
                                            amount={totalPlanPrice * 100}
                                            name={props.planName}
                                            email={props.userEmail}
                                            label="Pay with Card" 
                                        >
                                        </StripeCheckout>)
                                    }
                                        </div>
                                    
                                </Card.Footer>
                            </Card>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>

    )
}

export default MakePayment;
