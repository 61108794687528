import { RadioButton } from "primereact";
import { Card } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import axios from "../../../axiosCall.js";
import MakePayment from './MakePayment';
import { Alert, CircularProgress, Typography } from '@mui/material'

const PlanSelection = (props) => {
  const [favorite, setFavorite] = React.useState('3');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const search = useLocation().search;
  const verificationCode = new URLSearchParams(search).get('c');
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
  const [userEmail, setUserEmail] = useState('');
  const [userBadd, setUserBadd] = useState('');
  const [userId, setUserId] = useState('');
  const [planName, setPlanName] = React.useState('Basic');
  const [linkPost, setLinkPost] = useState(true);
  const [planId, setPlanId] = useState('');
  const [userType, setUserType] = useState('');
  const [userLicenseState, setUserLicenseState] = useState('');
  const [userMsg, setUserMsg] = useState('');
  const [isLinkExpired, setIsLinkExpired] = useState(false);

  const handleChange1 = () => {
    setFavorite('1');
    setPlanName('Standard')
  };

  const handleChange2 = () => {
    setFavorite('2');
    setPlanName('Pro')
  };
  const handleChange3 = () => {
    setFavorite('3');
    setPlanName('Basic')
  };
  const routeChange = () => {
    navigate('/');
  }
  const parentToChild = () => {
    setPlanId(favorite);
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  }

  let counter = 0;
  useEffect(() => {
    const verifyUser = async () => {
      setErrorMessage('');
      setSuccessMessage('');
      setLoader(true);

      try {
        const response = await axios.post(`/user_verify/${verificationCode}`);
        const { Status, StatusMessage, Error, Result } = response.data;
        let finalApiError = "";
        setLoader(false);

        if (Status === false) {
          if (Error.length > 0) {
            // Error.map(
            //   (errorMessage) =>
            //     finalApiError += '<li>'+errorMessage+'</li>'
            //     (finalApiError = errorMessage)
            // );
          } else {
            finalApiError = StatusMessage;
          }
          setErrorMessage(finalApiError);

          if (Result.is_expired === 1) {
            const timer = setTimeout(() =>
              navigate({
                pathname: '/login',
              })
              , 3000);
            setIsLinkExpired(true);
          }
          else {
            setIsLinkExpired(false);
          }
          window.scrollTo(0, 0);
        } else {

          setUserEmail(Result.email)
          setUserBadd(Result.business_address)
          setUserId(Result.user_id);
          setUserType(Result.type)
          setUserLicenseState(Result.license_state_id)
          if (Result.license_state_id === 9 || Result.license_state_id === 33) {
            setUserMsg('There is a $25 one-time activation fee and your state is also $25 charge to move your license.');
        } else if (Result.license_state_id === 6 || Result.license_state_id === 30) {
            setUserMsg('There is a $25 one-time activation fee and your state is also $25 charge to move your license.');
        }else{
            setUserMsg('There is a $25 one-time activation fee on all plans and some states charge $25 to move your license.');
        }

        }
      } catch (err) {
        // console.log(err);
        setErrorMessage(err);
        setLoader(false);
      }
    };

    if (counter === 0) {
      //alert(counter)
      counter++;

      verifyUser();

    }

  }, [linkPost]);

  return (
    <div className=''>
      {
        (isLinkExpired) && (
          <>
            <section className="form-section auth-bg">
              <div className="container-fluid">
                <div className="form-container reset-container">
                  <div className="form-wrapper">

                    <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                      Link is expired. Please try again.

                    </Alert>
                    <div className="card-container">
                      <div className=''>
                        <p className="title sub-titles">Verification link is expired.</p>
                        <br />

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      {(isLinkExpired === false) && (<div className="">
        {(show == true) ? <MakePayment parentToChild={parentToChild} favorite={favorite} userId={userId} userEmail={userEmail} userLicenseState={userLicenseState} planName={planName}/> : <div></div>}
        {(show == false) ?
          <section className='referral-template sec-pt sec-pb'>
            <div className="container">
              <div className="referral-container">
                <h2 className='main-heading'>Subscription</h2>
                <Card>
                  <Card.Body>
                    <div className="card-container">

                      <div className='referral-fields subscription-page'>
                        <p className="title sub-titles">Pick your plan</p>
                        <label htmlFor="subs_1">
                          <div className={(favorite == 3) ? "referral-field active  sub_field sub_field_1 " : "referral-field sub_field sub_field_1"}>
                            <RadioButton inputId="subs_1" name="referral_type" checked={(favorite == 3) ? true : false} value={favorite === '3'} onChange={handleChange3} />
                            <label className="step-one-label">Basic</label>
                            <p className='referral-desc'>FREE FOREVER - 50/50 Commission Split </p>
                          </div>
                        </label>
                        <label htmlFor="subs_2">
                          <div className={(favorite == 1) ? "referral-field active  sub_field sub_field_1 " : "referral-field sub_field sub_field_1"}>
                            <RadioButton inputId="subs_2" name="referral_type" checked={(favorite == 1) ? true : false} value={favorite === '1'} onChange={handleChange1} />
                            <label className="step-one-label">Standard</label>
                            <p className='referral-desc'><b>$9</b>/Mo-80/20 Commission Split (You Keep 80% of the Referral Commission)</p>
                          </div>
                        </label>
                        <label htmlFor="subs_3">
                          <div className={(favorite == 2) ? "referral-field active  sub_field sub_field_1 " : "referral-field sub_field sub_field_1"}>
                            <RadioButton inputId="subs_3" name="referral_type" checked={(favorite == 2) ? true : false} value={favorite === '2'} onChange={handleChange2} />
                            <label className="step-one-label">Pro</label>
                            <p className='referral-desc'><b>$19</b>/Mo-90/10 Commission Split (You Keep 90% of the Referral Commission) </p>
                          </div>
                        </label>
                        <p className="sub-description">{userMsg}</p>
                      </div>
                      <div className="card-footer text-end">
                        <button class="btn btn-cancel me-3" type="button" onClick={routeChange}>Cancel</button>
                        <button class="btn btn-submit btn-500" type="button" onClick={() => parentToChild(show)}>Next</button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div> </div></section> : <div></div>}
      </div>)
      }
    </div>

  )
}

export default PlanSelection;
