import React, { useContext, useState,useEffect } from "react";
import { SubmitAReferralContext } from "../context/submit-a-referral-context";
import { classNames } from 'primereact/utils';
import { Dropdown, InputText, RadioButton, InputTextarea } from "primereact";
import { Card } from "react-bootstrap";
import Button from '@mui/material/Button';

const ReferralType = ({ navigation }) => {
  const { next } = navigation;
  const [state, dispatch] = useContext(SubmitAReferralContext);
  const [referral_type, setReferralType] = useState(state.referral_type || "buyer_referral");
  const [isDisabled,setIsDisabled] = useState(false);
  useEffect(() => {
    console.log('state.referral_type '+state.referral_type)
    if(state.edit_referral_id)
    {
      setIsDisabled(true);
    }
    else
    {
      setIsDisabled(false);
    }
  }, [referral_type]);

  const submitForm = () => {
    if (referral_type === "") return;
    // save data to global state
    dispatch({
      type: "SAVE_RERERRAL_TYPE",
      edit_referral_id:state.edit_referral_id,
      referral_type: referral_type,
      buyer_or_seller:state.buyer_or_seller,
    });
    next();
  };


  const handleReferralTypeMode = (mode) => {
    setReferralType(mode)
  }
  return (
    <div>
      <Card.Body>
        <div className='referral-fields step-1'>
          <h2 className="referall-title mobile-title">Referral type</h2>
          <label htmlFor="referralTypeId">
            <div className={classNames("referral-field ref-type", { 'active': referral_type === 'buyer_referral' })} >
              <RadioButton inputId="referralTypeId" onChange={() => { handleReferralTypeMode('buyer_referral') }} name="referral_type" value="buyer_referral" checked={referral_type === 'buyer_referral'} disabled={isDisabled}/>
              <label className="step-one-label">Buyer Referral</label>
              <p className='referral-desc'>I have a referral looking to buy a property</p>
            </div>
            </label>
            <label htmlFor="referralTypeId1">
            <div className={classNames("referral-field ref-type", { 'active': referral_type === 'seller_referral' })}>
              <RadioButton inputId="referralTypeId1" onChange={() => { handleReferralTypeMode('seller_referral') }} name="referral_type" value="seller_referral" checked={referral_type === 'seller_referral'} disabled={isDisabled}/>
              <label className="step-one-label"> Seller Referral</label>
              <p className='referral-desc'>I have a referral looking to sell a property</p>
            </div>
            </label>
            <label htmlFor="referralTypeId2">
            <div className={classNames("referral-field ref-type", { 'active': referral_type === 'buyer_and_seller_referral' })}>
              <RadioButton inputId="referralTypeId2" onChange={() => { handleReferralTypeMode('buyer_and_seller_referral') }} name="referral_type" value="buyer_and_seller_referral" checked={referral_type === 'buyer_and_seller_referral'} disabled={isDisabled}/>
              <label className="step-one-label">Buyer and Seller Referral</label>
              <p className='referral-desc'>I have a referral looking to buy and sell a property</p>
            </div>
            </label>
      </div>
      </Card.Body>
      <Card.Footer>
      <div className="text-end">

        <Button
          className="btn btn-submit"
          variant="contained"
          color="primary"
          onClick={() => {submitForm()}}
        >
          Next
        </Button>
      </div>
    </Card.Footer>

    </div>
  );
};

export default ReferralType;
