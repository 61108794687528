import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import {  InputTextarea } from 'primereact';
import Button from 'react-bootstrap/Button';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import Swal from 'sweetalert2';

const AdminNotesForm = (props) => {

    const { user, logout } = useAuth();
    const [show, setShow] = useState(props.showAdminNotesForm);
    const navigate = useNavigate();
    const closeModal = () => setShow(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitCall, setIsSubmitCall] = useState(false);
    let minDate = new Date();
    const [inputList, setInputList] = useState(props.adminNotesJsonDB);
    const formik = useFormik({
        initialValues: {
            referral_id: props.referralRowId,
            admin_notes_json: props.adminNotesJsonDB,
            host_name: window.location.origin,
        },
        validate: (data) => {
            let errors = {};
            let isValid = true;
            
            if(isSubmitCall)
            {
                inputList.map((x, i) => {
                    
                    if (x.notes == null || x.notes.trim() === '') {
                        x.notes_error = 'Name is required.';
                        isValid = false;
                    }
                    else if (x.notes.length > 500)
                    {
                        x.notes_error = 'Max characters: 500';
                        isValid = false;
                    }
                    else if (x.notes.length < 20)
                    {
                        x.notes_error = 'Min characters: 20';
                        isValid = false;
                    }
                });
            }
            if (isValid) {
                return errors;
            } else {
                errors.multi_fields = "error";
                return errors;
            }
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    
    useEffect(() => {
        if (user !== null) {
            
            formik.setFieldValue("users_id", user.id ? user.id : "");
        }
    }, [user]);
    
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;

        if (name === "notes") {
            list[index]["notes_error"] = "";
        } 
        setInputList(list);
        formik.setFieldValue("admin_notes_json", list);
    };
    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        //alert(index);
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        //alert('list '+JSON.stringify(list));
        formik.setFieldValue("admin_notes_json", list);
    };

    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                notes: "",
                notes_error: "",
                notes_updated_date: new Date(new Date().toLocaleString('en', {timeZone: 'America/New_York'})),
            },
        ]);
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
   
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        try {
            const response = await axios.post('/admin-notes-update', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {
                setLoading(false);
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        closeModal();
                        props.changeStatuAdminNotesForm();
                    }
                });
            }
        } catch (err) {

            setLoading(false);
            window.scrollTo(0, 0);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {

                setErrorMessage("Something went wrong");
            }
        }
    };
    
    
    return (
        <>
            {/* <Button variant="primary" onClick={inviteShow}>
                Invite Modal
            </Button> */}
            <Modal enforceFocus={false} className='invite-user-popup' show={show} onHide={closeModal} data-keyboard="false" backdrop="static">

                <Modal.Header className='invite-user-modal'>
                    <Modal.Title>Admin Notes</Modal.Title>
                </Modal.Header>
                {errorMessage && (
                    <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                        {errorMessage}
                    </Alert>
                )}
                <form onSubmit={formik.handleSubmit} >
                    <Modal.Body className='invite-user-section'>
                        <div className="referral-fields step-3">
                            
                            <div className="invite-user-body">
                                {inputList.map((x, i) => {
                                    
                                    return (
                                        <div key={i} className="invite-user-form">
                                            <div className="field mb-3">
                                                <label htmlFor="recipient-name">Notes <span className="reuired-star">*</span></label>
                                                <InputTextarea
                                                    rows={5} cols={30}
                                                    autoComplete="off"
                                                    placeholder="Enter here"
                                                    name="notes"
                                                    value={x.notes} onChange={(e) => handleInputChange(e, i)}
                                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('notes') })}
                                                />
                                                { (x.notes_error) && (
                                                    <small className="p-error">
                                                        {
                                                            x.notes_error
                                                        }
                                                    </small>
                                                )}
                                            </div>
                                            {/* {
                                                (<div className="btn-box mb-3">
                                                    {inputList.length !== 1 && (
                                                        <Button 
                                                            className="add-more remove-link me-2"
                                                            onClick={() =>
                                                                handleRemoveClick(i)
                                                            }
                                                        >
                                                            Remove
                                                        </Button>
                                                    )}
                                                    {inputList.length - 1 === i && (i < 10) && (
                                                        <Button
                                                            onClick={handleAddClick}
                                                            className="add-more"
                                                        >
                                                            Add More +
                                                        </Button>
                                                    )}
                                                </div>)
                                            } */}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='invite-user-footer'>
                        <Button className='cancel-btn' variant="secondary" onClick={() => { closeModal(); props.changeStatuAdminNotesForm() }}>
                            Cancel
                        </Button>
                        <Button type="submit" className='btn btn-submit btn-500 btn-submit-loader' onClick={() => {setIsSubmitCall(true)}} variant="primary" disabled={loading}>
                        Save

                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                        </Button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default AdminNotesForm;