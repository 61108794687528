import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Alert, CircularProgress, Typography } from '@mui/material'
import axios from '../../axiosCall.js';
import { IconButton } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

let CryptoJS = require("crypto-js")
let CryptoJSAesJson = {
    stringify: function (cipherParams) {
        var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
        if (cipherParams.iv) j.iv = cipherParams.iv.toString();
        if (cipherParams.salt) j.s = cipherParams.salt.toString();
        return JSON.stringify(j);
    },
    parse: function (jsonStr) {
        var j = JSON.parse(jsonStr);
        var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
        if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
        if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
        return cipherParams;
    }
}
const UnsubscribePage = () => {
    const secretKey = "REFR2022@GALAXY";
    let requestPeopleId = '';
    const navigate = useNavigate();
    const search = useLocation().search;
    const peopleIdFromEmail = new URLSearchParams(search).get('r');
    
    const [errorMessage, setErrorMessage] = useState('');
    
    
    const [isLinkExpired, setIsLinkExpired] = useState(false);
    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    if (peopleIdFromEmail === null) {
        navigate('/login');
    } 
    else if(peopleIdFromEmail)
    {
        var encrypted = decodeURIComponent(peopleIdFromEmail);
        if(!isJsonString(encrypted))
        {
            navigate('/login');
        }
        else
        {   
            let json_obj =JSON.parse(encrypted);
            if(("ct" in json_obj)===false && ("iv" in json_obj)===false && ("s" in json_obj)===false)
            {
                navigate('/login');
            }
            else
            {
                
                let peopleIdDecrypt = CryptoJS.AES.decrypt(encrypted, secretKey,{format: CryptoJSAesJson});
                
                if (peopleIdDecrypt == '')
                {
                    navigate('/login');
                }
                else
                    requestPeopleId = JSON.parse(peopleIdDecrypt.toString(CryptoJS.enc.Utf8));
            
            }
        }
    }
    else {
        navigate('/login');
    }

    useEffect(() => {
        const verifyUser = async () => {
            setErrorMessage('');
           // alert(verificationCode)
            try {
                const response = await axios.post(`/verify-unsubscribe-link/${requestPeopleId}`);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = "";

                if (Status === false) {
                    if (Error.length > 0) {
                        Error.map(
                            (errorMessage) =>
                                //finalApiError += '<li>'+errorMessage+'</li>'
                                (finalApiError = errorMessage)
                        );
                    } else {
                        finalApiError = StatusMessage;
                    }
                    setErrorMessage(finalApiError);
                   // alert(Result.is_expired)
                    if(Result.is_expired)
                    {
                        setIsLinkExpired(true);
                    }
                    else
                    {
                        setIsLinkExpired(false);
                    }
                    window.scrollTo(0,0);
                }
                else {
                    setIsLinkExpired(false);
                }
            } catch (err) {
                // console.log(err);
                setErrorMessage('There is something wrong. Please try again');
                window.scrollTo(0,0);
            }
        };

        verifyUser();
    }, []);
    const redirectToLogin = () => {
        navigate('/login');
    }
    return (
        <>
            <section className="form-section auth-bg">
                <div className="container-fluid">
                    <div className="form-container reset-container">
                        <div className="form-wrapper">
                            {errorMessage && (
                                <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                    {errorMessage}
                                </Alert>
                            )}
                            {
                                (isLinkExpired) && (
                                    <>
                                
                                <p className="signup-desc">Your link is expired. Please try again.</p>
                                <br/>
                                <div className="form-group">
                                <NavLink to="/login" className="btn form-btn">Login</NavLink>
                                </div></>
                            )}
                            {
                                    (isLinkExpired === false) && (
                            <><h1 className="signup-heading my-2">Success</h1>

                            <p className="signup-desc changedPassword-desc mb-4">
                                <span>
                                    <IconButton>
                                        <CheckCircleIcon />
                                    </IconButton>
                                </span>
                                You have successfully unsubscribe.
                            </p>

                            <div className="form-group">
                                <button className="btn form-btn" type="button" onClick={redirectToLogin}>Login</button>
                            </div></>)}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default UnsubscribePage;