import React, { useContext, useState, useEffect } from "react";
import { SubmitAReferralContext } from "../context/submit-a-referral-context";
import { useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import { Dropdown, Avatar, InputText, RadioButton } from "primereact";
import { Card } from "react-bootstrap";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { InputMask } from "primereact";
import axios from "../../../axiosCall.js";
import useAuth from "./../../../hooks/useAuth";

const ClientInformation = ({ navigation }) => {
    const navigate = useNavigate();
    const { previous, next } = navigation;
    const [state, dispatch] = useContext(SubmitAReferralContext);

    
    
    const [dbPeopleData, setDbPeopleData] = useState([]);
    const [isEmailUnique, setIsEmailUnique] = useState(true);

    const [dbClientReferenceData, setDbClientReferenceData] = useState([]);
    const [clientReferenceOtherShow, setClientReferenceOtherShow] =
        useState(false);
    
    const { user,logout } = useAuth();
    const fetchClientReferenceData = async () => {
        try {
            const response = await axios.post("/get-all-client-reference-data");
            const { Status, StatusMessage, Error, Result } =
                response.data;

            if (Status === true) {
                //alert(Result)
                setDbClientReferenceData(Result);
            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
              }
        }
    };
    
    

    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };
    const optionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    };
    const formik = useFormik({
        initialValues: {
            client_type: state.client_type || "new_client",
            client_first_name: state.client_first_name || "",
            client_last_name: state.client_last_name || "",
            client_email: state.client_email || "",
            client_phone: state.client_phone || "",
            client_address: state.client_address || "",
            client_reference_other: state.client_reference_other || "",
            other_interested_client: state.other_interested_client || "",
            client_reference_id: state.client_reference_id || "",
            existing_client_id: state.existing_client_id || "",
        },

        validate: (data) => {
            let errors = {};
                if (data.client_type === "existing_client") {
                    if (!data.existing_client_id) {
                        errors.existing_client_id = "Client is required.";
                    }
                }

                if (data.client_first_name == null || data.client_first_name.trim() === '') {
                    //console.log('error client_first_name'+data.client_first_name)
                    errors.client_first_name = "First name is required.";
                }
                else if (data.client_first_name.length > 50)
                {
                    errors.client_first_name = 'Max characters: 50';
                }

                if (data.client_last_name == null || data.client_last_name.trim() === '') {
                    errors.client_last_name = "Last name is required.";
                }
                else if (data.client_last_name.length > 50)
                {
                    errors.client_last_name = 'Max characters: 50';
                }

                if (data.other_interested_client) {
                    if (data.other_interested_client.length > 200)
                    {
                        errors.other_interested_client = 'Max characters: 200';
                    }
                }

                if (!data.client_reference_id) {
                    errors.client_reference_id = "Please select one.";
                }
                else {
                    if (data.client_reference_id.name === 'Other' || data.client_reference_id.name === 'other') {
                        if (data.client_reference_other == null || data.client_reference_other.trim() === '') {
                            errors.client_reference_other = "Other notes is required.";
                        }
                        else if (data.client_reference_other.length > 50)
                        {
                            errors.client_reference_other = 'Max characters: 50';
                        }
                    }
                }
                if (!data.client_email) {
                    setIsEmailUnique(true);
                    errors.client_email = "Email is required.";
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                        data.client_email
                    )
                ) {
                    setIsEmailUnique(true);
                    errors.client_email =
                        "Invalid email address. e.g. example@email.com";
                } else if((!isEmailUnique) && (data.client_email)) {
                    errors.client_email =
                        "Email address already exists.";
                }
                if (!data.client_phone) {
                    errors.client_phone = "Phone is required.";
                }
                
            
            return errors;
        },
        onSubmit: (data) => {
            dispatch({
                type: "SAVE_CLIENT_INFO",
                client_type: data.client_type,
                client_first_name: data.client_first_name,
                client_last_name: data.client_last_name,
                client_email: data.client_email,
                client_phone: data.client_phone,
                client_address: data.client_address,
                client_reference_other: data.client_reference_other,
                other_interested_client: data.other_interested_client,
                client_reference_id: data.client_reference_id,
                existing_client_id: data.existing_client_id,
            });
            next();
        },
    });
    useEffect(() => {

        if (state.client_first_name && state.client_last_name) {
            
        }
        
        if (state.client_reference_id.name === 'Other' || state.client_reference_id.name === 'other') {
            setClientReferenceOtherShow(true);
        }
        else {
            setClientReferenceOtherShow(false);
        }
        if (user !== null) {
            const fetchPeopleData = async () => {
                let formData = new FormData();
                formData.append('users_id', user.id);
                try {
                    const response = await axios.post("/get-all-people", formData);
                    const { Status, StatusMessage, Error, Result } =
                        response.data;

                    if (Status === true) {
                        //alert(Result)
                        setDbPeopleData(Result);
                       
                        Result.map(data => {
                            if(data.id === state.existing_client_id)
                            {
                                formik.setFieldValue("existing_client_id", data);
                            }  
                        });
                    }
                } catch (err) {
                    
                }
            };
            fetchPeopleData();
            fetchClientReferenceData();
        }
    }, [user]);
    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };
    useEffect(() => {
        
        if ((formik.values.client_type == 'existing_client')) {
            
        }
        else {
            
        }
    }, []);

    const handleClientTypeMode = (mode) => {
        formik.setFieldValue("client_type", mode);
        formik.setFieldValue("client_first_name", "");
        formik.setFieldValue("client_last_name", "");
        formik.setFieldValue("client_email", "");
        formik.setFieldValue("client_phone", "");
        formik.setFieldValue("client_address", "");
        formik.setFieldValue("existing_client_id", "");
        formik.setFieldValue("client_reference_other", "");
        formik.setFieldValue("other_interested_client", "");
        formik.setFieldValue("client_reference_id", "");
        
    };
    const handleClient = (e) => {
        
        if (e.target.value) {
            let data = e.target.value;
            formik.setFieldValue("client_first_name", data.first_name);
            formik.setFieldValue("client_last_name", data.last_name);
            formik.setFieldValue("client_email", data.email);
            formik.setFieldValue("client_phone", data.contact_number);
            formik.setFieldValue("client_address", data.full_address);
            
            if(data.first_name)
            {
                //console.log('client_first_name'+data.first_name)
                formik.setFieldError('client_first_name',"")
            }
                
            if(data.last_name)
                formik.setFieldError('client_last_name',"")
            if(data.email)
                formik.setFieldError('client_email',"")
            if(data.contact_number)
                formik.setFieldError('client_phone',"")

        } else {
            
            formik.setFieldValue("client_first_name", "");
            formik.setFieldValue("client_last_name", "");
            formik.setFieldValue("client_email", "");
            formik.setFieldValue("client_phone", "");
            formik.setFieldValue("client_address", "");
            formik.setFieldValue("client_reference_other", "");
            formik.setFieldValue("other_interested_client", "");
            formik.setFieldValue("client_reference_id", "");
            
        }
        formik.setFieldValue('existing_client_id',e.target.value)
        formik.setFieldError('existing_client_id',"")
    };
    const handleClientReference = (e) => {
        formik.setFieldValue("client_reference_other", "");
        if (e.target.value.name === "Other") {
            setClientReferenceOtherShow(true);
        } else {
            setClientReferenceOtherShow(false);
        }
        formik.setFieldValue('client_reference_id',e.target.value)
        formik.setFieldError('client_reference_id',"")
    };
    
    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Card.Body>
                    <div className="referral-fields step-3">
                        <h2 className="referall-title client-info-title">
                            Client Information
                        </h2>
                        {
                            (dbPeopleData.length > 0) && (<div className="referral-agent-fields mb-2">
                                <div className="agent-field">
                                    <RadioButton
                                        inputId=""
                                        onChange={() => {
                                            handleClientTypeMode("new_client");
                                        }}
                                        name="client_type"
                                        value="new_client"
                                        checked={
                                            formik.values.client_type ===
                                            "new_client"
                                        }
                                    />
                                    <label htmlFor="">Add New</label>
                                </div>
                                <div className="agent-field">
                                    <RadioButton
                                        inputId=""
                                        onChange={() => {
                                            handleClientTypeMode("existing_client");
                                        }}
                                        name="client_type"
                                        value="existing_client"
                                        checked={
                                            formik.values.client_type ===
                                            "existing_client"
                                        }
                                    />
                                    <label htmlFor="">
                                        Select from Existing Contacts
                                    </label>
                                </div>
                            </div>)
                        }
                        {formik.values.client_type === "existing_client" && (
                            <div className="row referal-input">
                                <div className="col-lg-12">
                                    <div className="field referral-bar">
                                        <span className="p-input-icon-right">

                                            <Dropdown
                                                className={classNames(
                                                    "form-select",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "existing_client_id"
                                                            ),
                                                    }
                                                )}
                                                value={
                                                    formik.values
                                                        .existing_client_id
                                                }
                                                options={dbPeopleData}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    handleClient(e);
                                                }}
                                                // onChange={formik.handleChange}
                                                optionLabel="name"
                                                name="existing_client_id"
                                                filter
                                                filterBy="name"
                                                placeholder="Select one"
                                                valueTemplate={selectedTemplate}
                                                itemTemplate={optionTemplate}
                                            />
                                            {getFormErrorMessage(
                                                "existing_client_id"
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(formik.values.client_type === "new_client" || formik.values.existing_client_id) && (
                            <div className={`row ${dbPeopleData.length > 0 ? "referal-input" : ""}`}>
                                <div className="col-lg-6">
                                    <div className="field">
                                        <label
                                            htmlFor="FirstInput"
                                            className="form-label"
                                        >
                                            First Name <span className="reuired-star">*</span>
                                        </label>
                                        <InputText
                                            autoComplete="off"
                                            className={classNames(
                                                "form-control",
                                                {
                                                    "p-invalid": (!formik.values.client_first_name) &&
                                                        isFormFieldValid(
                                                            "client_first_name"
                                                        ),
                                                }
                                            )}
                                            placeholder="Enter here"
                                            name="client_first_name"
                                            value={
                                                formik.values.client_first_name
                                            }
                                            onChange={formik.handleChange}
                                        />
                                        {(!formik.values.client_first_name) &&
                                        getFormErrorMessage(
                                            "client_first_name"
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="field">
                                        <label
                                            htmlFor="LastInput"
                                            className="form-label"
                                        >
                                            Last Name <span className="reuired-star">*</span>
                                        </label>
                                        <InputText
                                            autoComplete="off"
                                            className={classNames(
                                                "form-control",
                                                {
                                                    "p-invalid": (!formik.values.client_last_name) &&
                                                        isFormFieldValid(
                                                            "client_last_name"
                                                        ),
                                                }
                                            )}
                                            placeholder="Enter here"
                                            name="client_last_name"
                                            value={
                                                formik.values.client_last_name
                                            }
                                            onChange={formik.handleChange}
                                        />
                                        {(!formik.values.client_last_name) && getFormErrorMessage(
                                            "client_last_name"
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {(formik.values.client_type === "new_client" || formik.values.existing_client_id) && (
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="field">
                                        <label
                                            htmlFor="FirstInput"
                                            className="form-label"
                                        >
                                            Email Address <span className="reuired-star">*</span>
                                        </label>
                                        
                                        <InputText
                                            autoComplete="off"
                                            className={classNames(
                                                "form-control",
                                                {
                                                    "p-invalid": (!formik.values.client_email) &&
                                                        isFormFieldValid(
                                                            "client_email"
                                                        ),
                                                }
                                            )}
                                            placeholder="Enter here"
                                            name="client_email"
                                            value={formik.values.client_email}
                                            onChange={(e) => { formik.handleChange(e) }}
                                            
                                        />
                                        {(!formik.values.client_email) && getFormErrorMessage("client_email")}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="field">
                                        <label
                                            htmlFor="LastInput"
                                            className="form-label"
                                        >
                                            Phone <span className="reuired-star">*</span>
                                        </label>
                                        <InputMask
                                            autoComplete="off"
                                            className={classNames(
                                                "form-control",
                                                {
                                                    "p-invalid": (!formik.values.client_phone) &&
                                                        isFormFieldValid(
                                                            "client_phone"
                                                        ),
                                                }
                                            )}
                                            name="client_phone"
                                            mask="(999) 999-9999"
                                            placeholder="(999) 999-9999"
                                            value={formik.values.client_phone}
                                            onChange={formik.handleChange}
                                        />
                                        {(!formik.values.client_phone) && getFormErrorMessage("client_phone")}
                                    </div>
                                </div>
                            </div>
                        )}
                        {(formik.values.client_type === "new_client" || formik.values.existing_client_id) && (
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="field referral-bar">
                                        <span className="p-input-icon-right">
                                            <label
                                                htmlFor="FirstInput"
                                                className="form-label"
                                            >
                                                How do you know this client? <span className="reuired-star">*</span>
                                            </label>
                                            <Dropdown
                                                className={classNames(
                                                    "form-select",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "client_reference_id"
                                                            ),
                                                    }
                                                )}
                                                value={
                                                    formik.values
                                                        .client_reference_id
                                                }
                                                options={dbClientReferenceData}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    handleClientReference(e);
                                                }}
                                                // onChange={formik.handleChange}
                                                optionLabel="name"
                                                name="client_reference_id"
                                                filter
                                                filterBy="name"
                                                placeholder="Select one"
                                                valueTemplate={selectedTemplate}
                                                itemTemplate={optionTemplate}
                                            />
                                            {getFormErrorMessage(
                                                "client_reference_id"
                                            )}
                                        </span>
                                    </div>
                                </div>
                                {clientReferenceOtherShow && (
                                    <div className="col-lg-6">
                                        <div className="field">
                                            <label
                                                htmlFor="FirstInput"
                                                className="form-label"
                                            >
                                                Other notes <span className="reuired-star">*</span>
                                            </label>
                                            <InputText
                                                autoComplete="off"
                                                className={classNames(
                                                    "form-control",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "client_reference_other"
                                                            ),
                                                    }
                                                )}
                                                placeholder="Enter here"
                                                name="client_reference_other"
                                                value={
                                                    formik.values
                                                        .client_reference_other
                                                }
                                                onChange={formik.handleChange}
                                            />
                                            {getFormErrorMessage(
                                                "client_reference_other"
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {(formik.values.client_type === "new_client" || formik.values.existing_client_id) && (<div className="row">
                            <div className="col-lg-12">
                                <div className="field label-desc">
                                    <div className="member-title">
                                        <label htmlFor="">Any other interested parties<span className="ms-2">-</span> </label> <p className="ms-2">Family member involved in the process</p>
                                    </div>
                                    <InputText
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "other_interested_client"
                                                    ),
                                            }
                                        )}
                                        placeholder="Enter here"
                                        name="other_interested_client"
                                        value={
                                            formik.values
                                                .other_interested_client
                                        }
                                        onChange={formik.handleChange}
                                    />
                                    {getFormErrorMessage(
                                        "other_interested_client"
                                    )}
                                </div>
                            </div>
                        </div>)
                        }
                        
                        
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className="text-end">
                        <Button
                            className="btn btn-cancel rf-cancel-btn me-3"
                            variant="contained"
                            color="primary"
                            onClick={previous}
                        >
                            Back
                        </Button>
                        <Button
                            className="btn btn-submit"
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Next
                        </Button>
                    </div>
                </Card.Footer>
            </form>
        </div>
    );
};

export default ClientInformation;
