import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { InputNumber, InputText } from 'primereact';
import Button from 'react-bootstrap/Button';
import { Dropdown } from 'primereact/dropdown';
const PeopleListPopup = () => {
    const [show, setShow] = useState(false);
    const addPeopleClose = () => setShow(false);
    const addPeopleShow = () => setShow(true);
    return (
        <>
            <Button variant="primary" onClick={addPeopleShow}>
                Launch demo modal
            </Button>
            <Modal className='people-popup' show={show} onHide={addPeopleClose} centered>
                    <Modal.Header className='people-header'>
                    <Modal.Title className='people-title'>People (80)</Modal.Title>
                    <a href='' className='see-all-btn'>See All</a>
                    </Modal.Header>
                    <Modal.Body className='addpeople-section'>
                    <div class="people-data">
                        <div className='people-column'>
                            <div class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root email-dp-name odd">DS</div>
                            <div class="people-info">
                            <h2 className='member-name'>Darrell Steward</h2>
                                <h3 className='member-email'>darrellsteward@yahoo.com</h3>
                            </div>
                        </div>
                        <div className='people-column'>
                            <div class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root email-dp-name even">JC</div>
                            <div class="people-info">
                            <h2 className='member-name'>Jane Cooper</h2>
                                <h3 className='member-email'>janecooper@yahoo.com</h3>
                            </div>
                        </div>
                        <div className='people-column'>
                            <div class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root email-dp-name odd">EP</div>
                            <div class="people-info">
                            <h2 className='member-name'>Eleonar Pena</h2>
                                <h3 className='member-email'>eleonarpena@yahoo.com</h3>
                            </div>
                        </div>
                        <div className='people-column'>
                            <div class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root email-dp-name even">CC</div>
                            <div class="people-info">
                            <h2 className='member-name'>Cameron Williamson</h2>
                                <h3 className='member-email'>cameronwilliamson@yahoo.com</h3>
                            </div>
                        </div>
                        <div className='people-column'>
                            <div class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root email-dp-name odd">FM</div>
                            <div class="people-info">
                            <h2 className='member-name'>Floyd Miles</h2>
                                <h3 className='member-email'>floydmiles@yahoo.com</h3>
                            </div>
                        </div>
                        <div className='people-column'>
                            <div class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-2s90m6-MuiAvatar-root email-dp-name even">CC</div>
                            <div class="people-info">
                            <h2 className='member-name'>Cameron Williamson</h2>
                                <h3 className='member-email'>cameronwilliamson@yahoo.com</h3>
                            </div>
                        </div>
                        </div>
                    </Modal.Body>
                   
            </Modal>
        </>
    )
}

export default PeopleListPopup;