import React,{useEffect,useState} from "react";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const ForgotCheckEmail = (props) => {

  const [isOpen, setIsOpen] = useState(props.showModal);
  useEffect(() => {
    if(props.showModal)
    {
      showModal();
    }
  }, []);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  return (
    <>
    <Modal className="check-email-m" show={isOpen} onHide={hideModal} backdrop="static">
      <Modal.Body className="p-0">
      <div className="email-popup-modal">
          <div className="modal-content">
            <div className="modal-header email-popup-logo">
              <img src="./assets/images/emailicon.png" alt="img" />
            </div>
            <div className="modal-body email-popup-body">
              <h2 className="email-popup-title" >Check Your Email</h2>
              <div className="email-content">
                <p>We have sent a password recovery instruction to your email.</p>
              </div>
              <button onClick={hideModal} type="button" className="btn btn-primary email-btn closeClass" >OK</button>
              {/* <NavLink to="/reset-password-request" className="btn btn-primary email-btn">OK</NavLink> */}
            </div>
          </div>
        </div>
      </Modal.Body>
      </Modal>

      {/* <div className="modal fade"  id="forgotemailcheck" tabIndex="-1" aria-labelledby="forgotModalLabel" aria-hidden="true"  >
        <div className="modal-dialog email-popup-modal">
          <div className="modal-content">
            <div className="modal-header email-popup-logo">
              <img src="./assets/images/emailicon.png" alt="img" />
            </div>
            <div className="modal-body email-popup-body">
              <h2 className="email-popup-title" >Check Your Email</h2>
              <div className="email-content">
                <p>We have sent a password recovery instruction to your email.</p>
              </div>
              <button type="button" className="btn btn-primary email-btn closeClass" >OK</button>*/}
              {/* <NavLink to="/reset-password-request" className="btn btn-primary email-btn">OK</NavLink> */}
            {/* </div>
          </div>
        </div>
      </div> */} 

    </>
  )
}

export default ForgotCheckEmail;