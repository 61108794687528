import React, { useState, useEffect } from 'react';
import axios from "../../../axiosCall.js";


const TermsUse = () => {

  const [termsOfServiceMessage, setTermsOfServiceMessage] = useState('');
  const getEditData = async () => {
        
    try {
        const response = await axios.post("/get-content");
        const { Status, StatusMessage, Error, Result } = response.data;

        if (Status === true) {
            //alert(Result[0].terms_of_service_message)
            let message = Result[0].terms_of_service_message.replace(/<br>/g,'');
            setTermsOfServiceMessage(message);

        }
    } catch (err) {
        
    }
};
useEffect(() => {
  
  getEditData();
}, []);
  return (
    <>
      <div className="modal fade popup-modal term-condition" id="termsUse" tabindex="-1" aria-labelledby="TermsModalLabel" aria-hidden="true">
        <div className="modal-dialog content-popup-modal">
          <div className="modal-content policy-modal-co0ntent">
            <div className="modal-header">
              <h2 className="main-title">Terms of Use</h2>
              <button type="button" className="btn-close close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body term-content">
            <div className="content" dangerouslySetInnerHTML={{__html: termsOfServiceMessage }}></div>
                                    
              {/* <div className="term-content">
                <h3 className="heading">Acceptance of Terms</h3>
                <p className="content">
                  Welcome to REFR, LLC, a Massachusetts limited liability company, website, www.refrnow.com (the “Site”). REFR, LLC (collectively “REFR,” “we,” “us,” or “our”), provides a platform to enable clients to handle lead management and response for the real estate industry (collectively “Broker,” “user,” or “you”) subject to the following terms and conditions. By visiting our Site, or any web application associated with the Site, you accept the Terms and Conditions of Use ("Terms and Conditions"), Disclaimers, Security Policy and <a className="link" href="">Privacy Policy</a>. In addition to these Terms and Conditions, you may also be subject to any policies, guidelines, and agreements applicable to individual services or businesses you use; if these Terms and Conditions are inconsistent with such separate terms, these terms will control.
                </p>
                <p className="content">
                  These Terms and Conditions do not apply to information collected by any third party, including through any application or content (including advertising) that may link to or be accessible from, or on, the Site. REFR has not reviewed all of the information on other webpages and is not responsible for the content of any third party websites, products, or services that may be offered through third parties.
                </p>
                <p className="content">
                  References to "REFR" throughout these Terms and Conditions shall mean REFR, LLC, its affiliates, subsidiaries, divisions, and operational designees. We reserve the right to make changes to this Site and its Terms and Conditions at any time. Such changes shall be effective immediately upon posting to the Site. Each time you use this Site, you should visit and review the current Terms and Conditions that apply to your certain transactions. BY REGISTERING FOR OR USING THE SITE, YOU SIGNIFY YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU CANNOT USE THE SITE.
                </p>
                <p className="content">
                  We make every effort to keep this Site up and running smoothly. However, we take no responsibility for this Site being temporarily unavailable due to technical issues beyond our control. Please read these Terms and Conditions carefully as they contain important information about your use of our services.
                </p>
                <h3 className="heading">The use of this website is subject to the following Terms and Conditions of Use:</h3>
                <p className="content">
                  <span className="inner-heading">Section 1. SITE PARTICIPATION.</span>  You and your designated real estate salespersons (the “Agent” or “Agents”) agree to participate in the Network and access our Site for the purpose of assisting Referrals in the consummation of certain real estate transactions. For purposes of these Terms and Conditions, “Referral(s)” shall mean a consumer that is interested in the buying, selling or leasing of real estate, and is referred by REFR to you and your Agents for the purpose of a real estate transaction. In addition, Referral shall mean any consumer(s) referred by the Referral to you and your Agents for the purpose of a real estate transaction.
                </p>
                <p className="content">
                  <span className="inner-heading">1.1. Services Users Provide.</span> The services you and your Agents will provide to Referrals may include but are not limited to: (1) assisting Referrals sell property, (2) assisting Referrals find the appropriate property to buy or lease, and (3) placing a Referral’s current home on the multiple listing service in the area in which you and your Agents participate.
                </p>
                <p className="content">
                  <span className="inner-heading">1.2 User Obligations.</span>  In connection with said services provided to Referrals, you and your agents agree to (1) disclose to the Referrals anything required under applicable law, and (2) charge a reasonable fee to the Referral based on that particular geographical area.
                </p>
                <p className="content">
                  <span className="inner-heading">1.3 REFR Not a Party to Any Transaction.</span> You acknowledge that REFR is not a party to any purchase, rental, or other agreement between you and consumers. In addition, you acknowledge and agree that REFR does not have any responsibility to provide brokerage services to Referrals. Lastly, you acknowledge and agree that you will be the only party to provide brokerage services to Referrals.
                </p>
                <p className="content">
                  <span className="inner-heading">1.4 REFR’s Responsibility.</span> REFR’s sole responsibility is to track your and your Agents’ delivery of brokerage services to Referrals and to provide you and your Agents contact and background information to consummate the transaction under consideration.
                </p>
                <p className="content">
                  <span className="inner-heading">1.5 Broker/Agent Responsibility.</span> You acknowledge and agree to update REFR on the status of all Referrals by submitting a Referral statement at least as frequent as every thirty (30) days via the Site. In addition, upon consummation of a real estate transaction with a Referral, you and your Agents agree to provide REFR with the closing statement within fifteen (15) days following the date of closing.
                </p>
                <p className="content">
                  <span className="inner-heading">1.6 Referral Satisfaction.</span>  In the event you and/or your agents can not provide satisfactory service to a Referral for any reason, you or your Agents must notify REFR immediately. REFR reserves the right to ask you or your Agents to terminate your relationship with the Referral or request other actions on the part of you or your Agents to ensure Referral satisfaction.
                </p>
                <p className="content">
                  <span className="inner-heading">1.7 Use of Broker’s Trademark.</span>  You hereby grant REFR a limited, non-exclusive, royalty free, and perpetual license to use Broker’s name, logo, and tradename for the sole purpose of identifying the Broker as a REFR customer.  You represent and warrant that the use of the Broker’s name, logo, and tradename does not and will not violate or infringe upon any intellectual property rights of any third party.
                </p>
                <h3 className="heading">Section 2. OWNERSHIP AND RIGHTS.</h3>
                <p className="content">
                  <span className="inner-heading">2.1 Ownership of Referrals.</span>  REFR owns all rights, title and interest (including all intellectual property and proprietary rights) in and to any Referrals. As owner of the Referrals, REFR reserves the right to utilize the Referrals and Referral data as REFR deems necessary.
                </p>
                <p className="content">
                  <span className="inner-heading">2.2 Ownership of Data.</span>  You own all right, title, and interest in your personal information, data and personal contacts. REFR shall only use such information for the purpose of marketing to generate Referrals for you and your Agents. You understand that you may export your data and delete your account for any reason at any time.
                </p>
                <h3 className="heading">Section 3. ACCEPTANCE OF REFERRALS.</h3>
                <p className="content">
                  Upon REFR’s determination that a Referral is available to you, your Agents will be provided the opportunity to accept or decline said Referral. You understand and agree that Agents have the authority to accept a Referral on your behalf. In the event that an Agent accepts the Referral, we will issue a Referral Agreement (the “Cooperative Referral Agreement”). You and your Agent will have forty-eight (48) hours from the time of acceptance to determine and notify REFR if the Referral is conflictive with an existing client or if you do not agree with the Referral Service Fee stated in the Cooperative Referral Agreement, and thus reject the Referral. Upon expiration of the forty-eight-hour-period, the Referral will be deemed to be accepted by you and your Agent.
                </p>
                <h3 className="heading">Section 4.  SERVICE FEES.</h3>
                <p className="content">
                  You agree to pay REFR THIRTY FIVE AND 00/100 PERCENT (35%) of the Gross Commissions for client-side real estate brokerage services and real estate agency services involving the Referral for a period of twenty-four (24) months following the Referral (the “Referral Service Fee”). For purposes of this Section, “Gross Commissions,” shall mean the amount that the Broker is eligible to receive as stated in the closing document, including any fees and bonuses paid to you or an Agent. Gross Commissions shall not include any discounts or commission reductions that you or an Agent may extend to a Referral. Gross Commissions shall exclude any other commissions for real estate settlement services such as title insurance.
                </p>
                <p className="content">You agree that all Referral Service Fees will be paid within thirty (30) days of the closing date of the Referral transaction. Sufficient forms of payment include company check, ACH or wire transfer.</p>
                <h3 className="heading">Section 5.  TRANSFER OF REFERRALS AND AGENTS. </h3>
                <p className="content">You hereby acknowledge and agree to the following:</p>
                <ul className="list-c">
                  <li>In the event you or an Agent transfer the Referral to another broker or agent, and said broker or agent enters into any real estate transaction with the Referral during the Referral Coverage Period, you shall pay REFR the respective Referral Service Fee as if you or your agent has personally participated in the real estate transaction.</li>
                  <li>In the event an Agent enters into a real estate transaction with a Referral during the Referral Coverage Period, you and the Agent shall be jointly and severally responsible for the respective Referral Service Fee.</li>
                  <li>In the event you create a new brokerage and take a Referral with you to your new brokerage, you are still obligated to pay us the applicable Referral Service Fee.</li>
                </ul>
                <h3 className="heading">Section 6. PERMITTED USES OF THE SITE.</h3>
                <p className="content">
                  Users are granted a limited, revocable, non-exclusive license to access the Site, the Site’s content, and our services provided through the Site. Users may use the Site for purposes of (1) submitting referrals to us to connect you with the Best Fit Agent to consummate the real estate transaction under consideration, (2) searching for leads, (3) accepting leads, (4) tracking leads, (5) communicating with REFR, and (6) and other related services that do not conflict with the terms and conditions stated herein.
                </p>
                <h3 className="heading">Section 7. UNAUTHORIZED USES OF THE SITE. </h3>
                <p className="content">By using this Site and accessing our services you agree that your contributions to the Site will comply with applicable state and federal laws, and will not:</p>
                <ul className="list-c">
                <li>Contain information that is false or misleading;</li>
                <li> Infringe on patent, trade secret, copyright, or other rights of any person, entity, or violate any law or contract;</li>
                <li>Contain any computer viruses, macro viruses, Trojan horses, worms, or anything else designed to interfere with, interrupt or disrupt the normal operating procedures of a computer;</li>
                <li>Contain material that is obscene, pornographic, offensive, inflammatory, defamatory of any person, unlawful, threatening, abusive, harassing, tortious, profane, or invasive of another’s privacy;</li>
                <li>Misrepresent your identity or affiliation with any person;</li>
                <li>Contain material that constitutes unauthorized advertising, junk mail, spam, or chain letters;</li>
                <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age; or</li>
                <li>Advocate, promote or assist in the infringement of the intellectual property rights of any other person.</li>
                </ul>
                <p className="content">
                  <span className="inner-heading">Noncompliance.</span> Failure to comply with the above terms constitutes a material breach of these Terms and Conditions. We have sole discretion in determining whether a user has complied with the above terms and whether a breach has occurred. In such circumstances of breach, we reserve the right to suspend or terminate your access to this Site. This termination may occur at our sole discretion without notice and may result in the forfeiture and destruction of all information associated with your account. Further, breach of these guidelines may result in legal action against you and/or disclosure of such information to law enforcement authorities.
                </p>
                <h3 className="heading">Section 8. USE OF YOUR CONTACT INFORMATION AND DATA.</h3>
                <p className="content">
                  In the event you provide your phone number, email address, first and last name or other information to us in connection with your use or access to the Site and our services, you agree to allow REFR to add such information provided to our database of users. You may receive one or more marketing e-mails from either REFR or REFR affiliates. You understand that you have the option to opt out and not receive such promotional e-mails from the Site or such affiliates’ services at any time.
                </p>
                <h3 className="heading">Section 9. CONFIDENTIALITY AND NON-DISCLOSURE.</h3>
                <p className="content">
                  Each party shall maintain and not, directly or indirectly, disclose any Confidential Information that is received from the other party.
                </p>
                <p className="content">
                  “Confidential Information” shall mean (a) the personal information of the client and the information constituting the Referral, and (b) all financial, technical, strategic, templates, documents, agreements, forms, customer and client lists, and other non-public information relating to a party or its actual or prospective business, products, or technology that may be furnished or disclosed to, or acquired by either party directly or indirectly. Such term shall also include all copies and extracts of Confidential Information and all computer-generated studies and data containing Confidential Information. 
                </p>
                <p className="content">
                  Neither party shall use the other party’s Confidential Information, except as expressly permitted by these Terms. Each party shall keep confidential and not disclose, directly or indirectly, to any persons other than those employees and/or agents who have a business-related need to have access to such Confidential Information in furtherance of the limited purposes of these Terms and Conditions and who have been notified of, and agree in writing to maintain the confidential nature of such information in accordance with these Terms and Conditions. Each party shall be responsible for any breach of this Agreement by and of its employees and/or agents.
                </p>
                <h3 className="heading">Section 10. REPRESENTATIONS AND WARRANTIES.</h3>
                <p className="content">
                  You hereby acknowledge and agree that you are responsible for and agree to abide by all laws, rules, ordinances, or regulations applicable to conduct of your real estate business, including but not limited to any and all laws, rules, ordinances, regulations or other requirements relating to taxes, data and privacy, license requirements, and compliance with all anti-discrimination and fair housing laws, as applicable.
                </p>
                <p className="content">YOU ACKNOWLEDGE AND UNDERSTAND THAT WE ARE NOT A PARTY TO ANY RENTAL, PURCHASE, OR OTHER AGREEMENT BETWEEN YOU AND CONSUMERS. Please be aware that there may be circumstances where we are nevertheless legally obligated to provide information related to your services in order to comply with requests from governmental bodies in relation to investigations, litigation or administrative proceedings, and we may choose to comply with such obligations in our sole discretion.</p>
                <p className="content">
                  For the duration of the term of the agreement between you and REFR, you and your Agents must hold all of the licenses that are required by the state your real estate brokerage business is located in, including without limitation licenses to act as real estate brokers and real estate agents as applicable. You and your Agents will comply with all applicable laws and regulations in performing its services, including without limitation the limits and restrictions on payments for referrals for other real estate settlement services.
                </p>
                <p className="content">
                  You represent and warrant that the execution, delivery and performance by you under these Terms and Conditions and the applicable agreement with REFR will not violate any law, statute or other governmental regulation and that it is in compliance and will continue to comply with during the term of the agreement all relevant local, state and federal requirements. You warrant that your brokerage will maintain sufficient insurance coverage to enable it to meet its obligations created by this agreement and by law.
                </p>
                <h3 className="heading">Section 11.  DISCLAIMER.</h3>
                <p className="content">
                  The information set forth on this Site has been obtained from sources believed reliable. While we do not doubt its accuracy we have not verified it and make no guarantee, warranty or representation about it. It is your responsibility to independently confirm its accuracy and completeness. Any projections, opinions, assumptions, or estimates used are for example only and do not represent the current or future performance of the property. The information is subject to errors, omissions, changes, including price, or withdrawal without notice. All properties are subject to prior sale or rental. The value of any property listing to you depends on a variety of factors, including but not limited to taxes, which should be evaluated by your tax, financial, and legal advisors. You and your advisors should conduct a careful, independent investigation of the property to determine to your satisfaction the suitability of the property for your needs. Furthermore, all property dimensions are approximate and may not have been verified by the selling party and cannot be verified by us. It is recommended that you hire a professional in the business of determining dimensions, such as an appraiser, architect, or civil engineer, to determine such information.
                </p>
                <p className="content">
                  WE MAKE NO REPRESENTATIONS ABOUT THE RESULTS OBTAINED FROM USING THIS SITE, ITS SERVICES, OR THE INFORMATION PROVIDED. ALL CONTENTS OF THIS SITE AND ALL SERVICES OFFERED IN CONNECTION WITH THE SITE ARE PROVIDED ON AN “AS IS” BASIS. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE EXPRESSLY DISCLAIM ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND IN CONNECTION THEREWITH, INCLUDING BUT NOT LIMITED TO TITLE, THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES' RIGHTS, AND FITNESS FOR A PARTICULAR PURPOSE.  WE MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE SECURITY, OR TIMELINESS OF THE CONTENT, INFORMATION, OR SERVICES PROVIDED ON OR THROUGH THE USE OF THIS SITE.  NO INFORMATION OBTAINED BY YOU FROM THE SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED BY US IN THESE TERMS OF USE. ALTHOUGH WE ENDEAVOR TO PROVIDE CURRENT, ACCURATE, AND RELIABLE INFORMATION ON THE SITE, IT DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE ACCURACY, RELIABILITY OR ANY USE OF INFORMATION ON THE SITE.
                </p>
                <h3 className="heading">Section 12.  LIMITATION OF LIABILITY.</h3>
                <p className="content">
                  YOU UNDERSTAND AND AGREE THAT NEITHER REFR, NOR ITS AFFILIATED OR RELATED ENTITIES (INCLUDING ITS PROVIDERS AND ITS WEB HOSTING SERVICE PROVIDERS, AND THEIR SUPPLIERS), NOR ANY OF THEIR RESPECTIVE EMPLOYEES, INCLUDING BUT NOT LIMITED TO OFFICERS, PARTNERS, AND DIRECTORS, OR AGENTS, NOR ANY PERSON OR ENTITY INVOLVED IN THE CREATION, PRODUCTION, DISTRIBUTION, HOSTING, AND/OR OPERATION OF THIS WEB SITE, IS RESPONSIBLE OR LIABLE TO ANY PERSON OR ENTITY WHATSOEVER FOR ANY LOSS, DAMAGE (WHETHER ACTUAL OR CONSEQUENTIAL), CLAIM, LIABILITY OR OTHER CAUSE OF ANY KIND OR CHARACTER WHATSOEVER BASED UPON OR RESULTING FROM THE USE OR ATTEMPTED USE OF THIS SITE INCLUDING, BUT WITHOUT LIMITATION, USE OF OR ACCESS TO ANY RELATED SOFTWARE OR HARDWARE. YOUR EXCLUSIVE REMEDY, AND REFR’S ENTIRE LIABILITY UNDER THESE TERMS AND CONDITIONS, SHALL BE A REFUND TO YOU OF THE FEES PAID TO REFR HEREUNDER, AND IN NO EVENT WILL REFR’S LIABILITY, FOR ANY REASON, EXCEED SUCH FEE.
                </p>
                <h3 className="heading">Section 13.  INDEMNITY.</h3>
                <p className="content">
                  We are not responsible for any claims of liability, damages, losses, costs, or expenses, including legal fees arising out of any breach of the Terms and Conditions or any other liabilities arising out of your use of this Site, or the use by any other person accessing this Site using your PC or internet access account.
                </p>
                <p className="content">
                  Your use of any information or materials on this Site is entirely at your own risk. It shall be your own responsibility to ensure that any products, services, or information available through this Site meets your specific requirements and or expectations.
                </p>
                <p className="content">
                  You hereby agree, at your own expense, to indemnify, defend, and hold REFR and our affiliates harmless against any and all liabilities and costs incurred in connection with any claim arising out of any breach of this agreement or claims arising from your use of this Site. This includes any loss or damage to any person or property from the use of any service from this Site caused by accident, negligence, or use of our products and services other than reasonable use.
                </p>
                <p className="content">
                  You shall cooperate with us in the defense of any claim. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you.
                </p>
                <p className="content">
                  <span className="inner-heading">13.1 Force Majeure.</span> We shall not be liable for failure or delay in any of its performance hereunder or a failure of this Site due to causes beyond our reasonable control. This includes, but is not limited to—an act of God, war, terrorism, natural disaster, governmental regulations, communication or utility failures or the failures or acts of third-parties.
                </p>
                <p className="content">
                  <span className="inner-heading">13.2 Guarantee.</span> Neither REFR nor any third-parties provide any warranty or guarantee as to the reliability, accuracy, availability, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
                </p>
                <p className="content">
                  <span className="inner-heading">13.3 No Warranties.</span> We make no warranties of any kind, whether express or implied, in relation to this Site, or products or services offered on this Site whether by us or on our behalf including but not limited to, implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security, accuracy, condition or completeness, or any implied warranty arising from course of dealing or usage or trade.
                </p>
                <h3 className="heading">Section 14. OUR RIGHTS.</h3>
                <p className="content">
                  <span className="inner-heading">14.1 Alteration of Site Content.</span>  We reserve the right to withdraw, temporarily or permanently, and to alter the content of this website with or without notice.
                </p>
                <p className="content">
                  <span className="inner-heading">14.2 Prohibited Unauthorized Use.</span> This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, and graphics. Reproduction is prohibited. Unauthorized use of this web site may give rise to a claim for damages and/or be a criminal offense.
                </p>
                <p className="content">
                  <span className="inner-heading">14.3 Alter Terms and Conditions.</span> REFR reserves the right to change these Terms and Conditions from time to time and your continued use of the Site (or any part of) following such change shall be deemed to be your acceptance of such change. It is your responsibility to check regularly to determine whether the Terms and Conditions have been changed. If you do not agree to any change to the Terms and Conditions then you must immediately stop using the Site.
                </p>
                <h3 className="heading">Section 15. MISCELLANEOUS. </h3>
                <p className="content">
                  <span className="inner-heading">15.1 Governing Law.</span>  Use of this Site and any actions arising out of, or relating to the use or subject matter of this Site, or these Terms and Conditions, shall be governed by the laws of the Commonwealth of Massachusetts without regard to the Commonwealth's rules concerning choice of law. If any material or services on this Site are contrary to the laws of the place where you are when you access it, then the Site is not intended for you, and we politely ask you to refrain from using the Site. You are solely responsible for knowing of, and complying with, the laws of the jurisdiction in which you are accessing the Site.
                </p>
                <p className="content">
                  <span className="inner-heading">15.2 Forum for Disputes.</span>  In the event that you desire to assert any claims arising out of the use of the Site or relating to the subject matter of these Terms and Conditions, you shall assert those claims in the state or federal courts located in Hampden County, Massachusetts, in which event any counterclaims to be asserted in response may also be asserted in that forum. We reserve the right, at our sole discretion, to commence and pursue proceedings in alternative jurisdictions.
                </p>
                <p className="content"><span className="inner-heading">15.3 Severance.</span>  In the event any provision of these Terms and Conditions is held to be invalid or unenforceable, the remainder these Terms and Conditions shall continue in full force and effect, and the provision held to be invalid or unenforceable shall be automatically amended to most closely approximate the original provision on terms that are valid and enforceable and the court or other adjudicating authority holding such provision invalid or unenforceable shall confirm the terms of such amendment accordingly.
                </p>
                <p className="content">
                  <span className="inner-heading
              ">15.4 Assignment.</span> REFR may assign these Terms and Conditions in our sole discretion. You may not assign these terms and conditions without our prior written consent.
                </p>
                <p className="content">
                  <span className="inner-heading
              ">15.5 Complete Agreement.</span> These Terms and Conditions, together with the Privacy Policy, Security Policy, Brokerage Participation Agreement, Cooperative Brokerage Referral Agreement, Master Referral Services Fee Agreement, Service Provider Agreement and the Order to Pay constitute the entire agreement between you and REFR relating to this Site and your use of this Site, and supersede any prior agreements or understandings not incorporated herein.
                </p>
              </div> */}
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default TermsUse;
