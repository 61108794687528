import React, { useState, useEffect } from 'react';
import { NavLink,useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Editor, InputText } from 'primereact';
import { Checkbox } from 'primereact';
import Button from 'react-bootstrap/Button';
import { Calendar } from "primereact/calendar";
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios,{ googleAPIKey } from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import Swal from 'sweetalert2';
import { InputMask } from 'primereact';
import { Dropdown } from 'primereact/dropdown';
import Autocomplete from "react-google-autocomplete";

const EditUser = (props) => {
    const [show, setShow] = useState(props.showEditUser);
    const navigate = useNavigate();
    const modalClose = () => setShow(false);
    const { user, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [uplineIsDisable, setUplineIsDisable] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [dbUplineUserData, setDbUplineUserData] = useState([]);
    const [isDisableBrokerOfRecord, setIsDisableBrokerOfRecord] = useState(false);
    let minDate = new Date();
    const [isSubmitCall, setIsSubmitCall] = useState(false);
    const [inputList, setInputList] = useState([
        {
            license_number: "",
            license_number_error: "",
            license_state: "",
            license_state_error: "",
        }
    ]);

    const selectedStateTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                   <div>{option.abbrv}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };
    const stateOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.abbrv}</div>
            </div>
        );
    };
    const formik = useFormik({
        initialValues: {
            user_id: props.editRowId,
            upline_users_id: "",
            is_new_upline_added: false,
            call_from: props.callFrom,
            first_name: "",
            last_name: "",
            email: "",
            type: props.editRowType,
            license_number: "",
            license_expiry_date: "",
            license_state_id: "",
            business_address: "",
            contact_number: "",
            brokerage_name: "",
            broker_name: "",
            broker_email: "",
            broker_phone: "",
            broker_license_json: '',
            brokerage_role_id: '',
            broker_of_record_detail: '',
            office_manager_detail: '',
            team_lead_detail: '',
            brokerage_role_other_detail: '',
            host_name: window.location.origin,
        },
        validate: (data) => {

            let errors = {};
            let isValid = true;
            if(props.callFrom === 'section1' || props.callFrom === 'listing')
            {
                if (data.first_name == null || data.first_name.trim() === '') {
                    errors.first_name = "First name is required.";
                }
                if (data.last_name == null || data.last_name.trim() === '') {
                    errors.last_name = "Last name is required.";
                }
                if (!data.email) {
                    errors.email = 'Email address is required.';
                }
                else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                    errors.email = 'Invalid email address. e.g. example@email.com';
                }
                if (data.type === 'ambassador' || data.type === 'agent') {
                    if(props.editRowId > 0)
                    {
                        if (!data.business_address) {
                            errors.business_address = "Home address is required.";
                        }
                    }
                }
                if (!data.contact_number) {
                    errors.contact_number = "Phone is required.";
                }
            }
            
            if(props.callFrom === 'section2' || props.callFrom === 'listing')
            {
                if (data.type === 'agent') {
                    if (data.license_number == null || data.license_number.trim() === '') {
                        errors.license_number = "License number is required.";
                    }
                    if (!data.license_expiry_date) {
                        errors.license_expiry_date = "License expiration date is required.";
                    }
                    if (!data.license_state_id) {
                        errors.license_state_id = "License state is required.";
                    }
                }
                if (data.type === 'ambassador') 
                {
                    if (data.brokerage_name == null || data.brokerage_name.trim() === '') {
                        errors.brokerage_name = 'Brokerage name is required.';
                    }
                    
                    if (data.broker_name == null || data.broker_name.trim() === '') {
                        errors.broker_name = 'Broker of record name is required.';
                    }
                    if (data.broker_email) 
                    {
                        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.broker_email)) 
                        {
                            errors.broker_email = 'Invalid email address. e.g. example@email.com';
                        }
                    }
                    
                    if(isSubmitCall)
                    {
                        if(inputList.length > 1)
                        {
                            inputList.map((x, i) => {

                                if (x.license_number == null || x.license_number.trim() === "")
                                {
                                    x.license_number_error = "Broker of Record License # is required.";
                                    isValid = false;
                                }
            
                                if (!x.license_state)
                                {
                                    x.license_state_error = "Broker of Record License State is required.";
                                    isValid = false;
                                }
                            });
                        }
                        else
                        {
                            inputList.map((x, i) => {

                                if (x.license_state)
                                {
                                    if (x.license_number == null || x.license_number.trim() === "")
                                    {
                                        x.license_number_error = "Broker of Record License # is required.";
                                        isValid = false;
                                    }
                                }
                                    
                                if (x.license_number)
                                {
                                    if (!x.license_state)
                                    {
                                        x.license_state_error = "Broker of Record License State is required.";
                                        isValid = false;
                                    }
                                }
                                if (!x.license_state && !x.license_number)
                                {
                                    x.license_state_error = "";
                                    x.license_number_error = "";
                                }
                            });
                        }
                    }
                    
                    if (!data.brokerage_role_id) {
                        errors.brokerage_role_id = 'Role with current brokerage is required.';
                    }
                    else {
                        if (data.brokerage_role_id.slug === 'broker_of_record') {
                            if(props.editRowId > 0)
                            {
                                if (data.broker_of_record_detail == null || data.broker_of_record_detail.trim() === '') {
                                    errors.broker_of_record_detail = 'How many agents in your brokerage is required.';
                                }
                            }
                        }
    
                        if (data.brokerage_role_id.slug === 'office_manager') {
                            if(props.editRowId > 0)
                            {
                                if (data.office_manager_detail == null || data.office_manager_detail.trim() === '') {
                                    errors.office_manager_detail = 'How many agents in your office is required.';
                                }
                            }
                        }
    
                        if (data.brokerage_role_id.slug === 'team_lead') {
                            if(props.editRowId > 0)
                            {
                                if (data.team_lead_detail == null || data.team_lead_detail.trim() === '') {
                                    errors.team_lead_detail = 'How many agents on your team is required.';
                                }
                            }
                        }
    
                        if (data.brokerage_role_id.slug === 'other') {
                            if (data.brokerage_role_other_detail == null || data.brokerage_role_other_detail.trim() === '') {
                                errors.brokerage_role_other_detail = 'Other role is required.';
                            }
                        }
                    }
                }
            }
            if (isValid) {
                return errors;
            } else {
                errors.multi_fields = "error";
                return errors;
            }
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });

    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name+' ('+Capitalize(option.type)+')'}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const optionTemplate = (option) => {
        return (
            <div className="country-item user-name-list">
                <p className='full-name-user'>{option.name+' ('+Capitalize(option.type)+')'}</p>
                <p className='email-option'>{option.email}</p>
            </div>
        );
    };

    const selectedTemplateBrokerage = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const optionTemplateBrokerage = (option) => {
        return (
            <div className="country-item user-name-list">
                <p className='full-name-user'>{option.name}</p>
            </div>
        );
    };
    const handleUplineUser = (e) => {
        if(e.target.value)
        {
            formik.setFieldValue("is_new_upline_added", true);
        }
    }

   const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    useEffect(() => {
        const fetchUplineUserData = async () => {
            let formData = new FormData();
            formData.append('user_id', props.editRowId);

            try {
                const response = await axios.post('/get-all-user', formData);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = '';
                setLoading(false);
                if (Status === false) {
                    if (Error.length > 0) {
                        Error.map(
                            (errorMessage) =>
                                //finalApiError += '<li>'+errorMessage+'</li>'
                                (finalApiError = errorMessage)
                        );
                    } else {
                        finalApiError = StatusMessage;
                    }
                    setErrorMessage(finalApiError);
                    window.scrollTo(0, 0);
                } else {
                    setLoading(false);
                    setDbUplineUserData(Result);
                }
            } catch (err) {
                setLoading(false);
                window.scrollTo(0, 0);

                if (err.response.status && err.response.status == 401) {
                    logout();
                    navigate('/login');
                } else {
                    setErrorMessage("Something went wrong");

                }

            }
        }
        fetchUplineUserData();

        const fetchEditData = async () => {
            let formData = new FormData();
            formData.append('user_id', props.editRowId);
            formData.append('mode', props.editRowType);

            try {
                const response = await axios.post('/get-user-data', formData);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = '';
                setLoading(false);
                if (Status === false) {
                    if (Error.length > 0) {
                        Error.map(
                            (errorMessage) =>
                                //finalApiError += '<li>'+errorMessage+'</li>'
                                (finalApiError = errorMessage)
                        );
                    } else {
                        finalApiError = StatusMessage;
                    }
                    setErrorMessage(finalApiError);
                    window.scrollTo(0, 0);
                } else {
                    setLoading(false);
                    formik.setFieldValue("first_name", Result.user.first_name);
                    formik.setFieldValue("last_name", Result.user.last_name);
                    formik.setFieldValue("email", Result.user.email);
                    formik.setFieldValue("contact_number", Result.user.contact_number);
                    formik.setFieldValue("business_address", Result.user.business_address);
                    if (Result.user.license_number)
                        formik.setFieldValue("license_number", Result.user.license_number);

                    if (Result.user.upline_users_id)
                    {
                        formik.setFieldValue("upline_users_id", Result.user.upline_users_id);
                        setUplineIsDisable(true);
                    }  

                    if (Result.user.license_expiry_date)
                        formik.setFieldValue("license_expiry_date", new Date(Result.user.license_expiry_date));

                    if (Result.user.license_state)
                        formik.setFieldValue("license_state_id", Result.user.license_state);

                    if (Result.user.brokerage_name)
                        formik.setFieldValue("brokerage_name", Result.user.brokerage_name);

                    if (Result.user.broker_name)
                        formik.setFieldValue("broker_name", Result.user.broker_name);

                    if (Result.user.broker_email)
                        formik.setFieldValue("broker_email", Result.user.broker_email);

                    if (Result.user.broker_phone)
                        formik.setFieldValue("broker_phone", Result.user.broker_phone);

                    if (Result.user.brokerage_role_id)
                    {
                        formik.setFieldValue("brokerage_role_id", Result.user.brokerage_role);
                        if(Result.user.brokerage_role.slug === 'broker_of_record')
                        {
                            setIsDisableBrokerOfRecord(true);
                        }
                    }
                        

                    if (Result.user.broker_of_record_detail)
                        formik.setFieldValue("broker_of_record_detail", Result.user.broker_of_record_detail);

                    if (Result.user.office_manager_detail)
                        formik.setFieldValue("office_manager_detail", Result.user.office_manager_detail);

                    if (Result.user.team_lead_detail)
                        formik.setFieldValue("team_lead_detail", Result.user.team_lead_detail);

                    if (Result.user.brokerage_role_other_detail)
                        formik.setFieldValue("brokerage_role_other_detail", Result.user.brokerage_role_other_detail);

                    if(Result.user.broker_license_json)
                    {
                        setInputList(JSON.parse(Result.user.broker_license_json));
                        formik.setFieldValue("broker_license_json", JSON.parse(Result.user.broker_license_json));
                    }
                }
            } catch (err) {
                setLoading(false);
                window.scrollTo(0, 0);


                if (err.response.status && err.response.status == 401) {
                    logout();
                    navigate('/login');
                } else {
                    setErrorMessage("Something went wrong");

                }

            }
        }
        //alert(props.editRowId)
        if (props.editRowId > 0) {
            fetchEditData();
        }
    }, []);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        
        if (name === "license_number") {
            list[index]["license_number_error"] = "";
        } else if (name === "license_state") {
            list[index]["license_state_error"] = "";
        }
        setInputList(list);
        formik.setFieldValue("broker_license_json", list);
    };
    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        formik.setFieldValue("broker_license_json", list);
    };

    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                license_number: "",
                license_number_error: "",
                license_state: "",
                license_state_error: "",
            },
        ]);
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleHomeAddress = (e) => {
        if(!e.target.value)
        {
            formik.setFieldValue("business_address", '');
        }
    }
    const handleGoogleAddress = (place) => {
    //     console.log(' inside if ')
    //     console.log(' place '+ JSON.stringify(place))
    //    // console.log(' place.formatted_address '+ place.formatted_address)

        formik.setFieldValue("business_address", place.formatted_address);
    }
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        setErrorMessage('');
        let formData = '';
        try {
            let addUpdateUrl = '';
            if (props.editRowId > 0) {
                addUpdateUrl = '/update-profile';
                formData = new FormData();
                formData.append('data', JSON.stringify(data))
            }
            else {
                formData = data;
                addUpdateUrl = '/add-user';
            }
            const response = await axios.post(addUpdateUrl, formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {

                setLoading(false);
                let message = 'Information added successfully';
                if (props.editRowId > 0) {
                    message = 'Information updated successfully';
                }
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: message,
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        modalClose();
                        props.changeStatusEditUser();
                        props.reloadListing();
                    }
                });
            }
        } catch (err) {

            setLoading(false);
            window.scrollTo(0, 0);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                setErrorMessage("Something went wrong");
            }
        }
    };
    const handleChangeUserDataFirstName = (e) => {
        if(formik.values.brokerage_role_id.slug === 'broker_of_record')
        {
            if(formik.values.last_name)
            {
                formik.setFieldValue("broker_name", e.target.value+' '+formik.values.last_name)
            }
            else
            {
                formik.setFieldValue("broker_name", e.target.value)
            }
        }
    }
    const handleChangeUserDataLastName = (e) => {
        if(formik.values.brokerage_role_id.slug === 'broker_of_record')
        {
            if(formik.values.first_name)
            {
                formik.setFieldValue("broker_name", formik.values.first_name+' '+e.target.value)
            }
            else
            {
                formik.setFieldValue("broker_name", e.target.value)
            }
        }
    }
    const handleChangeUserDataEmail = (e) => {
        if(formik.values.brokerage_role_id.slug === 'broker_of_record')
        {
            formik.setFieldValue("broker_email", e.target.value)
        }
    }
    const handleChangeUserDataPhone = (e) => {
        if(formik.values.brokerage_role_id.slug === 'broker_of_record')
        {
            formik.setFieldValue("broker_phone", e.target.value)
        }
    }
    const handleBrokerageRole = (e) => {
        setIsDisableBrokerOfRecord(false);
        formik.setFieldValue("broker_name", "")
        formik.setFieldValue("broker_email", "")
        formik.setFieldValue("broker_phone", "")
        let list = [...inputList];
       
        if(list.length > 1)
        {
            list = list.slice(0,1);
            setInputList(list);
        }
        if(e.target.value.slug === 'broker_of_record')
        {
            setIsDisableBrokerOfRecord(true);
            let first_name = formik.values.first_name;
            let last_name = formik.values.last_name;
            if((first_name) && (last_name))
            {
                formik.setFieldValue("broker_name", first_name+' '+last_name)
            }
            else if(first_name)
            {
                formik.setFieldValue("broker_name", first_name)
            }
            else if(last_name)
            {
                formik.setFieldValue("broker_name", last_name)
            }
            
            if(formik.values.email)
            {
                formik.setFieldValue("broker_email", formik.values.email)
            }
            
            if(formik.values.contact_number)
            {
                formik.setFieldValue("broker_phone", formik.values.contact_number)
            }
        }
        formik.setFieldValue("broker_of_record_detail", '')
        formik.setFieldValue("office_manager_detail", '')
        formik.setFieldValue("team_lead_detail", '')
        formik.setFieldValue("brokerage_role_other_detail", '')
    }
    
    const searchUplineUser = async(event) => {
        let formData = new FormData();
        formData.append('user_id', props.editRowId);

        try {
            const response = await axios.post('/get-all-user', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {
                setLoading(false);
                setDbUplineUserData(Result);
            }
        } catch (err) {
            setLoading(false);
            window.scrollTo(0, 0);

            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                setErrorMessage("Something went wrong");

            }

        }
        
        // setTimeout(() => {
        //     let _filteredCountries;
        //     if (!event.query.trim().length) {
        //         _filteredCountries = [...countries];
        //     }
        //     else {
        //         _filteredCountries = countries.filter((country) => {
        //             return country.name.toLowerCase().startsWith(event.query.toLowerCase());
        //         });
        //     }

        //     setFilteredCountries(_filteredCountries);
        // }, 250);
    }

    return (
        <>
            <Modal enforceFocus={false} className='add-people-popup' show={show} onHide={modalClose} backdrop="static" >
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header className='add-people-header'>
                        <Modal.Title>{(props.editRowId > 0) ? 'Update' : 'Add'} User</Modal.Title>
                    </Modal.Header>
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                            {errorMessage}
                        </Alert>
                    )}
                    <Modal.Body className='addpeople-section'>
                        {
                            ((props.callFrom === 'section1') || (props.callFrom === 'listing')) && (<div className="row gx-3 form-section">
                            <div className="field col-md-6 profile-input" >
                                <label htmlFor="inputEmail4" className="form-label">First Name <span className="reuired-star">*</span></label>
                                <InputText placeholder="Enter Here"
                                    autoComplete="off"
                                    name="first_name"
                                    value={formik.values.first_name} onChange={(e) =>{formik.handleChange(e);handleChangeUserDataFirstName(e)} }
                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('first_name') })}
                                />
                                {getFormErrorMessage('first_name')}
                            </div>
                            <div className="field col-md-6 profile-input right">
                                <label htmlFor="inputPassword4" className="form-label">Last Name <span className="reuired-star">*</span></label>
                                <InputText
                                    autoComplete="off"
                                    placeholder="Enter Here" name="last_name"
                                    value={formik.values.last_name} onChange={(e) =>{formik.handleChange(e);handleChangeUserDataLastName(e)} }
                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('last_name') })}
                                />
                                {getFormErrorMessage('last_name')}
                            </div>
                            <div className="field col-md-6 profile-input">
                                <label htmlFor="inputEmail4" className="form-label">Email Address <span className="reuired-star">*</span></label>
                                <InputText
                                    autoComplete="off"
                                    placeholder="Enter Here" name="email"
                                    value={formik.values.email} onChange={(e) =>{formik.handleChange(e);handleChangeUserDataEmail(e)} }
                                    disabled={(props.editRowId > 0) ? true : false}
                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('email') })}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                            <div className="field col-md-6 profile-input right">
                                <label htmlFor="inputPassword4" className="form-label">Phone <span className="reuired-star">*</span></label>
                                <InputMask
                                    autoComplete="off"
                                    mask="(999) 999-9999" placeholder="(999) 999-9999" name="contact_number"
                                    value={formik.values.contact_number} onChange={(e) =>{formik.handleChange(e);handleChangeUserDataPhone(e)} }
                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('contact_number') })}
                                />
                                {getFormErrorMessage('contact_number')}
                            </div>
                            <div className="field col-12 street-input">
                                <label htmlFor="inputAddress" className="form-label">Home Address 
                                {
                                    (props.editRowId > 0) ? <span className="reuired-star">*</span> : ''
                                }                               
                        </label>
                                <Autocomplete
                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('business_address') })}
                                    apiKey={googleAPIKey}
                                    onPlaceSelected={(place) => {
                                        handleGoogleAddress(place);
                                    }}
                                    options={{
                                        types: ["geocode", "establishment"],
                                        componentRestrictions: { country: "us" },
                                    }}
                                    onChange={(e) => {handleHomeAddress(e)}}
                                    defaultValue={formik.values.business_address}
                                />
                                {getFormErrorMessage('business_address')}
                            </div>
                            <div className="field signup-label col-lg-12 signup-form-field">
                                <label htmlFor="">Upline User </label>
                                

                                <Dropdown
                                    className={classNames("form-select mw-100", { 'p-invalid': isFormFieldValid('upline_users_id') })}
                                    value={
                                        formik.values
                                            .upline_users_id
                                    }
                                    options={dbUplineUserData}
                                    onChange={(e)=> {handleUplineUser(e);formik.handleChange(e)}}
                                    optionLabel="name"
                                    name="upline_users_id"
                                    placeholder="Select one"
                                    valueTemplate={selectedTemplate}
                                    itemTemplate={optionTemplate}
                                    disabled={uplineIsDisable}
                                    filter
                                    filterBy="name,email"
                                />
                                {getFormErrorMessage(
                                    "upline_users_id"
                                )}
                            </div>
                        </div>) 
                        }
                        

                            {
                                ((formik.values.type === 'agent') && ((props.callFrom === 'section2') || (props.callFrom === 'listing'))) && (
                                    <div className="row gx-3 form-section">
                                        <div className="col-lg-6">
                                            <div className="field">
                                                <label
                                                    htmlFor="LicenseInput"
                                                    className="form-label"
                                                >
                                                    License
                                                    Number <span className="reuired-star">*</span>
                                                </label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder="Enter here"
                                                    name="license_number"
                                                    value={
                                                        formik
                                                            .values
                                                            .license_number
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    className={classNames(
                                                        "form-control",
                                                        {
                                                            "p-invalid":
                                                                isFormFieldValid(
                                                                    "license_number"
                                                                ),
                                                        }
                                                    )}
                                                />
                                                {getFormErrorMessage(
                                                    "license_number"
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="field licence-date">
                                                <label
                                                    htmlFor="LicenseInput"
                                                    className="form-label"
                                                >
                                                    License Expiration Date <span className="reuired-star">*</span>
                                                </label>
                                                <Calendar
                                                    minDate={minDate}
                                                    showIcon
                                                    dateFormat="mm/dd/yy"
                                                    placeholder="MM/DD/YY"
                                                    name="license_expiry_date"
                                                    value={
                                                        formik
                                                            .values
                                                            .license_expiry_date
                                                    }
                                                    viewDate={
                                                        formik.values.license_expiry_date
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    inputClassName={classNames(
                                                        "form-control license-date",
                                                        {
                                                            "p-invalid":
                                                                isFormFieldValid(
                                                                    "license_expiry_date"
                                                                ),
                                                        }
                                                    )}
                                                />
                                                {getFormErrorMessage(
                                                    "license_expiry_date"
                                                )}
                                            </div>
                                        </div>

                                    </div>)
                            }
                            {
                                ((formik.values.type === 'ambassador') && ((props.callFrom === 'section2') || (props.callFrom === 'listing'))) && (
                                    <div className="row gx-3 form-section">
                                        <div className="col-lg-12">
                                            <div className="field">
                                                <label htmlFor="baddress">Role with current brokerage <span className="reuired-star">*</span> </label>
                                                <br />
                                                <Dropdown
                                                    className={classNames("form-select mw-100", { 'p-invalid': isFormFieldValid('brokerage_role_id') })}
                                                    value={formik.values.brokerage_role_id} options={props.dbBrokerageRoleData}
                                                    onChange={(e) => { formik.handleChange(e); handleBrokerageRole(e) }} optionLabel="name" name="brokerage_role_id" placeholder="Select a Role"
                                                    filter
                                                    filterBy="name"
                                                    valueTemplate={selectedTemplateBrokerage}
                                                    itemTemplate={optionTemplateBrokerage}
                                                />
                                                {
                                                    (!formik.values.brokerage_role_id) && (getFormErrorMessage('brokerage_role_id'))

                                                }

                                            </div>
                                        </div>
                                        {
                                            (formik.values.brokerage_role_id.slug === 'broker_of_record') && (<div className="field signup-label col-lg-12 signup-form-field">
                                                <label htmlFor="">How many agents in your brokerage {
                                                    (props.editRowId > 0) ? <span className="reuired-star">*</span> : ''
                                                } </label>
                                                <InputText
                                                    autoComplete="off"
                                                    name="broker_of_record_detail"
                                                    value={formik.values.broker_of_record_detail} onChange={formik.handleChange}
                                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('broker_of_record_detail') })} placeholder="Enter here" />
                                                {getFormErrorMessage('broker_of_record_detail')}
                                            </div>)
                                        }
                                        {
                                            (formik.values.brokerage_role_id.slug === 'office_manager') && (
                                                <div className="field signup-label col-lg-12 signup-form-field">
                                                    <label htmlFor="">How many agents in your office {
                                    (props.editRowId > 0) ? <span className="reuired-star">*</span> : ''
                                } </label>
                                                    <InputText
                                                        autoComplete="off"
                                                        name="office_manager_detail"
                                                        value={formik.values.office_manager_detail} onChange={formik.handleChange}
                                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('office_manager_detail') })} placeholder="Enter here" />
                                                    {getFormErrorMessage('office_manager_detail')}
                                                </div>)
                                        }
                                        {
                                            (formik.values.brokerage_role_id.slug === 'team_lead') && (
                                                <div className="field signup-label col-lg-12 signup-form-field">
                                                    <label htmlFor="">How many agents on your team {
                                    (props.editRowId > 0) ? <span className="reuired-star">*</span> : ''
                                } </label>
                                                    <InputText
                                                        autoComplete="off"
                                                        name="team_lead_detail"
                                                        value={formik.values.team_lead_detail} onChange={formik.handleChange}
                                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('team_lead_detail') })} placeholder="Enter here" />
                                                    {getFormErrorMessage('team_lead_detail')}
                                                </div>
                                            )}
                                        {
                                            (formik.values.brokerage_role_id.slug === 'other') && (
                                                <div className="field signup-label col-lg-12 signup-form-field">
                                                    <label htmlFor="">Other Role <span className="reuired-star">*</span></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        name="brokerage_role_other_detail"
                                                        value={formik.values.brokerage_role_other_detail} onChange={formik.handleChange}
                                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('brokerage_role_other_detail') })} placeholder="Enter here" />
                                                    {getFormErrorMessage('brokerage_role_other_detail')}
                                                </div>
                                            )}
                                    </div>)
                            }
                            {
                                ((formik.values.type === 'ambassador') && ((props.callFrom === 'section2') || (props.callFrom === 'listing'))) && (

                                    <div className="row gx-3 form-section">
                                        <div className="col-lg-6">
                                            <div className="field">
                                                <label
                                                    htmlFor="LicenseInput"
                                                    className="form-label"
                                                >
                                                    Brokerage Name <span className="reuired-star">*</span>
                                                </label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder="Enter here"
                                                    name="brokerage_name"
                                                    value={
                                                        formik
                                                            .values
                                                            .brokerage_name
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    className={classNames(
                                                        "form-control",
                                                        {
                                                            "p-invalid":
                                                                isFormFieldValid(
                                                                    "brokerage_name"
                                                                ),
                                                        }
                                                    )}
                                                />
                                                {getFormErrorMessage(
                                                    "brokerage_name"
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="field licence-date">
                                                <label
                                                    htmlFor="LicenseInput"
                                                    className="form-label"
                                                >
                                                    Broker of Record Name <span className="reuired-star">*</span>
                                                </label>
                                                <InputText
                                                //disabled={isDisableBrokerOfRecord}
                                                    autoComplete="off"
                                                    placeholder="Enter here"
                                                    name="broker_name"
                                                    value={
                                                        formik
                                                            .values
                                                            .broker_name
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    className={classNames(
                                                        "form-control",
                                                        {
                                                            "p-invalid":
                                                                isFormFieldValid(
                                                                    "broker_name"
                                                                ),
                                                        }
                                                    )}
                                                />
                                                {getFormErrorMessage(
                                                    "broker_name"
                                                )}
                                            </div>
                                        </div>
                                    </div>)
                            }
                            {
                                ((formik.values.type === 'ambassador') && ((props.callFrom === 'section2') || (props.callFrom === 'listing')))  && (
                                    <div className="row gx-3 form-section">
                                        <div className="col-lg-6">
                                            <div className="field">
                                                <label
                                                    htmlFor="LicenseInput"
                                                    className="form-label"
                                                >
                                                    Broker of Record Email 
                                                </label>
                                                <InputText
                                                //disabled={isDisableBrokerOfRecord}
                                                    autoComplete="off"
                                                    placeholder="Enter here"
                                                    name="broker_email"
                                                    value={
                                                        formik
                                                            .values
                                                            .broker_email
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    className={classNames(
                                                        "form-control",
                                                        {
                                                            "p-invalid":
                                                                isFormFieldValid(
                                                                    "broker_email"
                                                                ),
                                                        }
                                                    )}
                                                />
                                                {getFormErrorMessage(
                                                    "broker_email"
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="field licence-date">
                                                <label
                                                    htmlFor="LicenseInput"
                                                    className="form-label"
                                                >
                                                    Broker of Record Phone 
                                                </label>
                                                <InputMask
                                                //disabled={isDisableBrokerOfRecord}
                                                    autoComplete="off"
                                                    mask="(999) 999-9999" placeholder="(999) 999-9999"
                                                    name="broker_phone"
                                                    value={
                                                        formik.values.broker_phone
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    className={classNames(
                                                        "form-control",
                                                        {
                                                            "p-invalid":
                                                                isFormFieldValid(
                                                                    "broker_phone"
                                                                ),
                                                        }
                                                    )}
                                                />
                                                {getFormErrorMessage(
                                                    "broker_phone"
                                                )}
                                            </div>
                                        </div>
                                        
                                    </div>)
                            }
                            {(formik.values.type === 'ambassador')  && ((props.callFrom === 'section2') || (props.callFrom === 'listing'))  &&(
                                <div className="row">
                                    {inputList.map((x, i) => {
                                    return (
                                        <div key={i} className="repeaterClass">
                                            <div className="row gx-3 form-section">
                                                <div className="col-lg-6">
                                                    <div className="field">
                                                        <label
                                                            htmlFor="FirstInput"
                                                            className="form-label"
                                                        >
                                                            Broker of Record License # 
                                                        </label>
                                                        <InputText
                                                            autoComplete="off"
                                                            className={classNames("form-control",
                                                                {"p-invalid":isFormFieldValid("license_number"),
                                                                }
                                                            )}
                                                            placeholder="Enter here"
                                                            name="license_number"
                                                            value={x.license_number}
                                                            onChange={(e) => handleInputChange(e,i)
                                                            }
                                                        />
                                                        {(!x.license_number) && (x.license_number_error)  && (
                                                            <small className="p-error">
                                                                {
                                                                    x.license_number_error
                                                                }
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="field">
                                                        <label
                                                            htmlFor="LastInput"
                                                            className="form-label"
                                                        >
                                                            Broker of Record License State 
                                                        </label>
                                                        <Dropdown
                                                            className={classNames("form-select mw-100", { 'p-invalid': isFormFieldValid('license_state') })}
                                                            value={x.license_state}
                                                            options={props.dbStatesName} 
                                                            onChange={(e) =>
                                                                handleInputChange(e,i)
                                                            } optionLabel="state" 
                                                            name="license_state" filter filterBy="abbrv"
                                                            placeholder="Select a State"
                                                            valueTemplate={selectedStateTemplate} 
                                                            itemTemplate={stateOptionTemplate} />
                                                       
                                                        
                                                       {(!x.license_state) && (x.license_state_error) && (
                                                            <small className="p-error">
                                                                {
                                                                   x.license_state_error
                                                                }
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                (formik.values.brokerage_role_id.slug === 'broker_of_record') && (<div className="btn-box mb-3">
                                                {inputList.length !== 1 && (
                                                    <Button
                                                        className="add-more remove-link me-2"
                                                        onClick={() =>
                                                            handleRemoveClick(i)
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                )}
                                                {inputList.length - 1 === i && (
                                                    <Button
                                                        onClick={handleAddClick}
                                                        className="add-more"
                                                    >
                                                    Add More +
                                                    </Button>
                                                )}
                                            </div>)
                                            }
                                        </div>
                                    );
                                })}
                                </div>
                            )}

                            {
                                ((formik.values.type === 'agent') && ((props.callFrom === 'section2') || (props.callFrom === 'listing')))  && (
                                    <div className="row gx-3 form-section">
                                        <div className="col-lg-6">
                                            <div className="field">
                                                <label
                                                    htmlFor="LicenseInput"
                                                    className="form-label"
                                                >
                                                    License
                                                    State <span className="reuired-star">*</span>
                                                </label>
                                                <Dropdown
                                                    name="license_state_id"
                                                    value={
                                                        formik.values.license_state_id
                                                    }
                                                    options={
                                                        props.dbStatesName
                                                    }
                                                    // className={classNames(
                                                    //     {
                                                    //         "p-invalid":
                                                    //             isFormFieldValid(
                                                    //                 "license_state_id"
                                                    //             ),
                                                    //     }
                                                    // )}
                                                    className="form-select mw-100"
                                                    optionLabel="state"
                                                    filter
                                                    filterBy="abbrv"
                                                    placeholder="Select a State"
                                                    valueTemplate={
                                                        selectedStateTemplate
                                                    }
                                                    itemTemplate={
                                                        stateOptionTemplate
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    //disabled={true}
                                                />
                                                {getFormErrorMessage(
                                                    "license_state_id"
                                                )}
                                            </div>
                                        </div>

                                    </div>)
                            }
                        
                    </Modal.Body>
                    <Modal.Footer className='add-people-footer'>
                        <button type="button" className='btn btn-cancel' variant="secondary" onClick={() => { modalClose(); props.changeStatusEditUser(); }} disabled={loading}>
                            Close
                        </button>
                        <button type="submit" className='btn btn-submit btn-submit-loader btn-500' onClick={() => {setIsSubmitCall(true)}} variant="primary" disabled={loading}>
                            {(props.editRowId > 0) ? 'Update' : 'Add'}
                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                        </button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default EditUser;

