import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { InputText, RadioButton } from 'primereact';
import Button from 'react-bootstrap/Button';
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import { Calendar } from 'primereact/calendar';
import Swal from 'sweetalert2';

const ReferralPaidStatusForm = (props) => {

    const { user, logout } = useAuth();
    const [show, setShow] = useState(props.showReferralPaidStatusForm);
    const navigate = useNavigate();
    const closeModal = () => setShow(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitCall, setIsSubmitCall] = useState(false);
    let minDate = new Date();
    const [inputList, setInputList] = useState(props.referralPaidJsonDB);
    const formik = useFormik({
        initialValues: {
            referral_id: props.referralRowId,
            referral_paid_json: props.referralPaidJsonDB,
            host_name: window.location.origin,
        },
        validate: (data) => {
            let errors = {};
            let isValid = true;
            
            if(isSubmitCall)
            {
                inputList.map((x, i) => {

                    if (!x.cheque_date) {
                        x.cheque_date_error = 'Date is required.';
                        isValid = false;
                    }
                    
                    if (x.name == null || x.name.trim() === '') {
                        x.name_error = 'Name is required.';
                        isValid = false;
                    }
                    if (x.cheque_number == null || x.cheque_number.trim() === '') {
                        x.cheque_number_error = 'Check # is required.';
                        isValid = false;
                    }
                    if (x.amount == null || x.amount === '') {
                        x.amount_error = 'Amount is required.';
                        isValid = false;
                    }
                    else
                    {
                        if (!isPositiveFloat(x.amount)) {
                            x.amount_error = "Invalid amount. e.g. 10000";
                            isValid = false;
                        }
                        else if(parseFloat(x.amount) > 100000000)
                        {
                            x.amount_error = "Max: 100,000,000";
                            isValid = false;
                        }
                    }
                    
                });
            }
            if (isValid) {
                return errors;
            } else {
                errors.multi_fields = "error";
                return errors;
            }
        },
        onSubmit: (data) => {
            setErrorMessage('');
            handleSubmit(data);
        }
    });
    const isPositiveFloat = (s) => {
        return !isNaN(s) && Number(s) > 0;
    }
    useEffect(() => {
        if (user !== null) {
            
            formik.setFieldValue("users_id", user.id ? user.id : "");
        }
    }, [user]);
    
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;

        if (name === "name") {
            list[index]["name_error"] = "";
        } else if (name === "cheque_date") {
            list[index]["cheque_date_error"] = "";
        } else if (name === "amount") {
            list[index]["amount_error"] = "";
        } else if (name === "cheque_number") {
            list[index]["cheque_number_error"] = "";
        }
        setInputList(list);
        formik.setFieldValue("referral_paid_json", list);
    };
    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        //alert(index);
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
        //alert('list '+JSON.stringify(list));
        formik.setFieldValue("referral_paid_json", list);
    };

    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                name: "",
                name_error: "",
                cheque_date: "",
                cheque_date_error: "",
                amount: "",
                amount_error: "",
                cheque_number: "",
                cheque_number_error: "",
            },
        ]);
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
   
    const handleSubmit = async (data) => {
        //alert('inisde submit')
        setLoading(true);
        try {
            const response = await axios.post('/referral-paid-status-update', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {
                setLoading(false);
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        closeModal();
                    props.changeStatuReferralPaidForm();
                    }
                });
            }
        } catch (err) {

            setLoading(false);
            window.scrollTo(0, 0);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {

                setErrorMessage("Something went wrong");
            }
        }
    };
    
    
    return (
        <>
            {/* <Button variant="primary" onClick={inviteShow}>
                Invite Modal
            </Button> */}
            <Modal enforceFocus={false} className='invite-user-popup' show={show} onHide={closeModal} data-keyboard="false" backdrop="static">

                <Modal.Header className='invite-user-modal'>
                    <Modal.Title>Referral Paid Information</Modal.Title>
                </Modal.Header>
                {errorMessage && (
                    <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                        {errorMessage}
                    </Alert>
                )}
                <form onSubmit={formik.handleSubmit} >
                    <Modal.Body className='invite-user-section'>
                        <div className="referral-fields step-3">
                            
                            <div className="invite-user-body">
                                {inputList.map((x, i) => {
                                    if(x.cheque_date)
                                    {
                                        x.cheque_date = new Date(x.cheque_date);
                                    }
                                    return (
                                        <div key={i} className="invite-user-form">
                                            <div className="row">
                                                <div className="field form-field signup-label col-lg-12 signup-form-field">
                                                    <label htmlFor="fname">{(props.referralCreatedUserType === 'agent') ? 'Agent' : 'Brokerage'} Name <span className="reuired-star">*</span></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder="Enter here"
                                                        name="name"
                                                        value={x.name} onChange={(e) => handleInputChange(e, i)}
                                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('name') })}
                                                    />
                                                    {(!x.name) && (x.name_error) && (
                                                        <small className="p-error">
                                                            {
                                                                x.name_error
                                                            }
                                                        </small>
                                                    )}
                                                </div>
                                                <div className="field form-field signup-label col-lg-6 signup-form-field">
                                                    <label htmlFor="lname">Amount <span className="reuired-star">*</span></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder="Enter here"
                                                        name="amount"
                                                        value={x.amount} onChange={(e) => handleInputChange(e, i)}
                                                        className={classNames("form-control", { 'p-invalid': isFormFieldValid('amount') })}
                                                    />
                                                    {(x.amount_error) && (
                                                        <small className="p-error">
                                                            {
                                                                x.amount_error
                                                            }
                                                        </small>
                                                    )}
                                                </div>
                                                <div className="field form-field signup-label col-lg-6 signup-form-field">
                                                    <label htmlFor="lname">Date <span className="reuired-star">*</span></label>
                                                    <Calendar
                                                        dateFormat="mm/dd/yy"
                                                        placeholder="MM/DD/YY"
                                                        //minDate={minDate}
                                                        name="cheque_date"
                                                        value={x.cheque_date}   onChange={(e) => handleInputChange(e, i)}
                                                        InputClassName={classNames("form-control", { 'p-invalid': isFormFieldValid('cheque_date') })}
                                                        showIcon />
                                                    {(x.cheque_date_error) && (
                                                        <small className="p-error">
                                                            {
                                                                x.cheque_date_error
                                                            }
                                                        </small>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="field mb-3">
                                                <label htmlFor="recipient-name">Check # <span className="reuired-star">*</span></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder="Enter here"
                                                    name="cheque_number"
                                                    value={x.cheque_number} onChange={(e) => handleInputChange(e, i)}
                                                    className={classNames("form-control", { 'p-invalid': isFormFieldValid('cheque_number') })}
                                                />
                                                { (x.cheque_number_error) && (
                                                    <small className="p-error">
                                                        {
                                                            x.cheque_number_error
                                                        }
                                                    </small>
                                                )}
                                            </div>
                                            {/* Client said no need of multiple{
                                                (<div className="btn-box mb-3 d-none">
                                                    {inputList.length !== 1 && (
                                                        <Button 
                                                            className="add-more remove-link me-2"
                                                            onClick={() =>
                                                                handleRemoveClick(i)
                                                            }
                                                        >
                                                            Remove
                                                        </Button>
                                                    )}
                                                    {inputList.length - 1 === i && (i < 10) && (
                                                        <Button
                                                            onClick={handleAddClick}
                                                            className="add-more"
                                                        >
                                                            Add More +
                                                        </Button>
                                                    )}
                                                </div>)
                                            } */}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='invite-user-footer'>
                        <Button className='cancel-btn' variant="secondary" onClick={() => { closeModal(); props.changeStatuReferralPaidForm() }}>
                            Cancel
                        </Button>
                        <Button type="submit" className='btn btn-submit btn-500 btn-submit-loader' onClick={() => {setIsSubmitCall(true)}} variant="primary" disabled={loading}>
                        Save

                            {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                        </Button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ReferralPaidStatusForm;
