import React from 'react';
import './App.css';
import { useRoutes } from 'react-router-dom';
import routes from './router';
import { AuthProvider } from './contexts/JWTAuthContext';
import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';

function App() {
 
  let userData = localStorage.getItem('user_data')
  const [cookie, setCookie] = useCookies(['']);
  
  let isLoggedIn = true;

  if(userData === null)
    isLoggedIn = false;

  if(userData === undefined)
    isLoggedIn = false;

    
    if(isLoggedIn)
    {
      setCookie('valueName','logged_in', { path: '/' });
    }
    
    const content = useRoutes(routes(isLoggedIn));
    return (
      <div> 
          <CookiesProvider><AuthProvider>{content}</AuthProvider></CookiesProvider>
       </div>  
    );
}

export default App;
