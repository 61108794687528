import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Step, StepLabel, Stepper } from "@mui/material";
import { useStep } from "react-hooks-helper";
import { Dropdown, RadioButton } from "primereact";
import { Card } from "react-bootstrap";
import Button from "@mui/material/Button";
import { Avatar, Typography, CircularProgress, Alert } from "@mui/material";
import axios, { baseUrl } from "../../axiosCall.js";
import useAuth from "../../hooks/useAuth";
import Swal from 'sweetalert2';
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import AssignedAgent from "./AssignedAgent";
import SendContractToAgent from "./SendContractToAgent";
import UnderContractStatusForm from "./UnderContractStatusForm";
import ClosedStatusForm from "./ClosedStatusForm";
import ReferralPaidStatusForm from "./ReferralPaidStatusForm";
import ClientCancelledStatusForm from "./ClientCancelledStatusForm";
import AdminNotesForm from "./AdminNotesForm";
import ReferralFeeReceivedForm from "./ReferralFeeReceivedForm";
import PotentialCommissionEditForm from "./PotentialCommissionEditForm";

import { Message } from 'primereact/message';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ClientReferred from "./UpdateForm/ClientReferred";
import AssignedAgentModal from "./UpdateForm/AssignedAgentModal";
import ReferralData from "./UpdateForm/ReferralData";
import { Number, Currency } from "react-intl-number-format"
import UploadDocuments from "./../MyNetwork/UploadDocuments";
let CryptoJS = require("crypto-js")

const stepsSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }

    ]
};
const steps = [
    { id: "ReferralDetail" },
];
const getAdminBuyerSteps = () => {
    return [
        "pending_agent_selection",
        "agent_join_process",
        "pending_agent_signature",
        "pending_referral_assignment",
        "new",
        "attempted_contact",
        "spoke_with_customer",
        "appointment_set",
        "met_with_customer",
        "showing_homes",
        "submitting_offers",
        "under_contract",
        "closed",
        "no_longer_working_with_client",
        "client_cancelled",
        "previous_two_way_communication",
        "pending_agent_payment",
        "pending_ambassador_payment",
        "referral_paid"
    ];
};

const getAdminSellerSteps = () => {
    return [
        "pending_agent_selection",
        "agent_join_process",
        "pending_agent_signature",
        "pending_referral_assignment",
        "new",
        "attempted_contact",
        "spoke_with_customer",
        "appointment_set",
        "met_with_customer",
        "listing_agreement_signed",
        "active_listing",
        "under_contract",
        "closed",
        "client_cancelled",
        "no_longer_working_with_client",
        "previous_two_way_communication",
        "pending_agent_payment",
        "pending_ambassador_payment",
        "referral_paid"
    ];
};

const getBuyerSteps = () => {
    return [
        "pending_agent_selection",
        "agent_join_process",
        "pending_agent_signature",
        "pending_referral_assignment",
        "new",
        "attempted_contact",
        "spoke_with_customer",
        "appointment_set",
        "met_with_customer",
        "showing_homes",
        "submitting_offers",
        "under_contract",
        "closed",
        "client_cancelled",
        "no_longer_working_with_client",
        "previous_two_way_communication",
        "referral_paid"
    ];
};

const getSellerSteps = () => {
    return [
        "pending_agent_selection",
        "agent_join_process",
        "pending_agent_signature",
        "pending_referral_assignment",
        "new",
        "attempted_contact",
        "spoke_with_customer",
        "appointment_set",
        "met_with_customer",
        "listing_agreement_signed",
        "active_listing",
        "under_contract",
        "closed",
        "client_cancelled",
        "no_longer_working_with_client",
        "previous_two_way_communication",
        "referral_paid"
    ];
};
let CryptoJSAesJson = {
    stringify: function (cipherParams) {
        var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
        if (cipherParams.iv) j.iv = cipherParams.iv.toString();
        if (cipherParams.salt) j.s = cipherParams.salt.toString();
        return JSON.stringify(j);
    },
    parse: function (jsonStr) {
        var j = JSON.parse(jsonStr);
        var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
        if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
        if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
        return cipherParams;
    }
}
export const ReferralDetailsFinal = () => {

    const secretKey = "REFR2022@GALAXY";
    let requestReferralId = '';
    const navigate = useNavigate();
    const [showClientReferred, setShowClientReferred] = useState(false);
    const [showAssignedAgentModal, setShowAssignedAgentModal] = useState(false);
    const [showReferralData, setShowReferralData] = useState(false);
    const { user, logout } = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingForAssignRefr, setLoadingForAssignRefr] = useState(false);
    const [clientShortName, setClientShortName] = useState('N/A');
    const [agentShortName, setAgentShortName] = useState('N/A');
    const [referringAgentShortName, setReferringAgentShortName] = useState('N/A');
    const [referralCreatedUserName, setReferralCreatedUserName] = useState('');
    const [referralCreatedUserType, setReferralCreatedUserType] = useState('');

    const [showUploadDocs, setShowUploadDocs] = useState(false);
    const [referenceNumber, setReferenceNumber] = useState('');
    const [statusRadioButton, setStatusRadioButton] = useState('');
    const [editRowId, setEditRowId] = useState(0);

    const [propertyBuyerAreaJson, setPropertyBuyerAreaJson] = useState([]);
    const [propertyBuyerAreaErrorJson, setPropertyBuyerAreaErrorJson] = useState([]);

    const [clientCancelledReasonDB, setClientCancelledReasonDB] = useState('');
    const [clientCancelledDateDB, setClientCancelleDateDB] = useState('');
    const [clientCancelledByDB, setClientCancelledByDB] = useState('');

    const [noLongerWorkingWithClientReasonDB, setNoLongerWorkingWithClientReasonDB] = useState('');
    const [noLongerWorkingWithClientDateDB, setNoLongerWorkingWithClientDateDB] = useState('');
    const [noLongerWorkingWithClientByDB, setNoLongerWorkingWithClientByDB] = useState('');

    const search = useLocation().search;

    const [showAssignedAgent, setShowAssignedAgent] = useState(false);
    const [selectAgentCallFrom, setSelectAgentCallFrom] = useState('');
    const [showSendContract, setShowSendContract] = useState(false);
    const [showUnderContractStatusForm, setShowUnderContractStatusForm] = useState(false);
    const [showPotentialDataEditForm, setShowPotentialDataEditForm] = useState(false);

    const [isUnderContractEditCall, setIsUnderContractEditCall] = useState(false);
    const [showClosedStatusForm, setShowClosedStatusForm] = useState(false);
    const [showReferralPaidStatusForm, setShowReferralPaidStatusForm] = useState(false);

    const [showAdminNotesForm, setShowAdminNotesForm] = useState(false);
    const [showReferralFeeReceivedForm, setShowReferralFeeReceivedForm] = useState(false);
    const [referralFeeReceivedDB, setReferralFeeReceivedDB] = useState('');

    const [showClientCancelledStatusForm, setShowClientCancelledStatusForm] = useState(false);
    const [callFrom, setCallFrom] = useState(false);
    const [isPercentageAdded, setIsPercentageAdded] = useState(true);

    const [agentContract, setAgentContract] = useState('');
    const [agentContractName, setAgentContractName] = useState('');
    const [referralPaidJsonDB, setReferralPaidJson] = useState([
        {
            name: "",
            name_error: "",
            cheque_date: "",
            cheque_date_error: "",
            amount: "",
            amount_error: "",
            cheque_number: "",
            cheque_number_error: "",
        }
    ]);

    const [adminNotesJsonDB, setAdminNotesJsonDB] = useState([
        {
            notes: "",
            notes_error: "",
            notes_updated_date: new Date(new Date().toLocaleString('en', { timeZone: 'America/New_York' })),
        }
    ]);

    const [referralStatusList, setReferralStatusList] = useState([]);
    const [referralRowId, setReferralRowId] = useState('');
    const [currentActiveStep, setCurrentActiveStep] = useState(0);
    const [agentSelectedStatus, setAgentSelectedStatus] = useState('');
    const [validMessage, setValidMessage] = useState('');
    const [stepsLabel, setStepsLabel] = useState('');
    const [referralCommissionLogDetail, setReferralCommissionLogDetail] = useState([]);
    const [referralDocumentsLogDetail, setReferralDocumentsLogDetail] = useState([]);
    const [referraDetail, setReferralDetail] = useState([]);
    const [referraSettingDetail, setReferralSettingDetail] = useState([]);

    const [isClosedStage, setIsClosedStage] = useState(false);

    const [referralUsersId, setReferralUsersId] = useState('');
    const [referredAgentUsersId, setReferredAgentUsersId] = useState('');
    const [dbPeopleData, setDbPeopleData] = useState([]);
    const [dbClientReferenceData, setDbClientReferenceData] = useState([]);
    const { step, navigation } = useStep({ initialStep: 0, steps });
    const props = { navigation };
    const { id } = step;
    const sliderRef = useRef();

    const referralId = new URLSearchParams(search).get('u');
    const referralIdFromEmail = new URLSearchParams(search).get('r');

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    if (referralId === null && referralIdFromEmail === null) {
        navigate('/dashboard');
    }
    else if (referralId) {
        let referralIdDecrypt = AES.decrypt(referralId, secretKey);

        if (referralIdDecrypt == '') {
            navigate('/dashboard');
        }
        else
            requestReferralId = JSON.parse(referralIdDecrypt.toString(Utf8));
    }
    else if (referralIdFromEmail) {
        var encrypted = decodeURIComponent(referralIdFromEmail);
        if (!isJsonString(encrypted)) {
            navigate('/dashboard');
        }
        else {
            let json_obj = JSON.parse(encrypted);
            if (("ct" in json_obj) === false && ("iv" in json_obj) === false && ("s" in json_obj) === false) {
                navigate('/dashboard');
            }
            else {

                let referralIdDecrypt = CryptoJS.AES.decrypt(encrypted, secretKey, { format: CryptoJSAesJson });

                if (referralIdDecrypt == '') {
                    navigate('/dashboard');
                }
                else
                    requestReferralId = JSON.parse(referralIdDecrypt.toString(CryptoJS.enc.Utf8));

            }
        }
    }
    else {
        navigate('/dashboard');
    }
    const [state, setState] = useState(
        {
            users_id: '',
            referral_type: 'buyer_referral',
            referral_status: '',
            agent_first_name: '',
            agent_last_name: '',
            agent_id: '',
            agent_email: '',
            agent_phone: '',
            agent_type: '',
            agent_brokerage_name: '',
            agent_broker_name: '',
            agent_broker_license_json: '',
            assigned_agent_date_time: '',
            is_assigned: '',
            client_first_name: "",
            client_last_name: "",
            client_email: "",
            client_phone: "",
            property_type: "",
            seller_property_type: "",
            buyer_time_frame_name: "",
            seller_estimated_price: "",
            seller_time_frame_name: "",
            buyer_comments: "",
            seller_comments: "",
            seller_full_address: "",
            buyer_full_address: "",
            buyer_price_range_min: "",
            buyer_price_range_max: "",
            seller_price_range: "",
            potential_sales_price: "",
            potential_commission_earned: "",
            potential_commission: "",
            potential_referral_fee: "",
            potential_referral_fee_percentage: "",

            referral_fee_user_receive: "",
            referral_platform_fee: "",
            referral_fee_charged_to_receiving_agent: "",
            referral_fee_due_to_referring_agent: "",
            referring_agent_referral_fee_action: "",
            is_buyer_referral_upline_agent: "",
            is_agent_assign_to_referral: "",
            agent_plan_commission: '',
            refr_plan_commission: '',
            potential_user_earning: '',
            potential_refr_earning: '',

            sales_price: "",
            closing_date: "",
            property_address: '',
            pending_commission: "",
            referral_fee: "",
            referral_fee_percentage: '',
            refr_plan_commission_percentage: '',
            agent_plan_commission_percentage: "",
            pending_refr_plan_commission: "",
            pending_agent_plan_commission: '',
            commission: "",
            final_sales_price: "",
            final_closing_date: "",
            final_commission_earned: "",
            final_referral_fee_due: "",
            final_commission: "",
            clients_looking_to_purcahse: "",
            referral_paid_json: "",
            admin_notes_json: "",
            referral_created_users_type: "",
            referral_created_email: "",
            referral_created_phone: "",
            messageForDocuments: "",
            agentMessageForDocuments: "",
            client_reference_master_id: "",

            other_client_reference: "",
            host_name: window.location.origin,

            brokerage_list_id: '',
            affinity_group_preference_id:'',
            agent_language_master_id: '',
            language_other: "",
            agent_assign_other_requirement: "",
            other_interested_client: '',
            referral_fee_received: '',
            agent_plan_at_time_of_submission: '',
            agent_due: '',
            referring_agent_brokerage_name: '',
            referring_agent_broker_name: '',

            referring_agent_broker_name_db: '',
            agent_broker_name_db: '',

        }
    );

    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div style={{ textTransform: 'capitalize' }}>{option.name.replace(/_/g, ' ')}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const optionTemplate = (option) => {
        return (
            <div className="country-item">
                <div className="agent-field"><RadioButton name="agent_status" value={option.id} onChange={(e) => { setStatusRadioButton(e.target.value) }} checked={statusRadioButton === option.id} />
                    <label htmlFor="" style={{ textTransform: 'capitalize' }}>{option.name.replace(/_/g, ' ')}</label>
                </div>
            </div>
        );
    };

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const fetchClientReferenceData = async () => {
        try {
            const response = await axios.post("/get-all-client-reference-data");
            const { Status, StatusMessage, Error, Result } =
                response.data;

            if (Status === true) {
                //alert(Result)
                setDbClientReferenceData(Result);
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };
    useEffect(() => {
        if (user !== null) {


            fetchClientReferenceData();
        }
    }, [user]);
    const fetchPeopleData = async (referralUserId) => {
        let formData = new FormData();
        formData.append('users_id', referralUserId);
        try {
            const response = await axios.post("/get-all-people", formData);
            const { Status, StatusMessage, Error, Result } =
                response.data;

            if (Status === true) {
                //alert(Result)
                setDbPeopleData(Result);

            }
        } catch (err) {

        }
    };
    const fetchReferralDetailData = async () => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('user_id', user.id);
        formData.append('referral_id', requestReferralId);
        formData.append('page_call_from', 'referral_detail');
        setReferralRowId(requestReferralId);
        try {
            const response = await axios.post("/get-referral-data", formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                let isChecked = '';
                let index = 0;
                if (Result.referral.referral_type === 'buyer_referral') {
                    if (user.type === 'admin') {
                        setStepsLabel(getAdminBuyerSteps());
                        index = getAdminBuyerSteps().indexOf(Result.referral.referral_status, 0)
                    }
                    else {
                        setStepsLabel(getBuyerSteps())
                        index = getBuyerSteps().indexOf(Result.referral.referral_status, 0)
                    }
                }
                else if (Result.referral.referral_type === 'seller_referral') {
                    if (user.type === 'admin') {
                        setStepsLabel(getAdminSellerSteps())
                        index = getAdminSellerSteps().indexOf(Result.referral.referral_status, 0)
                    }
                    else {
                        setStepsLabel(getSellerSteps())
                        index = getSellerSteps().indexOf(Result.referral.referral_status, 0)
                    }
                }
                else {
                    if (Result.referral.buyer_or_seller == 'seller_referral') {
                        if (user.type === 'admin') {
                            setStepsLabel(getAdminSellerSteps())
                            index = getAdminSellerSteps().indexOf(Result.referral.referral_status, 0)
                        }
                        else {
                            setStepsLabel(getSellerSteps())
                            index = getSellerSteps().indexOf(Result.referral.referral_status, 0)
                        }
                    }
                    else {
                        if (user.type === 'admin') {
                            setStepsLabel(getAdminBuyerSteps())
                            index = getAdminBuyerSteps().indexOf(Result.referral.referral_status, 0)
                        }
                        else {
                            setStepsLabel(getBuyerSteps())
                            index = getBuyerSteps().indexOf(Result.referral.referral_status, 0)
                        }
                    }
                }
                let statusJson = { id: Result.referral.status, name: Result.referral.referral_status }
                //alert(statusJson)
                setAgentSelectedStatus(statusJson)

                setStatusRadioButton(Result.referral.status)

                setReferralCommissionLogDetail(Result.referral_commission_log);
                setReferralDocumentsLogDetail(Result.referral_documents);
                setReferralDetail(Result.referral)
                setReferralSettingDetail(Result.referral_setting)

                //alert(index)
                if (index < 0) {
                    setCurrentActiveStep(0);
                    sliderRef.current.slickGoTo(0);
                }
                else {
                    setCurrentActiveStep(index);
                    if (index === 0)
                        sliderRef.current.slickGoTo(0);
                    else
                        sliderRef.current.slickGoTo(index - 1);
                }

                setReferralCreatedUserName(Result.referral.referral_created_users_name)
                setReferenceNumber(Result.referral.reference_number)
                setAgentContract(Result.referral.agent_contract_media);

                if (Result.referral.agent_contract_media) {
                    let splitName = Result.referral.agent_contract_media.split('/')
                    setAgentContractName(splitName[splitName.length - 1]);
                }
                setReferralCreatedUserType(Result.referral.referral_created_users_type)



                if (Result.referral.referral_type === "buyer_and_seller_referral") {
                    if (Result.referral.buyer_or_seller == 'seller_referral') {
                        Result.referral.referral_type = 'seller_referral';
                    }
                    else {
                        Result.referral.referral_type = 'buyer_referral';
                    }
                }
                setReferralUsersId(Result.referral.users_id);
                setReferredAgentUsersId(Result.referral.agent_id)
                let closingDate = '';
                let salesPrice = '';
                let commissionPercentage = '';
                let referralFeePercentage = '';
                let commissionEarned = '';
                let referralDue = '';
                let finalUserCreatedAmount = '';
                let finalRefrCommissionAmount = '';
                let userPlanCommissionPercentage = '';
                let refrPlanCommissionPercentage = '';

                let propertyAddress = '';
                if (Result.referral_commission_log.length > 0) {
                    if (Result.referral_commission_log[0].property_address)
                        propertyAddress = Result.referral_commission_log[0].property_address;
                    else if (Result.referral.referral_type === 'seller_referral')
                        propertyAddress = (Result.referral.seller_full_address) ? Result.referral.seller_full_address : 'N/A';
                    if (Result.referral_commission_log[0].final_sales_price) {
                        setIsClosedStage(true);
                        if (Result.referral_commission_log[0].final_closing_date) {
                            let objectDate1 = new Date(Result.referral_commission_log[0].final_closing_date);
                            let day1 = objectDate1.getDate();
                            let month1 = objectDate1.getMonth() + 1;
                            let year1 = objectDate1.getFullYear();

                            if (month1 < 10) {
                                month1 = `0${month1}`;
                            }
                            // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
                            // "July", "Aug", "Sept", "Oct", "Nov", "Dec"
                            // ];
                            // month1 = monthNames[objectDate1.getMonth()];
                            closingDate = month1 + '/' + day1 + '/' + year1;
                        }
                        if (Result.referral_commission_log[0].final_sales_price)
                            salesPrice = parseFloat(Result.referral_commission_log[0].final_sales_price).toFixed(2);

                        if (Result.referral_commission_log[0].final_referral_fee_due)
                            referralDue = parseFloat(Result.referral_commission_log[0].final_referral_fee_due).toFixed(2);

                        if (Result.referral_commission_log[0].final_commission)
                            commissionPercentage = parseFloat(Result.referral_commission_log[0].final_commission).toFixed(2);

                        if (Result.referral_commission_log[0].closed_referral_fee_percentage)
                            referralFeePercentage = parseFloat(Result.referral_commission_log[0].closed_referral_fee_percentage).toFixed(2);

                        if (Result.referral_commission_log[0].final_commission_earned)
                            commissionEarned = parseFloat(Result.referral_commission_log[0].final_commission_earned).toFixed(2);

                        if (Result.referral_commission_log[0].final_agent_plan_commission)
                            userPlanCommissionPercentage = parseFloat(Result.referral_commission_log[0].final_agent_plan_commission).toFixed(2);

                        if (Result.referral_commission_log[0].final_refr_plan_commission >= 0 && Result.referral_commission_log[0].final_refr_plan_commission != null)
                            refrPlanCommissionPercentage = parseFloat(Result.referral_commission_log[0].final_refr_plan_commission).toFixed(2);

                        if (Result.referral_commission_log[0].final_refr_gross_commission_income >= 0 && Result.referral_commission_log[0].final_refr_gross_commission_income != null)
                            finalRefrCommissionAmount = parseFloat(Result.referral_commission_log[0].final_refr_gross_commission_income).toFixed(2);

                        if (Result.referral_commission_log[0].ambassador_final_commission)
                            finalUserCreatedAmount = parseFloat(Result.referral_commission_log[0].ambassador_final_commission).toFixed(2);
                        else if (Result.referral_commission_log[0].agent_final_commission)
                            finalUserCreatedAmount = parseFloat(Result.referral_commission_log[0].agent_final_commission).toFixed(2);
                    }
                    else if (Result.referral_commission_log[0].sales_price) {
                        setIsClosedStage(false);
                        if (Result.referral_commission_log[0].closing_date) {
                            let objectDate = new Date(Result.referral_commission_log[0].closing_date);
                            let day = objectDate.getDate();
                            let month = objectDate.getMonth() + 1;
                            let year = objectDate.getFullYear();
                            if (month < 10) {
                                month = `0${month}`;
                            }
                            // const monthNames1 = ["Jan", "Feb", "Mar", "Apr", "May", "June",
                            // "July", "Aug", "Sept", "Oct", "Nov", "Dec"
                            // ];
                            // month = monthNames1[objectDate.getMonth()];
                            closingDate = month + '/' + day + '/' + year;
                        }
                        if (Result.referral_commission_log[0].sales_price)
                            salesPrice = parseFloat(Result.referral_commission_log[0].sales_price).toFixed(2);

                        if (Result.referral_commission_log[0].referral_fee)
                            referralDue = parseFloat(Result.referral_commission_log[0].referral_fee).toFixed(2);

                        if (Result.referral_commission_log[0].commission)
                            commissionPercentage = parseFloat(Result.referral_commission_log[0].commission).toFixed(2);

                        if (Result.referral_commission_log[0].pending_commission)
                            commissionEarned = parseFloat(Result.referral_commission_log[0].pending_commission).toFixed(2);

                        if (Result.referral_commission_log[0].pending_referral_fee_percentage)
                            referralFeePercentage = parseFloat(Result.referral_commission_log[0].pending_referral_fee_percentage).toFixed(2);

                        if (Result.referral_commission_log[0].agent_plan_commission)
                            userPlanCommissionPercentage = parseFloat(Result.referral_commission_log[0].agent_plan_commission).toFixed(2);

                        if (Result.referral_commission_log[0].refr_plan_commission >= 0 && Result.referral_commission_log[0].refr_plan_commission != null)
                            refrPlanCommissionPercentage = parseFloat(Result.referral_commission_log[0].refr_plan_commission).toFixed(2);

                        //alert(Result.referral_commission_log[0].refr_gross_commission_income)
                        if (Result.referral_commission_log[0].refr_gross_commission_income >= 0 && Result.referral_commission_log[0].refr_gross_commission_income != null)
                            finalRefrCommissionAmount = parseFloat(Result.referral_commission_log[0].refr_gross_commission_income).toFixed(2);

                        if (Result.referral_commission_log[0].ambassador_pending_commission)
                            finalUserCreatedAmount = parseFloat(Result.referral_commission_log[0].ambassador_pending_commission).toFixed(2);
                        else if (Result.referral_commission_log[0].agent_pending_commission)
                            finalUserCreatedAmount = parseFloat(Result.referral_commission_log[0].agent_pending_commission).toFixed(2);


                    }
                }



                if (Result.referral.admin_notes_json) {
                    let jsonData = JSON.parse(Result.referral.admin_notes_json);
                    jsonData = jsonData.map((obj, i) => {
                        return { ...obj, notes_updated_date: new Date(new Date().toLocaleString('en', { timeZone: 'America/New_York' })) };
                    });
                    setAdminNotesJsonDB(jsonData);

                }

                let buyerPriceRangeMin = 'N/A';
                let buyerPriceRangeMax = 'N/A';
                let sellerPriceRange = '';
                if (Result.referral.referral_type === 'seller_referral') {
                    if (Result.referral.seller_estimated_price)
                        sellerPriceRange = parseFloat(Result.referral.seller_estimated_price).toFixed(2);

                }
                else {
                    if (Result.referral.buyer_min_price_range_id) {
                        let temp = Result.referral.buyer_min_price_range_id.name.split('$');
                        buyerPriceRangeMin = temp[1];
                    }

                    if (Result.referral.buyer_max_price_range_id) {
                        let temp1 = Result.referral.buyer_max_price_range_id.name.split('$');
                        buyerPriceRangeMax = temp1[1];
                    }
                }
                if (Result.referral.client_cancelled_reason) {
                    setClientCancelledReasonDB(Result.referral.client_cancelled_reason)
                    setClientCancelleDateDB(Result.referral.client_cancelled_date)
                    setClientCancelledByDB(Result.referral.client_cancelled_by_user_name)
                }
                if (Result.referral.no_longer_working_with_client_reason) {
                    setNoLongerWorkingWithClientReasonDB(Result.referral.no_longer_working_with_client_reason)
                    setNoLongerWorkingWithClientDateDB(Result.referral.no_longer_working_with_client_date)
                    setNoLongerWorkingWithClientByDB(Result.referral.no_longer_working_with_client_by_user_name)
                }
                let messageForDocuments = '';

                if (Result.referral.referral_created_users_type === 'agent') {
                    if ((!Result.referral.upload_personal_media) && (!Result.referral.independent_contractor_agreement_media)) {
                        messageForDocuments = 'Referring Side Missing Docs: Personal W-9 & ICA document not available';
                    }
                    else if (!Result.referral.upload_personal_media) {
                        messageForDocuments = 'Referring Side Missing Docs: Personal W-9 document not available';
                    }
                    else if (!Result.referral.independent_contractor_agreement_media) {
                        messageForDocuments = 'Referring Side Missing Docs: ICA document not available';
                    }
                }

                if (Result.referral.referral_created_users_type === 'ambassador') {
                    if ((!Result.referral.upload_personal_media) && (!Result.referral.master_referral_services_fee_media) && (!Result.referral.brokerage_participation_agreement_media)) {
                        messageForDocuments = 'Referring Side Missing Docs: Brokerage W-9, BPA & MRSFA document not available';
                    }
                    else if ((!Result.referral.upload_personal_media) && (!Result.referral.master_referral_services_fee_media)) {
                        messageForDocuments = 'Referring Side Missing Docs: Brokerage W-9 & MRSFA document not available';
                    }
                    else if ((!Result.referral.upload_personal_media) && (!Result.referral.brokerage_participation_agreement_media)) {
                        messageForDocuments = 'Referring Side Missing Docs: Brokerage W-9 & BPA document not available';
                    }
                    else if ((!Result.referral.master_referral_services_fee_media) && (!Result.referral.brokerage_participation_agreement_media)) {
                        messageForDocuments = 'Referring Side Missing Docs: BPA & MRSFA document not available';
                    }
                    else if ((!Result.referral.upload_personal_media)) {
                        messageForDocuments = 'Referring Side Missing Docs: Brokerage W-9 document not available';
                    }
                    else if ((!Result.referral.master_referral_services_fee_media)) {
                        messageForDocuments = 'Referring Side Missing Docs: MRSFA document not available';
                    }
                    else if ((!Result.referral.brokerage_participation_agreement_media)) {
                        messageForDocuments = 'Referring Side Missing Docs: BPA document not available';
                    }
                }

                let agentMessageForDocuments = '';

                if ((Result.referral.agent_type === 'agent') && (Result.referral.agent_id)) {
                    if ((!Result.referral.agent_upload_personal_media) && (!Result.referral.agent_independent_contractor_agreement_media)) {
                        agentMessageForDocuments = 'Receiving Side Missing Docs: Personal W-9 & ICA document not available';
                    }
                    else if (!Result.referral.agent_upload_personal_media) {
                        agentMessageForDocuments = 'Receiving Side Missing Docs: Personal W-9 document not available';
                    }
                    else if (!Result.referral.agent_independent_contractor_agreement_media) {
                        agentMessageForDocuments = 'Receiving Side Missing Docs: ICA document not available';
                    }
                }

                if ((Result.referral.agent_type === 'ambassador') && (Result.referral.agent_id)) {
                    if ((!Result.referral.agent_upload_personal_media) && (!Result.referral.agent_master_referral_services_fee_media) && (!Result.referral.agent_brokerage_participation_agreement_media)) {
                        agentMessageForDocuments = 'Receiving Side Missing Docs: Brokerage W-9, BPA & MRSFA document not available';
                    }
                    else if ((!Result.referral.agent_upload_personal_media) && (!Result.referral.agent_master_referral_services_fee_media)) {
                        agentMessageForDocuments = 'Receiving Side Missing Docs: Brokerage W-9 & MRSFA document not available';
                    }
                    else if ((!Result.referral.agent_upload_personal_media) && (!Result.referral.agent_brokerage_participation_agreement_media)) {
                        agentMessageForDocuments = 'Receiving Side Missing Docs: Brokerage W-9 & BPA document not available';
                    }
                    else if ((!Result.referral.agent_master_referral_services_fee_media) && (!Result.referral.agent_brokerage_participation_agreement_media)) {
                        agentMessageForDocuments = 'Receiving Side Missing Docs: BPA & MRSFA document not available';
                    }
                    else if ((!Result.referral.agent_upload_personal_media)) {
                        agentMessageForDocuments = 'Receiving Side Missing Docs: Brokerage W-9 document not available';
                    }
                    else if ((!Result.referral.agent_master_referral_services_fee_media)) {
                        agentMessageForDocuments = 'Receiving Side Missing Docs: MRSFA document not available';
                    }
                    else if ((!Result.referral.agent_brokerage_participation_agreement_media)) {
                        agentMessageForDocuments = 'Receiving Side Missing Docs: BPA document not available';
                    }
                }
                //alert(JSON.stringify(Result.referral.client_reference_master_id))
                let agentDue = '';
                if (Result.referral.referral_created_users_type === 'agent') {
                    if ((Result.referral.referral_fee_received) && (Result.referral.agent_plan_commission)) {
                        if(Result.referral_commission_log[0].fee_received_user_commission_due)
                            agentDue = parseFloat(Result.referral_commission_log[0].fee_received_user_commission_due);
                        else
                            agentDue = parseFloat(Result.referral.referral_fee_received) * (parseFloat(Result.referral.agent_plan_commission) / 100);

                            
                    } else if ((referralDue) && (Result.referral.agent_plan_commission)) {
                        if (finalUserCreatedAmount)
                            agentDue = parseFloat(finalUserCreatedAmount);
                        else
                            agentDue = parseFloat(referralDue) * (parseFloat(Result.referral.agent_plan_commission) / 100);
                    }
                } else {
                    if ((Result.referral.referral_fee_received)) {

                        //alert(Result.referral_commission_log[0].fee_received_user_commission_due)
                        if(Result.referral_commission_log[0].fee_received_user_commission_due)
                        {
                            agentDue = parseFloat(Result.referral_commission_log[0].fee_received_user_commission_due);
                        } 
                        else
                            agentDue = parseFloat(Result.referral.referral_fee_received) * (parseFloat(30) / 35);
                    } else if ((referralDue)) {
                        if (finalUserCreatedAmount)
                            agentDue = parseFloat(finalUserCreatedAmount);
                        else
                            agentDue = parseFloat(referralDue) * (parseFloat(30) / 35);
                    }
                }
                if (Result.referral.referral_paid_json) {
                    setReferralPaidJson(JSON.parse(Result.referral.referral_paid_json));
                }
                else {
                    if (agentDue)
                        agentDue = parseFloat(agentDue).toFixed(2);

                    setReferralPaidJson([
                        {
                            name: Result.referral.referral_created_users_type === 'agent' ? Result.referral.referral_created_users_name : Result.referral.referring_agent_brokerage_name,
                            name_error: "",
                            cheque_date: "",
                            cheque_date_error: "",
                            amount: agentDue,
                            amount_error: "",
                            cheque_number: "",
                            cheque_number_error: "",
                        }
                    ]);
                }

                let isBuyerReferralUplineAgent = "yes";
                let isAgentAssignToReferral = "no";

                if (Result.referral.agent_type == "upline_user") {
                    isBuyerReferralUplineAgent = "yes";

                } else {
                    isBuyerReferralUplineAgent = "no";

                    if (Result.referral.agent_id) {
                        isAgentAssignToReferral = "yes";

                    } else {
                        isAgentAssignToReferral = "no";
                    }
                    //alert(is_agent_assign_to_referral)
                }
                if(Result.referral.referral_fee_user_receive) {
                    if(Result.referral.referring_agent_referral_fee_action === 'yes')
                    {
                        setIsPercentageAdded(false);
                    }
                }
                

                //alert(finalRefrCommissionAmount)
                setState((prevState) => {
                    return {
                        ...prevState,
                        users_id: (Result.referral.users_id) ? Result.referral.users_id : '',
                        referral_status: (Result.referral.referral_status) ? Result.referral.referral_status : '',
                        referral_type: (Result.referral.referral_type) ? Result.referral.referral_type : '-',
                        agent_first_name: (Result.referral.agent_first_name) ? Result.referral.agent_first_name : '',
                        agent_id: (Result.referral.agent_id) ? Result.referral.agent_id : '',
                        agent_last_name: (Result.referral.agent_last_name) ? Result.referral.agent_last_name : '',
                        agent_email: (Result.referral.agent_email) ? Result.referral.agent_email : '',
                        agent_phone: (Result.referral.agent_phone) ? Result.referral.agent_phone : '',

                        agent_type: (Result.referral.agent_type) ? Result.referral.agent_type : '',
                        agent_brokerage_name: (Result.referral.agent_brokerage_name) ? Result.referral.agent_brokerage_name : '',
                        agent_broker_name: (Result.referral.agent_broker_name) ? Result.referral.agent_broker_name : '',
                        agent_broker_license_json: (Result.referral.agent_broker_license_json) ? JSON.parse(Result.referral.agent_broker_license_json) : [],
                        assigned_agent_date_time: (Result.referral.assigned_agent_date_time) ? Result.referral.assigned_agent_date_time : '',
                        is_assigned: (Result.referral.is_assigned) ? Result.referral.is_assigned : '',
                        client_first_name: (Result.referral.client_first_name) ? Result.referral.client_first_name : '',
                        client_last_name: (Result.referral.client_last_name) ? Result.referral.client_last_name : '',
                        client_email: (Result.referral.client_email) ? Result.referral.client_email : '',
                        client_phone: (Result.referral.client_phone) ? Result.referral.client_phone : '',
                        property_type: (Result.referral.property_type) ? Result.referral.property_type : '',
                        seller_property_type: (Result.referral.seller_property_type) ? Result.referral.seller_property_type : '',
                        seller_estimated_price: (Result.referral.seller_estimated_price) ? Result.referral.seller_estimated_price : '',
                        buyer_time_frame_name: (Result.referral.buyer_time_frame_name) ? Result.referral.buyer_time_frame_name : '',
                        seller_time_frame_name: (Result.referral.seller_time_frame_name) ? Result.referral.seller_time_frame_name : '',
                        client_reference_master_id: (Result.referral.client_reference_master_id) ? Result.referral.client_reference_master_id.name : '',
                        other_client_reference: (Result.referral.other_client_reference) ? Result.referral.other_client_reference : '',
                        clients_looking_to_purcahse: (Result.referral.clients_looking_to_purcahse_master_id) ? Result.referral.clients_looking_to_purcahse_master_id.name : '',
                        buyer_comments: (Result.referral.buyer_comments) ? Result.referral.buyer_comments : '',
                        seller_comments: (Result.referral.seller_comments) ? Result.referral.seller_comments : '',
                        seller_full_address: (Result.referral.seller_full_address) ? Result.referral.seller_full_address : '',
                        buyer_full_address: (Result.referral.buyer_full_address) ? Result.referral.buyer_full_address : '',
                        buyer_price_range_min: buyerPriceRangeMin,
                        buyer_price_range_max: buyerPriceRangeMax,
                        seller_price_range: sellerPriceRange,
                        sales_price: salesPrice,
                        closing_date: closingDate,
                        property_address: propertyAddress,
                        pending_commission: commissionEarned,
                        referral_fee: referralDue,
                        commission: commissionPercentage,
                        referral_fee_percentage: referralFeePercentage,

                        refr_plan_commission_percentage: refrPlanCommissionPercentage,
                        agent_plan_commission_percentage: userPlanCommissionPercentage,
                        pending_refr_plan_commission: finalRefrCommissionAmount,
                        pending_agent_plan_commission: finalUserCreatedAmount,

                        potential_sales_price: (Result.referral.average_sales_value) ? parseFloat(Result.referral.average_sales_value).toFixed(2) : '',
                        potential_commission_earned: (Result.referral.total_potential_commission) ? parseFloat(Result.referral.total_potential_commission).toFixed(2) : '',
                        potential_commission: (Result.referral_setting[0].potential_commission) ? parseFloat(Result.referral_setting[0].potential_commission).toFixed(2) : '',
                        potential_referral_fee: (Result.referral.potential_referral_fee) ? parseFloat(Result.referral.potential_referral_fee).toFixed(2) : '',
                        potential_referral_fee_percentage: (Result.referral.potential_referral_fee_percentage) ? parseFloat(Result.referral.potential_referral_fee_percentage).toFixed(2) : '',

                        referral_fee_user_receive: (Result.referral.referral_fee_user_receive) ? parseFloat(Result.referral.referral_fee_user_receive).toFixed(2) : '',
                        referral_platform_fee: (Result.referral.referral_platform_fee) ? parseFloat(Result.referral.referral_platform_fee).toFixed(2) : '',
                        referral_fee_charged_to_receiving_agent: (Result.referral.referral_fee_charged_to_receiving_agent) ? Result.referral.referral_fee_charged_to_receiving_agent : '',
                        referral_fee_due_to_referring_agent: (Result.referral.referral_fee_due_to_referring_agent) ? Result.referral.referral_fee_due_to_referring_agent : '',
                        referring_agent_referral_fee_action: (Result.referral.referring_agent_referral_fee_action) ? Result.referral.referring_agent_referral_fee_action : '',
                        is_buyer_referral_upline_agent: isBuyerReferralUplineAgent,
                        is_agent_assign_to_referral: isAgentAssignToReferral,

                        agent_plan_commission: (Result.referral.agent_plan_commission >= 0 && Result.referral.agent_plan_commission != null) ? parseFloat(Result.referral.agent_plan_commission).toFixed(2) : '',

                        refr_plan_commission: (Result.referral.refr_plan_commission >= 0 && Result.referral.refr_plan_commission != null) ? parseFloat(Result.referral.refr_plan_commission).toFixed(2) : '',

                        potential_refr_earning: (Result.referral.potential_refr_earning >= 0 && Result.referral.potential_refr_earning != null) ? parseFloat(Result.referral.potential_refr_earning).toFixed(2) : '',

                        potential_user_earning: (Result.referral.potential_user_earning >= 0 && Result.referral.potential_user_earning != null) ? parseFloat(Result.referral.potential_user_earning).toFixed(2) : '',

                        referral_paid_json: (Result.referral.referral_paid_json) ? JSON.parse(Result.referral.referral_paid_json) : '',
                        admin_notes_json: (Result.referral.admin_notes_json) ? JSON.parse(Result.referral.admin_notes_json) : '',

                        referral_created_email: (Result.referral.referral_created_email) ? Result.referral.referral_created_email : '',
                        referral_created_phone: (Result.referral.referral_created_phone) ? Result.referral.referral_created_phone : '',

                        referral_created_users_type: (Result.referral.referral_created_users_type) ? Result.referral.referral_created_users_type : '',
                        messageForDocuments: messageForDocuments,
                        agentMessageForDocuments: agentMessageForDocuments,
                        brokerage_list_id: (Result.referral.brokerage_list_id) ? Result.referral.brokerage_list_id : '',
                        affinity_group_preference_id: (Result.referral.affinity_group_preference_id) ? Result.referral.affinity_group_preference_id : '',
                        agent_language_master_id: (Result.referral.agent_language_master_id) ? Result.referral.agent_language_master_id : '',
                        language_other: (Result.referral.language_other) ? Result.referral.language_other : '',
                        agent_assign_other_requirement: (Result.referral.agent_assign_other_requirement) ? Result.referral.agent_assign_other_requirement : '',

                        other_interested_client: (Result.referral.other_interested_client) ? Result.referral.other_interested_client : '',
                        referral_fee_received: (Result.referral.referral_fee_received) ? Result.referral.referral_fee_received : '',
                        agent_plan_at_time_of_submission: (Result.referral.agent_plan_commission) ? Result.referral.agent_plan_commission : '',
                        agent_due: agentDue,


                        referring_agent_brokerage_name: (Result.referral.referring_agent_brokerage_name) ? Result.referral.referring_agent_brokerage_name : '',
                        referring_agent_broker_name: (Result.referral.referring_agent_broker_name) ? Result.referral.referring_agent_broker_name : '',
                        referring_agent_broker_name_db: (Result.referral.referring_agent_broker_name_db) ? Result.referral.referring_agent_broker_name_db : '',
                        agent_broker_name_db: (Result.referral.agent_broker_name_db) ? Result.referral.agent_broker_name_db : '',
                    };
                });
                if (Result.referral.referral_fee_received)
                    setReferralFeeReceivedDB(Result.referral.referral_fee_received)

                if ((Result.referral.client_first_name) && (Result.referral.client_last_name)) {
                    let str = Result.referral.client_first_name.trim() + ' ' + Result.referral.client_last_name.trim();
                    let matches = str.match(/\b(\w)/g);
                    let acronym = matches.join('');
                    setClientShortName(acronym);
                }

                if ((Result.referral.agent_first_name) && (Result.referral.agent_last_name)) {
                    let str = Result.referral.agent_first_name.trim() + ' ' + Result.referral.agent_last_name.trim();
                    let matches = str.match(/\b(\w)/g);
                    let acronym = matches.join('');
                    setAgentShortName(acronym);
                }

                if ((Result.referral.referral_created_users_name) && (Result.referral.referral_created_users_name)) {
                    let str = Result.referral.referral_created_users_name.trim();
                    let matches = str.match(/\b(\w)/g);
                    let acronym = matches.join('');
                    setReferringAgentShortName(acronym);
                }

                var arr = [];
                let buy_property_area_json = [
                    {
                        buy_city: "",
                        buy_zipcode: "",
                    }
                ];

                if (Result.referral.referral_type == 'buyer_referral') {
                    const countAll = JSON.parse(Result.referral.buy_property_area_json).length;
                    //alert(countAll);

                    for (var i = 0; i < countAll; i++) {
                        arr.push({
                            buy_city_error: "",
                            buy_zipcode_error: "",
                        });
                    }
                    buy_property_area_json = JSON.parse(Result.referral.buy_property_area_json);
                }
                else if (Result.referral.referral_type == 'seller_referral') {
                    const countAll = 1;
                    //alert(countAll);
                    for (var i = 0; i < countAll; i++) {
                        arr.push({
                            buy_city_error: "",
                            buy_zipcode_error: "",
                        });
                    }
                }
                else {
                    if (Result.referral.buyer_or_seller === 'buyer_referral') {
                        const countAll = JSON.parse(Result.referral.buy_property_area_json).length;
                        //alert(countAll);

                        for (var i = 0; i < countAll; i++) {
                            arr.push({
                                buy_city_error: "",
                                buy_zipcode_error: "",
                            });
                        }
                        buy_property_area_json = JSON.parse(Result.referral.buy_property_area_json);
                    }
                    else {
                        const countAll = 1;
                        //alert(countAll);
                        for (var i = 0; i < countAll; i++) {
                            arr.push({
                                buy_city_error: "",
                                buy_zipcode_error: "",
                            });
                        }
                    }
                }
                setPropertyBuyerAreaJson(buy_property_area_json);
                setPropertyBuyerAreaErrorJson(arr);
                fetchPeopleData(Result.referral.users_id);
            }
            else {
                navigate('/dashboard');
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            } else {
                setErrorMessage('There is something wrong. Please try after sometime');
            }
        }
    };
    

    const fetchStatusList = async () => {
        let formData = new FormData();
        formData.append('referral_id', requestReferralId);
        formData.append('user_type', user.type);
        try {
            const response = await axios.post("/get-referral-status-for-agent", formData);
            const { Status, Result } =
                response.data;

            if (Status === true) {
                //alert(Result)
                setReferralStatusList(Result);
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    };


    useEffect(() => {
        if (user !== null) {
            fetchStatusList();
            fetchReferralDetailData();
        }
    }, [user, requestReferralId]);
    const handleDelete = async (rowId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to reverse this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                callDelete(rowId)
            }
        })
    }

    const callDelete = async (rowId) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('id', rowId);

        try {
            const response = await axios.post('/delete-referral', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (user.type === 'admin') {

                            navigate('/app/referral-list-for-admin');
                        } else {
                            navigate('/app/referral-list');
                        }

                    }
                });
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    }

    const handleShowHideAssignedAgent = (mode, agentId) => {
        if (mode === 'select_agent') {
            if (agentId) {
                mode = 'change_agent'
            }
            setSelectAgentCallFrom(mode)
            if (showAssignedAgent) {
                //alert('if');
                setShowAssignedAgent(false)
            }
            else {
                //alert('else');
                setShowAssignedAgent(true)
            }
        } else if (mode === 'assign_agent') {
            Swal.fire({
                title: 'Are you sure?',
                text: "Want to assign referral!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, assign it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    callAssignReferral();
                }
            })
        }
    }

    const callAssignReferral = async () => {
        let formData = new FormData();
        formData.append('call_from', 'assign_agent');
        formData.append('referral_id', requestReferralId);
        formData.append('type', 'existing_ambassador');

        formData.append('existing_ambassador_id', state.agent_id);
        setLoadingForAssignRefr(true);
        try {
            const response = await axios.post('/add-assigned-agent', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoadingForAssignRefr(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                setLoadingForAssignRefr(false);
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    text: StatusMessage,
                }).then((result) => {
                    if (result.isConfirmed) {
                        fetchReferralDetailData();
                    }
                });
            }
        } catch (err) {

            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }

        }
    }


    const handleShowHideSendContract = () => {
        if (showSendContract) {
            //alert('if');
            setShowSendContract(false)
        }
        else {
            //alert('else');
            setShowSendContract(true)
        }
    }
    const changeStatuUnderContractForm = () => {
        setShowUnderContractStatusForm(false)
        setIsUnderContractEditCall(false);
        setAgentSelectedStatus('');
        setStatusRadioButton('');
        fetchReferralDetailData();
    }
    const changeStatuClosedForm = () => {
        setShowClosedStatusForm(false)
        setAgentSelectedStatus('');
        setStatusRadioButton('');
        fetchReferralDetailData();
    }

    const changeStatuReferralPaidForm = () => {
        setShowReferralPaidStatusForm(false)
        setAgentSelectedStatus('');
        setStatusRadioButton('');
        fetchReferralDetailData();
    }
    const changeStatuAdminNotesForm = () => {
        setShowAdminNotesForm(false)
        setAgentSelectedStatus('');
        setStatusRadioButton('');
        fetchReferralDetailData();
    }
    const changeStatusReferralFeeReceivedFormForm = () => {
        setShowReferralFeeReceivedForm(false)
        setAgentSelectedStatus('');
        setStatusRadioButton('');
        fetchReferralDetailData();
    }
    const changeStatuClientCancelledForm = () => {
        setShowClientCancelledStatusForm(false)
        setCallFrom('')
        setAgentSelectedStatus('');
        setStatusRadioButton('');
        fetchReferralDetailData();
    }
    const changeStatuAssignedAgent = () => {
        setShowAssignedAgent(false)
        setAgentSelectedStatus('');
        setStatusRadioButton('');
        fetchReferralDetailData();
    }

    const changeStatuSendContractToAgent = () => {
        setShowSendContract(false)
        setAgentSelectedStatus('');
        setStatusRadioButton('');
        fetchReferralDetailData();
    }
    const handleEmail = (rowId) => {
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();
        //alert(rowId)
        let finalUrl = 'u=' + encodeURIComponent(userIdEncryptedString);
        navigate('/app/referral-user-email-template?' + finalUrl);
    }
    const handleEmailCommunication = (rowId) => {
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();
        //alert(rowId)
        let finalUrl = 'u=' + encodeURIComponent(userIdEncryptedString);
        navigate('/app/referral-user-email-communication?' + finalUrl);
    }

    const handleSetReferralStatus = async () => {
        setValidMessage('');
        let formData = new FormData();
        formData.append('status', JSON.stringify(agentSelectedStatus));
        formData.append('referral_id', requestReferralId);
        formData.append('users_id', user.id);
        //alert(agentSelectedStatus.name)
        if (agentSelectedStatus) {

            if (agentSelectedStatus.name === 'under_contract' || agentSelectedStatus.name === 'closed' || agentSelectedStatus.name === 'referral_paid' || agentSelectedStatus.name === 'client_cancelled' || agentSelectedStatus.name === 'no_longer_working_with_client') {
                if (agentSelectedStatus.name === 'under_contract')
                    setShowUnderContractStatusForm(true);
                else if (agentSelectedStatus.name === 'referral_paid')
                    setShowReferralPaidStatusForm(true);
                else if (agentSelectedStatus.name === 'client_cancelled') {
                    setCallFrom('client_cancelled')
                    setShowClientCancelledStatusForm(true);
                }
                else if (agentSelectedStatus.name === 'no_longer_working_with_client') {
                    setCallFrom('no_longer_working_with_client')
                    setShowClientCancelledStatusForm(true);
                }
                else
                    setShowClosedStatusForm(true);
            }
            else {
                setLoading(true);
                try {
                    const response = await axios.post("/update-referral-status", formData);
                    const { Status, StatusMessage, Error } =
                        response.data;
                    setLoading(false);
                    let finalApiError = '';
                    if (Status === false) {
                        if (Error.length > 0) {
                            Error.map(
                                (errorMessage) =>
                                    //finalApiError += '<li>'+errorMessage+'</li>'
                                    (finalApiError = errorMessage)
                            );
                        } else {
                            finalApiError = StatusMessage;
                        }
                        setErrorMessage(finalApiError);
                    } else {
                        Swal.fire({
                            title: 'Success',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            text: StatusMessage,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setAgentSelectedStatus('');
                                setStatusRadioButton('');
                                fetchReferralDetailData();
                            }
                        });
                    }
                } catch (err) {
                    setLoading(false);

                    if (err.response.status && err.response.status == 401) {
                        logout();
                        navigate('/login');
                    }
                }
            }
        }
        else {
            setValidMessage('Please select status.')
        }
    }

    const handleModal = () => {
        setShowClientReferred(true)
    }
    const changeStatuClosedhandleModal = () => {
        setShowClientReferred(false)
        fetchReferralDetailData();
    }
    const handleModalAssignedAgent = () => {
        setShowAssignedAgentModal(true)
    }
    const changeStatuClosedhandleModalAssignedAgent = () => {
        setShowAssignedAgentModal(false)
        fetchReferralDetailData();
    }
    const handleModalReferralData = () => {
        setShowReferralData(true)
    }
    const changeStatuClosedhandleModalReferralData = () => {
        setPropertyBuyerAreaJson([]);
        setPropertyBuyerAreaErrorJson([]);
        setShowReferralData(false)
        fetchReferralDetailData();
    }
    function capitalizeFirstLetter(string) {
        if (string === 'agent join process')
            string = 'pending agent joining REFR';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    function getFormattedDate(dbDate) {
        var date = new Date(dbDate);

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();

        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        sec = (sec < 10 ? "0" : "") + sec;

        var str = month + "/" + day + "/" + date.getFullYear() + " " + hour + ":" + min + ":" + sec;

        /*alert(str);*/

        return str;
    }

    const handleEditDataForCommission = () => {
        setShowUnderContractStatusForm(true);
        setIsUnderContractEditCall(true);
    }
    const handlePotentialCommissionEdit = () => {
        setShowPotentialDataEditForm(true);
    }
    const changeStatuEditPotentialCommissionForm = () => {
        setShowPotentialDataEditForm(false)
        fetchReferralDetailData();
    }

    const handleReferralDocumentsUpload = () => {
        setShowUploadDocs(true)
        setCallFrom('referral_documents');
        setEditRowId(requestReferralId);
    }
    const changeStatuUpload = () => {
        setCallFrom('');
        setEditRowId(0)
        setShowUploadDocs(false)
    }
    const reloadListing = () => {
        setShowUploadDocs(false)
        setCallFrom('');
        setEditRowId(0)
        fetchReferralDetailData();
    }

    const handleDeleteDocuments = (rowId, mode, media) => {
        Swal.fire({
            title: 'Are you sure?',
            html: "You won't be able to reverse this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                handleDeleteDocumentsCall(rowId, mode, media);
            }
        })
    }
    const handleDeleteDocumentsCall = async (rowId, mode, media) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('user_id', rowId);
        formData.append('mode', mode);
        formData.append('media', media);
        formData.append('is_profile_page_call', 0);

        try {
            const response = await axios.post('/delete-docs_by_admin', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {

                fetchReferralDetailData();
            }
        } catch (err) {
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }
    }

    switch (id) {

        case "ReferralDetail":
            return (
                <>
                    <section className="referral-template sec-pt sec-pb">

                        <div className="container">
                            <div className="grid">
                                {
                                    (user) && (user.type === 'admin') && (state.messageForDocuments) && (<div className="col-12 md:col-12">
                                        <Message severity="error" text={state.messageForDocuments} />
                                    </div>)
                                }
                            </div>
                            <div className="grid">
                                {
                                    (user) && (user.type === 'admin') && (state.agentMessageForDocuments) && (<div className="col-12 md:col-12">
                                        <Message severity="error" text={state.agentMessageForDocuments} />
                                    </div>)
                                }
                            </div>
                            <div className="referral-container referral-details-container referal_details_space">
                                <div className="details-title ref-detail-title ref_title">
                                    <div className="title-box">

                                        {
                                            (referralCreatedUserName) && (<h2 className="main-heading mb-0 heading_agent">{referralCreatedUserName}</h2>)

                                        }
                                        {
                                            (referenceNumber) && <p className="heading-number text-center">( {referenceNumber} )</p>
                                        }
                                    </div>

                                    <div className="set-referral-step">
                                        {/* {errorMessage && (
                                            <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                                {errorMessage}
                                            </Alert>
                                        )} */}
                                        {
                                            (referralStatusList.length > 0) && (user) && (user.id === state.agent_id) && (state.referral_status !== 'pending_agent_selection' && state.referral_status !== 'agent_join_process' && state.referral_status !== 'pending_referral_assignment' && state.referral_status !== 'no_longer_working_with_client' && state.referral_status !== 'client_cancelled' && state.referral_status !== 'previous_two_way_communication' && state.referral_status !== 'referral_paid' && state.referral_status !== 'closed' && state.referral_status !== 'pending_agent_payment' && state.referral_status !== 'pending_ambassador_payment' && state.referral_status !== 'pending_agent_signature') && (<>
                                                <div className="field detail-op">
                                                    <label htmlFor=""></label>
                                                    <Dropdown name="agent_status_name" value={agentSelectedStatus} options={referralStatusList} placeholder="Select Status" onChange={(e) => { setAgentSelectedStatus(e.target.value) }}
                                                        valueTemplate={selectedTemplate}
                                                        itemTemplate={optionTemplate}
                                                        optionLabel="name"
                                                    />
                                                    {
                                                        (validMessage) && (<small className="p-error">{validMessage}</small>)
                                                    }
                                                </div>
                                                <button type="button" className="btn btn-submit detail-op ref-detail-btn" onClick={handleSetReferralStatus} disabled={loading}>Set Referral Status
                                                    {loading && (<Typography align="center">
                                                        <CircularProgress value={66} />
                                                    </Typography>)}
                                                </button></>)
                                        }
                                        {

                                            (referralStatusList.length > 0) && (user) && (user.type === 'admin') && (state.referral_status !== 'pending_agent_selection' && state.referral_status !== 'agent_join_process' && state.referral_status !== 'pending_referral_assignment' && state.referral_status !== 'pending_agent_signature') && (<>
                                                <div className="field detail-op fields_detail_ref step-ref">
                                                    <label htmlFor=""></label>
                                                    <Dropdown name="agent_status_name" value={agentSelectedStatus} options={referralStatusList} placeholder="Select Status" onChange={(e) => { setAgentSelectedStatus(e.target.value) }}
                                                        valueTemplate={selectedTemplate}
                                                        itemTemplate={optionTemplate}
                                                        optionLabel="name"
                                                    />
                                                    {
                                                        (validMessage) && (<small className="p-error">{validMessage}</small>)
                                                    }
                                                </div>
                                                <button type="button" className="btn btn-submit detail-op ref-detail-btn step-ref-btn" onClick={handleSetReferralStatus} disabled={loading}>Set Referral Status
                                                    {loading && (<Typography align="center">
                                                        <CircularProgress value={66} />
                                                    </Typography>)}
                                                </button></>)
                                        }
                                        {/* {
                                            (user) && (user.type === 'admin' || user.id === state.agent_id) && (agentContract) && (<a href={agentContract} target="_blank" className='file-btn detail-op step-ref-btn'>
                                                <span>
                                                    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.7925 4.35865L11.8376 0.617121C11.417 0.219146 10.8664 0 10.2873 0H2.75781C1.51393 0 0.501953 1.01198 0.501953 2.25586V18.7441C0.501953 19.988 1.51393 21 2.75781 21H14.2422C15.4861 21 16.498 19.988 16.498 18.7441V5.99739C16.498 5.38014 16.2409 4.78283 15.7925 4.35865ZM14.5974 4.92188H11.5352C11.4221 4.92188 11.3301 4.82988 11.3301 4.7168V1.83077L14.5974 4.92188ZM14.2422 19.7695H2.75781C2.19241 19.7695 1.73242 19.3095 1.73242 18.7441V2.25586C1.73242 1.69046 2.19241 1.23047 2.75781 1.23047H10.0996V4.7168C10.0996 5.50836 10.7436 6.15234 11.5352 6.15234H15.2676V18.7441C15.2676 19.3095 14.8076 19.7695 14.2422 19.7695Z" fill="#2B70E4" />
                                                        <path d="M12.8887 8.20312H3.86523C3.52546 8.20312 3.25 8.47859 3.25 8.81836C3.25 9.15813 3.52546 9.43359 3.86523 9.43359H12.8887C13.2284 9.43359 13.5039 9.15813 13.5039 8.81836C13.5039 8.47859 13.2284 8.20312 12.8887 8.20312Z" fill="#2B70E4" />
                                                        <path d="M12.8887 11.4844H3.86523C3.52546 11.4844 3.25 11.7598 3.25 12.0996C3.25 12.4394 3.52546 12.7148 3.86523 12.7148H12.8887C13.2284 12.7148 13.5039 12.4394 13.5039 12.0996C13.5039 11.7598 13.2284 11.4844 12.8887 11.4844Z" fill="#2B70E4" />
                                                        <path d="M6.84789 14.7656H3.86523C3.52546 14.7656 3.25 15.0411 3.25 15.3809C3.25 15.7206 3.52546 15.9961 3.86523 15.9961H6.84789C7.18766 15.9961 7.46312 15.7206 7.46312 15.3809C7.46312 15.0411 7.18766 14.7656 6.84789 14.7656Z" fill="#2B70E4" />
                                                    </svg>
                                                </span>
                                            </a>)
                                        } */}

                                        {
                                            (state.referral_status === 'pending_agent_selection' || state.referral_status === 'pending_agent_signature' || state.referral_status === 'agent_join_process' || state.referral_status === 'pending_referral_assignment') && (user.type === 'admin') && (!agentContract) && (<button type="button" className="btn btn-submit detail-op ref-detail-btn" onClick={handleShowHideSendContract}>Upload Referral Fee Agreement</button>)
                                        }

                                        {
                                            (user.type === 'admin') && (<button type="button" className="btn btn-submit detail-op ref-detail-btn step-ref-btn" onClick={() => { handleShowHideAssignedAgent('select_agent', state.agent_id) }}>{
                                                (state.agent_id) ? 'Change' : 'Select'
                                            } Agent</button>)
                                        }
                                        {
                                            (state.referral_status === 'pending_referral_assignment') && (user.type === 'admin') && (<button type="button" className="btn btn-submit detail-op ref-detail-btn" onClick={() => { handleShowHideAssignedAgent('assign_agent', '') }} disabled={loadingForAssignRefr}>Assign Referral{loadingForAssignRefr && (<Typography align="center">
                                                <CircularProgress value={66} />
                                            </Typography>)}</button>)
                                        }
                                    </div>
                                    {(user) && (user.id === state.users_id) && (!state.agent_id) &&
                                        (<div className="view-referral-btn">
                                            <Button
                                                to=""
                                                className="btn btn-cancel btn-delete-view me-3"
                                                variant="contained"
                                                color="primary"
                                                onClick={(e) => { handleDelete(requestReferralId) }}
                                            >
                                                Delete
                                            </Button>

                                        </div>)}
                                </div>
                                {(user) && (user.type === 'admin') &&
                                    (<div className="view-referral-btn">
                                        <Button
                                            to=""
                                            className="btn btn-submit btn_signin btn-delete-view btn_delete email_com_btn"
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => { handleEmailCommunication(requestReferralId) }}
                                        >
                                            Email Communication
                                        </Button>
                                        <Button
                                            to=""
                                            className="btn btn-cancel btn-delete-view btn_delete"
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => { handleDelete(requestReferralId) }}
                                        >
                                            Delete
                                        </Button>
                                        <Button
                                            to=""
                                            className="btn btn-submit btn_signin"
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => { handleEmail(requestReferralId) }}
                                        >
                                            Email
                                        </Button>

                                    </div>)}

                                <Card>
                                    <Card.Body>
                                        <div className="step-slides-container hide">
                                            <Slider {...stepsSlider} ref={sliderRef}>
                                                {(stepsLabel.length > 0) && stepsLabel.map((step, index) => {
                                                    const labelProps = {};
                                                    const stepProps = {};
                                                    let tempClass = '';
                                                    let tempClassIcon = '';
                                                    if (currentActiveStep > index) {
                                                        tempClass = 'steps-active';
                                                        tempClassIcon = 'pi-check';
                                                    }
                                                    else if (currentActiveStep === index) {
                                                        tempClass = 'steps-active steps-next';
                                                        tempClassIcon = 'pi-circle-fill';
                                                    }
                                                    else {
                                                        tempClass = 'steps-next steps-active steps-other';
                                                        tempClassIcon = 'pi-circle-fill';
                                                    }
                                                    const stepName = step.replace(/_/g, ' ');

                                                    return (
                                                        <div className="step-s" {...stepProps}>
                                                            <span className={`steps ${tempClass}`}><i className={`pi ${tempClassIcon}`}></i></span>
                                                            <p className="steps-label mb-0">{capitalizeFirstLetter(stepName)}</p>
                                                        </div>
                                                    );
                                                })}

                                            </Slider>
                                        </div>
                                        <div className="referral-fields referral-review">
                                            {/* <h2 className="referall-title mobile-title">
Review Your Referral
</h2> */}
                                            {errorMessage && (
                                                <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                                                    {errorMessage}
                                                </Alert>
                                            )}
                                            <Card>
                                                <Card.Body>
                                                    <div className="card-container">
                                                        <div className="row">
                                                            <div className="col-md-6" >
                                                                <p className="title">Client Referred</p>
                                                            </div>
                                                            {(user) && ((user.id === state.users_id) && (!state.agent_id)) &&
                                                                (<div className="col-md-6 iconcls" >
                                                                    <a href='javascript:void(0)' onClick={(e) => handleModal()}>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g clip-path="url(#clip0_3722_21796)">
                                                                                <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_3722_21796">
                                                                                    <rect width="20" height="20" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </a>
                                                                </div>)}
                                                            {(user) && (user.type === 'admin') &&
                                                                (<div className="col-md-6 iconcls" >
                                                                    <a href='javascript:void(0)' onClick={(e) => handleModal()}>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g clip-path="url(#clip0_3722_21796)">
                                                                                <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_3722_21796">
                                                                                    <rect width="20" height="20" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </a>
                                                                </div>)}
                                                        </div>
                                                        <ul className="client-detail-list">
                                                            <li className="user-pro-name">
                                                                <Avatar>{clientShortName}</Avatar>
                                                                <p className="name">{(state.client_first_name) ? state.client_first_name + ' ' + state.client_last_name : 'N/A'}</p>
                                                            </li>
                                                            <li className="user_proname_li">
                                                                <img
                                                                    src="../assets/images/email_i.png"
                                                                    alt=""
                                                                />
                                                                <a href={(state.client_email) ? 'mailto:' + state.client_email : ''}>
                                                                    {(state.client_email) ? state.client_email : 'N/A'}
                                                                </a>
                                                            </li>
                                                            <li className="user_proname_li">
                                                                <img
                                                                    src="../assets/images/phone.png"
                                                                    alt=""
                                                                />
                                                                <a href={(state.client_phone) ? 'tel:' + state.client_phone : ''}>
                                                                    {(state.client_phone) ? state.client_phone : 'N/A'}
                                                                </a>
                                                            </li>

                                                        </ul>
                                                        <ul className="data-list single-type mb-lg-6 full_space pb-0">
                                                            <li>
                                                                <p className="title">
                                                                    How do you know this client?
                                                                </p>
                                                                {
                                                                    (state.client_reference_master_id) ? (<p className="sub-title">{(state.client_reference_master_id === 'Other' ? state.other_client_reference : state.client_reference_master_id)}</p>) : <p className="sub-title">N/A</p>
                                                                }
                                                            </li>
                                                            <li>
                                                                <p className="title">
                                                                    Any other interested parties
                                                                </p>
                                                                <p className="sub-title">{(state.other_interested_client) ? state.other_interested_client : 'N/A'}</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            <div className="card-groups">
                                                <Card>
                                                    <Card.Body>
                                                        <div className="card-container">
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <p className="title">Referring Agent</p>
                                                                </div>
                                                            </div>
                                                            <ul className="client-detail-list">
                                                                <li className="user-pro-name">
                                                                    <Avatar>{referringAgentShortName}</Avatar>
                                                                    <p className="name">{(referralCreatedUserName) ? referralCreatedUserName : 'N/A'}</p>
                                                                </li>
                                                                <li className="user_proname_li">
                                                                    <img
                                                                        src="../assets/images/email_i.png"
                                                                        alt=""
                                                                    />
                                                                    <a href={(state.referral_created_email) ? 'mailto:' + state.referral_created_email : ''}>
                                                                        {(state.referral_created_email) ? state.referral_created_email : 'N/A'}
                                                                    </a>
                                                                </li>
                                                                <li className="user_proname_li">
                                                                    <img
                                                                        src="../assets/images/phone.png"
                                                                        alt=""
                                                                    />
                                                                    <a href={(state.referral_created_phone) ? 'tel:' + state.referral_created_phone : ''}>
                                                                        {(state.referral_created_phone) ? state.referral_created_phone : 'N/A'}
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                            <ul className="client-detail-list dlists refe_list">
                                                                <li>
                                                                    <p className="title">
                                                                        Type
                                                                    </p>
                                                                    <p className="sub-title">{(state.referral_created_users_type) ? Capitalize(state.referral_created_users_type) : 'N/A'}</p>
                                                                </li>
                                                                <li>
                                                                    <p className="title">
                                                                        Bokerage Name
                                                                    </p>
                                                                    <p className="sub-title">{((state.referral_created_users_type === 'ambassador') && (state.referring_agent_brokerage_name)) ? Capitalize(state.referring_agent_brokerage_name) : 'N/A'}</p>
                                                                </li>
                                                                <li>
                                                                    <p className="title">
                                                                        Broker of Record Name
                                                                    </p>
                                                                    <p className="sub-title">{((state.referral_created_users_type === 'ambassador') && (state.referring_agent_broker_name)) ? Capitalize(state.referring_agent_broker_name) : 'N/A'}</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="card-groups">
                                                <Card>
                                                    <Card.Body>
                                                        <div className="card-container">
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <p className="title">Best Fit Agent Preferences</p>
                                                                </div>
                                                            </div>
                                                            <ul className="client-detail-list dlists refe_list refe_brand">
                                                                <li>
                                                                    <p className="title">
                                                                        Brand
                                                                    </p>
                                                                    <p className="sub-title">{((state.brokerage_list_id) && (state.brokerage_list_id.name)) ? state.brokerage_list_id.name : 'N/A'}</p>
                                                                </li>
                                                                <li>
                                                                    <p className="title">
                                                                    Affinity Group
                                                                    </p>
                                                                    <p className="sub-title">{((state.affinity_group_preference_id) && (state.affinity_group_preference_id.name)) ? state.affinity_group_preference_id.name : 'N/A'}</p>
                                                                </li>
                                                                <li>
                                                                    <p className="title">
                                                                        Language
                                                                    </p>
                                                                    {
                                                                        (state.agent_language_master_id) && (state.agent_language_master_id.name !== 'Other') &&
                                                                        (<p className="sub-title">{state.agent_language_master_id.name}</p>)
                                                                    }

                                                                    {
                                                                        (state.agent_language_master_id) && (state.agent_language_master_id.name === 'Other') && (state.language_other) &&
                                                                        (<p className="sub-title">{state.language_other}</p>)
                                                                    }
                                                                    {
                                                                        (!state.agent_language_master_id) &&
                                                                        (<p className="sub-title">N/A</p>)
                                                                    }
                                                                </li>
                                                                
                                                            </ul>
                                                            <ul className="client-detail-list dlists refe_list refe_brand">
                                                                
                                                                <li>
                                                                    <p className="title">
                                                                        Other Special Requirements
                                                                    </p>
                                                                    <p className="sub-title">{(state.agent_assign_other_requirement) ? state.agent_assign_other_requirement : 'N/A'}</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className="card-groups">
                                                <div className="row">
                                                    <div className="col-lg-12 left-detail-col pb-lg-4 card_header_notes">
                                                        <Card>
                                                            <Card.Header>

                                                                <p className="title">{(state.is_assigned) ? 'Assigned' : 'Potential'} Agent</p>
                                                                <p className="sub-title">
                                                                    {(state.assigned_agent_date_time) ? getFormattedDate(state.assigned_agent_date_time) : ''}
                                                                </p>

                                                                {(user) && ((user.id === state.users_id) && (!state.agent_id)) &&
                                                                    (<div className="col-md-6 iconcls" >
                                                                        <a href='javascript:void(0)' onClick={(e) => handleModalAssignedAgent()}>
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clip-path="url(#clip0_3722_21796)">
                                                                                    <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_3722_21796">
                                                                                        <rect width="20" height="20" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </a>
                                                                    </div>)}
                                                                {(user) && (user.type === 'admin') &&
                                                                    (<div className="col-md-6 iconcls" >
                                                                        <a href='javascript:void(0)' onClick={(e) => handleModalAssignedAgent()}>
                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clip-path="url(#clip0_3722_21796)">
                                                                                    <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_3722_21796">
                                                                                        <rect width="20" height="20" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </a>
                                                                    </div>)}
                                                            </Card.Header>
                                                            <Card.Body>
                                                                {
                                                                    (state.agent_first_name) && (<div className="card-container">
                                                                        <ul className="client-detail-list dlists refe_list">
                                                                            <li className="user-pro-name">
                                                                                <Avatar>{agentShortName}</Avatar>
                                                                                <p className="name">{(state.agent_first_name) ? state.agent_first_name + ' ' + state.agent_last_name : 'N/A'}</p>
                                                                            </li>
                                                                            <li className="user_proname_rererral">
                                                                                <img
                                                                                    src="../assets/images/email_i.png"
                                                                                    alt=""
                                                                                />
                                                                                <a href={(state.agent_email) ? 'mailto:' + state.agent_email : ''}>
                                                                                    {(state.agent_email) ? state.agent_email : ''}
                                                                                </a>
                                                                            </li>
                                                                            <li className="user_proname_rererral">
                                                                                <img
                                                                                    src="../assets/images/phone.png"
                                                                                    alt=""
                                                                                />
                                                                                <a href={(state.agent_phone) ? 'tel:' + state.agent_phone : ''}>
                                                                                    {(state.agent_phone) ? state.agent_phone : '(209) 555-0104'}
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="client-detail-list dlists refe_list">
                                                                            <li>
                                                                                <p className="title">
                                                                                    Type
                                                                                </p>
                                                                                <p className="sub-title">{(state.agent_type) ? Capitalize(state.agent_type) : 'N/A'}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="title">
                                                                                    Bokerage Name
                                                                                </p>
                                                                                <p className="sub-title">{((state.agent_type === 'ambassador') && (state.agent_brokerage_name)) ? Capitalize(state.agent_brokerage_name) : 'N/A'}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="title">
                                                                                    Broker of Record Name
                                                                                </p>
                                                                                <p className="sub-title">{((state.agent_type === 'ambassador') && (state.agent_broker_name)) ? Capitalize(state.agent_broker_name) : 'N/A'}</p>
                                                                            </li>
                                                                        </ul>
                                                                        {(state.agent_type === 'ambassador') && (<ul className='client-detail-list dlists refe_list'>
                                                                            <li className="licence-field">
                                                                                <li className='title'><p>Broker of Record License #</p></li>
                                                                                {
                                                                                    (state.agent_broker_license_json.length > 0) && (state.agent_broker_license_json.map((x, i) => {
                                                                                        return (
                                                                                            <li key={i} className='sub-title'><p>{x.license_number}</p></li>
                                                                                        )
                                                                                    }))

                                                                                }
                                                                                {
                                                                                    (state.agent_broker_license_json.length === 0) && (<li className='sub-title'><p>-</p></li>)
                                                                                }
                                                                            </li>
                                                                            <li className="licence-field">
                                                                                <li className='title'><p>Broker of Record License State</p></li>
                                                                                {
                                                                                    (state.agent_broker_license_json.length > 0) && (state.agent_broker_license_json.map((x, i) => {
                                                                                        return (
                                                                                            <li key={i} className='sub-title'><p>{x.license_state.abbrv}</p></li>
                                                                                        )
                                                                                    }))

                                                                                }
                                                                                {
                                                                                    (state.agent_broker_license_json.length === 0) && (<li className='sub-title'><p>-</p></li>)
                                                                                }
                                                                            </li>
                                                                        </ul>)}

                                                                    </div>)
                                                                }
                                                                {
                                                                    (!state.agent_first_name) && (<div className="no-agent-select">
                                                                        <p>No Agent Selected</p>
                                                                    </div>)
                                                                }
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                    <div className="col-lg-12 right-ref-col">
                                                        <Card>
                                                            <Card.Header>
                                                                <div className="row">
                                                                    <div className="col-md-6" >
                                                                        <p className="title">Referral Data</p>
                                                                    </div>
                                                                    {(user) && ((user.id === state.users_id) && (!state.agent_id)) &&
                                                                        (<div className="col-md-6 iconcls" >
                                                                            <a href='javascript:void(0)' onClick={(e) => handleModalReferralData()}>
                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clip-path="url(#clip0_3722_21796)">
                                                                                        <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_3722_21796">
                                                                                            <rect width="20" height="20" fill="white" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </a>
                                                                        </div>)}
                                                                    {(user) && (user.type === 'admin') &&
                                                                        (<div className="col-md-6 iconcls" >
                                                                            <a href='javascript:void(0)' onClick={(e) => handleModalReferralData()}>
                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clip-path="url(#clip0_3722_21796)">
                                                                                        <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_3722_21796">
                                                                                            <rect width="20" height="20" fill="white" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </a>
                                                                        </div>)}
                                                                </div>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                {state.referral_type === "buyer_and_seller_referral" && (
                                                                    <div className="card-container">
                                                                        <ul className="data-list">
                                                                            <li>
                                                                                <p className="h-title">Referral Type :</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="data-title">Buyer</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="data-title">Seller</p>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="data-list">
                                                                            <li>
                                                                                <p className="h-title double-title">Property Type:</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="data-title">{(state.property_type) ? state.property_type : 'N/A'}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="data-title">{(state.seller_property_type) ? state.seller_property_type : 'N/A'}</p>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="data-list">
                                                                            <li>
                                                                                <p className="h-title">Time Frame :</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="data-title">{(state.buyer_time_frame_name) ? state.buyer_time_frame_name : 'N/A'}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="data-title">{(state.seller_time_frame_name) ? state.seller_time_frame_name : 'N/A'}</p>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="data-list">
                                                                            <li>
                                                                                <p className="h-title">Location :</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="data-title">{(state.buyer_full_address) ? state.buyer_full_address : 'N/A'}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="data-title">{(state.seller_full_address) ? state.seller_full_address : 'N/A'}</p>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="data-list">
                                                                            <li>
                                                                                <p className="h-title">Comments :</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="data-title">{(state.buyer_comments) ? state.buyer_comments : 'N/A'}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="data-title">{(state.seller_comments) ? state.seller_comments : 'N/A'}</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>)
                                                                }



                                                                {state.referral_type !== "buyer_and_seller_referral" && (<div className="card-container">
                                                                    <ul className="data-list single-type mb-lg-4 pb-0">
                                                                        <li>
                                                                            <p className="title">
                                                                                Referral Type
                                                                            </p>
                                                                            {
                                                                                (state.referral_type === 'buyer_referral') && (<p className="sub-title">Buyer</p>)
                                                                            }
                                                                            {
                                                                                (state.referral_type === 'seller_referral') && (<p className="sub-title">Seller</p>)
                                                                            }
                                                                        </li>
                                                                        {
                                                                            (state.referral_type === 'buyer_referral') && (<li>
                                                                                <p className="title">Time Frame </p>
                                                                                <p className="sub-title">
                                                                                    {(state.buyer_time_frame_name) ? state.buyer_time_frame_name : 'N/A'}
                                                                                </p>

                                                                            </li>)
                                                                        }
                                                                        {
                                                                            (state.referral_type === 'seller_referral') && (
                                                                                <li>
                                                                                    <p className="title">Time Frame </p>
                                                                                    <p className="sub-title">
                                                                                        {(state.seller_time_frame_name) ? state.seller_time_frame_name : 'N/A'}
                                                                                    </p>
                                                                                </li>)
                                                                        }
                                                                        <li>
                                                                            <p className="title">
                                                                                {(state.referral_type === 'seller_referral') ? 'Estimated Home Value' : 'Price Range'}
                                                                            </p>
                                                                            {
                                                                                (state.referral_type === 'buyer_referral') && (<p className="sub-title">
                                                                                    <Currency locale="en-US" currency="USD" minimumFractionDigits="0" maximumFractionDigits="0">{state.buyer_price_range_min}</Currency>To<Currency locale="en-US" currency="USD" minimumFractionDigits="0" maximumFractionDigits="0">{state.buyer_price_range_max}</Currency>

                                                                                </p>)
                                                                            }
                                                                            {
                                                                                (state.referral_type === 'seller_referral') && (<p className="sub-title">
                                                                                    {
                                                                                        (state.seller_price_range) && (<Currency locale="en-US" currency="USD">{state.seller_price_range}</Currency>)
                                                                                    }
                                                                                    {
                                                                                        (!state.seller_price_range) && ('N/A')
                                                                                    }
                                                                                </p>)
                                                                            }
                                                                        </li>
                                                                        <li className="me-3">
                                                                            <p className="title">
                                                                                {(state.referral_type === 'buyer_referral') ? 'Location Searching' : 'Property Address'}
                                                                            </p>
                                                                            {
                                                                                (state.referral_type === 'buyer_referral') && (<p className="sub-title">
                                                                                    {(state.buyer_full_address) ? state.buyer_full_address : 'N/A'}
                                                                                </p>)
                                                                            }
                                                                            {
                                                                                (state.referral_type === 'seller_referral') && (<p className="sub-title">
                                                                                    {(state.seller_full_address) ? state.seller_full_address : 'N/A'}
                                                                                </p>)
                                                                            }

                                                                        </li>
                                                                    </ul>
                                                                    {/* <ul className="data-list single-type">


                                                                        </ul> */}
                                                                    <ul className="data-list single-type pt-0">
                                                                        <li>
                                                                            <p className="title">
                                                                                Property Type
                                                                            </p>
                                                                            {
                                                                                (state.referral_type === 'buyer_referral') && (<p className="sub-title">
                                                                                    {(state.property_type) ? state.property_type : 'N/A'}
                                                                                </p>)
                                                                            }
                                                                            {
                                                                                (state.referral_type === 'seller_referral') && (<p className="sub-title">
                                                                                    {(state.seller_property_type) ? state.seller_property_type : 'N/A'}
                                                                                </p>)
                                                                            }
                                                                        </li>
                                                                        {
                                                                            (state.referral_type === 'buyer_referral') && (<li>
                                                                                <p className="title">
                                                                                    Reason for  <br /> Purchase
                                                                                </p>
                                                                                {
                                                                                    (state.referral_type === 'buyer_referral') && (<p className="sub-title">
                                                                                        {(state.clients_looking_to_purcahse) ? state.clients_looking_to_purcahse : 'N/A'}
                                                                                    </p>)
                                                                                }
                                                                                {/* {
                                                                                    (state.referral_type === 'seller_referral') && (<p className="sub-title">
                                                                                        N/A
                                                                                    </p>)
                                                                                } */}
                                                                            </li>)}
                                                                        <li>
                                                                            <p className="title">Comments </p>
                                                                            {
                                                                                (state.referral_type === 'buyer_referral') && (<p className="sub-title">
                                                                                    {(state.buyer_comments) ? state.buyer_comments : 'N/A'}
                                                                                </p>)
                                                                            }
                                                                            {
                                                                                (state.referral_type === 'seller_referral') && (<p className="sub-title">
                                                                                    {(state.seller_comments) ? state.seller_comments : 'N/A'}
                                                                                </p>)
                                                                            }

                                                                        </li>

                                                                    </ul>
                                                                </div>
                                                                )}



                                                            </Card.Body>
                                                        </Card>
                                                    </div>

                                                </div>
                                            </div>
                                            {(state.is_buyer_referral_upline_agent === 'no') && (state.is_agent_assign_to_referral === 'yes') &&
                                                (state.referral_created_users_type === 'ambassador') && (state.referral_fee_user_receive) && (<div className="card-groups">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <Card>
                                                                <Card.Header>

                                                                    <div className="row">
                                                                        <div className="col-md-6" >
                                                                            <p className="title">Referral Fee Calculation </p>
                                                                        </div>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <div className="card-container">
                                                                        <ul className="data-list single-type mt-0 pb-lg-4">
                                                                            <li>
                                                                                <p className="title inner-title">Referral Fee You Would Like to Receive </p>
                                                                                <p className="sub-title">
                                                                                    {(state.referral_fee_user_receive) ? state.referral_fee_user_receive + '%' : 'N/A'}
                                                                                </p>

                                                                            </li>
                                                                            <li>
                                                                                <p className="title inner-title">REFR Platform Fee </p>
                                                                                <p className="sub-title">
                                                                                    {(state.referral_platform_fee) ? '$' + state.referral_platform_fee : 'N/A'}
                                                                                </p>

                                                                            </li>
                                                                        </ul>
                                                                        <ul className="data-list single-type mt-0 percent_list">
                                                                            <li>
                                                                                <p className="title inner-title">Total Referral Fee charged to Receiving Agent </p>
                                                                                <p className="sub-title">
                                                                                    {(state.referral_fee_charged_to_receiving_agent) ? state.referral_fee_charged_to_receiving_agent
                                                                                        : 'N/A'}

                                                                                </p>

                                                                            </li>

                                                                        </ul>
                                                                        <ul className="data-list single-type mt-0 percent_list">
                                                                            <li>
                                                                                <p className="title inner-title">Total Referral Fee due to Referring Agent </p>
                                                                                <p className="sub-title">
                                                                                    {(state.referral_fee_due_to_referring_agent) ? state.referral_fee_due_to_referring_agent
                                                                                        : 'N/A'}

                                                                                </p>

                                                                            </li>

                                                                        </ul>
                                                                        <ul className="data-list single-type mt-0 percent_list">
                                                                            <li>
                                                                                <p className="title inner-title">Ambassador Action </p>
                                                                                <p className="sub-title">
                                                                                    {(state.referring_agent_referral_fee_action) && (state.referring_agent_referral_fee_action === 'yes') ? 'Yes, I would like to pass the $199.00 platform fee onto the receiving agent'
                                                                                        : ''}
                                                                                    {(state.referring_agent_referral_fee_action) && (state.referring_agent_referral_fee_action === 'no') ? 'No, I want REFR to take the $199.00 fee from my portion of the referral fee'
                                                                                        : ''}
                                                                                    {(!state.referring_agent_referral_fee_action) ? 'N/A' : ''}
                                                                                </p>

                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }

                                            {
                                                (!state.sales_price) && (<div className="card-groups">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <Card>
                                                            <Card.Header>

                                                                <div className="row">
                                                                    <div className="col-md-6" >
                                                                        <p className="title">Potential Commission </p>
                                                                    </div>
                                                                    {(user) && (user.type === 'admin') &&
                                                                        (<div className="col-md-6 iconcls" >
                                                                            <a href='javascript:void(0)' onClick={(e) => handlePotentialCommissionEdit()}>
                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clip-path="url(#clip0_3722_21796)">
                                                                                        <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <clipPath id="clip0_3722_21796">
                                                                                            <rect width="20" height="20" fill="white" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </a>
                                                                        </div>)}
                                                                </div>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <div className="card-container">
                                                                    <ul className="data-list single-type mt-0 pb-lg-4">
                                                                        <li className={(state.referral_type === 'buyer_referral') ? "tooltip-refr" : ''}>
                                                                            <p className="title inner-title">
                                                                                {(state.referral_type === 'buyer_referral') ? 'Estimated Purchase Price' : 'Estimated Sale Price'}

                                                                            </p>
                                                                            <p className="sub-title">{(state.potential_sales_price) ? <Currency locale="en-US" currency="USD">{state.potential_sales_price}</Currency> : 'N/A'}</p>
                                                                            {(state.referral_type === 'buyer_referral') ? (<span className="tooltiptext">Calculated by taking 95% of the max purchase price</span>) : ''}

                                                                        </li>
                                                                        <li>
                                                                            <p className="title inner-title">Average Commission Percentage </p>
                                                                            <p className="sub-title">
                                                                                {(state.potential_commission) ? state.potential_commission + '%' : 'N/A'}
                                                                            </p>

                                                                        </li>
                                                                        <li>
                                                                            <p className="title inner-title">Total Commission </p>
                                                                            <p className="sub-title">
                                                                                {(state.potential_commission_earned) ? <Currency locale="en-US" currency="USD">{state.potential_commission_earned}</Currency> : 'N/A'}
                                                                            </p>

                                                                        </li>


                                                                    </ul>
                                                                    <ul className="data-list single-type mt-0 percent_list">
                                                                        <li>
                                                                            <p className="title inner-title">REFR Referral Fee  </p>
                                                                            <p className="sub-title">
                                                                                {
                                                                                    (state.potential_referral_fee_percentage) && (state.referral_fee_user_receive) && 
                                                                                    (state.referring_agent_referral_fee_action === 'yes') 
                                                                                    ? state.potential_referral_fee_percentage + '% + $199' : 
                                                                                    (state.potential_referral_fee_percentage) ? state.potential_referral_fee_percentage + '%'
                                                                                    : 'N/A'
                                                                                }
                                                                                

                                                                            </p>

                                                                        </li>
                                                                        <li>
                                                                            <p className="title inner-title">Referral Fee </p>
                                                                            <p className="sub-title">
                                                                                {(state.potential_referral_fee) ? <Currency locale="en-US" currency="USD">{state.potential_referral_fee}</Currency> : 'N/A'}
                                                                            </p>

                                                                        </li>
                                                                        {/* <li>
                                                                                

                                                                            </li> */}
                                                                    </ul>
                                                                    {(user) && (user.type === 'admin') &&
                                                                        (
                                                                            <ul className="data-list single-type mt-0 percent_list">
                                                                                <li>
                                                                                    <p className="title inner-title">REFR Portion 
                                                                                    </p>
                                                                                    <p className="sub-title">
                                                                                        {(state.refr_plan_commission) ? (parseFloat(state.refr_plan_commission).toFixed(2) === '199.00') ? '$'+state.refr_plan_commission : state.refr_plan_commission+'%'
                                                                                            : 'N/A'}

                                                                                    </p>

                                                                                </li>
                                                                                {/* <li>
                                                                            </li> */}
                                                                                <li>
                                                                                    <p className="title inner-title">REFR Commission </p>
                                                                                    <p className="sub-title">
                                                                                        {(state.potential_refr_earning) ? <Currency locale="en-US" currency="USD">{state.potential_refr_earning}</Currency> : 'N/A'}
                                                                                    </p>

                                                                                </li>

                                                                            </ul>)}
                                                                    {(user) && (user.type === 'admin') &&
                                                                        (
                                                                            <ul className="data-list single-type mt-0 percent_list">
                                                                                <li>
                                                                                    <p className="title inner-title">{(state.referral_created_users_type === 'agent') ? 'Agent Portion' : 'Ambassador Portion'}  </p>
                                                                                    <p className="sub-title">
                                                                                        {(state.agent_plan_commission) ? state.agent_plan_commission + '%'
                                                                                            : 'N/A'}

                                                                                    </p>

                                                                                </li>
                                                                                {/* <li>
                                                                                

                                                                            </li> */}
                                                                                <li>
                                                                                    <p className="title inner-title">{(state.referral_created_users_type === 'agent') ? 'Agent Commission' : 'Ambassador Commission'} </p>
                                                                                    <p className="sub-title">
                                                                                        {(state.potential_user_earning) ? <Currency locale="en-US" currency="USD">{state.potential_user_earning}</Currency> : 'N/A'}
                                                                                    </p>

                                                                                </li>

                                                                            </ul>)}
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                </div>
                                            </div>)}
                                            {
                                                (state.sales_price) && (<div className="card-groups">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <Card>
                                                                <Card.Header>
                                                                    <div className="row">
                                                                        <div className="col-md-6" >
                                                                            <p className="title">{
                                                                                (isClosedStage) ? 'Closed Commission' : 'Pending Commission'
                                                                            }</p>
                                                                        </div>
                                                                        {(user) && (user.type === 'admin') && (state.sales_price) &&
                                                                            (<div className="col-md-6 iconcls" >
                                                                                <a href='javascript:void(0)' onClick={(e) => handleEditDataForCommission()}>
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_3722_21796)">
                                                                                            <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_3722_21796">
                                                                                                <rect width="20" height="20" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </a>
                                                                            </div>)}
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <div className="card-container">
                                                                        <ul className="data-list single-type mt-0 pb-lg-4">
                                                                            <li>
                                                                                <p className="title inner-title">Property Address </p>
                                                                                <p className="sub-title">
                                                                                    {(state.property_address) ? state.property_address : 'N/A'}
                                                                                </p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="title inner-title">Closing date </p>
                                                                                <p className="sub-title">
                                                                                    {(state.closing_date) ? state.closing_date : 'N/A'}
                                                                                </p>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="data-list single-type mt-0 pb-lg-4">
                                                                            <li>
                                                                                <p className="title inner-title">
                                                                                    Sale price
                                                                                </p>
                                                                                <p className="sub-title">{(state.sales_price) ? <Currency locale="en-US" currency="USD">{state.sales_price}</Currency> : 'N/A'}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="title inner-title">Commission Percentage </p>
                                                                                <p className="sub-title">
                                                                                    {(state.commission) ? state.commission + '%' : 'N/A'}
                                                                                </p>

                                                                            </li>
                                                                            <li>
                                                                                <p className="title inner-title"> Total Commission  </p>
                                                                                <p className="sub-title">
                                                                                    {(state.pending_commission) ? <Currency locale="en-US" currency="USD">{state.pending_commission}</Currency> : 'N/A'}
                                                                                </p>

                                                                            </li>

                                                                        </ul>
                                                                        <ul className="data-list single-type mt-0 pb-lg-4">
                                                                            <li>
                                                                                <p className="title inner-title">REFR Referral Fee  </p>
                                                                                <p className="sub-title">
                                                                                    {
                                                                                        (state.referral_fee_percentage) && (state.referral_fee_user_receive) && 
                                                                                        (state.referring_agent_referral_fee_action === 'yes') 
                                                                                        ? state.referral_fee_percentage + '% + $199' : 
                                                                                        (state.referral_fee_percentage) ? state.referral_fee_percentage + '%'
                                                                                        : 'N/A'
                                                                                    }
                                                                                </p>

                                                                            </li>
                                                                            <li>
                                                                                <p className="title inner-title">Referral Fee Due </p>
                                                                                <p className="sub-title">
                                                                                    {(state.referral_fee) ? <Currency locale="en-US" currency="USD">{state.referral_fee}</Currency> : 'N/A'}
                                                                                </p>

                                                                            </li>
                                                                            {/* <li>
                                                                            </li> */}
                                                                        </ul>
                                                                        {
                                                                            (isClosedStage) && (<ul className="data-list single-type mt-0 pb-lg-4">

                                                                                <li>
                                                                                    <p className="title inner-title">Referral Fee Received </p>
                                                                                    <p className="sub-title">
                                                                                        {(state.referral_fee_received) ? <Currency locale="en-US" currency="USD">{state.referral_fee_received}</Currency> : 'N/A'}
                                                                                    </p>

                                                                                </li>
                                                                                {
                                                                                    (state.referral_created_users_type == 'ambassador') && (<li>
                                                                                        <p className="title inner-title">Brokerage Name </p>
                                                                                        <p className="sub-title">
                                                                                            {(state.referring_agent_brokerage_name) ? Capitalize(state.referring_agent_brokerage_name) : 'N/A'}
                                                                                        </p>

                                                                                    </li>)
                                                                                }
                                                                                {
                                                                                    (state.referral_created_users_type === 'agent') && (<li>
                                                                                        <p className="title inner-title">Agent Plan at Time of submission </p>
                                                                                        <p className="sub-title">
                                                                                            {(state.agent_plan_at_time_of_submission) ? state.agent_plan_at_time_of_submission + '%' : 'N/A'}
                                                                                        </p>

                                                                                    </li>)
                                                                                }
                                                                                <li>
                                                                                    <p className="title inner-title">{(state.referral_created_users_type === 'agent') ? 'Agent Commission' : 'Brokerage Commission'} Due </p>
                                                                                    <p className="sub-title">
                                                                                        {(state.agent_due) ? <Currency locale="en-US" currency="USD">{state.agent_due}</Currency> : 'N/A'}
                                                                                    </p>

                                                                                </li>

                                                                            </ul>)
                                                                        }
                                                                        {(user) && (user.type === 'admin') &&
                                                                            (
                                                                                <ul className="data-list single-type mt-0 percent_list">
                                                                                    <li>
                                                                                        <p className="title inner-title">REFR Portion  </p>
                                                                                        <p className="sub-title">
                                                                                            
                                                                                            {(state.refr_plan_commission_percentage) ? (parseFloat(state.refr_plan_commission_percentage).toFixed(2) === '199.00') ? '$'+state.refr_plan_commission_percentage : state.refr_plan_commission_percentage+'%'
                                                                                            : 'N/A'}

                                                                                        </p>

                                                                                    </li>
                                                                                    {/* <li>
                                                                            </li> */}
                                                                                    <li>
                                                                                        <p className="title inner-title">REFR Commission </p>
                                                                                        <p className="sub-title">
                                                                                            {(state.pending_refr_plan_commission) ? <Currency locale="en-US" currency="USD">{state.pending_refr_plan_commission}</Currency> : 'N/A'}
                                                                                        </p>

                                                                                    </li>

                                                                                </ul>)}
                                                                        {(user) && (user.type === 'admin') &&
                                                                            (
                                                                                <ul className="data-list single-type mt-0 percent_list">
                                                                                    <li>
                                                                                        <p className="title inner-title">{(state.referral_created_users_type === 'agent') ? 'Agent Portion' : 'Ambassador Portion'} </p>
                                                                                        <p className="sub-title">
                                                                                            {(state.agent_plan_commission_percentage) ? state.agent_plan_commission_percentage + '%'
                                                                                                : 'N/A'}

                                                                                        </p>

                                                                                    </li>
                                                                                    {/* <li>
                                                                                

                                                                            </li> */}
                                                                                    <li>
                                                                                        <p className="title inner-title">{(state.referral_created_users_type === 'agent') ? 'Agent' : 'Ambassador'} Commission </p>
                                                                                        <p className="sub-title">
                                                                                            {(state.pending_agent_plan_commission) ? <Currency locale="en-US" currency="USD">{state.pending_agent_plan_commission}</Currency> : 'N/A'}
                                                                                        </p>

                                                                                    </li>

                                                                                </ul>)}
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }

                                            {
                                                (clientCancelledReasonDB) && (<div className="card-groups">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <Card>
                                                                <Card.Header>
                                                                    <p className="title">Client Cancelled Information</p>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <div className="card-container">
                                                                        <ul className="data-list single-type mt-0 pb-lg-4">
                                                                            <li>
                                                                                <p className="title inner-title">
                                                                                    Cancelled Date
                                                                                </p>
                                                                                <p className="sub-title">{clientCancelledDateDB}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="title inner-title">
                                                                                    Cancelled By
                                                                                </p>
                                                                                <p className="sub-title">{clientCancelledByDB}</p>
                                                                            </li>

                                                                        </ul>
                                                                        <ul className="data-list single-type mt-0 pb-lg-4 full_space">
                                                                            <li>
                                                                                <p className="title inner-title">
                                                                                    Reason for Client Cancelled
                                                                                </p>
                                                                                <p className="sub-title">{clientCancelledReasonDB}</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }
                                            {
                                                (noLongerWorkingWithClientReasonDB) && (<div className="card-groups">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <Card>
                                                                <Card.Header>
                                                                    <p className="title">No longer working with client Information</p>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <div className="card-container">
                                                                        <ul className="data-list single-type mt-0 pb-lg-4 half_space">
                                                                            <li>
                                                                                <p className="title inner-title">
                                                                                    No longer working with client Date
                                                                                </p>
                                                                                <p className="sub-title">{noLongerWorkingWithClientDateDB}</p>
                                                                            </li>
                                                                            <li>
                                                                                <p className="title inner-title">
                                                                                    No longer working with client By
                                                                                </p>
                                                                                <p className="sub-title">{noLongerWorkingWithClientByDB}</p>
                                                                            </li>

                                                                        </ul>
                                                                        <ul className="data-list single-type mt-0 pb-lg-4 full_space">
                                                                            <li>
                                                                                <p className="title inner-title">
                                                                                    Reason for no longer working with client
                                                                                </p>
                                                                                <p className="sub-title">{noLongerWorkingWithClientReasonDB}</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }
                                            {
                                                (state.referral_paid_json.length > 0) && (user) && (user.type === 'admin') && (<div className="card-groups">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <Card>
                                                                <Card.Header>
                                                                    <p className="title">Referral Paid </p>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <div className="card-container">
                                                                        {
                                                                            (state.referral_paid_json.map((x, i) => {
                                                                                let objectDate = new Date(x.cheque_date);

                                                                                let day = objectDate.getDate();
                                                                                let month = objectDate.getMonth() + 1;
                                                                                let year = objectDate.getFullYear();
                                                                                if (day < 10) {
                                                                                    day = '0' + day;
                                                                                }
                                                                                if (month < 10) {
                                                                                    month = `0${month}`;
                                                                                }

                                                                                let matches = x.name.match(/\b(\w)/g);
                                                                                let shortName = matches.join('');
                                                                                return (
                                                                                    <>
                                                                                        <ul key={i} className="data-list single-type mt-0 pb-lg-4">
                                                                                            <li>
                                                                                                <p className="title inner-title">
                                                                                                    {(state.referral_created_users_type === 'agent') ? 'Agent' : 'Brokerage'} Name
                                                                                                </p>
                                                                                                <p className="sub-title">{x.name}</p>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p className="title inner-title">Check Amount </p>
                                                                                                <p className="sub-title">
                                                                                                    <Currency locale="en-US" currency="USD">{x.amount}</Currency>
                                                                                                </p>

                                                                                            </li>
                                                                                            <li>
                                                                                                <p className="title inner-title">Check Date </p>
                                                                                                <p className="sub-title">
                                                                                                    {month + '/' + day + '/' + year}
                                                                                                </p>

                                                                                            </li>
                                                                                        </ul>
                                                                                        <ul className="data-list single-type mt-0">
                                                                                            <li>
                                                                                                <p className="title inner-title">Check # </p>
                                                                                                <p className="sub-title">
                                                                                                    {x.cheque_number}
                                                                                                </p>

                                                                                            </li>
                                                                                        </ul>
                                                                                    </>

                                                                                )
                                                                            }))
                                                                        }

                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }

                                            {
                                                (state.admin_notes_json.length > 0) && (user) && (user.type === 'admin') && (<div className="card-groups">
                                                    <div className="row">
                                                        <div className="col-lg-12 card_header_notes">
                                                            <Card>
                                                                <Card.Header>
                                                                    <p className="title">Admin Notes </p>
                                                                    <p className="sub-title">
                                                                        {(state.admin_notes_json[0].notes_updated_date) ? getFormattedDate(state.admin_notes_json[0].notes_updated_date) : 'N/A'}
                                                                    </p>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <div className="card-container">
                                                                        {
                                                                            (state.admin_notes_json.map((x, i) => {
                                                                                return (
                                                                                    <>

                                                                                        <ul key={i} className="data-list single-type mt-0 full_space">
                                                                                            <li>
                                                                                                <p className="title inner-title">Notes </p>
                                                                                                <p className="sub-title">
                                                                                                    {(x.notes) ? x.notes : 'N/A'}
                                                                                                </p>

                                                                                            </li>
                                                                                        </ul>
                                                                                    </>

                                                                                )
                                                                            }))
                                                                        }

                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }

                                            {
                                                (user) && (user.type === 'admin') && (<div className="card-groups">
                                                    <div className="row">

                                                        <div className="col-lg-12  card_header_notes ref_doc">
                                                            <Card>
                                                                <Card.Header>

                                                                    <div className="row">
                                                                        <div className="col-md-6" >
                                                                            <p className="title">Referral Documents </p>
                                                                        </div>
                                                                        <div className="col-md-6 iconcls" >
                                                                            <a href='javascript:void(0)' onClick={(e) => handleReferralDocumentsUpload()}>
                                                                                <img
                                                                                    src="../assets/images/Submit_referral_mob_icon.svg"
                                                                                    alt=""
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    {
                                                                        (referralDocumentsLogDetail.length === 0 && (!agentContract)) && (<div className="no-agent-select">
                                                                            <p>No document uploaded</p>
                                                                        </div>)
                                                                    }
                                                                    {

                                                                        (<div className="card-container">

                                                                            {
                                                                                (agentContract) && (

                                                                                    <ul className="data-list single-type mt-0 full_space">
                                                                                        <li>
                                                                                            <p className="title inner-title">Referral Fee Document </p>
                                                                                            <p className="sub-title">
                                                                                                {(agentContractName) ? agentContractName : 'N/A'}
                                                                                            </p>
                                                                                            {
                                                                                                (agentContract) && (<a href={agentContract} target="_blank" className="btn outlined-btn">Preview</a>)
                                                                                            }
                                                                                            {
                                                                                                (agentContract) && (<a href='javascript:;' className="btn outlined-btn delete_btn" onClick={() => { handleDeleteDocuments(requestReferralId, 'referral_fee_documents', agentContract) }}>Delete</a>)
                                                                                            }
                                                                                        </li>
                                                                                    </ul>
                                                                                )
                                                                            }
                                                                            {(referralDocumentsLogDetail.length > 0) && (referralDocumentsLogDetail.map((x, i) => {

                                                                                return (
                                                                                    <>
                                                                                        <ul key={i} className="data-list single-type mt-0 full_space">
                                                                                            <li>
                                                                                                <p className="title inner-title">Document {i + 1}</p>
                                                                                                <p className="sub-title">
                                                                                                    {(x.media_file) ? x.media_file : 'N/A'}
                                                                                                </p>
                                                                                                {
                                                                                                    (x.media_file) && (<a href={x.media_file_full_path} target="_blank" className="btn outlined-btn">Preview</a>)
                                                                                                }
                                                                                                {
                                                                                                    (x.media_file) && (<a href='javascript:;' className="btn outlined-btn delete_btn" onClick={() => { handleDeleteDocuments(x.id, 'referral_documents', x.media_file) }}>Delete</a>)
                                                                                                }
                                                                                            </li>
                                                                                        </ul>
                                                                                    </>
                                                                                )
                                                                            }))
                                                                            }
                                                                        </div>)}
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                    </Card.Body>
                                    <Card.Footer>
                                        <div className="text-end">
                                            {
                                                (user) && (user.type === 'admin') && (<button className="btn btn-submit me-3" type="button" onClick={(e) => setShowReferralFeeReceivedForm(true)}>Add Referral Fee Received</button>)
                                            }

                                            {
                                                (user) && (user.type === 'admin') && (<button className="btn btn-submit me-3" type="button" onClick={(e) => setShowAdminNotesForm(true)}>Admin Notes</button>)
                                            }


                                            <NavLink
                                                to={((user) && (user.type === 'admin')) ? '/app/referral-list-for-admin' : '/app/referral-list'}
                                                className="btn btn-cancel btn-delete-view"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Back
                                            </NavLink>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </div>
                        </div>

                    </section>
                    {
                        (showAssignedAgent) && (<AssignedAgent showAssignedAgent={showAssignedAgent} selectAgentCallFrom={selectAgentCallFrom} changeStatuAssignedAgent={changeStatuAssignedAgent} referralRowId={requestReferralId} />)
                    }

                    {
                        (showSendContract) && (<SendContractToAgent showSendContract={showSendContract} changeStatuSendContractToAgent={changeStatuSendContractToAgent} referralRowId={requestReferralId} loginUserId={user.id} />)
                    }
                    {
                        (showUnderContractStatusForm) && (<UnderContractStatusForm showUnderContractStatusForm={showUnderContractStatusForm} referralFeeReceivedDB={referralFeeReceivedDB} isUnderContractEditCall={isUnderContractEditCall} changeStatuUnderContractForm={changeStatuUnderContractForm} referralRowId={requestReferralId} loginUserId={user.id} loginUserName={user.name} referralUsersId={referralUsersId} referralCommissionLogDetail={referralCommissionLogDetail} referraSettingDetail={referraSettingDetail} referraDetail={referraDetail} referredAgentUsersId={referredAgentUsersId} />)
                    }
                    {
                        (showPotentialDataEditForm) && (<PotentialCommissionEditForm showPotentialDataEditForm={showPotentialDataEditForm} changeStatuEditPotentialCommissionForm={changeStatuEditPotentialCommissionForm} referralRowId={requestReferralId} loginUserId={user.id} loginUserName={user.name} referralUsersId={referralUsersId} referralCommissionLogDetail={referralCommissionLogDetail} referraDetail={referraDetail} referraSettingDetail={referraSettingDetail} referredAgentUsersId={referredAgentUsersId} />)
                    }

                    {
                        (showClosedStatusForm) && (<ClosedStatusForm showClosedStatusForm={showClosedStatusForm} changeStatuClosedForm={changeStatuClosedForm} referralRowId={requestReferralId} loginUserId={user.id} loginUserName={user.name} referralUsersId={referralUsersId} referralCommissionLogDetail={referralCommissionLogDetail} referraDetail={referraDetail} referraSettingDetail={referraSettingDetail} referredAgentUsersId={referredAgentUsersId} />)
                    }
                    {
                        (showClientReferred) && (<ClientReferred showClientReferred={showClientReferred} changeStatuClosedhandleModal={changeStatuClosedhandleModal} editReferralId={requestReferralId} loginUserId={user.id} loginUserName={user.name} referredAgentUsersId={referredAgentUsersId} dbPeopleData={dbPeopleData} dbClientReferenceData={dbClientReferenceData} />)
                    }
                    {
                        (showAssignedAgentModal) && (<AssignedAgentModal showAssignedAgentModal={showAssignedAgentModal} changeStatuClosedhandleModalAssignedAgent={changeStatuClosedhandleModalAssignedAgent} editReferralId={requestReferralId} requestReferralId={requestReferralId} loginUserId={user.id} loginUserName={user.name} referredAgentUsersId={referredAgentUsersId} referraDetail={referraDetail} />)
                    }
                    {
                        (showReferralData) && (<ReferralData showReferralData={showReferralData} changeStatuClosedhandleModalReferralData={changeStatuClosedhandleModalReferralData} editReferralId={requestReferralId} loginUserId={user.id} loginUserName={user.name} referredAgentUsersId={referredAgentUsersId} propertyBuyerAreaJson={propertyBuyerAreaJson} propertyBuyerAreaErrorJson={propertyBuyerAreaErrorJson} />)
                    }
                    {
                        (showReferralPaidStatusForm) && (<ReferralPaidStatusForm showReferralPaidStatusForm={showReferralPaidStatusForm} changeStatuReferralPaidForm={changeStatuReferralPaidForm} referralRowId={requestReferralId} referralPaidJsonDB={referralPaidJsonDB} referralCreatedUserType={referralCreatedUserType} />)
                    }
                    {
                        (showClientCancelledStatusForm) && (<ClientCancelledStatusForm showClientCancelledStatusForm={showClientCancelledStatusForm} changeStatuClientCancelledForm={changeStatuClientCancelledForm} referralRowId={requestReferralId} clientCancelledReasonDB={clientCancelledReasonDB} noLongerWorkingWithClientReasonDB={noLongerWorkingWithClientReasonDB} loginUserId={user.id} loginUserName={user.name} callFrom={callFrom} />)
                    }
                    {
                        (showAdminNotesForm) && (<AdminNotesForm showAdminNotesForm={showAdminNotesForm} changeStatuAdminNotesForm={changeStatuAdminNotesForm} referralRowId={requestReferralId} adminNotesJsonDB={adminNotesJsonDB} />)
                    }
                    {
                        (showReferralFeeReceivedForm) && (<ReferralFeeReceivedForm showReferralFeeReceivedForm={showReferralFeeReceivedForm} changeStatusReferralFeeReceivedFormForm={changeStatusReferralFeeReceivedFormForm} referralRowId={requestReferralId} referralFeeReceivedDB={referralFeeReceivedDB} />)
                    }
                    {
                        (showUploadDocs) && (<UploadDocuments showUploadDocs={showUploadDocs} changeStatuUpload={changeStatuUpload} reloadListing={reloadListing} editRowId={editRowId} editRowType='' dbUserFullName='' callFrom={callFrom} is_profile_page_call={0} />)
                    }
                </>


            );
        default:
            return <> Error! </>;
    }
};
