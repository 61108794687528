import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import * as XLSX from 'xlsx';
import { CircularProgress,Typography} from '@mui/material';
import Swal from 'sweetalert2';
import axios from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";

const UploadFileCsv = (props) => {
    const [show, setShow] = useState(props.showUploadCsv);
    const [excelData, setExcelData] = useState([]);
    const handleCloseCsv = () => setShow(false);
    const [isFile, setIsFile] = useState(false);
    const [fileName, setFileName] = useState('');
    const [validMessage, setValidMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { logout } = useAuth();
    const fileTypes = ["csv", "xlsx"];
    const navigate = useNavigate();
    const handleImport = (file) => {
        const fileSize = file.size / 1024 / 1024; // in MiB
        let splitName = file.type.split('/');
        console.log('file.type ' + file.type);
        console.log('splitName ' + splitName[1]);
        setValidMessage('');
        if (fileSize > 1) {
            //alert('File size exceeds 1 MiB');
            setIsFile(false);
            setFileName('')
            setValidMessage('Please upload csv file not more than 1 MB')
        } else if (splitName[1] !== 'csv') {
            //Swal.fire(file.name+' has an invalid extension. Valid extension(s): bmp, gif, jpeg, jpg, jpe, jfif, png, webp.');
            Swal.fire({
                title: '',
                text: file.name + " has an invalid extension.     Valid extension(s):csv",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
            }).then((result) => {

            })
            return false;
        }
        else {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);

                setIsFile(true);
                setFileName(file.name)
                setExcelData(JSON.stringify(data));
            };
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(isFile)
        {
            setLoading(true);
            let formData = new FormData();

            formData.append('data', excelData);
            formData.append('users_id',props.userId);

            try {
                const response = await axios.post('/csv-import-people', formData);
                const { Status, StatusMessage, Error, Result } = response.data;
                let finalApiError = '';
                setLoading(false);
                if (Status === false) {
                  if (Error.length > 0) {
                    Error.map(
                      (errorMessage) =>
                        //finalApiError += '<li>'+errorMessage+'</li>'
                        (finalApiError = errorMessage)
                    );
                  } else {
                    finalApiError = StatusMessage;

                  }
                  setErrorMessage(finalApiError);
                  setValidMessage(finalApiError)
                //   const timer = setTimeout(() => {
                //     handleCloseCsv();
                //     props.changeStatusCsvUpload();
                //     props.reloadListing();
                //    }, 6000);
                //    return () => clearTimeout(timer);

                } else {
                    setLoading(false);
                    handleCloseCsv();
                    props.changeStatusCsvUpload();
                    props.reloadListing();
                }
              } catch (err) {

                setLoading(false);
                if(err.response.status && err.response.status==401){
                    logout();
                    navigate('/login');
                }else{
                    setValidMessage('Something went wrong. Please try after sometime.')
                }
            }
        }
        else
        {
            setValidMessage('Please upload csv file')
        }
    }
    return (
        <>
            <Modal className='upload-popup' show={show} onHide={handleCloseCsv}backdrop="static">
                <form onSubmit={handleSubmit}>
                    <Modal.Header className='upload-header'>
                        <Modal.Title>Upload CSV File</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='upload-section'>
                        <FileUploader
                            handleChange={handleImport}
                            name="file"
                            types={fileTypes}
                            // label= "Drag & drop or, browse your file"
                            children= <div className='upload-files-csv'>
                                <div className='upload-file-box'>
                                    <p className='title'><img src="../assets/images/csv-file-icon.png" alt="" /> Drag & drop or, <span>browse</span>  your file</p>
                                    <p className='sub-title'>(CSV file only)</p>
                                </div>
                                {
                                    (fileName) && (<p>File Name {fileName}</p>)
                                }
                            </div>
                        />
                        {
                            (validMessage) && (<small className="p-error">{validMessage}</small>)
                        }
                    </Modal.Body>
                    <Modal.Footer className='file-upload-footer'>
                        <Button className='btn-cancel btn-500' variant="" onClick={() => {handleCloseCsv(); props.changeStatusCsvUpload();}} disabled={loading}>
                          Cancel
                        </Button>
                        <Button type="submit" className='btn-submit btn-submit-loader btn-500' variant="" disabled={loading}>
                        Upload
                        {loading && (<Typography align="center">
                            <CircularProgress value={66} />
                        </Typography>)}
                        </Button>

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default UploadFileCsv;
