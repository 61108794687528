import React, { useReducer,  useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import axios from "../../../axiosCall.js";
import useAuth from "./../../../hooks/useAuth";
import { useStep } from "react-hooks-helper";
import { Card } from "react-bootstrap";

import { Alert, CircularProgress, Typography } from "@mui/material";
import { classNames } from 'primereact/utils';
import { Dropdown, InputText, RadioButton, InputTextarea, Avatar } from "primereact";
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import { useFormik } from "formik";
import { Number, Currency } from "react-intl-number-format"

const ReferralData = (props) => {
    const [show, setShow] = useState(props.showReferralData);

    const navigate = useNavigate();
    const peopleModalClose = () => setShow(false);
    const { user, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [dbStatesName, setDbStatesName] = useState([]);
    const [referral_type, setReferralType] = useState();
    const [buyer_or_seller, setBuyerOrSeller] = useState();
    const [isDisabled, setIsDisabled] = useState(false);

    const [dbTimeframeData, setDbTimeframeData] = useState([]);
    const [dbPriceRangeMinData, setDbPriceMinRangeData] = useState([]);
    const [dbPriceRangeMaxData, setDbPriceMaxRangeData] = useState([]);
    const [dbPropertyTypeData, setDbPropertyTypeData] = useState([]);
    const [dbClientsLookingToPurchase, setDbClientsLookingToPurchase] = useState([]);
    const [isShowBuyerRefferal, setIsShowBuyerRefferal] = useState(false);
    const [isShowSellerRefferal, setIsShowSellerRefferal] = useState(false);
    const [inputList, setInputList] = useState(props.propertyBuyerAreaJson);
    const [errorText, setErrorText] = useState(props.propertyBuyerAreaErrorJson);



    const fetchReferralDetailData = async () => {
        let formData = new FormData();
        formData.append("referral_id", props.editReferralId);

        try {
            const response = await axios.post("/get-referral-data", formData);
            const { Status, Result } = response.data;

            if (Status === true) {
                setReferralType(Result.referral.referral_type);
                setBuyerOrSeller(Result.referral.buyer_or_seller);
                var arr = [];
                let buy_property_area_json = [
                    {
                        buy_city: "",
                        buy_zipcode: "",
                    }
                    ];

                if(Result.referral.referral_type == 'buyer_referral')
                {
                    const countAll = JSON.parse(Result.referral.buy_property_area_json).length;
                    //alert(countAll);

                    for (var i = 0; i < countAll; i++) {
                        arr.push({
                            buy_city_error: "",
                            buy_zipcode_error: "",
                        });
                    }
                    buy_property_area_json = JSON.parse(Result.referral.buy_property_area_json);
                }
                else if(Result.referral.referral_type == 'seller_referral')
                {
                    const countAll = 1;
                    //alert(countAll);
                    for (var i = 0; i < countAll; i++) {
                        arr.push({
                            buy_city_error: "",
                            buy_zipcode_error: "",
                        });
                    }
                }
                else
                {
                    if(Result.referral.buyer_or_seller === 'buyer_referral')
                    {
                        const countAll = JSON.parse(Result.referral.buy_property_area_json).length;
                        //alert(countAll);

                        for (var i = 0; i < countAll; i++) {
                            arr.push({
                                buy_city_error: "",
                                buy_zipcode_error: "",
                            });
                        }
                        buy_property_area_json = JSON.parse(Result.referral.buy_property_area_json);
                    }
                    else
                    {
                        const countAll = 1;
                        //alert(countAll);
                        for (var i = 0; i < countAll; i++) {
                            arr.push({
                                buy_city_error: "",
                                buy_zipcode_error: "",
                            });
                        }
                    }
                }
                formik.setFieldValue("property_area_multi_info", buy_property_area_json);
                formik.setFieldValue("property_error_multi_info", arr);
                formik.setFieldValue("property_master_id",(Result.referral.property_master_id) ? Result.referral.property_master_id : '');
                formik.setFieldValue("seller_property_master_id",(Result.referral.seller_property_master_id) ? Result.referral.seller_property_master_id : '');

                formik.setFieldValue("clients_looking_to_purcahse_master_id", (Result.referral.clients_looking_to_purcahse_master_id) ? Result.referral.clients_looking_to_purcahse_master_id : '');
                formik.setFieldValue("buy_state_id",(Result.referral.buy_state_id) ? Result.referral.buy_state_id : '');
                formik.setFieldValue("buyer_buy_timeframe_id", (Result.referral.buyer_buy_timeframe_id) ? Result.referral.buyer_buy_timeframe_id : '');
                formik.setFieldValue("seller_buy_timeframe_id", (Result.referral.seller_buy_timeframe_id) ? Result.referral.seller_buy_timeframe_id : '');
                formik.setFieldValue("buyer_min_price_range_id", (Result.referral.buyer_min_price_range_id) ? Result.referral.buyer_min_price_range_id : '');
                formik.setFieldValue("buyer_max_price_range_id",(Result.referral.buyer_max_price_range_id) ? Result.referral.buyer_max_price_range_id : '');
                formik.setFieldValue("seller_estimated_price", (Result.referral.seller_estimated_price) ? Result.referral.seller_estimated_price : '');
                formik.setFieldValue("buyer_comments", (Result.referral.buyer_comments) ? Result.referral.buyer_comments : '');
                formik.setFieldValue("seller_comments", (Result.referral.seller_comments) ? Result.referral.seller_comments : '');
                formik.setFieldValue("seller_address", (Result.referral.seller_address) ? Result.referral.seller_address : '');
                formik.setFieldValue("seller_state_id", (Result.referral.seller_state_id) ? Result.referral.seller_state_id : '');
                formik.setFieldValue("seller_city",(Result.referral.seller_city) ? Result.referral.seller_city : '');
                formik.setFieldValue("seller_zipcode", (Result.referral.seller_zipcode) ? Result.referral.seller_zipcode : '');
                

            } else {
                navigate("/dashboard");
            }
        } catch (err) {
            // if (err.response.status && err.response.status == 401) {
            //     logout();
            //     navigate("/login");
            // }
        }
    };
    useEffect(() => {
        if (user !== null && props.editReferralId) {
            fetchReferralDetailData();

           // console.log(referral_type);
        }

    }, [user]);

    useEffect(() => {

        if (props.editReferralId) {
            setIsDisabled(true);
        }
        else {
            setIsDisabled(false);
        }


    }, [referral_type]);

    const handleSubmit = async (data) => {
        if (referral_type === "") return;

        if(data.seller_estimated_price)
            data.seller_estimated_price =  data.seller_estimated_price.replaceAll(',', '');
            
        setLoading(true);
        setErrorMessage('');
        try {
            const response = await axios.post('/referral-property-data-update', data);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';
            setLoading(false);
            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
                window.scrollTo(0, 0);
            } else {
                setLoading(false);
                props.changeStatuClosedhandleModalReferralData();
                peopleModalClose();
            }
        } catch (err) {

            setLoading(false);
            window.scrollTo(0, 0);
            if (err.response.status && err.response.status == 401) {
                logout();
                navigate('/login');
            }
        }

    };

    useEffect(() => {
        //alert(' state.property_error_multi_info '+JSON.stringify(state.property_error_multi_info));

        if(props.editReferralId)
        {
            //alert(state.seller_state_id)
            if(buyer_or_seller == 'seller_referral')
            {
                setIsShowBuyerRefferal(false)
                setIsShowSellerRefferal(true);
            }
            else
            {
                setIsShowBuyerRefferal(true)
                setIsShowSellerRefferal(false);
            }
        }
        else
        {
            if (referral_type === "buyer_referral") {
                setIsShowBuyerRefferal(true)
                setIsShowSellerRefferal(false);
            }
            else if (referral_type === "seller_referral") {
                setIsShowBuyerRefferal(false)
                setIsShowSellerRefferal(true);
            }
            else
            {
                setIsShowBuyerRefferal(true)
                setIsShowSellerRefferal(true);
            }

        }
    }, [referral_type,isShowBuyerRefferal]);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);

        const errorList = [...errorText];
        if (name === "buy_city") {
            errorList[index]["buy_city_error"] = "";
            errorList[index]["buy_zipcode_error"] = "";
        } else if (name === "buy_zipcode") {
            errorList[index]["buy_city_error"] = "";
            errorList[index]["buy_zipcode_error"] = "";
        }
        setErrorText(errorList);
        formik.setFieldValue("property_area_multi_info", list);
        formik.setFieldValue("property_error_multi_info", errorList);
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);

        formik.setFieldValue("property_area_multi_info", list);

        const errorList = [...errorText];
        errorList.splice(index, 1);
        setErrorText(errorList);
        formik.setFieldValue("property_error_multi_info", errorList);
    };


    useEffect(() => {}, [errorText]);
    // handle click event of the Add button
const handleAddClick = () => {
    setInputList([
        ...inputList,
        {
            buy_city: "",
            buy_zipcode: "",
        },
    ]);
//alert(JSON.stringify(errorText))
    setErrorText([
        ...errorText,
        {
            buy_city_error: "",
            buy_zipcode_error: "",
        },
    ]);
};
    useEffect(() => {
        const fetchStatesData = async () => {
            try {
                const response = await axios.post("/get-all-states");
                const { Status, StatusMessage, Error, Result } = response.data;

                if (Status === true) {
                    //alert(Result)
                    setDbStatesName(Result);
                }
            } catch (err) {
                //console.log(err);
            }
        };
        fetchStatesData();
    }, []);

    const fetchTimeFrameData = async () => {
        try {
            const response = await axios.post("/get-all-timeframe-data");
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                //alert(Result)
                setDbTimeframeData(Result);
            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
              }
        }
    };
    const fetchPriceRangeData = async () => {
        try {
            const response = await axios.post("/get-all-pricerange-data");
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                //alert(Result)

                let minArray = Result.filter(function (el)
                    {
                        return el.is_min === 1;
                    }
                );

                let maxArray = Result.filter(function (el)
                    {
                        return el.is_max === 1;
                    }
                );
                setDbPriceMinRangeData(minArray);
                setDbPriceMaxRangeData(maxArray);
            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
              }
        }
    };
    const fetchPropertyTypeData = async () => {
        try {
            const response = await axios.post(
                "/get-all-property-type-data"
            );
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                //alert(Result)
                setDbPropertyTypeData(Result);
            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
              }
        }
    };
    const fetchClientsLookingToPurchaseData = async () => {
        try {
            const response = await axios.post(
                "/get-all-clients-looking-to-purcahse-master-data"
            );
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                //alert(Result)
                setDbClientsLookingToPurchase(Result);
            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
              }
        }
    };
    useEffect(() => {
        if (user !== null) {
            fetchTimeFrameData();
            fetchPriceRangeData();
            fetchPropertyTypeData();
            fetchClientsLookingToPurchaseData();
        }
    }, [user]);

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };



    const selectedTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };
    const optionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    };
    function currencyFormat(num) {
        let finalNum = num.split('$');
        let tempNum = parseFloat(finalNum[1]) ;
        return  <Currency locale="en-US" currency="USD" minimumFractionDigits="0" maximumFractionDigits="0">{tempNum}</Currency>
    }
    const selectedTemplatePriceRange = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{currencyFormat(option.name)}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };
    const optionTemplatePriceRange = (option) => {
        return (
            <div className="country-item">
                <div>{currencyFormat(option.name)}</div>
            </div>
        );
    };

    const selectedStateTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.abbrv}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };
    const stateOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.abbrv}</div>
            </div>
        );
    };

    const formik = useFormik({
        initialValues: {
            users_id: props.loginUserId,
            referral_id: props.editReferralId,
            property_area_multi_info: "",
            property_error_multi_info: "",
            property_master_id: "",
            clients_looking_to_purcahse_master_id: "",
            buy_state_id: "",
            buyer_buy_timeframe_id: "",
            seller_buy_timeframe_id: "",
            buyer_min_price_range_id: "",
            buyer_max_price_range_id: "",
            seller_estimated_price: "",
            seller_property_master_id: "", 
            buyer_comments: "",
            seller_comments: "",
            seller_address:"",
            seller_state_id: "",
            seller_city: "",
            seller_zipcode: "",
        },
        validate: (data) => {
            let errors = {};
            let isValid = true;

            if (isShowBuyerRefferal) {
                const list = [...errorText];

                inputList.map((x, i) => {

                    if (x.buy_city == null || x.buy_city.trim() === "")
                    {
                        if (x.buy_zipcode == null || x.buy_zipcode.trim() === "") {
                            list[i]["buy_city_error"] = "Please enter city";
                            isValid = false;
                        }
                    }
                    if (x.buy_city)
                    {
                        if (x.buy_city.length > 50)
                        {
                            list[i]["buy_city_error"] = 'Max characters: 50';
                            isValid = false;
                        }
                    }
                    if (x.buy_zipcode == null || x.buy_zipcode.trim() === "") {
                        if (x.buy_city == null || x.buy_city.trim() === "") {
                            list[i]["buy_zipcode_error"] = "Please enter zipcode";
                            isValid = false;
                        }
                    } else if (!/^\d{5}(-\d{4})?$/.test(x.buy_zipcode)) {
                        list[i]["buy_zipcode_error"] =
                            "Invalid zipcode. e.g. 00501";
                        isValid = false;
                    }
                });
                setErrorText(list);
            }

            if (
                !data.property_master_id &&
                (isShowBuyerRefferal)
            ){
                errors.property_master_id = "Property type is required.";
            }
            if (
                !data.clients_looking_to_purcahse_master_id &&
                (isShowBuyerRefferal)
            ){
                errors.clients_looking_to_purcahse_master_id = "Clients are looking to purcahse is required.";
            }
            
            if (
                !data.buy_state_id &&
                (isShowBuyerRefferal)
            ){
                errors.buy_state_id = "State is required.";
            }

            if (
                !data.buyer_buy_timeframe_id &&
                (isShowBuyerRefferal)
            ) {
                errors.buyer_buy_timeframe_id = "Time frame is required.";
            }
            if (isShowBuyerRefferal) {
                if(data.buyer_comments)
                {
                    if (data.buyer_comments.length > 500)
                    {
                        errors.buyer_comments = 'Max characters: 500';
                    }
                }
            }
            if (
                !data.buyer_min_price_range_id &&
                (isShowBuyerRefferal)
            ) {
                errors.buyer_min_price_range_id = "Price minimum is required.";
            }

            if (
                !data.buyer_max_price_range_id &&
                (isShowBuyerRefferal)
            ) {
                errors.buyer_max_price_range_id = "Price maximum is required.";
            }
            else if ((data.buyer_min_price_range_id) && (data.buyer_max_price_range_id) &&
                (isShowBuyerRefferal)
            )
            {
                let minprice = data.buyer_min_price_range_id.name.split('$');
                let maxprice = data.buyer_max_price_range_id.name.split('$');

                if(parseFloat(minprice[1]) > parseFloat(maxprice[1]))
                {
                    errors.buyer_max_price_range_id = "Maximum Price must be greater than the minimum price.";
                }
            }
            if (
                (data.seller_address == null ||
                    data.seller_address.trim() === "") &&
                (isShowSellerRefferal)
            ) {
                errors.seller_address = "Street address is required.";
            }else if (data.seller_address.length > 200)
            {
                errors.seller_address = 'Max characters: 200';
            }

            if (
                !data.seller_state_id &&
                (isShowSellerRefferal)
            ) {
                errors.seller_state_id = "State is required.";
            }

            if ((data.seller_city == null || data.seller_city.trim() === "") && (isShowSellerRefferal)
            ) {
                if ((data.seller_zipcode == null || data.seller_zipcode.trim() === "") &&
                (isShowSellerRefferal))
                {
                    errors.seller_city = "City is required.";
                }
            }
            if((data.seller_city) && (isShowSellerRefferal))
            {
                if (data.seller_city.length > 50)
                {
                    errors.seller_city = 'Max characters: 50';
                }
            }

            if (isShowSellerRefferal) {
                if(data.seller_comments)
                {
                    if (data.seller_comments.length > 500)
                    {
                        errors.seller_comments = 'Max characters: 500';
                    }
                }
            }
            if ((data.seller_zipcode == null || data.seller_zipcode.trim() === "") &&
                (isShowSellerRefferal))
            {
                if ((data.seller_city == null || data.seller_city.trim() === "") && (isShowSellerRefferal)
            )   {
                    errors.seller_zipcode = "Zipcode is required.";
                }
            }
            else if (!/^\d{5}(-\d{4})?$/.test(data.seller_zipcode) &&
                (isShowSellerRefferal))
            {
                errors.seller_zipcode = "Invalid zipcode. e.g. 00501";
            }

            if (
                (data.seller_estimated_price !== "") &&
                (isShowSellerRefferal)
            ) {
                if (!/^(\d*([.,](?=\d{2}))?\d+)+((?!\2)[.,]\d\d)?$/.test(data.seller_estimated_price)) {
                    errors.seller_estimated_price = "Invalid price. e.g. 10000";
                }
                else if(parseFloat(data.seller_estimated_price) > 100000000)
                {
                    errors.seller_estimated_price = "Max: 100,000,000";
                }
            }

            if (
                !data.seller_buy_timeframe_id &&
                (isShowSellerRefferal)
            ) {
                errors.seller_buy_timeframe_id = "Time frame is required.";
            }
            //console.log(isValid)
            if (isValid) {
                return errors;
            } else {
                errors.multi_fields = "error";
                return errors;
            }
        },
        onSubmit: (data) => {
            //alert('inisde submit')

            var arr = [];
            const countAll = document.querySelectorAll(".repeaterClass").length;
            //alert(countAll);
            if(countAll > 0)
            {
                for (var i = 0; i < countAll; i++) {
                    arr.push({
                        buy_city_error: "",
                        buy_zipcode_error: "",
                    });
                }
            }
            else
            {
                for (var i = 0; i < 1; i++) {
                    arr.push({
                        buy_city_error: "",
                        buy_zipcode_error: "",
                    });
                }
            }

            handleSubmit(data)

        },
    });

    return (
        <>
            <Modal enforceFocus={false} className='add-people-popup' show={show} onHide={peopleModalClose} backdrop="static" >

                    <Modal.Header className='add-people-header'>
                        <Modal.Title>Update Referral Data </Modal.Title>
                    </Modal.Header>
                    {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                        {errorMessage}
                        </Alert>
                    )}
                     <form onSubmit={formik.handleSubmit}>
                    <Modal.Body className='addpeople-section'>

                   <Card.Body>

                    {(referral_type === "buyer_referral" ||
                        referral_type === "buyer_and_seller_referral") && (isShowBuyerRefferal) && (
                        <div className="referral-fields mb-3">
                            <h2 className="referall-title property">
                                Property Information
                            </h2>
                            <div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="field">
                                            <p className="input-heading">
                                            What type of property are they looking to purchase? <span className="reuired-star">*</span>
                                            </p>

                                            <Dropdown
                                                className={classNames(
                                                    "form-select",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "property_master_id"
                                                            ),
                                                    }
                                                )}
                                                value={formik.values.property_master_id}
                                                options={dbPropertyTypeData}
                                                onChange={formik.handleChange}
                                                optionLabel="name"
                                                name="property_master_id"
                                                filter
                                                filterBy="name"
                                                placeholder="Select a Property Type"
                                                valueTemplate={selectedTemplate}
                                                itemTemplate={optionTemplate}
                                            />
                                            {getFormErrorMessage("property_master_id"
                                                )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="field">
                                            <p className="input-heading">
                                                What state are they looking to
                                                purchase in? <span className="reuired-star">*</span>
                                            </p>

                                            <Dropdown
                                                className={classNames(
                                                    "form-select",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "buy_state_id"
                                                            ),
                                                    }
                                                )}
                                                value={formik.values.buy_state_id}
                                                options={dbStatesName}
                                                onChange={formik.handleChange}
                                                optionLabel="state"
                                                name="buy_state_id"
                                                filter
                                                filterBy="abbrv"
                                                placeholder="Select a State"
                                                valueTemplate={
                                                    selectedStateTemplate
                                                }
                                                itemTemplate={
                                                    stateOptionTemplate
                                                }
                                            />
                                            {getFormErrorMessage(
                                        "buy_state_id"
                                )}



                                        </div>
                                    </div>
                                </div>
                            </div>
                            {inputList.map((x, i) => {
                                return (
                                    <div key={i} className="repeaterClass">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label
                                                        htmlFor="FirstInput"
                                                        className="form-label"
                                                    >
                                                        {(i ===0) ? 'Primary City' : 'Additional City'} <span className="reuired-star">*</span>
                                                    </label>
                                                    <InputText
                                                        autoComplete="off"
                                                        className={classNames(
                                                            "form-control",
                                                            {
                                                                "p-invalid":
                                                                    isFormFieldValid(
                                                                        "buy_city"
                                                                    ),
                                                            }
                                                        )}
                                                        placeholder="Enter here"
                                                        name="buy_city"
                                                        value={x.buy_city}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />
                                                    {(!x.buy_city) && (errorText[i]
                                                        .buy_city_error) && (
                                                        <small className="p-error">
                                                            {
                                                                errorText[i]
                                                                    .buy_city_error
                                                            }
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="field">
                                                    <label
                                                        htmlFor="LastInput"
                                                        className="form-label"
                                                    >
                                                        {(i ===0) ? 'Primary Zipcode' : 'Additional Zipcode'} <span className="reuired-star">*</span>
                                                    </label>
                                                    <InputText
                                                        autoComplete="off"
                                                        className={classNames(
                                                            "form-control",
                                                            {
                                                                "p-invalid":
                                                                    isFormFieldValid(
                                                                        "buy_zipcode"
                                                                    ),
                                                            }
                                                        )}
                                                        placeholder="Enter here"
                                                        name="buy_zipcode"
                                                        value={x.buy_zipcode}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                    />
                                                   {(errorText[i]
                                                        .buy_zipcode_error) && (
                                                        <small className="p-error">
                                                            {
                                                                errorText[i]
                                                                    .buy_zipcode_error
                                                            }
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn-box mb-3">
                                            {inputList.length !== 1 && (
                                                <button
                                                    className="mr10 add-more remove-link me-2"
                                                    onClick={() =>
                                                        handleRemoveClick(i)
                                                    }
                                                >
                                                    Remove
                                                </button>
                                            )}
                                            {inputList.length - 1 === i && (
                                                <button
                                                    onClick={handleAddClick}
                                                    className="add-more"
                                                >
                                                   Add More +
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}

                            {/* <div className="add-more-btn">

                <a href="" className="add-more">Add More +</a>
              </div> */}
                            <div className="row referal-input">
                                <div className="col-lg-6">
                                    <div className="field">
                                        <label
                                            htmlFor="LastInput"
                                            className="form-label"
                                        >
                                            What is the buyer's time frame? <span className="reuired-star">*</span>
                                        </label>
                                        <Dropdown
                                            className={classNames(
                                                "form-select",
                                                {
                                                    "p-invalid":
                                                        isFormFieldValid(
                                                            "buyer_buy_timeframe_id"
                                                        ),
                                                }
                                            )}
                                            value={
                                                formik.values
                                                    .buyer_buy_timeframe_id
                                            }
                                            options={dbTimeframeData}
                                            onChange={formik.handleChange}
                                            optionLabel="name"
                                            name="buyer_buy_timeframe_id"
                                            filter
                                            filterBy="name"
                                            placeholder="Select a Time frame"
                                            valueTemplate={selectedTemplate}
                                            itemTemplate={optionTemplate}
                                        />
                                        {getFormErrorMessage(
                                            "buyer_buy_timeframe_id"
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="field">
                                        <label
                                            htmlFor="LastInput"
                                            className="form-label"
                                        >
                                            Reason for Purchase? <span className="reuired-star">*</span>
                                        </label>
                                        <Dropdown
                                            className={classNames(
                                                "form-select",
                                                {
                                                    "p-invalid":
                                                        isFormFieldValid(
                                                            "clients_looking_to_purcahse_master_id"
                                                        ),
                                                }
                                            )}
                                            value={
                                                formik.values
                                                    .clients_looking_to_purcahse_master_id
                                            }
                                            options={dbClientsLookingToPurchase}
                                            onChange={formik.handleChange}
                                            optionLabel="name"
                                            name="clients_looking_to_purcahse_master_id"
                                            filter
                                            filterBy="name"
                                            placeholder="Select one"
                                            valueTemplate={selectedTemplate}
                                            itemTemplate={optionTemplate}
                                        />
                                        {getFormErrorMessage(
                                            "clients_looking_to_purcahse_master_id"
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="field">
                                        <label
                                            htmlFor=""
                                            className="form-label"
                                        >
                                            Minimum Price <span className="reuired-star">*</span>
                                        </label>
                                        <Dropdown
                                            className={classNames(
                                                "form-select",
                                                {
                                                    "p-invalid":
                                                        isFormFieldValid(
                                                            "buyer_min_price_range_id"
                                                        ),
                                                }
                                            )}
                                            value={
                                                formik.values
                                                    .buyer_min_price_range_id
                                            }
                                            options={dbPriceRangeMinData}
                                            onChange={formik.handleChange}
                                            optionLabel="name"
                                            name="buyer_min_price_range_id"
                                            filter
                                            filterBy="name"
                                            placeholder="Select a Minimum Price"
                                            valueTemplate={selectedTemplatePriceRange}
                                            itemTemplate={optionTemplatePriceRange}
                                        />
                                        {getFormErrorMessage(
                                            "buyer_min_price_range_id"
                                        )}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="field">
                                        {/* <p className="input-heading">
                                        Maximum Price
                                            What price range are they looking at?
                                        </p> */}
                                        <label
                                            htmlFor=""
                                            className="form-label"
                                        >
                                            Maximum Price <span className="reuired-star">*</span>
                                        </label>
                                        <Dropdown
                                            className={classNames(
                                                "form-select",
                                                {
                                                    "p-invalid":
                                                        isFormFieldValid(
                                                            "buyer_max_price_range_id"
                                                        ),
                                                }
                                            )}
                                            value={
                                                formik.values
                                                    .buyer_max_price_range_id
                                            }
                                            options={dbPriceRangeMaxData}
                                            onChange={formik.handleChange}
                                            optionLabel="name"
                                            name="buyer_max_price_range_id"
                                            filter
                                            filterBy="name"
                                            placeholder="Select a Maximum Price"
                                            valueTemplate={selectedTemplatePriceRange}
                                            itemTemplate={optionTemplatePriceRange}
                                        />
                                        {getFormErrorMessage(
                                            "buyer_max_price_range_id"
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <label
                                    htmlFor="LastInput"
                                    className="form-label"
                                >
                                    Comments
                                </label>
                                <InputTextarea
                                    autoComplete="off"
                                    className={classNames(
                                        "form-control comment-box",
                                        {
                                            "p-invalid":
                                                isFormFieldValid(
                                                    "buyer_comments"
                                                ),
                                        }
                                    )}
                                    name="buyer_comments"
                                    placeholder="Enter here"
                                    value={formik.values.buyer_comments}
                                    onChange={formik.handleChange}
                                />
                                {getFormErrorMessage("buyer_comments")}
                            </div>
                        </div>
                    )}
                    {(referral_type === "seller_referral" ||
                        referral_type === "buyer_and_seller_referral") && (isShowSellerRefferal) && (
                        <div className="info-sell-field">
                            <div className="referral-fields">
                                <h2 className="referall-title property">
                                    Information on the Property to Sell
                                </h2>

                                <div className="row referal-input">
                                    <div className="col-lg-12">
                                        <div className="field">
                                            <label
                                                htmlFor=""
                                                className="form-label"
                                            >
                                                Street Address <span className="reuired-star">*</span>
                                            </label>
                                            <InputText
                                                autoComplete="off"
                                                className={classNames(
                                                    "form-control",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "seller_address"
                                                            ),
                                                    }
                                                )}
                                                name="seller_address"
                                                placeholder="Enter here"
                                                value={
                                                    formik.values.seller_address
                                                }
                                                onChange={formik.handleChange}
                                            />
                                            {getFormErrorMessage(
                                                "seller_address"
                                            )}
                                        </div>
                                    </div>


                                    <div className="col-lg-4">
                                        <div className="field">
                                            <label
                                                htmlFor=""
                                                className="form-label"
                                            >
                                                City <span className="reuired-star">*</span>
                                            </label>
                                            <InputText
                                                autoComplete="off"
                                                className={classNames(
                                                    "form-control",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "seller_city"
                                                            ),
                                                    }
                                                )}
                                                name="seller_city"
                                                placeholder="Enter here"
                                                value={
                                                    formik.values.seller_city
                                                }
                                                onChange={formik.handleChange}
                                            />
                                            {getFormErrorMessage("seller_city")}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="field">
                                            <label
                                                htmlFor=""
                                                className="form-label"
                                            >
                                                Zip Code <span className="reuired-star">*</span>
                                            </label>
                                            <InputText
                                                autoComplete="off"
                                                className={classNames(
                                                    "form-control",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "seller_zipcode"
                                                            ),
                                                    }
                                                )}
                                                name="seller_zipcode"
                                                placeholder="Enter here"
                                                value={
                                                    formik.values.seller_zipcode
                                                }
                                                onChange={formik.handleChange}
                                            />
                                            {getFormErrorMessage(
                                                "seller_zipcode"
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="field">
                                            <label
                                                htmlFor=""
                                                className="form-label"
                                            >
                                                 State <span className="reuired-star">*</span>
                                            </label>
                                            <Dropdown
                                                className={classNames(
                                                    "form-select",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "seller_state_id"
                                                            ),
                                                    }
                                                )}
                                                value={
                                                    formik.values
                                                        .seller_state_id
                                                }
                                                options={dbStatesName}
                                                onChange={formik.handleChange}
                                                optionLabel="state"
                                                name="seller_state_id"
                                                filter
                                                filterBy="abbrv"
                                                
                                                placeholder="Select a State"
                                                valueTemplate={
                                                    selectedStateTemplate
                                                }
                                                itemTemplate={
                                                    stateOptionTemplate
                                                }
                                            />
                                            {getFormErrorMessage(
                                                "seller_state_id"
                                            )}


                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="field">
                                            <p className="input-heading">
                                                What is the seller’s time frame? <span className="reuired-star">*</span>
                                            </p>
                                            <Dropdown
                                                className={classNames(
                                                    "form-select",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "seller_buy_timeframe_id"
                                                            ),
                                                    }
                                                )}
                                                value={
                                                    formik.values
                                                        .seller_buy_timeframe_id
                                                }
                                                options={dbTimeframeData}
                                                onChange={formik.handleChange}
                                                optionLabel="name"
                                                name="seller_buy_timeframe_id"
                                                filter
                                                filterBy="name"
                                                placeholder="Select a Time frame"
                                                valueTemplate={selectedTemplate}
                                                itemTemplate={optionTemplate}
                                            />
                                            {getFormErrorMessage(
                                                "seller_buy_timeframe_id"
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="field doller-value">
                                            <p className="input-heading">
                                                Estimated Home Value
                                            </p>
                                            <span className="p-input-icon-left custom-input-left">

                                            <i className="pi pi-dollar"></i>
                                            <InputText
                                                autoComplete="off"
                                                className={classNames(
                                                    "form-control ps-4",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "seller_estimated_price"
                                                            ),
                                                    }
                                                )}
                                                name="seller_estimated_price"
                                                placeholder=""
                                                value={
                                                    formik.values.seller_estimated_price
                                                }
                                                onChange={formik.handleChange}
                                            /></span>
                                            {getFormErrorMessage(
                                                "seller_estimated_price"
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-12">
                                        <div className="field">
                                            <p className="input-heading">
                                             What type of property is this? 
                                            </p>

                                            <Dropdown
                                                className={classNames(
                                                    "form-select",
                                                    {
                                                        "p-invalid":
                                                            isFormFieldValid(
                                                                "seller_property_master_id"
                                                            ),
                                                    }
                                                )}
                                                value={formik.values.seller_property_master_id}
                                                options={dbPropertyTypeData}
                                                onChange={formik.handleChange}
                                                optionLabel="name"
                                                name="seller_property_master_id"
                                                filter
                                                filterBy="name"
                                                placeholder="Select a Property Type"
                                                valueTemplate={selectedTemplate}
                                                itemTemplate={optionTemplate}
                                            />
                                            {getFormErrorMessage("seller_property_master_id"
                                                )}
                                        </div>
                                    </div>
                                <div className="field">
                                    <label
                                        htmlFor="LastInput"
                                        className="form-label"
                                    >
                                        Comments
                                    </label>
                                    <InputTextarea
                                        autoComplete="off"
                                        className={classNames(
                                            "form-control comment-box",
                                            {
                                                "p-invalid":
                                                    isFormFieldValid(
                                                        "seller_comments"
                                                    ),
                                            }
                                        )}
                                        name="seller_comments"
                                        placeholder="Enter here"
                                        value={formik.values.seller_comments}
                                        onChange={formik.handleChange}
                                    />
                                    {getFormErrorMessage("seller_comments")}
                                </div>
                            </div>
                        </div>
                    )}
                </Card.Body>

                    </Modal.Body>
                    <Modal.Footer className='add-people-footer'>

                    <Card.Footer>
                    <div className="text-end">


                        <div className="text-end">
                                    <Button
                                    className="btn btn-cancel rf-cancel-btn me-3"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {peopleModalClose(); props.changeStatuClosedhandleModalReferralData();}}
                                    disabled={loading}
                                    >
                                    Close
                                    </Button>

                                <Button
                                    className="btn btn-submit"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Update
                                    {loading && (<Typography align="center">
                                <CircularProgress value={66} />
                            </Typography>)}
                                </Button>

                    </div>
                    </div>
                </Card.Footer>

                    </Modal.Footer>
                    </form>
            </Modal>

        </>
    )
}

export default ReferralData;
