import React, {  useState,useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';
import { createRoot } from 'react-dom/client'
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';
// import { CheckBox } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { InputText } from 'primereact';
import axios, { baseUrl } from '../../axiosCall.js';
import useAuth from "./../../hooks/useAuth";
import AES from 'crypto-js/aes';
import Swal from 'sweetalert2';
import EditUser from "./EditUser";
import { Dropdown } from "primereact";
import { Alert } from '@mui/material';
import { Button } from 'primereact/button';

const documentsWiseFilter = [
    {
        id: 'all',
        name: 'All'
    },
    {
        id: 'document_exits',
        name: 'Document exits'
    },
    {
        id: 'document_not_exits',
        name: 'Document not exits'
    }
];

const MyNetwork = () => {
    const { user,logout } = useAuth();
    const secretKey = 'REFR2022@GALAXY';
    const navigate = useNavigate();
    let filter = '';
    
    let agentCount = 0;
    const [selectedDocuments, setSelectedDocuments] = useState('');

    const [showEditUser, setShowEditUser] = useState(false);
    const [showClearSearch, setShowClearSearch] = useState(false);
    const [editRowId, setEditRowId] = useState(0);
    const [editRowType, setEditRowType] = useState(false);
    const [editRowTypeTemp, setEditRowTypeTemp] = useState(false);
    const [dbStatesName, setDbStatesName] = useState([]);
    const [dbBrokerageRoleData, setBrokerageRoleData] = useState([]);
    
    const [currentFilter, setCurrentFilter] = useState('my_brokerage_agents');
    let isFirstTimeList = 1;

    const [isBrokerageRecordShow, setIsBrokerageRecordShow] = useState(true);

    const [callFrom, setCallFrom] = useState('');
    let ambassadorCount = 0;
    let pendingCount = 0;
    const [errorMessage, setErrorMessage] = useState('');
    filter = 'my_brokerage_agents';
    useEffect(() => {
        if (user !== null) {
            //console.log(JSON.stringify(user))
            $.fn.dataTable.ext.errMode = 'none';
            
            
            if((user) && (user.type === 'ambassador') && (user.brokerage_role_id.slug === 'broker_of_record'))
            {
                filter = 'my_brokerage_agents';
                setCurrentFilter('my_brokerage_agents');
                setIsBrokerageRecordShow(true);
            }
            else
            {
                filter = 'ambassador';
                setCurrentFilter('ambassador');
                setIsBrokerageRecordShow(false);
            }
            fetchMyBrokerageAgentsData();
            fetchAmbassadorData();
            fetchAgentData();
            fetchPendingData();
            fetchNotLicensedData();
        }
        
    }, [user]);
    function handleCallTemp()
    {
        //alert('finally call');
    }
    function fetchAmbassadorData() {
        //$.fn.dataTable.ext.errMode = 'throw';

        const accessToken = window.localStorage.getItem('accessToken');
        const table = $('.ambassadorTable').
                on('preXhr.dt', function ( e, settings, data ) {
                if (settings.jqXHR) settings.jqXHR.abort();
            }).DataTable({
            "language": {
                "filter_tab": "",
                "search": "",
                'searchPlaceholder': "Search by name, email or phone",
                "sEmptyTable": "No records found"
            },
            dom: '<"top"f>rt<"bottom ambBottom"lip><"clear">',
           //"orderFixed": [ 4, 'desc' ],
            order: [[4, 'desc']],
            "ordering": true,
            responsive: true,
            destroy: true,
            processing: true,
            "pageLength": 50,
            "lengthMenu": [[10, 20, 25, 30, 50, 100, 200, 500], [10, 20, 25, 30, 50, 100, 200, 500]],
            "serverSide": true,
            "serverMethod": 'POST',
            'ajax': {
                'url': baseUrl + 'list-my-network',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                'data': function (data) {
                    data.user_id = user.id;
                    data.search_value = $('.searchbarTable').val();
                    data.filter = filter;
                    data.selected_documents_filter = $('#documents_search_type').val();
                    data.first_time_call = isFirstTimeList;
                },
                error: function (jqXHR) {
                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{
                        
                    }
                }
            },
            "drawCallback": function (settings) {
                // Here the response
                isFirstTimeList = 0;
                var response = settings.json;
                
                $("#spanAgentCount").html('(' + response.agentCount + ')');
                $("#spanAmbassadorCount").html('(' + response.ambassadorCount + ')');
                $("#spanPendingCount").html('(' + response.pendingCount + ')');
                $("#spanNotLicencedCount").html('(' + response.notLicencedCount + ')');
                $("#spanMyBrokerageAgents").html('(' + response.myBrokerageAgentsCount + ')');

                let totalCount = parseInt(response.agentCount) + parseInt(response.ambassadorCount)  + parseInt(response.myBrokerageAgentsCount);
                $("#spanNetworkCount").html('(' + totalCount + ')');

                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.ambBottom').hide();
                else
                    $('.ambBottom').show();
                
            },
            "rowCallback": function( row, data, index ) {
                if( data.is_all_document_uploaded ===  0)
                {
                    $('td', row).css('background-color', '#ffe7e6');
                }
            },
            "columnDefs":
                [
                    {
                        "targets": 0,
                        "width": "20%",
                        "title": "Name",
                        "data": "name",
                        "name": "name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "cellType": "td",
                        "className": "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let matches = rowData.name.match(/\b(\w)/g);
                            let shortName = matches.join('');

                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'><Avatar>{shortName}</Avatar> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e, `${rowData.id}`, 'ambassador')} className='name-link underline-ani-blue'>{rowData.name}</a></span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 1,
                        "width": "20%",
                        "title": "Up Line",
                        "data": "refer_user_name",
                        "name": "refer_user_name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [1],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop bold-td",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let tempType = '';
                            if(rowData.refer_user_type === 'agent')
                                tempType = '(RA)';
                            else if(rowData.refer_user_type === 'ambassador')
                                tempType = '(A)';
                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'>{(rowData.refer_user_name) ? rowData.refer_user_name+' '+tempType : 'N/A' }</span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 2,
                        "width": "15%",
                        "title": "Phone",
                        "data": "contact_number",
                        "name": "contact_number",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [2],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop bold-td",
                        "createdCell": "",
                    },
                    {
                        "targets": 3,
                        "width": "10%",
                        "title": "MLS Info",
                        "data": "is_mls",
                        "name": "is_mls",
                        "visible": user.type === 'admin',
                        "searchable": true,
                        "orderable": false,
                        "orderData": [3],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop",
                        "createdCell": (td, cellData, rowData, row, col) => {

                            let isChecked = "";
                            if (rowData.is_mls === 1) {
                                isChecked = "checked";
                            }
                            createRoot(td).render(<div class="form-check form-switch"><input class="form-check-input switch-toggle switchClass" type="checkbox" role="switch" value={rowData.id} onChange={(e) => handleCall(e, `${rowData.id}`)} defaultChecked={isChecked} /></div>
                            );
                        }
                    },
                    {
                        "targets": 4,
                        "width": "10%",
                        "title": "Power User",
                        "data": "is_power_user",
                        "name": "is_power_user",
                        "visible": user.type === 'admin',
                        "searchable": true,
                        "orderable": false,
                        "orderData": [4],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop",
                        "createdCell": (td, cellData, rowData, row, col) => {

                            let isChecked = "";
                            if (rowData.is_power_user === 1) {
                                isChecked = "checked";
                            }
                            createRoot(td).render(<div class="form-check form-switch"><input class="form-check-input switch-toggle switchClass" type="checkbox" role="switch" value={rowData.id} onChange={(e) => handleCallPowerUser(e, `${rowData.id}`)} defaultChecked={isChecked} /></div>
                            );
                        }
                    },
                    {
                        "targets": 5,
                        "width": "15%",
                        "title": "Created On",
                        "data": "created",
                        "name": "created",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [5],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 6,
                        "width": "15%",
                        "title": "Action",
                        "data": "id",
                        "visible": user.type === 'admin',
                        "searchable": false,
                        "orderable": false,
                        //"className": "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                        "className": "my_amb referral-t desktop",
                        // render: function (data, type,row) {
                            
                        //     let html = '';
                        //     html += '<ul class="title-check">'
                        //     html += '<li><a href="javascript:;" class="name-title mb-1" onclick="handleCallTemp()">Role: '+row.ambassador_broker_role_name+'</a></li>';
                        //     html += '</ul>';
                        //     return html;
                        // },
                        "createdCell": (td, cellData, rowData, row, col) => {

                            createRoot(td).render(<ul className='action-icon'>
                                <li className='action-list-icon'>
                                <a href='javascript:;' onClick={() => {handleEmail(rowData.id)}}>
                                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.667 13.4582H2.33366C1.72655 13.456 1.14493 13.2138 0.715632 12.7845C0.286336 12.3552 0.0441879 11.7736 0.0419922 11.1665V2.83317C0.0441879 2.22606 0.286336 1.64444 0.715632 1.21514C1.14493 0.785848 1.72655 0.5437 2.33366 0.541504H15.667C16.2741 0.5437 16.8557 0.785848 17.285 1.21514C17.7143 1.64444 17.9565 2.22606 17.9587 2.83317V11.1665C17.9565 11.7736 17.7143 12.3552 17.285 12.7845C16.8557 13.2138 16.2741 13.456 15.667 13.4582ZM2.33366 1.7915C2.05739 1.7915 1.79244 1.90125 1.59709 2.0966C1.40174 2.29195 1.29199 2.5569 1.29199 2.83317V11.1665C1.29199 11.4428 1.40174 11.7077 1.59709 11.9031C1.79244 12.0984 2.05739 12.2082 2.33366 12.2082H15.667C15.9433 12.2082 16.2082 12.0984 16.4036 11.9031C16.5989 11.7077 16.7087 11.4428 16.7087 11.1665V2.83317C16.7087 2.5569 16.5989 2.29195 16.4036 2.0966C16.2082 1.90125 15.9433 1.7915 15.667 1.7915H2.33366Z" fill="#1E2938" />
                                            <path d="M9.0001 8.12498C8.51651 8.12498 8.04174 7.99549 7.6251 7.74998L0.350104 3.42498C0.214182 3.34026 0.115402 3.2072 0.0736538 3.05258C0.0319056 2.89795 0.0502894 2.73326 0.125104 2.59164C0.208308 2.45398 0.342012 2.35427 0.497695 2.31379C0.653378 2.27331 0.818715 2.29527 0.958437 2.37498L8.24177 6.69164C8.46265 6.82637 8.71638 6.89764 8.9751 6.89764C9.23383 6.89764 9.48756 6.82637 9.70844 6.69164L16.9918 2.37498C17.1315 2.29527 17.2968 2.27331 17.4525 2.31379C17.6082 2.35427 17.7419 2.45398 17.8251 2.59164C17.8999 2.73326 17.9183 2.89795 17.8766 3.05258C17.8348 3.2072 17.736 3.34026 17.6001 3.42498L10.3751 7.74998C9.95847 7.99549 9.4837 8.12498 9.0001 8.12498Z" fill="#1E2938" />
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                    <a href='javascript:;' onClick={() => {handleEdit(rowData.id,rowData.type,'ambassador')}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_21796)">
                                                <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_21796">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                <a href='javascript:;' onClick={() => {handleDelete(rowData.id,rowData.created_referral_count,rowData.assigned_as_agent_referral_count,'ambassador')}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_13698)">
                                                <path d="M3.33398 5.83398H16.6673" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.33398 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.666 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.16602 5.83398L4.99935 15.834C4.99935 16.276 5.17494 16.6999 5.4875 17.0125C5.80006 17.3251 6.22399 17.5007 6.66602 17.5007H13.3327C13.7747 17.5007 14.1986 17.3251 14.5112 17.0125C14.8238 16.6999 14.9993 16.276 14.9993 15.834L15.8327 5.83398" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_13698">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </a>
                                </li>
                                
                            </ul>);
                        },

                    }
                ]
                
        });
        
        $('.searchbarTable').keyup(function () {
           // console.log($(this).val().length)
            if ((filter === 'ambassador') && (($(this).val().length >= 3) || ($(this).val().length === 0)))
            {
                setCurrentFilter('ambassador');
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else if (filter === 'ambassador')
            {
                setShowClearSearch(false);
            }    
        })
        $('#documents_search_type').change(function () {
            

             if (filter === 'ambassador')
             {
                setCurrentFilter('ambassador');
                table.draw();
             }    
         })
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

        $(".ambassadorTable").on('click', 'th', function () {
            $(".ambassadorTable thead th").each(function (i, th) {
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });

        $(".ambassadorTable th").first().click().click();

        // $('.clearSearchTable').click(function () {
        //     if (filter === 'ambassador') {
        //         $('.searchbarTable').val('');
        //         table.search('').draw();
        //     }
        //     else {
        //         $('.searchbarTable').val('');
        //         table.search('');
        //     }
        // })

        $('.ambassadorTabClass').click(function () {
            isFirstTimeList = 1;
            filter = 'ambassador';
            table.clear();
            table.draw();
        })
        setTimeout(function () {
            $('input[name^="switchId_"]').click(function () {
                
                let rowId = $(this).attr('data-id');
                let mlsStatus = $(this).attr('data-is-mls');
               // console.log('rowId ' + rowId + ' mlsStatus ' + mlsStatus)

            });
        }, 2000);
    }
    const handleClearSearch = () => {

        $('.searchbarTable').val('');
        
        if (currentFilter === 'ambassador') {
            $(".ambassadorTabClass").first().trigger('click');
        }
        else  if (currentFilter === 'agent') {
            $(".agentTabClass").first().trigger('click');
        }
        else  if (currentFilter === 'pending') {
            $(".pendingTabClass").first().trigger('click');
        }
        else  if (currentFilter === 'not_licensed') {
            $(".notLicencedTabClass").first().trigger('click');
        }
        else  if (currentFilter === 'my_brokerage_agents') {
            $(".myBrokerageAgentsTabClass").first().trigger('click');
        }
        setShowClearSearch(false);
    }
    function fetchAgentData() {
        const accessToken = window.localStorage.getItem('accessToken');
        const table = $('.agentTable').on('preXhr.dt', function ( e, settings, data ) {
                if (settings.jqXHR) settings.jqXHR.abort();
            }).DataTable({
            "language": {
                "filter_tab": "",
                "search": "",
                'searchPlaceholder': "Search by name, email or phone",
                "sEmptyTable": "No records found"
            },
            dom: '<"top"f>rt<"bottom agBottom"lip><"clear">',
            //"orderFixed": [ 4, 'desc' ],
            order: [[4, 'desc']],
            "ordering": true,
            responsive: true,
            destroy: true,
            processing: true,
            "pageLength": 50,
            "lengthMenu": [[10, 20, 25, 30, 50, 100, 200, 500], [10, 20, 25, 30, 50, 100, 200, 500]],
            "serverSide": true,
            "serverMethod": 'POST',
            'ajax': {
                'url': baseUrl + 'list-my-network',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                'data': function (data) {
                    data.user_id = user.id;
                    data.search_value = $('.searchbarTable').val();
                    data.filter = filter;
                    data.selected_documents_filter = $('#documents_search_type').val();
                    data.first_time_call = isFirstTimeList;
                },
                error: function (jqXHR) {
                    
                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{
                        
                    }
                }
            },
            "drawCallback": function (settings) {
                // Here the response
                isFirstTimeList = 0;
                var response = settings.json;
                
                $("#spanAgentCount").html('(' + response.agentCount + ')');
                $("#spanAmbassadorCount").html('(' + response.ambassadorCount + ')');
                $("#spanPendingCount").html('(' + response.pendingCount + ')');
                $("#spanNotLicencedCount").html('(' + response.notLicencedCount + ')');
                $("#spanMyBrokerageAgents").html('(' + response.myBrokerageAgentsCount + ')');

                let totalCount = parseInt(response.agentCount) + parseInt(response.ambassadorCount)  + parseInt(response.myBrokerageAgentsCount);
                $("#spanNetworkCount").html('(' + totalCount + ')');
                //alert(response.recordsTotal)
                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.agBottom').hide();
                else
                    $('.agBottom').show();
                
            },
            "rowCallback": function( row, data, index ) {
                if( data.is_all_document_uploaded ===  0)
                {
                    $('td', row).css('background-color', '#ffe7e6');
                }
            },
            "columnDefs":
                [
                    {
                        "targets": 0,
                        "width": "15.8%",
                        "title": "Name",
                        "data": "name",
                        "name": "name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "cellType": "td",
                        "className": "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let matches = rowData.name.match(/\b(\w)/g);
                            let shortName = matches.join('');

                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'><Avatar>{shortName}</Avatar> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e, `${rowData.id}`, 'agent')} className='name-link underline-ani-blue'>{rowData.name}</a></span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 1,
                        "width": "15.8%",
                        "title": "Up Line",
                        "data": "refer_user_name",
                        "name": "refer_user_name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [1],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_agent desktop bold-td",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let tempType = '';
                            if(rowData.refer_user_type === 'agent')
                                tempType = '(RA)';
                            else if(rowData.refer_user_type === 'ambassador')
                                tempType = '(A)';

                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'>{(rowData.refer_user_name) ? rowData.refer_user_name+' '+tempType : 'N/A' }</span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 2,
                        "width": "20.83%",
                        "title": "Phone",
                        "data": "contact_number",
                        "name": "contact_number",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [2],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop bold-td",
                        "createdCell": "",
                    },
                    {
                        "targets": 3,
                        "width": "15.8%",
                        "title": "License Info",
                        "data": "state_name",
                        "name": "state_name",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [3],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_agent desktop",
                        render: function (data, type,row) {
                            
                            let html = '';
                            html += '<ul class="title-check">'
                            html += '<li><span class="name-title mb-1">'+row.license_number+', '+row.state_name+'</span><span class="name-title">Exp. Date: '+row.license_expiry_date+'</span></li>';
                            html += '</ul>';
                            return html;
                        },
                        // "createdCell": (td, cellData, rowData, row, col) => {
                        //     createRoot(td).render(<ul className='title-check'>
                        //         <li><span className='name-title mb-1'>{rowData.license_number}, {rowData.state_name}</span><span className='name-title'>Expired On: {rowData.license_expiry_date}</span></li>
                        //     </ul>
                        //     );
                        // }
                    },
                    {
                        "targets": 4,
                        "width": "10%",
                        "title": "Power User",
                        "data": "is_power_user",
                        "name": "is_power_user",
                        "visible": user.type === 'admin',
                        "searchable": true,
                        "orderable": false,
                        "orderData": [4],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop",
                        "createdCell": (td, cellData, rowData, row, col) => {

                            let isChecked = "";
                            if (rowData.is_power_user === 1) {
                                isChecked = "checked";
                            }
                            createRoot(td).render(<div class="form-check form-switch"><input class="form-check-input switch-toggle switchClass" type="checkbox" role="switch" value={rowData.id} onChange={(e) => handleCallPowerUser(e, `${rowData.id}`)} defaultChecked={isChecked} /></div>
                            );
                        }
                    },
                    {
                        "targets": 5,
                        "width": "15.8%",
                        "title": "Created On",
                        "data": "created",
                        "name": "created",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [4],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_agent desktop",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 6,
                        "width": "15%",
                        "title": "Action",
                        "data": "id",
                        "visible": user.type === 'admin',
                        "searchable": false,
                        "orderable": false,
                        //"className": "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                        "className": "my_amb referral-t desktop",
                        "createdCell": (td, cellData, rowData, row, col) => {

                            createRoot(td).render(<ul className='action-icon'>
                                <li className='action-list-icon'>
                                <a href='javascript:;' onClick={() => {handleEmail(rowData.id)}}>
                                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.667 13.4582H2.33366C1.72655 13.456 1.14493 13.2138 0.715632 12.7845C0.286336 12.3552 0.0441879 11.7736 0.0419922 11.1665V2.83317C0.0441879 2.22606 0.286336 1.64444 0.715632 1.21514C1.14493 0.785848 1.72655 0.5437 2.33366 0.541504H15.667C16.2741 0.5437 16.8557 0.785848 17.285 1.21514C17.7143 1.64444 17.9565 2.22606 17.9587 2.83317V11.1665C17.9565 11.7736 17.7143 12.3552 17.285 12.7845C16.8557 13.2138 16.2741 13.456 15.667 13.4582ZM2.33366 1.7915C2.05739 1.7915 1.79244 1.90125 1.59709 2.0966C1.40174 2.29195 1.29199 2.5569 1.29199 2.83317V11.1665C1.29199 11.4428 1.40174 11.7077 1.59709 11.9031C1.79244 12.0984 2.05739 12.2082 2.33366 12.2082H15.667C15.9433 12.2082 16.2082 12.0984 16.4036 11.9031C16.5989 11.7077 16.7087 11.4428 16.7087 11.1665V2.83317C16.7087 2.5569 16.5989 2.29195 16.4036 2.0966C16.2082 1.90125 15.9433 1.7915 15.667 1.7915H2.33366Z" fill="#1E2938" />
                                            <path d="M9.0001 8.12498C8.51651 8.12498 8.04174 7.99549 7.6251 7.74998L0.350104 3.42498C0.214182 3.34026 0.115402 3.2072 0.0736538 3.05258C0.0319056 2.89795 0.0502894 2.73326 0.125104 2.59164C0.208308 2.45398 0.342012 2.35427 0.497695 2.31379C0.653378 2.27331 0.818715 2.29527 0.958437 2.37498L8.24177 6.69164C8.46265 6.82637 8.71638 6.89764 8.9751 6.89764C9.23383 6.89764 9.48756 6.82637 9.70844 6.69164L16.9918 2.37498C17.1315 2.29527 17.2968 2.27331 17.4525 2.31379C17.6082 2.35427 17.7419 2.45398 17.8251 2.59164C17.8999 2.73326 17.9183 2.89795 17.8766 3.05258C17.8348 3.2072 17.736 3.34026 17.6001 3.42498L10.3751 7.74998C9.95847 7.99549 9.4837 8.12498 9.0001 8.12498Z" fill="#1E2938" />
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                <a href='javascript:;' onClick={() => {handleEdit(rowData.id,rowData.type,'license_agent')}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_21796)">
                                                <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_21796">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                <a href='javascript:;' onClick={() => {handleDelete(rowData.id,rowData.created_referral_count,rowData.assigned_as_agent_referral_count,'license_agent')}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_13698)">
                                                <path d="M3.33398 5.83398H16.6673" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.33398 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.666 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.16602 5.83398L4.99935 15.834C4.99935 16.276 5.17494 16.6999 5.4875 17.0125C5.80006 17.3251 6.22399 17.5007 6.66602 17.5007H13.3327C13.7747 17.5007 14.1986 17.3251 14.5112 17.0125C14.8238 16.6999 14.9993 16.276 14.9993 15.834L15.8327 5.83398" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_13698">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </a>
                                </li>
                                
                            </ul>);
                        },
                    }
                ]
        });
        $('.searchbarTable').keyup(function () {

            if ((filter === 'agent') && (($(this).val().length >= 3) || ($(this).val().length === 0)))
            {
                setCurrentFilter('agent');
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else if (filter === 'agent')
            {
                setShowClearSearch(false);
            } 
        })
        $('#documents_search_type').change(function () {
           

             if (filter === 'agent')
             {
                setCurrentFilter('agent');
                table.draw();
             }    
         })
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

        $(".agentTable").on('click', 'th', function () {
            $(".agentTable thead th").each(function (i, th) {
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });

        $(".agentTable th").first().click().click();

        // $('.clearSearchTable').click(function () {
        //     if (filter === 'agent') {
        //         $('.searchbarTable').val('');
        //         table.search('').draw();
        //     }
        //     else {
        //         $('.searchbarTable').val('');
        //         table.search('');
        //     }
        // })

        $('.agentTabClass').click(function () {
            isFirstTimeList = 1;
            filter = 'agent';
            table.clear();
            table.draw();
        })

    }
    function fetchNotLicensedData() {
        const accessToken = window.localStorage.getItem('accessToken');
        const table = $('.notLicensedTable').on('preXhr.dt', function ( e, settings, data ) {
            if (settings.jqXHR) settings.jqXHR.abort();
        }).DataTable({
            "language": {
                "filter_tab": "",
                "search": "",
                'searchPlaceholder': "Search by name, email or phone",
                "sEmptyTable": "No records found"
            },
            dom: '<"top"f>rt<"bottom nLBottom"lip><"clear">',
            //"orderFixed": [ 4, 'desc' ],
            order: [[4, 'desc']],
            "ordering": true,
            responsive: true,
            destroy: true,
            processing: true,
            "pageLength": 50,
            "lengthMenu": [[10, 20, 25, 30, 50, 100, 200, 500], [10, 20, 25, 30, 50, 100, 200, 500]],
            "serverSide": true,
            "serverMethod": 'POST',
            'ajax': {
                'url': baseUrl + 'list-my-network',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                'data': function (data) {
                    data.user_id = user.id;
                    data.search_value = $('.searchbarTable').val();
                    data.filter = filter;
                    data.selected_documents_filter = $('#documents_search_type').val();
                    data.first_time_call = isFirstTimeList;
                },
                error: function (jqXHR) {
                    
                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{
                        
                        
                    }
                }
            },
            "drawCallback": function (settings) {
                // Here the response
                isFirstTimeList = 0;
                var response = settings.json;
                
                $("#spanAgentCount").html('(' + response.agentCount + ')');
                $("#spanAmbassadorCount").html('(' + response.ambassadorCount + ')');
                $("#spanPendingCount").html('(' + response.pendingCount + ')');
                $("#spanNotLicencedCount").html('(' + response.notLicencedCount + ')');
                $("#spanMyBrokerageAgents").html('(' + response.myBrokerageAgentsCount + ')');

                let totalCount = parseInt(response.agentCount) + parseInt(response.ambassadorCount)  + parseInt(response.myBrokerageAgentsCount);
                $("#spanNetworkCount").html('(' + totalCount + ')');
                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.nLBottom').hide();
                else
                    $('.nLBottom').show();
                
            },
            "rowCallback": function( row, data, index ) {
                if( data.is_all_document_uploaded ===  0)
                {
                    $('td', row).css('background-color', '#ffe7e6');
                }
            },
            "columnDefs":
                [
                    {
                        "targets": 0,
                        "width": "15.8%",
                        "title": "Name",
                        "data": "name",
                        "name": "name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "cellType": "td",
                        "className": "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let matches = rowData.name.match(/\b(\w)/g);
                            let shortName = matches.join('');

                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'><Avatar>{shortName}</Avatar> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e, `${rowData.id}`, 'agent')} className='name-link underline-ani-blue'>{rowData.name}</a></span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 1,
                        "width": "15.8%",
                        "title": "Up Line",
                        "data": "refer_user_name",
                        "name": "refer_user_name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [1],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_agent desktop bold-td",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let tempType = '';
                            if(rowData.refer_user_type === 'agent')
                                tempType = '(RA)';
                            else if(rowData.refer_user_type === 'ambassador')
                                tempType = '(A)';
                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'>{(rowData.refer_user_name) ? rowData.refer_user_name+' '+tempType : 'N/A' }</span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 2,
                        "width": "20.83%",
                        "title": "Phone",
                        "data": "contact_number",
                        "name": "contact_number",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [2],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop bold-td",
                        "createdCell": "",
                    },
                    {
                        "targets": 3,
                        "width": "15.8%",
                        "title": "License Info",
                        "data": "state_name",
                        "name": "state_name",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [3],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_agent desktop",
                        render: function (data, type,row) {
                            
                            let html = '';
                            html += '<ul class="title-check">'
                            html += '<li><span class="name-title mb-1">'+row.license_number+', '+row.state_name+'</span><span class="name-title">Exp. Date: '+row.license_expiry_date+'</span></li>';
                            html += '</ul>';
                            return html;
                        },
                        // "createdCell": (td, cellData, rowData, row, col) => {
                        //     createRoot(td).render(<ul className='title-check'>
                        //         <li><span className='name-title mb-1'>{rowData.license_number}, {rowData.state_name}</span><span className='name-title'>Expired On: {rowData.license_expiry_date}</span></li>
                        //     </ul>
                        //     );
                        // }
                    },
                    {
                        "targets": 4,
                        "width": "15.8%",
                        "title": "Created On",
                        "data": "created",
                        "name": "created",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [4],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_agent desktop",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 5,
                        "width": "15.8%",
                        "title": "Action",
                        "data": "id",
                        "visible": user.type === 'admin',
                        "searchable": false,
                        "orderable": false,
                        //"className": "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                        "className": "my_amb referral-t desktop",
                        "createdCell": (td, cellData, rowData, row, col) => {

                            createRoot(td).render(<ul className='action-icon'>
                                <li className='action-list-icon'>
                                <a href='javascript:;' onClick={() => {handleEmail(rowData.id)}}>
                                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.667 13.4582H2.33366C1.72655 13.456 1.14493 13.2138 0.715632 12.7845C0.286336 12.3552 0.0441879 11.7736 0.0419922 11.1665V2.83317C0.0441879 2.22606 0.286336 1.64444 0.715632 1.21514C1.14493 0.785848 1.72655 0.5437 2.33366 0.541504H15.667C16.2741 0.5437 16.8557 0.785848 17.285 1.21514C17.7143 1.64444 17.9565 2.22606 17.9587 2.83317V11.1665C17.9565 11.7736 17.7143 12.3552 17.285 12.7845C16.8557 13.2138 16.2741 13.456 15.667 13.4582ZM2.33366 1.7915C2.05739 1.7915 1.79244 1.90125 1.59709 2.0966C1.40174 2.29195 1.29199 2.5569 1.29199 2.83317V11.1665C1.29199 11.4428 1.40174 11.7077 1.59709 11.9031C1.79244 12.0984 2.05739 12.2082 2.33366 12.2082H15.667C15.9433 12.2082 16.2082 12.0984 16.4036 11.9031C16.5989 11.7077 16.7087 11.4428 16.7087 11.1665V2.83317C16.7087 2.5569 16.5989 2.29195 16.4036 2.0966C16.2082 1.90125 15.9433 1.7915 15.667 1.7915H2.33366Z" fill="#1E2938" />
                                            <path d="M9.0001 8.12498C8.51651 8.12498 8.04174 7.99549 7.6251 7.74998L0.350104 3.42498C0.214182 3.34026 0.115402 3.2072 0.0736538 3.05258C0.0319056 2.89795 0.0502894 2.73326 0.125104 2.59164C0.208308 2.45398 0.342012 2.35427 0.497695 2.31379C0.653378 2.27331 0.818715 2.29527 0.958437 2.37498L8.24177 6.69164C8.46265 6.82637 8.71638 6.89764 8.9751 6.89764C9.23383 6.89764 9.48756 6.82637 9.70844 6.69164L16.9918 2.37498C17.1315 2.29527 17.2968 2.27331 17.4525 2.31379C17.6082 2.35427 17.7419 2.45398 17.8251 2.59164C17.8999 2.73326 17.9183 2.89795 17.8766 3.05258C17.8348 3.2072 17.736 3.34026 17.6001 3.42498L10.3751 7.74998C9.95847 7.99549 9.4837 8.12498 9.0001 8.12498Z" fill="#1E2938" />
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                <a href='javascript:;' onClick={() => {handleEdit(rowData.id,rowData.type,'not_license_agent')}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_21796)">
                                                <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_21796">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                    <a href='javascript:;' onClick={() => {handleDelete(rowData.id,rowData.created_referral_count,rowData.assigned_as_agent_referral_count,'not_license_agent')}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_13698)">
                                                <path d="M3.33398 5.83398H16.6673" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.33398 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.666 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.16602 5.83398L4.99935 15.834C4.99935 16.276 5.17494 16.6999 5.4875 17.0125C5.80006 17.3251 6.22399 17.5007 6.66602 17.5007H13.3327C13.7747 17.5007 14.1986 17.3251 14.5112 17.0125C14.8238 16.6999 14.9993 16.276 14.9993 15.834L15.8327 5.83398" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_13698">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </a>
                                </li>
                                
                            </ul>);
                        },
                    }
                ]
        });
        $('.searchbarTable').keyup(function () {
            if ((filter === 'not_licensed') && (($(this).val().length >= 3) || ($(this).val().length === 0)))
            {
                setCurrentFilter('not_licensed');
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else if (filter === 'not_licensed')
            {
                setShowClearSearch(false);
            } 
        })
        $('#documents_search_type').change(function () {
            
             if (filter === 'not_licensed')
             {
                setCurrentFilter('not_licensed');
                table.draw();
             }    
         })
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

        $(".notLicensedTable").on('click', 'th', function () {
            $(".notLicensedTable thead th").each(function (i, th) {
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });

        $(".notLicensedTable th").first().click().click();
        // $('.clearSearchTable').click(function () {
        //     if (filter === 'not_licensed') {
        //         $('.searchbarTable').val('');
        //         table.search('').draw();
        //     }
        //     else {
        //         $('.searchbarTable').val('');
        //         table.search('');
        //     }
        // })

        $('.notLicencedTabClass').click(function () {
            isFirstTimeList = 1;
            filter = 'not_licensed';
            table.clear();
            table.draw();
        })
    }
    function fetchPendingData() {
        const accessToken = window.localStorage.getItem('accessToken');
        const table = $('.pendingTable').on('preXhr.dt', function ( e, settings, data ) {
            if (settings.jqXHR) settings.jqXHR.abort();
        }).DataTable({
            "language": {
                "filter_tab": "",
                "search": "",
                'searchPlaceholder': "Search by name, email or phone",
                "sEmptyTable": "No records found"
            },
            dom: '<"top"f>rt<"bottom pBottom"lip><"clear">',
           // "orderFixed": [ 3, 'desc' ],
            order: [[3, 'desc']],
            "ordering": true,
            rowReorder: {
                selector: 'td:nth-child(2)'
            },
            responsive: true,
            destroy: true,
            processing: true,
            "pageLength": 50,
            "lengthMenu": [[10, 20, 25, 30, 50, 100, 200, 500], [10, 20, 25, 30, 50, 100, 200, 500]],
            "serverSide": true,
            "serverMethod": 'POST',
            'ajax': {
                'url': baseUrl + 'list-my-network',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                'data': function (data) {
                    data.user_id = user.id;
                    data.search_value = $('.searchbarTable').val();
                    data.filter = filter;
                    data.selected_documents_filter = $('#documents_search_type').val();
                    data.first_time_call = isFirstTimeList;
                },
                error: function (jqXHR) {
                    
                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{
                        
                    }
                }
            },
            "drawCallback": function (settings) {
                // Here the response
                isFirstTimeList = 0;
                var response = settings.json;
                
                $("#spanAgentCount").html('(' + response.agentCount + ')');
                $("#spanAmbassadorCount").html('(' + response.ambassadorCount + ')');
                $("#spanPendingCount").html('(' + response.pendingCount + ')');
                $("#spanNotLicencedCount").html('(' + response.notLicencedCount + ')');
                $("#spanMyBrokerageAgents").html('(' + response.myBrokerageAgentsCount + ')');

                let totalCount = parseInt(response.agentCount) + parseInt(response.ambassadorCount)  + parseInt(response.myBrokerageAgentsCount);
                $("#spanNetworkCount").html('(' + totalCount + ')');
                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.pBottom').hide();
                else
                    $('.pBottom').show();
            },
            
            "columnDefs":
                [
                    {
                        "targets": 0,
                        "width": "23.75%",
                        "title": "Name",
                        "data": "name",
                        "name": "name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "cellType": "td",
                        "className": "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let matches = rowData.name.match(/\b(\w)/g);
                            let shortName = matches.join('');

                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'><Avatar>{shortName}</Avatar> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e, `${rowData.id}`, 'pending')} className='name-link underline-ani-blue'>{rowData.name}</a></span></li>
                            </ul>
                            );
                        }

                    },
                    {
                        "targets": 1,
                        "width": "23.75%",
                        "title": "Up Line",
                        "data": "refer_user_name",
                        "name": "refer_user_name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [1],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_pending desktop bold-td",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let tempType = '';
                            if(rowData.refer_user_type === 'agent')
                                tempType = '(RA)';
                            else if(rowData.refer_user_type === 'ambassador')
                                tempType = '(A)';

                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'>{(rowData.refer_user_name) ? rowData.refer_user_name+' '+tempType: 'N/A' }</span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 2,
                        "width": "23.75%",
                        "title": "Type",
                        "data": "type",
                        "name": "type",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [2],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_pending desktop",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 3,
                        "width": "23.75%",
                        "title": "Created On",
                        "data": "created",
                        "name": "created",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [3],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_pending desktop",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 4,
                        "width": "20%",
                        "title": "Action",
                        "data": "id",
                        "visible": true,
                        "searchable": false,
                        "orderable": false,
                        //"className": "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                        "className": "my_amb referral-t desktop",
                        
                        "createdCell": (td, cellData, rowData, row, col) => {

                            createRoot(td).render(
                                // <div class="dropdown referral-option">
                                //     <a class="dropdown-toggle" href="javascript:void(0)" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                //     <img src="../assets/images/option-icon.png" alt="" />
                                //     </a>

                                //     <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                       

                                //     { (rowData.is_user_registered === 1)  && (
                                //         <li><span>{rowData.type+' joined via another user' }</span></li>)
                                //     }

                                //     { (rowData.is_user_registered === 0)   && (<>
                                //         <li><a class="dropdown-item"  onClick={(e) => handleResendLink(`${rowData.id}`)} href="javascript:;">Delete</a></li></>)
                                //         }
                                //         <li><a class="dropdown-item" href="javascript:;" onClick={(e) => handleDeleteInviteEmailLog(`${rowData.id}`)}>Remove Invite</a></li>
                                //     </ul>
                                // </div>
                                <ul className='title-check'>
                                    {
                                        (rowData.is_user_registered === 1) && (<><li><span className='name-title'>{rowData.type+' joined via another user' }</span></li></>)
                                    }
                                    {
                                        (rowData.is_user_registered === 0) && (<><li><span className='name-title'><a href="javascript:;" onClick={(e) => handleResendLink(`${rowData.id}`)} className='name-link underline-ani-blue resend-link-c'>Resend Invite</a></span></li></>)
                                    }
                                </ul>
                            );
                        },
                    },
                    {
                        "targets": 5,
                        "width": "10.75%",
                        "title": "",
                        "data": "id",
                        "visible": true,
                        "searchable": false,
                        "orderable": false,
                        //"className": "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                        "className": "my_amb referral-t desktop",
                        
                        "createdCell": (td, cellData, rowData, row, col) => {

                            createRoot(td).render(
                                
                                <ul className='title-check'>
                                    <li><span className='name-title'><a href="javascript:;" onClick={(e) => handleDeleteInviteEmailLog(`${rowData.id}`)} className='name-link underline-ani-blue resend-link-c'>Remove Invite</a></span></li>
                                </ul>
                            );
                        },
                    }
                ]
        });
        $('.searchbarTable').keyup(function () {
            if ((filter === 'pending') && (($(this).val().length >= 3) || ($(this).val().length === 0)))
            {
                setCurrentFilter('pending');
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else if (filter === 'pending')
            {
                setShowClearSearch(false);
            } 
        })
        $('#documents_search_type').change(function () {
            

             if (filter === 'pending')
             {
                setCurrentFilter('pending');
                table.draw();
             }    
         })
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

        $(".pendingTable").on('click', 'th', function () {
            $(".pendingTable thead th").each(function (i, th) {
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });

        $(".pendingTable th").first().click().click();
        // $('.clearSearchTable').click(function () {
        //     if (filter === 'pending') {
        //         $('.searchbarTable').val('');
        //         table.search('').draw();
        //     }
        //     else {
        //         $('.searchbarTable').val('');
        //         table.search('');
        //     }
        // })

        $('.pendingTabClass').click(function () {
            isFirstTimeList = 1;
            filter = 'pending';
            table.clear();
            table.draw();
        })
    }

    function fetchMyBrokerageAgentsData() {
        //$.fn.dataTable.ext.errMode = 'throw';

        const accessToken = window.localStorage.getItem('accessToken');
        const table = $('.myBrokerageAgentsTable').
                on('preXhr.dt', function ( e, settings, data ) {
                if (settings.jqXHR) settings.jqXHR.abort();
            }).DataTable({
            "language": {
                "filter_tab": "",
                "search": "",
                'searchPlaceholder': "Search by name, email or phone",
                "sEmptyTable": "No records found"
            },
            dom: '<"top"f>rt<"bottom ambBottom"lip><"clear">',
            //"orderFixed": [ 4, 'desc' ],
            order: [[4, 'desc']],
            "ordering": true,
            responsive: true,
            destroy: true,
            processing: true,
            "pageLength": 50,
            "lengthMenu": [[10, 20, 25, 30, 50, 100, 200, 500], [10, 20, 25, 30, 50, 100, 200, 500]],
            "serverSide": true,
            "serverMethod": 'POST',
            'ajax': {
                'url': baseUrl + 'list-my-network',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                'data': function (data) {
                    data.user_id = user.id;
                    data.search_value = $('.searchbarTable').val();
                    data.filter = filter;
                    data.selected_documents_filter = $('#documents_search_type').val();
                    data.first_time_call = isFirstTimeList;
                },
                error: function (jqXHR) {
                    if(jqXHR.status && jqXHR.status==401){
                        logout();
                        navigate('/login');
                    }else{
                        
                    }
                }
            },
            "drawCallback": function (settings) {
                // Here the response
                isFirstTimeList = 0;
                var response = settings.json;
                
                $("#spanAgentCount").html('(' + response.agentCount + ')');
                $("#spanAmbassadorCount").html('(' + response.ambassadorCount + ')');
                $("#spanPendingCount").html('(' + response.pendingCount + ')');
                $("#spanNotLicencedCount").html('(' + response.notLicencedCount + ')');
                $("#spanMyBrokerageAgents").html('(' + response.myBrokerageAgentsCount + ')');

                let totalCount = parseInt(response.agentCount) + parseInt(response.ambassadorCount)  + parseInt(response.myBrokerageAgentsCount);
                $("#spanNetworkCount").html('(' + totalCount + ')');

                if((response.recordsTotal === 0) || (response.recordsFiltered === response.recordsTotal))
                    $('.ambBottom').hide();
                else
                    $('.ambBottom').show();
                
            },
            "rowCallback": function( row, data, index ) {
                if( data.is_all_document_uploaded ===  0)
                {
                    $('td', row).css('background-color', '#ffe7e6');
                }
            },
            "columnDefs":
                [
                    {
                        "targets": 0,
                        "width": "20%",
                        "title": "Name",
                        "data": "name",
                        "name": "name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "cellType": "td",
                        "className": "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                        "createdCell": (td, cellData, rowData, row, col) => {
                            let matches = rowData.name.match(/\b(\w)/g);
                            let shortName = matches.join('');

                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'><Avatar>{shortName}</Avatar> <a href="javascript:;" onClick={(e) => handleNetworkDetail(e, `${rowData.id}`, 'ambassador')} className='name-link underline-ani-blue'>{rowData.name}</a></span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 1,
                        "width": "20%",
                        "title": "Up Line",
                        "data": "refer_user_name",
                        "name": "refer_user_name",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [1],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop bold-td",
                        "createdCell": (td, cellData, rowData, row, col) => {

                            let tempType = '';
                            if(rowData.refer_user_type === 'agent')
                                tempType = '(RA)';
                            else if(rowData.refer_user_type === 'ambassador')
                                tempType = '(A)';
                            createRoot(td).render(<ul className='title-check'>
                                <li><span className='name-title'>{(rowData.refer_user_name) ? rowData.refer_user_name+' '+tempType : 'N/A' }</span></li>
                            </ul>
                            );
                        }
                    },
                    {
                        "targets": 2,
                        "width": "15%",
                        "title": "Phone",
                        "data": "contact_number",
                        "name": "contact_number",
                        "searchable": true,
                        "visible": true,
                        "orderable": true,
                        "orderData": [2],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop bold-td",
                        "createdCell": "",
                    },
                    {
                        "targets": 3,
                        "width": "20%",
                        "title": "Broker License Info",
                        "data": "broker_license_number",
                        "name": "broker_license_number",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [3],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop",
                        render: function (data, type,row) {
                            
                            let html = '';
                            html += '<ul class="title-check">'
                            html += '<li><span class="name-title mb-1">Role: '+row.ambassador_broker_role_name+'</span><span class="name-title mb-1">License #: '+row.broker_license_number+'</span><span class="name-title">State: '+row.broker_license_state+'</span></li>';
                            html += '</ul>';
                            return html;
                        },
                    },
                    {
                        "targets": 4,
                        "width": "15%",
                        "title": "Created On",
                        "data": "created",
                        "name": "created",
                        "visible": true,
                        "searchable": true,
                        "orderable": true,
                        "orderData": [4],
                        "orderDataType": "asc",
                        "cellType": "td",
                        "className": "my_amb desktop",
                        "createdCell": "",
                        "render": ""
                    },
                    {
                        "targets": 5,
                        "width": "15%",
                        "title": "Action",
                        "data": "id",
                        "visible": user.type === 'admin',
                        "searchable": false,
                        "orderable": false,
                        //"className": "my_amb referral-t desktop tablet-p tablet-l mobile-p mobile-l",
                        "className": "my_amb referral-t desktop",
                        
                        "createdCell": (td, cellData, rowData, row, col) => {

                            createRoot(td).render(<ul className='action-icon'>
                                <li className='action-list-icon'>
                                    <a href='javascript:;' onClick={() => {handleEmail(rowData.id)}}>
                                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.667 13.4582H2.33366C1.72655 13.456 1.14493 13.2138 0.715632 12.7845C0.286336 12.3552 0.0441879 11.7736 0.0419922 11.1665V2.83317C0.0441879 2.22606 0.286336 1.64444 0.715632 1.21514C1.14493 0.785848 1.72655 0.5437 2.33366 0.541504H15.667C16.2741 0.5437 16.8557 0.785848 17.285 1.21514C17.7143 1.64444 17.9565 2.22606 17.9587 2.83317V11.1665C17.9565 11.7736 17.7143 12.3552 17.285 12.7845C16.8557 13.2138 16.2741 13.456 15.667 13.4582ZM2.33366 1.7915C2.05739 1.7915 1.79244 1.90125 1.59709 2.0966C1.40174 2.29195 1.29199 2.5569 1.29199 2.83317V11.1665C1.29199 11.4428 1.40174 11.7077 1.59709 11.9031C1.79244 12.0984 2.05739 12.2082 2.33366 12.2082H15.667C15.9433 12.2082 16.2082 12.0984 16.4036 11.9031C16.5989 11.7077 16.7087 11.4428 16.7087 11.1665V2.83317C16.7087 2.5569 16.5989 2.29195 16.4036 2.0966C16.2082 1.90125 15.9433 1.7915 15.667 1.7915H2.33366Z" fill="#1E2938" />
                                            <path d="M9.0001 8.12498C8.51651 8.12498 8.04174 7.99549 7.6251 7.74998L0.350104 3.42498C0.214182 3.34026 0.115402 3.2072 0.0736538 3.05258C0.0319056 2.89795 0.0502894 2.73326 0.125104 2.59164C0.208308 2.45398 0.342012 2.35427 0.497695 2.31379C0.653378 2.27331 0.818715 2.29527 0.958437 2.37498L8.24177 6.69164C8.46265 6.82637 8.71638 6.89764 8.9751 6.89764C9.23383 6.89764 9.48756 6.82637 9.70844 6.69164L16.9918 2.37498C17.1315 2.29527 17.2968 2.27331 17.4525 2.31379C17.6082 2.35427 17.7419 2.45398 17.8251 2.59164C17.8999 2.73326 17.9183 2.89795 17.8766 3.05258C17.8348 3.2072 17.736 3.34026 17.6001 3.42498L10.3751 7.74998C9.95847 7.99549 9.4837 8.12498 9.0001 8.12498Z" fill="#1E2938" />
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                    <a href='javascript:;' onClick={() => {handleEdit(rowData.id,rowData.type,'ambassador')}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_21796)">
                                                <path d="M3.33398 16.6663H6.66732L15.4173 7.91627C15.8593 7.47424 16.1077 6.87472 16.1077 6.2496C16.1077 5.62448 15.8593 5.02496 15.4173 4.58293C14.9753 4.14091 14.3758 3.89258 13.7507 3.89258C13.1255 3.89258 12.526 4.14091 12.084 4.58293L3.33398 13.3329V16.6663Z" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.25 5.41602L14.5833 8.74935" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_21796">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </li>
                                <li className='action-list-icon'>
                                <a href='javascript:;' onClick={() => {handleDelete(rowData.id,rowData.created_referral_count,rowData.assigned_as_agent_referral_count,'ambassador')}}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3722_13698)">
                                                <path d="M3.33398 5.83398H16.6673" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.33398 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11.666 9.16602V14.166" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.16602 5.83398L4.99935 15.834C4.99935 16.276 5.17494 16.6999 5.4875 17.0125C5.80006 17.3251 6.22399 17.5007 6.66602 17.5007H13.3327C13.7747 17.5007 14.1986 17.3251 14.5112 17.0125C14.8238 16.6999 14.9993 16.276 14.9993 15.834L15.8327 5.83398" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333" stroke="#1E2938" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3722_13698">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </a>
                                </li>
                                
                            </ul>);
                        },

                    }
                ]
        });
        
        $('.searchbarTable').keyup(function () {
           // console.log($(this).val().length)
            if ((filter === 'my_brokerage_agents') && (($(this).val().length >= 3) || ($(this).val().length === 0)))
            {
                setCurrentFilter('my_brokerage_agents');
                if($(this).val().length >= 3)
                {
                    setShowClearSearch(true);
                }
                else
                {
                    setShowClearSearch(false);
                }
                table.search($(this).val()).draw();
            }
            else if (filter === 'my_brokerage_agents')
            {
                setShowClearSearch(false);
            }    
        })
        $('#documents_search_type').change(function () {
             if (filter === 'my_brokerage_agents')
             {
                setCurrentFilter('my_brokerage_agents');
                table.draw();
             }    
         })
        const spanSorting = '<span class="arrow-hack sort">&nbsp;&nbsp;&nbsp;</span>',
            spanAsc = '<span class="arrow-hack asc">&nbsp;&nbsp;&nbsp;</span>',
            spanDesc = '<span class="arrow-hack desc">&nbsp;&nbsp;&nbsp;</span>';

        $(".myBrokerageAgentsTable").on('click', 'th', function () {
            $(".myBrokerageAgentsTable thead th").each(function (i, th) {
                $(th).find('.arrow-hack').remove();
                var html = $(th).html(),
                    cls = $(th).attr('class');
                switch (cls) {
                    case 'sorting_asc':
                        $(th).html(html + spanAsc); break;
                    case 'sorting_desc':
                        $(th).html(html + spanDesc); break;
                    default:
                        $(th).html(html + spanSorting); break;
                }
            });
        });

        $(".myBrokerageAgentsTable th").first().click().click();

        

        $('.myBrokerageAgentsTabClass').click(function () {
            isFirstTimeList = 1;
            filter = 'my_brokerage_agents';
            table.clear();
            table.draw();
        })
        setTimeout(function () {
            $('input[name^="switchId_"]').click(function () {
                
                let rowId = $(this).attr('data-id');
                let mlsStatus = $(this).attr('data-is-mls');
               // console.log('rowId ' + rowId + ' mlsStatus ' + mlsStatus)

            });
        }, 2000);
    }
    const width = {
        width: "100%",
    }
    
    const handleResendLink = async (rowId) => {
        
        try {
            let formData = new FormData();
            formData.append('invite_id', rowId);
            formData.append('host_name', window.location.origin);

            const response = await axios.post('/resend-invite-email', formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                
                Swal.fire({
                    title: '',
                    text: StatusMessage,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    
                })
            }
            else {

            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }else{
                
            }
        }
    }
    const handleNetworkDetail = (event, rowId, mode) => {
        
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();

        const modeEncryptedString = AES.encrypt(JSON.stringify(mode), secretKey).toString();

        let finalUrl = 'u=' + encodeURIComponent(userIdEncryptedString) + '&m=' + encodeURIComponent(modeEncryptedString);
        
        navigate('/app/my-network-detail?' + finalUrl);
    }
    const handleCall = async (event, id) => {
        try {
            let formData = new FormData();
            formData.append('user_id', id);

            const response = await axios.post('/mls-status-change', formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                Swal.fire({
                    title: '',
                    text: "MLS status changed successfully",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    
                })
            }
            else {

            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }else{
               
            }
        }
    };

    const handleCallPowerUser = async (event, id) => {
        try {
            let formData = new FormData();
            formData.append('user_id', id);

            const response = await axios.post('/power-user-status-change', formData);
            const { Status, StatusMessage, Error, Result } = response.data;

            if (Status === true) {
                Swal.fire({
                    title: '',
                    text: "Power user status changed successfully",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    
                })
            }
            else {

            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }else{
               
            }
        }
    };
    
    const reloadListing = () => {
        setEditRowId(0)
        setEditRowType('')
        
        if(editRowTypeTemp === 'ambassador')
            $(".ambassadorTabClass").first().trigger('click');
        else if(editRowTypeTemp === 'license_agent')
            $(".agentTabClass").first().trigger('click');
        else if(editRowTypeTemp === 'not_license_agent')
            $(".notLicencedTabClass").first().trigger('click');

        setEditRowTypeTemp('')
    }

    const changeStatusEditUser = () => {
        setEditRowId(0)
        setEditRowType('')
        setShowEditUser(false)
    }
    
    const handleEdit = (rowId, rowType,mode) => {
        setShowEditUser(true)
        setEditRowId(rowId);
        setEditRowType(rowType);
        setEditRowTypeTemp(mode);
        setCallFrom('listing');
    }
    const handleEmail = (rowId) => {
        const userIdEncryptedString = AES.encrypt(JSON.stringify(rowId), secretKey).toString();

        let finalUrl = 'u=' + encodeURIComponent(userIdEncryptedString);
        
        navigate('/app/email-template?' + finalUrl);
    }

    
    const handleDelete = (rowId,referralCount,assignedAgentCount,mode) => {
        
        let message = '';
        let messageForDelete = '';
        if((referralCount > 0) && (assignedAgentCount > 0))
        {
            let referralText = "referral";
            let referralTextTemp = "referral";
            if(referralCount > 1)
                referralTextTemp = "referrals";

            if(assignedAgentCount > 1)
                referralText = "referrals";  
            message = "This can't be deleted, sorry!<br>The user assigned as agent for "+assignedAgentCount+" "+referralText+" and created "+referralCount+" "+referralTextTemp+".";
        }
        else if(referralCount > 0)
        {
            let referralTextTemp = "referral";
            if(referralCount > 1)
                referralTextTemp = "referrals";
            message = "This can't be deleted, sorry!<br>The user created "+referralCount+" "+referralTextTemp+".";
        }
        else if(assignedAgentCount > 0)
        {
            let referralText = "referral";
            if(assignedAgentCount > 1)
                referralText = "referrals";

            message = "This can't be deleted, sorry!<br>The user assigned as agent for "+assignedAgentCount+" "+referralText+".";
        }
        else
        {
            messageForDelete = "You won't be able to reverse this!";
        }
        if(message)
        {
            Swal.fire({
                title: '',
                html: message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

            })
        }
        else
        {
            Swal.fire({
                title: 'Are you sure?',
                html: messageForDelete,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                   
                    callDelete(rowId,mode)
                }
            })
        }
    }
    const callDelete = async (rowId,mode) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('user_id', rowId);

        try {
            const response = await axios.post('/delete-user', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                
                if(mode === 'ambassador')
                    $(".ambassadorTabClass").first().trigger('click');
                else if(mode === 'license_agent')
                    $(".agentTabClass").first().trigger('click');
                else if(mode === 'not_license_agent')
                    $(".notLicencedTabClass").first().trigger('click');

            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }
        }
    }
    
    const handleDeleteInviteEmailLog = (rowId) => {
        let messageForDelete = '';
        
        messageForDelete = "You won't be able to reverse this!";
        
        Swal.fire({
            title: 'Are you sure?',
            html: messageForDelete,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
               
                callDeleteInviteEmailLog(rowId)
            }
        })
    }
    const callDeleteInviteEmailLog = async (rowId) => {
        setErrorMessage('');
        let formData = new FormData();
        formData.append('invite_email_log_id', rowId);

        try {
            const response = await axios.post('/delete-invite-emai', formData);
            const { Status, StatusMessage, Error, Result } = response.data;
            let finalApiError = '';

            if (Status === false) {
                if (Error.length > 0) {
                    Error.map(
                        (errorMessage) =>
                            //finalApiError += '<li>'+errorMessage+'</li>'
                            (finalApiError = errorMessage)
                    );
                } else {
                    finalApiError = StatusMessage;
                }
                setErrorMessage(finalApiError);
            } else {
                $(".pendingTabClass").first().trigger('click');
            }
        } catch (err) {
            if(err.response.status && err.response.status==401){
                logout();
                navigate('/login');
            }
        }
    }
    useEffect(() => {
        const fetchBrokerageRoleData = async () => {
            try {
                const response = await axios.post('/get-all-Brokerage-role-data');
                const { Status, StatusMessage, Error, Result } = response.data;

                if (Status === true) {
                    
                    setBrokerageRoleData(Result);
                }
            } catch (err) {
               // console.log(err);
            }
        };
        fetchBrokerageRoleData();
        const fetchStatesData = async () => {
            try {
                const response = await axios.post("/get-all-states");
                const { Status, Result } = response.data;

                if (Status === true) {
                   
                    setDbStatesName(Result);
                }
            } catch (err) {
               // console.log(err);
            }
        };
        fetchStatesData();
    }, []);
    return (
        <>
            <section className='table-component sec-pt sec-pb'>
                <div className="container-fluid">
                {errorMessage && (
                        <Alert sx={{ mb: 3 }} severity="error" variant="filled">
                            {errorMessage}
                        </Alert>
                    )}
                    <div className="table-search">
                        <h2 className='main-heading mb-0' title='Does Not Include Pending Invitations or Agents in States that REFR does not currently have a License ("Unlicensed State Agents" tab)'>My Network <span id="spanNetworkCount">(0)</span></h2>
                        <div className='tableSearch emailtable-search'>
                            <span className="p-input-icon-right document_right">
                            <select id="documents_search_type" className='document_dropdown'>
                                <option  value="all">All</option>
                                <option  value="document_exits">Document exists</option>
                                <option  value="document_not_exits">Document not exists</option>
                            </select>
                                {/* <Dropdown name="documents_search_type" id="documents_search_type"
                                className="documents_search_type" value={selectedDocuments} options={documentsWiseFilter} placeholder="Select Data" onChange={(e) => { setSelectedDocuments(e.target.value)}}
                                    optionLabel="name"
                                /> */}
                                <i class="pi pi-angle-down"></i>
                            </span>
                            <span className="p-input-icon-right">
                                <InputText  autoComplete="off" id='searchbarTable' className="searchbarTable mynetwork-search" placeholder='Search by name, upline, email or phone' />
                                {
                                    (!showClearSearch) && (<i className="pi pi-search" />)
                                }
                                {
                                    (showClearSearch) && (<a className="close-icon" href="javascript:;" onClick={handleClearSearch} ><i className="pi pi-times" /></a>)
                                }
                            </span>
                            {/* <span className='filter-table'>
                                <a href="javascript:;" className="clearSearchTable ">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20" cy="20" r="19.5" fill="white" stroke="#828C99" />
                                        <g clip-path="url(#clip0_711_20484)">
                                            <path d="M27.5 13.3335V15.0002H26.6667L21.6667 22.5002V28.3335H18.3333V22.5002L13.3333 15.0002H12.5V13.3335H27.5Z" fill="#828C99" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_711_20484">
                                                <rect width="20" height="20" fill="white" transform="translate(10 10)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </a>

                            </span> */}
                            {
                                (user) && (user.type === 'admin') && (<span className='add-email-template ms-2'>
                                <Button label="Add Ambassador" className='btn btn-submit email-temp-plus-btn' icon="pi pi-plus-circle"  onClick={() => {setShowEditUser(true);setEditRowType('ambassador');setEditRowTypeTemp('ambassador');setCallFrom('listing')} } />
                            </span>)
                            }
                            
                        </div>
                    </div>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {
                            (user) && (user.type === 'ambassador') && (user.brokerage_role_id.slug === 'broker_of_record') && (<><li className="nav-item" role="presentation">
                            <button className={"nav-link myBrokerageAgentsTabClass " + (isBrokerageRecordShow ? 'active' : '')} id="my-brokerage-tab" data-bs-toggle="tab" data-bs-target="#my-brokerage-agents-tab-pane" type="button" role="tab" aria-controls="license-tab-pane" aria-selected="true" >My Brokerage Agents <span id="spanMyBrokerageAgents">(0)</span></button>
                        </li></>)  
                        }
                        <li className="nav-item" role="presentation">
                            <button className={"nav-link ambassadorTabClass " + (isBrokerageRecordShow ? '' : 'active')}  id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" >Ambassadors <span id="spanAmbassadorCount">(0)</span></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link agentTabClass" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" >REFR Agents <span id="spanAgentCount">(0)</span></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link pendingTabClass" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false" >Pending <span id="spanPendingCount">(0)</span></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link notLicencedTabClass" id="license-tab" data-bs-toggle="tab" data-bs-target="#license-tab-pane" type="button" role="tab" aria-controls="license-tab-pane" aria-selected="false" >Unlicensed State Agents <span id="spanNotLicencedCount">(0)</span></button>
                        </li>
                        
                       
                    </ul>
                    <div className="tab-content" id="myTabContent">
                    <div  className={"tab-pane fade " + (isBrokerageRecordShow ? 'show active' : '')} id="my-brokerage-agents-tab-pane" role="tabpanel" aria-labelledby="my-brokerage-tab" tabIndex="0">
                            <table className="short-table display nowrap networkTable myBrokerageAgentsTable" cellSpacing="0" style={width} id="networkTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Up Line </th>
                                        <th>Phone </th>
                                        <th>Broker License Info</th>
                                        <th>Created On</th>
                                        <th className='hide_sorting'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>


                                </tbody>
                            </table>
                        </div>
                        <div className={"tab-pane fade " + (isBrokerageRecordShow ? '' : 'show active')} id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                            <table className="short-table display nowrap networkTable ambassadorTable" cellSpacing="0" style={width} id="networkTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Up Line </th>
                                        <th>Phone </th>
                                        <th className='hide_sorting'>MLS Info</th>
                                        <th className='hide_sorting'>Power User</th>
                                        <th>Created On</th>
                                        <th className='hide_sorting'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>


                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                            <table className="short-table display nowrap networkTable agentTable" cellSpacing="0" style={width} id="networkTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Up Line </th>
                                        <th>Phone </th>
                                        <th className='hide_sorting'>Power User</th>
                                        <th>License Info </th>
                                        <th>Created On</th>
                                        <th className='hide_sorting'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>


                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                            <table className="short-table display nowrap networkTable pendingTable" cellSpacing="0" style={width} id="networkTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Up Line </th>
                                        <th>Type</th>
                                        <th>Created On</th>
                                        <th className='hide_sorting'>Action</th>
                                        <th className='hide_sorting'></th>
                                    </tr>
                                </thead>
                                <tbody>


                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade" id="license-tab-pane" role="tabpanel" aria-labelledby="license-tab" tabIndex="0">
                            <table className="short-table display nowrap networkTable notLicensedTable" cellSpacing="0" style={width} id="networkTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Up Line </th>
                                        <th>Phone </th>
                                        <th>License Info</th>
                                        <th>Created On</th>
                                        <th className='hide_sorting'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>


                                </tbody>
                            </table>
                        </div>
                        
                    </div>

                </div>
            </section>
            {
                (showEditUser) && (<EditUser showEditUser={showEditUser} changeStatusEditUser={changeStatusEditUser} reloadListing={reloadListing} editRowId={editRowId} editRowType={editRowType} dbStatesName={dbStatesName} dbBrokerageRoleData={dbBrokerageRoleData} callFrom={callFrom}/>)
            }
            
        </>

    )
}

export default MyNetwork;