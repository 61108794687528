import React, { useState,useEffect } from "react";
import { useNavigate,useLocation,Navigate } from "react-router-dom";
import { ReferralFormStepper } from "./ReferralFormStepper";
import { SubmitAReferralContextProvider } from "./context/submit-a-referral-context";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

export default function SubmitAReferralUpdate() {
    const navigate = useNavigate();
    const secretKey = "REFR2022@GALAXY";
    const search = useLocation().search;
    const referralRequestId = new URLSearchParams(search).get('u');
    let editReferralId = '';
    //console.log('referralRequestId '+referralRequestId)
    if ( referralRequestId === null) 
    {
        navigate('/dashboard');
    } else {
        let referralRequestIdDecrypt = AES.decrypt(referralRequestId, secretKey);
        console.log('referralRequestIdDecrypt '+referralRequestIdDecrypt)
        if(referralRequestIdDecrypt != "")
        {
            editReferralId = JSON.parse(referralRequestIdDecrypt.toString(Utf8));
        }
        else
        {
            navigate('/dashboard');
        }
    }
    //console.log('editReferralId '+editReferralId)
    if(editReferralId)
    {
      return (
        <SubmitAReferralContextProvider>
            <ReferralFormStepper editReferralId={editReferralId}/>
        </SubmitAReferralContextProvider>
      );
    }
    if(!editReferralId)
    {
      return (
        <Navigate to="/dashboard" />
      );
    }
}
